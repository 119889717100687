import * as React from "react";
import './otpField.scss'
import { integerExp } from "../../utils/regex";

export interface IOtpProps {
	placeholder?: string;
	eleId: string;
	onChange: (value: string) => void;
	disableOtp?: boolean;
	invalidOtp?: boolean;
}

enum EKeyCodes {
	BACKSPACE = 8,
	LEFT_ARROW = 37,
	RIGHT_ARROW = 39
}

const OtpField: React.FC<IOtpProps> = props => {
	// stores the value of each input in array
	const [value, setValue] = React.useState(["", "", "", ""]);

	// when focussed, this helps to colour the border of input_field_cont
	const [focusClass, setFocusClass] = React.useState("");

	React.useEffect(() => {
		let element = document.getElementsByClassName(props.eleId)[0];
		setTimeout(() => {
			document.getElementById(element.id)?.focus();
		}, 500);
		element.scrollIntoView();
	}, []);

	function handleChange(v: string, i: number) {
		if (integerExp.test(v)) {
			const valueCopy = [...value];
			valueCopy[i] = v;

			setValue(valueCopy);
			props.onChange(valueCopy.join(""));
		}
	}

	// go to the previous input
	function goToPrevious(event: any) {
		const idArr = event.target.id.split('-')
		const pervId = idArr[0] + '-' + (parseInt(idArr[1]) - 1)
		const prevInp = document.getElementById(pervId)
		prevInp && prevInp.focus();
	}

	// go to next input
	function goToNext(event: any) {
		const idArr = event.target.id.split('-')
		const nextId = idArr[0] + '-' + (parseInt(idArr[1]) + 1)
		const nextInp = document.getElementById(nextId)
		nextInp && nextInp.focus();
	}

	function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.keyCode === EKeyCodes.BACKSPACE || e.keyCode === EKeyCodes.LEFT_ARROW) {
			// if backspace or left arrow is pressed
			goToPrevious(e);
		} else if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === EKeyCodes.RIGHT_ARROW) {
			// if number key or right arrow is pressed
			goToNext(e);
		}
	}

	return (
		<div className="otp_inp_grp">
			{value.map((_, i) => (
				<input
					key={i}
					placeholder={`${i + 1}`}
					value={value[i]}
					onChange={e => handleChange(e.target.value, i)}
					onFocus={() => setFocusClass(" focus")}
					onBlur={() => setFocusClass("")}
					className={"otp_inp mg-b10 " + props.eleId + focusClass + (props.invalidOtp ? ' invalid' : '')}
					type="tel"
					min={0}
					max={9}
					maxLength={1}
					onKeyUp={e => handleKeyUp(e)}
					autoComplete='off'
					id={props.eleId + "otp-" + i}
					disabled={props.disableOtp}
				/>
			))}
		</div>
	);
};

export default OtpField;
