import React from "react";
import "./DocumentBrowse.scss";
import ErrMsg from "../ErrMsg/ErrMsg";
import ProgressBar from "../ProgressBar/ProgressBar";
import pngIcon from "../../../assets/icons/png.png";
import jpgIcon from "../../../assets/icons/jpg.png";
import pdfIcon from "../../../assets/icons/pdf.png";
import Backdrop from "../Backdrop/Backdrop";
import prevIcon from "../../../assets/icons/eye.png";
import cropIcon from "../../../assets/icons/crop.png";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "../Button/Button";
import DeviceContext from "../../Services/identifyDevice";
import GlobalStore from "../../store/globalStore";
import Loader from "./../Loader/Loader";
import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";

export interface DocDetailFormat {
  id: string;
  disable?: boolean;
  isImageOnly?: boolean;
  uploadPer: number;
  hideProgress: boolean;
  fileName: string;
  showFile: boolean;
  imgUrl: any;
  documentCategoryId?: string;
  documentId?: number;
  mimeType?: string;
  documentcategory?: string;
  base64?: string;
  iscropped?: boolean;
  isWatsappFlag?: boolean;
}
export interface DocumentBrowseProps {
  id: string;
  docObj: DocDetailFormat;
  onDocUploadComplete?: (
    value: any,
    fileName: string,
    id: string,
    type: string
  ) => void;
  isRemove?: (docObj: DocDetailFormat) => void;
  removePanImg?: boolean;
  previewImageexternalClassName?: string;
  mandateRemove?: boolean;
}

const DocumentBrowse: React.FC<DocumentBrowseProps> = (props) => {
  const [cropper, setCropper] = React.useState<any>();
  const [docErrorMsg, setDocErrorMsg] = React.useState<string | number>("");
  // const [prevImage, setPrevImage] = React.useState<any>(props.docObj.imgUrl);
  const [prevImage, setPrevImage] = React.useState<any>();
  const [loadingFlag, setLoadingFlag] = React.useState<boolean>(
    props.docObj.showFile
  );
  const [docName, setDocName] = React.useState<string>(props.docObj.fileName);
  // const [ext, setExt] = React.useState<String>(props.docObj.mimeType === "application/pdf" ? "PDF" : "");
  const [ext, setExt] = React.useState<String>("");
  const isCroppingRef = React.useRef<HTMLDivElement>(null);
  const isPreviewRef = React.useRef<HTMLDivElement>(null);

  const minContainerWidth: number = DeviceContext().isMobile ? 300 : 500;
  const minContainerHeight: number = DeviceContext().isMobile ? 150 : 250;
  const minCanvasWidth: number = DeviceContext().isMobile ? 200 : 400;
  const minCanvasHeight: number = DeviceContext().isMobile ? 200 : 400;
  const minCropBoxWidth: number = DeviceContext().isMobile ? 100 : 100;
  const minCropBoxHeight: number = DeviceContext().isMobile ? 100 : 100;

  React.useEffect(() => {
    isPreviewRef.current!.hidden = true;
    isCroppingRef.current!.hidden = true;
    if (props.docObj.mimeType === "application/pdf") {
      setExt("PDF");
    }
  }, []);
  React.useEffect(() => {
    setLoadingFlag(props.docObj.showFile);
  }, [props.docObj.showFile, props.docObj]);

  React.useEffect(() => {
    if (props.removePanImg) {
      console.log("props.removePanImg", props.removePanImg);
      removeDoc();
    }
  }, [props.removePanImg]);

  function onFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const elem = event.target;
    const file = elem.files && elem.files[0];
    if (file) {
      setDocName(file.name);
    }

    if (file && file.size > 5242880) {
      setDocErrorMsg(errorMsg(errorCode.FE12));
      return;
    }
    if (file && file.name.split(".").length === 2) {
      let extensions = file.name.split(".");
      if (props.docObj.documentId === 305) {
        if (file.size > 1048576) {
          setDocErrorMsg(errorMsg(errorCode.FE47));
          return;
        } else if (extensions[1].toUpperCase() === "PDF") {
          setDocErrorMsg(errorMsg(errorCode.FE13));
          return;
        }
      }
      if (
        extensions[1].toUpperCase() === "JPG" ||
        extensions[1].toUpperCase() === "JPEG" ||
        extensions[1].toUpperCase() === "PDF"
      ) {
        setExt(extensions[1].toUpperCase());

        if (elem.files && elem.files[0]) {
          setDocErrorMsg("");
          let data = new FormData();
          data.append("file", elem.files[0]);
          const reader = new FileReader();
          setLoadingFlag(true);

          reader.onload = function (e) {
            const images = (e.target && e.target.result)?.toString();
            if (images) {
              setPrevImage(images);
            }
            setLoadingFlag(false);
            props.onDocUploadComplete &&
              props.onDocUploadComplete(images, file.name, props.id, file.type);
          };
          reader.readAsDataURL(elem.files[0]);
        }
      } else {
        if (props.docObj.id !== "signature") {
          setDocErrorMsg(errorMsg(errorCode.FE14));
        } else {
          setDocErrorMsg(errorMsg(errorCode.FE13));
        }
        return;
      }
    } else {
      setDocErrorMsg(errorMsg(errorCode.FE15));
      return;
    }
    // if (props.id === 'signature' || file.name.split(".")[1].toUpperCase() === "JPG" || file.name.split(".")[1].toUpperCase() === "JPEG") {
    //     handleCropping(file.type);
    // }
    if (props.id === "signature") {
      handleCropping(file.type);
    }
  }

  const removeDoc = () => {
    setLoadingFlag(true);
    // let updateProps = {...props};
    props.docObj.uploadPer = 0;
    props.isRemove && props.isRemove(props.docObj);
    // setPrevImage(undefined);
    // setExt("");
    // props.docObj.hideProgress = true;
  };

  const closeSample = (props: any) => {
    isPreviewRef.current!.hidden = true;
    isCroppingRef.current!.hidden = true;
  };

  const handleCropping = (mimetype?: string) => {
    // if (mimetype === "application/pdf" || (!mimetype && props.docObj.mimeType === "application/pdf")) {
    if (props.id !== "signature") {
      isPreviewRef.current!.hidden = false;
      isCroppingRef.current!.hidden = true;
    } else {
      isPreviewRef.current!.hidden = true;
      // isCroppingRef.current!.hidden = false;
    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setPrevImage(cropper.getCroppedCanvas().toDataURL(props.docObj.mimeType));
      props.docObj.iscropped = true;
      props.docObj.base64 = cropper
        .getCroppedCanvas()
        .toDataURL(props.docObj.mimeType);
      saveCroppedImage();
    }
  };

  const saveCroppedImage = () => {
    props.docObj.imgUrl = prevImage;
    if (prevImage !== undefined) {
      const cropImgtype = prevImage.substring(
        "data:image/".length,
        prevImage.indexOf(";base64")
      );
      // props.onDocUploadComplete && props.onDocUploadComplete(cropper.getCroppedCanvas().toDataURL(), props.docObj.fileName, props.id, cropImgtype);
      props.onDocUploadComplete &&
        props.onDocUploadComplete(
          prevImage,
          props.docObj.fileName,
          props.id,
          cropImgtype
        );
      isPreviewRef.current!.hidden = true;
      isCroppingRef.current!.hidden = true;
    }
  };

  return (
    <div className="docBrowseLayout">
      <div ref={isPreviewRef}>
        <Backdrop
          isShow={true}
          isCloseIcon={true}
          onClose={() => closeSample(props)}
        >
          <>
            {ext === "PDF" ? (
              <embed
                src={prevImage || props.docObj.imgUrl}
                className="pdfMaxSize"
              />
            ) : (
              <div className="">
                <img
                  className={props?.previewImageexternalClassName?props?.previewImageexternalClassName:"maxSize"}
                  src={prevImage || props.docObj.imgUrl}
                  alt={prevImage}
                />
              </div>
            )}
          </>
        </Backdrop>
      </div>
      <div ref={isCroppingRef}>
        <Backdrop
          isShow={true}
          isCloseIcon={true}
          onClose={() => closeSample(props)}
        >
          <div className="cropPrev">
            {prevImage || props.docObj.imgUrl ? (
              <Cropper
                // style={DeviceContext().isMobile ? { height: "150px", width: "300px" } : {}}
                initialAspectRatio={2}
                preview=".img-preview"
                src={prevImage || props.docObj.imgUrl}
                viewMode={1}
                guides={true}
                minContainerWidth={minContainerWidth}
                minContainerHeight={minContainerHeight}
                minCanvasWidth={minCanvasWidth}
                minCanvasHeight={minCanvasHeight}
                minCropBoxWidth={minCropBoxWidth}
                minCropBoxHeight={minCropBoxHeight}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
            ) : (
              <div>
                <Loader size={20} />
              </div>
            )}
          </div>
          <Button btnName="Crop and Upload" onClick={() => getCropData()} />
        </Backdrop>
      </div>

      {loadingFlag ? (
        <div
          className={"docBrowse " + (props.docObj.disable ? " disable" : "")}
        >
          <input
            type="file"
            disabled={props.docObj.disable}
            id={props.docObj.id}
            className="fileInput"
            onChange={onFileUpload}
            accept="image/jpeg, image/jpg, .pdf"
          />
          <label className="dragLbl">
            DRAG FILE HERE OR<span className="dragLbl browse">BROWSE</span>
          </label>
          <br />
          {props.id === "signature" ? (
            <label className="dragLbl">JPG, JPEG (Max size: 1MB)</label>
          ) : (
            <label className="dragLbl">PDF, JPG, JPEG (Max size: 5MB)</label>
          )}
        </div>
      ) : (
        <div className="uploadSec">
          <div className="flexConatiner">
            <div className="imgSec">
              {props.docObj.uploadPer !== 100 ? (
                (ext === "PDF" && <img src={pdfIcon} alt="" />) ||
                (ext === "JPG" && (
                  <img src={jpgIcon} onClick={() => handleCropping()} alt="" />
                ))
              ) : (
                <div className="container" onClick={() => handleCropping()}>
                  {ext === "PDF" ||
                  props.docObj.mimeType === "application/pdf" ? (
                    <img className="thumbnail" src={pdfIcon} alt="" />
                  ) : (
                    <img
                      className="thumbnail"
                      src={props.docObj.imgUrl || prevImage}
                      alt=""
                    />
                  )}
                  <div className="middle">
                    <div className="text">
                      {props.id !== "signature" ? (
                        <img src={prevIcon} alt="prevImg" />
                      ) : (
                        <img src={cropIcon} alt="prevImg" />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="uploading">
              <label>{docName}</label>
              {props.docObj.hideProgress && (
                <div className="mrt8">
                  <ProgressBar
                    percent={props.docObj.uploadPer.toString()}
                    width="120px"
                    height="4px"
                    isBlueColor
                  />
                </div>
              )}
            </div>
            {(!props.docObj.disable || props.mandateRemove) && (
              <div
                className={"cancelSec"}
                onClick={() => {
                  removeDoc();
                }}
              >
                <label className="cancelBtn">
                  {props.docObj.hideProgress ? "Cancel" : "Remove"}
                </label>
                <div className="cancelIcon edel-icon-close closeIcon"></div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* Error Msg Start*/}
      {docErrorMsg && (
        <div className="errMsgBlck">
          <ErrMsg>
            {typeof docErrorMsg === "number" ? (
              <>
                <label>We are facing some technical issue.</label>
                <label>
                  Please try after sometime (error code:{docErrorMsg})
                </label>
              </>
            ) : (
              <label>{docErrorMsg}</label>
            )}
          </ErrMsg>
        </div>
      )}
      {/* Error Msg End*/}
    </div>
  );
};

export default DocumentBrowse;
