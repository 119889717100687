import React from "react";
import ProfileNavBar, {
  checkModuleCompleteness,
} from "../ProfileNavBar/ProfileNavBar";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import DeviceContext from "../../Services/identifyDevice";
import "./ProfileLayout.scss";
import {
  clientPartialDetailsToStore,
  ACreateApplicationTicket,
  setStageData,
  redirectToEntryPoint,
  completeModuleStatus,
} from "../../Services/globalService";
import { useHistory } from "react-router-dom";
import GlobalStore from "../../store/globalStore";
import {
  AGetEsignUrlDetails,
  APendingUploadList,
} from "../../Services/dashboard/eSignApiServices";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import {
  containerUrl,
  dashboardUrl,
  leftNavBarArray,
  ipvNavBarObj,
  statusUrl,
} from "../../utils/constants";
import { ASendApplicationForQC } from "../../Services/dashboard/docVerificationAPIServices";
import { ACheckClientProfilingData } from "../../Services/dashboard/profilerApiServices";
import gtmService from "../../Services/gtmService";
import config from "../../../config/domain";
import { errorMessage } from "../../utils/errorMessage";

export interface ProfileLayoutProps {}

const ProfileLayout: React.FC<ProfileLayoutProps> = (props) => {
  clientPartialDetailsToStore();
  const isDesktop = DeviceContext().isDesktop;
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (history.action === "POP") history.push(redirectToEntryPoint());
    else if (
      !GlobalStore.isDigiLocker &&
      !GlobalStore.isKRA &&
      !GlobalStore.isKYC &&
      GlobalStore.leftNavBarState.length === leftNavBarArray.length
    )
      GlobalStore.leftNavBarState.push(ipvNavBarObj);
  }, [GlobalStore.isKYC, GlobalStore.isKRA]);

  React.useEffect(() => {
    setIsLoading(true);
    APendingUploadList(checkPendingUpload, GlobalStore.applicationID);
  }, [history.location.pathname]);

  function checkPendingUpload(res: any) {
    if (!res || res.error) {
    } else {
      if (res && res.data) {
        const list: Array<any> = JSON.parse(res.data).filter(
          (item: any) =>
            // Prev Api response
            // item.documentcategoryid !== 36 &&
            // item.documentcategoryid !== 30 &&
            // item.documentcategoryid !== 62
            item.Mandatory == true
        );
        let isUploadDocumentsCompleted: boolean = false;
        if (list.length === 0 || list[0].count === 0)
          isUploadDocumentsCompleted = true;
        ACheckClientProfilingData(
          getProfilerStatus,
          GlobalStore.applicationID,
          config.ClientProfilingDataSourseId
        );
        AGetStageCompletionStatus(
          (res: any) => getClientStageDetails(res, isUploadDocumentsCompleted),
          GlobalStore.applicationID
        );
      } else {
        setIsLoading(false);
      }
    }
  }

  function getProfilerStatus(res: any) {
    if (res.data) {
      completeModuleStatus(dashboardUrl.profiler);
    }
  }

  function getClientStageDetails(
    res: any,
    isUploadDocumentsCompleted: boolean
  ) {
    if (!res || res.error) {
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        //set stage api data to GlobalStore

        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );

        console.log(clientPartialDetails_ao, "clientPartialDetails_ao");

        if (!clientPartialDetails_ao.mobile) {
          clientPartialDetails_ao.mobile = responseObj.mobileno;
        }

        if (!clientPartialDetails_ao.emailID) {
          clientPartialDetails_ao.emailID = responseObj.emailid;
        }

        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );

        setStageData(responseObj);

        //check completeness of stages
        checkModuleCompleteness(isUploadDocumentsCompleted);

        if (responseObj.isdigilockercompleted) GlobalStore.isDigiLocker = true;
        //route according to stage api response
        if (
          (!responseObj.isdigilockercompleted &&
            GlobalStore.aadharLinkedChoice &&
            !GlobalStore.isDigiLocker) ||
          responseObj.iskyc === null ||
          responseObj.iskra === null
        )
          GlobalStore.nextIncompleteStage = containerUrl.dgLocker;
        else if (
          !(
            responseObj.isprofiledetailscompleted &&
            responseObj.ispersonaldetailscompleted
          )
        )
          GlobalStore.nextIncompleteStage = dashboardUrl.profile;
        else if (!responseObj.isbankdetailscompleted)
          GlobalStore.nextIncompleteStage = dashboardUrl.bank;
        else if (
          !responseObj.isbrokeragesegmentcompleted ||
          !responseObj.isbrokerageschemecompleted
        )
          GlobalStore.nextIncompleteStage = dashboardUrl.plan;
        else if (!isUploadDocumentsCompleted)
          GlobalStore.nextIncompleteStage = dashboardUrl.doc;
        else if (
          !GlobalStore.isDigiLocker &&
          !GlobalStore.isKRA &&
          !GlobalStore.isKYC &&
          !responseObj.isipvarificationcompleted
        )
          GlobalStore.nextIncompleteStage = dashboardUrl.ipv;
        else if (responseObj.isagentvisitcompleted)
          GlobalStore.nextIncompleteStage = containerUrl.ocv;
        else if (!responseObj.isesigncompleted)
          GlobalStore.nextIncompleteStage = dashboardUrl.eSign;
        else if (
          (responseObj.applicationstatus === 0 ||
            responseObj.bpm_pd_status === 2 ||
            responseObj.bpm_pd_status === 3) &&
          responseObj.isprofiledetailscompleted &&
          responseObj.ispersonaldetailscompleted &&
          responseObj.isbankdetailscompleted &&
          responseObj.isbrokerageschemecompleted &&
          isUploadDocumentsCompleted &&
          (GlobalStore.isDigiLocker ||
            GlobalStore.isKRA ||
            GlobalStore.isKYC ||
            responseObj.isipvarificationcompleted)
        ) {
          AGetEsignUrlDetails(getEsignUrl, GlobalStore.applicationID, 1);
        } else GlobalStore.nextIncompleteStage = containerUrl.poa;
      }
    }
    setIsLoading(false);
  }

  function getEsignUrl(res: any) {
    if (!res || res.error) {
      console.log(res.error);
      setIsLoading(false);
    } else {
      if (res.data.Data) {
        if (res.data.Data.ConvertToPhysical === true)
          history.push(statusUrl.docVerificationInProgress);
      } else {
        setIsLoading(false);
      }
    }
  }

  function createApplicationTicket(e?: any) {
    var message = "not refreshed";

    if ((window as any).refreshKeyPressed) {
      message = "refreshed";
    }
    console.log("message", message);

    if (message !== "refreshed") {
      const stageInfoId: number = parseInt(
        window.sessionStorage.getItem("stageInfoId") || ""
      );
      if (
        stageInfoId &&
        (stageInfoId === 1 ||
          stageInfoId === 2 ||
          stageInfoId === 5 ||
          stageInfoId === 6 ||
          stageInfoId === 7 ||
          stageInfoId === 9 ||
          stageInfoId === 10)
      ) {
        const appIdlocalStorage = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );

        let applicationId = "";
        let clientPrimaryDetailId = "";
        if (
          GlobalStore.applicationID != undefined ||
          GlobalStore.applicationID != ""
        ) {
          applicationId = GlobalStore.applicationID;
        } else if (
          appIdlocalStorage.applicationId != "" ||
          appIdlocalStorage.applicationId != undefined
        ) {
          applicationId = appIdlocalStorage.applicationId;
        }

        if (
          GlobalStore.clientPrimaryDetailId != undefined ||
          GlobalStore.clientPrimaryDetailId != ""
        ) {
          clientPrimaryDetailId = GlobalStore.clientPrimaryDetailId;
        } else if (
          appIdlocalStorage.clientPrimaryDetailId != "" ||
          appIdlocalStorage.clientPrimaryDetailId != undefined
        ) {
          clientPrimaryDetailId = appIdlocalStorage.clientPrimaryDetailId;
        }
        const stageInfoObj = {
          applicationid: applicationId,
          clientprimarydetailid: parseInt(clientPrimaryDetailId),
          stageinfoid: stageInfoId,
        };
        ACreateApplicationTicket(() => {}, stageInfoObj);
        e.returnValue = message;
        return message;
      }
    }

    // e.preventDefault();
    // window.alert("closing the window")
    // e.returnValue = '';
    // const stageInfoId: number = parseInt(window.sessionStorage.getItem('stageInfoId') || '');
    // if (
    //     stageInfoId &&
    //     (stageInfoId === 1 ||
    //         stageInfoId === 2 ||
    //         stageInfoId === 5 ||
    //         stageInfoId === 6 ||
    //         stageInfoId === 7 ||
    //         stageInfoId === 9 ||
    //         stageInfoId === 10)
    // ) {
    //     const stageInfoObj = {
    //         applicationid: GlobalStore.applicationID,
    //         clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
    //         stageinfoid: stageInfoId,
    //     };

    //     ACreateApplicationTicket(() => { }, stageInfoObj);
    // }
  }

  window.onbeforeunload = createApplicationTicket;

  //check for Navigation Timing API support
  if (window.performance) {
    console.info("window.performance works fine on this browser");
  }

  return (
    <div className="prflLayout">
      <ProfileHeader />
      <div className="prflBox">
        {isDesktop && (
          <div className="prflLayoutBar">
            <ProfileNavBar isLoading={isLoading} />
          </div>
        )}
        <div
          className={
            "prflLayoutForm" +
            (GlobalStore.stageStatus.applicationstatus === 1 &&
            GlobalStore.stageStatus.bpm_pd_status !== 2 &&
            history.location.pathname !== dashboardUrl.profiler &&
            history.location.pathname !== dashboardUrl.eSign
              ? " readOnly"
              : "")
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;
