import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";

export const GetErrorBank = (
  callback: CallableFunction,
) => {
  apiService(
    {
      url: "MastersData/Errormessages",
      body: {
        ErrorCode: null,
        ProductId: "1",
        SourceId: "1",
        Type: "C",
        Datasource: "FE",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getIPVOTP,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-ivp-otp",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};
