import * as React from 'react';
import './NriDetail.scss'
import DropdownSearch from '../../../UI/DropdownSearch/DropdownSearch';
import Tag from '../../../UI/Tag/Tag';
import { nriIdType } from '../../../utils/constants';
import InputField from '../../../UI/InputField/InputField';
import { INriDetailField } from '../NriDetailForm';
import { IListObj } from '../../../types/genericTypes';
import { errorCode, errorMsg } from '../../../../ErrorCode/ErrorCode';

export interface NriDetailProps {
    countryList: Array<IListObj>;
    index?: number;
    nriDetailsObj: INriDetailField;
    onChangeNriDetails: (obj: INriDetailField) => void;
    errors: { [key: string]: string | undefined };
    setErrors: (obj: { [key: string]: string | undefined }) => void

}

const NriDetail: React.FC<NriDetailProps> = (props) => {

    function onChange(key: keyof INriDetailField, value: string) {
        let obj: INriDetailField = { ...props.nriDetailsObj }
        obj[key] = value;
        props.onChangeNriDetails(obj);
    }

    function handleBlur(key: keyof INriDetailField, value: any) {
        typeof value !== 'object' ?
            props.setErrors({ ...props.errors, [key]: errorMsg(errorCode.FE20) }) :
            props.setErrors({ ...props.errors, [key]: undefined })
    }

    return (
        <div className=''>

            <div className='mgt40'>
                <label className='fieldLbl'>Country</label>
                <DropdownSearch
                    defaultValue={props.nriDetailsObj.country}
                    name={`country${props.index}`}
                    onOptionSelect={(val) => onChange('country', val['display'] as string)}
                    searchLength={0}
                    options={props.countryList}
                    onBlur={(val) => handleBlur('country', val)}
                    searchKey='display'
                    placeholder='Select the country'
                    noDataMsg="Country with similar name not found"
                    errorText={props.errors.country}
                    isDownArrow={true} />
            </div>

            <div className='mgt40'>
                <label className='fieldLbl'>Identification Type</label>
                <Tag
                    defaultValue={props.nriDetailsObj.idType}
                    tagList={nriIdType}
                    onSelect={(val) => onChange('idType', val as string)}
                    className='liMgt12'
                />
            </div>
            {
                props.nriDetailsObj.idType === 'Others' &&
                <div className='mgt40'>
                    <label className='fieldLbl'>Identification Type - Other</label>
                    <InputField
                        defaultValue={props.nriDetailsObj.idTypeOther}
                        onChange={(val) => onChange('idTypeOther', val)}
                        onBlur={error => props.setErrors({ ...props.errors, idTypeOther: error.required ? errorMsg(errorCode.FE23) : '' })}
                        placeholder='Eg. SPI Number'
                        errorText={props.errors.idTypeOther}
                        type='text'
                        required
                    />
                </div>
            }

            <div className='mgt40'>
                <label className='fieldLbl'>Identification Number</label>
                <InputField
                    defaultValue={props.nriDetailsObj.idTypeValue}
                    onChange={(val) => onChange('idTypeValue', val)}
                    onBlur={error => props.setErrors({ ...props.errors, idTypeValue: error.required ? errorMsg(errorCode.FE21) : '' })}
                    placeholder='Eg. XXX XX XXXX'
                    errorText={props.errors.idTypeValue}
                    type='text'
                    required
                />
            </div>
        </div>
    );
}

export default NriDetail;