import * as React from "react";
import InfoModal from "../../UI/InfoModal/InfoModal";
import DeviceContext from "../../Services/identifyDevice";
import "./DocStatusList.scss";
import AdhaarSample from "../../../assets/docSampleImages/Adhaar_Sample.png";
import DrivingLicense from "../../../assets/docSampleImages/DLSample.jpg";
import Passport from "../../../assets/docSampleImages/passportSample.jpg";
import VoterID from "../../../assets/docSampleImages/VoterID_Sample.jpg";

export interface DocStatusListProps {
  docName: string;
  //   docStatus: "Pending Upload" | "Pending Approval" | "Approved";
  docInfo: string;
}

const OptionDocumentList: React.FC<DocStatusListProps> = (props) => {
  const [showExplain, setShowExplain] = React.useState<boolean>(false);

  let InfomodalReturn = (doctype: string) => {
    switch (doctype) {
      case "Adhaar":
        return (
          <label>
            <img className="sampleImages" src={AdhaarSample} />
          </label>
        );
      case "Driving License":
        return (
          <label>
            <img className="sampleImages" src={DrivingLicense} />
          </label>
        );
      case "Voter Id":
        return (
          <label>
            <img className="sampleImages" src={VoterID} />
          </label>
        );
      case "Passport":
        return (
          <label>
            <img className="sampleImages" src={Passport} />
          </label>
        );
    }
  };

  return (
    <div className="docList">
      <label className="docName">{props.docName}</label>
      <div className="mgt40">
        <div
          className="fieldLblRight "
          onMouseOver={() => setShowExplain(true)}
          onMouseLeave={() => setShowExplain(false)}
        >
          <div className="explain">
            <label className="explainLbl SampleImageText">Explain</label>
            {showExplain && (
              <InfoModal width={DeviceContext().isMobile ? "328px" : "354px"}>
                <div className="explainInfo">
                  {InfomodalReturn(props.docName)}
                </div>
              </InfoModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OptionDocumentList;
