import React from 'react';
import './ProgressBar.scss'

export interface ProgressBarProps {
    percent: string;
    width: string;
    height: string;
    isBlueColor?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    return (
        <div className='progressBar' style={{ width: `${props.width}`, height: `${props.height}` }} >
            <div className={'bar' + (props.isBlueColor ? ' isBlue' : '')} style={{ width: `${props.percent}%` }}>
            </div>
        </div>
    );
}

export default ProgressBar;