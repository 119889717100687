import React from "react";
import { Redirect } from "react-router-dom";
import cleverTapEvents from "../../config/cleverTapEvents";
import useQuery from "../../customHooks/useQuery";
import { ClevertapService } from "../Services/clevertapService";
import gtmService from "../Services/gtmService";
import {
  ACreateLead,
  ACreateLeadSource,
  ASearchLeadByName,
  AWhatsappNotify,
} from "../Services/registration/registrationApiService";
import GlobalStore from "../store/globalStore";
import ErrMsg from "../UI/ErrMsg/ErrMsg";
import Loader from "../UI/Loader/Loader";
import { assignedTo, containerUrl, productCode } from "../utils/constants";
import { errorMessage } from "../utils/errorMessage";
import "./Marketing.scss";

const Marketing = () => {
  const queryParams = useQuery();
  let utm_source = "";
  let utm_medium = "";
  let utm_campaign = "";

  const [values, setValues] = React.useState({
    firstname: "",
    lastName: "",
    email: "",
    mobile: "",
    city: "",
  });
  const [whatsappMsgSuccess, setWhatsappMsgSuccess] =
    React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState("");
  const [isComplete, setIsComplete] = React.useState<boolean>(false);
  const [improperData, setImproperData] = React.useState<boolean>(false);

  React.useEffect(() => {
    utm_source = queryParams.get("utm_source") || "";
    utm_medium = queryParams.get("utm_medium") || "";
    utm_campaign = queryParams.get("utm_campaign") || "";
    const utm_term = queryParams.get("utm_term") || "";
    const utm_creative = queryParams.get("utm_creative") || "";
    const utm_adgroup = queryParams.get("utm_adgroup") || "";
    const utm_placement = queryParams.get("utm_placement") || "";
    const utm_device = queryParams.get("utm_device") || "";
    const utm_content = queryParams.get("utm_content") || "";
    const firstname = queryParams.get("fname") || "";
    const lastName = queryParams.get("lname") || "";
    const email = queryParams.get("email") || "";
    const mobile = queryParams.get("mobile") || "";
    const city = queryParams.get("city") || "";
    const utmParams = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_creative,
      utm_adgroup,
      utm_placement,
      utm_device,
      utm_content,
    };

    if (utm_source && utm_source.toUpperCase() === "LEADFORDSA")
      GlobalStore.leadForDsa = true;
    setUTMParams(utmParams);
    // init();

    if (
      firstname !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      city !== ""
    ) {
      setValues({ firstname, lastName, email, mobile, city });
    } else {
      setImproperData(true);
    }
  }, []);

  React.useEffect(() => {
    if (Object.values(values).every((value) => value !== "")) {
      handleSubmit();
    }
  }, [values]);

  // New Start

  const setUTMParams = (queryObject: any) => {
    if (Object.keys(queryObject).length > 0) {
      let objUTM_Info = {
        modeofapplication: "E",
        utm_source: queryObject.utm_source,
        utm_medium: queryObject.utm_medium,
        utm_term: queryObject.utm_term,
        utm_creative: queryObject.utm_creative,
        utm_campaign: queryObject.utm_campaign,
        utm_adgroup: queryObject.utm_adgroup,
        utm_placement: queryObject.utm_placement,
        utm_device: queryObject.utm_device,
        utm_content: queryObject.utm_content,
        referralcode: "",
        couponcode: "",
        stageinfo: "",
        signupdropout: "",
        registrationdur: "",
        devicetype: "",
        clientip: "",
        diysource: "W",
      };
      window.sessionStorage.setItem("objUTM_Info", JSON.stringify(objUTM_Info));
    } else {
      const utmObj = JSON.parse(
        window.sessionStorage.getItem("objUTM_Info") || "{}"
      );
      if (!utmObj && Object.keys(utmObj).length === 0) {
        let objUTM_Info = {
          modeofapplication: "E",
          utm_source: queryObject.utm_source,
          utm_medium: queryObject.utm_medium,
          utm_term: queryObject.utm_term,
          utm_creative: queryObject.utm_creative,
          utm_campaign: queryObject.utm_campaign,
          utm_adgroup: queryObject.utm_adgroup,
          utm_placement: queryObject.utm_placement,
          utm_device: queryObject.utm_device,
          utm_content: queryObject.utm_content,
          referralcode: "",
          couponcode: "",
          stageinfo: "",
          signupdropout: "",
          registrationdur: "",
          devicetype: "",
          clientip: "",
          diysource: "W",
        };
        window.sessionStorage.setItem(
          "objUTM_Info",
          JSON.stringify(objUTM_Info)
        );
      }
    }
  };

  // New End

  function handleSubmit() {
    if (Object.values(values).every((value) => value)) {
      GlobalStore.user = {
        firstname: values.firstname,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
        city: values.city,
        // rmCode: rmCode
      };
      if (whatsappMsgSuccess) {
        searchLeadByName();
      } else {
        const msgReq = {
          type: "optin",
          recipients: [
            {
              recipient: values.mobile,
              source: "edel-web",
              user_agent: navigator.appName,
            },
          ],
        };
        AWhatsappNotify(whatsappNotify, msgReq);
      }
    }
  }

  function whatsappNotify(res: any) {
    if (!res || res.error) {
      // setErrCode('101 - Failed to provide consent for WhatsApp Notifications.');
      // setErrCode(errorMessage.whatsAppNotify);
      setErrCode(res.error);
      setWhatsappMsgSuccess(false);
    } else {
      setWhatsappMsgSuccess(true);
      searchLeadByName();
    }
  }

  function searchLeadByName() {
    const objAccType =
      window.sessionStorage.getItem("objAccType") || productCode;
    const viewLeadObj = {
      assignedTo: assignedTo,
      emailId: values.email,
      firstName: values.firstname,
      lastName: values.lastName,
      mobile: values.mobile,
      productCode: objAccType === "DMT" ? "D" : "TD",
    };
    window.localStorage.setItem(
      "clientName",
      values.firstname.trim() + " " + values.lastName.trim()
    );
    GlobalStore.LeadIdMsg = "";
    ASearchLeadByName(searchedLead, viewLeadObj);
  }

  function searchedLead(res: any) {
    if (!res || res.error) {
      // setErrCode('102 - Failed to fetch existing Leads');
      //   setErrCode(errorMessage.searchLead);
      setErrCode(res.error);
    } else {
      setErrCode("");
      if (res.data) {
        let responseObj = JSON.parse(res.data);
        if (
          responseObj &&
          responseObj.leadList &&
          responseObj.leadList.length > 0
        ) {
          if (responseObj?.leadList[0]?.ApplicationId?.slice(0, 2) != "80") {
            GlobalStore.leadId = responseObj.leadList[0].LeadId;
            GlobalStore.LeadIdMsg = "duplicateLeadId";

            // dudpe implemetation from here //

            const objUtm = JSON.parse(
              window.sessionStorage.getItem("objUTM_Info") || "{}"
            );
            const objAccType =
              window.sessionStorage.getItem("objAccType") || productCode;
            GlobalStore.createLeadDudupeParam = null;

            const createLeadObj = {
              assignedTo: responseObj.leadList[0].AssignTeam,
              byPassDedupe: false,
              city: values.city,
              email: values.email,
              firstName: values.firstname,
              lastName: values.lastName,
              phone: values.mobile,
              productCode: objAccType === "DMT" ? "D" : "TD",
              // rmCode: rmCode,
              // sourceId: 0,
              // subSourceId: '1',
              utm_adgroup: objUtm.utm_adgroup || "",
              utm_campaign: objUtm.utm_campaign || "",
              sourceName: objUtm.utm_source || "",
              utm_content: objUtm.utm_content || "",
              utm_creative: objUtm.utm_creative || "",
              utm_device: objUtm.utm_device || "",
              utm_medium: objUtm.utm_medium || "",
              utm_placement: objUtm.utm_placement || "",
              utm_source: objUtm.utm_source || "",
              subSourceName: objUtm.utm_campaign || "",
              utm_term: objUtm.utm_term || "",
            };
            GlobalStore.createLeadDudupeParam = createLeadObj;

            // ends//
            window.localStorage.removeItem("clientPartialDetails_ao");

            gtmService({ action: "LEAD_PAGE", label: "Step_Complete" });
            setIsComplete(true);
          } else {
            createLead();
          }
        } else {
          createLead();
        }
      }
    }
  }

  function createLead() {
    const objUtm = JSON.parse(
      window.sessionStorage.getItem("objUTM_Info") || "{}"
    );
    const objAccType =
      window.sessionStorage.getItem("objAccType") || productCode;
    GlobalStore.createLeadDudupeParam = null;

    let createLead = {
      assignedTo: assignedTo,
      byPassDedupe: false,
      city: values.city,
      email: values.email,
      firstName: values.firstname,
      lastName: values.lastName,
      phone: values.mobile,
      productCode: objAccType === "DMT" ? "D" : "TD",
      // rmCode: rmCode,
      // sourceId: 0,
      // subSourceId: '1',
      utm_adgroup: objUtm.utm_adgroup || "",
      utm_campaign: objUtm.utm_campaign || "",
      sourceName: objUtm.utm_source || "",
      utm_content: objUtm.utm_content || "",
      utm_creative: objUtm.utm_creative || "",
      utm_device: objUtm.utm_device || "",
      utm_medium: objUtm.utm_medium || "",
      utm_placement: objUtm.utm_placement || "",
      utm_source: objUtm.utm_source || "",
      subSourceName: objUtm.utm_campaign || "",
      utm_term: objUtm.utm_term || "",
    };
    // ACreateLead((res: any) => leadCreated(res, createLead), createLead);
    ACreateLeadSource((res: any) => leadCreated(res, createLead), createLead);
  }

  function leadCreated(res: any, leadObj: any) {
    if (!res) {
      // setErrCode('103 - Error in generating new Lead.');
      // setErrCode(errorMessage.leadCreated);
      setErrCode(res.error);
    } else {
      if (res && res.data) {
        // New lead is created. We need to register with clevertap event
        const data = {
          EventName:
            cleverTapEvents.registration.personalDetailsPage.CLICK_ON_PROCEED,
          InitiationPlatform: "",
          utm_source,
          utm_medium,
          utm_campaign,
        };
        ClevertapService.pushEvent(data);
        // CleverTap event end

        let responseObj = JSON.parse(res.data);
        if (responseObj && responseObj.LeadId) {
          GlobalStore.leadId = responseObj.LeadId;
          GlobalStore.createLeadDudupeParam = leadObj;
          setErrCode("");
          window.localStorage.removeItem("clientPartialDetails_ao");
          gtmService({ action: "LEAD_PAGE", label: "Step_Complete" });
          setIsComplete(true);
        } else {
          setErrCode("Technical Issue");
        }
      } else {
        setErrCode("104 - Error in generating new Lead.");
        setErrCode(errorMessage.leadCreated);
      }
    }
  }

  return (
    <div className="marketing-page-banner op05">
      {isComplete && <Redirect to={`${containerUrl.pan}`} />}
      {!improperData && !errCode && <Loader size={100} color="blue" />}
      {improperData && (
        <div className="errMsgBlck">
          <ErrMsg>
            <label>Improper Data for Account Intitiation</label>
          </ErrMsg>
        </div>
      )}
      {/* Error Msg Start*/}
      {errCode && (
        <div className="errMsgBlck">
          <ErrMsg>
            <label>We are facing some technical issue.</label>
            <label>Please try after sometime (error code:{errCode})</label>
          </ErrMsg>
        </div>
      )}
      {/* Error Msg End*/}
    </div>
  );
};

export default Marketing;
