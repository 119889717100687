import React, { useState } from "react";
import {
  ACheckClientProfilingData,
  AGetClientProfilingQuestion,
  ASaveClientProfilingQuestion,
} from "../Services/dashboard/profilerApiServices";
import GlobalStore from "../store/globalStore";
import ErrMsg from "../UI/ErrMsg/ErrMsg";
import Radio from "../../components/UI/Radio/Radio";
import { MultiSelectCheckBox } from "../UI/Checkbox/Checkbox";
import "./Profiler.scss";
import { SubmitButton } from "../UI/Button/Button";
import { dashboardUrl, subsource_ID } from "../utils/constants";
import { useHistory } from "react-router-dom";
import {
  completeModuleStatus,
  incompleteStageNavigation,
} from "../Services/globalService";
import { isTemplateTail } from "typescript";
import Dropdown from "../UI/Dropdown/Dropdown";
import gtmService from "../Services/gtmService";
import cleverTapEvents from "../../config/cleverTapEvents";
import { ClevertapService } from "../Services/clevertapService";
import useQuery from "../../customHooks/useQuery";
import appsFlyerEvents from "../../config/appsFlyerEvents";
import { AppsFlyerService } from "../Services/appsFlyerService";
import config from "../../config/domain";

export interface OptionProps {
  option_ID: number;
  question_ID: number;
  isChecked?: boolean;
}

const Profiler = (props: any) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loaderSign, setLoaderSign] = React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<number | string>();
  const [checkClientProfilingData, setCheckClientProfilingData] =
    React.useState<boolean>(false);
  const [optionId, setOptionId] = React.useState<number>();
  const [singleAns, setSingleAns] = React.useState<Array<any>>([]);
  const [questionLists, setQuestionLists] = React.useState<any>();

  const [lists, setLists] = React.useState<Array<any>>([]);
  const history = useHistory();

  React.useEffect(() => {
    setIsLoading(true);
    ACheckClientProfilingData(
      getCheckClientkProfilingData,
      GlobalStore.applicationID,
      config.ClientProfilingDataSourseId
    );
  }, []);

  // Clervertap event: Profiler/Investment screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.investmentPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);
  }, []);

  const getCheckClientkProfilingData = (res: any) => {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode("180- Failed to fetch client profiling data.");
      setErrCode(res.error);
    } else if (res.data === false) {
      setIsLoading(false);
      setCheckClientProfilingData(res.data);
      if (!res.data) {
        AGetClientProfilingQuestion(
          getClientProfilingQuestion,
          GlobalStore.applicationID,
          //   sourceId
          config.clientQuesttionSourceId
        );
      }
    } else {
      setIsLoading(false);
    }
  };

  const getClientProfilingQuestion = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error)
    } else if (res.data) {
      const responseObj = JSON.parse(res.data);
      console.log("getClientProfilingQuestion responseObj", responseObj);
      if (responseObj) {
        setQuestionLists(responseObj.QuestionList);
      }
      const multiAnswerList = responseObj.QuestionList.filter(
        (x: any) => x.IsMultiple === "Y"
      );
      const remultiAnswerList = multiAnswerList.map((i: any, index: number) =>
        i.QuestionOptions.map((item: any) =>
          Object.assign({}, item, { checked: false })
        )
      );
      setLists([...remultiAnswerList]);
    }
  };

  const handleSelection = (e: any) => {
    const single = [...singleAns];
    let isPresent = singleAns.some(function (o: any) {
      return o["QuestionID"] === e.QuestionID;
    });
    if (!isPresent) {
      single.push(e);
    } else {
      const objIndex = singleAns.findIndex(
        (obj: any) => obj.QuestionID == e.QuestionID
      );
      single[objIndex] = e;
    }
    setSingleAns(single);
  };

  const handleSubmit = () => {
    const updatelist: any = [];
    const saveQuestion: any = [];

    const filterSingleAnswer = singleAns.filter((j: any) => j.QuestionID != 0);

    const filterMultiAnser = lists.map((item: any) =>
      updatelist.push(item.filter((x: any) => x.checked === true))
    );
    const mergedmultiAnswerObj = [].concat.apply([], updatelist);

    if (filterSingleAnswer.length != 0) {
      filterSingleAnswer.map((item: any) =>
        saveQuestion.push({
          clientId: GlobalStore.applicationID,
          createdBy: 0,
          optionId: item.OptionId,
          questionId: item.QuestionID,
          versions: 0,
          sourceId: config.clientQuesttionSourceId,
        })
      );
    }

    if (mergedmultiAnswerObj.length !== 0) {
      mergedmultiAnswerObj.map((item: any) =>
        saveQuestion.push({
          // prev request types

          //   client_ID: GlobalStore.applicationID,
          //   createdby: 0,
          //   option_ID: item.OptionId,
          //   question_ID: item.QuestionID,
          //   versions: 0,
          clientId: GlobalStore.applicationID,
          createdBy: 0,
          optionId: item.OptionId,
          questionId: item.QuestionID,
          versions: 0,
          sourceId: config.clientQuesttionSourceId,
        })
      );
    }

    ASaveClientProfilingQuestion(
      getSaveResonse,
      GlobalStore.applicationID,
      config.clientQuesttionSourceId,
      saveQuestion
    );
    setIsLoading(true);
  };

  const getSaveResonse = (res: any) => {
    if (!res || res.error) {
      // setErrCode("111 - Failed to fetch client profiling question details");
      setErrCode(res.error);
      setIsLoading(false);
    } else if (res.data) {
      setIsLoading(false);
      setLoaderSign(false);
      completeModuleStatus(dashboardUrl.profiler);
      gtmService({ action: "INVESTMENT_SETUP", label: "Step_Complete" });

      // Clevertap event: submit investment data
      const data = {
        EventName: cleverTapEvents.registration.investmentPage.CLICK_ON_SUBMIT,
        InitiationPlatform: "",
        UTM_SOURCE,
        UTM_MEDIUM,
        UTM_CAMPAIGN,
        KRA: GlobalStore.isKRA,
        DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
        ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
        LEAD_ID: GlobalStore.leadId,
        REF_ID: GlobalStore.applicationID,
      };

      ClevertapService.pushEvent(data);
      // Clevertap ends here

      // Apps flyer event for click on proceed on investmentPage details page
      AppsFlyerService.pushEvent(
        appsFlyerEvents.registration.investmentPage.CLICK_ON_SUBMIT
      );

      history.push(incompleteStageNavigation());
    }
  };

  const handleChange = (list: any, index: number) => {
    const newList = [...lists];
    newList[index] = list;
    setLists(newList);
  };

  return (
    <div className={"profilerLayout" + (isLoading ? " op05" : "")}>
      <form>
        <fieldset disabled={isLoading}>
          <div className="page_header">
            <div className="heading">
              <label>Additional Profile Information</label>
            </div>
            <div className="subHeading">
              <label>
                Help us understand and serve you better by answering some
                questions about your investment habits.
              </label>
            </div>
          </div>

          {questionLists &&
            questionLists.map((list: any, index: number) => {
              return (
                <div className="mrt40" key={index}>
                  <div className="questionHeading">
                    <label>{list.QuestionsDescription}</label>
                  </div>
                  {list.IsMultiple === "N" ? (
                    <div
                      className={
                        "answers " +
                        (index === questionLists.length - 1 &&
                        questionLists[questionLists.length - 1].IsMultiple
                          ? "last_ans"
                          : "")
                      }
                    >
                      <Dropdown
                        name={"quest" + list.QuestionId}
                        searchLength={0}
                        options={list.QuestionOptions}
                        searchKey="OptionDescription"
                        placeholder={
                          "Select" +
                          list.QuestionsDescription.replace("Your", "")
                        }
                        noDataMsg="Option not found"
                        isDownArrow={true}
                        className="profiler_dd"
                        onOptionSelect={(val) => handleSelection(val)}
                      />
                    </div>
                  ) : (
                    <div className="multi_select_list">
                      {lists &&
                        lists.map((multiOption: any, index: number) => {
                          if (
                            multiOption[index].QuestionID === list.QuestionId
                          ) {
                            return (
                              <div className="multiAnswers" key={index}>
                                <MultiSelectCheckBox
                                  list={multiOption}
                                  onChange={(list) => handleChange(list, index)}
                                />
                              </div>
                            );
                          }
                        })}
                    </div>
                  )}
                </div>
              );
            })}

          {/* Error Msg Start*/}
          {errCode && (
            <div className="errWidth mrt40">
              <ErrMsg>
                {typeof errCode === "number" ? (
                  <>
                    <label>We are facing some technical issue.</label>
                    <label>
                      Please try after sometime (error code:{errCode})
                    </label>
                  </>
                ) : (
                  <label>{errCode}</label>
                )}
              </ErrMsg>
            </div>
          )}
          {/* Error Msg End*/}

          <div className="submitProfiler">
            <SubmitButton
              isLoading={loaderSign}
              btnName="Submit"
              onClick={handleSubmit}
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default Profiler;
