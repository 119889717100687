import React, { Component } from "react";
import down from "../../../assets/icons/down.svg";
import "./Dropdown.scss";

export interface IProps {
    options: any;
    searchKey: string;
    placeholder?: string;
    noDataMsg?: string;
    isDownArrow?: boolean;
    name?: string;
    disabled?: boolean;
    errorText?: string;
    autoComplete?: string;
    searchLength: number;
    className?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    onOptionSelect?: (value: any) => void;
    onBlur?: (value: any) => void;
}

export interface IState {
    isShowSuggestions: boolean;
    value: string;
    selectedObj?: object | undefined;
}

class Dropdown extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isShowSuggestions: false,
            value: props.defaultValue || "",
            selectedObj: undefined
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps, prevState: IState) {
        if (nextProps.defaultValue !== prevState.value && nextProps.defaultValue !== undefined) {
            this.setState({ value: nextProps.defaultValue });
        }
    }

    onFocus = () => {
        if (this.props.searchLength === 0 || this.state.value.length >= this.props.searchLength) {
            this.setState({ isShowSuggestions: true });
        } else {
            this.setState({ isShowSuggestions: false });
        }
    };

    onChange = (value: any) => {
        this.setState({ value: value, selectedObj: undefined });
        this.props.onChange && this.props.onChange(value);
    };

    onOptionSelect = (item: any) => {
        this.setState({ value: item[this.props.searchKey], selectedObj: item, isShowSuggestions: false });
        if (this.props.onOptionSelect) {
            this.props.onOptionSelect(item);
        }
    };
    handleBlur = () => {
        setTimeout(() => {
            if (this.state.isShowSuggestions) {
                this.setState({ isShowSuggestions: false });
            }
            this.props.onBlur && this.props.onBlur(this.state.selectedObj);
        }, 300);
    };

    render() {
        const { isShowSuggestions } = this.state;
        let ddCount = 0;

        const noData = (
            <div className="dds_odiv">
                <div className="dds_oldiv">
                    <label className="dds_olabel">{this.props.noDataMsg}</label>
                </div>
            </div>
        );

        let showSuggestions = this.props.options.map((item: any, i: any) => {
            // const dropdownData = item[this.props.searchKey].replace(new RegExp(this.state.value, "i"), "<b>$&</b>");
            const dropdownData = item[this.props.searchKey];
            ddCount++;
            return (
                <div key={i} className="dds_odiv" onClick={() => this.onOptionSelect(item)}>
                    <div className="dds_oldiv">
                        <label className="dd_lbl">
                            {this.state.selectedObj === item ? <i className="edel-icon-tick"></i> : <i></i>}
                            <span className="dds_olabel" dangerouslySetInnerHTML={{ __html: dropdownData }}></span>
                        </label>
                    </div>
                </div>
            );
        });

        return (
            <div className={"dd_grp " + (isShowSuggestions ? "dd_open" : "")}>
                <input
                    name={this.props.name}
                    id={this.props.name}
                    type="text"
                    readOnly
                    className={"dds " + this.props.className + (this.props.errorText ? " error" : "")}
                    onChange={(e) => this.onChange(e.target.value)}
                    onBlur={this.handleBlur}
                    onFocus={this.onFocus}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    autoComplete={this.props.autoComplete ? this.props.autoComplete : "off"}
                    style={this.props.isDownArrow ? { backgroundImage: `url(${down})` } : {}}
                />
                {isShowSuggestions && (showSuggestions || this.props.noDataMsg) ? (
                    <div className="option_grp">{ddCount ? showSuggestions : noData}</div>
                ) : null}
                {this.props.errorText && <p className="help_text red">{this.props.errorText}</p>}
            </div>
        );
    }
}

export default Dropdown;
