export const errorMessage = {
  whatsAppNotify:
    "Problem in connecting with our server, refresh & resubmit your request",
  searchLead:
    "Your contact details are already registered with us. Same details are permitted upto 4 accounts. For the 5th account request, please try with new details.",
  leadCreated:
    "We are facing a network issue, request you to retry after sometime",
  employeeDataFailed: "Failed to fetch Employee Details.",
  citiesFailed: "We are facing slowness, request you to retry after sometime",
  otpSent: "Click on retry button to generate new OTP.",
  mobValidateOtp: "It seems  you have entered wrong OTP, re-enter correct OTP",
  enableCamera: "Enable Camera & Location to be access on your browser",

  //PanInfo page
  panDetailsFailed:
    "We faced a connectivity issue, request you to retry after sometime",
  panExist:
    "We faced a connectivity issue, request you to retry after sometime",
  validatePAN: "PAN details entered are invalid, retry with correct details",
  clientDetailsByPan: "Failed to fetch Pan details from our system",
  clientDetailsByPanServerDown: "Server Down, Please retry",
  searchByPan: " Failed to fetch Client details from our system",
  searchByLeadId: "Failed to fetch Client details from our system",
  getCkycNumber: "Failed to fetch CKYC Number",
  getCkycDetails: "Failed to fetch CKYC Details",
  dobNotMatchWithPan:
    "Date of Birth is not matching with the PAN, Please Select Corect DOB",
  applicationInitialized:
    "Failed to Application initialization, Please try Again",
  checkPendingUpload: "Failed to fetch pending document upload list",
  getRedisData: "Failed to fetch Digilocker document",
  getClientStageDetails: "Error in stage completion, Please try Again",
  maskedAadhar: "Error in  masking Aadhar, Please try Again",
  storeAadharData: "Failed to fetch Aadhar details, Please try Again",
  getUploadResponse: "Error in uploading the file, Please try Again",
  documentDetailsSaved: "Error in Save the Documents, Please try Again",
  getKraDetails: "Failed to fetch details from KRA portal",
  // imgNotMatchWithPan: 'Uploaded Image is not a Pan Card. Please check if you are uploading a Photo of Pan Card',
  imgNotMatchWithPan: "Document uploaded is invalid. Upload a Valid Pan Image",
  // profileForm page
  getEnumList: " Failed to fetch the category lists, please reload",
  getRelationshipData: "Failed to fetch relationship data, please reload",
  getAreaList: "Failed to fetch the area list for the pincode,please reload",
  getFullAddress: "Failed to fetch  pincode and area, please reload",
  getCorrespondingAreaList:
    "Failed to fetch the area list for the pincode, please reload",
  getCorrAddress:
    "Special Characters are not allowed in correspondence address",
  saveRelationshipData: "Please Select Family Relationship",
  saveKycDetails:
    "We faced a connectivity issue, retry resubmitting your details now",

  saveBankDetails:
    "We faced a connectivity issue, request you to retry after sometime",
  saveBrokerageSegDetailsRes:
    "We faced a connectivity issue, request you to retry after sometime",
  ApplicationInProcess: "Application is already in process with this pan",

  // New error message
  newEsignError:
    "We found delay in generating your application form due to which E-sign cannot be completed. You are requested to connect our support team for further processing the application.",
  newPanUtiError:
    "It seems Income Tax Website is facing intermittent connection issue",
  newDigilockerError:
    "There is delay in response from Digilocker Website. We suggest to opt for Non-Digilocker Option",
  newCkycError:
    "There is delay in response from CERSAI. We would skip the verification process to speed up your application",
  newKraError:
    "There is delay in response from KRA. We would skip the verification process to speed up your application",
};
