// ALL FUNCTIONS RETURN
// VALID - NULL
// INVALID - ERROR STRING

/**
import { integerExp } from './regex';
 * @param value The numerical value that needs to be checked for integer
 * @returns return null if VALID; error message if INVALID
 */
export function isValidRegex(value: string, regex: RegExp): string | null {
  return regex.test(value) ? null : "value is not integer";
}

/**
 * @param value The value that is required
 * @returns return null if value is present; error message if value is blank
 */
export function required(value: any): string | null {
  return value === undefined || value === null || value === ""
    ? "value is required"
    : null;
}

/**
 * @param value The value that is to be validated
 * @param min Value should be less than this number
 * @returns return null if value is not present or if value is >= min; error message if value < min
 */
export function grtrThanEqTo(value: number, min: number): string | null {
  return value === undefined || value === null || value >= min
    ? null
    : "value should be greater";
}

/**
 * @param value The value that is to be validated
 * @param max Value should be greater than this number
 * @returns return null if value is not present or if value is <= max; error message if value > max
 */
export function lessThanEqTo(value: number, max: number): string | null {
  return value === undefined || value === null || value <= max
    ? null
    : "value should be smaller";
}

/**
 * @param value The value to be validated
 * @param multiple Value should be multiple of this value
 * @returns return null if value is not present or if value is multiple of `multiple`; error message otherwise
 */
export function multipleValidation(
  value: number,
  multiple: number
): string | null {
  return value === undefined || value === null || value % multiple === 0
    ? null
    : "value is not a multiple of " + multiple;
}

/**
 * @param day The value to be validated
 * @param month The value to be validated imp: minus 1 in this becuase month is start from 0
 * @param year The value to be validated
 * @returns return true if date is valid; else return false
 */
export function dateValidation(
  year: number,
  month: number,
  day: number,
  isLessThanToday?: boolean
): boolean {
  const date = new Date(year, month, day);
  if (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day &&
    (!isLessThanToday || date < new Date())
  ) {
    return true;
  }
  return false;
}

/**
 * @param existingString The existing string which needs to be validate
 * @param length The minimum length the sting required
 * @returns return the string with append blank string with existing string to the length specified
 */
export function appendSpace(existingString: string, length: number) {
  for (let i = existingString.length || 0; i < length; i++) {
    existingString += " ";
  }
  return existingString;
}

export function repairAddressWithConditions(address: string, city: string) {
  address = address.trim();
  const addLength = address.length;

  let addLine1 = address.substring(0, 30);
  let addLine2 = "";
  let addLine3 = "";
  let addLine4 = "";

  if (addLine1.length < 5 || !isNaN(Number(addLine1.replace(/ /g, ""))))
    addLine1 = `${addLine1}`;

  if (addLength > 30) {
    addLine2 = address.substring(30, 60);
    if (addLine2.length < 5 || !isNaN(Number(addLine2.replace(/ /g, ""))))
      addLine2 = `${addLine2}`;
  }

  if (addLength > 60) {
    addLine3 = address.substring(60, 90);
    if (addLine3.length < 5 || !isNaN(Number(addLine3.replace(/ /g, ""))))
      addLine3 = `${addLine3}`;
  }

  if (addLength > 90) {
    addLine4 = address.substring(90, 140);
    if (addLine4.length < 5 || !isNaN(Number(addLine4.replace(/ /g, ""))))
      addLine4 = `${addLine4}`;
  }

  return { addLine1, addLine2, addLine3, addLine4 };
}
