import React from "react";
import InputField from "../../UI/InputField/InputField";
import TextArea from "../../UI/TextArea/TextArea";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import {
  betweenNChars,
  blockSpecChar,
  detectIntWithSpace,
} from "../../utils/regex";
import { IListObj } from "../../types/genericTypes";
import {
  AGetArea,
  AGetCityByPincode,
  AGetFullAddress,
} from "../../Services/dashboard/profileApiService";
import Checkbox from "../../UI/Checkbox/Checkbox";
import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";
import GlobalStore from "../../store/globalStore";

export interface AddressFieldProps {
  addressType: "permanent" | "correspondance" | "nominee" | "guardian";
  sectionLabel: string;
  addressObj: IAddressFormat;
  isDisable?: boolean;
  showSameAsAbove?: boolean;
  sameAsAbove?: boolean;
  handleSameAsAbove: (value: React.ChangeEvent<HTMLInputElement>) => void;
  setErrCode: (value: number | string) => void;
  onAddressChange: (addressObj: IAddressFormat) => void;
  onAddressBlur: (addressObj: {
    [key in keyof IAddressFormat]?: string;
  }) => void;
}

export interface IAddressFormat {
  addressLine1: string;
  City: string;
  Country: string;
  District: string;
  PinCode: string;
  State: string;
  Area?: string;
  CountryCode?: number;
  StateCode?: number;
}

export interface CkycAddressFormat {
  addressLine1: string;
  corrAddress?: string;
  sameAsPermanent: boolean;
  city: string;
  country: string;
  district: string;
  pincode: string;
  corrPincode?: string;
  state: string;
  area?: string;
  countrycode?: number;
  statecode?: number;
}

export const addressErrorObj = {
  addressLine1: undefined,
  pincode: undefined,
  area: undefined,
};

export const addressErrorMsg = {
  // addressLine1:
  //     'Please enter valid address. Address should contains minimum 5 characters and Address line should not contain  ~ ` # $ % ^ & * ( ) = + { } [ ] \\ | : ; ” ’ < > , ? /',
  addressLine1:
    "Special Characters are not allowed and Address should contains minimum 5 characters",
  // pincode: 'Please enter 6 digit pincode',
  pincode: "Enter 6 Digit Pin Code",
  // area: 'Please select a area from dropdown',
  area: "Type first 3 characters of city",
};

const AddressField: React.FC<AddressFieldProps> = (props) => {
  const [addressObj, setAddressObj] = React.useState<IAddressFormat>(
    props.addressObj
  );
  const [areaList, setAreaList] = React.useState<Array<IListObj>>([]);
  const [validPincode, setValidPincode] = React.useState<string>("");
  const [errors, setErrors] = React.useState<{
    [key in keyof IAddressFormat]?: string;
  }>({ ...addressErrorObj });

  React.useEffect(() => {
    if (
      JSON.stringify(addressObj) !== JSON.stringify(props?.addressObj) ||
      addressObj?.PinCode === props?.addressObj?.PinCode
    ) {
      setAddressObj(props.addressObj);
      if (
        props?.addressObj?.PinCode &&
        props?.addressObj?.PinCode?.length === 6
      ) {
        setAreaList([]);
        setValidPincode("");
        // console.log('Calling getArea fn from useEffect');
        // AGetArea((res: any) => {
        //   getAreaList(res, props.addressObj.PinCode);
        // }, props.addressObj.PinCode);
        AGetCityByPincode((res:any)=>getFullAddress(res),props.addressObj.PinCode)
      }
    }
  }, [
    props?.addressObj?.addressLine1 != addressObj?.addressLine1,
    props?.addressObj?.PinCode !== addressObj?.PinCode,
  ]);
  console.log(
    props?.addressObj?.addressLine1,
    " props?.addressObj?.addressLine1 check"
  );
  console.log(addressObj?.addressLine1, " addressObj.addressLine1 check");

  function handleChangeAddress(key: keyof IAddressFormat, value: string) {
    if (key === "PinCode" && value.length === 6) {
      setAreaList([]);
      // AGetArea((res: any) => {
      //   getAreaList(res, value);
      // }, value);
      AGetCityByPincode((res:any)=>getFullAddress(res),value)
      setErrors({ ...errors, [key]: undefined });
      setValidPincode("");
    }
    if (key === "Area") {
      AGetFullAddress(getFullAddress, value, addressObj?.PinCode);
      setErrors({ ...errors, [key]: undefined });
    }
    setAddressObj({ ...addressObj, [key]: value });
    props.onAddressChange({ ...addressObj, [key]: value });
    if (key === "PinCode" || key === "Area") {
      props.onAddressBlur({ ...errors, [key]: undefined });
    }

    if (key === "addressLine1") {
      console.log("key === addressLine1");
      if (detectIntWithSpace.test(value)) {
        // Address line should not contains only numbers
        setErrors({
          ...errors,
          addressLine1: errorMsg(errorCode.FE17),
        });
        props.onAddressBlur({
          ...errors,
          addressLine1: errorMsg(errorCode.FE17),
        });
      } 
      // else if (!blockSpecChar(5).test(value)) {
      //   console.log("Inside else if (!blockSpecChar(5).test(value))");
      //   // Check addressline have any special characters or not
      //   setErrors({ ...errors, addressLine1: errorMsg(errorCode.FE43) });
      //   props.onAddressBlur({
      //     ...errors,
      //     addressLine1: errorMsg(errorCode.FE43),
      //   });
      // } 
      else {
        // Need to clear
        console.log("else call");
        setErrors({ ...errors, addressLine1: undefined });
        props.onAddressBlur({ ...errors, addressLine1: undefined });
      }
    }
  }

  function getAreaList(res: any, pincode: string) {
    if (!res || res.error) {
      // props.setErrCode('130 - Failed to fetch the area list for the pincode');
      props.setErrCode(res.error);
    } else {
      if (res.data && res.data.areas) {
        // if (res.data.areas[0] !== "Area not exist.") {  // Rohit -- commented on 13-08-2022
        if (res.data.areas.length > 0) {
          let arr: Array<IListObj> = [];
          res.data.areas.forEach((item: string) => {
            let obj: IListObj = {
              value: item,
              display: item,
            };
            arr.push(obj);
          });
          setAreaList(arr);
          AGetFullAddress(getFullAddress, arr[0].value as string, pincode);
          setValidPincode("");
        } else {
          setValidPincode(errorMsg(errorCode.FE38));
        }
      } else {
        props.setErrCode(res.error);
      }
    }
  }

  function getFullAddress(res: any) {
    console.log("GetFullAddress", res);
    
    if (!res || res.error) {
      //   props.setErrCode(
      //     "131 - Failed to fetch details corresponding to pincode and area"
      //   );
      props.setErrCode(res.error);
    } else {
      if (res.data && res.data.length>0) {
        const obj: any = {};
        obj.PinCode = res.data[0].PinCode;
        obj.Area = res.data[0].City;
        obj.District = res.data[0].District;
        obj.Country = res.data[0].Country;
        obj.City = res.data[0].City;
        obj.State = res.data[0].State;
        obj.StateCode =res.data[0].StateCode;
        obj.CountryCode = res.data[0].CountryCode;
        setAddressObj({ ...props.addressObj, ...obj });
        props.onAddressChange({ ...props.addressObj, ...obj });
      } else {
        const obj: any = {};
        obj.Area = "";
        obj.District ="";
        obj.Country = "";
        obj.City = "";
        obj.State = "";
        obj.StateCode = "";
        obj.CountryCode = "";
        setAddressObj({ ...props.addressObj, ...obj });
        props.onAddressChange({ ...props.addressObj, ...obj });
        props.setErrCode(
          "131 - Failed to fetch details corresponding to pincode and area"
        );
      }
    }
  }

  function handleBlurAddress(key: keyof IAddressFormat, value: any) {
    switch (key) {
      case "addressLine1":
        setErrors({
          ...errors,
          addressLine1: value.pattern ? undefined : errorMsg(errorCode.FE43),
        });
        props.onAddressBlur({
          ...errors,
          addressLine1: value.pattern ? undefined : errorMsg(errorCode.FE43),
        });
        break;
      case "PinCode":
        setErrors({
          ...errors,
          PinCode: value.pattern ? undefined : errorMsg(errorCode.FE18),
        });
        props.onAddressBlur({
          ...errors,
          PinCode: value.pattern ? undefined : errorMsg(errorCode.FE18),
        });
        break;
      case "Area":
        if (typeof value === "object") {
          setErrors({ ...errors, Area: undefined });
          props.onAddressBlur({ ...errors, Area: undefined });
        } else {
          setErrors({ ...errors, Area: errorMsg(errorCode.FE44) });
          props.onAddressBlur({ ...errors, Area: errorMsg(errorCode.FE44) });
        }
        break;
    }
  }

  return (
    <>
      <div className="mgt30">
        <label className="sectionLbl">{props.sectionLabel}</label>
        {props.showSameAsAbove && (
          <label className="sectionLbl">
            <label className="fieldLblRight">Same as above</label>
            <div className="crrAddCheckBox fieldLblRight">
              <Checkbox
                checked={props.sameAsAbove || false}
  
                name={`sameAs${props.addressType}`}
                onChange={props.handleSameAsAbove}
              />
            </div>
          </label>
        )}
      </div>
      {(!(props.addressType === "correspondance") || !props.sameAsAbove) && (
        <>
          <div className="mgt30">
            <label className="fieldLbl">Address</label>
            <TextArea
              // onBlur={event => {
              //     console.log("event", event);
              //     // handleBlurAddress('addressLine1', error)
              // }}
              // addressAlphaNum
              defaultValue={addressObj?.addressLine1}
              placeholder="Eg. Address"
              onChange={(value) => handleChangeAddress("addressLine1", value)}
              disabled={props.isDisable}
              errorText={errors.addressLine1}
              name={`address${props.addressType}`}
              maxLength={140}
            />
          </div>

          <div className="mgt40">
            <label className="fieldLbl">Pin Code</label>
            <InputField
              pattern={betweenNChars(6)}
              onBlur={(error) => handleBlurAddress("PinCode", error)}
              defaultValue={addressObj?.PinCode}
              placeholder="Eg. 123456"
              onChange={(value) => handleChangeAddress("PinCode", value)}
              disabled={props.isDisable}
              integer
              maxLength={6}
              errorText={errors.PinCode || validPincode}
              name={`pinCode${props.addressType}`}
            />
          </div>

          {/* <div className="mgt40">
            <label className="fieldLbl">Area</label>
            <DropdownSearch
              name={`area${props.addressType}`}
              defaultValue={addressObj?.Area}
              disabled={addressObj?.PinCode?.length < 6 || areaList.length < 1}
              onOptionSelect={(value) =>
                handleChangeAddress("Area", value["value"] as string)
              }
              searchLength={0}
              options={areaList}
              onBlur={(val) => handleBlurAddress("Area", val)}
              searchKey="display"
              placeholder="Eg. Thane"
              noDataMsg="Area with similar name not found"
              errorText={errors.Area}
              isDownArrow={true}
            />
          </div> */}

          <div className="mgt40">
            <label className="fieldLbl">City/State</label>
            <InputField
              defaultValue={
                addressObj?.City || addressObj?.State
                  ? addressObj?.City + " / " + addressObj?.State
                  : ""
              }
              placeholder="Eg. Mumbai/Maharashtra"
              disabled={true}
              type="text"
              name={`cityState${props.addressType}`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AddressField;
