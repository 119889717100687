import React from "react";
import NavBarTile from "./NavBarTile/NavBarTile";
import "./ProfileNavBar.scss";
import { useHistory, useLocation } from "react-router-dom";
import GlobalStore from "../../store/globalStore";
import DeviceContext from "../../Services/identifyDevice";
import { compareValues } from "../../utils/sortingUtils";
import {
  dashboardUrl,
  ILeftNavBarArray,
  ipvNavBarObj,
  leftNavBarArray,
} from "../../utils/constants";
import { completeModuleStatus } from "../../Services/globalService";

export interface ProfileNavBarProps {
  isLoading?: boolean;
}

export function checkModuleCompleteness(isUploadDocumentsCompleted: boolean) {
  if (GlobalStore.stageStatus.isaddressstagecompleted) {
    completeModuleStatus(dashboardUrl.personalIfo);
  }
  if (
    GlobalStore.stageStatus.isprofiledetailscompleted &&
    GlobalStore.stageStatus.ispersonaldetailscompleted
  )
    completeModuleStatus(dashboardUrl.profile);
  if (isUploadDocumentsCompleted) completeModuleStatus(dashboardUrl.doc);
  if (GlobalStore.stageStatus.isbankdetailscompleted)
    completeModuleStatus(dashboardUrl.bank);
  if (
    GlobalStore.stageStatus.isbrokeragesegmentcompleted &&
    GlobalStore.stageStatus.isbrokerageschemecompleted
  )
    completeModuleStatus(dashboardUrl.plan);
  if (GlobalStore.stageStatus.isipvarificationcompleted) {
    if (GlobalStore.leftNavBarState.length === leftNavBarArray.length)
      GlobalStore.leftNavBarState.push(ipvNavBarObj);
    completeModuleStatus(dashboardUrl.ipv);
  }
  if (GlobalStore.stageStatus.isesigncompleted)
    completeModuleStatus(dashboardUrl.eSign);
}

const ProfileNavBar: React.FC<ProfileNavBarProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [urlPath, setUrlPath] = React.useState<string>();
  const [leftNavBarState, setLeftNavBarState] = React.useState<
    ILeftNavBarArray[]
  >(GlobalStore.leftNavBarState);

  React.useEffect(() => {
    setLeftNavBarState(GlobalStore.leftNavBarState)
    if (DeviceContext().isDesktop) {
      setUrlPath(location.pathname);
      GlobalStore.dashboardActivetUrl = location.pathname;
    }
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    if (GlobalStore.applicationID || clientPartialDetails_ao.applicationId) {
      if (
        GlobalStore.isKRA ||
        clientPartialDetails_ao.isKRA ||
        GlobalStore.isKYC ||
        clientPartialDetails_ao.isKYC ||
        GlobalStore.isDigiLocker ||
        clientPartialDetails_ao.isDigiLocker
      ) {
        const filteredLeftNavBar = GlobalStore.leftNavBarState.filter(
          (item) => item.name !== "Personal Documents"
        );
        if (filteredLeftNavBar && filteredLeftNavBar.length > 0) {
          setLeftNavBarState(filteredLeftNavBar);
          GlobalStore.leftNavBarState=filteredLeftNavBar
        }
      }
    }
  }, [location.pathname]);

  function onNavTileClick(navUrl: string, index: any) {
    const sidebarOptions = leftNavBarState.sort(compareValues("priority"));
    if (
      index !== 0 &&
      sidebarOptions[index - 1] &&
      !sidebarOptions[index - 1]["isComplete"]
    ) {
      return false;
    }
    GlobalStore.dashboardActivetUrl = navUrl;
    setUrlPath(navUrl);
    history.push(navUrl);
  }
  console.log(GlobalStore.leftNavBarState, "GlobalStore.leftNavBarState works");
  console.log(leftNavBarState, "state works");

  return (
    <div className="prflNavBar">
      {leftNavBarState.sort(compareValues("priority")).map((tile, index) => (
        <NavBarTile
          key={index}
          isLoading={props.isLoading}
          name={tile.name}
          time={tile.time}
          isActive={
            urlPath === tile.navUrl ||
            GlobalStore.dashboardActivetUrl === tile.navUrl
          }
          isComplete={tile.isComplete}
          navUrl={tile.navUrl}
          logo={tile.logo}
          isClickable={
            index === 0
              ? true
              : leftNavBarState.sort(compareValues("priority"))[index - 1] &&
                leftNavBarState.sort(compareValues("priority"))[index - 1][
                  "isComplete"
                ]
              ? true
              : false
          }
          onNavTileClick={() => onNavTileClick(tile.navUrl, index)}
        />
      ))}
    </div>
  );
};

export default ProfileNavBar;
