import { apiService } from "../apiServices";
import config from "../../../config/domain";
import servicesList from "../serviceList";

export const AGetEnumList = (callback: CallableFunction, enumname: string) => {
  apiService(
    {
      url: "MastersData/GetEnumList",
      method: "POST",
      body: {
        EnumName: enumname,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getEnumList,
    },
    callback
  );

  // apiService(
  //     {
  //         url: 'eco/get-enum-list',
  //         body: {
  //             "enumname": enumname
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const ARedisDigilockerDoc = (
  callback: CallableFunction,
  reqObj: object
) => {
  apiService(
    {
      url: "DIYAPI/RedisDigilockerDoc",
      method: "POST",
      body: reqObj,
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.redisDigilockerDoc,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/redisDigilockerDoc",
  //     body: reqObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetOcrResponseDetails = (
  callback: CallableFunction,
  reqObj: any
) => {
  apiService(
    {
      url: "DIYAPI/GetOCRDocumentResponseData",
      method: "POST",
      body: {
        ApplicationId: reqObj.applicationid,
        DocumentCategory: reqObj.documentcategory,
        DocumentId: reqObj.documentid,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getOCRDocumentResponseData,
    },
    callback
  );

  // apiService(
  //   {
  //     url: "eco/get-ocr-response-details",
  //     body: reqObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetDocumentidByApplicationid = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: applicationid,
        InputDataType: 5,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-documentid-by-applicationid",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetArea = (callback: CallableFunction, pinCode: string) => {
  apiService(
    {
      url: "MastersData/GetAreaorFullAddress",
      body: {
        Pincode: pinCode,
        Area: "",
        InputDataType: 1,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getArea,
    },
    callback
  );

  // apiService(
  //     {
  //         url: 'eco/get-area',
  //         body: {
  //             "pinCode": pinCode
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};
export const ACheckRAAccount = (
  callback: CallableFunction,
  checkRAAccountPayloadObj: any
) => {
  apiService(
    {
      url: "DIYAPI/CheckRAAccount",
      body: {
        EmailId: checkRAAccountPayloadObj?.EmailId,
        MobileNo: checkRAAccountPayloadObj?.MobileNo,
        PanNo: checkRAAccountPayloadObj?.PanNo,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.CheckRAAccount,
    },
    callback
  );
};
export const AGetRelationshipData = (
  callback: CallableFunction,
  applicationid: string,
  email: string,
  mobile: string
) => {
  apiService(
    {
      url: "MastersData/GetRelationshipData",
      body: {
        ApplicationId: applicationid,
        Email: email,
        Mobile: mobile,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getRelationshipData,
    },
    callback
  );

  // apiService(
  //     {
  //         url: 'eco/get-relationship-data',
  //         body: {
  //             "applicationid": applicationid,
  //             "email": email,
  //             "mobile": mobile
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const ASaveRelationshipData = (
  callback: CallableFunction,
  saveRelationshipObj: any
) => {
  apiService(
    {
      url: "DIYAPI/SaveRelationshipDetails",
      body: {
        ApplicationId: saveRelationshipObj.applicationid,
        MobileMatchedList: saveRelationshipObj.mobileMatchedList,
        EmailMatchedList: saveRelationshipObj.emailMatchedList,
        IsSavedRelationship: true,
        IsRelationshipFromIbeats: true,
        ClientPrimaryDetailId: saveRelationshipObj.clientprimarydetailid,
        EmpCode: saveRelationshipObj.empcode,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveRelationshipDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-relationship-page",
  //     body: saveRelationshipObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetFullAddress = (
  callback: CallableFunction,
  area: string,
  pinCode: string
) => {
  // console.log(area);
  apiService(
    {
      url: "MastersData/GetAreaorFullAddress",
      body: {
        Pincode: pinCode,
        Area: area,
        InputDataType: 2,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getFullAddress,
    },
    callback
  );

  // apiService(
  //     {
  //         url: 'eco/get-fulladdress',
  //         body: {
  //             "area": area,
  //             "pinCode": pinCode,
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const AGetKycDetails = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetKycDetails",
      body: {
        ApplicationId: applicationid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getKYCDetails,
    },
    callback
  );

  // apiService(
  //     {
  //         url: 'eco/get-kyc-details',
  //         body: {
  //             applicationid: applicationid
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const ASaveKycDetails = (callback: CallableFunction, kycObj: object) => {
  apiService(
    {
      url: "DIYAPI/SavePersonalProfileDetails",
      body: kycObj,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveKYCDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-kyc-details",
  //     body: kycObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const ASavePinCity = (callback: CallableFunction, areaObj: any) => {
  apiService(
    {
      url: "MastersData/GetAreaorFullAddress",
      body: {
        Pincode: areaObj.pincode,
        Area: "",
        City:areaObj.city,
        InputDataType: 4,
        State:areaObj.state
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveArea,
    },
    callback
  );
  }
  export const AGetCityByPincode = (callback: CallableFunction, pincode: string) => {
    apiService(
      {
        url: "MastersData/GetAreaorFullAddress",
        body: {
          Pincode: pincode,
          Area: "",
          City:"",
          InputDataType: 3,
          State:""
        },
        method: "POST",
        apiBaseUrl: config.diyNewApiUrl,
        tempToken: config.tempToken,
        serviceName: servicesList.getCityByPincode,
      },
      callback
    );
    }
