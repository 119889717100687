import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";

export const AGetIpvOtp = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetIPVOTP",
      body: {
        ApplicationId: applicationid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getIPVOTP,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-ivp-otp",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AUploadIpv = (callback: CallableFunction, uploadObj: any) => {
  apiService(
    {
      url: "DIYAPI/UploadIPVMerge",
      body: {
        ClientPrimaryDetailId: uploadObj.clientprimarydetailid,
        ApplicationId: uploadObj.applicationid,
        LeadId: uploadObj.leadid,
        ImageName: uploadObj.imageName,
        Image: uploadObj.image,
        ContentType: uploadObj.contentType,
        PanNo: uploadObj.panno,
        IsUpload: 1,
        UtmInfoDetails: uploadObj,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.uploadIPVMerge,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/UploadIPVMerge",
  //     body: uploadObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AUploadIpvSafari = (
  callback: CallableFunction,
  uploadObj: any
) => {
  console.log("AUploadIpvSafari called");

  apiService(
    {
      url: "DIYAPI/ImagetoVideoIpv",
      body: {
        ClientPrimaryDetailId: uploadObj.clientprimarydetailid,
        ApplicationId: uploadObj.applicationid,
        LeadId: uploadObj.leadid,
        ContentType: uploadObj.contentType,
        PanNo: uploadObj.panno,
        image64: uploadObj.image64,
        Width: uploadObj.width,
        Height: uploadObj.height,
        IsUpload: 1,
        UtmInfoDetails: "",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.imagetoVideoIpv,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/ImagetoVideoIpv",
  //     body: uploadObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ASendSms = (callback: CallableFunction, uploadObj: any) => {
  apiService(
    {
      url: "DIYAPI/SelfieLink",
      body: {
        MobNo: uploadObj.mobNo,
        SelfieLink: uploadObj.selfieLinkText,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.selfieLink,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/selfielink",
  //     body: uploadObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ASaveIPVerificationDetails = (
  callback: CallableFunction,
  uploadObj: any
) => {
  apiService(
    {
      url: "DIYAPI/SaveIpverificationDetailsVer1",
      body: {
        LeadId: uploadObj.leadid,
        ClientPrimaryDetailId: uploadObj.clientprimarydetailid,
        ApplicationId: uploadObj.applicationid,
        PlaceOfDeclaration: uploadObj.placeofDeclaration,
        EmpName: uploadObj.empName,
        Designation: uploadObj.designation,
        Branch: uploadObj.branch,
        EmpCode: uploadObj.empCode,
        Organization: uploadObj.organization,
        OrganizationCode: uploadObj.organizationCode,
        GeoLocation: uploadObj.geo_location,
        IpAddress: uploadObj.ipaddress,
        Is_AllDocUploaded: true,
        IpvLink: uploadObj.ipvlink ? uploadObj.ipvlink : "",
        latitude: uploadObj.latitude,
        longitude: uploadObj.longitude,
        source:uploadObj.source
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveIpverificationDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-ipverification-details",
  //     body: uploadObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};
