import * as React from "react";
import ExitScreen from "../UI/ExitScreen/ExitScreen";
import { IListObj } from "../types/genericTypes";
import { AGetAgentVisitDetails } from "../Services/dashboard/eSignApiServices";
import GlobalStore from "../store/globalStore";
import { stringToDate } from "../Services/globalService";
import ocvCalender from "../../assets/icons/ocvCalenderNew.jpg";
import cleverTapEvents from "../../config/cleverTapEvents";
import { ClevertapService } from "../Services/clevertapService";
import useQuery from "../../customHooks/useQuery";
import { AppsFlyerService } from "../Services/appsFlyerService";
import appsFlyerEvents from "../../config/appsFlyerEvents";
export interface OcvScheduledProps {}

const OcvScheduled: React.FC<OcvScheduledProps> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const [visitDate, setVisitDate] = React.useState<string>("");
  const [visitTime, setVisitTime] = React.useState<string>("");
  const [displayInfoList, setDisplayInfoList] = React.useState<Array<IListObj>>(
    []
  );

  React.useEffect(() => {
    AGetAgentVisitDetails(agentVisitDetails, GlobalStore.applicationID);
  }, []);

  // Clevertap event while final page load
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.finalPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE: UTM_SOURCE,
      UTM_MEDIUM: UTM_MEDIUM,
      UTM_CAMPAIGN: UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
      STATUS_ON_FINAL_SCREEN: "AGENT VISIT SCHEDULED",
    };

    ClevertapService.pushEvent(data);
    // Apps flyer event for When Final Status screen is viewed
    AppsFlyerService.pushEvent(
      appsFlyerEvents.registration.finalPage.PAGE_LOAD
    );
  }, []);
  // Clevertap ends

  function agentVisitDetails(res: any) {
    if (!res || res.error) {
    } else {
      if (res.data) {
        let responseObj = JSON.parse(JSON.parse(res.data));
        if (responseObj && responseObj.date_for_visit && responseObj.dpid) {
          let arr: Array<IListObj> = [
            {
              display: "Date & Time",
              value: `${stringToDate(responseObj.date_for_visit)}. ${
                responseObj.time_for_visit
              }`,
            },
            { display: "Name", value: GlobalStore.personaldetails.PANName },
            { display: "Email ID", value: GlobalStore.user.email },
            {
              display: "Mobile Number",
              value: `+91 ${GlobalStore.user.mobile}`,
            },
            {
              display: "Address",
              value: `${responseObj.address} ${responseObj.area} ${responseObj.city}  ${responseObj.state} ${responseObj.pincode}`,
            },
          ];
          setDisplayInfoList(arr);
          setVisitDate(stringToDate(responseObj.date_for_visit));
          setVisitTime(responseObj.time_for_visit);
        }
      }
    }
  }

  return (
    <ExitScreen
      heading="Agent Visit Scheduled"
      info={`An agent would visit your address on ${visitDate} between ${visitTime}. Keep all your original documents ready for verification`}
      displayInfoList={displayInfoList}
      logo={ocvCalender}
    />
  );
};

export default OcvScheduled;
