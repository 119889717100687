import React from "react";
import "./EdelLogoCenter.scss";
import logo from "../../../assets/icons/edel_icons/Nuvama_Identity_RGB_Positive.jpg";

const EdelLogoCenter = () => {
  return (
    <div className="edelLogoLabel">
      <div className="headerLogo">
        {/* <span className="edel-icon-logo-label-new   " /> */}
        <img className="Logo-main" src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default EdelLogoCenter;
