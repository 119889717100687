import React, { useState } from "react";
import { IListInfoObj } from "../../types/genericTypes";
import './Tag.scss'

export interface ITagProps {
    defaultValue?: number | string; // default selected value
    tagList: Array<IListInfoObj>; // list of all tags
    className?: string; // extra css class if needed
    onSelect: (id: string | number) => void; // the function to be called onSelect
    bool?: { lhs: any; rhs: any; className: string };//rhs will be checked with tag value
}

const Tag: React.FC<ITagProps> = (props: ITagProps) => {
    /**
     * state and function for selectedValue
     * selectedValue type is number | string
     * setSelectedValue(number | string)
     */
    const [selectedValue, setSelectedValue] = useState(props.defaultValue);

    React.useEffect(() => {
        setSelectedValue(props.defaultValue);
    }, [props.defaultValue]);
    /**
     * called when user selects a tag
     * @param value id of the selected tag
     * @returns onSelect prop passed from parent
     */
    function tagSelected(value: string | number) {
        setSelectedValue(value);
        return props.onSelect(value);
    }

    return (
        <ul className={"tag_navigation_content " + (props.className ? props.className : '')}>
            {props.tagList.map((tag) => (
                <li className={"tag" + (tag.value.toString() === (selectedValue && selectedValue.toString()) ? " active" : "") + (props.bool && props.bool.lhs && props.bool.rhs === tag.value ? props.bool.className : "")}
                    key={tag.value}
                    onClick={() => tagSelected(tag.value)}>
                    {tag.display}
                </li>
            ))}
        </ul>
    );
};

export interface IEsignTagProps extends ITagProps {
    isInfo?: boolean;
}

export const EsignTag: React.FC<IEsignTagProps> = (props) => {

    const [selectedValue, setSelectedValue] = useState(props.defaultValue);

    React.useEffect(() => {
        setSelectedValue(props.defaultValue);
    }, [props.defaultValue]);

    function tagSelected(value: string | number) {
        setSelectedValue(value);
        return props.onSelect(value);
    }

    return (
        <ul className="esignTagList">
            {props.tagList.map((tag) => {
                return (
                    <li className={"esignTag" + (tag.value === selectedValue ? ' activeEsignTag' : '')}
                        key={tag.value}
                        onClick={() => tagSelected(tag.value)}
                    >
                        {props.isInfo &&
                            <span className='tagInfo'>{tag.info}</span>
                        }
                        <span className='tagDisplay'>
                            {tag.display}
                        </span>
                    </li>
                )
            })}
        </ul>
    );
}

export default Tag;