import React from 'react';
import { Route } from 'react-router-dom';
import Marketing from '../components/Marketing/Marketing';

const MarketingRoute = () => (
    <Route path="/marketing" exact>
        <Marketing />
    </Route>
);

export default MarketingRoute;
