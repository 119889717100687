import React, { Component } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import regbanner from '../../../assets/icons/verificationInProgress.png';
import Button from '../../UI/Button/Button';

import './DocsVerificationInProgress.scss';

class DocsVerificationInProgress extends Component<RouteChildrenProps>  { 
    render() {
       
        return (
            <div className="docs-pending-root">
                <img src={regbanner} className="verified-img" />
                <div>
                    <h2 className="status-heading">Document Verification in Process</h2>
                    <span className="status-subHead">
                    Your documents are getting verified by our <br/>
                     agents.This process usually take 1-2 <br/> business days
                    </span>
                   <div className="notes">We will notify you of the update via <span className="email-text">WhatsApp/Email</span></div>
                </div>
            </div>
        )
    }
}

export default DocsVerificationInProgress
