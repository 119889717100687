import * as React from "react";
import {
  AValidatePoaServicableCityPincode,
  ASavePoaDetails,
  ADownloadForm,
} from "../Services/dashboard/eSignApiServices";
import GlobalStore from "../store/globalStore";
import ocvCalender from "../../assets/icons/ocvCalenderNew.jpg";
import ProfileImage from "../UI/ProfileImage/ProfileImage";
import { IAddressFormat } from "../Profile/AddressField/AddressField";
import { initialAddressObj } from "../Profile/ProfileForm/ProfileForm";
import Button, { Verify } from "../UI/Button/Button";
import { integerExp } from "../utils/regex";
import "./Poa.scss";
import "../ESign/ScheduleAgentVisit/ScheduleAgentVisit.scss";
import AgentVisitForm from "../ESign/ScheduleAgentVisit/AgentVisitForm/AgentVisitForm";
import {
  ecomAgentTimeList,
  edelweissOfficeName,
  edelweissOfficeAddress,
  containerUrl,
} from "../utils/constants";
import ErrMsg from "../UI/ErrMsg/ErrMsg";
import { useHistory } from "react-router-dom";
import { stringToDate, base64toBlob } from "../Services/globalService";
import cleverTapEvents from "../../config/cleverTapEvents";
import { ClevertapService } from "../Services/clevertapService";
import useQuery from "../../customHooks/useQuery";
import { AppsFlyerService } from "../Services/appsFlyerService";
import appsFlyerEvents from "../../config/appsFlyerEvents";
export interface PoaProps {}

export const downloadForm = (
  documentName: string,
  setIsLoading: Function,
  setErrCode: Function,
  isFullPdf?: boolean
) => {
  setIsLoading(true);
  setErrCode("");
  ADownloadForm(
    (res: any) => getDownloadForm(res, documentName, setIsLoading, setErrCode),
    GlobalStore.applicationID,
    isFullPdf || false
  );
};

function getDownloadForm(
  res: any,
  documentName: string,
  setIsLoading: Function,
  setErrCode: Function
) {
  if (!res || res.error) {
    setErrCode("7345 - Failed to download form");
  } else {
    if (res.data) {
      const base64 = res.data;
      if (base64 && base64 !== "") {
        const blob = base64toBlob(base64, "application/pdf");
        const link: HTMLAnchorElement = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = documentName;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        setErrCode("7345 - Failed to download form");
      }
    }
  }
  setIsLoading(false);
}

const Poa: React.FC<PoaProps> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<number | string>();
  const [enablePinCheck, setEnablePinCheck] = React.useState<boolean>(false);
  const [clientAddressOcv, setClientAddressOcv] =
    React.useState<IAddressFormat>({ ...initialAddressObj });
  const [ocvDate, setOcvDate] = React.useState<string>("");
  const [ocvTime, setOcvTime] = React.useState<string>("");
  const [showAgentVisitForm, setShowAgentVisitForm] =
    React.useState<boolean>(false);
  const [showDownload, setShowDownload] = React.useState<boolean>(true); // for disabling POA scheduling default value is true
  const [disablePOASchedule, setDisablePOASchedule] = React.useState<boolean>(true); // disable POA download
  const history = useHistory();

  function checkPincodeServiceability() {
    setIsLoading(true);
    setShowAgentVisitForm(false);
    AValidatePoaServicableCityPincode(
      validatePincode,
      clientAddressOcv.PinCode
    );
  }

  function validatePincode(res: any) {
    if (!res || res.error) {
      setErrCode("926 - Failed to check servicability of the pincode");
    } else {
      if (res.data && res.data.state && res.data.drop_Vendor_Code) {
        setShowDownload(false);
        setShowAgentVisitForm(true);
      } else {
        setShowDownload(true);
        setTimeout(() => {
          document.getElementById("addressInfoId")?.scrollIntoView({
            behavior: "smooth",
          });
        }, 300);
        setShowAgentVisitForm(false);
      }
    }
    setIsLoading(false);
  }

  function pincodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    if (event.target.name === "pincode" && !integerExp.test(value)) {
      value = clientAddressOcv.PinCode;
    }
    setClientAddressOcv({ ...clientAddressOcv, PinCode: value });

    if (value && value.length === 6) {
      setEnablePinCheck(true);
    } else {
      setEnablePinCheck(false);
      if (showAgentVisitForm) {
        setShowAgentVisitForm(false);
        setClientAddressOcv(initialAddressObj);
        setOcvDate("");
        setOcvTime("");
      }
    }
  }

  function handleAddressChange(addressObj: IAddressFormat) {
    setClientAddressOcv({ ...clientAddressOcv, ...addressObj });
  }

  function ocvDateAndTimeChange(type: "date" | "time", value: string) {
    if (type === "date") setOcvDate(value);
    else setOcvTime(value);
  }

  function handlePoaSchedule() {
    setIsLoading(true);
    const reqObj = {
      addr: {
        line1: clientAddressOcv.addressLine1,
        line2: clientAddressOcv.Area,
        city: clientAddressOcv.City,
        state: clientAddressOcv.State,
      },
      scheduledDate: stringToDate(ocvDate, true),
      selectedTimeSlot: ocvTime,
      customerName: GlobalStore.personaldetails.PANName,
      customerMobile: GlobalStore.user.mobile,
      applicationId: GlobalStore.applicationID,
      selectedPincode: clientAddressOcv.PinCode,
      defaultPinCode: "",
      vendorResponse: "yes",
      isChequeLeafSubmitted: "false",
      emailurl: "",
    };
    ASavePoaDetails(vendorResponse, reqObj);
  }

  function vendorResponse(res: any) {
    if (!res || res.error) {
      setErrCode("9102 - Failed to fetch vendor response");
    } else {
      const [responseObj] = JSON.parse(JSON.parse(res.data));
      if (responseObj && responseObj.awb_number && responseObj.success)
        history.push(containerUrl.submit);
      else if (
        responseObj &&
        responseObj.reason &&
        responseObj.reason
          .toString()
          .indexOf("There is a lead already open with the same Order Id") !== -1
      ) {
        setErrCode("Request Already Exists");
      } else {
        setErrCode("9872 - Failed to fetch vendor response");
      }
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    registerCleverTapScheduleAgentEvent(true);
  });

  const registerCleverTapScheduleAgentEvent = (isInitial: boolean) => {
    // Clevertap event while user choose for e-sign
    const data = {
      EventName: cleverTapEvents.registration.agentVisit.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE: UTM_SOURCE,
      UTM_MEDIUM: UTM_MEDIUM,
      UTM_CAMPAIGN: UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    if (!isInitial) {
      data["EventName"] =
        cleverTapEvents.registration.agentVisit.CLICK_ON_SUBMIT;
      // Apps flyer event for click on proceed on Agent visit details page
      AppsFlyerService.pushEvent(
        appsFlyerEvents.registration.agentVisit.CLICK_ON_SUBMIT
      );
    }

    ClevertapService.pushEvent(data);
    // Clevertap ends
  };

  return (
    <div className={"poaHome " + (isLoading ? " op05" : "")}>
      { !disablePOASchedule && <ProfileImage className="prflImageBox">
        <img src={ocvCalender} alt="" className="pfImg" />
      </ProfileImage> }
      { !disablePOASchedule && ( <><label className="heading">Schedule POA Form Pickup</label>
      <label className="info">
        An agent will visit below address to collect a signed copy of POA.
      </label>
      <div className="scheduleAgentVisit">
        <label className="cnfAddress">Check Pickup Availability</label>
        <label className="cnfAddInfo">
          Enter your Pincode below to check physical pickup availability
        </label>
        <div className="pinCodeBlock">
          <label className="pincodeLbl">Pincode</label>
          <input
            className="pincodeInput"
            name="pincode"
            value={clientAddressOcv.PinCode}
            maxLength={6}
            onChange={pincodeChange}
          />
          <div className="pincodeBtn">
            <Verify
              btnName="Check"
              disabled={!enablePinCheck}
              isLoading={isLoading}
              onClick={checkPincodeServiceability}
            />
          </div>
        </div>
        {showAgentVisitForm && (
          <AgentVisitForm
            agentTimesList={ecomAgentTimeList}
            handleSubmit={handlePoaSchedule}
            addressObj={clientAddressOcv}
            setErrCode={setErrCode}
            onAddressChange={handleAddressChange}
            ocvDateAndTimeChange={ocvDateAndTimeChange}
            ocvDate={ocvDate}
            ocvTime={ocvTime}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            registerCleverTapScheduleAgentEvent={
              registerCleverTapScheduleAgentEvent
            }
          />
        )}
      </div>  </> ) }
      {showDownload && (
        <div className="eSignForm">
          <label className="addressInfo" id="addressInfoId">
          You are requested to download the form and courier it to the below address
          </label>
          <div className="courierAddress">
            <label className="addressLbl">Address</label>
            <label className="addressVal">{edelweissOfficeName}</label>
            <label className="addressVal">{edelweissOfficeAddress}</label>
          </div>
          <div className="courierBtn" id="downloadBtn">
            <Button
              onClick={() =>
                downloadForm(
                  `EsignPoa_${GlobalStore.applicationID}`,
                  setIsLoading,
                  setErrCode
                )
              }
              btnName="Download and Courier"
              isLoading={isLoading}
              prefix={<span className="edel-icon-download" />}
            />
          </div>
        </div>
      )}
      {(showDownload || showAgentVisitForm) && (
        <div
          className="withoutPoa"
          onClick={() => history.push(containerUrl.submit)}
        >
          Continue Without POA
        </div>
      )}
      {/* Error Msg Start*/}
      {errCode && (
        <div className="errMsgBlck">
          <ErrMsg>
            {typeof errCode === "number" ? (
              <>
                <label>We are facing some technical issue.</label>
                <label>Please try after sometime (error code:{errCode})</label>
              </>
            ) : (
              <label className="errMsgLbl">{errCode}</label>
            )}
          </ErrMsg>
        </div>
      )}
      {/* Error Msg End*/}
    </div>
  );
};

export default Poa;
