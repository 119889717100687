import React from 'react';
import profileBanner from '../../../assets/icons/profileBanner.jpeg'
import ProfileForm from '../ProfileForm/ProfileForm';
import './ProfileHome.scss';

export interface ProfileHomeProps {

}

const ProfileHome: React.FC<ProfileHomeProps> = (props) => {

    React.useEffect(() => {
        window.sessionStorage.setItem('stageInfoId', '1');
    }, []);

    return (
        <div className='prflHome'>
            <div className='prflHeading'>
                <label>Profile</label>
            </div>
            <div className='banner'>
                <img className='bannerImg' src={profileBanner} alt='Profile' />
            </div>
            <ProfileForm />
        </div>
    );
}

export default ProfileHome;