import React, { Component } from 'react';
import OtpField from '../../UI/otpField/otpField';
import OtpTimer from '../../UI/OtpTimer/OtpTimer';
import { errorCode,errorMsg } from '../../../ErrorCode/ErrorCode';
import './RegOtp.scss'

interface IRegOtpProps {
    label: string;
    otpTimer: number;
    eleId: string;
    onChange: (value: string) => string | void;
    resendClicked: () => string | void;
    disableOtp?: boolean;
    invalidOtp?: boolean;
}

let resendTimer: any;

class RegOtp extends Component<IRegOtpProps> {
    state = {
        otp: '',
        isResend: false,
        timer: this.props.otpTimer
    }
    enableClock = () => {
        resendTimer = setInterval(() => {
            let temp = this.state.timer - 1;
            if (temp === 0) {
                clearInterval(resendTimer);
                this.setState({ timer: this.props.otpTimer, isResend: true })
            } else {
                this.setState({ timer: temp })
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(resendTimer);
    }

    componentDidMount() {
        this.enableClock();
    }

    setOtp = (value: any) => {
        this.setState({ otp: value })
        this.props.onChange(value)
    }

    resendClicked = () => {
        this.setState({ isResend: false })
        this.props.resendClicked();
        setTimeout(() => this.enableClock(), 500)
    }

    render() {
        return (
            <div className='regOtp'>
                <p className='otpLbl'>{this.props.label}</p>
                <div className='otpInput'>
                    <OtpField
                        onChange={(value) => this.setOtp(value)}
                        eleId={this.props.eleId}
                        disableOtp={this.props.disableOtp}
                        invalidOtp={this.props.invalidOtp}
                    />
                    {/* {this.props.invalidOtp && <p className='incrtOtp'>Incorrect OTP</p>} */}
                    {this.props.invalidOtp && <p className='incrtOtp'>{errorMsg(errorCode.FE05)}</p>}
                </div>
                {this.state.isResend ?
                    <div className='resendOtpText'>
                        <label className='otpNotRecv'>Didn’t receive the OTP?</label>
                        <label className='resendOtpLink' onClick={this.resendClicked}>Resend now</label>
                    </div>
                    :
                    <div className='otpTimer'>
                        <OtpTimer timer={this.props.otpTimer} />
                    </div>
                }
            </div>
        )
    }
}

export default RegOtp