import { apiService } from "../apiServices";
import config from "../../../config/domain";
import servicesList from "../serviceList";

export const AGetCities = (callback: CallableFunction) => {
  // ToDo: Remove commented code once testing is done for
  // apiService(
  //     {
  //         url: 'eco/get-city',
  //         method: 'GET',
  //     },
  //     callback
  // );

  apiService(
    {
      url: "MastersData/GetCity",
      method: "GET",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.getCities,
    },
    callback
  );
};

export const AGetEmailOtp = (callback: CallableFunction, emlObj: any) => {
  apiService(
    {
      url: "DIYAPI/SendOTPonMail",
      body: {
        MailTo: emlObj.mailTo,
        MailFrom: emlObj.mailFrom,
        MailSubject: emlObj.mailSubject,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getOtp,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/send-otp-on-email",
  //     body: emlObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const AGetOtpNew = (callback: CallableFunction, contactInfo: any,mode?:any) => {
  apiService(
    {
      url: "DIYAPI/v1/GO",
      body: {
        contactInfo:contactInfo,
        mode:mode
    },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getOtpNew,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/send-otp-on-email",
  //     body: emlObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};


export const AGetMobileOtp = (callback: CallableFunction, mobNo: string) => {
  apiService(
    {
      url: "DIYAPI/GetOTP",
      body: {
        MobNo: mobNo,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.getOtp,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/getotp",
  //     body: {
  //       mobNo: mobNo,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AValidateEmailOtp = (
  callback: CallableFunction,
  email: string,
  otp: string
) => {
  apiService(
    {
      url: "DIYAPI/ValidateEmailOTP",
      body: {
        EmailId: email,
        Otp: otp,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.validateOtp,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/validateemailotp",
  //     body: {
  //       email: email,
  //       otp: otp,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AValidateMobileOtp = (
  callback: CallableFunction,
  mobNo: string,
  otp: string
) => {
  apiService(
    {
      url: "DIYAPI/ValidateOTP",
      body: {
        MobNo: mobNo,
        Otp: otp,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.validateOtp,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/validateotp",
  //     body: {
  //       mobNo: mobNo,
  //       otp: otp,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const AValidateOtpNew = (
  callback: CallableFunction,
  otpId: any,
  otp: string
) => {
  apiService(
    {
      url: "DIYAPI/v1/ValO",
      body: {
        otpId: otpId,
        otp: otp,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.validateOtpNew,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/validateotp",
  //     body: {
  //       mobNo: mobNo,
  //       otp: otp,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetEmployeeDetails = (
  callback: CallableFunction,
  bodyParams: any
) => {
  apiService(
    {
      url: "DIYAPI/GetEmployeeDetails",
      body: {
        UserName: bodyParams.username,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getEmployeeDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-employee-details",
  //     body: bodyParams,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ASearchLeadByName = (callback: CallableFunction, leadObj: any) => {
  apiService(
    {
      url: "DIYAPI/v1/SearchLead",
      body: {
        AssignedTo: leadObj.assignedTo,
        FirstName: leadObj.firstName,
        LastName: leadObj.lastName,
        Mobile: leadObj.mobile,
        EmailId: leadObj.emailId,
        ProductCode: leadObj.productCode,
        SearchBy: "CRITERIA_MOBILE_EMAIL",
        source: "DIY",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.searchLeadByName,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/search-lead-by-name",
  //     body: leadObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ACreateLead = (callback: CallableFunction, leadObj: any) => {
  apiService(
    {
      url: "eco/create-lead",
      body: leadObj,
      method: "POST",
    },
    callback
  );
};

export const ACreateLeadSource = (callback: CallableFunction, leadObj: any) => {
  apiService(
    {
      url: "DIYAPI/CreateLead",
      body: {
        Email: leadObj.email,
        FirstName: leadObj.firstName,
        LastName: leadObj.lastName,
        Phone: leadObj.phone,
        City: leadObj.city,
        AssignedTo: leadObj.assignedTo,
        UtmAdGroup: leadObj.utm_adgroup,
        UtmCampaign: leadObj.utm_campaign,
        UtmContent: leadObj.utm_content,
        UtmCreative: leadObj.utm_creative,
        UtmDevice: leadObj.utm_device,
        UtmMedium: leadObj.utm_medium,
        UtmPlacement: leadObj.utm_placement,
        UtmSource: leadObj.utm_source,
        UtmTerm: leadObj.utm_term,
        ProductCode: leadObj.productCode,
        SourceId: leadObj.sourceId,
        SubSourceId: leadObj.subSourceId,
        ByPassDedupe: leadObj.byPassDedupe,
        RmCode: leadObj.rmCode,
        SourceName: leadObj.sourceName,
        SubSourceName: leadObj.subSourceName,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.createLead,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/create-lead-source",
  //     body: leadObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AWhatsappNotify = (callback: CallableFunction, req: any) => {
  apiService(
    {
      url: "WhatsUpAppService/WhatsUpAppConsent",
      body: {
        Type: req.type,
        recipients: req.recipients,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.whatsUpAppConsent,
    },
    callback
  );
  // apiService(
  //   {
  //     apiBaseUrl: config.javaComUrl,
  //     url: "consent",
  //     body: req,
  //     method: "POST",
  //     headers: {
  //       mwAuth: config.whatsappBearerToken,
  //     },
  //   },
  //   callback
  // );
};

export const CreateUCCRegCode = (callback: CallableFunction, req: any) => {
  console.log("REQ||", req);
  apiService(
    {
      url: "DIYAPI/CreateUCCRegCode",
      body: req,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.createUCCRegCode,
    },
    callback
  );
};
