import React from "react";
import "./Toggle.scss";

export interface IToggleProps {
    disabled?: boolean //true if toggle is disabled
    isOn: boolean; // true if toggle is on, else false
    children?: string | React.ReactElement; // text/element for label
    name?: string; // name for input checkbox
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // function to be called on change
}

const Toggle: React.FC<IToggleProps> = (props: IToggleProps) => {
    return (
        <label className="toggle">
            {props.children &&
                <span className="tgl_text">{props.children}</span>
            }
            <span className="toggle_outer">
                <input
                    type="checkbox"
                    name={props.name}
                    checked={props.isOn}
                    onChange={props.onChange}
                    disabled={props.disabled}
                />
                <span className="toggle_inner"></span>
            </span>
        </label>
    );
};

export default Toggle;
