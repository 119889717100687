import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import RegLayout from "../components/Registration/Layout/Layout";
import RegistrationHome from "../components/Registration/RegistrationHome/RegistrationHome";
import WelcomeBack from "../components/Registration/WelcomeBack/WelcomeBack";
import MailVerified from "../components/Registration/MailVerified/MailVerified";
import NRI from "../components/Registration/NRI/NRI";
import OTP from "../components/Registration/OTP/OTP";
import AccExists from "../components/Registration/AccExists/AccExists";
import PANInfo from "../components/Registration/PANInfo/PANInfo";
import Error from "../components/UI/ErrorScreen/ErrorScreen";
import ShareAdd from "../components/Registration/ShareAdd/ShareAdd";
import DgLocker from "../components/Registration/DgLocker/DgLocker";
import { containerUrl } from "../components/utils/constants";
import useQuery from "../customHooks/useQuery";
import { ClevertapService } from "../components/Services/clevertapService";
import { AppsFlyerService } from "../components/Services/appsFlyerService";
import InstanHelp from "../components/InstantHelp/InstantHelp";

const RegistrationRoutes = () => {
  const quryParams = useQuery();
  const utm_placement = quryParams.get("utm_placement")
    ? quryParams.get("utm_placement")
    : "";

  useEffect(() => {
    getCleverTapIDFromService();
  }, []);

  const getCleverTapIDFromService = async () => {
    // const id =  utm_placement || await ClevertapService.getCleverTapID();
    // const utmObj = JSON.parse(window.sessionStorage.getItem("objUTM_Info") || '{}');
    // window.sessionStorage.setItem("objUTM_Info", JSON.stringify({
    //     ...utmObj,
    //     ...{
    //         utm_placement: id
    //     }
    // }));
    // // Now we need to initilise CUID with AppsFlyer
    // AppsFlyerService.setCUID(id);
  };

  return (
    <>
      <Switch>
        <Route
          path={containerUrl.continue}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <RegLayout>
                <WelcomeBack {...props} />
              </RegLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={containerUrl.pan}
          exact
          render={() => (
            <InstanHelp panPageFlag={true}>
              <RegLayout>
                <PANInfo />
              </RegLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={containerUrl.otp}
          exact
          render={(props) => (
            <RegLayout>
              <OTP {...props} {...undefined} />
            </RegLayout>
          )}
        />
        <Route
          path={containerUrl.accexists}
          exact
          render={() => (
            <InstanHelp panPageFlag={false}>
              <RegLayout>
                <AccExists />
              </RegLayout>
            </InstanHelp>
          )}
        />

        <Route
          path={containerUrl.dgLocker}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <RegLayout supportNeeded>
                <DgLocker {...props} />
              </RegLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={containerUrl.showDocuments}
          exact
          render={() => (
            <InstanHelp panPageFlag={false}>
              <RegLayout supportNeeded>
                <ShareAdd />
              </RegLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={containerUrl.mailVerified}
          exact
          render={() => (
            <RegLayout>
              <MailVerified />
            </RegLayout>
          )}
        />
        <Route
          path={containerUrl.error}
          exact
          render={(props) => (
            <RegLayout>
              <Error {...props} />
            </RegLayout>
          )}
        />
        <Route
          path={containerUrl.nri}
          exact
          render={() => (
            <RegLayout>
              <NRI />
            </RegLayout>
          )}
        />
        <Route
          path={containerUrl.partner}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <RegLayout>
                <RegistrationHome {...props} />
              </RegLayout>
            </InstanHelp>
          )}
        />
        <Route
          path="/"
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <RegLayout>
                <RegistrationHome {...props} />
              </RegLayout>
            </InstanHelp>
          )}
        />
      </Switch>
    </>
  );
};

export default RegistrationRoutes;
