import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import RegistrationRoutes from "./containers/RegistrationRoutes";
import DashboardRoutes from "./containers/DashboardRoutes";
import SubmissionRoutes from "./containers/SubmissionRoutes";
import StatusRoutes from "./containers/StatusRoutes";
import "./assets/icons/edel_icons/style.scss";
import "./assets/scss/variables/fontFamily.scss";
import "./assets/scss/variables/ffVar.scss";
import "./App.scss";
import ChatText from "./components/UI/ChatText/ChatText";
import Downtime from "./components/UI/Downtime/Downtime";
import Backdrop from "./components/UI/Backdrop/Backdrop";
import MarketingRoute from "./containers/MarketingRoute";
import config from "./config/domain";
import SmsRoutes from "./containers/SmsRoutes";
import { GetErrorBank } from "./components/Services/dashboard/errorBankServices";
import GlobalStore from "./components/store/globalStore";
import DeviceContext from "./components/Services/identifyDevice";
import CacheBuster from "react-cache-buster";

const ReactZenDeskChat = require("@inlightmedia/react-zendesk-chat").default;

const enable_CacheBuster =
  process.env.REACT_APP_ENV === "production"
    ? true
    : process.env.REACT_APP_ENV === "preprod"
    ? false
    : process.env.REACT_APP_ENV === "uat"
    ? true
    : true;

declare global {
  interface Window {
    ymConfig?: any;
    YellowMessenger?: any;
  }
}

function App() {
  const [panPageFlag, setPanPageFlag] = React.useState<boolean>(false);
  useEffect(() => {
    GetErrorBank(getErrorMsg);
    (window as any).clevertap.account.push({ id: config.cleverTapId });
    (window as any).clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
    (window as any).clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
    (function () {
      var wzrk = document.createElement("script");
      wzrk.type = "text/javascript";
      wzrk.async = true;
      // wzrk.src = "lib/clevertap.js";
      wzrk.src =
        ("https:" == document.location.protocol
          ? "https://d2r1yp2w7bby2u.cloudfront.net"
          : "http://static.clevertap.com") + "/js/clevertap.min.js";
      var s = document.getElementsByTagName("script")[0];
      (s as any).parentNode.insertBefore(wzrk, s);
    })();

    (window as any).clevertap.notifications.push({
      titleText: "Would you like to receive Push Notifications?",
      bodyText:
        "We promise to only send you relevant content and give you updates on your transactions",
      okButtonText: "Sign me up!",
      rejectButtonText: "No thanks",
      okButtonColor: "#f28046",
      askAgainTimeInSeconds: 172800,
      serviceWorkerPath: "/serviceworker.js",
    });
  }, []);
  function getErrorMsg(res: any) {
    if (res.data && res.data.length > 0) {
      GlobalStore.fieldErrorBank = res.data;
    }
  }
  useEffect(() => {
    console.log(window.location.href, "Location", window.location.pathname);
    if (
      window.location.pathname == "/marketing" ||
      window.location.pathname == "/pan"
    ) {
      console.log("chattext condition match");
      setPanPageFlag(true);
    } else {
      setPanPageFlag(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    window.ymConfig = {
      bot: "x1648725853798",
      host: "https://cloud.yellow.ai",
      payload: {},
    };
    window.YellowMessenger?.update?.(window.ymConfig);

    return () => {
      document.getElementsByTagName("head")[0].removeChild(script);
    };
  }, []);

  return (
    <CacheBuster
      currentVersion={"6.6.13"}
      isEnabled={enable_CacheBuster} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <React.Fragment>
        {/* <Backdrop isShow isCloseIcon={false}><Downtime /></Backdrop> */}

        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <StatusRoutes />
          <RegistrationRoutes />
          <DashboardRoutes />
          <SubmissionRoutes />
          <MarketingRoute />
          <SmsRoutes />
        </BrowserRouter>
        <footer className="support">
          {/* <ChatText panPageFlag={panPageFlag} /> */}
          {DeviceContext().isMobile ? (
            ""
          ) : (
            <ReactZenDeskChat appID="41MH0WLFP1PIiJh5X3GVaJmPkKi5A6EP" />
          )}
        </footer>
      </React.Fragment>
    </CacheBuster>
  );
}

export default App;
