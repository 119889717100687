import React from 'react';
import './Radio.scss';

export interface RadioProps {
    label?: string;
    name?: string;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<RadioProps> = (props) => {
    return (
        <label className={'radioBtn ' + props.className}>
            <label className="container">
                {props.label}
                <input
                    type="radio"
                    name={props.name}
                    onChange={props.onChange}
                    checked={props.checked}
                    disabled={props.disabled}
                />
                <span className="checkmark"></span>
            </label>
        </label>
    );
};

export default Radio;
