import React, { useState } from "react";
import "./PersonalInfo.scss";
import InputField from "../../UI/InputField/InputField";
import Tag from "../../UI/Tag/Tag";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import Button, {
  DashedButton,
  TransparentButton,
} from "../../UI/Button/Button";
import SubmitButton from "../../UI/Button/Button";
import Toggle from "../../UI/Toggle/Toggle";
import GlobalStore from "../../store/globalStore";
import Backdrop from "../../UI/Backdrop/Backdrop";
import micr from "../../../assets/icons/micr.jpg";
import { AGetKycDetails } from "../../Services/dashboard/profileApiService";
import { IListObj } from "../../types/genericTypes";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import moment from "moment";

import {
  aadhaarDocumnetId,
  addressType,
  corrAadhaarDocumnetId,
  corrDlDocumentId,
  corrPassportDocumnetId,
  corrVoterIdDocumentId,
  dashboardUrl,
  DIY,
  dlDocumentId,
  panDocumentId,
  passportDocumnetId,
  voterIdDocumentId,
} from "../../utils/constants";
import { useHistory } from "react-router-dom";
import gtmService from "../../Services/gtmService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import { ClevertapService } from "../../Services/clevertapService";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import { errorMessage } from "../../utils/errorMessage";
import AddressField, {
  IAddressFormat,
  addressErrorObj,
  CkycAddressFormat,
} from "../../Profile/AddressField/AddressField";
import DocumentBrowse, {
  DocDetailFormat,
} from "../../UI/DocumentBrowse/DocumentBrowse";
import {
  AextractAadhaar,
  AextractDl,
  AextractPassport,
  AextractVoterid,
  AgetPdf,
  AGetUploadedDocument,
  AmaskAadhaar,
  ADownloadDocument,
  ASaveDocumentDetails,
} from "../../Services/dashboard/docVerificationAPIServices";
import { AUploadDocument } from "../../Services/dashboard/brokerageServices";
import { uploadDocObj } from "../../DocumentVerification/DocVerificationForm/DocVerificationForm";
import Checkbox from "../../UI/Checkbox/Checkbox";
import {
  ASaveAddressDetails,
  AVerifyDocumentData,
  GetAddressDetails,
  ASavePersonalDocument,
} from "../../Services/registration/manualFlowService";
import { repairAddressWithConditions } from "../../utils/validation";
import {
  completeModuleStatus,
  incompleteStageNavigation,
  utmDurationInfoObj,
} from "../../Services/globalService";
import Calender from "../../UI/Calender/Calender";
import AddressInfoField, {
  addressInfoErrorObj,
  IAddressInfoFormat,
} from "./AddressInfoField";

const identificationListPerm = [
  {
    display: "Aadhaar Card",
    value: 117,
  },
  {
    display: "Driving License",
    value: 118,
  },
  {
    display: "Voter ID",
    value: 116,
  },
  {
    display: "Passport",
    value: 114,
  },
];
const identificationListCorr = [
  {
    display: "Aadhaar Card",
    value: 10,
  },
  {
    display: "Driving License",
    value: 13,
  },
  {
    display: "Voter ID",
    value: 12,
  },
  {
    display: "Passport",
    value: 11,
  },
];

export const initialAddressObj: any = {
  addressLine1: "",
  City: "",
  Country: "",
  District: "",
  PinCode: "",
  State: "",
  Area: "",
  CountryCode: 0,
  StateCode: 0,
  clientName: "",
};

export const initialDocObj: DocDetailFormat = {
  id: "",
  disable: true,
  uploadPer: 0,
  hideProgress: false,
  fileName: "",
  showFile: true,
  imgUrl: "",
  documentcategory: "",
  documentCategoryId: "",
  documentId: 0,
  mimeType: "",
  base64: "",
  isWatsappFlag: false,
};
export const initialPanDocObj: DocDetailFormat = {
  id: "",
  disable: false,
  uploadPer: 0,
  hideProgress: true,
  fileName: "",
  showFile: true,
  imgUrl: "",
  documentcategory: "",
  documentCategoryId: "",
  documentId: 0,
  mimeType: "",
  base64: "",
  iscropped: false,
  isWatsappFlag: false,
};
let localAadharNo: any;
const PersoanlInfo = (props: any) => {
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<number | string>();
  const [permAddListError, setPermAddListError] = useState<any>("");
  const [permSecAddListError, setPermSecAddListError] = useState<any>("");
  const [corrAddListError, setCorrAddListError] = useState<any>("");
  const [corrSecAddListError, setCorrSecAddListError] = useState<any>("");
  const [anotherFile, setAnotherFile] = React.useState<boolean>(false);
  const [secondDoc, setSecondDoc] = useState<boolean>(false);
  const [corrSecondDoc, setCorrSecondDoc] = useState<boolean>(false);
  const [corrAnotherFile, setCorrAnotherFile] = React.useState<boolean>(false);

  const [permAddList, setPermAddList] = useState<any>([]);
  const [defaultAddress, setDefaultAddress] = useState<string>("");
  const [defaultCorrAddress, setDefaultCorrAddress] = useState<string>("");

  const [permAddressObj, setPermAddressObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [permSecondAddressObj, setPermSecondAddressObj] =
    useState<DocDetailFormat>({ ...initialPanDocObj });
  const [corrAddressObj, setCorrAddressObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [corrSecondAddressObj, setCorrSecondAddressObj] = useState<any>({
    ...initialPanDocObj,
  });
  const [permanentAddress, setPermanentAddress] = React.useState<any>({
    ...initialAddressObj,
  });
  const [correspondanceAddress, setCorrespondanceAddress] = React.useState<any>(
    { ...initialAddressObj }
  );
  const [permErrorObj, setPermErrorObj] = React.useState<{
    [key in keyof IAddressInfoFormat]?: string;
  }>({
    ...addressInfoErrorObj,
  });
  const [corrErrorObj, setCorrErrorObj] = React.useState<{
    [key in keyof IAddressInfoFormat]?: string;
  }>({
    ...addressInfoErrorObj,
  });
  const [isNewPermAddress, setIsNewPermAddress] = useState<boolean>(false);
  const [isNewCorrAddress, setIsNewCorrAddress] = useState<boolean>(false);
  const [addressName, setAddressName] = React.useState<string>("");
  const [corrAddressName, setCorrAddressName] = React.useState<string>("");
  const [sameAsPermanent, setSameAsPermanent] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<any>({
    permanentAddress: "",
    correspondanceAddress: "",
  });
  const [drivingFlag, setDrivingFlag] = React.useState<boolean>(false);
  const [corrsDrivingFlag, SetCorrsDrivingFlag] =
    React.useState<boolean>(false);
  const [qrVerifiedAadhar, setQrVerifiedAadhar] =
    React.useState<boolean>(false);
  const [autoQCFlagPer, setAutQcFlagPer] = React.useState<boolean>(false);
  const [autoQCFlagCor, setAutQcFlagCor] = React.useState<boolean>(false);
  const [qrVerifiedCorrsAadhar, setQrVerifiedCorrsAadhar] =
    React.useState<boolean>(false);
  // const [aadharNo, setAadharNo] = React.useState<string>("");
  const history = useHistory();
  React.useEffect(() => {
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    // localAadharNo = JSON.parse(
    //   window.localStorage.getItem("aadharNumber") || ""
    // );
    clientPartialDetails_ao.emailID = GlobalStore.user.email;
    clientPartialDetails_ao.applicationId = GlobalStore.applicationID;
    clientPartialDetails_ao.dob = GlobalStore.dob;

    window.localStorage.setItem(
      "clientPartialDetails_ao",
      JSON.stringify(clientPartialDetails_ao)
    );
    GetAddressDetails(getKycDetails, GlobalStore.applicationID);
    AGetUploadedDocument(getUploadedDoc, GlobalStore.applicationID);
  }, []);

  function getKycDetails(res: any) {
    try {
      setIsLoading(true);
      if (!res || res.error) {
        setIsLoading(false);
        setErrCode(res.error);
      } else {
        if (res.data) {
          let responseObj = JSON.parse(res.data);
          if (responseObj.AddressDetails) {
            const clientPartialDetails_ao = JSON.parse(
              window.localStorage.getItem("clientPartialDetails_ao") || "{}"
            );
            clientPartialDetails_ao.applicationId = GlobalStore.applicationID;
            clientPartialDetails_ao.leadID = GlobalStore.leadId;
            clientPartialDetails_ao.clientPrimaryDetailId =
              responseObj.ClientPrimaryDetailID;
            clientPartialDetails_ao.addressLine1 =
              responseObj.AddressDetails.objPermanentAddressDetails.permanentaddressline1;
            clientPartialDetails_ao.sameAsPermanent =
              responseObj.corrsaddraspermanent
                ? responseObj.corrsaddraspermanent
                : true;
            clientPartialDetails_ao.corrAddress =
              responseObj.AddressDetails.objCorrespondenceAddressDetails.correspondenceaddressline1;
            if (responseObj?.DocumentNumber?.length === 4) {
              clientPartialDetails_ao.aadharNo = responseObj.DocumentNumber
                ? responseObj.DocumentNumber.slice(-4)
                : "";
              GlobalStore.personaldetails.aadharNo =
                window.localStorage.setItem(
                  "aadharNumber",
                  GlobalStore.personaldetails.aadharNo
                );
              localAadharNo = responseObj.DocumentNumber.slice(-4);
              window.localStorage.setItem("aadharNumber", localAadharNo);
            }

            window.localStorage.setItem(
              "clientPartialDetails_ao",
              JSON.stringify(clientPartialDetails_ao)
            );

            let permResultObj =
              responseObj.AddressDetails.objPermanentAddressDetails;
            if (permResultObj) {
              const permAddObj = { ...permanentAddress };
              permAddObj.addressLine1 =
                (permResultObj.permanentaddressline1 || "") +
                (permResultObj.permanentaddressline2 || "") +
                (permResultObj.permanentaddressline3 || "") +
                (permResultObj.permanentaddressline4 || "");
              permAddObj.Area = permResultObj.permanentarea;
              permAddObj.City = permResultObj.permanentcity;
              permAddObj.PinCode = permResultObj.permanentpincode;
              permAddObj.District = permResultObj.permanentdistrict;
              permAddObj.State = permResultObj.permanentstate;
              permAddObj.StateCode = permResultObj.permanentstatecode;
              permAddObj.Country = permResultObj.permanentcountry;
              permAddObj.CountryCode = permResultObj.permanentcountrycode;
              permAddObj.clientName = permResultObj.documentname;
              permAddObj.documentNumber = permResultObj.documentnumber;
              permAddObj.dateOfIssue = permResultObj?.documentissuedate
                ? permResultObj?.documentissuedate
                    ?.split("-")
                    ?.reverse()
                    ?.join("/")
                : "";
              permAddObj.dateOfExpiry = permResultObj?.documentexpirydate
                ? permResultObj?.documentexpirydate
                    ?.split("-")
                    ?.reverse()
                    ?.join("/")
                : "";
              if (
                permResultObj?.documentnumber?.length === 4 ||
                permResultObj?.documentnumber?.includes("XXXX")
              ) {
                permAddObj.documentNumber =
                  permResultObj?.documentnumber?.length === 4
                    ? `XXXX XXXX ${permResultObj.documentnumber}`
                    : permResultObj?.documentnumber;
                delete permAddObj.dateOfExpiry;
                delete permAddObj.dateOfIssue;
              }
              if (permResultObj?.documentnumber?.length === 10) {
                delete permAddObj.dateOfExpiry;
              }

              setPermanentAddress(permAddObj);
              // for AO-1395 KYC/KRA address proof reject
            }
            let corrsResultObj =
              responseObj.AddressDetails.objCorrespondenceAddressDetails;
            if (corrsResultObj) {
              const corrAddObj = { ...correspondanceAddress };
              corrAddObj.addressLine1 =
                corrsResultObj.correspondenceaddressline1 +
                corrsResultObj.correspondenceaddressline2 +
                (corrsResultObj.correspondenceaddressline3 || "") +
                (corrsResultObj.correspondenceaddressline4 || "");
              corrAddObj.Area = corrsResultObj.correspondancearea;
              corrAddObj.City = corrsResultObj.correspondancecity;
              corrAddObj.PinCode = corrsResultObj.correspondancepincode;
              corrAddObj.District = corrsResultObj.correspondancedistrict;
              corrAddObj.State = corrsResultObj.correspondancestate;
              corrAddObj.StateCode = corrsResultObj.correspondancestatecode;
              corrAddObj.Country = corrsResultObj.correspondancecountry;
              corrAddObj.CountryCode = corrsResultObj.correspondancecountrycode;
              corrAddObj.clientName = corrsResultObj.documentname;
              corrAddObj.documentNumber = corrsResultObj.documentnumber;
              corrAddObj.dateOfIssue = corrsResultObj?.documentissuedate
                ? corrsResultObj?.documentissuedate
                    ?.split("-")
                    ?.reverse()
                    ?.join("/")
                : "";
              corrAddObj.dateOfExpiry = corrsResultObj?.documentexpirydate
                ? corrsResultObj?.documentexpirydate
                    ?.split("-")
                    ?.reverse()
                    ?.join("/")
                : "";
              if (
                corrsResultObj?.documentnumber?.length === 4 ||
                corrsResultObj?.documentnumber?.includes("XXXX")
              ) {
                corrAddObj.documentNumber =
                  corrsResultObj?.documentnumber?.length === 4
                    ? `XXXX XXXX ${corrsResultObj.documentnumber}`
                    : corrsResultObj?.documentnumber;
                delete corrAddObj.dateOfIssue;
                delete corrAddObj.dateOfExpiry;
              }
              if (corrsResultObj?.documentnumber?.length === 10) {
                delete corrAddObj.dateOfExpiry;
              }
              setCorrespondanceAddress(corrAddObj);
            }
            setSameAsPermanent(
              responseObj.corrsaddraspermanent === false ? false : true
            );
          }
          setSameAsPermanent(
            responseObj.Corrsaddraspermanent === false ? false : true
          );
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  }
  const getUploadedDoc = async (res: any) => {
    console.log("getUploadedDoc || ==", res);
    if (!res || res.error) {
      // setErrCode(164)
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR124" });
    } else if (res.data) {
      const uplDoc = JSON.parse(res.data);
      console.log("getUploadedDoc", uplDoc);

      const isCorrAddressProof = uplDoc.filter(
        (x: any) => x.DocumentCategory === "Address Proof Correspondence"
      );
      const isPermAddressProof = uplDoc.filter(
        (x: any) => x.DocumentCategory === "Address Proof Permanent"
      );
      if (isCorrAddressProof.length > 0) {
        setIsLoading(true);
        if (isCorrAddressProof[0].DocumentName === "Aadhaar Card") {
          setDefaultCorrAddress("Aadhaar Card");
          setQrVerifiedCorrsAadhar(true);
        } else if (isCorrAddressProof[0].DocumentName === "Passport") {
          setDefaultCorrAddress("Passport");
        } else if (isCorrAddressProof[0].DocumentName === "Voter ID") {
          setDefaultCorrAddress("Voter ID");
        } else if (isCorrAddressProof[0].DocumentName === "Driving License") {
          setDefaultCorrAddress("Driving License");
        } else if (isCorrAddressProof[0].DocumentName === "Nrega Job Card") {
          setDefaultCorrAddress("Nrega Job Card");
        }

        corrAddressObj.id = "corrAddress";
        corrAddressObj.disable = false;
        corrAddressObj.uploadPer = 100;
        corrAddressObj.fileName = `${GlobalStore.applicationID}_Corrospondance Address`;
        corrAddressObj.showFile = false;
        corrAddressObj.hideProgress = false;
        corrAddressObj.documentCategoryId =
          isCorrAddressProof[0].DocumentCategoryId;
        corrAddressObj.documentcategory =
          isCorrAddressProof[0].DocumentCategory;
        corrAddressObj.documentId = isCorrAddressProof[0].DocumentId;
        corrAddressObj.mimeType = isCorrAddressProof[0].MimeType;
        corrAddressObj.documentCategoryId =
          isCorrAddressProof[0].DocumentCategoryId;
        setCorrAddressObj(corrAddressObj);
        ADownloadDocument(
          getCorrAddressImage,
          isCorrAddressProof[0].FileName,
          isCorrAddressProof[0].UploadedDocPath
        );
      }
      if (isPermAddressProof.length > 0) {
        setIsLoading(true);
        // setDefaultPermAddressProof(true);
        if (isPermAddressProof[0].DocumentName === "Aadhaar Card") {
          setDefaultAddress("Aadhaar Card");
          setQrVerifiedAadhar(true);
        } else if (isPermAddressProof[0].DocumentName === "Passport") {
          setDefaultAddress("Passport");
        } else if (isPermAddressProof[0].DocumentName === "Voter ID") {
          setDefaultAddress("Voter ID");
        } else if (isPermAddressProof[0].DocumentName === "Driving License") {
          setDefaultAddress("Driving License");
        } else if (isPermAddressProof[0].DocumentName === "Nrega Job Card") {
          setDefaultAddress("Nrega Job Card");
        }

        permAddressObj.id = "permAddress";
        permAddressObj.disable = false;
        permAddressObj.uploadPer = 100;
        permAddressObj.fileName = `${GlobalStore.applicationID}_Permanent Address`;
        permAddressObj.showFile = false;
        permAddressObj.hideProgress = false;
        permAddressObj.documentCategoryId =
          isPermAddressProof[0].DocumentCategoryId;
        permAddressObj.documentcategory =
          isPermAddressProof[0].DocumentCategory;
        permAddressObj.documentId = isPermAddressProof[0].DocumentId;
        permAddressObj.mimeType = isPermAddressProof[0].MimeType;
        permAddressObj.documentCategoryId =
          isPermAddressProof[0].DocumentCategoryId;
        setPermAddressObj(permAddressObj);
        ADownloadDocument(
          getAddressImage,
          isPermAddressProof[0].FileName,
          isPermAddressProof[0].UploadedDocPath
        );
      }
    } else {
      setPermAddressObj(permAddressObj);
      setCorrAddressObj(corrAddressObj);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR124-S" });
    }
    setIsLoading(false);
  };

  const getAddressImage = async (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR129",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, permAddressObj);
        permAddressObj.imgUrl = base64;
        setPermAddressObj(permAddressObj);
        console.log("-perm-BASE64");
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR129-S",
        });
      }
    }
  };

  function getbase64(dataString: string, obj: DocDetailFormat) {
    return (
      "data:" +
      (obj.mimeType === "application/pdf" ? "application/pdf" : "image/jpeg") +
      ";base64," +
      dataString
    );
  }

  const getCorrAddressImage = async (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR130",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, corrAddressObj);
        corrAddressObj.imgUrl = base64;
        // corrAddressObj.base64 = base64;
        setCorrAddressObj(corrAddressObj);
        console.log("-corr-BASE64");
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR130-S",
        });
      }
    }
  };

  const handlePermanentAddress = (val: any) => {
    if (val && val.value) {
      permAddressObj.disable = false;
      permAddressObj.documentId = val.value;
      setPermAddressObj(permAddressObj);
      setDefaultAddress(val.display);
      setDrivingFlag(false);
      setQrVerifiedAadhar(false);
      // setAadharNo("");
      switch (val.display) {
        case "Aadhaar Card":
          setPermanentAddress({
            ...initialAddressObj,
            documentNumber: "",
          });
          setPermErrorObj({
            ...permErrorObj,
            documentNumber: undefined,
          });
          break;
        case "Driving License":
          setPermanentAddress({
            ...initialAddressObj,
            documentNumber: "",
            dateOfIssue: "",
            dateOfExpiry: "",
          });
          setPermErrorObj({
            ...permErrorObj,
            documentNumber: undefined,
            dateOfIssue: undefined,
            dateOfExpiry: undefined,
          });
          break;
        case "Voter ID":
          setPermanentAddress({
            ...initialAddressObj,
            documentNumber: "",
            dateOfIssue: "",
          });
          setPermErrorObj({
            ...permErrorObj,
            documentNumber: undefined,
            dateOfIssue: undefined,
          });
          break;
        case "Passport":
          setPermanentAddress({
            ...initialAddressObj,
            documentNumber: "",
            dateOfIssue: "",
            dateOfExpiry: "",
          });
          setPermErrorObj({
            ...permErrorObj,
            documentNumber: undefined,
            dateOfIssue: undefined,
            dateOfExpiry: undefined,
          });
          break;
        default:
          setPermanentAddress({ ...initialAddressObj });
          setPermErrorObj({
            ...permErrorObj,
          });
          break;
      }
    }
  };
  const handleAddressBlur = (value: any, identificationDetailType: string) => {
    if (typeof value === "object") {
      setErrors({ ...errors, [identificationDetailType]: "" });
    } else {
      setErrors({
        ...errors,
        [identificationDetailType]: "Please select from dropdown'",
      });
    }
  };
  const handleCorrespondenceAddress = (val: any) => {
    if (val && val.value) {
      corrAddressObj.disable = false;
      corrAddressObj.documentId = val.value;
      setCorrAddressObj(corrAddressObj);
      setDefaultCorrAddress(val.display);
      setQrVerifiedCorrsAadhar(false);
      SetCorrsDrivingFlag(false);
      // setAadharNo("");
      switch (val.display) {
        case "Aadhaar Card":
          setCorrespondanceAddress({
            ...initialAddressObj,
            documentNumber: "",
          });
          setCorrErrorObj({
            ...corrErrorObj,
            documentNumber: undefined,
          });
          break;
        case "Driving License":
          setCorrespondanceAddress({
            ...initialAddressObj,
            documentNumber: "",
            dateOfIssue: "",
            dateOfExpiry: "",
          });
          setCorrErrorObj({
            ...corrErrorObj,
            documentNumber: undefined,
            dateOfIssue: undefined,
            dateOfExpiry: undefined,
          });
          break;
        case "Voter ID":
          setCorrespondanceAddress({
            ...initialAddressObj,
            documentNumber: "",
            dateOfIssue: "",
          });
          setCorrErrorObj({
            ...corrErrorObj,
            documentNumber: undefined,
            dateOfIssue: undefined,
          });
          break;
        case "Passport":
          setCorrespondanceAddress({
            ...initialAddressObj,
            documentNumber: "",
            dateOfIssue: "",
            dateOfExpiry: "",
          });
          setCorrErrorObj({
            ...corrErrorObj,
            documentNumber: undefined,
            dateOfIssue: undefined,
            dateOfExpiry: undefined,
          });
          break;
        default:
          setCorrespondanceAddress({ ...initialAddressObj });
          setCorrErrorObj({
            ...corrErrorObj,
          });
          break;
      }
    }
  };

  function onFirstPermAddressRemove(val: any) {
    setPermAddressObj({
      ...initialDocObj,
      disable: false,
      documentId: permAddressObj.documentId,
    });
    setPermanentAddress({ ...initialAddressObj });
  }
  function onSecondPermAddressRemove(val: any) {
    setPermSecondAddressObj({ ...initialPanDocObj });
    setPermanentAddress({ ...initialAddressObj });
  }
  function onFirstPermAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    permAddressObj.imgUrl = "";
    permAddressObj.base64 = val!.split(",")[1];
    permAddressObj.fileName = fileName;
    setPermAddressObj(permAddressObj);
    if (fileName.split(".")[1] !== "pdf" && permAddressObj.documentId !== 125) {
      setSecondDoc(true);

      if (permAddressObj.documentId === aadhaarDocumnetId) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: permAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: permAddressObj.documentId,
          source: DIY,
        };
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      // if PDF
      setSecondDoc(false);
      setAnotherFile(false);

      if (permAddressObj.documentId === aadhaarDocumnetId) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: permAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: permAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar PDF Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    }
  }
  function onSecondPermAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    permSecondAddressObj.imgUrl = "";
    permSecondAddressObj.fileName = fileName;
    permSecondAddressObj.base64 = val!.split(",")[1];
    setPermSecondAddressObj(permSecondAddressObj);
    if (
      fileName.split(".")[1] !== "pdf" &&
      permSecondAddressObj.documentId !== 125
    ) {
      setSecondDoc(true);
      if (
        permAddressObj.documentId === aadhaarDocumnetId ||
        permAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: permSecondAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: permSecondAddressObj.documentId,
          source: DIY,
        };
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        const uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      setIsLoading(false);
      setSecondDoc(false);
      setAnotherFile(false);
    }
  }

  function onFirstCorrAddressRemove(val: any) {
    setCorrAddressObj({
      ...initialDocObj,
      disable: false,
      documentId: corrAddressObj.documentId,
    });
    setCorrespondanceAddress({ ...initialAddressObj });
  }
  function onSecondCorrAddressRemove(val: any) {
    setCorrSecondAddressObj({ ...initialPanDocObj });
    setCorrespondanceAddress({ ...initialAddressObj });
  }
  function onFirstCorrAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    corrAddressObj.imgUrl = "";
    corrAddressObj.base64 = val!.split(",")[1];
    corrAddressObj.fileName = fileName;
    setCorrAddressObj(corrAddressObj);
    if (fileName.split(".")[1] !== "pdf" && corrAddressObj.documentId !== 133) {
      setCorrSecondDoc(true);

      if (
        corrAddressObj.documentId === aadhaarDocumnetId ||
        corrAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: corrAddressObj.base64,
          documentcategory: "Corr Address Proof Permanent",
          documentid: corrAddressObj.documentId,
          source: DIY,
        };
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      // if PDF
      setCorrSecondDoc(false);
      setCorrAnotherFile(false);

      if (
        corrAddressObj.documentId === aadhaarDocumnetId ||
        corrAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: corrAddressObj.base64,
          documentcategory: "Corr Address Proof Permanent",
          documentid: corrAddressObj.documentId,
          source: DIY,
        };
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    }
  }

  function onSecondCorrAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    corrSecondAddressObj.imgUrl = "";
    corrSecondAddressObj.fileName = fileName;
    corrSecondAddressObj.base64 = val!.split(",")[1];
    setCorrSecondAddressObj(corrSecondAddressObj);
    if (
      fileName.split(".")[1] !== "pdf" &&
      corrSecondAddressObj.documentId !== 133
    ) {
      setCorrSecondDoc(true);
      if (
        corrAddressObj.documentId === aadhaarDocumnetId ||
        corrAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: corrSecondAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: corrSecondAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        const uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      setCorrSecondDoc(false);
      setCorrAnotherFile(false);
    }
  }

  const addDocument = () => {
    if (secondDoc) {
      setAnotherFile(true);
    } else {
      setAnotherFile(false);
    }
  };
  const addCorrDocument = () => {
    if (corrSecondDoc) {
      setCorrAnotherFile(true);
    } else {
      setCorrAnotherFile(false);
    }
  };
  const maskedAadhar = (
    res: any,
    id: string,
    fileName: string,
    type: string
  ) => {
    if (!res || res.error) {
      setIsLoading(false);
      // gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR138" });
      if (res.data.error) {
        setErrCode("Aadhar Masking failed: " + res.data.error);
        setIsLoading(false);
      } else {
        setErrCode(res.error);
      }
    } else if (res.data) {
      if (res.data.error) {
        setErrCode("Aadhar Masking failed: " + res.data.error);
        setIsLoading(false);
      } else {
        let uploadObj: uploadDocObj;
        if (id === "permAddress") {
          if (res.data.result.id_number_found) {
            permAddressObj.base64 = res.data.result.base64_image;
            permAddressObj.mimeType = "image/jpeg";
            permAddressObj.fileName =
              permAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setPermAddressObj(permAddressObj);
          }

          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: permAddressObj.base64 || "",
            filename: permAddressObj.fileName,
          };
        } else if (id === "permSecondAddress") {
          if (res.data.result.id_number_found) {
            permSecondAddressObj.base64 = res.data.result.base64_image;
            permSecondAddressObj.mimeType = "image/jpeg";
            permSecondAddressObj.fileName =
              permSecondAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setPermSecondAddressObj(permSecondAddressObj);
          }
          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: permSecondAddressObj.base64 || "",
            filename: permSecondAddressObj.fileName,
          };
        } else if (id === "corrAddress") {
          if (res.data.result.id_number_found) {
            corrAddressObj.base64 = res.data.result.base64_image;
            corrAddressObj.mimeType = "image/jpeg";
            corrAddressObj.fileName =
              corrAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setCorrAddressObj(corrAddressObj);
          }
          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: corrAddressObj.base64 || "",
            filename: corrAddressObj.fileName,
          };
        } else {
          if (res.data.result.id_number_found) {
            corrSecondAddressObj.base64 = res.data.result.base64_image;
            corrSecondAddressObj.mimeType = "image/jpeg";
            corrSecondAddressObj.fileName =
              corrSecondAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setCorrSecondAddressObj(corrSecondAddressObj);
          }
          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: corrSecondAddressObj.base64 || "",
            filename: corrSecondAddressObj.fileName,
          };
        }
        AUploadDocument(
          (res: any) =>
            getUploadRes(res, id, uploadObj.base64document, uploadObj.filename),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      // gtmService({
      //   action: "ExtractDocumentData",
      //   label: "ERROR || ERR138-S",
      // });
    }
  };
  function handErrorForFileUpload(id: string, res: any) {
    if (id === "permAddress") {
      setPermAddListError(res.error);
    }
    if (id === "permSecondAddress") {
      setPermSecAddListError(res.error);
    }
    if (id === "corrAddress") {
      setCorrAddListError(res.error);
    }

    if (id === "corrSecondAddress") {
      setCorrSecAddListError(res.error);
    }
  }
  const getUploadRes = (res: any, id: string, val: any, fileName: string) => {
    if (!res || res.error) {
      // gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR140" });
      setIsLoading(false);
      handErrorForFileUpload(id, res);
      setErrCode(res.error);
    } else {
      if (res.data) {
        setErrCode("");
        if (id === "permAddress" && fileName.split(".")[1] === "pdf") {
          setIsLoading(false);
          setPermAddListError("");
          setIsNewPermAddress(true);
          setAddressName(res.data.Guid);
          const addressOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: permAddressObj.base64,
            documentcategory: permAddressObj.documentcategory,
            documentid: permAddressObj.documentId,
            source: DIY,
          };
          if (permAddressObj.documentId === aadhaarDocumnetId) {
            setIsLoading(true);
            AextractAadhaar(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (permAddressObj.documentId === passportDocumnetId) {
            setIsLoading(true);
            AextractPassport(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (permAddressObj.documentId === voterIdDocumentId) {
            setIsLoading(true);
            AextractVoterid(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (permAddressObj.documentId === dlDocumentId) {
            setIsLoading(true);
            AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
          }
        } else if (id === "permAddress" && fileName.split(".")[1] !== "pdf") {
          setPermAddListError("");
          setIsNewPermAddress(true);
          setAddressName(res.data.Guid);
          const extractPermOCRObj = {
            applicationid: GlobalStore.applicationID,
            base64document: permAddressObj.base64,
            documentcategory: permAddressObj.documentcategory,
            documentid: permAddressObj.documentId,
            source: DIY,
          };
          console.log(res.data.Guid, "check 1");

          if (permAddressObj.documentId === aadhaarDocumnetId) {
            setIsLoading(true);
            AextractAadhaar(
              (res: any) => storeOcrData(res, extractPermOCRObj),
              extractPermOCRObj,
              "OCR"
            );
          } else if (permAddressObj.documentId === passportDocumnetId) {
            setIsLoading(true);
            AextractPassport(
              (res: any) => storeOcrData(res, extractPermOCRObj),
              extractPermOCRObj
            );
          } else if (permAddressObj.documentId === voterIdDocumentId) {
            setIsLoading(true);
            AextractVoterid(
              (res: any) => storeOcrData(res, extractPermOCRObj),
              extractPermOCRObj
            );
          } else if (permAddressObj.documentId === dlDocumentId) {
            setIsLoading(true);
            AextractDl(
              (res: any) => storeOcrData(res, extractPermOCRObj),
              extractPermOCRObj
            );
          } else setIsLoading(false);
        } else if (id === "permSecondAddress") {
          setPermSecAddListError("");
          setIsLoading(true);
          setIsNewPermAddress(true);
          let uploadImageObj = {
            applicationId: GlobalStore.applicationID,
            image1: permAddressObj.base64,
            image2: permSecondAddressObj.base64,
            imagename1: permAddressObj.fileName,
            imagename2: permSecondAddressObj.fileName,
          };
          AgetPdf(getPDFData, uploadImageObj);
        } else if (id === "corrAddress" && fileName.split(".")[1] === "pdf") {
          setIsLoading(false);
          setCorrAddListError("");
          setIsNewCorrAddress(true);
          setCorrAddressName(res.data.Guid);
          const addressOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: corrAddressObj.base64,
            documentcategory: corrAddressObj.documentcategory,
            documentid: corrAddressObj.documentId,
            source: DIY,
          };
          if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
            setIsLoading(true);
            AextractAadhaar(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR,
              "OCR"
            );
          } else if (corrAddressObj.documentId === corrPassportDocumnetId) {
            setIsLoading(true);
            AextractPassport(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (corrAddressObj.documentId === corrVoterIdDocumentId) {
            setIsLoading(true);
            AextractVoterid(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (corrAddressObj.documentId === corrDlDocumentId) {
            setIsLoading(true);
            AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
          }
        } else if (id === "corrAddress" && fileName.split(".")[1] !== "pdf") {
          setIsNewCorrAddress(true);
          setCorrAddListError("");
          setCorrAddressName(res.data.Guid);
          const extractCorrsOCRObj = {
            applicationid: GlobalStore.applicationID,
            base64document: corrAddressObj.base64,
            documentcategory: corrAddressObj.documentcategory,
            documentid: corrAddressObj.documentId,
            source: DIY,
          };
          if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
            setIsLoading(true);

            AextractAadhaar(
              (res: any) => storeOcrData(res, extractCorrsOCRObj),
              extractCorrsOCRObj,
              "OCR"
            );
          } else if (corrAddressObj.documentId === corrPassportDocumnetId) {
            setIsLoading(true);
            AextractPassport(
              (res: any) => storeOcrData(res, extractCorrsOCRObj),
              extractCorrsOCRObj
            );
          } else if (corrAddressObj.documentId === corrVoterIdDocumentId) {
            setIsLoading(true);
            AextractVoterid(
              (res: any) => storeOcrData(res, extractCorrsOCRObj),
              extractCorrsOCRObj
            );
          } else if (corrAddressObj.documentId === corrDlDocumentId) {
            setIsLoading(true);
            AextractDl(
              (res: any) => storeOcrData(res, extractCorrsOCRObj),
              extractCorrsOCRObj
            );
          } else setIsLoading(false);
        } else if (
          id === "corrAddress" &&
          fileName.split(".")[1] !== "pdf" &&
          corrSecondAddressObj.base64 !== undefined
        ) {
          setIsLoading(true);
          setCorrAddListError("");
          setIsNewCorrAddress(true);
          let uploadImageObj = {
            applicationId: GlobalStore.applicationID,
            image1: corrAddressObj.base64,
            image2: corrSecondAddressObj.base64,
            imagename1: corrAddressObj.fileName,
            imagename2: corrSecondAddressObj.fileName,
          };
          AgetPdf(getCorrPDFData, uploadImageObj);
        } else if (id === "corrSecondAddress") {
          setIsLoading(true);
          setCorrSecAddListError("");
          setIsNewCorrAddress(true);
          let uploadImageObj = {
            applicationId: GlobalStore.applicationID,
            image1: corrAddressObj.base64,
            image2: corrSecondAddressObj.base64,
            imagename1: corrAddressObj.fileName,
            imagename2: corrSecondAddressObj.fileName,
          };
          AgetPdf(getCorrPDFData, uploadImageObj);
        }
      } else {
        setErrCode("1365- Error in uploading the file.");
        // gtmService({
        //   action: "UploadDocumentToS3",
        //   label: "ERROR || ERR140-S",
        // });
      }
    }
  };
  const storeOcrData = (res: any, id: any) => {
    if (!res || res.error) {
      if (res?.data?.error) {
        // gtmService({
        //   action: "ExtractDocumentData",
        //   label: "ERROR || ERR134-E",
        // });
      } else {
        setErrCode(res.error);
        // gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR134" });
      }
      setIsLoading(false);
    } else if (res.data) {
      setErrCode("");

      setIsLoading(false);
      if (id.documentid === aadhaarDocumnetId) {
        GlobalStore.aadhaarOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        if (!GlobalStore.aadhaarOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setPermanentAddress({ ...initialAddressObj });
        } else {
          let permExtractionData = res?.data?.result?.extraction_output;
          setPermanentAddress({
            ...permanentAddress,
            addressLine1: permExtractionData?.address,
            City: permExtractionData?.district,
            State: permExtractionData?.state,
            PinCode: permExtractionData.pincode,
            clientName: permExtractionData?.name_on_card,
            documentNumber:
              permExtractionData?.id_number?.length > 4
                ? `XXXX XXXX ${permExtractionData?.id_number?.slice(-4)}`
                : permExtractionData?.id_number
                ? `XXXX XXXX ${permExtractionData?.id_number}`
                : "",
          });
          // setAadharNo(permExtractionData?.id_number);
          //if (res?.data?.result?.qr_detected) {
          if (
            res?.data?.result?.qr_detected &&
            res?.data?.result?.qr_encoded &&
            res?.data?.result?.qr_scannable
          ) {
            setAutQcFlagPer(true);
          } else {
            setAutQcFlagPer(false);
          }

          if (res?.data?.result?.qr_detected) {
            setQrVerifiedAadhar(true);
            setErrCode("");
          } else {
            setQrVerifiedAadhar(false);
            setErrCode("Please upload a valid Document");
          }
          if (
            permExtractionData?.id_number === null ||
            permExtractionData?.id_number === ""
          ) {
            alert("please Upload front side of Adhaar as well");
          }
        }
      } else if (id.documentid === passportDocumnetId) {
        GlobalStore.passportOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";

        if (!GlobalStore.passportOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setPermanentAddress({ ...initialAddressObj });
        } else {
          let permExtractionData = res?.data?.result?.extraction_output;
          setPermanentAddress({
            ...permanentAddress,
            addressLine1: permExtractionData?.address,
            City: permExtractionData?.district,
            State: permExtractionData?.state,
            PinCode: permExtractionData.pincode,
            clientName: permExtractionData?.name_on_card,
            documentNumber: permExtractionData?.id_number,
            // dateOfIssue: typeof permExtractionData?.date_of_issue == "string" ? permExtractionData?.date_of_issue : undefined,
            // dateOfExpiry: typeof permExtractionData?.date_of_expiry == "string" ? permExtractionData?.date_of_expiry : undefined,
            dateOfIssue: permExtractionData?.date_of_issue,
            dateOfExpiry: permExtractionData?.date_of_expiry,
          });
          console.log(
            typeof permExtractionData.date_of_issue == "string",
            "||",
            typeof permExtractionData.date_of_expiry,
            "===?",
            new Date() < permExtractionData?.date_of_expiry
          );
          if (
            permExtractionData.id_number === null ||
            permExtractionData.id_number === ""
          ) {
            alert("please Upload other side of Passport as well");
          }
        }
      } else if (id.documentid === voterIdDocumentId) {
        GlobalStore.voterIdOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";

        if (!GlobalStore.voterIdOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setPermanentAddress({ ...initialAddressObj });
        } else {
          let permExtractionData = res?.data?.result?.extraction_output;
          setPermanentAddress({
            ...permanentAddress,
            addressLine1: permExtractionData?.address,
            City: permExtractionData?.district,
            State: permExtractionData?.state,
            PinCode: permExtractionData.pincode,
            clientName: permExtractionData?.name_on_card,
            documentNumber: permExtractionData?.id_number,
          });

          if (
            permExtractionData.id_number === null ||
            permExtractionData.id_number === ""
          ) {
            alert("Please upload the other side of the Voter ID as well.");
          }
        }
      } else if (id.documentid === dlDocumentId) {
        GlobalStore.dlOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        if (!GlobalStore.dlOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setPermanentAddress({ ...initialAddressObj });
        } else {
          let permExtractionData = res?.data?.result?.extraction_output;

          setPermanentAddress({
            ...permanentAddress,
            addressLine1: permExtractionData?.address,
            City: permExtractionData?.district,
            State: permExtractionData?.state,
            PinCode: permExtractionData.pincode,
            documentNumber: permExtractionData?.id_number,
            clientName: permExtractionData?.name_on_card,
            dateOfIssue:
              moment(
                moment(permExtractionData?.issue_dates, "DD/MM/YYYY")
              ).format("YYYY/MM/DD") == "Invalid date"
                ? undefined
                : moment(
                    moment(permExtractionData?.issue_dates, "DD/MM/YYYY")
                  ).format("DD/MM/YYYY"),
            dateOfExpiry:
              moment(
                moment(permExtractionData?.date_of_validity, "DD/MM/YYYY")
              ).format("YYYY/MM/DD") == "Invalid date"
                ? undefined
                : moment(
                    moment(permExtractionData?.date_of_validity, "DD/MM/YYYY")
                  ).format("DD/MM/YYYY"),
          });
          let verifyDrivingObj = {
            ActionName: "verify_with_source",
            ApplicationId: GlobalStore.applicationID,
            DateOfBirth: permExtractionData?.date_of_birth,
            DocumentType: "ind_driving_license",
            Source: "DIY",
            //IdNumber: permExtractionData?.id_number ? null : permExtractionData?.id_number.replace(" ", ""),
            IdNumber:
              permExtractionData?.id_number !== null
                ? permExtractionData.id_number
                : "",
          };
          AVerifyDocumentData(
            (res: any) => verifyDocumentRes(res),
            verifyDrivingObj
          );
        }
      } else if (id.documentid === corrAadhaarDocumnetId) {
        GlobalStore.corrAadhaarOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        if (!GlobalStore.corrAadhaarOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setCorrespondanceAddress({ ...initialAddressObj });
        } else {
          let corrsExtractionData = res?.data?.result?.extraction_output;
          setCorrespondanceAddress({
            ...correspondanceAddress,
            addressLine1: corrsExtractionData?.address,
            City: corrsExtractionData?.district,
            State: corrsExtractionData?.state,
            PinCode: corrsExtractionData.pincode,
            clientName: corrsExtractionData?.name_on_card,
            documentNumber:
              corrsExtractionData?.id_number?.length > 4
                ? `XXXX XXXX ${corrsExtractionData?.id_number?.slice(-4)}`
                : corrsExtractionData?.id_number
                ? `XXXX XXXX ${corrsExtractionData?.id_number}`
                : "",
          });
          if (
            corrsExtractionData.id_number === null ||
            corrsExtractionData.id_number === ""
          ) {
            alert("please Upload front side of Adhaar as well");
          }
          // setAadharNo(corrsExtractionData?.id_number);
          //if (res?.data?.result?.qr_detected) {

          if (
            res?.data?.result?.qr_detected &&
            res?.data?.result?.qr_encoded &&
            res?.data?.result?.qr_scannable
          ) {
            setAutQcFlagCor(true);
          } else {
            setAutQcFlagCor(false);
          }

          if (res?.data?.result?.qr_detected) {
            setQrVerifiedCorrsAadhar(true);
            setErrCode("");
          } else {
            setQrVerifiedCorrsAadhar(false);
            setErrCode("Please upload a valid Document");
          }
        }
      } else if (id.documentid === corrPassportDocumnetId) {
        GlobalStore.corrPassportOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        if (!GlobalStore.corrPassportOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setCorrespondanceAddress({ ...initialAddressObj });
        } else {
          let corrsExtractionData = res?.data?.result?.extraction_output;
          setCorrespondanceAddress({
            ...correspondanceAddress,
            addressLine1: corrsExtractionData?.address,
            City: corrsExtractionData?.district,
            State: corrsExtractionData?.state,
            PinCode: corrsExtractionData.pincode,
            clientName: corrsExtractionData?.name_on_card,
            documentNumber: corrsExtractionData?.id_number,
            dateOfIssue: corrsExtractionData?.date_of_issue,
            dateOfExpiry: corrsExtractionData?.date_of_expiry,
          });
        }
      } else if (id.documentid === corrVoterIdDocumentId) {
        GlobalStore.corrVoterIdOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        if (!GlobalStore.corrVoterIdOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setCorrespondanceAddress({ ...initialAddressObj });
        } else {
          let corrsExtractionData = res?.data?.result?.extraction_output;
          setCorrespondanceAddress({
            ...correspondanceAddress,
            addressLine1: corrsExtractionData?.address,
            City: corrsExtractionData?.district,
            State: corrsExtractionData?.state,
            PinCode: corrsExtractionData.pincode,
            clientName: corrsExtractionData?.name_on_card,
            documentNumber: corrsExtractionData?.id_number,
          });
        }
      } else if (id.documentid === corrDlDocumentId) {
        GlobalStore.corrDlOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        if (!GlobalStore.corrDlOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
          setCorrespondanceAddress({ ...initialAddressObj });
        } else {
          let corrsExtractionData = res?.data?.result?.extraction_output;
          setCorrespondanceAddress({
            ...correspondanceAddress,
            addressLine1: corrsExtractionData?.address,
            City: corrsExtractionData?.district,
            State: corrsExtractionData?.state,
            PinCode: corrsExtractionData.pincode,
            documentNumber: corrsExtractionData?.id_number,
            clientName: corrsExtractionData?.name_on_card,
            dateOfIssue:
              moment(
                moment(corrsExtractionData?.issue_dates, "DD/MM/YYYY")
              ).format("YYYY/MM/DD") == "Invalid date"
                ? undefined
                : moment(
                    moment(corrsExtractionData?.issue_dates, "DD/MM/YYYY")
                  ).format("DD/MM/YYYY"),

            dateOfExpiry:
              moment(
                moment(corrsExtractionData?.date_of_validity, "DD/MM/YYYY")
              ).format("YYYY/MM/DD") == "Invalid date"
                ? undefined
                : moment(
                    moment(corrsExtractionData?.date_of_validity, "DD/MM/YYYY")
                  ).format("DD/MM/YYYY"),
          });
          let verifyDrivingObj = {
            ActionName: "verify_with_source",
            ApplicationId: GlobalStore.applicationID,
            DateOfBirth: corrsExtractionData?.date_of_birth,
            DocumentType: "ind_driving_license",
            Source: "DIY",
            // IdNumber: corrsExtractionData?.id_number ? null : corrsExtractionData?.id_number.replace(" ", ""),
            IdNumber:
              corrsExtractionData?.id_number !== null
                ? corrsExtractionData.id_number
                : "",
          };
          AVerifyDocumentData(
            (res: any) => corrsVerifyDocumentRes(res),
            verifyDrivingObj
          );
        }
      }
    } else {
      // gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR134-S" });
    }
  };
  const corrsVerifyDocumentRes = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data && res?.data.verified) {
        setErrCode("");
        SetCorrsDrivingFlag(true);
      } else {
        setErrCode(res?.data?.Message);
        SetCorrsDrivingFlag(false);
      }
      setIsLoading(false);
    }
  };
  const verifyDocumentRes = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data && res?.data.verified) {
        setErrCode("");
        setDrivingFlag(true);
      } else {
        setErrCode(res?.data?.Message);
        setDrivingFlag(false);
      }
      setIsLoading(false);
    }
  };
  const getPDFData = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
      // gtmService({
      //   action: "MargeMultipleImagestoPDF",
      //   label: "ERROR || ERR136",
      // });
      setIsLoading(false);
    } else {
      if (res.data) {
        setIsLoading(false);
        setAddressName(res.data.Guid);
        permAddressObj.mimeType = "application/pdf";
        const addressOCR = {
          applicationid: GlobalStore.applicationID,
          base64document: res.data.Mergedimagespdfbase64,
          documentcategory: permAddressObj.documentcategory,
          documentid: permAddressObj.documentId,
          source: DIY,
        };
        if (permAddressObj.documentId === aadhaarDocumnetId) {
          setIsLoading(true);
          AextractAadhaar(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR,
            "OCR"
          );
        } else if (permAddressObj.documentId === passportDocumnetId) {
          setIsLoading(true);
          AextractPassport(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (permAddressObj.documentId === voterIdDocumentId) {
          setIsLoading(true);
          AextractVoterid(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (permAddressObj.documentId === dlDocumentId) {
          setIsLoading(true);
          AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
        }
      } else {
        // gtmService({
        //   action: "MargeMultipleImagestoPDF",
        //   label: "ERROR || ERR136-S",
        // });
      }
    }
  };
  const getCorrPDFData = (res: any) => {
    if (!res || res.error) {
      gtmService({
        action: "MargeMultipleImagestoPDF",
        label: "ERROR || ERR137",
      });
      setIsLoading(false);
    } else {
      if (res.data) {
        setIsLoading(false);
        setCorrAddressName(res.data.Guid);
        corrAddressObj.mimeType = "application/pdf";
        const addressOCR = {
          applicationid: GlobalStore.applicationID,
          base64document: res.data.Mergedimagespdfbase64,
          documentcategory: corrAddressObj.documentcategory,
          documentid: corrAddressObj.documentId,
          source: DIY,
        };
        if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
          setIsLoading(true);
          AextractAadhaar(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR,
            "OCR"
          );
        } else if (corrAddressObj.documentId === corrPassportDocumnetId) {
          setIsLoading(true);
          AextractPassport(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (corrAddressObj.documentId === corrVoterIdDocumentId) {
          setIsLoading(true);
          AextractVoterid(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (corrAddressObj.documentId === corrDlDocumentId) {
          setIsLoading(true);
          AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
        }
      } else {
        // gtmService({
        //   action: "MargeMultipleImagestoPDF",
        //   label: "ERROR || ERR137-S",
        // });
      }
    }
  };
  const onUploadProgress = (
    event: ProgressEvent,
    id: string,
    fileName: string,
    type: string
  ) => {
    const { loaded, total } = event;
    let percentage = Math.floor((loaded * 100) / total);
    let permAddressCopy = { ...permAddressObj };
    let permSecondAddressCopy = { ...permSecondAddressObj };
    let corrAddressCopy = { ...corrAddressObj };
    let corrSecondAddressCopy = { ...corrSecondAddressObj };
    console.log(fileName, "fileName check 2");
    if (percentage < 100) {
      if (id === "permAddress") {
        permAddressCopy.id = id;
        permAddressCopy.disable = false;
        permAddressCopy.uploadPer = percentage;
        permAddressCopy.hideProgress = true;
        permAddressCopy.showFile = false;
        setPermAddressObj(permAddressCopy);
      } else if (id === "permSecondAddress") {
        permSecondAddressCopy.id = id;
        permSecondAddressCopy.disable = false;
        permSecondAddressCopy.uploadPer = percentage;
        permSecondAddressCopy.hideProgress = true;
        permSecondAddressCopy.showFile = false;
        setPermSecondAddressObj(permSecondAddressCopy);
      } else if (id === "corrAddress") {
        corrAddressCopy.id = id;
        corrAddressCopy.disable = false;
        corrAddressCopy.uploadPer = percentage;
        corrAddressCopy.hideProgress = true;
        corrAddressCopy.showFile = false;
        setCorrAddressObj(corrAddressCopy);
      } else if (id === "corrSecondAddress") {
        corrSecondAddressCopy.id = id;
        corrSecondAddressCopy.disable = false;
        corrSecondAddressCopy.uploadPer = percentage;
        corrSecondAddressCopy.hideProgress = true;
        corrSecondAddressCopy.showFile = false;
        setCorrSecondAddressObj(corrSecondAddressCopy);
      }
    } else if (percentage === 100) {
      if (id === "permAddress") {
        permAddressCopy.id = id;
        permAddressCopy.disable = false;
        permAddressCopy.uploadPer = percentage;
        permAddressCopy.showFile = false;
        permAddressCopy.hideProgress = false;
        permAddressCopy.mimeType = type;
        permAddressCopy.fileName = fileName;
        setPermAddressObj(permAddressCopy);
      } else if (id === "permSecondAddress") {
        permSecondAddressCopy.id = id;
        permSecondAddressCopy.disable = false;
        permSecondAddressCopy.uploadPer = percentage;
        permSecondAddressCopy.showFile = false;
        permSecondAddressCopy.hideProgress = false;
        permSecondAddressCopy.mimeType = type;
        permSecondAddressCopy.fileName = fileName;
        setPermSecondAddressObj(permSecondAddressCopy);
      } else if (id === "corrAddress") {
        corrAddressCopy.id = id;
        corrAddressCopy.disable = false;
        corrAddressCopy.uploadPer = percentage;
        corrAddressCopy.showFile = false;
        corrAddressCopy.hideProgress = false;
        corrAddressCopy.mimeType = type;
        corrAddressCopy.fileName = fileName;
        setCorrAddressObj(corrAddressCopy);
      } else if (id === "corrSecondAddress") {
        corrSecondAddressCopy.id = id;
        corrSecondAddressCopy.disable = false;
        corrSecondAddressCopy.uploadPer = percentage;
        corrSecondAddressCopy.showFile = false;
        corrSecondAddressCopy.hideProgress = false;
        corrSecondAddressCopy.mimeType = type;
        corrSecondAddressCopy.fileName = fileName;
        setCorrSecondAddressObj(corrSecondAddressCopy);
      }
    }
  };
  function handleSameAsAbove(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === "sameAscorrespondance") {
      sameAsPermanent && setCorrespondanceAddress({ ...initialAddressObj });
      !sameAsPermanent && setCorrespondanceAddress({ ...permanentAddress });
      if (!sameAsPermanent) {
        setCorrErrorObj({});
      }
      setSameAsPermanent(!sameAsPermanent);
      setCorrAddressObj({ ...initialDocObj });
      setCorrSecondAddressObj({ ...initialPanDocObj });
      setErrors({ ...errors, correspondanceAddress: "" });
      setDefaultCorrAddress("");
      setQrVerifiedCorrsAadhar(false);
      SetCorrsDrivingFlag(false);
    }
  }
  const removeSpclCracters = (value: string) => {
    if (value) {
      return value; //.replace(/[^a-zA-Z0-9 ]/g, " ");
    } else {
      return "";
    }
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    saveAddressDetails();
  };
  const saveAddressDetails = () => {
    const correspondanceAddressLine = repairAddressWithConditions(
      correspondanceAddress.addressLine1,
      correspondanceAddress.City
    );
    const permanentAddressLine = repairAddressWithConditions(
      permanentAddress.addressLine1,
      permanentAddress.City
    );
    let saveAddressPayload = {
      ApplicationId: GlobalStore.applicationID,
      corrsaddraspermanent: sameAsPermanent,
      CorrespondenceAddressDetails: {
        CorrespondenceAddressLine1:
          removeSpclCracters(correspondanceAddressLine.addLine1) || "",
        CorrespondenceAddressLine2:
          removeSpclCracters(correspondanceAddressLine.addLine2) || "",
        CorrespondenceAddressLine3:
          removeSpclCracters(correspondanceAddressLine.addLine3) || "",
        CorrespondenceAddressLine4:
          removeSpclCracters(correspondanceAddressLine.addLine4) || "",
        CorrespondencePincode: correspondanceAddress.PinCode,
        CorrespondenceCountry: correspondanceAddress.Country,
        CorrespondenceState: correspondanceAddress.State,
        CorrespondenceCity: correspondanceAddress.City,
        CorrespondenceDistrict: correspondanceAddress.District,
        CorrespondenceArea: correspondanceAddress.Area,
        CorrespondenceStatecode: Number(correspondanceAddress.StateCode),
        CorrespondenceCountryCode: Number(correspondanceAddress.CountryCode),
        CorrespondenceAddressType: addressType,
        autoQC: true,
        documentIssueDate: correspondanceAddress?.dateOfIssue
          ? correspondanceAddress?.dateOfIssue?.split("/").reverse()?.join("-")
          : null,

        documentExpiryDate: correspondanceAddress?.dateOfExpiry
          ? correspondanceAddress?.dateOfExpiry?.split("/").reverse()?.join("-")
          : null,

        documentName: correspondanceAddress.clientName
          ? correspondanceAddress.clientName
          : "",
        documentNumber:
          defaultCorrAddress === "Aadhaar Card"
            ? correspondanceAddress.documentNumber.slice(-4)
            : correspondanceAddress.documentNumber,
        DocumentId: corrAddressObj.documentId
          ? Number(corrAddressObj.documentId)
          : sameAsPermanent
          ? Number(permAddressObj.documentId)
          : "",
      },
      PermanentAddressDetails: {
        PermanentAddressLine1:
          removeSpclCracters(permanentAddressLine.addLine1) || "",
        PermanentAddressLine2:
          removeSpclCracters(permanentAddressLine.addLine2) || "",
        PermanentAddressLine3:
          removeSpclCracters(permanentAddressLine.addLine3) || "",
        PermanentAddressLine4:
          removeSpclCracters(permanentAddressLine.addLine4) || "",
        PermanentPincode: permanentAddress.PinCode,
        PermanentCountry: permanentAddress.Country,
        PermanentState: permanentAddress.State,
        PermanentCity: permanentAddress.City,
        PermanentDistrict: permanentAddress.District,
        Permanentarea: permanentAddress.Area,
        Permanentstatecode: Number(permanentAddress.StateCode),
        Permanentcountrycode: Number(permanentAddress.CountryCode),
        PermanentAddresstype: addressType,
        autoQC: true,
        documentIssueDate: permanentAddress?.dateOfIssue
          ? permanentAddress?.dateOfIssue?.split("/").reverse()?.join("-")
          : null,
        documentExpiryDate: permanentAddress?.dateOfExpiry
          ? permanentAddress?.dateOfExpiry?.split("/").reverse()?.join("-")
          : null,
        documentName: permanentAddress.clientName
          ? permanentAddress.clientName
          : "",
        documentNumber:
          defaultAddress === "Aadhaar Card"
            ? permanentAddress.documentNumber.slice(-4)
            : permanentAddress.documentNumber,
        DocumentId: permAddressObj.documentId
          ? Number(permAddressObj.documentId)
          : "",
      },
    };
    ASaveAddressDetails((res: any) => saveAddressRes(res), saveAddressPayload);
  };
  const saveDocument = () => {
    setIsLoading(true);
    const saveDetails = [];
    console.log(
      "PO=",
      permAddressObj,
      "==||==",
      permanentAddress,
      " ||  CO=",
      corrAddressObj,
      "==||==",
      correspondanceAddress
    );
    if (
      (permAddressObj.uploadPer === 100 && isNewPermAddress) ||
      permAddressObj.isWatsappFlag
    ) {
      let savepermAddressObj = {
        documentCategoryId: permAddressObj.documentCategoryId?.toString(),
        documentId: permAddressObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: addressName || permAddressObj.fileName,
        isfilecropped: true,
        mimeType: permAddressObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: permAddressObj.isWatsappFlag,
        personaldetailsid: "0",
        //autoqc: qrVerifiedAadhar ? qrVerifiedAadhar : false,
        autoqc: autoQCFlagPer,
        DocumentIssueDate: permanentAddress?.dateOfIssue
          ? permanentAddress?.dateOfIssue?.split("/")?.reverse()?.join("-")
          : null,
        DocumentName: permanentAddress?.clientName,
        documentNumber:
          defaultAddress === "Aadhaar Card"
            ? permanentAddress.documentNumber.slice(-4)
            : permanentAddress.documentNumber,
        IsDocumentVerified: drivingFlag ? drivingFlag : false,
        IsQRVerified: qrVerifiedAadhar ? qrVerifiedAadhar : false,
      };
      saveDetails.push(savepermAddressObj);
    }
    if (
      (corrAddressObj.uploadPer === 100 && isNewCorrAddress) ||
      corrAddressObj.isWatsappFlag
    ) {
      let saveCorrAddressObj = {
        documentCategoryId: corrAddressObj.documentCategoryId?.toString(),
        documentId: corrAddressObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: corrAddressName || corrAddressObj.fileName,
        isfilecropped: true,
        mimeType: corrAddressObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: corrAddressObj.isWatsappFlag,
        personaldetailsid: "0",
        // autoqc: qrVerifiedCorrsAadhar ? qrVerifiedCorrsAadhar : false,
        autoqc: autoQCFlagCor,
        DocumentIssueDate: correspondanceAddress?.dateOfIssue
          ? correspondanceAddress?.dateOfIssue?.split("/")?.reverse()?.join("-")
          : null,
        DocumentName: correspondanceAddress?.clientName,
        documentNumber:
          defaultAddress === "Aadhaar Card"
            ? correspondanceAddress.documentNumber.slice(-4)
            : correspondanceAddress.documentNumber,
        IsDocumentVerified: corrsDrivingFlag ? corrsDrivingFlag : false,
        IsQRVerified: qrVerifiedCorrsAadhar ? qrVerifiedCorrsAadhar : false,
      };
      saveDetails.push(saveCorrAddressObj);
    }

    const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");
    const saveObj = {
      applicationId: GlobalStore.applicationID,
      clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
      documentInfo: saveDetails,
      leadId: GlobalStore.leadId,
      objUTM_Info: objUTM_Info,
    };
    ASavePersonalDocument(getSaveStatus, saveObj);
  };

  const getSaveStatus = (res: any) => {
    if (!res || res.error) {
      //setErrCode(1641)
      setErrCode(res.error);
      gtmService({
        action: "SaveUploadedDocumentDetails",
        label: "ERROR || ERR133",
      });
      setIsLoading(false);
    } else {
      if (res.data) {
        // Clevertap event: submit documents data
        const data = {
          EventName:
            cleverTapEvents.registration.documentVerificationPage
              .CLICK_ON_SUBMIT,
          InitiationPlatform: "",
          UTM_SOURCE: props.UTM_SOURCE || "",
          UTM_MEDIUM: props.UTM_MEDIUM || "",
          UTM_CAMPAIGN: props.UTM_CAMPAIGN || "",
          KRA: GlobalStore.isKRA,
          ADHAR_LINKED_WITH_MOBILE: "",
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          ADDRESS_PROOF_SUBMITTED_VIA: "upload_now",
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
        };

        ClevertapService.pushEvent(data);
        // Clevertap ends here

        // Apps flyer event for click on proceed on plan details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.documentVerificationPage.CLICK_ON_SUBMIT
        );
        // AGetPendingDocument(pendingDocQC, GlobalStore.applicationID);
      } else {
        setIsLoading(false);
        gtmService({
          action: "SaveUploadedDocumentDetails",
          label: "ERROR || ERR133-S",
        });
      }
    }
  };

  const saveAddressRes = (res: any) => {
    if (!res || res.error) {
      // setErrCode("164- Failed to get the document status for the application.");
      setErrCode(res.error);
    } else {
      if (res?.data && res?.data?.result) {
        saveDocument();
        completeModuleStatus(dashboardUrl.personalIfo);
        // gtmService({ action: "PROFILE_DETAILS", label: "Step_Complete" });

        history.push(incompleteStageNavigation());

        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        GlobalStore.personaldetails.pincode = permanentAddress.PinCode;
        GlobalStore.isManual = true;
        clientPartialDetails_ao.PinCode = permanentAddress.PinCode;
        clientPartialDetails_ao.isManual = true;
        clientPartialDetails_ao.sameAsPermanent = sameAsPermanent;
        clientPartialDetails_ao.addressLine1 = permanentAddress.addressLine1;
        if (!sameAsPermanent) {
          clientPartialDetails_ao.corrAddress =
            correspondanceAddress.addressLine1;
          clientPartialDetails_ao.corrPincode = correspondanceAddress.PinCode;
        }
        localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        localStorage.removeItem("appendedStage");
      } else {
        setErrCode(res?.data?.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="bnklHome">
      <div className="bnklHeading">Personal Documents</div>
      <div className="bnkSubHeading">
        Provide your personal details and address to recieve important letters
        and emails.
      </div>
      <div className={"formLayout " + (isLoading ? " op05" : "")}>
        <div className="mgt40">
          {/* Permanent Address Proof */}
          <label className="bankDoc"> Permanent Address Proof</label>
          <DropdownSearch
            name="identificationDetailPerm"
            defaultValue={
              identificationListPerm.find(
                (item) => item.display === defaultAddress
              )?.display
            }
            onOptionSelect={(val) => handlePermanentAddress(val)}
            searchLength={0}
            options={identificationListPerm}
            onBlur={(val) => handleAddressBlur(val, "permanentAddress")}
            errorText={errors.permanentAddress}
            searchKey="display"
            placeholder="Select Personal Address Proof"
            isDownArrow={true}
          />
          <label className="help_text blue mrt10">
            Upload clear scanned image of both front and back of the card or
            upload them in a single PDF.
          </label>
          {defaultAddress && (
            <div>
              <div className="mrt20">
                <DocumentBrowse
                  id="permAddress"
                  previewImageexternalClassName={"maxSizewithAnother"}
                  docObj={permAddressObj}
                  onDocUploadComplete={onFirstPermAddressUploadComplete}
                  isRemove={onFirstPermAddressRemove}
                />
                {permAddListError != "" ? (
                  <div>
                    {" "}
                    <br />
                    <ErrMsg>
                      <>
                        <label>{permAddListError}</label>
                      </>
                    </ErrMsg>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {anotherFile && (
                <div className="row">
                  <div className="mrt20">
                    <DocumentBrowse
                      id="permSecondAddress"
                      docObj={permSecondAddressObj}
                      previewImageexternalClassName={"maxSizewithAnother"}
                      onDocUploadComplete={onSecondPermAddressUploadComplete}
                      isRemove={onSecondPermAddressRemove}
                    />
                    {permSecAddListError != "" ? (
                      <div>
                        <br />
                        <ErrMsg>
                          <>
                            <label>{permSecAddListError}</label>
                          </>
                        </ErrMsg>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              <div className="mrt20">
                <label
                  className={secondDoc ? "addDoc" : "addDocDisable"}
                  onClick={() => addDocument()}
                >
                  <span className="edel-icon-plus"></span> Add Document
                </label>
              </div>
            </div>
          )}
        </div>
        <form className="PersonalInfoForm">
          <fieldset disabled={isLoading}>
            <>
              {(permanentAddress.addressLine1 ||
                permanentAddress.PinCode ||
                permanentAddress.City ||
                permanentAddress.State) && (
                <>
                  <AddressInfoField
                    defaultAddress={defaultAddress}
                    addressObj={permanentAddress}
                    addressErrorObj={permErrorObj}
                    onAddressInfoChange={(obj: IAddressFormat) => {
                      setPermanentAddress(obj);
                      sameAsPermanent && setCorrespondanceAddress(obj);
                    }}
                    onAddressInfoBlur={setPermErrorObj}
                    qrVerified={
                      qrVerifiedAadhar || defaultAddress === "Aadhaar Card"
                    }
                  />
                  <AddressField
                    addressObj={permanentAddress}
                    isDisable={
                      qrVerifiedAadhar || defaultAddress === "Aadhaar Card"
                    }
                    sectionLabel="Permanent Addr."
                    addressType="correspondance"
                    setErrCode={setErrCode}
                    onAddressChange={(obj: IAddressFormat) => {
                      setPermanentAddress(obj);
                      sameAsPermanent && setCorrespondanceAddress(obj);
                    }}
                    onAddressBlur={setPermErrorObj}
                    handleSameAsAbove={() => {}}
                  />
                </>
              )}
              {!sameAsPermanent && (
                <>
                  <br />
                  <br />
                  <label className="fieldLbl">
                    Correspondance Address Proof
                  </label>
                  <div className="mgt60 row">
                    <DropdownSearch
                      name="identificationDetailCorrs"
                      defaultValue={
                        identificationListCorr.find(
                          (item) => item.display === defaultCorrAddress
                        )?.display
                      }
                      onOptionSelect={(val) => handleCorrespondenceAddress(val)}
                      searchLength={0}
                      options={identificationListCorr}
                      onBlur={(val) =>
                        handleAddressBlur(val, "correspondanceAddress")
                      }
                      errorText={errors.correspondanceAddress}
                      searchKey="display"
                      placeholder="Select Personal Address Proof"
                      isDownArrow={true}
                    />

                    <div>
                      <label className="help_text blue mrt10">
                        Upload clear scanned image of both front and back of the
                        card or upload them in a single PDF.
                      </label>
                      {defaultCorrAddress && (
                        <>
                          <div className="mrt20">
                            <DocumentBrowse
                              id="corrAddress"
                              docObj={corrAddressObj}
                              previewImageexternalClassName={"maxSizewithAnother"}
                              onDocUploadComplete={
                                onFirstCorrAddressUploadComplete
                              }
                              isRemove={onFirstCorrAddressRemove}
                            />
                            {corrAddListError != "" ? (
                              <div>
                                {" "}
                                <br />
                                <ErrMsg>
                                  <>
                                    <label>{corrAddListError}</label>
                                  </>
                                </ErrMsg>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {corrAnotherFile && (
                            <div className="row">
                              <div className="mrt20">
                                <DocumentBrowse
                                  id="corrSecondAddress"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={corrSecondAddressObj}
                                  onDocUploadComplete={
                                    onSecondCorrAddressUploadComplete
                                  }
                                  isRemove={onSecondCorrAddressRemove}
                                />
                                {corrAddListError != "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>{corrAddListError}</label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                          <div className="mrt20">
                            <label
                              className={
                                corrSecondDoc ? "addDoc" : "addDocDisable"
                              }
                              onClick={() => addCorrDocument()}
                            >
                              <span className="edel-icon-plus"></span> Add
                              Document
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {(sameAsPermanent ||
                correspondanceAddress.addressLine1 ||
                correspondanceAddress.PinCode ||
                correspondanceAddress.City ||
                correspondanceAddress.State) && (
                <>
                  <AddressInfoField
                    defaultAddress={defaultCorrAddress}
                    addressObj={correspondanceAddress}
                    addressErrorObj={corrErrorObj}
                    onAddressInfoChange={setCorrespondanceAddress}
                    onAddressInfoBlur={setCorrErrorObj}
                    qrVerified={
                      qrVerifiedCorrsAadhar ||
                      defaultCorrAddress === "Aadhaar Card"
                    }
                  />
                  <AddressField
                    addressObj={correspondanceAddress}
                    isDisable={
                      qrVerifiedCorrsAadhar ||
                      defaultCorrAddress === "Aadhaar Card"
                    }
                    sectionLabel="Correspondence Addr."
                    addressType="correspondance"
                    setErrCode={setErrCode}
                    onAddressChange={setCorrespondanceAddress}
                    onAddressBlur={setCorrErrorObj}
                    showSameAsAbove
                    sameAsAbove={sameAsPermanent}
                    handleSameAsAbove={handleSameAsAbove}
                  />
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="termone"
                      onChange={() => {}}
                    />
                    <label className="mrl13 crrAddCheckBoxleft sectionLbl">
                      Confirm if the address mentioned is similar to above
                    </label>
                  </div>
                </>
              )}
            </>

            {errCode && (
              <div className="errMsgBlck errWidth mrt40">
                <ErrMsg>
                  {typeof errCode === "number" ? (
                    <>
                      <label>We are facing some technical issue.</label>
                      <label>
                        Please try after sometime (error code:{errCode})
                      </label>
                    </>
                  ) : (
                    <label>{errCode}</label>
                  )}
                </ErrMsg>
              </div>
            )}
            {/* {aadharNo && localAadharNo && localAadharNo != aadharNo && (
              <div className="errMsgBlck errWidth mrt40">
                <ErrMsg>
                  <>
                    <label>
                      Document is not valid Please upload Valid Document
                    </label>
                  </>
                </ErrMsg>
              </div>
            )} */}
            <div className="docSubBtn">
              <SubmitButton
                isLoading={isLoading}
                btnName="Save Address Details"
                onClick={handleSubmit}
                disabled={
                  Object.values(permanentAddress).some((value) => !value) ||
                  Object.values(correspondanceAddress).some(
                    (value) => !value
                  ) ||
                  Object.values(permErrorObj).some((error) => error) ||
                  Object.values(corrErrorObj).some((error) => error) ||
                  Object.values(errors).some((error) => error) ||
                  (defaultAddress === "Driving License" && !drivingFlag) ||
                  (defaultCorrAddress === "Driving License" &&
                    !corrsDrivingFlag) ||
                  (defaultAddress === "Aadhaar Card" && !qrVerifiedAadhar) ||
                  (defaultCorrAddress === "Aadhaar Card" &&
                    !qrVerifiedCorrsAadhar)
                  // ((defaultAddress === "Aadhaar Card" ||
                  //   defaultCorrAddress === "Aadhaar Card") &&
                  //   aadharNo &&
                  //   localAadharNo &&
                  //   localAadharNo != aadharNo)
                }
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default PersoanlInfo;
