import * as React from "react";
import { IListObj } from "../types/genericTypes";
import ExitScreen from "../UI/ExitScreen/ExitScreen";
import finalTick from "../../assets/icons/finalTick.svg";
import { AGetClientAccountCode } from "../Services/dashboard/eSignApiServices";
import GlobalStore from "../store/globalStore";
import StepsInfo from "./StepsInfo";
import cleverTapEvents from "../../config/cleverTapEvents";
import { ClevertapService } from "../Services/clevertapService";
import useQuery from "../../customHooks/useQuery";
import { AppsFlyerService } from "../Services/appsFlyerService";
import appsFlyerEvents from "../../config/appsFlyerEvents";

export interface SubmitAOProps {}

const SubmitAO: React.FC<SubmitAOProps> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const [displayInfoList, setDisplayInfoList] = React.useState<Array<IListObj>>(
    []
  );
  const [time, setTime] = React.useState<string>("");
  React.useEffect(() => {
    AGetClientAccountCode(getClientDetails, GlobalStore.applicationID);
  }, []);

  // Clevertap event while final page load
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.finalPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE: UTM_SOURCE,
      UTM_MEDIUM: UTM_MEDIUM,
      UTM_CAMPAIGN: UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
      STATUS_ON_FINAL_SCREEN: "ACCOUNT OPENING COMPLETED",
    };

    ClevertapService.pushEvent(data);
    // Apps flyer event for When Final Status screen is viewed
    AppsFlyerService.pushEvent(
      appsFlyerEvents.registration.finalPage.PAGE_LOAD
    );
  }, []);
  // Clevertap ends

  function getClientDetails(res: any) {
    if (!res || res.error) {
    } else {
      const responseObj = JSON.parse(res.data);
      const clientPartialDetails_ao = JSON.parse(
        window.localStorage.getItem("clientPartialDetails_ao") || "{}"
      );
      if (responseObj && responseObj.dpid) {
        let holderName = responseObj.holderfullname
          ? responseObj.holderfullname
          : clientPartialDetails_ao.PANName
          ? clientPartialDetails_ao.PANName
          : "";
        let mobile = responseObj.mobileno
          ? responseObj.mobileno
          : clientPartialDetails_ao.mobile
          ? clientPartialDetails_ao.mobile
          : "";
        let email = responseObj.emailid
          ? responseObj.emailid
          : clientPartialDetails_ao.emailID
          ? clientPartialDetails_ao.emailID
          : "";
        const displayInfo: Array<IListObj> = [
          {
            display: "Trading Account Details",
            value: responseObj.applicationid,
          },
          { display: "Name", value: holderName },
          {
            display: "Email ID",
            value: email,
          },
          {
            display: "Mobile Number",
            value: `+91 ${mobile}`,
          },
          { display: "Demat Account Number", value: responseObj.dpid },
        ];
        setTime(responseObj.esigncompletedtime);
        setDisplayInfoList(displayInfo);
      }
    }
  }

  React.useEffect(() => {
    if (displayInfoList.length > 0) {
      window.localStorage.removeItem("clientPartialDetails_ao");
    }
  }, [displayInfoList]);

  return (
    <ExitScreen
      // heading="Account Opening application submitted!"
      heading="Account Opening Submitted"
      info="Your account opening is now submitted, here’s some important details about your account"
      time={time}
      displayInfoList={displayInfoList}
      logo={finalTick}
    >
      <StepsInfo />
    </ExitScreen>
  );
};

export default SubmitAO;
