import React, { useState } from "react";
import Backdrop, { FullBackdrop } from "../UI/Backdrop/Backdrop";
import "./SelectPlan.scss";
import Button, {
  SubmitButton,
  TransparentButton,
  Verify,
} from "../../components/UI/Button/Button";
import planOne from "../../assets/icons/new_lite.png";
import mobPlanOne from "../../assets/icons/mob_new_lite.png";
import planTwo from "../../assets/icons/new_elite.png";
import mobPlanTwo from "../../assets/icons/mob_new_elite.png";
import arrowPoint from "../../assets/icons/arrow_point.png";
import Toggle from "../UI/Toggle/Toggle";
import Checkbox from "../UI/Checkbox/Checkbox";
import DeviceContext from "../Services/identifyDevice";
import {
  incomeProofList,
  dashboardUrl,
  edelweissElite,
  edelweissLite,
} from "../utils/constants";
import DocumentBrowse, {
  DocDetailFormat,
} from "../UI/DocumentBrowse/DocumentBrowse";
import GlobalStore from "../store/globalStore";
import { AGetEmployeeByPan } from "../Services/registration/panDobApiService";
import DropdownSearch from "../UI/DropdownSearch/DropdownSearch";
import {
  AGetBrokerage,
  AGetBrokerageDetails,
  ASaveBrokerageDetails,
  AGetBrokerageSegmentDetails,
  ASaveBrokerageSegmentDetiails,
  AUploadDocument,
} from "../Services/dashboard/brokerageServices";
import ErrMsg from "../UI/ErrMsg/ErrMsg";
import PdfContainer from "../UI/PdfContainer/PdfContainer";
import bp from "../../assets/pdf/T&C_BrokeragePlan.pdf";
import SMTF_PDF from "../../assets/pdf/SMTF.pdf";
import ao from "../../assets/pdf/NWIL-Booklet-A5-Nov 2022.pdf";
import dc from "../../assets/pdf/Disclaimer_DIY.pdf";
import {
  ADownloadDocument,
  AGetUploadedDocument,
  ASaveDocumentDetails,
} from "../Services/dashboard/docVerificationAPIServices";
import {
  completeModuleStatus,
  utmDurationInfoObj,
  incompleteStageNavigation,
} from "../Services/globalService";
import { useHistory } from "react-router-dom";
import { ACheckClientProfilingData } from "../Services/dashboard/profilerApiServices";
import { AGetStageCompletionStatus } from "../Services/registration/continueApiService";
import gtmService from "../Services/gtmService";
import useQuery from "../../customHooks/useQuery";
import cleverTapEvents from "../../config/cleverTapEvents";
import { ClevertapService } from "../Services/clevertapService";
import { AppsFlyerService } from "../Services/appsFlyerService";
import appsFlyerEvents from "../../config/appsFlyerEvents";
import { errorMessage } from "../utils/errorMessage";
import config from "../../config/domain";
import { pan } from "../utils/regex";
import { errorCode, errorMsg } from "../../ErrorCode/ErrorCode";
import { checkESignSubmitted } from "../utils/eSignSubmitHandler";

export interface SelectPlanprops {}

export const initialDocObj: DocDetailFormat = {
  id: "",
  disable: true,
  uploadPer: 0,
  hideProgress: true,
  fileName: "",
  showFile: true,
  imgUrl: "",
  documentCategoryId: "8",
  documentId: 0,
  mimeType: "",
};

const SelectPlan: React.FC<SelectPlanprops> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [eliteCard, setEliteCard] = useState<boolean>(false);
  const [liteCard, setLiteCard] = useState<boolean>(false);
  const [liteData, setliteData] = useState<any>();
  const [eliteData, setEliteData] = useState<any>();
  const [openPlanModal, setOpenPlanModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [segTog, setSegTog] = useState<boolean>(false);
  const [checkedDerNSE, setCheckedDerNSE] = useState<boolean>(false);
  const [checkedCurrNSE, setCheckedCurrNSE] = useState<boolean>(false);
  const [termOne, setTermOne] = useState<boolean>(true);
  const [checkedComMCXnNCXDX, setCheckedComMCXnNCXDX] =
    useState<boolean>(false);
  const [termTwo, setTermTwo] = useState<boolean>(false);
  const [termThree, setTermThree] = useState<boolean>(false);
  const [isLite, setIsLite] = useState<boolean>(true);
  const [isElite, setIsElite] = useState<boolean>(false);
  const [openLite, setOpenLite] = useState<boolean>(false);
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(true);
  const [errCode, setErrCode] = React.useState<number | string>();
  const [showPdfModal, setShowPdfModal] = useState<boolean>(false);
  const [pdfName, setPdfName] = useState<any>();
  const [incomeObj, setIncomeObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [defaultIncomeDoc, setDefaultIncomeDoc] = useState<string>("");
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const history = useHistory();
  const [liteResponse, setliteResponse] = React.useState<Array<any>>([]);
  const [eliteResponse, setEliteResponse] = React.useState<Array<any>>([]);
  const [active, setActive] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [incomeProofErr, setIncomeProofErr] = React.useState<string>("");
  const [accountType, setAccountType] = React.useState<any>(
    GlobalStore.stageStatus.dpaccounttype
  );
  const [accTypeToggle, setAccTypeToggle] = React.useState<boolean>(false);
  const [accountTypeChangeCount, setAccountTypeChangeCount] =
    React.useState<number>(0);
  const [SMTF, setSMTF] = useState<boolean>(true); //Added By Ravi
  const [isEmployeePAN, setisEmployeePAN] = useState<boolean>(false); //Added By Ravi

  const [checkedEquityCashNSEBSE, setCheckedEquityCashNSEBSE] =
    useState<boolean>(true);
  const [checkedMutualFundsBSE, setCheckedMutualFundsBSE] =
    useState<boolean>(true);
  const [checkedDebtFundsNSEBSE, setCheckedDebtFundsNSEBSE] =
    useState<boolean>(true);
  const [NoSegSelected, setNoSegSelected] = useState<boolean>(false);
  const [eSignSummited, setESignSummited] = useState<boolean>(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.sessionStorage.setItem("stageInfoId", "5");
    setESignSummited(checkESignSubmitted(GlobalStore.applicationID));
    setIsLoading(true);
    AGetBrokerage(getBrokerage, GlobalStore.applicationID);
    AGetUploadedDocument(getUploadedDoc, GlobalStore.applicationID);
    AGetBrokerageDetails(getBrokerageDetails, GlobalStore.applicationID);
    AGetBrokerageSegmentDetails(
      getBrokerageSegmentDetailsRes,
      GlobalStore.applicationID
    );

    const panFromLocalStorage = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    let panNum = "";
    if (
      GlobalStore.personaldetails.pan != undefined ||
      GlobalStore.personaldetails.pan != ""
    ) {
      panNum = GlobalStore.personaldetails.pan;
    } else if (panFromLocalStorage.pan != "") {
      panNum = panFromLocalStorage.pan != "" ? panFromLocalStorage.pan : "";
    }
    let isValidPan = pan.test(panNum);
    if (isValidPan) {
      AGetEmployeeByPan(checkIsPanEmployee, panNum);
    }
    AGetStageCompletionStatus(getClientStageDetails, GlobalStore.applicationID);
  }, []);

  // Clervertap event: Plan screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.planPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);
  }, []);

  function getClientStageDetails(res: any) {
    if (!res || res.error) {
      setErrCode(155);
      setErrCode(res.error);
    } else {
      if (res.data) {
        const response = JSON.parse(res.data);
        if (response.isbrokerageschemecompleted) {
          setTermOne(true);
          setTermTwo(true);
          setTermThree(true);
        }
      }
    }
  }
  function getBrokerage(res: any) {
    if (!res || res.error) {
      //setErrCode(155);
      setErrCode(res.error);
    } else {
      if (res.data) {
        if (
          res.data.Liteplan != null &&
          Object.keys(res.data.Liteplan).length !== 0
        ) {
          setliteData(res.data.Liteplan);
        }
        if (
          res.data.Eliteplan != null &&
          Object.keys(res.data.Eliteplan).length !== 0
        ) {
          setEliteData(res.data.Eliteplan);
        }
      }
    }
  }

  function getBrokerageDetails(res: any) {
    if (!res || res.error) {
      //setErrCode(159325);
      setErrCode(res.error);
    } else {
      if (res.data) {
        const resdata = res.data.brokerageDetails;
        const liteResdata = resdata.filter(
          (x: any) => x.ct_display_name === edelweissLite
        );
        const eliteResData = resdata.filter(
          (x: any) => x.ct_display_name === edelweissElite
        );
        liteResdata ? setliteResponse(liteResdata) : setliteResponse([]);
        eliteResData ? setEliteResponse(eliteResData) : setEliteResponse([]);
        if (liteResdata[0]?.is_selected_scheme) {
          setIsLite(true);
          setIsElite(false);
          setEliteCard(false);
          setLiteCard(true);
        } else if (eliteResData[0]?.is_selected_scheme) {
          setIsLite(false);
          setIsElite(true);
          setEliteCard(true);
          setLiteCard(false);
        } else if (liteResdata.length > 0) {
          setIsLite(true);
          setIsElite(false);
          setEliteCard(false);
          setLiteCard(true);
        } else if (eliteResData.length > 0) {
          setIsLite(false);
          setIsElite(true);
          setEliteCard(true);
          setLiteCard(false);
        } else {
          setIsLite(true);
          setIsElite(false);
        }
      }
    }
  }

  function getBrokerageSegmentDetailsRes(res: any) {
    if (!res || res.error) {
      //setErrCode(15500);
      setErrCode(res.error);
    } else {
      if (res.data) {
        setCheckedComMCXnNCXDX(false);
        //setCheckedEquityCashNSEBSE(false);
        //setCheckedMutualFundsBSE(false);
        setCheckedDebtFundsNSEBSE(false);

        const ischeckedData = res.data.dbelist.filter(
          (item: any) => item.Checked === true
        );
        if (ischeckedData.length > 0) {
          setSMTF(ischeckedData[0].SMTF); //Added By Ravi
        }

        if (
          ischeckedData.map((item: any) => {
            if (item.CBEList === "EBL-EQ-N") {
              setCheckedEquityCashNSEBSE(item.Checked);
            } else if (item.CBEList === "EBL-MF-BSE") {
              setCheckedMutualFundsBSE(item.Checked);
            } else if (item.CBEList === "EBL-Debt-NSE") {
              setCheckedDebtFundsNSEBSE(item.Checked);
            } else if (item.CBEList === "EBL-FO-N") {
              setCheckedDerNSE(true);
              setSegTog(true);
            } else if (item.CBEList === "EBL-CDS-N") {
              setCheckedCurrNSE(true);
              setSegTog(true);
            } else if (
              item.CBEList === "CX-NCDEX" ||
              item.CBEList === "CX-MCX"
            ) {
              setCheckedComMCXnNCXDX(true);
              setSegTog(true);
            }
          })
        ) {
        }
      }
    }
  }

  function checkIsPanEmployee(res: any) {
    if (!res || res.error) {
      // AIsPANExists(isPANExists, values.pan)
      setErrCode(res.error);
    } else {
      if (res.data) {
        const responseDataObj = res.data;
        if (responseDataObj.result.Flag === "Y") {
          setIsEmployee(false);
          setIsElite(true);
          setSMTF(false); //Added By Ravi
          setisEmployeePAN(true); //Added By Ravi
        } else {
          setIsEmployee(true);
          setisEmployeePAN(false); //Added By Ravi
        }
      } else {
        // AIsPANExists(isPANExists, values.pan)
      }
    }
    setIsLoading(false);
  }

  const getUploadedDoc = (res: any) => {
    if (!res || res.error) {
      // setErrCode(15514);
      setErrCode(res.error);
      setIsLoading(false);
    } else if (res.data && res.data !== "Record Not Found") {
      const uplDoc = JSON.parse(res.data);
      const isIncomeProof = uplDoc.filter(
        (x: any) => x.DocumentCategory === "Income Proof"
      );
      if (isIncomeProof.length > 0) {
        if (isIncomeProof[0].DocumentName === "Form 16") {
          setDefaultIncomeDoc("Form 16");
        } else if (isIncomeProof[0].DocumentName === "Salary Slip") {
          setDefaultIncomeDoc("Salary Slip");
        } else if (isIncomeProof[0].DocumentName === "ITR Acknowledgement") {
          setDefaultIncomeDoc("ITR Acknowledgement");
        } else if (isIncomeProof[0].DocumentName === "Bank Statement") {
          setDefaultIncomeDoc("Bank Statement");
        } else if (
          isIncomeProof[0].DocumentName === "Demat Holding Statement"
        ) {
          setDefaultIncomeDoc("Demat Holding Statement");
        } else if (isIncomeProof[0].DocumentName === "Networth Certificate") {
          setDefaultIncomeDoc("Networth Certificate");
        }
        incomeObj.id = "incomeDoc";
        incomeObj.disable = false;
        incomeObj.uploadPer = 100;
        incomeObj.hideProgress = true;
        incomeObj.fileName = isIncomeProof[0].FileName;
        incomeObj.showFile = false;
        incomeObj.hideProgress = false;
        incomeObj.mimeType = isIncomeProof[0].MimeType;
        incomeObj.documentId = isIncomeProof[0].DocumentId;
        setIncomeObj(incomeObj);
        ADownloadDocument(
          getIncomeImage,
          isIncomeProof[0].FileName,
          isIncomeProof[0].UploadedDocPath
        );
      }
    } else if (!res || res.error || res.data === "Record Not Found") {
      setErrCode(res.error);
    } else {
      setIncomeObj(incomeObj);
    }
  };

  const getIncomeImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode("162 - Failed to fetch the document.");
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data) {
        const base64 = "data:image/jpg;base64," + res.data.base64document;
        let fetchImage = { ...incomeObj };
        fetchImage.imgUrl = base64;
        setIncomeObj(fetchImage);
      }
    }
  };
  function dropDownBlur(value: any) {
    typeof value !== "object"
      ? setIncomeProofErr(errorMsg(errorCode.FE20))
      : setIncomeProofErr("");
  }

  const handleIncomeProof = (val: any) => {
    //val.preventDefault();
    if (val !== "") {
      incomeObj.disable = false;
      incomeObj.documentId = val.documentid;
      setIncomeObj(incomeObj);
      setDefaultIncomeDoc(val.documentname);
    }
  };

  function onIncomeUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    incomeObj.imgUrl = "";

    setIncomeObj(incomeObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(getUploadRes, uploadObj, (event: ProgressEvent) =>
      onUploadProgress(event, id, fileName, type)
    );
  }

  const onUploadProgress = (
    event: ProgressEvent,
    id: string,
    fileName: string,
    type: string
  ) => {
    const { loaded, total } = event;
    let percentage = Math.floor((loaded * 100) / total);
    let changeIncomeObj = { ...incomeObj };
    if (percentage < 100) {
      if (id === "incomeProof") {
        changeIncomeObj.id = id;
        changeIncomeObj.disable = false;
        changeIncomeObj.uploadPer = percentage;
        changeIncomeObj.showFile = false;
        changeIncomeObj.hideProgress = true;
        setIncomeObj(changeIncomeObj);
      }
    } else if (percentage === 100) {
      if (id === "incomeProof") {
        changeIncomeObj.id = id;
        changeIncomeObj.disable = false;
        changeIncomeObj.uploadPer = percentage;
        changeIncomeObj.showFile = false;
        changeIncomeObj.hideProgress = false;
        changeIncomeObj.mimeType = type;
        changeIncomeObj.fileName = fileName;
        setIncomeObj(changeIncomeObj);
        setActive(false);
      }
    }
  };

  const getUploadRes = (res: any) => {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode('150- Error in uploading the file.');
      // setErrCode("Document Uploaded is invalid. Upload correct document.");
      setErrCode(res.error);
    } else if (res.data && res.data.Result) {
      setIsLoading(false);
      let updateIncomeObj = { ...incomeObj };
      let saveDetails = [];
      let saveIncomeObj = {
        documentCategoryId: updateIncomeObj.documentCategoryId,
        documentId: updateIncomeObj.documentId,
        documentexpirydate: "",
        fileName: res.data.Guid,
        isfilecropped: true,
        mimeType: res.data.Guid.split(".")[1],
        panno: GlobalStore.personaldetails.pan,
        personaldetailsid: "0",
      };
      saveDetails.push(saveIncomeObj);
      const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");
      const saveObj = {
        applicationId: GlobalStore.applicationID,
        clientPrimaryDetailId: parseInt(GlobalStore.clientPrimaryDetailId) || 0,
        documentInfo: saveDetails,
        leadId: GlobalStore.leadId,
        objUTM_Info: objUTM_Info,
      };
      ASaveDocumentDetails(saveIncome, saveObj);
    } else {
      // setErrCode('150- Error in uploading the file.');
      setErrCode(res.error);
    }
  };

  const saveIncome = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res && res.data) {
      }
    }
  };

  const closeCharges = () => {
    setShowModal(false);
    setOpenPlanModal(false);
  };

  const openCharges = (val: string) => {
    if (val === "lite") {
      setOpenLite(true);
    } else if (val === "elite") {
      setOpenLite(false);
    }
    setShowModal(true);
    setOpenPlanModal(true);
  };

  const openPDF = (fileName: any) => {
    setPdfName(fileName);
    setShowPdfModal(true);
  };

  const handleTog = () => {
    setSegTog(!segTog);
    if (segTog === false) {
      setCheckedDerNSE(true);
      setCheckedCurrNSE(true);
      setCheckedComMCXnNCXDX(true);
    } else if (segTog === true) {
      setCheckedDerNSE(false);
      setCheckedCurrNSE(false);
      setCheckedComMCXnNCXDX(false);
    }
  };

  // const showInfo = (e: any) => {
  //     e.preventDefault();
  //     setShowPopup(true);
  // };

  // const closePopup = () => {
  //     setShowPopup(false);
  //     handleSubmit();
  // };

  const handleSubmit = (e: any) => {
    if (
      !checkedEquityCashNSEBSE &&
      !checkedMutualFundsBSE &&
      !checkedDebtFundsNSEBSE &&
      !checkedDerNSE &&
      !checkedCurrNSE &&
      !checkedComMCXnNCXDX
    ) {
      setNoSegSelected(true);
    } else {
      setIsLoading(true);
      e.preventDefault();
      setShowPopup(true);
      const objUTM_Info = utmDurationInfoObj(startTime, "productsdur");
      if (isElite && eliteResponse.length > 0) {
        const saveObj = {
          applicationId: GlobalStore.applicationID,
          cash: true,
          cashDeliveryActual: eliteResponse[0].cash_delivery_std,
          cashDeliveryMin: eliteResponse[0].cash_delivery_min,
          cashDeliverySide: "BOTH",
          cashIntradayActual: eliteResponse[0].cash_intraday_std,
          cashIntradayMin: eliteResponse[0].cash_intraday_min,
          cashIntradaySide: "BOTH",
          cds: true,
          cdsFutureActual: eliteResponse[0].cds_future_std,
          cdsFutureMin: eliteResponse[0].cds_future_min,
          cdsFutureSide: "BOTH",
          cdsOptionActual: eliteResponse[0].cds_options_std,
          cdsOptionSide: "BOTH",
          cdsOptionsMin: eliteResponse[0].cds_options_min,
          cdsBrokCode: "",
          clientPrimaryDetailId:
            parseInt(GlobalStore.clientPrimaryDetailId) || 0,
          clientType: "",
          cxBrokCode: "",
          eqBrokCode: "",
          fo: true,
          foFutureActual: eliteResponse[0].fo_future_std,
          foFutureMin: eliteResponse[0].fo_future_min,
          foFutureSide: "BOTH",
          foOptionActual: eliteResponse[0].cds_options_std,
          foOptionSide: "BOTH",
          foOptionsMin: eliteResponse[0].cds_options_min,
          foBrokCode: "",
          isActive: true,
          isStandard: true,
          leadId: GlobalStore.leadId,
          objUTMInfo: objUTM_Info,
          schemeName: "",
          schemeId: eliteResponse[0].scheme_id,
        };
        // const saveObj = {
        //   applicationId: GlobalStore.applicationID,
        //   cash: true,
        //   cash_Delivery_Actual: eliteResponse[0].cash_delivery_std,
        //   cash_Delivery_Min: eliteResponse[0].cash_delivery_min,
        //   cash_Delivery_Side: "BOTH",
        //   cash_Intraday_Actual: eliteResponse[0].cash_intraday_std,
        //   cash_Intraday_Min: eliteResponse[0].cash_intraday_min,
        //   cash_Intraday_Side: "BOTH",
        //   cds: true,
        //   cds_Future_Actual: eliteResponse[0].cds_future_std,
        //   cds_Future_Min: eliteResponse[0].cds_future_min,
        //   cds_Future_Side: "BOTH",
        //   cds_Option_Actual: eliteResponse[0].cds_options_std,
        //   cds_Option_Side: "BOTH",
        //   cds_options_min: eliteResponse[0].cds_options_min,
        //   cdsbrokcode: "",
        //   clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId) || 0,
        //   clienttype: "",
        //   cxbrokcode: "",
        //   eqbrokcode: "",
        //   fo: true,
        //   fo_Future_Actual: eliteResponse[0].fo_future_std,
        //   fo_Future_Min: eliteResponse[0].fo_future_min,
        //   fo_Future_Side: "BOTH",
        //   fo_Option_Actual: eliteResponse[0].cds_options_std,
        //   fo_Option_Side: "BOTH",
        //   fo_options_min: eliteResponse[0].cds_options_min,
        //   fobrokcode: "",
        //   is_active: true,
        //   isstandard: true,
        //   leadId: GlobalStore.leadId,
        //   objUTM_Info: objUTM_Info,
        //   scheme_name: "",
        //   schemeid: eliteResponse[0].scheme_id,
        // };
        ASaveBrokerageDetails(saveBrokerageDetailsRes, saveObj);
      } else if (isLite && liteResponse.length > 0) {
        const saveObj = {
          applicationId: GlobalStore.applicationID,
          cash: true,
          cashDeliveryActual: liteResponse[0].cash_delivery_std,
          cashDeliveryMin: liteResponse[0].cash_delivery_min,
          cashDeliverySide: "BOTH",
          cashIntradayActual: liteResponse[0].cash_intraday_std,
          cashIntradayMin: liteResponse[0].cash_intraday_min,
          cashIntradaySide: "BOTH",
          cds: true,
          cdsFutureActual: liteResponse[0].cds_future_std,
          cdsFutureMin: liteResponse[0].cds_future_min,
          cdsFutureSide: "BOTH",
          cdsOptionActual: liteResponse[0].cds_options_std,
          cdsOptionSide: "BOTH",
          cdsOptionsMin: liteResponse[0].cds_options_min,
          cdsBrokCode: "",
          clientPrimaryDetailId:
            parseInt(GlobalStore.clientPrimaryDetailId) || 0,
          clientType: "",
          cxBrokCode: "",
          eqBrokCode: "",
          fo: true,
          foFutureActual: liteResponse[0].fo_future_std,
          foFutureMin: liteResponse[0].fo_future_min,
          foFutureSide: "BOTH",
          foOptionActual: liteResponse[0].fo_options_std,
          foOptionSide: "BOTH",
          foOptionsMin: liteResponse[0].fo_options_min,
          foBrokCode: "",
          isActive: true,
          isStandard: true,
          leadId: GlobalStore.leadId,
          objUTMInfo: objUTM_Info,
          schemeName: "",
          schemeId: liteResponse[0].scheme_id,
        };
        // const saveObj = {
        //   applicationid: GlobalStore.applicationID,
        //   cash: true,
        //   cash_Delivery_Actual: liteResponse[0].cash_delivery_std,
        //   cash_Delivery_Min: liteResponse[0].cash_delivery_min,
        //   cash_Delivery_Side: "BOTH",
        //   cash_Intraday_Actual: liteResponse[0].cash_intraday_std,
        //   cash_Intraday_Min: liteResponse[0].cash_intraday_min,
        //   cash_Intraday_Side: "BOTH",
        //   cds: true,
        //   cds_Future_Actual: liteResponse[0].cds_future_std,
        //   cds_Future_Min: liteResponse[0].cds_future_min,
        //   cds_Future_Side: "BOTH",
        //   cds_Option_Actual: liteResponse[0].cds_options_std,
        //   cds_Option_Side: "BOTH",
        //   cds_options_min: liteResponse[0].cds_options_min,
        //   cdsbrokcode: "",
        //   clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId) || 0,
        //   clienttype: "",
        //   cxbrokcode: "",
        //   eqbrokcode: "",
        //   fo: true,
        //   fo_Future_Actual: liteResponse[0].fo_future_std,
        //   fo_Future_Min: liteResponse[0].fo_future_min,
        //   fo_Future_Side: "BOTH",
        //   fo_Option_Actual: liteResponse[0].fo_options_std,
        //   fo_Option_Side: "BOTH",
        //   fo_options_min: liteResponse[0].fo_options_min,
        //   fobrokcode: "",
        //   is_active: true,
        //   isstandard: true,
        //   leadId: GlobalStore.leadId,
        //   objUTM_Info: objUTM_Info,
        //   scheme_name: "",
        //   schemeid: liteResponse[0].scheme_id,
        // };
        ASaveBrokerageDetails(saveBrokerageDetailsRes, saveObj);
      }
    }
  };

  function saveBrokerageDetailsRes(res: any) {
    if (!res || res.error) {
      // setErrCode('157- Failed to save brokerage details.');
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data) {
        // Clevertap event: Select plan
        const data = {
          EventName:
            cleverTapEvents.registration.planPage.CLICK_ON_SUBMIT_SELECTED_PLAN,
          InitiationPlatform: "",
          UTM_SOURCE,
          UTM_MEDIUM,
          UTM_CAMPAIGN,
          KRA: GlobalStore.isKRA,
          ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
          PLAN_SELECTED: "",
        };

        if (isElite && eliteResponse.length > 0) {
          data["PLAN_SELECTED"] = "ELITE";
        } else if (isLite && liteResponse.length > 0) {
          data["PLAN_SELECTED"] = "LITE";
        }

        ClevertapService.pushEvent(data);
        // Clevertap ends here

        // Apps flyer event for click on proceed on plan details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.planPage.CLICK_ON_SUBMIT_SELECTED_PLAN
        );

        const CBEListVM = [
          {
            cbeList_Value: "12111100",
            cbeList: "EBL-EQ-N",
            cbeListName: "",
            isReadOnly: true,
            //checked: checkedEquityCashNSEBSE,
            checked: true,
          },
          {
            cbeList_Value: "12111200",
            cbeList: "EBL-EQ-B",
            cbeListName: "",
            isReadOnly: true,
            //checked: checkedEquityCashNSEBSE,
            checked: true,
          },
          {
            cbeList_Value: "12201200",
            cbeList: "EBL-MF-BSE",
            cbeListName: "",
            isReadOnly: true,
            //checked: checkedMutualFundsBSE,
            checked: true,
          },
          {
            cbeList_Value: "12161100",
            cbeList: "EBL-Debt-NSE",
            cbeListName: "",
            isReadOnly: true,
            checked: checkedDebtFundsNSEBSE,
          },
          {
            cbeList_Value: "12161200",
            cbeList: "EBL-Debt-BSE",
            cbeListName: "",
            isReadOnly: true,
            checked: checkedDebtFundsNSEBSE,
          },
          {
            cbeList_Value: "12121100",
            cbeList: "EBL-FO-N",
            cbeListName: "",
            isReadOnly: true,
            checked: checkedDerNSE,
          },
          {
            cbeList_Value: "12131100",
            cbeList: "EBL-CDS-N",
            cbeListName: "",
            isReadOnly: true,
            checked: checkedCurrNSE,
          },
          {
            cbeList_Value: "12717200",
            cbeList: "EBL-KRA-CVL",
            cbeListName: "",
            isReadOnly: true,
            checked: true,
          },
          {
            cbeList_Value: "12717300",
            cbeList: "EBL-CKYCR",
            cbeListName: "",
            isReadOnly: true,
            checked: true,
          },
          // according to new BRS\
          {
            cbeList_Value: "12141600",
            cbeList: "CX-NCDEX",
            cbeListName: "",
            isReadOnly: true,
            checked: checkedComMCXnNCXDX,
            // checked: false,
          },
          {
            cbeList_Value: "12141700",
            cbeList: "CX-MCX",
            cbeListName: "",
            isReadOnly: true,
            checked: checkedComMCXnNCXDX,
            // checked: false,
          },
        ];
        if (accountType == 1) {
          CBEListVM.push({
            cbeList_Value: "63616200",
            cbeList: "ESLDP_CDSL",
            cbeListName: "",
            isReadOnly: true,
            checked: true,
          });
        } else {
          CBEListVM.push({
            cbeList_Value: "62616100",
            cbeList: "ESLDP_NSDL",
            cbeListName: "",
            isReadOnly: true,
            checked: true,
          });
        }

        const objUTM_Info = utmDurationInfoObj(startTime, "segmentsdur");
        const saveSegData = {
          applicationid: GlobalStore.applicationID,
          bankcbe: "",
          cbelistVM: CBEListVM,
          clientprimarydetailid:
            parseInt(GlobalStore.clientPrimaryDetailId) || 0,
          leadid: GlobalStore.leadId,
          createddate: "",
          objUTM_Info: objUTM_Info,
          //sebi_mtf: true,
          sebi_mtf: SMTF, //Added By Ravi
          changeDematAccountType:
            GlobalStore.stageStatus.dpaccounttype === accountType
              ? false
              : true,
          dematAccountType: accountType,
        };
        ASaveBrokerageSegmentDetiails(saveBrokerageSegDetailsRes, saveSegData);
      } else {
        // setErrCode('157- Failed to save brokerage details.');
        setErrCode(res.error);
      }
    }
  }

  function saveBrokerageSegDetailsRes(res: any) {
    if (!res || res.error) {
      // setErrCode('156- Failed to save brokerage segment details.');
      setErrCode(res.error);
      // setErrCode(errorMessage.saveBrokerageSegDetailsRes);
    } else {
      if (res.data) {
        completeModuleStatus(dashboardUrl.plan);
        gtmService({
          action: "PLAN_SEGMENT",
          label: `Step_Complete | ${isLite ? "LITE" : "ELITE"}`,
        });

        ACheckClientProfilingData(
          getResForNav,
          GlobalStore.applicationID,
          config.ClientProfilingDataSourseId
        );
      } else {
        // setErrCode('156- Failed to save brokerage segment details.');
        setErrCode(res.error);
      }
    }
    setIsLoading(false);
  }

  const getResForNav = (res: any) => {
    if (!res || res.error) {
      // setErrCode('156- Failed to fetch client profiling data.');
      // setErrCode(errorMessage.saveBrokerageSegDetailsRes);
      setErrCode(res.error);
    } else {
      history.push(incompleteStageNavigation());
    }
  };

  const handleEquityCashNSEBSE = () => {
    setCheckedEquityCashNSEBSE(!checkedEquityCashNSEBSE);
    setNoSegSelected(false);
  };

  const handleMutualFundsBSE = () => {
    setCheckedMutualFundsBSE(!checkedMutualFundsBSE);
    setNoSegSelected(false);
  };

  const handleDebtFundsNSEBSE = () => {
    setCheckedDebtFundsNSEBSE(!checkedDebtFundsNSEBSE);
    setNoSegSelected(false);
  };

  const handleDerNSE = () => {
    setCheckedDerNSE(!checkedDerNSE);
    setNoSegSelected(false);
  };

  const handleCurrNSE = () => {
    setCheckedCurrNSE(!checkedCurrNSE);
    setNoSegSelected(false);
  };

  const handleTermOne = () => {
    setTermOne(!termOne);
  };

  const handleTermTwo = () => {
    setTermTwo(!termTwo);
  };

  const handleTermThree = () => {
    setTermThree(!termThree);
  };

  const handleLiteTick = () => {
    setIsLite(true);
    setIsElite(false);
  };

  const handleEliteTick = () => {
    setIsLite(false);
    setIsElite(true);
  };

  const checkTNC = () => {
    if (segTog) {
      if (!(checkedDerNSE || checkedCurrNSE || checkedComMCXnNCXDX)) {
        setSegTog(!segTog);
      } else if (
        termOne &&
        !incomeObj.hideProgress &&
        !active &&
        incomeProofErr === ""
      ) {
        return false;
      } else {
        return true;
      }
    } else if (!segTog && termOne) {
      return false;
    } else {
      return true;
    }
  };

  const handleRemove = (val: any) => {
    if (val.uploadPer === 0) {
      setActive(true);
      checkTNC();
    }
  };

  // according to new DRS
  const handleComNCXDX = () => {
    setCheckedComMCXnNCXDX(!checkedComMCXnNCXDX);
  };

  //Added By Ravi
  const handleSMTF = () => {
    setSMTF(!SMTF);
  };
  // const handleComMCX = () => {
  //   setCheckedComMCX(!checkedComMCX);
  // };

  React.useEffect(() => {
    console.log("ACC-T = called");
    if (accountTypeChangeCount !== 0) {
      if (accountType === 1) {
        setAccountType(2);
      } else if (accountType === 2) {
        setAccountType(1);
      }
    }
  }, [accountTypeChangeCount, accTypeToggle]);

  const handleTogAccountType = () => {
    if (accountTypeChangeCount < 2) {
      setAccountTypeChangeCount(accountTypeChangeCount + 1);
      setAccTypeToggle(!accTypeToggle);
    }
  };

  return (
    <div className="selectPlan">
      <PdfContainer
        src={pdfName}
        onClose={() => setShowPdfModal(false)}
        showModal={showPdfModal}
      />
      <FullBackdrop
        isShow={openPlanModal}
        isCloseIcon={true}
        onClose={() => closeCharges()}
      >
        {DeviceContext().isDesktop ? (
          <div className="planModal">
            <div className="wrapper">
              <div className="box borderTop">
                <div></div>
                <div className="bck_img headborder">
                  <div className="contentAlighment">
                    <p className="header pdb10">Nuvama Lite</p>
                    <p className="subHeader removeBorder subLabel">
                      Light on pocket, rich on features
                    </p>
                    <div className="removeBorder btnSize mrt21">
                      {isLite ? (
                        <Button
                          isTick={isLite}
                          isLightBlue={true}
                          btnName="Selected"
                          onClick={() => {
                            handleLiteTick();
                          }}
                        />
                      ) : (
                        <div className="removeBorder btnSize">
                          <Button
                            isTick={isLite}
                            btnName="Select Plan"
                            onClick={() => {
                              handleLiteTick();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="gc">
                  <div className="contentAlighment">
                    <p className="header pdb10">Nuvama Elite</p>
                    <p className="subHeader removeBorder subLabel">
                      Rich on features, high on privileges
                    </p>
                    <div className="removeBorder btnSize mrt21">
                      {isElite ? (
                        <Button
                          isTick={isElite}
                          isLightBlue={true}
                          btnName="Selected"
                          onClick={() => {
                            handleEliteTick();
                          }}
                        />
                      ) : (
                        <Button
                          isTick={isElite}
                          btnName="Select Plan"
                          onClick={() => {
                            handleEliteTick();
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div>
                  <p className="labelHeader ">Key Features</p>
                </div>
                <div className="cl pdt24 liteCharges">
                  {liteData && liteData.KeyFeatures[0]}
                </div>
                <div className="cl pdt24 liteCharges">
                  {eliteData && eliteData.KeyFeatures[0]}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div></div>
                <div className="cl liteCharges">
                  {liteData && liteData.KeyFeatures[1]}
                </div>
                <div className="cl liteCharges">
                  {eliteData && eliteData.KeyFeatures[1]}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div></div>
                <div className="cl liteCharges">
                  {liteData && liteData.KeyFeatures[2]}
                </div>
                <div className="cl liteCharges">
                  {eliteData && eliteData.KeyFeatures[2]}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div></div>
                <div></div>
                <div className="cl liteCharges">
                  {eliteData && eliteData.KeyFeatures[3]}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div></div>
                <div></div>
                <div className="cl liteCharges">
                  {eliteData && eliteData.KeyFeatures[4]}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div></div>
                <div></div>
                <div className="cl liteCharges">
                  {eliteData && eliteData.KeyFeatures[5]}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div>
                  <p className="labelHeader pdb20 pdt50">Broking Changes*</p>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Equity Delivery</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.EquityDelivery}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.EquityDelivery}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Equity Intraday</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.EquityIntraday}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.EquityIntraday}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Equity Futures</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.EquityFutures}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.EquityFutures}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Equity Options</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.EquityOptions}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.EquityOptions}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">CUR Futures</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.CurFutures}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.CurFutures}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">CUR Options</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.CurOptions}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.CurOptions}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Commodity Intraday</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.ComIntraday
                    ? liteData.BrokingCharges.ComIntraday
                    : "00"}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.ComIntraday
                    ? eliteData.BrokingCharges.ComIntraday
                    : "00"}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Commodity Delivery</div>
                <div className="liteCharges">
                  {liteData && liteData.BrokingCharges.ComDelivery
                    ? liteData.BrokingCharges.ComDelivery
                    : "00"}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.BrokingCharges.ComDelivery
                    ? eliteData.BrokingCharges.ComDelivery
                    : "00"}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div>
                  <p className="labelHeader pdb20 pdt50">Other Charges</p>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Margin / Funding (Leverage)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.OtherCharges.MarginFunding}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.OtherCharges.MarginFunding}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Delayed Payment Interest (Normal)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.OtherCharges.DpInterestNormal}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.OtherCharges.DpInterestNormal}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Delayed Payment Interest (MTF)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.OtherCharges.DpInterestMTF}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.OtherCharges.DpInterestMTF}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Switching Charges (Only 2 switches allowed)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.OtherCharges.SwitchingCharges}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.OtherCharges.SwitchingCharges}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div>
                  <p className="labelHeader pdb20 pdt50">Manual Support</p>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Call and Trade (Advisory, Trading)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.ManualSupport.CallAndTrade}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ManualSupport.CallAndTrade}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">RM / Advisor</div>
                <div className="liteCharges">
                  {liteData && liteData.ManualSupport.RmAdvisor}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ManualSupport.RmAdvisor}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Special Situation Trades Assistance
                </div>
                <div className="liteCharges">
                  {liteData && liteData.ManualSupport.SplTrdAssistance}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ManualSupport.SplTrdAssistance}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div>
                  <p className="labelHeader pdb20 pdt50">Tools & Features</p>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Auto Collateral</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.AutoCollateral}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.AutoCollateral}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  Standard Tools (Watchlist, Screene₹, Alerts etc.)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.StandardTools}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.StandardTools}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Advanced Charting</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.AdvancedCharting}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.AdvancedCharting}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Market Info</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.MarketInfo}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.MarketInfo}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Research</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.Research}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.Research}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">News (Live, Details)</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.News}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.News}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Content</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.Content}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.Content}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Notifications (Whatsapp)</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.Notifications}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.Notifications}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Portfolio Organizer</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.PortfolioOrganizer}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.PortfolioOrganizer}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Guided Portfolios</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.GuidedPortfolios}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.GuidedPortfolios}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Investology</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.Investology}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.Investology}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Trade Point</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.TradePoint}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.TradePoint}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Smallcase</div>
                <div className="liteCharges">
                  {liteData && liteData.ToolsAndFeatures.SmallCase}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.ToolsAndFeatures.SmallCase}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div>
                  <p className="labelHeader pdb20 pdt50">Ancillary Charges</p>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">DP AMC</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.DpAmc}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.DpAmc}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">
                  DP Fees (Trf Out / Sell) / Inter-settlement Fees
                </div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.DpFees}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.DpFees}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Payin</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.Payin}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.Payin}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Payout</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.Payout}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.Payout}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Physical CML Request</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.PhyCMLreq}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.PhyCMLreq}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Physical Contract Notes</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.PhyContractNotes}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.PhyContractNotes}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Cheque payment</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.ChqPayment}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.ChqPayment}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box">
                <div className="labelSubHeader">Pledge Charges</div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.PledgeCharges}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.PledgeCharges}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="box borderBottom">
                <div className="labelSubHeader">
                  Physical Statement Charges (Ledger, PNL, Tax etc.)
                </div>
                <div className="liteCharges">
                  {liteData && liteData.AncillaryCharges.PhysicalStmtCharges}
                </div>
                <div className="liteCharges">
                  {eliteData && eliteData.AncillaryCharges.PhysicalStmtCharges}
                </div>
              </div>
            </div>
            <div className="pdt50">
              <p className="note">NOTE:</p>
              <p className="notePara">
                *For Nuvama Lite plan, the lower value in between the maximum
                order amount and 0.01% will be considered as the brokerage
                applicable on equity cash & future order.
              </p>
              <p className="notePara">
                *For Nuvama Elite plan, equity Cash transactions brokerage rates
                shown above are additionally subject to a minimum brokerage
                rate. Final brokerage will be calculated as the maximum between
                existing brokerage rate as per plan and a minimum brokerage
                rate. This minimum brokerage rate is the lower between ₹25 and
                2.5% of transaction value.
              </p>
            </div>
          </div>
        ) : DeviceContext().isMobile ? (
          openLite ? (
            <div className="mobPlanModal">
              <div className="bck_img headborder">
                <div className="contAlign">
                  <p className="header">Nuvama Lite</p>
                  <p className="subHeader removeBorder">
                    Light on pocket, rich on features
                  </p>
                  <div className="removeBorder pdt20 btnSize">
                    {isLite ? (
                      <Button
                        isTick={isLite}
                        btnName="Selected"
                        onClick={() => {
                          handleLiteTick();
                        }}
                      />
                    ) : (
                      <Button
                        isTick={isLite}
                        btnName="Select Plan"
                        onClick={() => {
                          handleLiteTick();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mobWrapper">
                <div className="box1">
                  <p className="labelHeader">Key Features</p>
                  <p className="cl liteCharges">
                    {liteData && liteData.KeyFeatures[0]}
                  </p>
                  <p className="cl liteCharges">
                    {liteData && liteData.KeyFeatures[1]}
                  </p>
                  <p className="cl liteCharges">
                    {liteData && liteData.KeyFeatures[2]}
                  </p>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Broking Changes*</label>
                    <label className="labelSubHeader mrt17">
                      Equity Delivery
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.EquityDelivery}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Equity Intraday</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.EquityIntraday}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Equity Futures</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.EquityFutures}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Equity Options</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.EquityOptions}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">CUR Futures</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.CurFutures}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">CUR Options</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.CurOptions}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Commodity Intraday</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.ComIntraday}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Commodity Delivery</label>
                    <label className="liteCharges">
                      {liteData && liteData.BrokingCharges.ComDelivery}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Other Charges</label>
                    <label className="labelSubHeader mrt17">
                      Margin / Funding (Leverage)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.OtherCharges.MarginFunding}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Delayed Payment Interest (Normal)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.OtherCharges.DpInterestNormal}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Delayed Payment Interest (MTF)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.OtherCharges.DpInterestMTF}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Switching Charges (Only 2 switches allowed)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.OtherCharges.SwitchingCharges}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Manual Support</label>
                    <label className="labelSubHeader mrt17">
                      Call and Trade (Advisory, Trading)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ManualSupport.CallAndTrade}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">RM / Advisor</label>
                    <label className="liteCharges">
                      {liteData && liteData.ManualSupport.RmAdvisor}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Special Situation Trades Assistance
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ManualSupport.SplTrdAssistance}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Tools & Features</label>
                    <label className="labelSubHeader mrt17">
                      Auto Collateral
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.AutoCollateral}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Standard Tools (Watchlist, Screene₹, Alerts etc.)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.StandardTools}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Advanced Charting</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.AdvancedCharting}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Market Info</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.MarketInfo}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Research</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.Research}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      News (Live, Details)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.News}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Content</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.Content}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Notifications (Whatsapp)
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.Notifications}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Portfolio Organizer
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.PortfolioOrganizer}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Guided Portfolios</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.GuidedPortfolios}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Investology</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.Investology}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Trade Point</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.TradePoint}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Smallcase</label>
                    <label className="liteCharges">
                      {liteData && liteData.ToolsAndFeatures.SmallCase}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Ancillary Charges</label>
                    <label className="labelSubHeader mrt17">DP AMC</label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.DpAmc}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      DP Fees (Trf Out / Sell) / Inter-settlement Fees
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.DpFees}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Payin</label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.Payin}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Payout</label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.Payout}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Physical CML Request
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.PhyCMLreq}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Physical Contract Notes
                    </label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.PhyContractNotes}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Cheque payment</label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.ChqPayment}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Pledge Charges</label>
                    <label className="liteCharges">
                      {liteData && liteData.AncillaryCharges.PledgeCharges}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Physical Statement Charges (Ledger, PNL, Tax etc.)
                    </label>
                    <label className="liteCharges">
                      {liteData &&
                        liteData.AncillaryCharges.PhysicalStmtCharges}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">NOTE:</label>
                    <label className="labelSubHeader mrt17">
                      *For Nuvama Lite plan, the lower value in between the
                      maximum order amount and 0.01% will be considered as the
                      brokerage applicable on equity cash & future order.
                    </label>
                    <label className="labelSubHeader mrt17">
                      *For Nuvama Elite plan, equity Cash transactions brokerage
                      rates shown above are additionally subject to a minimum
                      brokerage rate. Final brokerage will be calculated as the
                      maximum between existing brokerage rate as per plan and a
                      minimum brokerage rate. This minimum brokerage rate is the
                      lower between ₹25 and 2.5% of transaction value.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mobPlanModal">
              <div className="gc headborder">
                <div className="contAlign">
                  <p className="header">Nuvama Elite</p>
                  <p className="subHeader removeBorder">
                    Light on pocket, rich on features
                  </p>
                  <div className="removeBorder pdt20 btnSize">
                    {isElite ? (
                      <Button
                        isTick={isElite}
                        btnName="Selected"
                        onClick={() => {
                          handleEliteTick();
                        }}
                      />
                    ) : (
                      <Button
                        isTick={isElite}
                        btnName="Select Plan"
                        onClick={() => {
                          handleEliteTick();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mobWrapper">
                <div className="box1">
                  <p className="labelHeader">Key Features</p>
                  <p className="cl liteCharges">
                    {eliteData && eliteData.KeyFeatures[0]}
                  </p>
                  <p className="cl liteCharges">
                    {eliteData && eliteData.KeyFeatures[1]}
                  </p>
                  <p className="cl liteCharges">
                    {eliteData && eliteData.KeyFeatures[2]}
                  </p>
                  <p className="cl liteCharges">
                    {eliteData && eliteData.KeyFeatures[3]}
                  </p>
                  <p className="cl liteCharges">
                    {eliteData && eliteData.KeyFeatures[4]}
                  </p>
                  <p className="cl liteCharges">
                    {eliteData && eliteData.KeyFeatures[5]}
                  </p>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Broking Changes*</label>
                    <label className="labelSubHeader mrt17">
                      Equity Delivery
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.EquityDelivery}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Equity Intraday</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.EquityIntraday}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Equity Futures</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.EquityFutures}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Equity Options</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.EquityOptions}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">CUR Futures</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.CurFutures}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">CUR Options</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.CurOptions}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Commodity Intraday</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.ComIntraday}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Commodity Delivery</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.BrokingCharges.ComDelivery}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Other Charges</label>
                    <label className="labelSubHeader mrt17">
                      Margin / Funding (Leverage)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.OtherCharges.MarginFunding}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Delayed Payment Interest (Normal)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.OtherCharges.DpInterestNormal}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Delayed Payment Interest (MTF)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.OtherCharges.DpInterestMTF}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Switching Charges (Only 2 switches allowed)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.OtherCharges.SwitchingCharges}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Manual Support</label>
                    <label className="labelSubHeader mrt17">
                      Call and Trade (Advisory, Trading)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ManualSupport.CallAndTrade}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">RM / Advisor</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ManualSupport.RmAdvisor}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Special Situation Trades Assistance
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ManualSupport.SplTrdAssistance}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Tools & Features</label>
                    <label className="labelSubHeader mrt17">
                      Auto Collateral
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.AutoCollateral}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Standard Tools (Watchlist, Screene₹, Alerts etc.)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.StandardTools}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Advanced Charting</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.AdvancedCharting}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Market Info</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.MarketInfo}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Research</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.Research}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      News (Live, Details)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.News}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Content</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.Content}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Notifications (Whatsapp)
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.Notifications}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Portfolio Organizer
                    </label>
                    <label className="liteCharges">
                      {eliteData &&
                        eliteData.ToolsAndFeatures.PortfolioOrganizer}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Guided Portfolios</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.GuidedPortfolios}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Investology</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.Investology}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Trade Point</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.TradePoint}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Smallcase</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.ToolsAndFeatures.SmallCase}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">Ancillary Charges</label>
                    <label className="labelSubHeader mrt17">DP AMC</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.DpAmc}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      DP Fees (Trf Out / Sell) / Inter-settlement Fees
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.DpFees}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Payin</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.Payin}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Payout</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.Payout}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Physical CML Request
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.PhyCMLreq}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Physical Contract Notes
                    </label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.PhyContractNotes}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Cheque payment</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.ChqPayment}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">Pledge Charges</label>
                    <label className="liteCharges">
                      {eliteData && eliteData.AncillaryCharges.PledgeCharges}
                    </label>
                  </div>
                  <div className="pdb12">
                    <label className="labelSubHeader">
                      Physical Statement Charges (Ledger, PNL, Tax etc.)
                    </label>
                    <label className="liteCharges">
                      {eliteData &&
                        eliteData.AncillaryCharges.PhysicalStmtCharges}
                    </label>
                  </div>
                </div>
                <div className="box1">
                  <div className="pdb12 mrt50">
                    <label className="labelHeader">NOTE:</label>
                    <label className="labelSubHeader mrt17">
                      *For Nuvama Lite plan, the lower value in between the
                      maximum order amount and 0.01% will be considered as the
                      brokerage applicable on equity cash & future order.
                    </label>
                    <label className="labelSubHeader mrt17">
                      *For Nuvama Elite plan, equity Cash transactions brokerage
                      rates shown above are additionally subject to a minimum
                      brokerage rate. Final brokerage will be calculated as the
                      maximum between existing brokerage rate as per plan and a
                      minimum brokerage rate. This minimum brokerage rate is the
                      lower between ₹25 and 2.5% of transaction value.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="tabletLayout">
            <div className="wrapper1">
              <div className="box2">
                <div className="bck_img_tab headborder">
                  <div className="contentAlighment">
                    <div className="removeBorder btnSize">
                      <Verify btnName="Selected" onClick={() => {}} />
                    </div>
                  </div>
                </div>
                <div className="gc_tab headborder">
                  <div className="contentAlighment">
                    <div className="removeBorder btnSize">
                      <Verify btnName="Selected" onClick={() => {}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box2">
                <div className="pdt30 pdb20">
                  <label>Light on pocket, rich on features</label>
                </div>
                <div className="pdt30 pdb20">
                  <label>Rich on features, high on privilages</label>
                </div>
              </div>
              <div className="box2">
                <div className="pdt30 pdb20">
                  <label>Key Features</label>
                </div>
                <div className="pdt30 pdb20"></div>
              </div>
              <div className="box2">
                <div className="cl  pdb20">
                  {liteData && liteData.KeyFeatures[0]}
                </div>
                <div className="cl  pdb20">
                  {eliteData && eliteData.KeyFeatures[0]}
                </div>
              </div>
              <div className="box2">
                <div className="cl  pdb20">
                  {liteData && liteData.KeyFeatures[1]}
                </div>
                <div className="cl  pdb20">
                  {eliteData && eliteData.KeyFeatures[1]}
                </div>
              </div>
              <div className="box2">
                <div className="cl  pdb20">
                  {liteData && liteData.KeyFeatures[2]}
                </div>
                <div className="cl  pdb20">
                  {eliteData && eliteData.KeyFeatures[2]}
                </div>
              </div>
              <div className="box2">
                <div className=" pdb20"></div>
                <div className="cl  pdb20">
                  {eliteData && eliteData.KeyFeatures[3]}
                </div>
              </div>
              <div className="box2">
                <div className=" pdb20"></div>
                <div className="cl  pdb20">
                  {eliteData && eliteData.KeyFeatures[4]}
                </div>
              </div>
              <div className="box2">
                <div className=" pdb20"></div>
                <div className="cl  pdb20">
                  {eliteData && eliteData.KeyFeatures[5]}
                </div>
              </div>
              <div className="box2">
                <div>
                  <p className="tabHeader pdb20 pdt50">Broking Changes*</p>
                </div>
                <div></div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Equity Delivery</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.BrokingCharges.EquityDelivery}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.BrokingCharges.EquityDelivery}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Equity Intraday</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.BrokingCharges.EquityIntraday}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.BrokingCharges.EquityIntraday}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Equity Futures</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.BrokingCharges.EquityFutures}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.BrokingCharges.EquityFutures}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Equity options</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.BrokingCharges.EquityOptions}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.BrokingCharges.EquityOptions}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">CUR Futures</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.BrokingCharges.CurFutures}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.BrokingCharges.CurFutures}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">CUR Options</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.BrokingCharges.CurOptions}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.BrokingCharges.CurOptions}
                  </div>
                </div>
              </div>
              <div className="box2">
                <div>
                  <p className="tabHeader pdb20 pdt50">Other Charges</p>
                </div>
                <div></div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Margin / Funding (Leverage)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.OtherCharges.MarginFunding}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.OtherCharges.MarginFunding}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Delayed Payment Interest (Normal)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.OtherCharges.DpInterestNormal}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.OtherCharges.DpInterestNormal}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Delayed Payment Interest (MTF)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.OtherCharges.DpInterestMTF}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.OtherCharges.DpInterestMTF}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Switching Charges (Only 2 switches allowed)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.OtherCharges.SwitchingCharges}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.OtherCharges.SwitchingCharges}
                  </div>
                </div>
              </div>
              <div className="box2">
                <div>
                  <p className="tabHeader pdb20 pdt50">Manual Support</p>
                </div>
                <div></div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Call and Trade (Advisory, Trading)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ManualSupport.CallAndTrade}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ManualSupport.CallAndTrade}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">RM / Advisor</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ManualSupport.RmAdvisor}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ManualSupport.RmAdvisor}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Special Situation Trades Assistance
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ManualSupport.SplTrdAssistance}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ManualSupport.SplTrdAssistance}
                  </div>
                </div>
              </div>
              <div className="box2">
                <div>
                  <p className="tabHeader pdb20 pdt50">Tools & Features</p>
                </div>
                <div></div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Auto Collateral</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.AutoCollateral}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.AutoCollateral}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Standard Tools (Watchlist, Screene₹, Alerts etc.)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.StandardTools}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.StandardTools}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Advanced Charting</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.AdvancedCharting}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.AdvancedCharting}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Market Info</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.MarketInfo}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.MarketInfo}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Research</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.Research}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.Research}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">News (Live, Details)</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.News}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.News}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Content</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.Content}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.Content}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Notifications (Whatsapp)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.Notifications}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.Notifications}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Portfolio Organizer</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.PortfolioOrganizer}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.PortfolioOrganizer}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Guided Portfolios</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.GuidedPortfolios}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.GuidedPortfolios}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Investology</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.Investology}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.Investology}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Trade Point</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ToolsAndFeatures.TradePoint}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ToolsAndFeatures.TradePoint}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Smallcase</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.ManualSupport.RmAdvisor}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.ManualSupport.RmAdvisor}
                  </div>
                </div>
              </div>
              <div className="box2">
                <div>
                  <p className="tabHeader pdb20 pdt50">Ancillary Charges</p>
                </div>
                <div></div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">DP AMC</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.DpAmc}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.DpAmc}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  DP Fees (Trf Out / Sell) / Inter-settlement Fees
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.DpFees}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.DpFees}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Payin</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.Payin}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.Payin}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Payout</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.Payout}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.Payout}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Physical CML Request</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.PhyCMLreq}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.PhyCMLreq}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Physical Contract Notes</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.PhyContractNotes}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.PhyContractNotes}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Cheque payment</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.ChqPayment}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.ChqPayment}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">Pledge Charges</div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.PledgeCharges}
                  </div>
                  <div className="tabCharges">
                    {eliteData && eliteData.AncillaryCharges.PledgeCharges}
                  </div>
                </div>
              </div>
              <div className="pdb20">
                <div className="tabSubLabel pdb12">
                  Physical Statement Charges (Ledger, PNL, Tax etc.)
                </div>
                <div className="box">
                  <div className="tabCharges">
                    {liteData && liteData.AncillaryCharges.PhysicalStmtCharges}
                  </div>
                  <div className="tabCharges">
                    {eliteData &&
                      eliteData.AncillaryCharges.PhysicalStmtCharges}
                  </div>
                </div>
              </div>
              <div className="pdt50 pdb20">
                <p className="note">NOTE:</p>
                <p className="notePara">
                  *For Nuvama Lite plan, the lower value in between the maximum
                  order amount and 0.01% will be considered as the brokerage
                  applicable on equity cash & future order.
                </p>
                <p className="notePara">
                  *For Nuvama Elite plan, equity Cash transactions brokerage
                  rates shown above are additionally subject to a minimum
                  brokerage rate. Final brokerage will be calculated as the
                  maximum between existing brokerage rate as per plan and a
                  minimum brokerage rate. This minimum brokerage rate is the
                  lower between ₹25 and 2.5% of transaction value.
                </p>
              </div>
            </div>
          </div>
        )}
      </FullBackdrop>
      {!showModal && (
        <div className={"planLayout" + (isLoading ? " op05" : "")}>
          {isEmployee && (
            <div>
              <div className="planHeading">
                <label>Select Plan</label>
              </div>
              <div className="planHeader mrt7">
                <label>
                  Select your plan to invest in stocks, mutual funds, derivaties
                  & others.
                </label>
              </div>
              {liteResponse.length > 0 &&
                liteResponse[0].client_type === "Online" && (
                  <div className="recBox">
                    <div className="innerBoxHeader">
                      <div className="innerBox">
                        {DeviceContext().isDesktop ? (
                          <img
                            className="planIcon"
                            src={planOne}
                            alt="litePlan"
                          />
                        ) : (
                          <img
                            className="mobPlanIcon"
                            src={mobPlanOne}
                            alt="litePlan"
                          />
                        )}
                      </div>
                      <div className="innerBox flex4">
                        <div className="PlanHeading">
                          <label>Lite Plan</label>
                        </div>
                        <div className="link">
                          <a onClick={() => openCharges("lite")}>
                            View All Charges
                          </a>
                        </div>
                      </div>
                      <div className="innerBox selectedBtn">
                        {isLite ? (
                          <Button
                            isTick={isLite}
                            isLightBlue={true}
                            btnName="Selected"
                            onClick={() => {
                              handleLiteTick();
                            }}
                          />
                        ) : (
                          <div className="borderBlue">
                            <TransparentButton
                              isTick={isLite}
                              btnName="Select Plan"
                              onClick={() => {
                                handleLiteTick();
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="planDesc">
                      <label className="planDescPoints">
                        <span className="arrowPointer">
                          <img src={arrowPoint} />
                        </span>
                        Flat brokerage rates of ₹10 / order for Equity and F&O
                      </label>
                      <label className="planDescPoints">
                        <span className="arrowPointer">
                          <img src={arrowPoint} />
                        </span>
                        Pay as you use model, without Relationship Manager
                        assistance
                      </label>
                      <label className="planDescPoints">
                        <span className="arrowPointer">
                          <img src={arrowPoint} />
                        </span>
                        Value added services chargeable separately
                      </label>
                    </div>
                  </div>
                )}
              {eliteResponse.length > 0 &&
                eliteResponse[0].client_type === "Premium" && (
                  <div className="recBox">
                    <div className="innerBoxHeader">
                      <div className="innerBox">
                        {DeviceContext().isDesktop ? (
                          <img
                            className="planIcon"
                            src={planTwo}
                            alt="litePlan"
                          />
                        ) : (
                          <img
                            className="mobPlanIcon"
                            src={mobPlanTwo}
                            alt="ElitePlan"
                          />
                        )}
                      </div>
                      <div className="innerBox flex4">
                        <div className="PlanHeading">
                          <label>Elite Plan</label>
                        </div>
                        <div className="link">
                          <a onClick={() => openCharges("elite")}>
                            View All Charges
                          </a>
                        </div>
                      </div>
                      <div className="innerBox selectedBtn">
                        {isElite ? (
                          <Button
                            isTick={isElite}
                            isLightBlue={true}
                            btnName="Selected"
                            onClick={() => {
                              handleEliteTick();
                            }}
                          />
                        ) : (
                          <div className="borderBlue">
                            <TransparentButton
                              isTick={isElite}
                              btnName="Select Plan"
                              onClick={() => {
                                handleEliteTick();
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="planDesc">
                      <label className="planDescPoints">
                        <span className="arrowPointer">
                          <img src={arrowPoint} />
                        </span>
                        Volume and Slab based pricings
                      </label>
                      <label className="planDescPoints">
                        <span className="arrowPointer">
                          <img src={arrowPoint} />
                        </span>
                        Dedicated Relationship Manager with trade placement
                        assistance
                      </label>
                      <label className="planDescPoints">
                        <span className="arrowPointer">
                          <img src={arrowPoint} />
                        </span>
                        Value added services complimentary
                      </label>
                    </div>
                  </div>
                )}
            </div>
          )}
          <div className="segLayout">
            <fieldset disabled={isLoading}>
              <div className="segment">
                <label>Select Segments</label>
              </div>
              <div className="toggleContainer">
                <div className="isTogSeg">
                  <label>
                    {/* Your DMAT account is being processed with{" "} */}
                    Your Demat A/c is being opened with{" "}
                    {accountType == 1 ? "CDSL" : "NSDL"}. click here to change
                  </label>
                </div>
                <div className="segToggle">
                  <Toggle
                    isOn={accTypeToggle}
                    onChange={() => handleTogAccountType()}
                    disabled={
                      accountTypeChangeCount === 2 ||
                      GlobalStore.stageStatus.dpaccountchanged ||
                      eSignSummited
                    }
                  />
                </div>
              </div>
              <div className="toggleContainer">
                <div className="isTogSeg">
                  <label>
                    Equity and Mutual Funds are by default enabled for your
                    account, to activate FNO click here.
                  </label>
                </div>
                <div className="segToggle">
                  <Toggle isOn={segTog} onChange={() => handleTog()} />
                </div>
              </div>
              <div
                className={
                  DeviceContext().isMobile ? "segList mrt40" : "segList"
                }
              ></div>
              <div className="segList">
                <div className="checkList">
                  <div className="pdt18 ckLabelWdth">
                    <Checkbox
                      //checked={checkedEquityCashNSEBSE}
                      name="Equity-Cash-NSE-BSE"
                      //onChange={() => handleEquityCashNSEBSE()}
                      checked={true}
                      disabled={true}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div>
                  <p className="seglabel">Equity Cash</p>
                </div>
              </div>
              <div className="segList">
                <div className="checkList">
                  <div className="pdt18 ckLabelWdth">
                    <Checkbox
                      //checked={checkedMutualFundsBSE}
                      name="Mutual-Funds-BSE"
                      //onChange={() => handleMutualFundsBSE()}
                      checked={true}
                      disabled={true}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div>
                  <p className="seglabel">Mutual Funds</p>
                </div>
              </div>
              <div className="segList">
                <div className="checkList">
                  <div className="pdt18 ckLabelWdth">
                    <Checkbox
                      checked={checkedDebtFundsNSEBSE}
                      name="Debt-Funds-BSE"
                      onChange={() => handleDebtFundsNSEBSE()}
                    />
                  </div>
                </div>
                <div>
                  <p className="seglabel">Debt Funds</p>
                </div>
              </div>
              {segTog && (
                <div>
                  <div className="segList">
                    <div className="checkList">
                      <div className="pdt18 ckLabelWdth">
                        <Checkbox
                          checked={checkedDerNSE}
                          name="Equity-Derivatives-NSE"
                          onChange={() => handleDerNSE()}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="seglabel">Equity Derivatives</p>
                    </div>
                  </div>
                  <div className="segList">
                    <div className="checkList">
                      <div className="pdt18 ckLabelWdth">
                        <Checkbox
                          checked={checkedCurrNSE}
                          name="Currency-Derivatives-NSE"
                          onChange={() => handleCurrNSE()}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="seglabel">Currency Derivatives</p>
                    </div>
                  </div>
                  <div className="segList">
                    <div className="checkList">
                      <div className="pdt18 ckLabelWdth">
                        <Checkbox
                          checked={checkedComMCXnNCXDX}
                          name="Commodity-NSE"
                          onChange={() => handleComNCXDX()}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="seglabel">Commodity</p>
                    </div>
                  </div>
                </div>
              )}
              {segTog && (
                <div className="mrt20 mrb40">
                  <div className="mrb40">
                    <label className="noteLabel">
                      Please Note: As per new NSE circular income proof is now
                      mandatory for all segments, to ease your trading
                      experience with us by default FNO.
                    </label>
                  </div>
                  <div className="mrb30">
                    <label>Income Proof</label>
                    <DropdownSearch
                      name="incomeProof"
                      searchLength={0}
                      options={incomeProofList}
                      searchKey="name"
                      placeholder="Select Income Proof"
                      isDownArrow={true}
                      defaultValue={defaultIncomeDoc}
                      onOptionSelect={(val) => handleIncomeProof(val)}
                      errorText={incomeProofErr}
                      onBlur={(val) => dropDownBlur(val)}
                    />
                  </div>
                  <div>
                    <DocumentBrowse
                      id="incomeProof"
                      docObj={incomeObj}
                      onDocUploadComplete={onIncomeUploadComplete}
                      isRemove={handleRemove}
                    />
                  </div>
                </div>
              )}
              {/* <InfoModal width={DeviceContext().isMobile ? '328px' : '354px'}>
                                <div className="explainInfo">
                                    <label>To add other Derivtatives, Activate FNO</label>
                                </div>
                            </InfoModal> */}

              {/* <Backdrop isShow={showPopup} onClose={() => closePopup()} isCloseIcon={true}>
                                <div className="popup">
                                    <label className="popupLabel">To add other Derivatives, Activate FNO</label>
                                </div>
                            </Backdrop> */}

              {!isEmployeePAN && (
                <div className="termList mrt41">
                  <Checkbox
                    checked={SMTF}
                    name="SMTF"
                    onChange={() => handleSMTF()}
                  />
                  <label className="mrl13 linkLabel">
                    I agree to terms and conditions for the{" "}
                    <a target="_blank" href={SMTF_PDF}>
                      <u>SEBI Margin Trading Facility</u>
                    </a>
                  </label>
                </div>
              )}
              <div className="termList mrt41">
                <Checkbox
                  checked={termOne}
                  name="termone"
                  onChange={() => handleTermOne()}
                />
                <label className="mrl13 linkLabel">
                  I agree to terms and conditions for the{" "}
                  <a target="_blank" href={bp}>
                    <u>Brokerage plan</u>
                  </a>
                  ,{" "}
                  <a target="_blank" href={ao}>
                    <u>Account Opening</u>
                  </a>
                  ,{" "}
                  <a onClick={() => openCharges("lite")}>
                    <u>VAS Charges</u>
                  </a>{" "}
                  &{" "}
                  <a target="_blank" href={dc}>
                    <u>Disclaimer</u>
                  </a>
                </label>
              </div>
              {/* <div className="termList mrt21">
                                <Checkbox
                                    checked={termTwo}
                                    name="termTwo"
                                    onChange={() => handleTermTwo()} />
                                <label className="mrl13 linkLabel">I agree to <a onClick={() => openCharges("lite")}><u>VAS Charges</u></a></label>
                            </div>
                            <div className="termList mrt21">
                                <Checkbox
                                    checked={termThree}
                                    name="termThree"
                                    onChange={() => handleTermThree()} />
                                <label className="mrl13 linkLabel" >I agree to <a target="_blank" href={dc}><u>Disclaimer</u></a></label>
                            </div> */}

              {/* Error Msg Start*/}
              {errCode && (
                <div className="errMsgBlck errWidth mrt40">
                  <ErrMsg>
                    {typeof errCode === "number" ? (
                      <>
                        <label>We are facing some technical issue.</label>
                        <label>
                          Please try after sometime (error code:{errCode})
                        </label>
                      </>
                    ) : (
                      <label>{errCode}</label>
                    )}
                  </ErrMsg>
                </div>
              )}
              {/* Error Msg End*/}
              {!termOne ? (
                <div className="mrt40">
                  <ErrMsg>
                    <>
                      <label>
                        Please upload income proof and accept the terms &
                        conditions to proceed ahead.
                      </label>
                    </>
                  </ErrMsg>
                </div>
              ) : null}
              {NoSegSelected && (
                <div className="mrt40">
                  <ErrMsg>
                    <>
                      <label>Please Select Segment.</label>
                    </>
                  </ErrMsg>
                </div>
              )}
              <div className="planSubmitBtn">
                <SubmitButton
                  isLoading={isLoading}
                  btnName="Submit"
                  onClick={handleSubmit}
                  disabled={checkTNC() || eSignSummited}
                />
              </div>
            </fieldset>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectPlan;
