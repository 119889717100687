import React from "react";
import { Route, Switch } from "react-router-dom";
import { containerUrl } from "../components/utils/constants";
import OcvScheduled from "../components/OcvScheduled/OcvScheduled";
import RegistrationLayout from "../components/Registration/Layout/Layout";
import SubmitAO from "../components/SubmitAO/SubmitAO";
import Poa from "../components/POA/Poa";
import InstanHelp from "../components/InstantHelp/InstantHelp";

export interface SubmissionRoutesProps {}

const SubmissionRoutes: React.FC<SubmissionRoutesProps> = (props) => (
  <Switch>
    <Route
      path={containerUrl.ocv}
      exact
      render={() => (
        <InstanHelp panPageFlag={false}>
          <RegistrationLayout isSubmissionRoute>
            <OcvScheduled />
          </RegistrationLayout>
        </InstanHelp>
      )}
    />
    <Route
      path={containerUrl.poa}
      exact
      render={() => (
        <InstanHelp panPageFlag={false}>
          <RegistrationLayout isSubmissionRoute>
            <Poa />
          </RegistrationLayout>
        </InstanHelp>
      )}
    />
    <Route
      path={containerUrl.submit}
      exact
      render={() => (
        <InstanHelp panPageFlag={false}>
          <RegistrationLayout isSubmissionRoute>
            <SubmitAO />
          </RegistrationLayout>
        </InstanHelp>
      )}
    />
  </Switch>
);

export default SubmissionRoutes;
