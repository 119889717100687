import { Idevice } from "../types/device";

const DeviceContext = () => {
	let device: Idevice = {};
	const updateDevice = () => {
		device = {
			isMobile: document.body.offsetWidth <= 767 ? true : false,
			isTablet: document.body.offsetWidth > 767 && document.body.offsetWidth <= 1024 ? true : false,
			isDesktop: document.body.offsetWidth > 1024 ? true : false
		};
	};
	updateDevice();

	return device;
};

export default DeviceContext;