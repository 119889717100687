import { apiService } from "../apiServices";
import config from "../../../config/domain";
import servicesList from "../serviceList";
import { isGetAccessor } from "typescript";

export const APendingUploadList = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: applicationid,
        InputDataType: 1,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-pending-document-list",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const APendingApprovalList = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: applicationid,
        InputDataType: 3,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-doc-status",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AValidateServicableCityPincode = (
  callback: CallableFunction,
  pincode: string
) => {
  apiService(
    {
      url: "eco/validate-servicable-city-pincode",
      body: {
        Pincode: pincode,
      },
      method: "POST",
    },
    callback
  );
};

export const AAgentDatesList = (callback: CallableFunction) => {
  apiService(
    {
      url: "eco/date-for-agent-visit",
      method: "POST",
    },
    callback
  );
};

export const AAgentTimesList = (callback: CallableFunction) => {
  apiService(
    {
      url: "eco/time-for-agent-visit",
      method: "POST",
    },
    callback
  );
};

export const ASaveAgentVisitDetails = (
  callback: CallableFunction,
  agentVisitObj: any
) => {
  apiService(
    {
      url: "eco/save-agent-visit-details",
      body: agentVisitObj,
      method: "POST",
    },
    callback
  );
};

export const AGetAgentVisitDetails = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "eco/get-agent-visit-details",
      body: {
        applicationid: applicationid,
      },
      method: "POST",
    },
    callback
  );
};

export const ACreateHelpTicket = (callback: CallableFunction, reqObj: any) => {
  apiService(
    {
      url: "eco/create-help-ticket",
      body: reqObj,
      method: "POST",
    },
    callback
  );
};

export const AGetEsignUrlDetails = (
  callback: CallableFunction,
  applicationid: string,
  esigndocumenttype: number
) => {
  apiService(
    {
      url: "DIYAPI/GetEsignLink",
      body: {
        ApplicationId: applicationid,
        esigndocumenttype: esigndocumenttype,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getEsignLink,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-esign-link",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetClientAccountCode = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetAccountcodeData",
      body: {
        ApplicationId: applicationid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getAccountcodeData,
    },
    callback
  );
  //   apiService(
  //     {
  //       url: "eco/get-client-account-code",
  //       body: {
  //         applicationId: applicationid,
  //       },
  //       method: "POST",
  //     },
  //     callback
  //   );
};

export const AValidatePoaServicableCityPincode = (
  callback: CallableFunction,
  pincode: string
) => {
  apiService(
    {
      url: `eco/checkPincodeJavaMW?pincode=${pincode}`,
      method: "GET",
    },
    callback
  );
};

export const ASavePoaDetails = (callback: CallableFunction, reqObj: any) => {
  apiService(
    {
      url: "eco/SavePoaScheduleJavaMW",
      body: reqObj,
      method: "POST",
    },
    callback
  );
};

export const ADownloadForm = (
  callback: CallableFunction,
  applicationid: string,
  isFullPdf: boolean
) => {
  let body: any = {
    ApplicationId: applicationid,
  };
  if (isFullPdf) {
    body.EsignFlag = 1;
  }
  apiService(
    {
      url: "DIYAPI/GenerateKYCPdf",
      body: body,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.generateKYCPdf,
    },
    callback
  );
  // let url = isFullPdf ? "saveKycFullFormJavaMW" : "DownLoadAndCourierJavaMW";
  // apiService(
  //   {
  //     url: `eco/${url}?applicationId=${applicationid}`,
  //     method: "GET",
  //   },
  //   callback
  // );
};

export const AUpdatePanAddharLinkStatus = (
  callback: CallableFunction,
  applicationid: string,
  ispanaadhaarlinked: boolean
) => {
  apiService(
    {
      url: "DIYAPI/UpdatePanAddharLinkStatus",
      body: {
        ApplicationId: applicationid,
        ispanaadhaarlinked: ispanaadhaarlinked,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.updatePanAddharLinkStatus,
    },
    callback
  );
};
