import React from "react";
import { Route, Switch } from "react-router-dom";
import ProfileLayout from "../components/Profile/ProfileLayout/ProfileLayout";
import ProfileHome from "../components/Profile/ProfileHome/ProfileHome";
import ProfileMenu from "../components/Profile/ProfileMenu/ProfileMenu";
import BankHome from "../components/Bank/BankHome/BankHome";
import { dashboardUrl } from "../components/utils/constants";
import SelectPlan from "../components/SelectPlan/SelectPlan";
import DocVerificationHome from "../components/DocumentVerification/DocVerificationHome/DocVerificationHome";
import ESignHome from "../components/ESign/ESignHome/ESignHome";
import VideoVerification from "../components/VideoVerification/VideoVerification";
import Profiler from "../components/Profiler/Profiler";
import EsignKra from "../components/ESign/ESignKra/ESignKra";
import PersoanlInfo from "../components/Registration/PersonalInformation/PersonalInfo";
// import { dashboardUrl } from "../components/utils/constants";
// import SelectPlan from "../components/SelectPlan/SelectPlan";
// import DocVerificationHome from "../components/DocumentVerification/DocVerificationHome/DocVerificationHome";
// import ESignHome from "../components/ESign/ESignHome/ESignHome";
// import VideoVerification from "../components/VideoVerification/VideoVerification";
// import Profiler from "../components/Profiler/Profiler";
import InstanHelp from "../components/InstantHelp/InstantHelp";

export interface DashboardRoutesProps {}

const DashboardRoutes: React.FC<DashboardRoutesProps> = (props) => {
  return (
    <Switch>
      <Route
        path={dashboardUrl.profile}
        exact
        render={(props) => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <ProfileHome {...props} />
            </ProfileLayout>
          </InstanHelp>
        )}
      />

      <Route
        path={dashboardUrl.ipv}
        exact
        render={(props) => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <VideoVerification />
            </ProfileLayout>
          </InstanHelp>
        )}
      />
      <Route
        path={dashboardUrl.doc}
        exact
        render={(props) => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <DocVerificationHome {...props} />
            </ProfileLayout>
          </InstanHelp>
        )}
      />
      <Route
        path={dashboardUrl.bank}
        exact
        render={() => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <BankHome {...props} />
            </ProfileLayout>
          </InstanHelp>
        )}
      />
      <Route
        path={dashboardUrl.plan}
        exact
        render={(props) => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <SelectPlan {...props} />
            </ProfileLayout>
          </InstanHelp>
        )}
      />
      <Route
        path={dashboardUrl.profiler}
        exact
        render={(props) => (
          <ProfileLayout {...props}>
            <Profiler {...props} />
          </ProfileLayout>
        )}
      />
      <Route
        path={dashboardUrl.eSign}
        exact
        render={(props) => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <ESignHome />
            </ProfileLayout>
          </InstanHelp>
        )}
      />
      <Route
        path={dashboardUrl.personalIfo}
        exact
        render={(props) => (
          <InstanHelp panPageFlag={false}>
            <ProfileLayout {...props}>
              <PersoanlInfo />
            </ProfileLayout>
          </InstanHelp>
        )}
      />
      <Route path={dashboardUrl.menu} exact component={ProfileMenu} />
    </Switch>
  );
};

export default DashboardRoutes;
