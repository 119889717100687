//import React, { useState } from 'react';
import indexer, { IListObj, initialIListObj } from "../types/genericTypes";
import GlobalStore from "../store/globalStore";
import {
  ILeftNavBarArray,
  days,
  containerUrl,
  dashboardUrl,
} from "../utils/constants";
import { AUpdateUtmDetails } from "./registration/panDobApiService";
import { xhrApiService } from "./xhrApiServices";
import { apiService } from "./apiServices";
import servicesList from "./serviceList";
import config from "../../config/domain";

export function clientContinue() {
  const clientDetails = JSON.parse(
    window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  );
  const partner = JSON.parse(window.sessionStorage.getItem("partner") || "{}");
  console.log("Partner: ", partner);

  if (
    clientDetails.applicationId &&
    clientDetails.applicationId !== "" &&
    clientDetails.panDetails &&
    clientDetails.panDetails !== "" &&
    !partner &&
    !(clientDetails.isNormalClient && !clientDetails.isPANVerified)
  )
    return containerUrl.continue;
}

export function clientPartialDetailsToStore(initialObj?: {
  applicationID: string;
}) {
  const clientPartialDetails_ao =
    initialObj ||
    JSON.parse(window.localStorage.getItem("clientPartialDetails_ao") || "{}");
  if (Object.keys(clientPartialDetails_ao).length > 0) {
    GlobalStore.clientPrimaryDetailId =
      clientPartialDetails_ao.clientPrimaryDetailId || "";
    GlobalStore.token = clientPartialDetails_ao.token || "";
    GlobalStore.applicationID = clientPartialDetails_ao.applicationId || "";
    GlobalStore.dob = clientPartialDetails_ao.dob || "";
    GlobalStore.birthDate = clientPartialDetails_ao.birthDate || "";
    GlobalStore.personaldetails.pan = clientPartialDetails_ao.pan || "";
    GlobalStore.leadId = clientPartialDetails_ao.leadID || "";
    GlobalStore.isSeeding = clientPartialDetails_ao.isSeeding || false;
    GlobalStore.selfieUrl = clientPartialDetails_ao.selfieUrl || "";
    GlobalStore.user.email = clientPartialDetails_ao.emailID || "";
    GlobalStore.user.mobile = clientPartialDetails_ao.mobile || "";
    GlobalStore.personaldetails.panDetails =
      clientPartialDetails_ao.panDetails || "";
    GlobalStore.personaldetails.PANName = clientPartialDetails_ao.PANName || "";
    GlobalStore.isDigiLocker = clientPartialDetails_ao.isDigiLocker || false;
    GlobalStore.isKRA = clientPartialDetails_ao.isKRA || false;
    GlobalStore.isKYC = clientPartialDetails_ao.isKYC || false;
    GlobalStore.isNormalClient =
      clientPartialDetails_ao.isNormalClient || false;
    GlobalStore.isPANVerified = clientPartialDetails_ao.isPANVerified || false;
    GlobalStore.isSeniorCitizen =
      clientPartialDetails_ao.isSeniorCitizen || false;
    GlobalStore.aadharLinkedChoice =
      clientPartialDetails_ao.aadharLinkedChoice || false;
    GlobalStore.kraInfoObject.addressLine1 =
      clientPartialDetails_ao.kraAddressLine1 || "";
    GlobalStore.kraInfoObject.pincode =
      clientPartialDetails_ao.kraPincode || "";
    GlobalStore.ckycNo = clientPartialDetails_ao.ckycNo || "";
    GlobalStore.personaldetails.fsfullName =
      clientPartialDetails_ao.fsfullName || "";
    GlobalStore.personaldetails.motherfullName =
      clientPartialDetails_ao.motherfullName || "";
  }
  return clientPartialDetails_ao;
}

export function calculateCompletePercentage(): string {
  const percent =
    (GlobalStore.leftNavBarState.filter((stage) => stage.isComplete).length /
      GlobalStore.leftNavBarState.length) *
    100;
  return percent.toFixed();
}

/*Convert Pan Details string name to Client Name;
@para str - String, response of PanUti api, need be parse
@return - Array of strings as First Name, Middle Name and Last Name
*/
export function splitFullName(fullName: string): Array<string> {
  const fullNameArr: Array<string> = fullName
    .split(" ")
    .filter((name) => name !== "");
  const fullNameArrLength: number = fullNameArr.length;
  let PANFirstName = "",
    PANMiddleName = "",
    PANlastName = "";
  switch (true) {
    case fullNameArrLength >= 3:
      PANFirstName = fullNameArr.slice(0, fullNameArrLength - 2).join(" ");
      PANMiddleName = fullNameArr[fullNameArrLength - 2];
      PANlastName = fullNameArr[fullNameArrLength - 1];
      return [PANFirstName, PANMiddleName, PANlastName];
    case fullNameArrLength === 2:
      PANFirstName = fullNameArr[0];
      PANlastName = fullNameArr[1];
      return [PANFirstName, PANMiddleName, PANlastName];
    case fullNameArrLength === 1:
      PANFirstName = fullNameArr[0];
      PANlastName = ".";
      return [PANFirstName, PANMiddleName, PANlastName];
    default:
      return [fullName];
  }
}

export function capitalizeWords(str: string) {
  return str.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function parseQuery(queryString: string) {
  const query = queryString.substring(1);
  const vars = query.split("&");

  return vars.reduce((acc: indexer, element: string) => {
    const pair: Array<string> = element.split("=");
    acc[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    return acc;
  }, {});
}

export function utmDurationInfoObj(startTime: Date, key: string) {
  const objUTM_Info = JSON.parse(
    window.sessionStorage.getItem("objUTM_Info") || "{}"
  );
  const elapsed: number = new Date().getTime() - startTime.getTime();
  objUTM_Info[key] = elapsed / 60000; //in minutes
  if (window.innerWidth <= 800 && window.innerHeight <= 600)
    objUTM_Info.devicetype = "Mobile";
  else objUTM_Info.devicetype = "Desktop";
  return objUTM_Info;
}

export function updateUtmInfo(callback: CallableFunction) {
  const objUTM_Info: any = JSON.parse(
    window.sessionStorage.getItem("objUTM_Info") || "{}"
  );
  const utmObj = {
    applicationid: GlobalStore.applicationID,
    utmcontent: objUTM_Info.utm_content || "",
    utmcreative: objUTM_Info.utm_creative || "",
  };
  AUpdateUtmDetails(callback, utmObj);
}

export function setStageData(apiResponseObj: any) {
  GlobalStore.stageStatus = {
    isregistrationcompleted: apiResponseObj.isregistrationcompleted,
    ispersonaldetailscompleted: apiResponseObj.ispersonaldetailscompleted,
    isprofiledetailscompleted: apiResponseObj.isprofiledetailscompleted,
    isnewdpnomineecompleted: apiResponseObj.isnewdpnomineecompleted,
    isnewdpguardiancompleted: apiResponseObj.isnewdpguardiancompleted,
    isbankdetailscompleted: apiResponseObj.isbankdetailscompleted,
    isbrokeragesegmentcompleted: apiResponseObj.isbrokeragesegmentcompleted,
    isuploaddocumentscompleted: apiResponseObj.isuploaddocumentscompleted,
    isipvarificationcompleted: apiResponseObj.isipvarificationcompleted,
    isbrokerageschemecompleted: apiResponseObj.isbrokerageschemecompleted,
    isagentvisitcompleted: apiResponseObj.isagentvisitcompleted,
    isesigncompleted: apiResponseObj.isesigncompleted,
    isdigilockercompleted: apiResponseObj.isdigilockercompleted,
    bpm_pd_status: apiResponseObj.bpm_pd_status,
    bpm_nsb_status: apiResponseObj.bpm_nsb_status,
    applicationstatus: apiResponseObj.applicationstatus,
    iskra: apiResponseObj.iskra,
    iskyc: apiResponseObj.iskyc,
    ckycnumber: apiResponseObj.ckycnumber,
    iskraesigncompleted: apiResponseObj.iskraesigncompleted,
    isaddressstagecompleted: apiResponseObj.isaddressstagecompleted,
    ispanlinkedwithaadhar: apiResponseObj.ispanlinkedwithaadhar,
    checkpanaaddharflag: apiResponseObj.checkpanaaddharflag,
    dpaccounttype: apiResponseObj.dpaccounttype,
    dpaccountchanged: apiResponseObj.dpaccountchanged,
    dob:apiResponseObj.dob,
    isPCGSubLob:apiResponseObj.ispcgsublob,
    panfirstname:apiResponseObj?.panfirstname,
    panmiddletname:apiResponseObj?.panmiddletname,
    panlastname:apiResponseObj?.panlastname,
  };
}

export function redirectToEntryPoint() {
  if (Object.keys(clientPartialDetailsToStore()).length === 0)
    return containerUrl.registration;
  return containerUrl.continue;
}

export function isSafariBrowser(): boolean {
  // Safari 3.0+ "[object HTMLElementConstructor]"
  let isSafari =
    /constructor/i.test((window as any).HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !(window as any)["safari"] || (window as any)["safari"].pushNotification
    );
  return isSafari;
}

export function guid() {
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export interface enumObj {
  EnumName: string;
  ValueItem: string;
  DisplayItem: string;
  DisplayItem2?: string;
}

export function convertEnumIntoIObj(
  enumArr: Array<enumObj>,
  key: Array<string>
) {
  let resultArr: Array<Array<IListObj>> = [];
  key.forEach((k) => {
    let arr: Array<IListObj> = [];
    enumArr.forEach((element) => {
      if (k === element.EnumName) {
        let obj: IListObj = { ...initialIListObj };
        obj.display = element.DisplayItem;
        obj.value = element.ValueItem;
        arr.push(obj);
      }
    });
    resultArr.push(arr);
  });
  return resultArr;
}

export function ageCalculator(dob: Date): number {
  //extract the year, month, and date from user date input
  let dobYear = dob.getFullYear(),
    dobMonth = dob.getMonth(),
    dobDate = dob.getDate();

  //get the current date from the system
  let now = new Date();
  //extract the year, month, and date from current date
  let currentYear = now.getFullYear();
  let currentMonth = now.getMonth();
  let currentDate = now.getDate();

  //declare a variable to collect the age in year, month, and days
  let age: any = {};

  //get years
  let yearAge: number = currentYear - dobYear;
  let monthAge: number;
  let dateAge: number;
  //get months
  if (currentMonth >= dobMonth)
    //get months when current month is greater
    monthAge = currentMonth - dobMonth;
  else {
    yearAge--;
    monthAge = 12 + currentMonth - dobMonth;
  }

  //get days
  if (currentDate >= dobDate)
    //get days when the current date is greater
    dateAge = currentDate - dobDate;
  else {
    monthAge--;
    dateAge = 31 + currentDate - dobDate;
    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }
  //group the age in a single variable
  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  return age.years;
}

export function getFirstMiddleLastName(name: Array<string>) {
  const nameArr = [];
  nameArr[0] = name[0];
  if (name.length > 1) {
    nameArr[1] = name[name.length - 1];
    nameArr[2] = "";
    for (let i = 1; i <= name.length - 2; i++) {
      nameArr[2] = nameArr[2] + " " + name[i];
    }
    nameArr[2] = nameArr[2].trim();
  }
  return nameArr;
}

export function completeModuleStatus(navUrl: string) {
  let moduleArr: Array<ILeftNavBarArray> = GlobalStore.leftNavBarState;
  const clientPartialDetails_ao = JSON.parse(
    window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  );
  if (
    GlobalStore.isKRA ||
    clientPartialDetails_ao.isKRA ||
    GlobalStore.isKYC ||
    clientPartialDetails_ao.isKYC ||
    GlobalStore.isDigiLocker ||
    clientPartialDetails_ao.isDigiLocker
  ) {
    moduleArr = GlobalStore.leftNavBarState.filter(
      (item) => item.name !== "Personal Documents"
    );
  }
  for (let element of moduleArr) {
    if (element.navUrl === navUrl) {
      if (navUrl == "/esign") {
        if (
          GlobalStore.stageStatus.isesigncompleted &&
          GlobalStore.stageStatus.iskraesigncompleted
        ) {
          element.isComplete = true;
          continue;
        } else {
          element.isComplete = false;
          continue;
        }
      }
      element.isComplete = true;
      break;
    }
  }
  GlobalStore.leftNavBarState = moduleArr;
}

//It return next imcomplete Stage Url
export function incompleteStageNavigation(currentPage?: string) {
  let returnUrl: string = dashboardUrl.eSign;
  const clientPartialDetails_ao = JSON.parse(
    window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  );

  if (
    (GlobalStore.applicationID || clientPartialDetails_ao.applicationId) &&
    !GlobalStore.isDigiLocker
  ) {
    if (
      GlobalStore.isKRA ||
      clientPartialDetails_ao.isKRA ||
      GlobalStore.isKYC ||
      clientPartialDetails_ao.isKYC
    ) {
      const filteredLeftNavBar = GlobalStore.leftNavBarState.filter(
        (item) => item.name !== "Personal Documents"
      );
      if (filteredLeftNavBar && filteredLeftNavBar.length > 0) {
        GlobalStore.leftNavBarState = filteredLeftNavBar;
      }
    }
  }
  for (let i = 0; i < GlobalStore.leftNavBarState.length; i++) {
    if (
      !GlobalStore.leftNavBarState[i].isComplete &&
      GlobalStore.leftNavBarState[i].navUrl !== currentPage
    ) {
      returnUrl = GlobalStore.leftNavBarState[i].navUrl;
      break;
    }
  }
  return returnUrl;
}

// Convert base64 into Blob
export const base64toBlob = (
  b64Data: string,
  contentType: string,
  sliceSize: number = 512
) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function stringToDate(dateStr: string, mmDDYYYY?: boolean): string {
  const dateObj = new Date(dateStr);
  const day = dateObj
    .getDate()
    .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
  const month = (dateObj.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  }); //months from 1-12
  const year = dateObj.getUTCFullYear();
  if (mmDDYYYY) return month + "/" + day + "/" + year;
  return day + "/" + month + "/" + year;
}

export function dateStringToDay(dateStr: string): string {
  const d = new Date(dateStr);
  const dayName = days[d.getDay()];
  return dayName;
}

export const ACreateApplicationTicket = (
  callback: CallableFunction,
  stageInfoObj: any
) => {
  apiService(
    {
      url: "DIYAPI/CreateApplicationTicket",
      body: {
        ApplicationId: stageInfoObj.applicationid,
        ClientPrimaryDetailId: stageInfoObj.clientprimarydetailid,
        StageInfoId: stageInfoObj.stageinfoid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.createApplicationTicket,
    },
    callback
  );

  // apiService(
  //   {
  //     url: "eco/create-application-ticket",
  //     body: stageInfoObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetRsaPublicKey = (callback: CallableFunction) => {
  apiService(
    {
      url: "eco/get-public-key",
      method: "GET",
    },
    callback
  );
};
