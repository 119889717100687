import React, { Component } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import regbanner from '../../../assets/icons/accountAlreadyExists.png';
import Button from '../../UI/Button/Button';
import { statusUrl } from '../../utils/constants';

import './StatusVerified.scss';

class StatusVerified extends Component<RouteChildrenProps> {
    checkStatus = () => {
        window.location.href = statusUrl.statusHome;
    };

    render() {
        return (
            <div className="status-verification-root">
                <img src={regbanner} className="verified-img" />
                <div>
                    <h2 className="status-heading">Account Already Exists</h2>
                    <span className="status-subHead">
                        An account already exists with this PAN
                        <br /> and mobile information.
                    </span>
                    <div className="login-btn">
                        <Button btnName="Need Login Help?" type="submit" />
                    </div>
                    <div className="new-pan-entry">
                        <label className="alreadyAccountLabel">Entered PAN by mistake? </label>
                        <label className="loginLink" onClick={this.checkStatus}>
                            Use a different PAN
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatusVerified;
