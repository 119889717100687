import { ClevertapService } from './clevertapService';

export const AppsFlyerService = {
    pushEvent: async function (eventName: string) {
        if (window && typeof (window as any).AF === 'function') {
            const utmObj = JSON.parse(window.sessionStorage.getItem('objUTM_Info') || '{}');
            let cuid;
            if (utmObj && utmObj.utm_placement) {
                cuid = utmObj.utm_placement;
            } else {
                cuid = await ClevertapService.getCleverTapID();
            }

            (window as any).AF('pba', 'event', {
                eventType: 'EVENT',
                eventName,
                cuid,
                eventValue: {
                    eventName,
                    cuid,
                },
            });
        }
    },
    setCUID: function (cuid: any) {
        if (window && typeof (window as any).AF === 'function') {
            (window as any).AF('pba', 'setCustomerUserId', cuid);
        }
    },
};
