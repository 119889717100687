import React, { useEffect, useRef, useState } from "react";

import regbanner from "../../assets/icons/regBanner.jpeg";
import useQuery from "../../customHooks/useQuery";
import { initialDocObj } from "../DocumentVerification/DocVerificationForm/DocVerificationForm";
import { saveDocumentObj } from "../Registration/PANInfo/PANInfo";
import { AUploadDocument } from "../Services/dashboard/brokerageServices";
import {
  ACheckLocation,
  ACheckPhotoLiveness,
  ASaveDocumentDetails,
} from "../Services/dashboard/docVerificationAPIServices";
import { ASaveIPVerificationDetails } from "../Services/dashboard/ipvApiServices";
import { AGetStageCompletionStatus } from "../Services/registration/continueApiService";
import { AGetClientDetailsByPan } from "../Services/registration/panDobApiService";
import GlobalStore from "../store/globalStore";
import Button from "../UI/Button/Button";
import { DocDetailFormat } from "../UI/DocumentBrowse/DocumentBrowse";
import { errorMessage } from "../utils/errorMessage";
import { geoLocationConfig } from "../utils/constants";
import useGeoLocation from "../../customHooks/useGeoLocation";
import InfoModal from "../UI/InfoModal/InfoModal";
import DeviceContext from "../Services/identifyDevice";

import "./Selfie.scss";
import { utmDurationInfoObj } from "../Services/globalService";
import gtmService from "../Services/gtmService";
import ErrMsg from "../UI/ErrMsg/ErrMsg";
import { NewLineKind } from "typescript";
import NewSelfie from "./NewSelfie";
export interface iGeoLocation {
  loaded: boolean;
  coordinates: { lat: string; lng: string };
  error: boolean;
}

const Selfie = () => {
  const queryParams = useQuery();
  const id = queryParams.get("id") ? queryParams.get("id") : "";
  const [hasPhoto, setHasPhoto] = useState(false);
  const [photoObj, setPhotoObj] = React.useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [showRetake, setShowRetake] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<number | string>("");
  const [eSignCompleted, setESignCompleted] = useState(false);
  const [streams, setStreams] = useState<any>();
  const [lat, setLat] = useState<any>("");
  const [long, setLong] = useState<any>("");
  const [startTime, setStartTime] = React.useState<Date>(new Date());

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const geoLoc: iGeoLocation = useGeoLocation();
  const [isGeoError, setIsGeoError] = React.useState<boolean>(false);
  const [isGeoErrorTimeFlag, setIsGeoErrorTimeFlag] =
    React.useState<boolean>(false);
  const [showBrowserPermissionModal, setsShowBrowserPermissionModal] =
    useState<boolean>(false);
  const [isSdkSelfie, setIsSdkSelfie] = useState<boolean>(true);
  const [browserPermission, setBrowserPermission] = useState<boolean>(true);
  const [locationPermission, setLocationPermission] = useState<boolean>(true);
  const [cameraPermission, setCameraPermission] = useState<boolean>(true);
  const [liveLocationCheck, setLiveLocationCheck] = useState<any>({
    checkPhotoLiveness: true,
    checkLocation: true,
  });
  const [photoErrorMsg, setPhotoErrorMsg] = React.useState("");
  let watchPositionId: number;

  let base64String: string;
  let saveDetails: saveDocumentObj[] = [];

  useEffect(() => {
    if (id) {
      const pan = atob(id);
      console.log("pan", pan);
      AGetClientDetailsByPan(searchByPan, pan);
    }
  }, [id]);

  useEffect(() => {
    console.log("Use effect called");
    if (!isSdkSelfie) {
      setTimeout(() => {
        watchPositionId = navigator.geolocation.watchPosition(
          successPosition,
          errorPosition
        );
        setIsGeoErrorTimeFlag(true);
      }, geoLocationConfig.errorTimer);

      canvasRef.current!.hidden = true;
    }

    //     intiateCamera();
  }, []);

  React.useEffect(() => {
    if (!isSdkSelfie) {
      watchPositionId = navigator.geolocation.watchPosition(
        successPosition,
        errorPosition
      );
      if (locationPermission === true && cameraPermission === true) {
        console.log("inside setter ");
        setBrowserPermission(true);
        setsShowBrowserPermissionModal(false);
        setIsGeoError(false);
        setIsLoading(false);
      } else {
        console.log("inside setter else");
        setBrowserPermission(false);
        setsShowBrowserPermissionModal(true);
        setIsGeoError(true);
        setIsLoading(true);
      }
    }
    // getLocation();
  }, [geoLoc, locationPermission, cameraPermission, isSdkSelfie]);

  React.useEffect(() => {
    //     initiateCamera();
    if (lat && long && !isSdkSelfie) {
      const locationObj = {
        lat: lat,
        lng: long,
      };
      ACheckLocation((res: any) => checkLocationRes(res), locationObj);
    }
  }, [lat, long, isSdkSelfie]);

  const checkLocationRes = (res: any) => {
    if (!res || res.error) {
      // setErrCode("116 - Error in fetching Pan details from our system.");
      // setErrCode(errorMessage.panDetailsFailed);
      setErrCode(res.error);
      setIsLoading(false);
      setLiveLocationCheck((prev: any) => {
        return {
          ...prev,
          checkLocation: true,
        };
      });
      gtmService({ action: "CheckLocation", label: "ERROR || ERR999" });
    } else {
      if (res.data && res.data.LocationInIndia) {
        setLiveLocationCheck((prev: any) => {
          return {
            ...prev,
            checkLocation: false,
          };
        });
        setErrCode("");
      } else if (!res.data.LocationInIndia) {
        setLiveLocationCheck((prev: any) => {
          return {
            ...prev,
            checkLocation: true,
          };
        });
        setErrCode(res.data.Message);
      }
      setIsLoading(false);
    }
  };

  const getLocation = () => {
    if (geoLoc.loaded && geoLoc.error == false) {
      console.log("I'm here");
      setLat(`${geoLoc.coordinates.lat}`);
      setLong(`${geoLoc.coordinates.lng}`);
    } else {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(setLocation, errorLocation, {
          enableHighAccuracy: geoLocationConfig.enableHighAccuracy,
          maximumAge: geoLocationConfig.maximumAge,
          timeout: geoLocationConfig.timeout,
        });
      } else {
        alert("Geo location is not supported by this browser");
      }
      watchPositionId = navigator.geolocation.watchPosition(
        successPosition,
        errorPosition
      );
    }
  };

  function successPosition(data: any) {
    setIsLoading(false);
    setLocationPermission(true);

    // setBrowserPermission(true);
    // setsShowBrowserPermissionModal(false);
    setLat(data.coords.latitude);
    setLong(data.coords.longitude);
    navigator.geolocation.clearWatch(watchPositionId);
  }

  function errorPosition(data: any) {
    setIsLoading(true);
    setLocationPermission(false);

    // setBrowserPermission(false);
    // setsShowBrowserPermissionModal(true);
    // if (!browserPermission) {
    //   getLocation();
    // }
  }

  function errorLocation(err: any) {
    console.log(`ERROR(${err.code}): ${err.message}`);
    geoLoc.error = true;
    setIsLoading(true);
    setIsGeoError(true);
    // getLocation();
  }

  const setLocation = (position: { coords: any }) => {
    console.log(position.coords);
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
    geoLoc.coordinates.lat = position.coords.latitude;
    geoLoc.coordinates.lng = position.coords.longitude;
    geoLoc.error = false;
    setIsGeoError(false);
    setIsGeoErrorTimeFlag(false);
    setIsLoading(false);
    console.log("In saveLocation", geoLoc);
  };

  function saveIPVerification(data: any, uploadObj: saveDocumentObj, res: any) {
    let latitude = lat;
    let longitude = long;
    const ipvObj = {
      leadid: GlobalStore.leadId,
      clientprimarydetailid: GlobalStore.clientPrimaryDetailId,
      applicationid: GlobalStore.applicationID,
      placeofDeclaration: "mumbai",
      empName: "diy user",
      designation: "online rm",
      branch: "mumbai",
      empCode: "0",
      organization: "Nuvama Wealth & investment Ltd.",
      organizationCode: "2",
      // geo_location: `${latitude}, ${longitude}`,
      latitude: latitude,
      longitude: longitude,
      ipaddress: "192.168.200.80",
      source: "Selfie",
    };
    //ASaveIPVerificationDetails(iPVerificationSaved, ipvObj);

    ASaveIPVerificationDetails(
      (response: any) => iPVerificationSaved(response, uploadObj, res),
      ipvObj
    );
  }

  const iPVerificationSaved = (
    res: any,
    uploadObj: saveDocumentObj,
    prevres: any
  ) => {
    if (!res || res.error) {
      // setErrCode("Error in saving geo-location details");
      setErrCode(res.error);
      gtmService({
        action: "SaveIpverificationDetails",
        label: "ERROR || ERR146",
      });
      setIsLoading(false);
    } else {
      gtmService({
        action: "SaveIpverificationDetails",
        label: "ERROR || ERR146-S",
      });

      uploadObj.fileName = prevres.data.Guid;
      saveDetails.push(uploadObj);
      const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");

      const saveObj = {
        applicationId: GlobalStore.applicationID,
        clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
        documentInfo: saveDetails,
        leadId: GlobalStore.leadId,
        objUTM_Info: objUTM_Info,
      };

      ASaveDocumentDetails(() => documentDetailsSaved(res), saveObj);
    }
  };

  const searchByPan = (res: any) => {
    if (!res || res.error) {
      // setErrCode("118 - Error in fetching Client details from our system.");
      setErrCode(res.error);
      gtmService({
        action: "GetClientDataByPanOrLead",
        label: "ERROR || ERR148",
      });
      setIsLoading(false);
    } else {
      setIsLoading(true);
      if (res.data) {
        if (res.data != null) {
          GlobalStore.personaldetails.pan = res.data.PAN;
          GlobalStore.token = res.data.Token;
          GlobalStore.applicationID = res.data.ApplicatoinId;
          GlobalStore.leadId = res.data.LeadId;
          GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;
          if (!isSdkSelfie) {
            getLocation();
            AGetStageCompletionStatus(
              (res: any) => getClientStageDetails(res),
              GlobalStore.applicationID
            );
          } else {
            setIsLoading(false);
          }
        }
      } else {
        setErrCode("1182 - Error in fetching Client details from our system.");
        setIsLoading(false);
        gtmService({
          action: "GetClientDataByPanOrLead",
          label: "ERROR || ERR148-S",
        });
      }
    }
  };

  function getClientStageDetails(res: any) {
    setIsLoading(false);
    if (!res || res.error) {
      // setErrCode('1061 - Error in fetching stage completion details.');
      // setErrCode(errorMessage.getClientStageDetails);
      gtmService({
        action: "GetStageCompletionStatus",
        label: "ERROR || ERR147",
      });
      setErrCode(res.error);
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        if (responseObj.isesigncompleted) {
          console.log("Esign Completed");
          setESignCompleted(true);
        }
      } else {
        gtmService({
          action: "GetStageCompletionStatus",
          label: "ERROR || ERR147-S",
        });
      }
    }
  }

  const HandleNewSDKSelfieRes = (data: any) => {
    if (data && data.errMsg) {
      setBrowserPermission(true);
    } else if (data.is_valid == "1" && data.Latitude && data.Longitude) {
      // setIsLoading(true);
      const new_File = new File(
        [Blob as unknown as BlobPart],
        "personalPhoto.jpeg",
        {
          type: "image/jpeg",
          lastModified: Date.now(),
        }
      );

      setHasPhoto(true);

      let base64 = data?.cropped_image;
      base64String = base64;
      setPhotoObj((prev: any) => {
        return {
          ...prev,
          base64: base64String,
          imgUrl: `data:image/jpeg;base64, ${base64}`,
        };
      });

      if (data?.Country && data?.Country.toLowerCase() == "india") {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkLocation: false,
          checkPhotoLiveness: false,
        });

        setLat(data.Latitude);
        setLong(data.Longitude);
      } else {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkLocation: true,
        });

        setErrCode(
          errCode
            ? errCode
            : " " +
                "Oops, you are not in permitted range of account opening as per regulatory guidelines."
        );
      }
    }
  };

  const intiateCamera = async () => {
    canvasRef.current!.hidden = true;
    const constraints = {
      audio: false,
      video: {
        facingMode: "user",
      },
    };

    try {
      const userMedia = await navigator.mediaDevices.getUserMedia(constraints);
      const video = videoRef.current;
      if (video) {
        video.srcObject = userMedia;
        await video.play();
        setStreams(userMedia);
      }
    } catch (err) {
      alert(err);
    }
    setShowRetake(true);
  };

  const takePhoto = async () => {
    canvasRef.current!.hidden = false;

    let video = videoRef.current;
    let canvas = canvasRef.current;

    if (canvas && video) {
      // console.log('If canvas');
      const width = video.videoWidth;
      const height = video.videoHeight;
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");

      videoRef.current!.hidden = true;
      ctx?.drawImage(video, 0, 0, width, height);

      canvas.toBlob((blob) => storePhoto(blob));

      closePhoto();
    }
    setHasPhoto(true);
  };

  const closePhoto = () => {
    streams?.getTracks().forEach((track: { stop(): any }) => {
      track.stop();
    });
  };

  const storePhoto = (blob: Blob | null) => {
    const new_File = new File([blob as BlobPart], "personalPhoto.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    if (new_File.size > 50000000) {
      alert("video size exceeds maximum limit. Please upload it again");
      return false;
    }
    if (new_File.size < 1000) {
      alert("video size should be greater than 1KB. Please upload it again");
      return false;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(new_File);
    fileReader.onload = function (event) {
      const base64 = (event.target && event.target.result)?.toString();
      base64String = base64!.split(",")[1];
      photoObj.base64 = base64String;
      // console.log(base64String);
      setIsLoading(true);
      ACheckPhotoLiveness(
        (res: any) => getExtractPhotoLiveness(res),
        base64String,
        GlobalStore.leadId
      );
    };
  };
  const getExtractPhotoLiveness = (res: any) => {
    if (!res || res.error) {
      // setErrCode("116 - Error in fetching Pan details from our system.");
      // setErrCode(errorMessage.panDetailsFailed);
      setErrCode(res.error);
      setPhotoErrorMsg("");
      setIsLoading(false);
      setLiveLocationCheck((prev: any) => {
        return {
          ...prev,
          checkPhotoLiveness: true,
        };
      });
      gtmService({ action: "checkPhotoLiveness", label: "ERROR || ERR998" });
    } else {
      if (res.data && res.data.IsLive) {
        setPhotoErrorMsg(res.data.Message);
        setLiveLocationCheck((prev: any) => {
          return {
            ...prev,
            checkPhotoLiveness: false,
          };
        });
        setErrCode("");
      } else if (!res.data.IsLive) {
        setLiveLocationCheck((prev: any) => {
          return {
            ...prev,
            checkPhotoLiveness: true,
          };
        });
        setPhotoErrorMsg(res.data.Message);
        // if (errCode !== res.data.Message) {
        //   setErrCode(res.data.Message);
        // }
      }
      setIsLoading(false);
    }
  };

  const uploadSelfie = () => {
    console.log("lat", lat);
    console.log("long", long);
    if (lat !== "" && lat !== undefined && long !== "" && long !== undefined) {
      if (photoObj.base64 !== "" || photoObj.base64 !== undefined) {
        photoObj.id = "photo";
        photoObj.disable = false;
        photoObj.uploadPer = 100;
        photoObj.fileName = `${GlobalStore.applicationID}_Photo`;
        photoObj.showFile = false;
        photoObj.hideProgress = false;
        photoObj.mimeType = "image/jpeg";
        photoObj.documentId = 2;
        setPhotoObj(photoObj);
        const savePhotoObj: saveDocumentObj = {
          documentCategoryId: photoObj.documentCategoryId?.toString(),
          documentId: photoObj.documentId?.toString(),
          fileName:
            photoObj.fileName +
            (photoObj.mimeType === "application/pdf" ? ".pdf" : ".jpg"),
          isfilecropped: false,
          mimeType: photoObj.mimeType || ".",
          panno: GlobalStore.personaldetails.pan,
          personaldetailsid: "0",
          autoqc: false,
          iskycdocument: false,
        };
        const selfie = true;
        const photoUploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: photoObj.base64,
          filename:
            photoObj.fileName +
            (photoObj.mimeType === "application/pdf" ? ".pdf" : ".jpg"),
        };

        AUploadDocument(
          (res: any) => getUploadResponse(res, savePhotoObj),
          photoUploadObj
        );
      } else {
        setErrCode("Error in uploading the file...");
      }
    } else {
      setsShowBrowserPermissionModal(true);
    }
  };

  const getUploadResponse = (res: any, uploadObj: saveDocumentObj) => {
    saveDetails = [];
    if (!res || res.error) {
      // setErrCode("1361- Error in uploading the file.");
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data && res.data.Result) {
        saveIPVerification({ coords: {} }, uploadObj, res);
        // uploadObj.fileName = res.data.Guid;
        // saveDetails.push(uploadObj);
        // const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");

        // const saveObj = {
        //   applicationId: GlobalStore.applicationID,
        //   clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
        //   documentInfo: saveDetails,
        //   leadId: GlobalStore.leadId,
        //   objUTM_Info: objUTM_Info,
        // };

        // ASaveDocumentDetails(() => documentDetailsSaved(res), saveObj);
      } else {
        setErrCode(res.error);
        gtmService({
          action: "UploadDocumentToS3",
          label: "ERROR || ERR144-S",
        });
      }
    }
  };

  const documentDetailsSaved = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
      gtmService({
        action: "SaveUploadedDocumentDetails",
        label: "ERROR || ERR145",
      });
      setIsLoading(false);
      saveDetails = [];
    } else if (res) {
      saveDetails = [];
      alert(
        "Selfie captured successfully, please close this browser window and continue with your Account Opening process"
      );
    } else {
      gtmService({
        action: "SaveUploadedDocumentDetails",
        label: "ERROR || ERR145-S",
      });
    }
  };
  async function turnOnCamera() {
    getLocation();

    const constraints = {
      audio: false,
      video: {
        facingMode: "user",
      },
    };

    function handleSuccess(turnOfCamera: MediaStream) {
      console.log("HANDLE success", turnOfCamera);
      // setBrowserPermission(true);
      // setsShowBrowserPermissionModal(false);
      setCameraPermission(true);
      setIsLoading(false);
    }

    function handleError(error: any) {
      console.log("HANDLE ERROR", error);
      setIsLoading(true);
      // setBrowserPermission(false);
      // setsShowBrowserPermissionModal(true);
      setCameraPermission(false);
      console.log("handle error line end");
      // if (!browserPermission) {
      //   turnOnCamera();
      // }
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        console.log(stream, "then");
        handleSuccess(stream);
      })
      .catch((stream) => {
        console.log(stream, "catch");
        handleError(stream);
      });
  }

  console.log(
    "hasPhoto",
    !hasPhoto,
    isGeoError,
    liveLocationCheck.checkPhotoLiveness,
    liveLocationCheck.checkLocation
  );
  return (
    <>
      <div className="mgt40">
        <div className="fieldLblRight">
          <div className="explain">
            {showBrowserPermissionModal && (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InfoModal width={DeviceContext().isMobile ? "328px" : "354px"}>
                  <div style={{ margin: "16px" }} className="explainInfo w-100">
                    <label>
                      To open your account online, we would mandatorily need
                      access to your geo locations and camera. In your browser
                      notification, click on 'Allow' to proceed ahead
                    </label>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <Button
                      btnName="OK"
                      onClick={() => {
                        turnOnCamera();
                        // setsShowBrowserPermissionModal(false);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </InfoModal>
              </div>
            )}
          </div>
        </div>
      </div>
      <fieldset disabled={isLoading}>
        <div className={`container  ${eSignCompleted ? "readOnly" : ""}`}>
          {/* <div className="banner">
                <img className="bannerImg" src={regbanner} alt="banner" />
            </div> */}
          <label className="heading">Personal Photo</label>
          <br />
          <label className="photoSubHeading">
            Click a selfie making sure your face is clearly visible.
          </label>
          <div className="imageBox">
            {isSdkSelfie ? (
              <>
                <NewSelfie
                  callback={(data: any) => {
                    HandleNewSDKSelfieRes(data);
                  }}
                  onFailedToOpenSdk={setIsSdkSelfie}
                />
                {photoObj.base64 && (
                  <section className="photo-cont">
                    <img src={photoObj.imgUrl} alt="photograph" />
                  </section>
                )}
              </>
            ) : (
              <>
                <video
                  className="videoBox"
                  id="videoPersonal"
                  ref={videoRef}
                  autoPlay
                  playsInline
                />
                {!hasPhoto && <div className="photoOutline"></div>}
                {!hasPhoto && <div className="captureFrame"></div>}
                {!showBrowserPermissionModal && (
                  <div>
                    {" "}
                    {!showRetake ? (
                      <div className="cameraBtn">
                        <span
                          onClick={intiateCamera}
                          style={{ color: "white" }}
                        >
                          Ready?
                        </span>
                      </div>
                    ) : hasPhoto ? (
                      <div className="cameraBtn">
                        <span
                          className="edel-icon-retake"
                          onClick={() => {
                            videoRef.current!.hidden = false;
                            setHasPhoto(false);
                            intiateCamera();
                          }}
                        />
                      </div>
                    ) : (
                      <div className="cameraBtn">
                        <span
                          className="edel-icon-camera"
                          onClick={takePhoto}
                        />
                      </div>
                    )}{" "}
                  </div>
                )}
                <canvas ref={canvasRef} id="capture" className="canvasImg" />
              </>
            )}
          </div>

          {isGeoError && !isGeoErrorTimeFlag ? (
            <>
              <div className="geoErrorMsg">
                <p>
                  {" "}
                  There is a delay in loading the contents, please wait for a
                  moment !!
                </p>
              </div>
            </>
          ) : null}

          {isGeoError && isGeoErrorTimeFlag ? (
            <>
              <div className="geoErrorMsg">
                <p>
                  {" "}
                  There was a delay in loading the contents, refresh the page!!{" "}
                </p>
              </div>
            </>
          ) : null}
          {/* Error Msg Start*/}
          {errCode && (
            <div className="errMsgBlck errWidth mrt40">
              <ErrMsg>
                {typeof errCode === "number" ? (
                  <>
                    <label>We are facing some technical issue.</label>
                    <label>
                      Please try after sometime (error code:{errCode})
                    </label>
                  </>
                ) : (
                  <label>{errCode}</label>
                )}
              </ErrMsg>
            </div>
          )}
          {/* Error Msg End*/}
          {isGeoError && isGeoErrorTimeFlag ? (
            <>
              <div className="geoErrorMsg">
                <p>
                  {" "}
                  There was a delay in loading the contents, refresh the page!!{" "}
                </p>
              </div>
            </>
          ) : null}
          {/* Error Msg Start*/}

          {/* Photo Error Msg Start*/}
          {photoErrorMsg != "" && (
            <div className="errMsgBlck">
              <ErrMsg>
                <label>{photoErrorMsg}</label>
              </ErrMsg>
            </div>
          )}
          {/* Photo Error Msg End*/}

          {/* {errCode && (
            <div className="errMsgBlck errWidth mrt40">
              <ErrMsg>
                {typeof errCode === "number" ? (
                  <>
                    <label>We are facing some technical issue.</label>
                    <label>
                      Please try after sometime (error code:{errCode})
                    </label>
                  </>
                ) : (
                  <label>{errCode}</label>
                )}
              </ErrMsg>
            </div>
          )} */}
          {/* Error Msg End*/}
          <div className="row">
            <Button
              onClick={uploadSelfie}
              btnName="Submit"
              isLoading={isLoading}
              disabled={
                !hasPhoto ||
                isGeoError ||
                liveLocationCheck.checkPhotoLiveness ||
                liveLocationCheck.checkLocation
              }
              type="submit"
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default Selfie;
