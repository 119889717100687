const nomineeDocIdsUAT = {
  nomineeOne: {
    photo: {
      documentId: 191,
      documentCategoryId: 19,
    },
    signature: {
      documentId: 623,
      documentCategoryId: 93,
    },
    guardianDetails: {
      photo: {
        documentId: 219,
        documentCategoryId: 23,
      },
      signature: {
        documentId: 382,
        documentCategoryId: 69,
      },
    },
  },
  nomineeTwo: {
    photo: {
      documentId: 501,
      documentCategoryId: 75,
    },
    signature: {
      documentId: 624,
      documentCategoryId: 94,
    },
    guardianDetails: {
      photo: {
        documentId: 561,
        documentCategoryId: 83,
      },
      signature: {
        documentId: 621,
        documentCategoryId: 91,
      },
    },
  },
  nomineeThree: {
    photo: {
      documentId: 531,
      documentCategoryId: 79,
    },
    signature: {
      documentId: 625,
      documentCategoryId: 95,
    },
    guardianDetails: {
      photo: {
        documentId: 591,
        documentCategoryId: 87,
      },
      signature: {
        documentId: 622,
        documentCategoryId: 92,
      },
    },
  },
};

const nomineeDocIdsPreprod = {
  nomineeOne: {
    photo: {
      documentId: 191,
      documentCategoryId: 19,
    },
    signature: {
      documentId: 623,
      documentCategoryId: 93,
    },
    guardianDetails: {
      photo: {
        documentId: 219,
        documentCategoryId: 23,
      },
      signature: {
        documentId: 382,
        documentCategoryId: 69,
      },
    },
  },
  nomineeTwo: {
    photo: {
      documentId: 501,
      documentCategoryId: 75,
    },
    signature: {
      documentId: 624,
      documentCategoryId: 94,
    },
    guardianDetails: {
      photo: {
        documentId: 561,
        documentCategoryId: 83,
      },
      signature: {
        documentId: 621,
        documentCategoryId: 91,
      },
    },
  },
  nomineeThree: {
    photo: {
      documentId: 531,
      documentCategoryId: 79,
    },
    signature: {
      documentId: 625,
      documentCategoryId: 95,
    },
    guardianDetails: {
      photo: {
        documentId: 591,
        documentCategoryId: 87,
      },
      signature: {
        documentId: 622,
        documentCategoryId: 92,
      },
    },
  },
};

const nomineeDocIdsProd = {
  nomineeOne: {
    photo: {
      documentId: 191,
      documentCategoryId: 19,
    },
    signature: {
      documentId: 623,
      documentCategoryId: 93,
    },
    guardianDetails: {
      photo: {
        documentId: 219,
        documentCategoryId: 23,
      },
      signature: {
        documentId: 382,
        documentCategoryId: 69,
      },
    },
  },
  nomineeTwo: {
    photo: {
      documentId: 501,
      documentCategoryId: 75,
    },
    signature: {
      documentId: 624,
      documentCategoryId: 94,
    },
    guardianDetails: {
      photo: {
        documentId: 561,
        documentCategoryId: 83,
      },
      signature: {
        documentId: 621,
        documentCategoryId: 91,
      },
    },
  },
  nomineeThree: {
    photo: {
      documentId: 531,
      documentCategoryId: 79,
    },
    signature: {
      documentId: 625,
      documentCategoryId: 95,
    },
    guardianDetails: {
      photo: {
        documentId: 591,
        documentCategoryId: 87,
      },
      signature: {
        documentId: 622,
        documentCategoryId: 92,
      },
    },
  },
};

const nomineeDocsIds =
  process.env.REACT_APP_ENV === "production"
    ? nomineeDocIdsProd
    : process.env.REACT_APP_ENV === "preprod"
    ? nomineeDocIdsPreprod
    : nomineeDocIdsUAT;

export default nomineeDocsIds;
