import React, { Component } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import regbanner from '../../../assets/icons/account-not-found.png';
import config from '../../../config/domain';
import Button from '../../UI/Button/Button';
import { containerUrl, dashboardUrl, statusUrl } from '../../utils/constants';

// import './AccountNotFound.scss';

class AccountNotFound extends Component<RouteChildrenProps> {
    checkStatus = () => {
        window.location.href = config.root + statusUrl.statusHome.replace("/", "");
    };
    newAccount = () => {
        window.location.href = config.root;
    };
    render() {
        return (
            <div className="status-verification-root">
                <img src={regbanner} className="verified-img" />
                <div>
                    <h2 className="status-heading">No Account Found</h2>
                    <span className="status-subHead">
                        We didn't have any account with the given <br />
                        details.Would you like to open an account <br /> with us?
                    </span>
                    <div className="login-btn">
                        <Button btnName="Open an account" type="submit" onClick={this.newAccount} />
                    </div>
                    <div className="new-pan-entry">
                        <label className="alreadyAccountLabel">Entered PAN by mistake? </label>
                        <label className="loginLink" onClick={this.checkStatus}>
                            Use a different PAN
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountNotFound;
