import * as React from "react";
import "./ESignForm.scss";
import DocStatus from "../DocStatus/DocStatus";
import Button, { TransparentButton } from "../../UI/Button/Button";
import GlobalStore from "../../store/globalStore";
import ScheduleAgentVisit from "../ScheduleAgentVisit/ScheduleAgentVisit";
import {
  APendingUploadList,
  APendingApprovalList,
  AGetEsignUrlDetails,
} from "../../Services/dashboard/eSignApiServices";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import { AGetUploadedDocument } from "../../Services/dashboard/docVerificationAPIServices";
import { downloadForm } from "../../POA/Poa";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import { setStageData } from "../../Services/globalService";
import blueTick from "../../../assets/icons/blueTick.svg";
import EsignInfoMsg from "../EsignInfoMsg/EsignInfoMsg";
import gtmService from "../../Services/gtmService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import { ClevertapService } from "../../Services/clevertapService";
import useQuery from "../../../customHooks/useQuery";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import Backdrop from "../../UI/Backdrop/Backdrop";
import isSeeding from "../../../assets/icons/seeding.png";
import { AGetPanUtiData } from "../../Services/registration/panDobApiService";
import { errorMessage } from "../../utils/errorMessage";
export interface ESignFormProps {}
let intervalTimer: any;
const ESignForm: React.FC<ESignFormProps> = () => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const [errCode, setErrCode] = React.useState<number | string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showResult, setShowResult] = React.useState<boolean>(false);
  const [isPendingUpload, setIsPendingUpload] = React.useState<boolean>(true);
  const [isPendingApproval, setIsPendingApproval] =
    React.useState<boolean>(true);
  const [pendingUploadList, setPendingUploadList] = React.useState<Array<any>>(
    []
  );
  const [pendingApprovalList, setPendingApprovalList] = React.useState<
    Array<any>
  >([]);
  const [showLink, setShowLink] = React.useState<boolean>(true);
  const [esignCompleted, setEsignCompleted] = React.useState(false);
  const [seedingPopup, setSeedingPopup] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (GlobalStore.stageStatus.bpm_pd_status === 1) {
      setIsPendingUpload(false);
      setIsPendingApproval(false);
      setShowResult(true);
      setIsLoading(false);
    } else {
      APendingUploadList(checkPendingUpload, GlobalStore.applicationID);
    }

    return () => {
      clearInterval(intervalTimer);
    };
  }, []);

  function checkPendingUpload(res: any) {
    if (!res || res.error) {
      //   setErrCode("");
      setErrCode(res.error);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR115" });
      setIsLoading(false);
    } else {
      if (res && res.data) {
        // let list: Array<any> = JSON.parse(JSON.parse(res.data)).filter((item: any) => item.documentcategoryid !== 36 && item.documentcategoryid !== 30 && item.documentcategoryid !== 62);
        let list: Array<any> = JSON.parse(res.data).filter(
          (item: any) =>
            // Prev api response
            // item.mandatory

            // Cureent Response
            item.Mandatory
        );
        if (list.length === 0) {
          setIsLoading(true);
          setIsPendingUpload(false);
          if (GlobalStore.stageStatus.bpm_pd_status === 0)
            AGetUploadedDocument(getUploadedDocList, GlobalStore.applicationID);
          if (GlobalStore.stageStatus.bpm_pd_status === 1) {
            setIsPendingUpload(false);
            setShowResult(true);
            setIsPendingApproval(false);
            setIsLoading(false);
          }
          if (
            GlobalStore.stageStatus.bpm_pd_status === 2 ||
            GlobalStore.stageStatus.bpm_pd_status === 3
          )
            APendingApprovalList(
              checkPendingApproval,
              GlobalStore.applicationID
            );
        } else {
          // const arr: Array<{
          //   contenttype: string;
          //   status: string;
          //   documenttype: string;
          // }> = [];
          // for (let i = 0; i < list.length; i++) {
          //   let obj: {
          //     contenttype: string;
          //     status: string;
          //     documenttype: string;
          //   } = {
          //     contenttype: list[i].DocumentCategory,
          //     status: "Pending Approval",
          //     documenttype: list[i].DocumentName,
          //   };
          //   arr.push(obj);
          // }
          // setPendingApprovalList(arr);
          setPendingUploadList(list);
          setIsLoading(false);
          setShowResult(true);
        }
      }
    }
  }

  function checkPendingApproval(res: any) {
    setIsLoading(false);
    if (!res || res.error) {
      setErrCode("");
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR116" });
    } else {
      if (res && res.data) {
        let list: Array<any> = JSON.parse(res.data).filter(
          (item: any) => item.DocumentType
        );
        let showList: boolean = false;
        for (let i = 0; i < list.length; i++) {
          if (list[i].Status !== "Approved") {
            showList = true;
            break;
          }
        }
        if (showList) setPendingApprovalList(list);
        else setIsPendingApproval(false);
        setShowResult(true);
      }
    }
  }

  function checkPdStatus() {
    const timer = setInterval(() => {
      AGetStageCompletionStatus(
        getClientStageDetails,
        GlobalStore.applicationID
      );
    }, 10000);
    intervalTimer = timer;
  }

  function getClientStageDetails(res: any) {
    if (!res || res.error) {
      setErrCode(res.error);
      gtmService({
        action: "GetStageCompletionStatus",
        label: "ERROR || ERR119",
      });
    } else if (res.data) {
      const responseObj = JSON.parse(res.data);
      setStageData(responseObj);
      if (responseObj.bpm_pd_status === 1) {
        setIsPendingUpload(false);
        setShowResult(true);
        setIsPendingApproval(false);
        setIsLoading(false);
        clearInterval(intervalTimer);
      }
      if (
        responseObj.bpm_pd_status === 2 ||
        GlobalStore.stageStatus.bpm_pd_status === 3
      ) {
        APendingApprovalList(checkPendingApproval, GlobalStore.applicationID);
        clearInterval(intervalTimer);
      }
      if (responseObj.isesigncompleted === true) {
        setEsignCompleted(true);
      }
    }
  }

  function getUploadedDocList(res: any) {
    if (!res || res.error) {
      setErrCode("");
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR118" });
    } else {
      const responseObj = JSON.parse(res.data);
      if (responseObj) {
        const list: Array<any> = responseObj.filter(
          (item: any) => item.DocumentName
        );
        const arr: Array<{
          contenttype: string;
          status: string;
          documenttype: string;
        }> = [];
        for (let i = 0; i < list.length; i++) {
          let obj: {
            contenttype: string;
            status: string;
            documenttype: string;
          } = {
            contenttype: list[i].DocumentCategory,
            status: list[i].WsdlFlag,
            documenttype: list[i].DocumentName,
          };
          arr.push(obj);
        }
        setPendingApprovalList(arr);
        setShowResult(true);
        // checkPdStatus();
      } else {
        setErrCode("");
      }
    }
    setIsLoading(false);
  }

  function esignSubmit() {
    //redirect to NSDL website for esign
    setIsLoading(true);
    window.alert("You will be redirected here after Completion of Esign");
    AGetEsignUrlDetails(getEsignUrl, GlobalStore.applicationID, 1);
  }

  function getEsignUrl(res: any) {
    if (!res || res.error) {
      console.log(res.errMsg);
      setErrCode(res.error);
      gtmService({ action: "GetEsignLink", label: "ERROR || ERR117" });
      setIsLoading(false);
    } else {
      if (
        (res.data.Code == 200 || res.data.Code == 201) &&
        res.data.Data.EsignLink !== null
      ) {
        const encryptedUrl = res.data.Data.EsignLink;
        gtmService({ action: "E_SIGN | E_SIGN", label: "Step_Complete" });

        // Clevertap event while user choose for e-sign
        esignCleverTapEvent(true);
        // Clevertap ends

        const eSignCall = {
          applicationId: GlobalStore.applicationID,
          isESignSubmitted: true,
        };

        localStorage.setItem(
          "isESignSubmitted",
          btoa(JSON.stringify(eSignCall))
        );

        window.location.href = encryptedUrl;
      } else if (
        res.data.Data.FormValidationSummary[0].FormValidations &&
        res.data.Data.FormValidationSummary[0].FormValidations ===
          "Aadhaar seeding is unsuccessful."
      ) {
        setSeedingPopup(true);
        setIsLoading(false);
      } else {
        var allErrorsString = "";
        if (res.data.Data.FormValidationSummary.length > 0) {
          res.data.Data.FormValidationSummary.forEach(
            (element: any, i: number) => {
              if (i === 0) allErrorsString = element.FormValidations;
              else allErrorsString += `, ${element.FormValidations}`;
            }
          );
        }
        console.log("Error from Esign api", allErrorsString);
        setErrCode(res.data.Data.FormValidationSummary[0].FormValidations);
        setIsLoading(false);
      }
    }
  }

  // Clevertap event
  const esignCleverTapEvent = (isEsign: boolean) => {
    const data = {
      EventName: cleverTapEvents.registration.eSignPage.CLICK_ON_ESIGN,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
      IS_ESIGN: isEsign,
    };

    ClevertapService.pushEvent(data);

    // Apps flyer event for click on proceed on bank details page
    AppsFlyerService.pushEvent(
      appsFlyerEvents.registration.eSignPage.CLICK_ON_ESIGN
    );
  };
  // Clevertap ends

  const reCheck = () => {
    setIsLoading(true);
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    AGetPanUtiData(getSeedingData, clientPartialDetails_ao.pan);
  };

  // const getSeedingData = (res: any) => {
  //   if (!res || res.error) {
  //     // setErrCode("170  - Error in fetching Pan details from PAN UTI system.");
  //     console.log(res.errMsg);
  //     setErrCode(res.error);
  //   } else {
  //     if (res.data) {
  //       setIsLoading(false);
  //       let responseObj = res.data.panDetails;
  //       // if (!responseObj?.IsValid) {
  //       //   GlobalStore.isSeeding = false;
  //       //   setSeedingPopup(true);
  //       // } else if (responseObj?.IsValid) {
  //       //   GlobalStore.isSeeding = true;
  //       //   setSeedingPopup(false);
  //       // }
  //       if (responseObj?.AadharSeedingStatus.includes("Aadhaar not Seeded")) {
  //         GlobalStore.isSeeding = false;
  //         setSeedingPopup(true);
  //       } else if (
  //         responseObj?.AadharSeedingStatus.includes(
  //           "Aadhaar Seeding is Successful"
  //         )
  //       ) {
  //         GlobalStore.isSeeding = true;
  //         setSeedingPopup(false);
  //       }
  //     }
  //   }
  // };
  const getSeedingData = (res: any) => {
    if (!res || res.error) {
      console.log(res.errMsg);
      setErrCode(res.error);
      gtmService({ action: "GetPanDetails", label: "ERROR || ERR120" });
    } else {
      if (res.data) {
        setIsLoading(false);
        // let responseObj = JSON.parse(res.data.panDetails);
        let responseObj = res.data.AadharSeedingStatus
          ? res.data.AadharSeedingStatus
          : "";
        // if (responseObj.includes("Aadhaar not Seeded")) {
        if (res.data.IsAadharSeeded == "N") {
          GlobalStore.isSeeding = false;
          setSeedingPopup(true);
        } else if (
          res.data.IsAadharSeeded == "Y" ||
          res.data.IsAadharSeeded == "NA"
        ) {
          // } else if (responseObj.includes("Aadhaar Seeding is Successful")) {
          GlobalStore.isSeeding = true;
          setSeedingPopup(false);
        }
      }
    }
  };
  const navToLink = () => {
    window.open(
      "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar",
      "_blank"
    );
  };
  return (
    <>
      {isPendingApproval &&
        pendingApprovalList.length > 0 &&
        pendingApprovalList.some((data: any) => data.status === "PENDING") && (
          <div className="approvalInfo">
            <div className="heading">Verification in progress</div>
            <div className="heading info">{`It will take upto 30 mins on working days (between 9 AM to 6 PM). You can wait or we will update you when verified, by Email/Whatsapp.`}</div>
          </div>
        )}
      <div className={"eSignForm " + (isLoading ? " op05" : "")}>
        <DocStatus
          isPendingUpload={isPendingUpload}
          isPendingApproval={isPendingApproval}
          pendingUploadList={pendingUploadList}
          pendingApprovalList={pendingApprovalList}
          showResult={showResult}
        />
        {/* <Backdrop isShow={seedingPopup} isCloseIcon={false}> */}
        {/* <div className="isSeeding"> */}
        {/* {DeviceContext().isMobile && <EdelLogoCenter />} */}
        {/* <img className="seedingIcon" src={isSeeding} alt="isSeeding" />
            <label className="seedingLabel">PAN Aadhar Link</label>
            <label className="subLAbel">
              Please link your PAN and Aadhar to continue with the account
              opening journey.
            </label>
            <div className="seedingCTA">
              <div className="borderBlue">
                <TransparentButton
                  isLoading={isLoading}
                  btnName="Re-Check"
                  onClick={() => reCheck()}
                />
              </div>
              <div>
                <Button btnName="Link PAN & Aadhar" onClick={navToLink} />
              </div>
            </div>
          </div>
        </Backdrop> */}
        {/* Error Msg Start*/}
        {errCode && (
          <div className="errMsgBlck">
            <ErrMsg>
              {typeof errCode === "number" ? (
                <>
                  <label>We are facing some technical issue.</label>
                  <label>
                    Please try after sometime (error code:{errCode})
                  </label>
                </>
              ) : (
                <label>{errCode}</label>
              )}
            </ErrMsg>
          </div>
        )}
        {/* Error Msg End*/}
        {!isPendingUpload && showResult && (
          <>
            {/* <label className='eSignInfo'>
                            You will receive an OTP on your Aadhar linked mobile number. You can E-sign directly.
                        </label> */}
            {!isPendingApproval && (
              <div className="readyToEsign">
                <img className="tickImg" src={blueTick} alt="ok" />
                <label className="readyInfo">
                  Great! Your documents are ready to be E-Signed. Proceed to
                  E-Sign by clicking the button below.
                </label>
              </div>
            )}
            <div className="eSignBtn">
              <Button
                btnName="E-Sign"
                isLoading={isLoading}
                disabled={isPendingUpload || esignCompleted}
                onClick={esignSubmit}
              />
            </div>
          </>
        )}
        {/* {!isPendingUpload && showResult && !isPendingApproval && (
                    <>
                        {GlobalStore.isDigiLocker ? (
                            <>
                                {showLink ? (
                                    <EsignInfoMsg
                                        heading={`Courier your physical form`}
                                        info={'This can be used in case your Mobile No. and Aadhaar are not linked'}
                                        linkText={'Click here to continue'}
                                        onLinkClick={() => {
                                            esignCleverTapEvent(false);
                                            setShowLink(false);
                                        }}
                                    />
                                ) : (
                                    <div className="courierBlck">
                                        <label className="courierHeading">Courier Your Form</label>
                                        <label className="addressInfo">
                                            If your mobile number and Aadhar number aren’t linked, then download the
                                            pre-filled form, sign and courier it to our Mumbai Office at:
                                        </label>
                                        <div className="courierAddress">
                                            <label className="addressLbl">Address</label>
                                            <label className="addressVal">{`Nuvama Wealth & investment Ltd`}</label>
                                            <label className="addressVal">{`Unit No. 1 to 12, 1st Floor, Kanakia Wall Street, Chakala, Andheri Kurla Road, Andheri East, Mumbai 400093`}</label>
                                        </div>
                                        <label className="addressInfo btnMsg">
                                            Please Note: Account will be opened only on receipt of the physical form.
                                        </label>
                                        <div className="courierBtn">
                                            <Button
                                                btnName="Download and Courier"
                                                onClick={() => {
                                                    gtmService({
                                                        action: 'E-SIGN | DOWNLOAD_&_COURIER',
                                                        label: 'Step_Complete',
                                                    });
                                                    downloadForm(
                                                        `Esign_${GlobalStore.applicationID}`,
                                                        setIsLoading,
                                                        setErrCode,
                                                        true
                                                    );
                                                }}
                                                isLoading={false}
                                                disabled={isPendingApproval}
                                                prefix={<span className="edel-icon-download" />}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {showLink ? (
                                    <EsignInfoMsg
                                        heading={`Schedule a physical form pickup`}
                                        info={
                                            'This can be used in case your Mobile No. and Aadhaar are not linked. Pickup service is available only in select locations.'
                                        }
                                        linkText={'Click here to continue'}
                                        onLinkClick={() => {
                                            esignCleverTapEvent(false);
                                            setShowLink(false);
                                        }}
                                    />
                                ) : (
                                    <ScheduleAgentVisit
                                        disable={isPendingApproval}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        UTM_SOURCE={`${UTM_SOURCE}`}
                                        UTM_MEDIUM={`${UTM_MEDIUM}`}
                                        UTM_CAMPAIGN={`${UTM_CAMPAIGN}`}
                                    />
                                )}
                            </>
                        )}
                    </>
                )} */}
      </div>
    </>
  );
};

export default ESignForm;
