import React from "react";
import "./VideoPlayerIpv.scss";
import OtpTimer from "../OtpTimer/OtpTimer";
import Button from "../Button/Button";
import GlobalStore from "../../store/globalStore";
import {
  isSafariBrowser,
  utmDurationInfoObj,
} from "../../Services/globalService";
import gtmService from "../../Services/gtmService";
import { checkESignSubmitted } from "../../utils/eSignSubmitHandler";

export interface VideoPlayerIpvProps {
  ipvUploaded: (uploadObj: any, isSafari: boolean) => void;
  isGeoError?: boolean;
  isLocationPermission?: boolean;
  checkGeoLocation?: Function;
}

export function captureScreenShot(
  video: HTMLVideoElement,
  callback: CallableFunction
) {
  let canvas: HTMLCanvasElement = document.createElement(
    "canvas"
  ) as HTMLCanvasElement;
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas
    .getContext("2d")!
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  canvas.toBlob((blob) => {
    const new_File = new File([blob as BlobPart], "safariVideoIPV.webm", {
      type: "video/webm",
      lastModified: Date.now(),
    });
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      const base64 = (e.target && e.target.result)?.toString();
      callback(
        base64?.split(",")[1],
        blob,
        video.videoWidth,
        video.videoHeight
      );
    };
    fileReader.readAsDataURL(new_File);
  });
}

const VideoPlayerIpv: React.FC<VideoPlayerIpvProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showStartBtn, setShowStartBtn] = React.useState<boolean>(true);
  const [showVidPerm, setShowVidPerm] = React.useState<boolean>(false);
  const [showRecordTimer, setShowRecordTimer] = React.useState<boolean>(false);
  const [showUploadBtn, setShowUploadBtn] = React.useState<boolean>(false);
  const [stream, setStream] = React.useState<MediaStream>();
  const [webBlob, setWebBlob] = React.useState<Blob>();
  const isSafari: boolean = isSafariBrowser();
  const [frames, setFrames] = React.useState<Array<any>>([]);
  const [mediaRecorder, setMediaRecorder] = React.useState<MediaRecorder>();
  const videoRefElement = React.useRef<HTMLVideoElement>(null);
  const previewRefElement = React.useRef<HTMLVideoElement>(null);
  const imageRefElement = React.useRef<HTMLImageElement>(null);
  const [imgSrcSafari, setImgSrcSafari] = React.useState<string>();
  const [videoWidth, setVideoWidth] = React.useState<number>();
  const [videoHeight, setVideoHeight] = React.useState<number>();
  const [startTime, setStartTime] = React.useState<Date>(new Date());

  React.useEffect(() => {
    console.log(props.isGeoError);
    turnOnCamera();
    showPreview(false);
    return () => {};
  }, []);

  React.useEffect(() => {
    return () => {
      stream && stream.getVideoTracks()[0].stop();
    };
  }, [stream]);

  function showPreview(showPreview: boolean) {
    videoRefElement.current!.hidden = showPreview;
    if (isSafari) imageRefElement.current!.hidden = !showPreview;
    else previewRefElement.current!.hidden = !showPreview;
  }

  function turnOnCamera() {
    setIsLoading(true);

    const constraints = {
      audio: false,
      video: {
        facingMode: "user",
      },
    };

    function handleSuccess(stream: MediaStream) {
      videoRefElement.current!.srcObject = stream;
      try {
        setMediaRecorder(new MediaRecorder(stream));
      } catch (ex) {}
      setStream(stream);
      setShowVidPerm(false);
      setIsLoading(false);
    }

    function handleError(error: any) {
      setShowVidPerm(true);
      setIsLoading(false);
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(handleSuccess)
      .catch(handleError);
  }
  const startFinalRecording = () => {
    if (!!props?.checkGeoLocation) {
      props?.checkGeoLocation();
    }
    setTimeout(() => {
      record();
    }, 3000);
  };
  function record() {
    setShowStartBtn(false);
    setShowRecordTimer(true);
    gtmService({ action: "VIDEO_IPV", label: "START_RECORDING" });
    let interval: NodeJS.Timeout;
    const arr: Array<string> = [];
    if (isSafari) {
      interval = setInterval(
        () =>
          captureScreenShot(
            videoRefElement.current!,
            (
              base64: string,
              blob: Blob,
              videoWidth: number,
              videoHeight: number
            ) => {
              setImgSrcSafari(window.URL.createObjectURL(blob));
              setVideoWidth(videoWidth);
              setVideoHeight(videoHeight);
              arr.push(base64);
            }
          ),
        500
      );
    } else {
      mediaRecorder && mediaRecorder.start();
    }
    setTimeout(() => {
      setShowRecordTimer(false);
      setShowUploadBtn(true);
      isSafari && interval && clearInterval(interval);
      stop(arr);
    }, 5200);
  }

  if (mediaRecorder) {
    mediaRecorder.ondataavailable = function (event: any) {
      setFrames([event.data]);
    };

    mediaRecorder.onstop = (ev) => {
      const blob = new Blob(frames, { type: "video/webm" });
      previewRefElement.current!.src = window.URL.createObjectURL(blob);
      setWebBlob(blob);
      showPreview(true);
    };
  }

  function stop(framesArr?: Array<string>) {
    showPreview(true);
    if (isSafari && framesArr) {
      setFrames(framesArr);
    } else {
      mediaRecorder && mediaRecorder.stop();
    }
    stream && stream.getVideoTracks()[0].stop();
  }

  function retryVideo() {
    setFrames([]);
    showPreview(false);
    setShowUploadBtn(false);
    setShowRecordTimer(false);
    setShowStartBtn(true);
    setImgSrcSafari("");
    turnOnCamera();
  }

  function uploadVideo() {
    if(props?.isLocationPermission==false){

    }else{
      const objUTM_Info = utmDurationInfoObj(startTime, "bankdur");
      if (isSafari) {
        const uploadObj = {
          image64: frames,
          clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
          applicationid: GlobalStore.applicationID,
          leadid: GlobalStore.leadId,
          contentType: "video/webm",
          panno: GlobalStore.personaldetails.pan,
          width: videoWidth,
          height: videoHeight,
          utmObj: objUTM_Info,
        };
        props.ipvUploaded(uploadObj, isSafari);
      } else {
        const new_File = new File([webBlob as BlobPart], "videoIPV.webm", {
          type: "video/webm",
          lastModified: Date.now(),
        });
        if (new_File.size > 50000000) {
          alert("video size exceeds maximum limit. Please upload it again");
          return false;
        }
        if (new_File.size <= 8192) {
          alert(
            "video size is less then minimum required size. Please upload it again"
          );
          return false;
        }
        const fileReader = new FileReader();
        fileReader.onload = function (e) {
          const base64 = (e.target && e.target.result)?.toString();
          if (base64?.toString()) {
            const uploadObj = {
              applicationid: GlobalStore.applicationID,
              clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
              contentType: new_File.type,
              image: base64!.split(",")[1],
              imageName: `${GlobalStore.applicationID}_${new_File.name}`,
              leadid: GlobalStore.leadId,
              panno: GlobalStore.personaldetails.pan,
              utmObj: objUTM_Info,
            };
            props.ipvUploaded(uploadObj, isSafari);
          }
        };
        fileReader.readAsDataURL(new_File);
      }
    }
    
  }

  return (
    <div className="videoPlayerIpv">
      {showVidPerm && (
        <div className="videoPermission">
          <label className="videoPermissionMsg">
            Please allow access for camera and location from the advance setting
            to complete this step.
          </label>
        </div>
      )}
      <video
        className="videoPlayer"
        id="videoRef"
        ref={videoRefElement}
        autoPlay
        playsInline
      />
      {isSafari ? (
        <img
          src={imgSrcSafari}
          ref={imageRefElement}
          alt="ipvImage"
          className="videoPlayer"
        />
      ) : (
        <video
          className="videoPlayer"
          ref={previewRefElement}
          controls
          controlsList="nodownload nofullscreen noremoteplayback"
        />
      )}

      {/* Start Recording Button Start */}
      {showStartBtn && !props.isGeoError && props?.isLocationPermission && (
        <div
          className={"startBtn" + (isLoading || showVidPerm ? " op05" : "")}
          onClick={
            isLoading || showVidPerm ? () => {} : () => startFinalRecording()
          }
        >
          <span className="edel-icon-start-recording" />
          <label className="startLbl">Start Recording</label>
        </div>
      )}
      {/* Start Recording Button End */}

      {/* Recording Timer Start */}
      {showRecordTimer && (
        <div className="recordingTimer">
          <OtpTimer timer={5} />
        </div>
      )}
      {/* Recording Timer End */}

      {/* Upload Btn Start */}
      {showUploadBtn && (
        <div className="uploadDiv">
          <label className="uploadInfo">
            Make sure the quality of the video is clear and the OTP and your
            face is clearly visible.
          </label>
          <div className="uploadBtns">
            <Button
              btnName="Retry"
              disabled={isLoading}
              isSecondary={true}
              isLoading={isLoading}
              onClick={retryVideo}
            />
            <Button
              btnName="Upload"
              disabled={
                isLoading || checkESignSubmitted(GlobalStore.applicationID)
              }
              isLoading={isLoading}
              onClick={uploadVideo}
            />
          </div>
        </div>
      )}
      {/* Upload Btn End */}
    </div>
  );
};

export default VideoPlayerIpv;
