import { useEffect, useState } from "react";
import { geoLocationConfig } from "../components/utils/constants";

const useGeoLocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
    error: false,
  });

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
      error: false,
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      coordinates: { lat: "", lng: "" },
      error,
      error: true,
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError, {
      timeout: geoLocationConfig.timeout,
      enableHighAccuracy: geoLocationConfig.enableHighAccuracy,
      maximumAge: geoLocationConfig.maximumAge,
    });
  }, []);

  return location;
};

export default useGeoLocation;
