import * as React from "react";
import arrowPoint from "../../assets/icons/arrow_point.png";
import "./StepsInfo.scss";
export interface StepsInfoProps {}

const StepsInfo: React.SFC<StepsInfoProps> = () => {
  const [viewBtn, setViewBtn] = React.useState<boolean>(false);
  return (
    <>
      <div className="clientStepsInfo">
        <div className="clientStepheading">
          {/* Complete Quality Check process and upload your details at Exchanges. */}
          Here are the things that we will do at our end:
        </div>
        <div className="clientStep">
          <img src={arrowPoint} alt="=>" />
          {/* <label>Post approval from exchanges, you would receive User Id and Password.</label> */}
          <label>
            Your Account Activation request is received & post quality check, it
            will be sent to Exchanges for approvals.
          </label>
        </div>
        <div className="clientStep">
          <img src={arrowPoint} alt="=>" />
          <label>
            Post Approval from exchanges, you would receive password via email &
            SMS. Please note your trading Id is your Default Login ID.
          </label>
          {/* <label>
            You can login and trading immediately using the above details.
          </label> */}
        </div>
        <div className="clientStep">
          <img src={arrowPoint} alt="=>" />
          <label>
            Please download TX3 on Desktop and EMT App on Mobile, to start
            trading with us.
          </label>
          {/* <label>
            Download TX3 on Desktop and EMT App on mobile, to start trading with
            us.
          </label> */}
        </div>
      </div>
      <div className="ddpi-box">
        <div className="ddpi-heading">Lets Know more about DDPI:</div>
        <div className="ddpi-info">
          As per SEBI regulation POA is now replaced by Demat and Pledge
          instruction (DDPI). DDPI is an authorization to access your demat
          account to meet pay-in obligations for settlement of trades executed
          by you.
        </div>
        {viewBtn ? (
          <>
            <div className="ddpi-heading">
              Purpose of DDPI is limited only for two purposes:
            </div>

            <div className="ddpi-info">
              <ol>
                <li>
                  To transfer securities held in your account toward exchange
                  related deliveries or settlement obligations.
                </li>
                <li>To pledge / repledge securities</li>
              </ol>
            </div>

            <div className="ddpi-heading">DDPI doesn’t support:</div>
            <div className="ddpi-info">
              <div>Corporate Actions (Buy Back, TakeOver, Delisting, etc).</div>
              <div>Mutual Fund Transactions.</div>
              <div>
                To execute any transactions above either you submit the
                prefilled POA on the below address or the settlement would be
                done through e-DIS.
              </div>
            </div>
            <div className="ddpi-heading">
              Address: NUVAMA WEALTH & INVESTMENT LTD., 201 to 203, Zodiac
              Plaza, Xavier College Road, Off C G Road, Ahmedabad, Gujrat-380009
            </div>
          </>
        ) : (
          ""
        )}
        <div className="view-btn">
          {viewBtn ? (
            <span onClick={() => setViewBtn(false)}>View Less</span>
          ) : (
            <span onClick={() => setViewBtn(true)}>View More</span>
          )}
        </div>
      </div>

      <div className="platformRedirect">
        While the above steps are under process, why not get comfortable with
        our platforms.
      </div>
    </>
  );
};

export default StepsInfo;
