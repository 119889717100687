import React from 'react';
import ProfileImage from '../../UI/ProfileImage/ProfileImage';
import Button from '../../UI/Button/Button'
import './AccExists.scss'
import { useHistory } from 'react-router-dom';
import { loginUrl, containerUrl } from '../../utils/constants';
import useDiffPan from '../../../assets/icons/useDiffPan.svg'
import gtmService from '../../Services/gtmService';

export interface AccExistsProps {
}

const AccExists: React.FC<AccExistsProps> = () => {

    const history = useHistory();

    return (
        <div className='accExts'>

            <div className='accExtsLogo'>
                <ProfileImage>
                    <img src={useDiffPan} alt='useDiffPan' className='pfImg' />
                </ProfileImage>
            </div>

            <div className='accExtsHeading'>
                <label >Use a Different PAN</label>
            </div>

            <div className='accExtsInfo'>
                <label>An account already exists with this PAN.</label>
            </div>

            <div className='accExtsBtns'>
                <Button
                    btnName='Use Different PAN'
                    disabled={false}
                    isLoading={false}
                    onClick={() => {
                        gtmService({ action: 'USE_DIFF_PAN', label: 'DIFF_PAN' });
                        history.push(containerUrl.pan)
                    }} />
            </div>

            <div className='accExtsLogin'>
                <label>Can’t access your account?</label>
                <label className='loginHelp'
                    onClick={() => {
                        gtmService({ action: 'USE_DIFF_PAN', label: 'LOGIN_HELP' });
                        window.location.href = loginUrl
                    }} >Login Help</label>
            </div>
        </div >
    );
}

export default AccExists;