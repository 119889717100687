import { RequireOnlyOne } from "../types/genericTypes";

declare let clevertap: ClevertapType;

export interface ClevertapType {
	event: Array<ClevertapEvent | string>;
	profile: Array<object>;
	account: Array<ClevertapAccount>;
	onUserLogin: Array<ClevertapUserProfile>;
	notifications: Array<CleverTapNotification>;
	privacy: Array<RequireOnlyOne<ClevertapProfile, "optout" | "useIP">>;
	getCleverTapID: () => string;
}

export interface ClevertapEvent {
	[x: string]: any;
	EventName: string;
}

export interface ClevertapAccount {
	id: string;
}

export interface ClevertapUserProfile {
	Site: {
		Name?: string;
		Identity?: string | number;
		Email?: string;
		Phone?: string;
		Gender?: "M" | "F";
		DOB?: Date;
		AccountType?: string;
		profileID?: string;
		"MSG-email"?: boolean;
		"MSG-push"?: boolean;
		"MSG-sms"?: boolean;
		"MSG-whatsapp"?: boolean;
	};
}

export interface CleverTapNotification {
	titleText: string;
	bodyText: string;
	okButtonText: string;
	rejectButtonText: string;
	okButtonColor: string;
	askAgainTimeInSeconds: number;
	serviceWorkerPath: string;
}

export interface ClevertapProfile {
	optout: boolean;
	useIP: boolean;
}

export const ClevertapService = {
	pushEvent: function (data: ClevertapEvent) {
		clevertap.event.push(
			data.EventName,
			{
				...data,
				CurrentPage: window.location.href,
				Platform: window.innerWidth > 767 ? 'Website_Desktop' : 'Website_Mobile',
			}
		);
	},

	showClevertapNotification: function () {
		setTimeout(() => {
			clevertap.notifications.push({
				titleText: "Would you like to receive Push Notifications?",
				bodyText: "We promise to only send you relevant content and give you updates on your transactions",
				okButtonText: "Sign me up!",
				rejectButtonText: "No thanks",
				okButtonColor: "#f28046",
				askAgainTimeInSeconds: 604800,
				serviceWorkerPath: process.env.PUBLIC_URL + "/serviceworker.js"
			});
		}, 10000);
	},
	getCleverTapID: function () {
		return new Promise((resolve) => {
			setTimeout(() => {
				return resolve(clevertap.getCleverTapID());
			}, 1000);
		});
	}
}
