import React from 'react';
import regbanner from '../../assets/icons/regBanner.jpeg';
import DeviceContext from '../Services/identifyDevice';
import { clientPartialDetailsToStore } from '../Services/globalService';
import { useHistory } from 'react-router-dom';

import './SmsLinksLayout.scss';

export interface SmsLinksLayoutProps {}

const SmsLinksLayout: React.FC<SmsLinksLayoutProps> = (props) => {
    clientPartialDetailsToStore();
    const isDesktop = DeviceContext().isDesktop;
    const history = useHistory();

    return (
        <div className="linkLayout">
            <div className="banner">
                <img className="bannerImg" src={regbanner} alt="banner" />
            </div>
            <div className="linkBox">
                <div className={'linkLayoutForm'}>{props.children}</div>
            </div>
        </div>
    );
};

export default SmsLinksLayout;
