import React from 'react'
import './NavBarTile.scss'
import Loader from '../../../UI/Loader/Loader'

export interface NavBarTileProps {
    name: string;
    time: any;
    isActive: boolean;
    isComplete: boolean;
    navUrl: string;
    logo: string;
    logoNew?:string;
    isClickable?: boolean,
    isLoading?: boolean
    onNavTileClick: () => void
}

const NavBarTile: React.FC<NavBarTileProps> = (props) => {
    return (
        <div className={'navBarTile' + (props.isActive ? ' active' : '') + (props.isComplete ? ' op05' : '') + (!props.isClickable ? ' non-clickble' : '')} onClick={props.onNavTileClick}>
            <div className='tileContent'>
                <div className='prflNavTileLogo'>
                    {/* <span className='edel-icon-clock' /> */}
                    <img src={props.isActive && !props.isComplete?props.logoNew:props.logo} alt={''} />
                </div>
                <div className='navBarLabel'>
                    <div className='navBarHead'>
                        <label className='navBarHeading'>{props.name}</label>
                    </div>
                    <div className='navBarInfo'>
                        {
                            props.isLoading ?
                                <Loader size={18} />
                                :
                                props.isComplete ?
                                    <>
                                        <span className='edel-icon-tick' />
                                        <label className='infoComp'>Completed</label>
                                    </>
                                    :
                                    <label className={'navBarInfoLbl' + (props.isActive ? ' blue' : '')}>
                                        {props.isActive ? 'In Progress' : props.time}
                                    </label>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBarTile;