import config from "../../config/domain";
import GlobalStore from "../store/globalStore";
import requestMapper from "./mapper/serviceMapper";
import { statusUrl } from "../utils/constants";

interface callReq {
  apiBaseUrl?: string;
  url: string;
  method: string;
  body?: {};
  authType?: "bearer" | "authtoken";
  appidkey?: string;
  source?: string;
  sourcetoken?: string;
  headers?: object;
  tempToken?: string | boolean;
  serviceName?: any;
}

export const apiService = async (req: callReq, callback: CallableFunction) => {
  const params: any = {
    method: req.method,
    headers: {
      "Content-Type": "application/json",
      "api-key" : config.apiKey
    },
  };

  if (req.appidkey) {
    params.headers["appidkey"] = req.appidkey;
  }

  if (req.headers) params.headers = { ...params.headers, ...req.headers };

  // if (GlobalStore.token !== "")
  //   params.headers = { ...params.headers, mwAuth: GlobalStore.token };
  if (GlobalStore.token !== "" && req.tempToken !== false)
    params.headers = {
      ...params.headers,
      Authorization: "Bearer " + GlobalStore.token,
    };

  let clientPartialDetails = JSON.parse(
    window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  );
  if (
    !GlobalStore.token &&
    clientPartialDetails.token &&
    req.tempToken !== false
  ) {
    params.headers = {
      ...params.headers,
      Authorization: "Bearer " + clientPartialDetails.token,
    };
  }

  if (req.method === "POST" || req.method === "PUT" || req.method === "DELETE")
    params["body"] = JSON.stringify(req.body);

  try {
    let URL = req.apiBaseUrl ? req.apiBaseUrl : config.apiUrl;
    const resp = await fetch(URL + req.url, params);
    const newResp = await resp.json();
    // handle 401 response 
    if(newResp.RequestResult.Status.StatusCode == "-6"){
      alert(newResp.RequestResult.Errors[0].ErrorMessage);
      window.location.href= config.root + statusUrl.statusHome.replace("/", "");
    }
    if (resp.headers.get("CEPRK")) {
      const CEPRK = resp.headers.get("CEPRK");
      callback(newResp, CEPRK);
    } else {
      // callback(newResp);
      // check if revamped API call else return to normal flow
      if (req.serviceName && req.serviceName !== "") {
        let res = await requestMapper(newResp, req.serviceName);
        callback(res);
      } else {
        callback(newResp);
      }
      // add a mapper here so that no code needs to be changed
    }

    // newResp
    //     // .json()
    //     .then((res:any) => {
    //         // handleSessionTimeout(newResp, res);
    //         callback(res, newResp);
    //     })
    //     .catch((res: any) => {
    //         // handleSessionTimeout(newResp, res);
    //         callback(undefined, newResp);
    //     });
  } catch (err) {
    // all errors will be captured here for anything in the try block

    console.log("req.serviceName", req, req.serviceName);
    callback &&
      callback({
        error: "",
        errMsg: err,
      });
  }
};
