import React, { useState } from "react";
import "./BankForm.scss";
import InputField from "../../UI/InputField/InputField";
import Tag from "../../UI/Tag/Tag";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import Button, {
  DashedButton,
  TransparentButton,
} from "../../UI/Button/Button";
import SubmitButton from "../../UI/Button/Button";
import Toggle from "../../UI/Toggle/Toggle";
import GlobalStore from "../../store/globalStore";
import {
  getIFSCDetails,
  SaveBankDetails,
  getbankList,
  getbankCity,
  getbankBranch,
  AGetBankDetails,
  AgetBeneficiaryName,
  AgetOcrResponseDetails,
  AGetBeneficiaryNamePichainV1,
} from "../../Services/dashboard/bankApiServices";
import Backdrop from "../../UI/Backdrop/Backdrop";
import micr from "../../../assets/icons/micr.jpg";
import { AGetEnumList } from "../../Services/dashboard/profileApiService";
import {
  AGetRsaPublicKey,
  completeModuleStatus,
  convertEnumIntoIObj,
  incompleteStageNavigation,
  utmDurationInfoObj,
} from "../../Services/globalService";
import { IListObj } from "../../types/genericTypes";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
// import MandateDetails from "../MandateDetails/MandateDetails";
import { dashboardUrl } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import gtmService from "../../Services/gtmService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import { ClevertapService } from "../../Services/clevertapService";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import { errorMessage } from "../../utils/errorMessage";
import {
  aesDecryptMessage,
  aesEncryptMessage,
  exportAesCryptoKey,
  exportRsaCryptoKey,
  generateAesKey,
  generateRsaKey,
  importAesKey,
  importRsaKey,
  rsaDecryptMessage,
  rsaEncryptMessage,
} from "./encryption";
import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";
import InfoModal from "../../UI/InfoModal/InfoModal";
import DeviceContext from "../../Services/identifyDevice";
import PennyDropLoader from "../../UI/Loader/PennyDropLoader";
import { tr } from "date-fns/locale";
import { checkESignSubmitted } from "../../utils/eSignSubmitHandler";

export interface IBankDetails {
  branch: string;
  ifsccode: string;
  micr: string;
}
export interface MICRdata {
  bankpincode: string;
  bankaddr1: string;
  bankcity: string;
  bankcode: string;
  bankname: string;
  branch: string;
  contact: string;
  district: string;
  ifsccode: string;
  micr: string;
  state: string;
}

export interface BankFormFields {
  accountholdername: string;
  applicationid: string;
  accountno: string;
  bankaddr1: string;
  bankcity: string;
  bankdetailsid: number;
  bankpincode: string;
  reaccountno: string;
  ifsccode: string;
  bankacctype: string;
  isdefaultaccount: boolean;
  branch: string;
  bankname: string;
  state: string;
  micr: string;
  clientprimarydetailid: number;
  empcode: number;
  payinpriority: number;
  qcapproved: boolean;
  micrcodeList: Array<{ value: string; name: string }>;
}

const BankForm = (props: any) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const [maxBank, setMaxBank] = React.useState<number>(0);
  const [accountnoErrors, setaccountnoErrors] = React.useState<Array<string>>(
    []
  );
  const [bankNameOnIFSCCode, setBankNameOnIFSCCode] = useState<string>("");
  const [bankCityOnIFSCCode, setBankCityOnIFSCCode] = useState<string>("");
  const [pryAccount, setPryAccount] = useState<boolean>(false);
  const [micrModalFlag, setMicrModalFlag] = useState<boolean>(false);
  const [ifscModalFlag, setIfscModalFlag] = useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<number | string>();
  const [bankList, setBankList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [hideSearch, setHideSearch] = useState<boolean>(true);
  const [ismatched, setIsmatched] = useState<boolean>(false);
  const [branchDetail, setBranchDetail] = useState<IBankDetails>();
  const [bankListOnIFSC, setBankListOnIFSC] = useState([]);
  const [bankacctypeList, setbankacctypeList] = useState<Array<IListObj>>([]);
  const [lists, setLists] = useState<Array<BankFormFields>>([
    {
      accountholdername: GlobalStore.accountHolderName,
      applicationid: GlobalStore.applicationID,
      accountno: "",
      bankaddr1: "",
      reaccountno: "",
      bankdetailsid: 0,
      bankpincode: "",
      ifsccode: "",
      bankacctype: "10",
      branch: "",
      isdefaultaccount: true,
      bankcity: "",
      bankname: "",
      state: "",
      micr: "",
      clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
      payinpriority: 1,
      empcode: 0,
      qcapproved: false,
      micrcodeList: [{ value: "", name: "" }],
    },
  ]);
  const [showMandate, setShowMandate] = React.useState<boolean>(false);
  const [uploadedBankList, setUploadedBankList] = React.useState<Array<any>>(
    []
  );
  const [loaderSign, setLoaderSign] = React.useState<boolean>(false);
  const [allowOnlyAddBank, setAllowOnlyAddBank] =
    React.useState<boolean>(false);
  const [isSbi, setIsSbi] = useState(false);
  const [showPennyDropModal, setShowPennyDropModal] =
    React.useState<boolean>(false);
  let serverPublicKey: string;
  let rsaAoepKeyPair: CryptoKeyPair;
  let exportRsaPublicKey: string;

  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    AGetEnumList(getEnumList, "bankacctype");
    AGetBankDetails(getBankDetails, GlobalStore.applicationID);
    // AGetRsaPublicKey(encryptData);
  }, []);

  const encryptData = async (res: any, bankObj: any, isAddition?: boolean) => {
    if (!res || res.error) {
      setErrCode("Error in capturing bank details");
    } else {
      // Get Server RSA public key for encryting client side UUID, Symmetric key, AES from the response
      serverPublicKey = res.data.key;
      window.sessionStorage.setItem("serverPublicKey", res.data.key);
      const importedRsaCryptoKeyObj = await importRsaKey(res.data.key);
      const aesGcmKey = await generateAesKey();
      const exportAesSecretKey = await exportAesCryptoKey(aesGcmKey);

      const { aesCipheredText, ivBase64 } = await aesEncryptMessage(
        aesGcmKey,
        JSON.stringify(bankObj)
      );

      const headerPayload = {
        reqId: Date.now(),
        clSy: exportAesSecretKey,
        clIv: ivBase64,
      };

      const cipheredheaderPayload = await rsaEncryptMessage(
        importedRsaCryptoKeyObj,
        JSON.stringify(headerPayload)
      );

      if (window.sessionStorage.getItem("clientPublicKey")) {
        console.log("Client public key exists");
      }
      rsaAoepKeyPair = await generateRsaKey();
      exportRsaPublicKey = await exportRsaCryptoKey(rsaAoepKeyPair.publicKey);
      window.sessionStorage.setItem("clientPublicKey", exportRsaPublicKey);

      const header = {
        SEPRK: cipheredheaderPayload,
        CPK: exportRsaPublicKey,
      };

      const reqObj = {
        req: aesCipheredText,
      };

      // const aesDecrytedText = await aesDecryptMessage(aesGcmKey, ivBase64, aesCipheredText);

      AGetBeneficiaryNamePichainV1(
        (res: any, CEPRK: string) => newPichainResponse(res, CEPRK, isAddition),
        reqObj,
        header
      );
    }
  };

  const newPichainResponse = async (
    res: any,
    CEPRK: string,
    isAddition?: Boolean
  ) => {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      const rsaDecrytedText = await rsaDecryptMessage(
        rsaAoepKeyPair.privateKey,
        CEPRK
      );
      const rsaDecrytedobj = JSON.parse(rsaDecrytedText);
      const importedAesCryptoKeyObj = await importAesKey(rsaDecrytedobj.srSym);
      const aesDecrytedText = await aesDecryptMessage(
        importedAesCryptoKeyObj,
        rsaDecrytedobj.rsIv,
        res.res
      );
      if (isAddition) getAccountHolderForSaveOnAdd(aesDecrytedText);
      else getAccountHolderForSave(aesDecrytedText);
    }
  };

  const getAccountHolderName = (res: any) => {
    if (!res || res.error) {
      // setErrCode("170 - Failed to fetch Beneficiary name details");
      setErrCode(res.error);
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        GlobalStore.accountHolderName = responseObj.registered_name;
        // if (responseObj.message === "SUCCESS" && responseObj.score == 100) {
        //     GlobalStore.isSeeding = true;
        // }
        // else {
        //     GlobalStore.isSeeding = false;
        // }
        if (responseObj.score == 100) {
          setIsmatched(true);
        }
      }
    }
  };

  function getEnumList(res: any) {
    if (!res || res.error) {
      // setErrCode('164 - Failed to fetch the category lists, please reload.')
      setErrCode(res.error);
    } else {
      if (res.data && res.data.enumlist && res.data.enumlist.length > 0) {
        const [bankacctypeList] = convertEnumIntoIObj(res.data.enumlist, [
          "BankAccType",
        ]);
        const filterBankacctypeList = bankacctypeList.filter(
          (item: any) =>
            item.display !== "Others" && item.display !== "Cash Credit"
        );
        setbankacctypeList(filterBankacctypeList);
      } else {
        setErrCode(res.error);
      }
    }
  }

  function getBankDetails(res: any) {
    console.log("&& res.data !== Record Not Found", res);
    if (!res || res.error) {
      setErrCode(res.error);
    } else if (res.data) {
      let responseObj;
      if (res.data != "Record Not Found") {
        responseObj = JSON.parse(res.data);
      } else {
        responseObj = res.data;
      }

      if (responseObj.length > 0 && responseObj !== "Record Not Found") {
        GlobalStore.accountHolderName = responseObj[0].accountholdername;
        setUploadedBankList(responseObj);
        setShowMandate(true);
        updateLists(responseObj);
        setLists(responseObj);
      } else {
        if (
          GlobalStore.chequeOCRObj.ifsc_code &&
          GlobalStore.chequeOCRObj.account_no
        ) {
          const actObj = {
            applicationid: GlobalStore.applicationID,
            account_number: GlobalStore.chequeOCRObj.account_no,
            ifsc: GlobalStore.chequeOCRObj.ifsc_code,
            name: GlobalStore.personaldetails.PANName,
          };
          lists[0].accountno = GlobalStore.chequeOCRObj.account_no;
          lists[0].reaccountno = GlobalStore.chequeOCRObj.account_no;
          lists[0].ifsccode = GlobalStore.chequeOCRObj.ifsc_code;
          lists[0].micr = GlobalStore.chequeOCRObj.micr_code;

          setLists(lists);
          AgetBeneficiaryName(getAccountHolderName, actObj);
          // AGetRsaPublicKey((res: any) => encryptData(res, actObj));
          if (GlobalStore.chequeOCRObj.ifsc_code != "") {
            getIFSCDetails(
              (res: any) => getData(res, 0),
              GlobalStore.chequeOCRObj.ifsc_code
            );
          }
        }
        const sendOcrObj = {
          applicationId: GlobalStore.applicationID,
          documentCategory: "Bank Proof",
          documentId: 21,
        };
        AgetOcrResponseDetails(getOcrResponse, sendOcrObj);
      }
      setIsLoading(false);
    } else {
      setErrCode(res.error);
    }
  }

  function getBankDetailsMandate(res: any) {
    if (!res || res.error) {
      setErrCode(1362);
    } else {
      if (res.data) {
        const responseObj = JSON.parse(JSON.parse(res.data));
        if (responseObj.length > 0 && responseObj !== "Record Not Found") {
          setUploadedBankList(responseObj);
          //setShowMandate(true);
          completeModuleStatus(dashboardUrl.bank);
          gtmService({ action: "BANK_DETAILS", label: "Step_Complete" });
          history.push(incompleteStageNavigation());
        }
      }
    }
  }

  const getOcrResponse = (res: any) => {
    if (!res || res.error) {
      //setErrCode(1361);
      setErrCode(res.error);
    } else {
      if (res.data !== "Record Not Found") {
        const ocrResponseObj = JSON.parse(res.data);
        let values: Array<BankFormFields> = [...lists];
        values[0].accountno =
          ocrResponseObj.ocrdata.result.extraction_output.account_no;
        values[0].reaccountno =
          ocrResponseObj.ocrdata.result.extraction_output.account_no;
        values[0].ifsccode =
          ocrResponseObj.ocrdata.result.extraction_output.ifsc_code;
        setLists(values);
        getIFSCDetails(
          (res: any) => getData(res, 0),
          ocrResponseObj.ocrdata.result.extraction_output.ifsc_code
        );
      }
    }
  };

  function getData(res: any, index: number) {
    setErrCode("");
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode(`1363 - No bank found for the IFSC code entered`);
      setErrCode(res.error);
    } else {
      if (res.data && res.data.ecoIBeatsBank.length > 0) {
        let values: Array<BankFormFields> = [...lists];
        let result = res.data;
        values[index].bankcity = result.ecoIBeatsBank[0].bankcity;
        values[index].bankname = result.ecoIBeatsBank[0].bankname;
        values[index].state = result.ecoIBeatsBank[0].state;
        if (values[index].bankname === "STATE BANK OF INDIA") {
          if (values[index].reaccountno.length < 11) {
            alert("SBI account number should have more than 10 digits");
            let setAccError = { ...accountnoErrors };
            setAccError[
              index
            ] = `SBI account number should have more than 10 digits`;
            setaccountnoErrors(setAccError);
          }
          setIsSbi(true);
        } else {
          setIsSbi(false);
        }

        const micrData = result.ecoIBeatsBank.map(
          (item: any, index: number) => ({
            value: index + 1,
            name: item.micr,
          })
        );
        values[index].micrcodeList = micrData;
        //if only one MICR is in the list, it will selected by default instead of showing dropwdown list
        if (micrData.length === 1) {
          values[index].micr = micrData[0].name;
        } else {
          values[index].micr = "";
        }
        setBankListOnIFSC(result.ecoIBeatsBank);
        setLists(values);
        setIsLoading(false);
      } else {
        setErrCode("No bank found for the IFSC code entered");
        setIsLoading(false);
      }
    }
  }

  const updateLists = (listObj: any) => {
    let values: Array<BankFormFields> = [...lists];
    if (listObj.length > 0) {
      values = listObj.map((item: any) => {
        let item2 = listObj.find(
          (i2: any) => i2.bankdetailsid === item.bankdetailsid
        );
        item2.micrcodeList = [{ value: "", name: "" }];
        item2.reaccountno = item.accountno;
        return item2 ? { ...item, ...item2 } : item;
      });
    }
    setLists(values);
  };

  const handleChange = (
    name: keyof BankFormFields,
    index: number,
    val: string
  ): void => {
    let values: Array<BankFormFields> = [...lists];
    if (name === "isdefaultaccount") {
      if (typeof val === "boolean") values[index][name] = val;
    }
    if (name === "qcapproved") {
      if (typeof val === "boolean") values[index][name] = val;
    } else if (name === "ifsccode") {
      if (val.length === 11) {
        setIsLoading(true);
        values[index][name] = val;
        //if only one MICR is in the list, it will selected by default instead of showing dropwdown list
        if (values[index].micrcodeList.length === 1) {
          values[index].micr = values[index].micrcodeList[0].name;
        } else {
          values[index].micr = "";
        }
        getIFSCDetails((res: any) => getData(res, index), val);
      }
    } else if (name === "accountno") {
      if (val !== "") {
        values[index][name] = val;
      } else {
      }
    } else if (name === "reaccountno") {
      if (val !== "") {
        values[index][name] = val;
      }
    }
    setLists(values);
  };

  const handleTag = (name: keyof BankFormFields, index: number, val: any) => {
    let values: Array<BankFormFields> = [...lists];
    if (name === "bankacctype") {
      values[index][name] = val;
    } else if (name === "qcapproved") {
      if (typeof val === "boolean") values[index][name] = val;
    } else if (name === "isdefaultaccount") {
      if (typeof val === "boolean") values[index][name] = val;
    }
    setLists(values);
  };

  const handleDefaultAccount = (
    name: keyof BankFormFields,
    index: number,
    val: string | boolean
  ) => {
    let values: Array<BankFormFields> = [...lists];
    if (name === "isdefaultaccount") {
      if (typeof val === "boolean") values[index][name] = val;
    } else if (name === "qcapproved") {
      if (typeof val === "boolean") values[index][name] = val;
    }
    setLists(values);
  };

  const checkMatch = (index: number, val: any) => {
    let values: Array<BankFormFields> = [...lists];
    if (isSbi && values[index].reaccountno.length < 11) {
      let setAccError = { ...accountnoErrors };
      setAccError[index] = errorMsg(errorCode.FE32);
      setaccountnoErrors(setAccError);
    } else if (!isSbi && values[index].reaccountno.length < 6) {
      let setAccError = { ...accountnoErrors };
      setAccError[index] = errorMsg(errorCode.FE30);
      setaccountnoErrors(setAccError);
    } else if (values[index].accountno !== values[index].reaccountno) {
      let setAccError = { ...accountnoErrors };
      // setAccError[index] = 'Account numbers entered do not match';
      setAccError[index] = errorMsg(errorCode.FE31);
      setaccountnoErrors(setAccError);
    } else {
      let setAccError = { ...accountnoErrors };
      delete setAccError[index];
      setaccountnoErrors(setAccError);
    }
  };

  const handleDropdown = (val: string, index: number) => {
    let values: Array<BankFormFields> = [...lists];
    values[index].micr = val;
    if (bankListOnIFSC && bankListOnIFSC.length > 0) {
      let filterDataOnMICR: Array<MICRdata> = bankListOnIFSC.filter(
        (item: any) => item.micr === val
      );
      values[index].bankpincode = filterDataOnMICR[0].bankpincode;
      values[index].bankaddr1 = filterDataOnMICR[0].bankaddr1;
      values[index].bankcity = filterDataOnMICR[0].bankcity;
      values[index].bankname = filterDataOnMICR[0].bankname;
      values[index].branch = filterDataOnMICR[0].branch;
      values[index].state = filterDataOnMICR[0].state;
      setLists(values);
    } else {
      setLists(values);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);
    setLoaderSign(true);
    setShowPennyDropModal(true);

    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    clientPartialDetails_ao.account_no = lists[0].accountno;
    clientPartialDetails_ao.ifsc_code = lists[0].ifsccode;
    window.localStorage.setItem(
      "clientPartialDetails_ao",
      JSON.stringify(clientPartialDetails_ao)
    );

    GlobalStore.account_no = clientPartialDetails_ao.account_no;
    GlobalStore.ifsc_code = clientPartialDetails_ao.ifsc_code;

    if (
      GlobalStore.accountHolderName === "" ||
      lists[lists.length - 1].bankdetailsid === 0
    ) {
      const actObj = {
        applicationid: GlobalStore.applicationID,
        account_number: lists[lists.length - 1].accountno,
        ifsc: lists[lists.length - 1].ifsccode,
        name: GlobalStore.personaldetails.PANName,
      };
      AgetBeneficiaryName(getAccountHolderForSave, actObj);
      // AGetRsaPublicKey((res: any) => encryptData(res, actObj));
    } else {
      // setIsLoading(false);
      // setLoaderSign(false);
      // To avaid calling APIs unless a new bank's details are added
      const res = {
        data: true,
      };
      saveDetailsRes(res);
    }
  };

  // const getAccountHolderForSave = (res: any) => {
  //   if (!res || res.error) {
  //     setErrCode("170 - Failed to fetch Beneficiary name details");
  //     setIsLoading(false);
  //     setLoaderSign(false);
  //   } else {
  //     const parsedRes = JSON.parse(res);
  //     if (parsedRes.data) {
  //       const responseObj = JSON.parse(JSON.parse(parsedRes.data));
  //       // if (lists.length === 1) {
  //       //     if (responseObj.message === "SUCCESS" && responseObj.score == 100) {
  //       //         GlobalStore.isSeeding = true;
  //       //     }
  //       //     else {
  //       //         GlobalStore.isSeeding = false;
  //       //     }
  //       // }
  //       if (responseObj.score == 100) {
  //         setIsmatched(true);
  //       }

  //       if (responseObj.code === 200) {
  //         GlobalStore.accountHolderName = responseObj.registered_name;
  //         const objUTM_Info = utmDurationInfoObj(startTime, "bankdur");
  //         const saveBankAccount = {
  //           accountHolderName: responseObj.registered_name,
  //           accountNo: lists[lists.length - 1].accountno,
  //           applicationId: lists[lists.length - 1].applicationid,
  //           bankAccType: parseInt(lists[lists.length - 1].bankacctype),
  //           bankAddr1: lists[lists.length - 1].bankaddr1,
  //           bankCity: lists[lists.length - 1].bankcity,
  //           bankDetailsId: lists[lists.length - 1].bankdetailsid,
  //           bankName: lists[lists.length - 1].bankname,
  //           bankPinCode: lists[lists.length - 1].bankpincode,
  //           branch: lists[lists.length - 1].branch,
  //           clientPrimaryDetailId:
  //             lists[lists.length - 1].clientprimarydetailid,
  //           contactNo: "",
  //           district: "",
  //           empCode: lists[lists.length - 1].empcode,
  //           ifscCode: lists[lists.length - 1].ifsccode,
  //           isDefaultAccount: lists[lists.length - 1].isdefaultaccount,
  //           leadId: "",
  //           micr: lists[lists.length - 1].micr,
  //           qcapproved: false,
  //           state: lists[lists.length - 1].state,
  //           ismatched: responseObj.score >= 85 ? true : false,
  //           objUTM_info: objUTM_Info,
  //           match_score: responseObj.score,
  //         };
  //         SaveBankDetails(saveDetailsRes, saveBankAccount);
  //       } else {
  //         const res = {
  //           data: true,
  //         };
  //         saveDetailsRes(res);
  //       }
  //     }
  //   }
  // };

  //Sending data as Per JAVA MW parameter as MOUI parameter types are different from JAVA MW API parameter: Getting 500 response
  const getAccountHolderForSave = (res: any) => {
    if (!res || res.error) {
      // setErrCode("170 - Failed to fetch Beneficiary name details");
      setErrCode(res.error);
      setIsLoading(false);
      setLoaderSign(false);
    } else {
      const parsedRes = JSON.parse(res.data);
      if (parsedRes) {
        const responseObj = parsedRes;
        // if (lists.length === 1) {
        //     if (responseObj.message === "SUCCESS" && responseObj.score == 100) {
        //         GlobalStore.isSeeding = true;
        //     }
        //     else {
        //         GlobalStore.isSeeding = false;
        //     }
        // }
        let score = 0;
        if (responseObj.score) score = responseObj.score;
        if (responseObj.score && responseObj.score == 100) {
          setIsmatched(true);
        }
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        if (responseObj.registered_name)
          GlobalStore.accountHolderName = responseObj.registered_name;
        if (
          GlobalStore.accountHolderName === "" ||
          GlobalStore.accountHolderName === null
        )
          GlobalStore.accountHolderName = clientPartialDetails_ao.PANName;
        const objUTM_Info = utmDurationInfoObj(startTime, "bankdur");
        const saveBankAccount = {
          accountHolderName: GlobalStore.accountHolderName,
          accountNo: lists[lists.length - 1].accountno,
          applicationId: lists[lists.length - 1].applicationid,
          bankAccType: parseInt(lists[lists.length - 1].bankacctype),
          bankAddr1: lists[lists.length - 1].bankaddr1,
          bankCity: lists[lists.length - 1].bankcity,
          bankDetailsId: lists[lists.length - 1].bankdetailsid,
          bankName: lists[lists.length - 1].bankname,
          bankPinCode: lists[lists.length - 1].bankpincode,
          branch: lists[lists.length - 1].branch,
          clientPrimaryDetailId: lists[lists.length - 1].clientprimarydetailid,
          contactNo: "",
          district: "",
          empCode: lists[lists.length - 1].empcode,
          ifscCode: lists[lists.length - 1].ifsccode,
          isDefaultAccount: lists[lists.length - 1].isdefaultaccount,
          leadId: GlobalStore.leadId,
          micr: lists[lists.length - 1].micr,
          qcApproved: false,
          state: lists[lists.length - 1].state,
          isMatched: score >= 85 ? true : false,
          utmInfoDetails: objUTM_Info,
          matchScore: score,
        };
        SaveBankDetails(saveDetailsRes, saveBankAccount);
      }
    }
    setShowPennyDropModal(false);
  };

  const handleAddForm = () => {
    let values: Array<BankFormFields> = [...lists];
    setIsLoading(true);
    if (maxBank < 6) {
      setMaxBank(maxBank + 1);
    }
    if (lists.length === 1 && lists[lists.length - 1].bankdetailsid === 0) {
      const actObj = {
        applicationid: GlobalStore.applicationID,
        account_number: lists[0].accountno,
        ifsc: lists[0].ifsccode,
        name: GlobalStore.personaldetails.PANName,
      };
      AgetBeneficiaryName(getAccountHolderForSaveOnAdd, actObj);
      // AGetRsaPublicKey((res: any) => encryptData(res, actObj, true));
    }
    if (lists[lists.length - 1].bankdetailsid === 0 && allowOnlyAddBank) {
      const actObj = {
        applicationid: GlobalStore.applicationID,
        account_number: lists[lists.length - 1].accountno,
        ifsc: lists[lists.length - 1].ifsccode,
        name: GlobalStore.personaldetails.PANName,
      };
      AgetBeneficiaryName(getAccountHolderForSaveOnAdd, actObj);
      // AGetRsaPublicKey((res: any) => encryptData(res, actObj, true));
    } else {
      setAllowOnlyAddBank(true);
      let values: Array<BankFormFields> = [...lists];
      setLists([
        ...values,
        {
          accountholdername: GlobalStore.accountHolderName,
          accountno: "",
          applicationid: GlobalStore.applicationID,
          bankaddr1: "",
          bankdetailsid: 0,
          bankpincode: "",
          branch: "",
          reaccountno: "",
          clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
          ifsccode: "",
          bankacctype: "10",
          isdefaultaccount: pryAccount,
          bankcity: "",
          bankname: "",
          state: "",
          micr: "",
          empcode: 0 || lists[0].empcode,
          payinpriority: 1,
          qcapproved: false,
          micrcodeList: [{ value: "", name: "" }],
        },
      ]);
      setIsLoading(false);
      setIfscModalFlag(false);
      if (hideSearch === false) {
        setHideSearch(true);
      }
    }
  };

  // const getAccountHolderForSaveOnAdd = (res: any) => {
  //   if (!res || res.error) {
  //     setIsLoading(false);
  //     setErrCode("170 - Failed to fetch the IFSC Details");
  //   } else {
  //     const parsedRes = JSON.parse(res);
  //     if (parsedRes.data) {
  //       const responseObj = JSON.parse(JSON.parse(parsedRes.data));
  //       // if (responseObj.message === "SUCCESS" && responseObj.score == 100) {
  //       //     GlobalStore.isSeeding = true;
  //       // }
  //       // else {
  //       //     GlobalStore.isSeeding = false;
  //       // }

  //       if (responseObj.score == 100) {
  //         setIsmatched(true);
  //       }

  //       const objUTM_Info = utmDurationInfoObj(startTime, "bankdur");
  //       const saveBankAccount = {
  //         accountHolderName: responseObj.registered_name,
  //         accountNo: lists[lists.length - 1].accountno,
  //         applicationId: lists[lists.length - 1].applicationid,
  //         bankAccType: parseInt(lists[lists.length - 1].bankacctype),
  //         bankAddr1: lists[lists.length - 1].bankaddr1,
  //         bankCity: lists[lists.length - 1].bankcity,
  //         bankDetailsId: lists[lists.length - 1].bankdetailsid,
  //         bankName: lists[lists.length - 1].bankname,
  //         bankPinCode: lists[lists.length - 1].bankpincode,
  //         branch: lists[lists.length - 1].branch,
  //         clientPrimaryDetailId: lists[lists.length - 1].clientprimarydetailid,
  //         contactNo: "",
  //         district: "",
  //         empCode: lists[lists.length - 1].empcode,
  //         ifscCode: lists[lists.length - 1].ifsccode,
  //         isDefaultAccount: lists[lists.length - 1].isdefaultaccount,
  //         leadId: "",
  //         micr: lists[lists.length - 1].micr,
  //         qcapproved: lists[lists.length - 1].qcapproved,
  //         state: lists[lists.length - 1].state,
  //         ismatched: responseObj.score >= 85 ? true : false,
  //         objUTM_info: objUTM_Info,
  //         match_score: responseObj.score,
  //       };
  //       if (lists[lists.length - 1].bankdetailsid === 0) {
  //         SaveBankDetails(saveDetailsResponse, saveBankAccount);
  //       } else {
  //         let values: Array<BankFormFields> = [...lists];
  //         setLists([
  //           ...values,
  //           {
  //             accountholdername: GlobalStore.accountHolderName,
  //             accountno: "",
  //             applicationid: GlobalStore.applicationID,
  //             bankaddr1: "",
  //             bankdetailsid: 0,
  //             bankpincode: "",
  //             branch: "",
  //             reaccountno: "",
  //             clientprimarydetailid: parseInt(
  //               GlobalStore.clientPrimaryDetailId
  //             ),
  //             ifsccode: "",
  //             bankacctype: "10",
  //             isdefaultaccount: pryAccount,
  //             bankcity: "",
  //             bankname: "",
  //             state: "",
  //             micr: "",
  //             empcode: 0 || lists[0].empcode,
  //             payinpriority: 1,
  //             qcapproved: false,
  //             micrcodeList: [{ value: "", name: "" }],
  //           },
  //         ]);
  //         setIsLoading(false);
  //       }
  //       setIfscModalFlag(false);
  //       if (hideSearch === false) {
  //         setHideSearch(true);
  //       }
  //     }
  //   }
  // };
  const getAccountHolderForSaveOnAdd = (res: any) => {
    if (!res || res.error) {
      setIsLoading(false);
      setErrCode(res.error);
    } else {
      const parsedRes = JSON.parse(res.data);
      if (parsedRes) {
        const responseObj = parsedRes;
        // if (responseObj.message === "SUCCESS" && responseObj.score == 100) {
        //     GlobalStore.isSeeding = true;
        // }
        // else {
        //     GlobalStore.isSeeding = false;
        // }
        let score = 0;
        if (responseObj.score) score = responseObj.score;
        if (responseObj.score && responseObj.score == 100) {
          setIsmatched(true);
        }

        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        if (
          GlobalStore.accountHolderName === "" ||
          GlobalStore.accountHolderName === null
        )
          GlobalStore.accountHolderName = clientPartialDetails_ao.PANName;

        const objUTM_Info = utmDurationInfoObj(startTime, "bankdur");
        const saveBankAccount = {
          accountHolderName: responseObj.registered_name
            ? responseObj.registered_name
            : GlobalStore.accountHolderName,
          accountNo: lists[lists.length - 1].accountno,
          applicationId: lists[lists.length - 1].applicationid,
          bankAccType: parseInt(lists[lists.length - 1].bankacctype),
          bankAddr1: lists[lists.length - 1].bankaddr1,
          bankCity: lists[lists.length - 1].bankcity,
          bankDetailsId: lists[lists.length - 1].bankdetailsid,
          bankName: lists[lists.length - 1].bankname,
          bankPinCode: lists[lists.length - 1].bankpincode,
          branch: lists[lists.length - 1].branch,
          clientPrimaryDetailId: lists[lists.length - 1].clientprimarydetailid,
          contactNo: "",
          district: "",
          empCode: lists[lists.length - 1].empcode,
          ifscCode: lists[lists.length - 1].ifsccode,
          isDefaultAccount: lists[lists.length - 1].isdefaultaccount,
          leadId: GlobalStore.leadId,
          micr: lists[lists.length - 1].micr,
          qcApproved: lists[lists.length - 1].qcapproved,
          state: lists[lists.length - 1].state,
          isMatched: score >= 85 ? true : false,
          utmInfoDetails: objUTM_Info,
          matchScore: score,
        };
        if (lists[lists.length - 1].bankdetailsid === 0) {
          console.log("saveBankAccountAdd", saveBankAccount);
          SaveBankDetails(saveDetailsResponse, saveBankAccount);
        } else {
          let values: Array<BankFormFields> = [...lists];
          setLists([
            ...values,
            {
              accountholdername: GlobalStore.accountHolderName,
              accountno: "",
              applicationid: GlobalStore.applicationID,
              bankaddr1: "",
              bankdetailsid: 0,
              bankpincode: "",
              branch: "",
              reaccountno: "",
              clientprimarydetailid: parseInt(
                GlobalStore.clientPrimaryDetailId
              ),
              ifsccode: "",
              bankacctype: "10",
              isdefaultaccount: pryAccount,
              bankcity: "",
              bankname: "",
              state: "",
              micr: "",
              empcode: 0 || lists[0].empcode,
              payinpriority: 1,
              qcapproved: false,
              micrcodeList: [{ value: "", name: "" }],
            },
          ]);
          setIsLoading(false);
        }
        setIfscModalFlag(false);
        if (hideSearch === false) {
          setHideSearch(true);
        }
      }
    }
  };
  function saveDetailsResponse(res: any) {
    if (!res || res.error) {
      // setErrCode('135 - Failed to save the Bank Details provided.');
      // setErrCode(errorMessage.saveBankDetails);
      setErrCode(res.error);
      setIsLoading(false);
      setLoaderSign(false);
    } else {
      if (res.data) {
        setIsLoading(false);
        setLoaderSign(false);
        //AGetBankDetails(getBankDetails, GlobalStore.applicationID);
        // Code of Mandate update start
        let values: Array<BankFormFields> = [...lists];
        setLists([
          ...values,
          {
            accountholdername: GlobalStore.accountHolderName,
            accountno: "",
            applicationid: GlobalStore.applicationID,
            bankaddr1: "",
            bankdetailsid: 0,
            bankpincode: "",
            branch: "",
            reaccountno: "",
            clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
            ifsccode: "",
            bankacctype: "10",
            isdefaultaccount: pryAccount,
            bankcity: "",
            bankname: "",
            state: "",
            micr: "",
            empcode: 0 || lists[0].empcode,
            payinpriority: 1,
            qcapproved: false,
            micrcodeList: [{ value: "", name: "" }],
          },
        ]);
        // AGetBankDetails(getBankDetailsMandate, GlobalStore.applicationID);
        // Code of Mandate update end
      } else {
        // setErrCode('135 - Failed to save the Bank Details provided.');
        setErrCode(res.error);
        setIsLoading(false);
      }
    }
  }

  function saveDetailsRes(res: any) {
    if (!res || res.error) {
      // setErrCode('135 - Failed to save the Bank Details provided.');
      // setErrCode(errorMessage.saveBankDetails);
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data) {
        setIsLoading(false);
        setLoaderSign(false);
        setAllowOnlyAddBank(false);
        // Code of Mandate update start
        // AGetBankDetails(getBankDetailsMandate, GlobalStore.applicationID);
        // Code of Mandate update end
        completeModuleStatus(dashboardUrl.bank);
        gtmService({ action: "BANK_DETAILS", label: "Step_Complete" });

        // Clevertap event for save bank details
        const data = {
          EventName:
            cleverTapEvents.registration.bankPage.CLICK_ON_SUBMIT_BANK_DETAILS,
          InitiationPlatform: "",
          UTM_SOURCE: props.UTM_SOURCE,
          UTM_MEDIUM: props.UTM_MEDIUM,
          UTM_CAMPAIGN: props.UTM_CAMPAIGN,
          KRA: GlobalStore.isKRA,
          ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
        };
        ClevertapService.pushEvent(data);
        // Clevertap ends

        // Apps flyer event for click on proceed on bank details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.bankPage.CLICK_ON_SUBMIT_BANK_DETAILS
        );

        history.push(incompleteStageNavigation());
      } else {
        // setErrCode('135 - Failed to save the Bank Details provided.');
        setErrCode(res.error);
        setIsLoading(false);
      }
    }
  }

  const closeSample = () => {
    setMicrModalFlag(false);
  };

  const closeIFSC = () => {
    setIfscModalFlag(false);
  };

  const openIfscModal = (index: number) => {
    let values = { ...lists };
    if (values[index].bankdetailsid === 0 && index === lists.length - 1) {
      setIsLoading(true);
      setIfscModalFlag(true);
      getbankList((res: any) => getBankData(res));
    }
  };

  const getBankData = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res.data) {
        setIsLoading(false);
        setBankList(res.data.banknames);
      } else {
        setErrCode(res.error);
      }
    }
  };

  const getCityData = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res.data) {
        setCityList(res.data.bankcities);
        setIsLoading(false);
      } else {
        setErrCode(res.error);
      }
    }
  };

  const handlebanknameDropdown = (val: string, index: number) => {
    setIsLoading(true);
    setBankNameOnIFSCCode(val);
    getbankCity((res: any) => getCityData(res), val);
  };

  const handleCityDropdown = (val: string, index: number) => {
    setIsLoading(true);
    setBankCityOnIFSCCode(val);
    getbankBranch((res: any) => getBranchData(res), val, bankNameOnIFSCCode);
  };

  const getBranchData = (res: any) => {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res && res.data) {
        const sortBranch = res.data.bankbranches.sort(dynamicSort("branch"));
        setBranchList(sortBranch);
      }
    }
    setIsLoading(false);
  };

  function dynamicSort(property: string) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a: any, b: any) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  const handleBranchDropdown = (val: any, index: number) => {
    setBranchDetail(val);
  };

  const searchIFSC = (index: number) => {
    let values: Array<BankFormFields> = [...lists];
    if (
      bankNameOnIFSCCode &&
      bankCityOnIFSCCode &&
      branchDetail &&
      branchDetail.branch !== ""
    ) {
      setHideSearch(false);
    }
  };

  const closeSearchModal = (index: number) => {
    setBankCityOnIFSCCode("");
    setBankNameOnIFSCCode("");
    setHideSearch(!hideSearch);
  };

  const handleSelect = (index: number, selectedBranch: any) => {
    let values: Array<BankFormFields> = [...lists];
    if (selectedBranch) {
      values[index].branch = selectedBranch.branch;
      values[index].bankaddr1 = selectedBranch.branch;
      values[index].ifsccode = selectedBranch.ifsccode;
      values[index].micr = selectedBranch.micr;
      values[index].bankname = bankNameOnIFSCCode;
      values[index].bankcity = bankCityOnIFSCCode;
      const reMICR = [
        {
          value: (index + 1).toString(),
          name: selectedBranch.micr,
        },
      ];
      values[index].micrcodeList = reMICR;
      setLists(values);
      setIfscModalFlag(false);
    }
  };

  const checkDisabled = () => {
    if (
      Object.values(accountnoErrors).includes(
        "SBI account number has more than 10 digits"
      )
    ) {
      return true;
    } else if (
      lists[lists.length - 1].bankdetailsid !== 0 ||
      (lists[lists.length - 1].accountno !== "" &&
        lists[lists.length - 1].reaccountno !== "" &&
        lists[lists.length - 1].accountno.length !== 1 &&
        lists[lists.length - 1].reaccountno.length !== 1 &&
        // accountnoErrors[accountnoErrors.length - 1] !== 'Account numbers entered do not match' &&
        accountnoErrors[accountnoErrors.length - 1] !==
          "Bank inputs do not match" &&
        accountnoErrors[accountnoErrors.length - 1] !==
          "SBI account number has more than 10 digits" &&
        lists[lists.length - 1].ifsccode !== "" &&
        lists[lists.length - 1].micr !== "")
    ) {
      return false;
    } else {
      return true;
    }
  };
  const checkAddAnotherBankDisabled = () => {
    if (lists.length >= 5) {
      return true;
    } else if (
      lists[lists.length - 1].bankdetailsid !== 0 ||
      (lists[lists.length - 1].accountno !== "" &&
        lists[lists.length - 1].reaccountno !== "" &&
        lists[lists.length - 1].accountno.length !== 1 &&
        lists[lists.length - 1].reaccountno.length !== 1 &&
        lists[lists.length - 1].ifsccode !== "" &&
        lists[lists.length - 1].micr !== "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const removeBank = (i: number) => {
    let removeLast: Array<BankFormFields> = [...lists];
    removeLast = lists.slice(0, lists.length - 1);
    setLists(removeLast);
  };

  return (
    <div className={"formLayout mrl90" + (isLoading ? " op05" : "")}>
      <form>
        <Backdrop
          isShow={micrModalFlag}
          isCloseIcon={true}
          onClose={() => closeSample()}
        >
          <div className="sampleMICR">
            <img className="alignMICRBottom" src={micr} alt="micr" />
          </div>
        </Backdrop>
        {/* <form> */}

        {lists.map((list, index: number) => {
          return (
            <div key={index}>
              <fieldset disabled={isLoading}>
                <Backdrop
                  isShow={ifscModalFlag}
                  isCloseIcon={false}
                  onClose={() => closeIFSC()}
                >
                  {hideSearch ? (
                    <div className="searchForm">
                      <div className="modalHeader">
                        <label>Search IFSC Code</label>
                      </div>
                      <div className="modalLabel mrt40">
                        <label>Bank Name</label>
                        <DropdownSearch
                          name="bankname"
                          searchLength={0}
                          options={bankList}
                          searchKey="bankname"
                          placeholder="Select your Bank"
                          noDataMsg="Bank not found"
                          isDownArrow={true}
                          onOptionSelect={(val) =>
                            handlebanknameDropdown(
                              val["bankname"] as string,
                              index
                            )
                          }
                        />
                      </div>
                      <div className="modalLabel mrt40">
                        <label>City</label>
                        <DropdownSearch
                          name="bankcity"
                          searchLength={0}
                          options={cityList}
                          searchKey="bankcity"
                          placeholder="Select City"
                          noDataMsg="City not found"
                          isDownArrow={true}
                          onOptionSelect={(val) =>
                            handleCityDropdown(val["bankcity"] as string, index)
                          }
                        />
                      </div>
                      <div className="modalLabel mrt40">
                        <label>Branch</label>
                        <DropdownSearch
                          name="branch"
                          searchLength={0}
                          options={BranchList}
                          searchKey="branch"
                          placeholder="Select Branch"
                          noDataMsg="Branch not found"
                          isDownArrow={true}
                          onOptionSelect={(val) =>
                            handleBranchDropdown(val, index)
                          }
                        />
                      </div>
                      <div className="mrt40 mobWidth">
                        <Button
                          onClick={() => searchIFSC(index)}
                          btnName="Search"
                        />
                      </div>
                      <div className="mrt20 txtCenter mobWidth">
                        <TransparentButton
                          onClick={() => closeIFSC()}
                          btnName="Cancel"
                        />
                      </div>
                      {errCode && (
                        <div className="errMsgBlck errWidth mrt40">
                          <ErrMsg>
                            {typeof errCode === "number" ? (
                              <>
                                <label>
                                  We are facing some technical issue.
                                </label>
                                <label>
                                  Please try after sometime (error code:
                                  {errCode})
                                </label>
                              </>
                            ) : (
                              <label>{errCode}</label>
                            )}
                          </ErrMsg>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="selectSample">
                      <div className="modalHeader">
                        <label>Search IFSC Code</label>
                      </div>
                      <div className="rectangleCard">
                        <div className="article mrl20">
                          <div>
                            <label>
                              IFSC:{" "}
                              <span className="ifscCodeBold">
                                {branchDetail && branchDetail.ifsccode}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label>{bankNameOnIFSCCode}</label>
                          </div>
                          <div>
                            <label className="breakWord">
                              {branchDetail && branchDetail.branch}
                            </label>
                          </div>
                        </div>
                        <div className="article btnAligh">
                          <div className="selectBtn">
                            <Button
                              btnName="SELECT"
                              onClick={() => handleSelect(index, branchDetail)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="txtCenter mobWidth">
                        <TransparentButton
                          btnName="Search Another Branch"
                          onClick={() => closeSearchModal(index)}
                        />
                        {/* <button >Search Another Branch</button> */}
                      </div>
                    </div>
                  )}
                </Backdrop>
                <div className="bankLabel inputWidth">
                  <label className="bankLabelTxt">
                    Bank Account {index > 0 ? index + 1 : ""}
                    {index > 0 && checkAddAnotherBankDisabled() ? (
                      <span
                        className="removex"
                        onClick={() => {
                          removeBank(index);
                        }}
                      >
                        Remove
                        <span className="cancelIcon edel-icon-close"></span>
                      </span>
                    ) : null}
                  </label>
                </div>
                <div className="actType">
                  <label className="actTypeTxt">Account Type</label>
                </div>
                <div className="mrt10">
                  <Tag
                    defaultValue={list.bankacctype}
                    tagList={bankacctypeList}
                    onSelect={(val) => handleTag("bankacctype", index, val)}
                  />
                </div>
                <div className="bankLabel inputWidth">
                  <label className="addActLabel">Add Bank Account Number</label>
                  <InputField
                    onChange={(val) => handleChange("accountno", index, val)}
                    disabled={list.bankdetailsid !== 0}
                    placeholder="Eg. 18273946573849"
                    name="accountno"
                    maxLength={18}
                    min={isSbi ? 10 : 6}
                    integer={true}
                    type="password"
                    defaultValue={list.accountno}
                  />
                </div>

                <div className="bankLabel inputWidth">
                  <label className="addActLabel">
                    Re-enter Bank Account Number
                  </label>
                  <InputField
                    onChange={(val) => handleChange("reaccountno", index, val)}
                    onBlur={(val) => checkMatch(index, val)}
                    errorText={accountnoErrors[index]}
                    disabled={list.bankdetailsid !== 0}
                    placeholder="Eg. 18273946573849"
                    name="reaccountno"
                    maxLength={18}
                    min={isSbi ? 10 : 6}
                    integer={true}
                    defaultValue={list.reaccountno}
                  />
                </div>
                {showPennyDropModal ? (
                  <Backdrop
                    width="width28"
                    isShow={showPennyDropModal}
                    isCloseIcon={false}
                  >
                    <div
                      className="InfoModal_Bankform"
                      style={{ fontSize: "16px" }}
                    >
                      <h3>Penny drop validation</h3>
                      <div className="container">
                        <div
                          className="explainInfo w-100"
                          style={{ fontSize: "16px" }}
                        >
                          <div>We are crediting account with Rs. 1</div>
                        </div>
                        <div className="pennyDropLoader-positioning centered-div">
                          <PennyDropLoader />
                        </div>
                      </div>
                    </div>
                  </Backdrop>
                ) : (
                  ""
                )}
                <div className="bankLabel inputWidth">
                  <label
                    className={
                      list.bankdetailsid !== 0 || index !== lists.length - 1
                        ? "dissableSearchIFSC"
                        : "addActLabel"
                    }
                  >
                    IFSC Code
                    <span
                      className="ifscmodal"
                      onClick={() => openIfscModal(index)}
                    >
                      Search IFSC Code
                    </span>
                  </label>
                  <InputField
                    onChange={(val) => handleChange("ifsccode", index, val)}
                    onBlur={() => {}}
                    disabled={list.bankdetailsid !== 0}
                    placeholder="Eg. HDFC0000234"
                    name="ifsccode"
                    maxLength={11}
                    defaultValue={list.ifsccode}
                    isCaps
                  />
                </div>
                {list.bankname && (
                  <div className="bankName">
                    <p>
                      {list.bankname}
                      {", " + list.bankcity}
                      {", " + list.state}
                    </p>
                  </div>
                )}

                <div className="bankLabel inputWidth">
                  <label className="addActLabel">
                    MICR Code
                    <span
                      className="sampleModal"
                      onClick={() => setMicrModalFlag(true)}
                    >
                      View Sample
                    </span>
                  </label>
                  <DropdownSearch
                    name="micrCode"
                    searchLength={0}
                    hideSuggestion={
                      list.micrcodeList && list.micrcodeList.length === 1
                    }
                    options={list.micrcodeList && list.micrcodeList}
                    defaultValue={list.micr}
                    disabled={list.bankdetailsid !== 0}
                    searchKey="name"
                    placeholder="Select your MICR code"
                    noDataMsg="MICR code not found"
                    isDownArrow={
                      list.micrcodeList && list.micrcodeList.length > 1
                    }
                    onOptionSelect={(val) =>
                      handleDropdown(val["name"] as string, index)
                    }
                  />
                </div>
                {index !== 0 ? (
                  <div className="mrt40 toggleContainer">
                    <label className="toggleLabel">Primary Account</label>
                    <div className="nomToggle ">
                      <Toggle
                        disabled={list.bankdetailsid !== 0}
                        isOn={list.isdefaultaccount}
                        onChange={() =>
                          handleDefaultAccount(
                            "isdefaultaccount",
                            index,
                            !lists[index].isdefaultaccount
                          )
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </fieldset>
            </div>
          );
        })}

        {/* Error Msg Start*/}
        {errCode && (
          <div className="errMsgBlck errWidth mrt40">
            <ErrMsg>
              {typeof errCode === "number" ? (
                <>
                  <label>We are facing some technical issue.</label>
                  <label>
                    Please try after sometime (error code:{errCode})
                  </label>
                </>
              ) : (
                <label>{errCode}</label>
              )}
            </ErrMsg>
          </div>
        )}
        {/* Error Msg End*/}

        <div className="mrt40 addBtn">
          <DashedButton
            isAdd={true}
            btnName="Add another Bank Account"
            disabled={checkAddAnotherBankDisabled()}
            onClick={() => handleAddForm()}
          />
        </div>

        <div className="mrt40 sunBtn">
          <SubmitButton
            isLoading={loaderSign}
            btnName="Save Bank Details"
            disabled={
              checkDisabled() || checkESignSubmitted(GlobalStore.applicationID)
            }
            onClick={handleSubmit}
            type="submit"
          />
        </div>
      </form>

      {/* </form> */}
      {/* {
                showMandate &&
                <MandateDetails
                    uploadedBankList={uploadedBankList}
                />
            } */}
    </div>
  );
};

export default BankForm;
