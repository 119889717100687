import GlobalStore from "../../store/globalStore";

export const nomineeMatch=(showNominee:boolean,nomiDetails:any)=>{
    if (showNominee && nomiDetails && nomiDetails.length > 0) {
        const nomiDetailsOne = nomiDetails[0];
        const nomiDetailsTwo = nomiDetails[1];
        const nomiDetailThree = nomiDetails[2];
         
        if (
          (nomiDetailsOne && Object.keys(nomiDetailsOne)) ||
          (nomiDetailsTwo && Object.keys(nomiDetailsTwo)) ||
          (nomiDetailThree && Object.keys(nomiDetailThree))
        ) {
          // name check start
          if (
            (nomiDetailsOne?.nomFullName &&
              GlobalStore.personaldetails?.PANName &&
              nomiDetailsOne?.nomFullName?.toLowerCase() ===
                GlobalStore?.personaldetails?.PANName?.toLowerCase()) ||
            (nomiDetailsTwo?.nomFullName &&
              GlobalStore.personaldetails?.PANName &&
              nomiDetailsTwo?.nomFullName?.toLowerCase() ===
                GlobalStore?.personaldetails?.PANName?.toLowerCase()) ||
            (nomiDetailThree?.nomFullName &&
              GlobalStore.personaldetails?.PANName &&
              nomiDetailThree?.nomFullName?.toLowerCase() ===
                GlobalStore?.personaldetails?.PANName?.toLowerCase())
          ) {
            return (
              "ClientName,NomineeName and guardianName must be different"
            );
          } else if (
            (nomiDetailsOne?.guardianDetails?.guardFullName &&
              GlobalStore.personaldetails?.PANName &&
              nomiDetailsOne?.guardianDetails?.guardFullName?.toLowerCase() ===
                GlobalStore?.personaldetails?.PANName?.toLowerCase()) ||
            (nomiDetailsTwo?.guardianDetails?.guardFullName &&
              GlobalStore.personaldetails?.PANName &&
              nomiDetailsTwo?.guardianDetails?.guardFullName?.toLowerCase() ===
                GlobalStore?.personaldetails?.PANName?.toLowerCase()) ||
            (nomiDetailThree?.guardianDetails?.guardFullName &&
              GlobalStore.personaldetails?.PANName &&
              nomiDetailThree?.guardianDetails?.guardFullName?.toLowerCase() ===
                GlobalStore?.personaldetails?.PANName?.toLowerCase())
          ) {
            return(
              "ClientName,NomineeName and guardianName must be different"
            );
          } else if (
            (nomiDetailsOne?.nomFullName &&
              nomiDetailsTwo?.nomFullName &&
              nomiDetailsOne?.nomFullName?.toLowerCase() ===
                nomiDetailsTwo?.nomFullName?.toLowerCase()) ||
            (nomiDetailsTwo?.nomFullName &&
              nomiDetailThree?.nomFullName &&
              nomiDetailsTwo?.nomFullName?.toLowerCase() ===
                nomiDetailThree?.nomFullName?.toLowerCase()) ||
            (nomiDetailThree?.nomFullName &&
              nomiDetailsOne?.nomFullName &&
              nomiDetailThree?.nomFullName?.toLowerCase() ===
                nomiDetailsOne?.nomFullName?.toLowerCase())
          ) {
            return(
              "ClientName,NomineeName and guardianName must be different"
            );
          } else if (
            (nomiDetailsOne?.nomFullName &&
              nomiDetailsOne?.guardianDetails?.guardFullName &&
              nomiDetailsOne?.nomFullName?.toLowerCase() ===
                nomiDetailsOne?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailsOne?.nomFullName &&
              nomiDetailsTwo?.guardianDetails?.guardFullName &&
              nomiDetailsOne?.nomFullName?.toLowerCase() ===
                nomiDetailsTwo?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailsOne?.nomFullName &&
              nomiDetailThree?.guardianDetails?.guardFullName &&
              nomiDetailsOne?.nomFullName?.toLowerCase() ===
                nomiDetailThree?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailsTwo?.nomFullName &&
              nomiDetailsOne?.guardianDetails?.guardFullName &&
              nomiDetailsTwo?.nomFullName?.toLowerCase() ===
                nomiDetailsOne?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailsTwo?.nomFullName &&
              nomiDetailsTwo?.guardianDetails?.guardFullName &&
              nomiDetailsTwo?.nomFullName?.toLowerCase() ===
                nomiDetailsTwo?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailsTwo?.nomFullName &&
              nomiDetailThree?.guardianDetails?.guardFullName &&
              nomiDetailsTwo?.nomFullName?.toLowerCase() ===
                nomiDetailThree?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailThree?.nomFullName &&
              nomiDetailsOne?.guardianDetails?.guardFullName &&
              nomiDetailThree?.nomFullName?.toLowerCase() ===
                nomiDetailsOne?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailThree?.nomFullName &&
              nomiDetailsTwo?.guardianDetails?.guardFullName &&
              nomiDetailThree?.nomFullName?.toLowerCase() ===
                nomiDetailsTwo?.guardianDetails?.guardFullName?.toLowerCase()) ||
            (nomiDetailThree?.nomFullName &&
              nomiDetailThree?.guardianDetails?.guardFullName &&
              nomiDetailThree?.nomFullName?.toLowerCase() ===
                nomiDetailThree?.guardianDetails?.guardFullName?.toLowerCase())
          ) {
            return(
              "ClientName,NomineeName and guardianName must be different"
            );
          } else if (
            (nomiDetailsOne?.guardianDetails?.guardFullName &&
              nomiDetailsTwo?.guardianDetails?.guardFullName &&
              nomiDetailsOne?.guardianDetails?.guardFullName?.toLowerCase() ===
                nomiDetailsTwo?.guardianDetails?.guardFullName.toLowerCase()) ||
            (nomiDetailsTwo?.guardianDetails?.guardFullName &&
              nomiDetailThree?.guardianDetails?.guardFullName &&
              nomiDetailsTwo?.guardianDetails?.guardFullName?.toLowerCase() ===
                nomiDetailThree[
                  "guardianDetails"
                ]?.guardFullName?.toLowerCase()) ||
            (nomiDetailsOne?.guardianDetails?.guardFullName &&
              nomiDetailThree?.guardianDetails?.guardFullName &&
              nomiDetailsOne?.guardianDetails?.guardFullName?.toLowerCase() ===
                nomiDetailThree?.guardianDetails?.guardFullName?.toLowerCase())
          ) {
            return(
              "ClientName,NomineeName and guardianName must be different"
            );
          }
          //name check end
          // pan no check start
          else if (
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              GlobalStore.personaldetails?.pan &&
              nomiDetailsOne?.identificationDetail?.value ===
                GlobalStore.personaldetails?.pan) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              GlobalStore.personaldetails?.pan &&
              nomiDetailsTwo?.identificationDetail?.value ===
                GlobalStore.personaldetails?.pan) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              GlobalStore.personaldetails?.pan &&
              nomiDetailThree?.identificationDetail?.value ===
                GlobalStore.personaldetails?.pan)
          ) {
            return(
              "ClientPanNo,NomineePanNo and guardianPanNo must be different"
            );
          } else if (
            (nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              GlobalStore.personaldetails?.pan &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.value ===
                GlobalStore.personaldetails?.pan) ||
            (nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              GlobalStore.personaldetails?.pan &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.value ===
                GlobalStore?.personaldetails?.pan) ||
            (nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              GlobalStore.personaldetails?.pan &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.value ===
                GlobalStore?.personaldetails?.pan)
          ) {
            return(
              "ClientPanNo,NomineePanNo and guardianPanNo must be different"
            );
          } else if (
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsTwo?.identificationDetail?.name?.includes(
                "PAN Card no"
              ) &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailsTwo?.identificationDetail?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailThree?.identificationDetail?.name?.includes(
                "PAN Card no"
              ) &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailThree?.identificationDetail?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsOne?.identificationDetail?.name?.includes(
                "PAN Card no"
              ) &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailsOne?.identificationDetail?.value)
          ) {
            return(
              "ClientPanNo,NomineePanNo and guardianPanNo must be different"
            );
          } else if (
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailsOne?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail
                  ?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailsOne?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail
                  ?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailsOne?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "PAN Card no"
            ) &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail?.value)
          ) {
            return(
              "ClientPanNo,NomineePanNo and guardianPanNo must be different"
            );
          } else if (
            (nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail
                  ?.value) ||
            (nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("PAN Card no") &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail?.value)
          ) {
            return(
              "ClientPanNo,NomineePanNo and guardianPanNo must be different"
            );
          }
          // pan no check end
          // addhar no check start
          else if (
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              GlobalStore.personaldetails?.aadharNo &&
              nomiDetailsOne?.identificationDetail?.value ===
                GlobalStore.personaldetails?.aadharNo) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              GlobalStore.personaldetails?.aadharNo &&
              nomiDetailsTwo?.identificationDetail?.value ===
                GlobalStore.personaldetails?.aadharNo) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              GlobalStore.personaldetails?.aadharNo &&
              nomiDetailThree?.identificationDetail?.value ===
                GlobalStore.personaldetails?.aadharNo)
          ) {
            return(
              "ClientAadharNo,NomineeAadharNo and guardianAadharNo must be different"
            );
          } else if (
            (nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              GlobalStore.personaldetails?.aadharNo &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.value ===
                GlobalStore.personaldetails?.aadharNo) ||
            (nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              GlobalStore.personaldetails?.aadharNo &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.value ===
                GlobalStore?.personaldetails?.aadharNo) ||
            (nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              GlobalStore.personaldetails?.aadharNo &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.value ===
                GlobalStore?.personaldetails?.aadharNo)
          ) {
            return(
              "ClientAadharNo,NomineeAadharNo and guardianAadharNo must be different"
            );
          } else if (
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsTwo?.identificationDetail?.name?.includes(
                "Aadhar Card no"
              ) &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailsTwo?.identificationDetail?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailThree?.identificationDetail?.name?.includes(
                "Aadhar Card no"
              ) &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailThree?.identificationDetail?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsOne?.identificationDetail?.name?.includes(
                "Aadhar Card no"
              ) &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailsOne?.identificationDetail?.value)
          ) {
            return(
              "ClientAadharNo,NomineeAadharNo and guardianAadharNo must be different"
            );
          } else if (
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailsOne?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsOne?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsOne?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail
                  ?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailsOne?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsTwo?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsTwo?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail
                  ?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailsOne?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailThree?.identificationDetail?.name?.includes(
              "Aadhar Card no"
            ) &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailThree?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail?.value)
          ) {
            return(
              "ClientAadharNo,NomineeAadharNo and guardianAadharNo must be different"
            );
          } else if (
            (nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.value ===
                nomiDetailsTwo?.guardianDetails?.identificationDetail?.value) ||
            (nomiDetailsTwo?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsTwo?.guardianDetails?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail
                  ?.value) ||
            (nomiDetailsOne?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailThree?.guardianDetails?.identificationDetail?.name?.includes("Aadhar Card no") &&
              nomiDetailsOne?.guardianDetails?.identificationDetail?.value ===
                nomiDetailThree?.guardianDetails?.identificationDetail?.value)
          ) {
            return(
              "ClientAadharNo,NomineeAadharNo and guardianAadharNo must be different"
            );
          }
          //  addhar no check end
          else {
            return("");
          }
        }
      }
}