import React, { Component } from "react";
import down from "../../../assets/icons/down.svg";
import "./DropdownSearch.scss";

export interface IProps {
  options: any;
  searchKey: string;
  placeholder?: string;
  noDataMsg?: string;
  isDownArrow?: boolean;
  name?: string;
  disabled?: boolean;
  errorText?: string;
  autoComplete?: string;
  searchLength: number;
  className?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onOptionSelect?: (value: any) => void;
  onBlur?: (value: any) => void;
  hideSuggestion?: boolean;
  autoSelectOff?: boolean;
  disableTick?: boolean;
}

export interface IState {
  isShowSuggestions: boolean;
  value: string;
  selectedObj?: object | undefined;
}
class DropdownSearch extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isShowSuggestions: false,
      value: props.defaultValue || "",
      selectedObj:
        (this.props.defaultValue &&
          this.findFromList(this.props.options, this.props.defaultValue)) ||
        undefined,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    //post getting the updated props, sets the selectedObj value to object with matching defaultvalue
    //if autoselectoff is true do not set the selectedObj value for ex refer city DD
    if (
      !prevProps.options.length &&
      this.props.options.length &&
      !this.state.selectedObj &&
      !this.props.autoSelectOff &&
      this.props.defaultValue &&
      this.state.selectedObj !== null
    ) {
      const matchedItem = this.findFromList(
        this.props.options,
        this.props.defaultValue
      );
      matchedItem &&
        this.setState({
          selectedObj: matchedItem,
        });
    }
  }
  findFromList = (list: Array<any>, lookFor: string) => {
    const matchedOption = list.filter((val: any) => {
      return (
        val[this.props.searchKey]?.toLowerCase() === lookFor?.toLowerCase() ||
        Number(lookFor) === Number(val[this.props.searchKey])
      );
    });
    if (matchedOption.length === 1) {
      return matchedOption[0];
    } else {
      return null;
    }
  };

  // static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
  //     if (nextProps.defaultValue !== prevState.value && nextProps.defaultValue !== undefined) {
  //         return { value: nextProps.defaultValue };
  //     } else return null;
  // }

  UNSAFE_componentWillReceiveProps(nextProps: IProps, prevState: IState) {
    if (
      nextProps.defaultValue !== prevState.value &&
      nextProps.defaultValue !== undefined
    ) {
      this.setState({ value: nextProps.defaultValue });
    }
  }

  onFocus = () => {
    if (
      this.props.searchLength === 0 ||
      this.state.value.length >= this.props.searchLength
    ) {
      this.setState({ isShowSuggestions: true });
    } else {
      this.setState({ isShowSuggestions: false });
    }
  };

  onChange = (value: any) => {
    if (value.trim().length >= this.props.searchLength) {
      this.setState({ isShowSuggestions: true });
    } else {
      this.setState({ isShowSuggestions: false });
    }
    this.setState({ value: value, selectedObj: undefined });
    this.props.onChange && this.props.onChange(value);
  };

  handleBlur = () => {
    setTimeout(() => {
      if (this.state.isShowSuggestions) {
        this.setState({ isShowSuggestions: false });
      }
      this.props.onBlur && this.props.onBlur(this.state.selectedObj);
    }, 300);
  };

  onOptionSelect = (item: any) => {
    this.setState({
      value: item[this.props.searchKey],
      selectedObj: item,
      isShowSuggestions: false,
    });
    if (this.props.onOptionSelect) {
      this.props.onOptionSelect(item);
    }
  };
  //selects all the text on click
  handleClick = (event: any) => {
    if (!this.props.autoSelectOff) {
      !this.props.hideSuggestion && event.target.select();

      event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      //after selecting the option if user clicks on dropdown user will be taken to the option selected in the list
      if (this.props.options.indexOf(this.state.selectedObj) >= 0) {
        var index = this.props.options.findIndex((val: any) =>
          val[this.props.searchKey]
            .toLowerCase()
            .includes(this.state.value.toLowerCase())
        );
        index > -1 && event.target.nextSibling.scrollTo(0, index * 60);
        //60px height of the list item
      }
    }
  };

  render() {
    const { isShowSuggestions } = this.state;
    let ddCount = 0;

    const noData = (
      <div className="dds_odiv">
        <div className="dds_oldiv">
          <label className="dds_olabel">{this.props.noDataMsg}</label>
        </div>
      </div>
    );
    let suggestions =
      this.state.isShowSuggestions &&
      this.state.value.length >= this.props.searchLength
        ? this.props.options.filter((item: any, i: any) => {
            if (
              item[this.props.searchKey]
                .toLowerCase()
                .includes(this.state.value.toLowerCase()) ||
              (this.props.options.indexOf(this.state.selectedObj) >= 0 &&
                !this.props.autoSelectOff)
            ) {
              ddCount++;
              return item;
            }
          })
        : null;
    suggestions &&
      suggestions.sort((a: any, b: any) =>
        a[this.props.searchKey]
          .toLowerCase()
          .indexOf(this.state.value.toLowerCase()) <
        b[this.props.searchKey]
          .toLowerCase()
          .indexOf(this.state.value.toLowerCase())
          ? -1
          : b[this.props.searchKey]
              .toLowerCase()
              .indexOf(this.state.value.toLowerCase()) >
            a[this.props.searchKey]
              .toLowerCase()
              .indexOf(this.state.value.toLowerCase())
          ? 1
          : 0
      );
    let showSuggestions =
      suggestions && suggestions.length > 0
        ? suggestions.map((item: any, i: any) => {
            let dropdownData = item[this.props.searchKey];
            return (
              <div
                key={i}
                className="dds_odiv"
                onClick={() => this.onOptionSelect(item)}
              >
                <div className="dds_oldiv">
                  <label
                    className={
                      this.props.disableTick ? "dd_lbl_notGrid" : "dd_lbl"
                    }
                  >
                    {!this.props.disableTick &&
                    (this.state.selectedObj === item ||
                      dropdownData === this.props.defaultValue) ? (
                      <i className="edel-icon-tick"></i>
                    ) : (
                      <i></i>
                    )}
                    <span
                      className="dds_olabel"
                      dangerouslySetInnerHTML={{ __html: dropdownData }}
                    ></span>
                  </label>
                </div>
              </div>
            );
          })
        : null;

    return (
      <div className="dds_grp">
        <input
          name={this.props.name}
          id={this.props.name}
          type="text"
          className={
            "dds " +
            this.props.className +
            (this.props.errorText ? " error" : "")
          }
          onClick={this.handleClick}
          onChange={(e) => this.onChange(e.target.value)}
          onFocus={this.onFocus}
          onBlur={this.handleBlur}
          value={this.state.value}
          readOnly={!this.props.hideSuggestion ? false : true}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          autoComplete={
            this.props.autoComplete ? this.props.autoComplete : "off"
          }
          style={
            this.props.isDownArrow ? { backgroundImage: `url(${down})` } : {}
          }
        />
        {!this.props.hideSuggestion &&
        isShowSuggestions &&
        (showSuggestions || this.props.noDataMsg) ? (
          <div className="option_grp">{ddCount ? showSuggestions : noData}</div>
        ) : null}
        {this.props.errorText && (
          <p className="help_text red">{this.props.errorText}</p>
        )}
      </div>
    );
  }
}

// export default React.memo(DropdownSearch);
export default DropdownSearch;
