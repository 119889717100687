import React, { Component } from "react";
import Button from "../../UI/Button/Button";
import OtpField from "../../UI/otpField/otpField";
import OtpTimer from "../../UI/OtpTimer/OtpTimer";
import "./OTP.scss";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import { AValidateMobileOtp } from "../../Services/registration/registrationApiService";
import { IUserObj } from "../../types/genericTypes";
import GlobalStore from "../../store/globalStore";

export interface IProps {}
export interface IState {
  otp: string;
  isResend: boolean;
  timer: number;
  reloadKey: any;
  user: IUserObj;
}
class OTP extends Component<IProps, IState> {
  state = {
    otp: "",
    isResend: false,
    timer: 15,
    reloadKey: Math.random(),
    user: GlobalStore.user,
  };

  enableResend = () => {
    let resendTimer = setInterval(() => {
      let temp = this.state.timer - 1;
      if (temp === 0) {
        clearInterval(resendTimer);
        this.setState({ timer: 15, isResend: true });
      } else {
        this.setState({ timer: temp });
      }
    }, 1000);
  };

  componentDidMount() {
    this.enableResend();
  }

  setOtp = (value: any) => {
    this.setState({ otp: value });
  };

  resendClicked = () => {
    this.setState({ reloadKey: Math.random(), isResend: false });
    this.enableResend();
  };

  checkValidateOtp = (res: any) => {};

  validateOtp = () => {
    AValidateMobileOtp(
      this.checkValidateOtp,
      this.state.user.mobile.toString(),
      this.state.otp
    );
  };

  render() {
    return (
      <div className="otp">
        <form>
          <p className="heading">Enter OTP</p>
          <p className="info">
            We have sent you an OTP on your mobile no. ending 3462
          </p>
          <div className="otpField">
            <OtpField
              onChange={this.setOtp}
              key={this.state.reloadKey}
              eleId="otp-field"
            />
          </div>
          {false && <label className="otpVerified">OTP Verified</label>}
          {false && <label className="invalidOtp">Incorrect OTP</label>}
          <div className="resendOtp">
            {this.state.isResend ? (
              <div className="resendOtpText">
                <label className="otpNotRecv">Didn’t receive the OTP?</label>
                <label className="resendOtpLink" onClick={this.resendClicked}>
                  Resend now
                </label>
              </div>
            ) : (
              <div className="otpTimer">
                <OtpTimer timer={15} />
              </div>
            )}
          </div>
          {false && (
            <div className="codeErrMsg">
              <ErrMsg>
                <label>We are facing some technical issue.</label>
                <label>Please try after sometime (error code:115)</label>
              </ErrMsg>
            </div>
          )}
          <div className="verifyBtn">
            <Button
              btnName="Verify"
              onClick={this.validateOtp}
              disabled={false}
              isLoading={false}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default OTP;
