import React from "react";
import esignBanner from "../../../assets/icons/esignBanner.jpeg";
import ESignForm from "../ESignForm/ESignForm";
import PendingModules from "../PendingModulesList/PendingModulesList";
import GlobalStore from "../../store/globalStore";
import cleverTapEvents from "../../../config/cleverTapEvents";
import useQuery from "../../../customHooks/useQuery";
import { ClevertapService } from "../../Services/clevertapService";
import EsignKra from "../ESignKra/ESignKra";
import { useLocation } from "react-router-dom";
import { parseQuery } from "../../Services/globalService";
import Button, { TransparentButton } from "../../UI/Button/Button";
import Backdrop from "../../UI/Backdrop/Backdrop";
import {
  AGetPanUtiData,
  APanLinkedWithAadhar,
} from "../../Services/registration/panDobApiService";
import isSeeding from "../../../assets/icons/seeding.png";
import { AUpdatePanAddharLinkStatus } from "../../Services/dashboard/eSignApiServices";
export interface ESignHomeProps {}

const ESignHome: React.FC<ESignHomeProps> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const location = useLocation();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";
  const [isEsign, setisEsign] = React.useState<boolean>(
    GlobalStore.stageStatus.isesigncompleted
  );
  const [isEsignKra, setEsignKra] = React.useState<boolean>(
    GlobalStore.stageStatus.iskraesigncompleted
  );

  const [ispanlinkedwithaadhar, setIspanlinkedwithaadhar] =
    React.useState<boolean>(GlobalStore.stageStatus.ispanlinkedwithaadhar);
  const [checkpanaaddharflag, setCheckpanaaddharflag] = React.useState<boolean>(
    GlobalStore.stageStatus.checkpanaaddharflag
  );
  const [pendingModules, setPendingModules] =
    React.useState<Array<{ name: string; navUrl: string }>>();
  const queryObject = parseQuery(location.search);
  React.useEffect(() => {
    checkPendingModules();
    window.sessionStorage.setItem("stageInfoId", "10");
  }, []);
  React.useEffect(() => {
    setEsignKra(GlobalStore.stageStatus.iskraesigncompleted);
    setisEsign(GlobalStore.stageStatus.isesigncompleted);
  }, [
    GlobalStore.stageStatus.isesigncompleted,
    GlobalStore.stageStatus.iskraesigncompleted,
    isEsign,
    isEsignKra,
  ]);
  const [seedingPopup, setSeedingPopup] = React.useState<boolean>(
    !GlobalStore.stageStatus.ispanlinkedwithaadhar
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<any>("");

  // Clervertap event: Profiler/Investment screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.eSignPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);
  }, []);

  function checkPendingModules(): Array<{ name: string; navUrl: string }> {
    let arr: Array<{ name: string; navUrl: string }> = [];
    GlobalStore.leftNavBarState.forEach((module) => {
      if (!module.isComplete && module.isRequired) {
        arr.push({ name: module.name, navUrl: module.navUrl });
      }
    });
    setPendingModules(arr);
    return arr;
  }

  const closeSeedingPopup = () => {
    setSeedingPopup(false);
    // saveDocument();
  };

  const reCheck = () => {
    setIsLoading(true);
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    AGetPanUtiData(getSeedingData, clientPartialDetails_ao.pan);
  };

  const getSeedingData = (res: any) => {
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    if (!res || res.error) {
      console.log(res.errMsg);
      setErrCode(errCode + res.error);
    } else {
      if (res.data) {
        setIsLoading(false);
        // let responseObj = JSON.parse(res.data.panDetails);
        let responseObj = res.data.AadharSeedingStatus
          ? res.data.AadharSeedingStatus
          : "";
        // if (responseObj.includes("Aadhaar not Seeded")) {
        if (responseObj.IsAadharSeeded == "N") {
          GlobalStore.isSeeding = false;
          setSeedingPopup(true);
          setIspanlinkedwithaadhar(false);
          APanLinkedWithAadhar(
            APanLinkedWithAadharRes,
            clientPartialDetails_ao.pan,
            clientPartialDetails_ao.aadharNo
          );
        }
        // else if (responseObj.includes("Aadhaar Seeding is Successful")) {
        else if (
          responseObj.IsAadharSeeded == "Y" ||
          responseObj.IsAadharSeeded == "NA"
        ) {
          GlobalStore.isSeeding = true;
          setSeedingPopup(false);
          setIspanlinkedwithaadhar(true);
          AUpdatePanAddharLinkStatus(
            panAdhaarLinkStatusUpdated,
            GlobalStore.applicationID,
            true
          );
        }
      }
    }
  };
  function APanLinkedWithAadharRes(res: any) {
    if (!res || res.error) {
      setIsLoading(false);
      setErrCode(res.error);
    } else {
      if (res.data?.isvalid == true) {
        if (res.data.islinked) {
          setIsLoading(true);
          setSeedingPopup(false);
          AUpdatePanAddharLinkStatus(
            panAdhaarLinkStatusUpdated,
            GlobalStore.applicationID,
            true
          );
        } else {
          setSeedingPopup(true);
          setIsLoading(false);
        }
        // AIsPANExists(isPANExists, values.pan);
      } else {
        // alert(res.data?.message);
        setIsLoading(false);
      }
    }
  }

  const panAdhaarLinkStatusUpdated = (res: any) => {
    console.log("AUpdatePanAddharLinkStatus", res);
  };

  const navToLink = () => {
    window.open(
      "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar",
      "_blank"
    );
  };

  return (
    <>
      <div>
        {!isEsign && queryObject.sc !== "k" && (
          <div className="prflHome">
            <div className="prflHeading">
              <label>E-Sign</label>
            </div>
            <div className="banner">
              <img className="bannerImg" src={esignBanner} alt="ESign" />
            </div>
            {pendingModules && (
              <>
                {pendingModules.length > 0 ? (
                  <PendingModules pendingModulesList={pendingModules} />
                ) : (
                  <ESignForm />
                )}
              </>
            )}
          </div>
        )}
        {queryObject.sc === "k" && !isEsignKra && <EsignKra />}
      </div>
      <div className={"docForm frame" + (isLoading ? " op05" : "")}>
        {/* <Backdrop
          isShow={seedingPopup}
          onClose={() => closeSeedingPopup()}
          isCloseIcon={false}
        >
          <div className="isSeeding"> */}
            {/* {DeviceContext().isMobile && <EdelLogoCenter />} */}
            {/* <img className="seedingIcon" src={isSeeding} alt="isSeeding" />
            <label className="seedingLabel">PAN Aadhar Link</label>
            <label className="subLAbel">
              We observe that your PAN and Aadhar is not linked. There are
              higher chances of your PAN getting deactivated.
            </label>
            <div className="seedingCTA">
              <div className="borderBlue">
                <TransparentButton
                  isLoading={isLoading}
                  btnName="Re-Check"
                  onClick={() => reCheck()}
                  disabled={!checkpanaaddharflag}
                />
              </div>
              <div>
                <Button btnName="Link PAN & Aadhar" onClick={navToLink} />
              </div>
            </div>
          </div>
        </Backdrop> */}
      </div>
    </>
  );
};

export default ESignHome;
