import React from "react";
import "./FamilyMember.scss";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import InputField from "../../UI/InputField/InputField";
import { IListObj } from "../../types/genericTypes";

export interface IFamilyMember {
  accountCode: string;
  AccountName: string;
  dependentOn: string;
  mobileList: string;
  panNo: string;
  relationshipName: string;
  subActiveDate: string;
  toEmailList: string;
  error: string;
}
export interface FamilyMemberProps {
  index: number | string;
  type: "email" | "mobile" | "both";
  member: IFamilyMember;
  relationList: Array<IListObj>;
  originalRelationList?: Array<IListObj>;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  error: string;
}

const FamilyMember: React.FC<FamilyMemberProps> = (props) => {
  const [isOther, setIsOther] = React.useState<boolean>(false);

  function infoType() {
    switch (props.type) {
      case "email":
        return "Email ID matches";
      case "mobile":
        return "Mobile number matches";
      case "both":
        return "Mobile number and Email ID matches";
    }
  }

  function onRelationSelect(val: any) {
    // if (val.display === 'Others') {
    //     setIsOther(true)
    // } else {
    //     setIsOther(false)
    // }
    props.onChange(val.value);
  }
  let defaultValue: string = "";
  props?.originalRelationList?.forEach((relation) => {
    if (
      parseInt(relation.value.toString()) ===
      parseInt(props.member.relationshipName)
    ) {
      defaultValue = relation.display;
      return;
    }
  });
  return (
    <div className="familyMember">
      <label className="memName">{props.member.AccountName}</label>
      <div className="mgt10">
        <label className="memInfo">{infoType()}</label>
      </div>
      <div className="mgt20">
        <label className="memRel">Relation</label>
        <DropdownSearch
          name={`fmlyRelation-${props.type}-${props.index}`}
          onOptionSelect={(value) => onRelationSelect(value)}
          defaultValue={defaultValue}
          searchLength={0}
          options={props.relationList}
          // onBlur={handleCityBlur}
          searchKey="display"
          placeholder="Father"
          noDataMsg="Relation with similar name not found"
          // errorText={errors.city}
          isDownArrow={true}
        />
      </div>
      {isOther && (
        <div className="mgt40">
          <label className="fieldLbl">Other</label>
          <InputField
            onBlur={() => {}}
            onChange={() => {}}
            type="text"
            placeholder="Eg. Niece"
          />
        </div>
      )}
    </div>
  );
};

export default FamilyMember;
