import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";
import domain from "../../../config/domain";
import { digiLockerUrl } from "../../utils/constants";


export const AGenerateAccessToken = (
  callback: CallableFunction,
  accessTokenObj: any
) => {
  apiService(
    {
      url: "DIYAPI/v1/GenerateAccessTokenForDigilockerJavaMW",
      method: "POST",
      body: {
        State: "",
        Code: accessTokenObj.code,
        ReDirectUrl: accessTokenObj.reDirectUrl,
        ClientId: accessTokenObj.client_Id,
        AccessToken: "",
        SourceId: domain.digiLockerSource,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.generateAccessTokenForDigilockerJavaMW,
    },
    callback
  );
  // apiService(
  //     {
  //         url: 'eco/generateAccessToken',
  //         body: accessTokenObj,
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const AGetDocListFromDigilocker = (
  callback: CallableFunction,
  digilockerObj: any
) => {
  apiService(
    {
      url: "DIYAPI/v1/GetDocListFromDigilocker",
      method: "POST",
      body: {
        access_token: digilockerObj.access_token,
        SourceId: domain.digiLockerSource,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocListFromDigilocker,
    },
    callback
  );
  //   apiService(
  //     {
  //       url: "eco/getDocListFromDigilocker",
  //       body: digilockerObj,
  //       method: "POST",
  //     },
  //     callback
  //   );
};

export const AGetSelectedDocument = (
  callback: CallableFunction,
  dataObj: any
) => {
  apiService(
    {
      url: "DIYAPI/v1/GetSelectedDocument",
      method: "POST",
      body: {
        Token: dataObj.token,
        FileUri: dataObj.fileUri,
        ApplicationId: dataObj.applicationId,
        FileName: dataObj.filename,
        ClientPrimaryDetailId: dataObj.clientPrimaryDetailId,
        LeadId: dataObj.leadId,
        PanNo: dataObj.panno,
        AadharNo: dataObj.aadharNo,
        utmInfoDetails: dataObj.utmObj,
        SourceId: domain.digiLockerSource,
        // UtmInfoDetails: {
        //   modeofapplication: "",
        //   utm_source: "",
        //   utm_term: "",
        //   utm_campaign: "",
        //   utm_content: "",
        //   referralcode: "",
        //   couponcode: "",
        //   stageinfo: "",
        //   signupdropout: "",
        //   documentsdur: "",
        //   registrationdur: "",
        //   devicetype: "",
        //   clientip: "",
        //   diysource: "",
        // },
        // UTMInfoDetails: {
        //   modeofapplication: "E",
        //   referralcode: "",
        //   couponcode: "",
        //   stageinfo: "",
        //   signupdropout: "",
        //   registrationdur: "",
        //   devicetype: "Desktop",
        //   clientip: "",
        //   diysource: "W",
        // },
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getSelectedDocument,
    },
    callback
  );
  //   apiService(
  //     {
  //       url: "eco/getSelectedDocument",
  //       body: dataObj,
  //       method: "POST",
  //     },
  //     callback
  //   );
};

export const AGetDigiLink = (
  callback: CallableFunction,
  getDigiLinkObj: any
) => {
  if(domain.digiLockerSource==2)
  {
    apiService(
      {
        url: "DIYAPI/v1/GenerateAccessTokenForDigilockerJavaMW",
        method: "POST",
        body: {
          SourceId: domain.digiLockerSource,
          ApplicationId:getDigiLinkObj.ApplicationId
        },
        apiBaseUrl: config.diyNewApiUrl,
        tempToken: config.tempToken,
        serviceName: servicesList.generateAccessTokenForDigilockerJavaMW,
      },
      callback
    );

  }
};

// export const AGetSelectedDocument = (callback: CallableFunction, obj: object) => {
//     apiService(
//         {
//             url: 'eco/getDocListFromDigilocker',
//             body: obj,
//             method: "POST",
//         },
//         callback
//     );
// }
