import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";

export const AGetDocumentList = (callback: CallableFunction, appID: string) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: appID,
        InputDataType: 2,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-document-list",
  //     body: {
  //       applicationid: appID,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetUploadedDocument = (
  callback: CallableFunction,
  appID: string
) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: appID,
        InputDataType: 4,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-uploaded-document-list",
  //     body: {
  //       applicationid: appID,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetPendingDocument = (
  callback: CallableFunction,
  appID: string
) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: appID,
        InputDataType: 1,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //     {
  //         url: 'eco/get-pending-document-list',
  //         body: {
  //             "applicationid": appID,
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const AGetDocStatus = (callback: CallableFunction, appID: string) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentDetails",
      body: {
        ApplicationId: appID,
        InputDataType: 3,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-doc-status",
  //     body: {
  //       applicationid: appID,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ADownloadDocument = (
  callback: CallableFunction,
  fileName: string,
  filePath: string
) => {
  apiService(
    {
      url: "DIYAPI/DownloadDocumentFromS3",
      body: {
        FileName: fileName,
        FilePath: filePath,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.downloadDocumentFromS3,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/download-document",
  //     body: {
  //       filename: fileName,
  //       filepath: filePath,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ASaveDocumentDetails = (
  callback: CallableFunction,
  saveObj: any
) => {
  apiService(
    {
      url: "DIYAPI/SaveUploadedDocumentDetails",
      body: {
        ClientPrimaryDetailId: saveObj.clientPrimaryDetailId,
        ApplicationId: saveObj.applicationId,
        LeadId: saveObj.leadId,
        DocumentInfo: saveObj.documentInfo
          ? sortDocumentInfo(saveObj.documentInfo)
          : [],
        UtmInfoDetails: saveObj.objUTM_Info,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveUploadedDocumentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-document-details",
  //     body: saveObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AextractPan = (callback: CallableFunction, panObj: any) => {
  console.log(panObj, "AextractPan");
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: panObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: "ind_pan",
        Source: panObj.source,
        ApplicationId: panObj.applicationid,
        DocumentCategory: panObj.documentcategory,
        DocumentId: panObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractpan",
  //     body: panObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const AextractPanNew = (callback: CallableFunction, panObj: any) => {
  console.log(panObj, "AextractPanNew");
  apiService(
    {
      url: "Idfy/ED",
      body: {
        Base64Document1: panObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: "ind_pan",
        Source: panObj.source,
        ApplicationId: panObj.applicationid,
        DocumentCategory: panObj.documentcategory,
        DocumentId: panObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
   //   tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractpan",
  //     body: panObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const AextractAadhaar = (
  callback: CallableFunction,
  aadhaarObj: any,
  OCR?: any
) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: aadhaarObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: OCR ? "ind_aadhaar_plus" : "ind_aadhaar",
        Source: aadhaarObj.source,
        ApplicationId: aadhaarObj.applicationid,
        DocumentCategory: OCR ? "Aadhaar" : aadhaarObj.documentcategory,
        DocumentId: aadhaarObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractaadhaar",
  //     body: aadhaarObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AextractCheque = (callback: CallableFunction, chequeObj: any) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: chequeObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: "ind_cheque",
        Source: chequeObj.source,
        ApplicationId: chequeObj.applicationid,
        DocumentCategory: chequeObj.documentcategory,
        DocumentId: chequeObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractcheque",
  //     body: chequeObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AextractBankStatement = (
  callback: CallableFunction,
  chequeObj: any
) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        BankName: chequeObj.bankName,
        AccountType: chequeObj.accountType,
        Base64Document1: chequeObj.base64document,
        Base64Document2: "",
        ActionName: "verify_with_source",
        DocumentType: "ind_bank_statement",
        Source: chequeObj.source,
        ApplicationId: chequeObj.applicationid,
        DocumentCategory: chequeObj.documentcategory,
        DocumentId: chequeObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractcheque",
  //     body: chequeObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AextractPassport = (
  callback: CallableFunction,
  passportObj: any
) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: passportObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: "ind_passport",
        Source: passportObj.source,
        ApplicationId: passportObj.applicationid,
        DocumentCategory: passportObj.documentcategory,
        DocumentId: passportObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractpassport",
  //     body: passportObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AextractVoterid = (
  callback: CallableFunction,
  voterIdObj: any
) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: voterIdObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: "ind_voter_id",
        Source: voterIdObj.source,
        ApplicationId: voterIdObj.applicationid,
        DocumentCategory: voterIdObj.documentcategory,
        DocumentId: voterIdObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractvoterid",
  //     body: voterIdObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AextractDl = (callback: CallableFunction, dlObj: any) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: dlObj.base64document,
        Base64Document2: "",
        ActionName: "extract",
        DocumentType: "ind_driving_license",
        Source: dlObj.source,
        ApplicationId: dlObj.applicationid,
        DocumentCategory: dlObj.documentcategory,
        DocumentId: dlObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/extractdl",
  //     body: dlObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const Afacecompare = (callback: CallableFunction, faceObj: any) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: faceObj.base64document1,
        Base64Document2: faceObj.base64document2,
        ActionName: "compare",
        Source: faceObj.source,
        DocumentType: "face",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/facecompare",
  //     body: faceObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AgetPdf = (callback: CallableFunction, pdfObj: any) => {
  apiService(
    {
      url: "DIYAPI/MargeMultipleImagestoPDF",
      body: {
        ApplicationId: pdfObj.applicationId,
        UploadFlag: 1,
        Images: [
          {
            Base64DocumentImage: pdfObj.image1,
            Imagename: pdfObj.imagename1,
          },
          {
            Base64DocumentImage: pdfObj.image2,
            Imagename: pdfObj.imagename2,
          },
        ],
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.margeMultipleImagestoPDF,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/MargeMultipleImagestoPDF",
  //     body: pdfObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ASendApplicationForQC = (
  callback: CallableFunction,
  postDataQcObj: object
) => {
  apiService(
    {
      url: "eco/post-qc-data",
      body: postDataQcObj,
      method: "POST",
    },
    callback
  );
};

export const AcreateApplicationTicket = (
  callback: CallableFunction,
  appTicketObj: any
) => {
  apiService(
    {
      url: "DIYAPI/CreateApplicationTicket",
      body: {
        ApplicationId: appTicketObj.applicationid,
        ClientPrimaryDetailId: appTicketObj.clientprimarydetailid,
        StageInfoId: appTicketObj.stageinfoid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.createApplicationTicket,
    },
    callback
  );

  // apiService(
  //   {
  //     url: "eco/create-application-ticket",
  //     body: appTicketObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AmaskAadhaar = (callback: CallableFunction, aadhaarObj: any) => {
  apiService(
    {
      url: "Idfy/ExtractDocumentData",
      body: {
        Base64Document1: aadhaarObj.base64document,
        Base64Document2: "",
        ActionName: "mask",
        DocumentType: "ind_aadhaar",
        Source: aadhaarObj.source,
        ApplicationId: aadhaarObj.applicationid,
        DocumentCategory: aadhaarObj.documentcategory,
        DocumentId: aadhaarObj.documentid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getExtractDocumentData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/maskaadhaar",
  //     body: aadhaarObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

function sortDocumentInfo(documents: any) {
  let sortedDocuments: Array<Object> = [];
  for (let doc of documents) {
    let docObj: any = {};
    docObj.FileName = doc.fileName;
    docObj.DocumentId = doc.documentId;
    docObj.MimeType = doc.mimeType;
    docObj.PanNo = doc.panno;
    docObj.PersonalDetailsId = doc.personaldetailsid;
    docObj.DocumentCategoryId = doc.documentCategoryId;
    docObj.DocumentExpiryDate = doc.documentexpirydate;
    docObj.IsFileCropped = doc.isfilecropped;
    docObj.IsWatsappFlag = doc.isWatsappFlag;
    docObj.DocumentNumber = doc.documentNumber;
    docObj.AutoQc = doc.autoqc;
    docObj.IsKycDocument = doc.iskycdocument;
    if (doc.AutoQcWithDocumentId) {
      docObj.AutoQcWithDocumentId = doc.AutoQcWithDocumentId;
    }
    sortedDocuments.push(docObj);
  }
  return sortedDocuments;
}
export const AgetDocumentAutoQcDetails = (
  callback: CallableFunction,
  listOfAutoQcDocument: any,
  applicationId: string,
  documentType: string
) => {
  apiService(
    {
      url: "DIYAPI/GetDocumentAutoQcDetails",
      body: {
        ListAutoQcDocumentDetails: listOfAutoQcDocument,
        ApplicationId: applicationId,
        AutoQcDocumentType: "Selfie",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDocumentAutoQcDetails,
    },
    callback
  );
};

export const ACheckPhotoLiveness = (callback: CallableFunction, base64document: any, leadId?: any) => {
  apiService(
    {
      url: "Idfy/CheckPhotoLiveness",
      body: {
        Base64Document:base64document, 
        LeadId: (leadId) ? leadId : ''
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.checkPhotoLiveness,
    },
    callback
  );
}
  export const ACheckLocation = (callback: CallableFunction, locationObj: any) => {
    apiService(
      {
        url: "Idfy/CheckLocation",
        body: {
          latitude: locationObj.lat,
          longitude: locationObj.lng,
        },
        method: "POST",
        apiBaseUrl: config.diyNewApiUrl,
        tempToken: false,
        serviceName: servicesList.checkLocation,
      },
      callback
    );
  // apiService(
  //   {
  //     url: "eco/maskaadhaar",
  //     body: aadhaarObj,
  //     method: "POST",
  //   },
  //   callback
  // );
}
