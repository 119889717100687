//  localStorage.setItem("isESignSubmitted", btoa(JSON.stringify(eSignCall)));

export const getStateFromLs = () => {
  let isESignSubmitted = localStorage.getItem("isESignSubmitted");
  if (isESignSubmitted !== null && isESignSubmitted !== undefined && isESignSubmitted !== "") {
    return JSON.parse(atob(localStorage.getItem("isESignSubmitted") || ""));
  }
  return null;

};

export const checkESignSubmitted = (applicationId: string) => {
  const state = getStateFromLs();
  if (state !== null && state && state.applicationId == applicationId && state.isESignSubmitted) {
    return true;
  } else {
    return false;
  }
};
