import React, { useState } from "react";
import DeviceContext from "../Services/identifyDevice";
import Button from "../UI/Button/Button";
import "./VideoVerification.scss";
import VideoPlayerIpv from "../UI/VideoPlayerIpv/VideoPlayerIpv";
import Backdrop from "../UI/Backdrop/Backdrop";
import ipvDemo from "../../assets/icons/ipv_demo.jpg";
import GlobalStore from "../store/globalStore";
import {
  AGetIpvOtp,
  ASaveIPVerificationDetails,
  ASendSms,
  AUploadIpv,
  AUploadIpvSafari,
} from "../Services/dashboard/ipvApiServices";
import ErrMsg from "../UI/ErrMsg/ErrMsg";
import {
  completeModuleStatus,
  incompleteStageNavigation,
  setStageData,
} from "../Services/globalService";
import { dashboardUrl, watsappNo, geoLocationConfig } from "../utils/constants";
import { useHistory } from "react-router-dom";
import gtmService from "../Services/gtmService";
import useQuery from "../../customHooks/useQuery";
import cleverTapEvents from "../../config/cleverTapEvents";
import { ClevertapService } from "../Services/clevertapService";
import { AppsFlyerService } from "../Services/appsFlyerService";
import appsFlyerEvents from "../../config/appsFlyerEvents";
import { AGetClientDetailsByPan } from "../Services/registration/panDobApiService";
import Toggle from "../UI/Toggle/Toggle";
import watsAppIcon from "../../assets/icons/watsapp.png";
import { AGetStageCompletionStatus } from "../Services/registration/continueApiService";
import config from "../../config/domain";
import useGeoLocation from "../../customHooks/useGeoLocation";
import InfoModal from "../UI/InfoModal/InfoModal";

export interface iGeoLocation {
  loaded: boolean;
  coordinates: { lat: string; lng: string };
  error: boolean;
}

export interface VideoVerificationProps {}

const VideoVerification: React.FC<VideoVerificationProps> = () => {
  // Fetch query params for clervertap
  const queryParams = useQuery();
  const UTM_SOURCE = queryParams.get("utm_source")
    ? queryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = queryParams.get("utm_medium")
    ? queryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = queryParams.get("utm_campaign")
    ? queryParams.get("utm_campaign")
    : "";
  const id = queryParams.get("id") ? queryParams.get("id") : "";

  const [errCode, setErrCode] = React.useState<number | string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSample, setShowSample] = React.useState(false);
  const [otp, setOtp] = React.useState<string>("");
  const [enableLink, setEnableLink] = React.useState<boolean>(false);
  const [eSignCompleted, setESignCompleted] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [retryLocation, setRetryLocation] = useState(false);
  const history = useHistory();
  let intervalTimer: any;

  const geoLoc: iGeoLocation = useGeoLocation();
  const [isGeoError, setIsGeoError] = React.useState<boolean>(false);
  const [isGeoErrorTimeFlag, setIsGeoErrorTimeFlag] =
    React.useState<boolean>(false);
  const [showBrowserPermissionModal, setsShowBrowserPermissionModal] =
    useState<boolean>(false);
  const [browserPermission, setBrowserPermission] = useState<boolean>(true);
  const [locationPermission, setLocationPermission] = useState<boolean>(true);
  const [cameraPermission, setCameraPermission] = useState<boolean>(true);
  let watchPositionId: number;

  React.useEffect(() => {
    window.sessionStorage.setItem("stageInfoId", "7");
    setTimeout(() => {
      watchPositionId = navigator.geolocation.watchPosition(
        successPosition,
        errorPosition
      );
      setIsGeoErrorTimeFlag(true);
    }, geoLocationConfig.errorTimer);
    setIsLoading(true);
    if (!id) AGetIpvOtp(getIpvOtp, GlobalStore.applicationID);
    else {
      const pan = atob(id);
      console.log(pan);
      setIsLoading(true);
      AGetClientDetailsByPan(searchByPan, pan);
    }
  }, []);

  React.useEffect(() => {
    watchPositionId = navigator.geolocation.watchPosition(
      successPosition,
      errorPosition
    );
    if (locationPermission === true && cameraPermission === true) {
      console.log("inside setter ");
      setBrowserPermission(true);
      setsShowBrowserPermissionModal(false);
      setIsGeoError(false);
    } else {
      console.log("inside setter else");
      setBrowserPermission(false);
      setsShowBrowserPermissionModal(true);
      setIsGeoError(true);
    }
    // getLocation();
  }, [geoLoc, locationPermission, cameraPermission]);

  // React.useEffect(() => {
  //     // navigator.geolocation.watchPosition(successPosition, errorPosition)
  // }, [lat, long]);

  // Clervertap event: Profiler/Investment screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.ipvPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);
  }, []);

  const searchByPan = (res: any) => {
    if (!res || res.error) {
      // setErrCode("118 - Error in fetching Client details from our system.");
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data) {
        if (res.data != null) {
          GlobalStore.personaldetails.pan = res.data.PAN;
          GlobalStore.token = res.data.Token;
          GlobalStore.applicationID = res.data.ApplicatoinId;
          GlobalStore.leadId = res.data.LeadId;
          GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;
        }
        AGetStageCompletionStatus(
          (res: any) => getClientStageDetails(res),
          GlobalStore.applicationID
        );
        AGetIpvOtp(getIpvOtp, GlobalStore.applicationID);
      } else {
        setErrCode("1182 - Error in fetching Client details from our system.");
        setIsLoading(false);
      }
    }
  };

  const getLocation = () => {
    if (geoLoc.loaded && geoLoc.error == false) {
      console.log("I'm here");
      setLat(`${geoLoc.coordinates.lat}`);
      setLong(`${geoLoc.coordinates.lng}`);
    } else {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(setLocation, errorLocation, {
          enableHighAccuracy: geoLocationConfig.enableHighAccuracy,
          maximumAge: geoLocationConfig.maximumAge,
          timeout: geoLocationConfig.timeout,
        });
        watchPositionId = navigator.geolocation.watchPosition(
          successPosition,
          errorPosition
        );
      }
    }
  };

  const setLocation = (position: { coords: any }) => {
    console.log("Set Location", position.coords);
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);

    geoLoc.coordinates.lat = position.coords.latitude;
    geoLoc.coordinates.lng = position.coords.longitude;
    geoLoc.error = false;
    setIsGeoError(false);
    setIsGeoErrorTimeFlag(false);
    setIsLoading(false);
    console.log("In saveLocation", geoLoc);
  };

  const errorLocation = (err: any) => {
    console.log(`ERROR(${err.code}): ${err.message}`);
    geoLoc.error = true;
    setIsLoading(true);
    setIsGeoError(true);
    // getLocation();
  };

  const iPVerificationSaved = (res: any,isFromSMS?:string) => {
    if (!res || res.error) {
      // setErrCode("Error in saving geo-location details");
      setErrCode(res.error);
      gtmService({
        action: "SaveIpverificationDetails",
        label: "ERROR || ERR150",
      });
      setIsLoading(false);
    } else {
      gtmService({
        action: "SaveIpverificationDetails",
        label: "ERROR || ERR150-S",
      });
      if(isFromSMS=="fromSMS")
      {
        checkPdStatus();
      }
      else
      {
        completeModuleStatus(dashboardUrl.ipv);
        gtmService({ action: "VIDEO_IPV", label: "Step_Complete" });
        // Clevertap event: submit documents data
        const data = {
          EventName: cleverTapEvents.registration.ipvPage.CLICK_ON_SUBMIT,
          InitiationPlatform: "",
          UTM_SOURCE: UTM_SOURCE || "",
          UTM_MEDIUM: UTM_MEDIUM || "",
          UTM_CAMPAIGN: UTM_CAMPAIGN || "",
          KRA: GlobalStore.isKRA,
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
        };

        ClevertapService.pushEvent(data);
        // Clevertap ends here

        // Apps flyer event for click on proceed on bank details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.ipvPage.CLICK_ON_SUBMIT
        );

        if (!id) history.push(incompleteStageNavigation());
        else
          alert(
            "IPV uploaded successfully, please close this browser window and continue with your Account Opening process"
          );
      }



    }
  };

  function successPosition(data: any) {
    setIsLoading(false);
    setLocationPermission(true);
    // setBrowserPermission(true);
    // setsShowBrowserPermissionModal(false);
    setLat(data.coords.latitude);
    setLong(data.coords.longitude);
    navigator.geolocation.clearWatch(watchPositionId);
  }

  function saveIpvDetails(data: any) {
    console.log("CALLED HHH");
    console.log("lat",lat);
    console.log("long",long);
    if (lat !== "" && lat !== undefined && long !== "" && long !== undefined) {
      var latitude = lat;
      var longitude = long;
      const ipvObj = {
        leadid: GlobalStore.leadId,
        clientprimarydetailid: GlobalStore.clientPrimaryDetailId,
        applicationid: GlobalStore.applicationID,
        placeofDeclaration: "mumbai",
        empName: "diy user",
        designation: "online rm",
        branch: "mumbai",
        empCode: "0",
        organization: "Nuvama Wealth & investment Ltd",
        organizationCode: "2",
        geo_location: `${latitude}, ${longitude}`,
        ipaddress: "192.168.200.80",
        ipvlink: "",
        latitude: `${latitude}`,
        longitude: `${longitude}`,
        source:"VideoVerification"
      };
      
      console.log("Called  in success");
      //ASaveIPVerificationDetails(iPVerificationSaved, ipvObj);
      ASaveIPVerificationDetails((response:any) => iPVerificationSaved(response), ipvObj);
    } else {
      setsShowBrowserPermissionModal(true);
    }
  }

  function errorPosition(data: any) {
    setIsLoading(true);
    setLocationPermission(false);
    // setBrowserPermission(false);
    // setsShowBrowserPermissionModal(true);
    // if (!browserPermission) {
    //   getLocation();
    // }
  }
  async function turnOnCamera() {
    getLocation();

    const constraints = {
      audio: false,
      video: {
        facingMode: "user",
      },
    };

    function handleSuccess(turnOfCamera: MediaStream) {
      console.log("HANDLE success", turnOfCamera);
      // setBrowserPermission(true);
      // setsShowBrowserPermissionModal(false);
      setCameraPermission(true);
      setIsLoading(false);
    }

    function handleError(error: any) {
      console.log("HANDLE ERROR", error);
      setIsLoading(true);
      // setBrowserPermission(false);
      // setsShowBrowserPermissionModal(true);
      setCameraPermission(false);
      console.log("handle error line end");
      // if (!browserPermission) {
      //   turnOnCamera();
      // }
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        console.log(stream, "then");
        handleSuccess(stream);
      })
      .catch((stream) => {
        console.log(stream, "catch");
        handleError(stream);
      });
  }
  function getIpvOtp(res: any) {
    if (!res || res.error) {
      // setErrCode("456 - Error in getting IPV OTP");
      setErrCode(res.error);
      gtmService({ action: "GetIPVOTP", label: "ERROR || ERR149" });
    } else {
      if (res.data && res.data.OTP) {
        setOtp(res.data.OTP);
        getLocation();
      } else {
        gtmService({ action: "GetIPVOTP", label: "ERROR || ERR149-S" });
      }
    }
    setIsLoading(false);
  }

  function ipvUploaded(uploadObj: any, isSafari: boolean) {
    console.log("lat",lat);
    console.log("long",long);
    if (lat !== "" && lat !== undefined && long !== "" && long !== undefined)
    {
    console.log("uploadObj", uploadObj);
    setIsLoading(true);
    if (isSafari) {
      AUploadIpvSafari(videoUploaded, uploadObj);
    } else {
      AUploadIpv(videoUploaded, uploadObj);
    }
  }
  else
  {
    setsShowBrowserPermissionModal(true);
  }
  }

  function videoUploaded(res: any) {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode("980 - Error in uploading video");
      gtmService({ action: "UploadIPVMerge", label: "ERROR || ERR151" });
      setErrCode(res.error);
    } else {
      if (res.data) {
        
        saveIpvDetails({ coords: {} });
        // completeModuleStatus(dashboardUrl.ipv);
        // gtmService({ action: "VIDEO_IPV", label: "Step_Complete" });
        // // Clevertap event: submit documents data
        // const data = {
        //   EventName: cleverTapEvents.registration.ipvPage.CLICK_ON_SUBMIT,
        //   InitiationPlatform: "",
        //   UTM_SOURCE: UTM_SOURCE || "",
        //   UTM_MEDIUM: UTM_MEDIUM || "",
        //   UTM_CAMPAIGN: UTM_CAMPAIGN || "",
        //   KRA: GlobalStore.isKRA,
        //   DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
        //   ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
        //   LEAD_ID: GlobalStore.leadId,
        //   REF_ID: GlobalStore.applicationID,
        // };

        // ClevertapService.pushEvent(data);
        // // Clevertap ends here

        // // Apps flyer event for click on proceed on bank details page
        // AppsFlyerService.pushEvent(
        //   appsFlyerEvents.registration.ipvPage.CLICK_ON_SUBMIT
        // );

        // if (!id) history.push(incompleteStageNavigation());
        // else
        //   alert(
        //     "IPV uploaded successfully, please close this browser window and continue with your Account Opening process"
        //   );
      } else {
        setIsLoading(false);
        setErrCode("981 - Error in uploading video");
        gtmService({ action: "UploadIPVMerge", label: "ERROR || ERR151-S" });
      }
    }
  }

  const sendSms = (val: string) => {
    console.log("lat",lat);
    console.log("long",long);
    if (lat !== "" && lat !== undefined && long !== "" && long !== undefined) {
      if (enableLink === true) {
        setEnableLink(false);
      } else {
        setEnableLink(true);
        // Create and send link for capturing IPV from mobile
        const encodedPan = btoa(`${GlobalStore.personaldetails.pan}`);
        const stringParams = `ipvLink?id=${encodedPan}`;
        const ipvUrl = `${config.root}${stringParams}`;

        console.log(ipvUrl);
        const smsBody = {
          mobNo: GlobalStore.user.mobile,
          selfieLinkText: ipvUrl,
          // selfieLinkText: `Give a Smile and complete your IPV process for Online Account Opening, click here ${ipvUrl} to complete the process. Regards, Nuvama`,
        };
        ASendSms(() => {}, smsBody);

        const ipvObj = {
          leadid: GlobalStore.leadId,
          clientprimarydetailid: GlobalStore.clientPrimaryDetailId,
          applicationid: GlobalStore.applicationID,
          placeofDeclaration: "mumbai",
          empName: "diy user",
          designation: "online rm",
          branch: "mumbai",
          empCode: "0",
          organization: "Nuvama Wealth & investment Ltd",
          organizationCode: "2",
          geo_location: `${lat}, ${long}`,
          ipaddress: "192.168.200.80",
          ipvlink: ipvUrl,
          latitude: `${lat}`,
          longitude: `${long}`,
          source:"VideoVerification 2"

        };
        console.log("called here in send SMS");
        //ASaveIPVerificationDetails(iPVerificationSaved, ipvObj);
        ASaveIPVerificationDetails((response:any) => iPVerificationSaved(response,"fromSMS"), ipvObj);
        
      }
    } else {
      setsShowBrowserPermissionModal(true);
    }
  };

  function checkPdStatus() {
    const timer = setInterval(() => {
      AGetStageCompletionStatus(
        getClientStageDetails,
        GlobalStore.applicationID
      );
    }, 10000);
    intervalTimer = timer;
  }

  function getClientStageDetails(res: any) {
    if (!res || res.error) {
    } else if (res.data) {
      const responseObj = JSON.parse(res.data);
      setStageData(responseObj);
      if (responseObj.isesigncompleted) {
        setIsLoading(false);
        console.log("Esign Completed");
        setESignCompleted(true);
      } else if (responseObj.isipvarificationcompleted) {
        setIsLoading(false);
        clearInterval(intervalTimer);
        // history.push(incompleteStageNavigation());
        if (!id) history.push(dashboardUrl.profiler);
      }
    }
  }

  return (
    <>
      <div className="mgt40">
        <div className="fieldLblRight">
          <div className="explain">
            {showBrowserPermissionModal && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InfoModal width={DeviceContext().isMobile ? "328px" : "354px"}>
                  <div className="explainInfo w-100">
                    <label>
                      To open your account online, we would mandatorily need
                      access to your geo locations and camera. In your browser
                      notification, click on 'Allow' to proceed ahead
                    </label>
                  </div>
                  <div>
                    <Button
                      btnName="OK"
                      onClick={() => {
                        setsShowBrowserPermissionModal(false);
                        turnOnCamera();
                        // setsShowBrowserPermissionModal(false);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </InfoModal>
              </div>
            )}
          </div>
        </div>
      </div>
      <Backdrop
        isCloseIcon={true}
        isShow={showSample}
        onClose={() => setShowSample(false)}
      >
        <div className="ipvDemo">
          <img className="ipvDemoImg" src={ipvDemo} alt="ipvDemo" />
          <div className="ipvDemoInfo">
            <label className="heading">Video Verification</label>
            <label className="info">
              Write down the OTP on a white paper and hold it in front of the
              camera.
            </label>
          </div>
        </div>
      </Backdrop>
      <fieldset disabled={isLoading}>
        <div
          className={
            "ipvHome" +
            (isLoading ? " op05" : "") +
            (id ? "" : " ml100") +
            (eSignCompleted ? " readOnly" : "")
          }
        >
          <label className="ipvHeading">Video Verification</label>
          <label className="ipvInfo">
            Write down the OTP given below on a white paper and hold it in front
            of the camera.
          </label>
          <label className="ipvSample" onClick={() => setShowSample(true)}>
            View Sample
          </label>
          <div className="ipvOtp">
            <label className="ipvOtpVal">{otp}</label>
          </div>
          {otp && (
            <div className="ipvVideoPlayer">
              <VideoPlayerIpv
                ipvUploaded={ipvUploaded}
                isGeoError={showBrowserPermissionModal}
                isLocationPermission={locationPermission}
                checkGeoLocation={getLocation}
              />
            </div>
          )}

          {isGeoError && !isGeoErrorTimeFlag ? (
            <>
              <div className="geoErrorMsg">
                <p>
                  {" "}
                  There is a delay in loading the contents, please wait for a
                  moment !!
                </p>
              </div>
            </>
          ) : null}

          {isGeoError && isGeoErrorTimeFlag ? (
            <>
              <div className="geoErrorMsg">
                <p>
                  {" "}
                  There was a delay in loading the contents, refresh the page!!{" "}
                </p>
              </div>
            </>
          ) : null}

          {!id && (
            <div className="togContainer mrt40">
              <label className="isTogLabel">
                Send link to capture IPV on Mobile
              </label>
              <div className="togSize">
                <Toggle isOn={enableLink} onChange={() => sendSms("Photo")} />
              </div>
            </div>
          )}
          <div>
            {enableLink && (
              <div className="watsLayout">
                <label className="shareDoc">
                  You will receive a link on Mobile. Once uploaded, we will take
                  you to the next step.
                </label>
                {/* <div className="watsNum">
                                    <label className="watsapp">WhatsApp</label>
                                    <br />
                                    <label className="watsAppNum">
                                        <span>
                                            <img className="watsappImg" src={watsAppIcon} />
                                        </span>
                                        {watsappNo}
                                    </label>
                                </div> */}
              </div>
            )}
          </div>
          {/* Error Msg Start*/}
          {errCode && (
            <div className="errMsgBlck">
              <ErrMsg>
                {typeof errCode === "number" ? (
                  <>
                    <label>We are facing some technical issue.</label>
                    <label>
                      Please try after sometime (error code:{errCode})
                    </label>
                  </>
                ) : (
                  <label>{errCode}</label>
                )}
              </ErrMsg>
            </div>
          )}
          {/* Error Msg End*/}
        </div>
      </fieldset>
    </>
  );
};

export default VideoVerification;
