import { dashboardUrl, ILeftNavBarArray } from "../utils/constants";
import { IUserObj } from "../types/genericTypes";
import { leftNavBarArray } from "../utils/constants";
import {
  CkycAddressFormat,
  IAddressFormat,
} from "../Profile/AddressField/AddressField";
import {
  ckycAddressObj,
  initialAddressObj,
} from "../Profile/ProfileForm/ProfileForm";

interface IPersonaldetails {
  firstname?: string;
  lastname?: string;
  middlename?: string;
  mobile?: string;
  emailId?: string;
  aadhaar?: string;
  pan: string;
  panDetails?: string;
  gender?: "M" | "F" | string;
  dob?: string;
  additionalemail?: string;
  additionalmobile?: string;
  PANName?: string;
  adrLine1?: string;
  adrLine2?: string;
  city?: string;
  state?: string;
  pincode?: string;
}

interface IStageStatus {
  isregistrationcompleted?: boolean;
  ispersonaldetailscompleted: boolean;
  isprofiledetailscompleted: boolean;
  isnewdpnomineecompleted?: boolean;
  isnewdpguardiancompleted?: boolean;
  isbankdetailscompleted: boolean;
  isbrokeragesegmentcompleted?: boolean;
  isuploaddocumentscompleted: boolean;
  isipvarificationcompleted?: boolean;
  isbrokerageschemecompleted?: boolean;
  isagentvisitcompleted?: boolean;
  isesigncompleted: boolean;
  isdigilockercompleted?: boolean;
  isaddressstagecompleted: boolean;
  bpm_pd_status: number;
  bpm_nsb_status: number;
  applicationstatus: number;
  iskra: boolean;
  iskyc: boolean;
  ckycnumber: string;
  iskraesigncompleted: boolean;
  ispanlinkedwithaadhar: boolean;
  checkpanaaddharflag: boolean;
  dpaccounttype: any;
  dpaccountchanged: boolean;
  dob:string;
  isPCGSubLob:boolean;
  panfirstname?:string;
  panmiddletname?:string;
  panlastname?:string;
}

export default class GlobalStore {
  static user: IUserObj = {
    firstname: "",
    lastName: "",
    email: "",
    mobile: "",
    city: "",
    rmCode: "",
    UCC_Code:""
  };
  static isPartialFilledClient: boolean = false;
  static leadForDsa: boolean = false;
  static SaveKYCResponse: any = {};
  static personaldetails: any = {};
  static profileDetails: any = {};
  static LeadIdMsg: string;
  static createLeadDudupeParam: any = {};
  static leadId: string;
  static sessionId: string;
  static dateofbirth: string = "";
  static dob: string = "";
  static birthDate: string = "";
  static applicationID: string = "";
  static aadharLinkedChoice: boolean = false;
  static clientPrimaryDetailId: string = "";
  static clientStageStatus: any = {};
  static dashboardDefaultUrl = dashboardUrl.profile;
  static dashboardActivetUrl = dashboardUrl.profile;
  static leftNavBarState: Array<ILeftNavBarArray> = [...leftNavBarArray];
  static stageStatus: IStageStatus = {
    isregistrationcompleted: false,
    ispersonaldetailscompleted: false,
    isprofiledetailscompleted: false,
    isnewdpnomineecompleted: false,
    isnewdpguardiancompleted: false,
    isbankdetailscompleted: false,
    isbrokeragesegmentcompleted: false,
    isuploaddocumentscompleted: false,
    isipvarificationcompleted: false,
    isbrokerageschemecompleted: false,
    isagentvisitcompleted: false,
    isesigncompleted: false,
    isdigilockercompleted: false,
    isaddressstagecompleted: false,
    bpm_pd_status: 0,
    bpm_nsb_status: 0,
    applicationstatus: 0,
    iskra: false,
    iskyc: false,
    ckycnumber: "",
    iskraesigncompleted: false,
    ispanlinkedwithaadhar: false,
    checkpanaaddharflag: false,
    dpaccounttype: "",
    dpaccountchanged: false,
    dob:"",
    isPCGSubLob:false
  };
  static isDigiLocker: boolean = false;
  static isKRA: boolean = false;
  static isKYC: boolean = false;
  static isManual: boolean = false;
  static isNormalClient: boolean = false;
  static isPANVerified: boolean = false;
  static isSeniorCitizen: boolean = false;
  static panOCRObj: any = {};
  static chequeOCRObj: any = {};
  static aadhaarOCRObj: any = {};
  static passportOCRObj: any = {};
  static dlOCRObj: any = {};
  static voterIdOCRObj: any = {};
  static corrAadhaarOCRObj: any = {};
  static corrPassportOCRObj: any = {};
  static corrDlOCRObj: any = {};
  static corrVoterIdOCRObj: any = {};
  static accountHolderName: string = "";
  static isSeeding: boolean = false;
  static selfieUrl: string = "";
  static nextIncompleteStage: string = "";
  static kraInfoObject: CkycAddressFormat = { ...ckycAddressObj };
  static ckycInfoObject: CkycAddressFormat = { ...ckycAddressObj };
  static ckycNo: string = "";
  static token: string = "";
  static account_no: string = "";
  static ifsc_code: string = "";
  static location: any = "";
  static KRA_mobile?: string;
  static KRA_email?: string;
  static KRA_mode?: number;
  static fieldErrorBank: Array<any> = [
    // errCode1: "test errror",
  ];
}
