import React from 'react'
import EdelLogoCenter from '../../UI/EdelLogoCenter/EdelLogoCenter'
import './Layout.scss'

export interface StatusLayoutProps {}

const RegistrationLayout: React.FC<StatusLayoutProps> = (props) => {
    return (
        <>
            <div className='statusLayout'>
                <EdelLogoCenter />
                {props.children}
            </div>
        </>
    )
}

export default RegistrationLayout