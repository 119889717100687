import { IListObj } from "../types/genericTypes";
import profileNavLogo from "../../assets/icons/profileNavLogo.svg";
import bankNavLogo from "../../assets/icons/bankNavLogo.svg";
import planNavLogo from "../../assets/icons/planNavLogo.svg";
import docNavLogo from "../../assets/icons/docNavLogo.svg";
import ipvNavLogo from "../../assets/icons/ipvNavLogo.svg";
import esignNavLogo from "../../assets/icons/esignNavLogo.svg";
import investNavLogo from "../../assets/icons/investNavLogo.svg";
import profileNavLogoNew from "../../assets/icons/profileNavLogoNew.svg";
import bankNavLogoNew from "../../assets/icons/bankNavLogoNew.svg";
import planNavLogoNew from "../../assets/icons/planNavLogoNew.svg";
import docNavLogoNew from "../../assets/icons/docNavLogoNew.svg";
import esignNavLogoNew from "../../assets/icons/esignNavLogoNew.svg";
import investNavLogoNew from "../../assets/icons/investNavLogoNew.svg";

export const emailVerificationBody = {
  mailBody: "Please collect your OTP",
  mailFrom: "contact@nuvama.com",
  mailSource: "DIY",
  mailSubject: "Enter OTP to authenticate registered email id",
};

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dateOptionsList = [
  { value: "01" },
  { value: "02" },
  { value: "03" },
  { value: "04" },
  { value: "05" },
  { value: "06" },
  { value: "07" },
  { value: "08" },
  { value: "09" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
  { value: "21" },
  { value: "22" },
  { value: "23" },
  { value: "24" },
  { value: "25" },
  { value: "26" },
  { value: "27" },
  { value: "28" },
  { value: "29" },
  { value: "30" },
  { value: "31" },
];

export const monthOptionsList = [
  { value: "01", display: "JAN" },
  { value: "02", display: "FEB" },
  { value: "03", display: "MAR" },
  { value: "04", display: "APR" },
  { value: "05", display: "MAY" },
  { value: "06", display: "JUN" },
  { value: "07", display: "JUL" },
  { value: "08", display: "AUG" },
  { value: "09", display: "SEP" },
  { value: "10", display: "OCT" },
  { value: "11", display: "NOV" },
  { value: "12", display: "DEC" },
];

export const yearOptionsList = (minAge?: number, maxAge?: number) => {
  let currentYear = new Date().getFullYear();
  currentYear = currentYear - (minAge || 0);
  let yearArr: any = [];
  for (let i = 0; i < (maxAge || 100); i++) {
    let obj: any = {};
    obj["value"] = (currentYear - i).toString();
    yearArr.push(obj);
  }
  return yearArr;
};

export const dashboardUrl = {
  personalIfo: "/personalInformation",
  profile: "/profile",
  bank: "/bank",
  plan: "/plan",
  profiler: "/profiler",
  doc: "/verify_personal_documents",
  ipv: "/ipv",
  menu: "/menu",
  eSign: "/esign",
};

export const geoLocationConfig = {
  timeout: 3000,
  enableHighAccuracy: false,
  maximumAge: 0,
  errorTimer: 20000,
};

export interface ILeftNavBarArray {
  name: string;
  time: string;
  isComplete: boolean;
  navUrl: string;
  percentWeight: number;
  priority: number;
  logo: string;
  logoNew?: string;
  isRequired: boolean;
}

export const ipvNavBarObj: ILeftNavBarArray = {
  name: "Video IPV",
  time: "30 Sec",
  isComplete: false,
  navUrl: dashboardUrl.ipv,
  priority: 5,
  percentWeight: 2,
  logo: ipvNavLogo,
  isRequired: true,
};

export const leftNavBarArray: Array<ILeftNavBarArray> = [
  {
    name: "Personal Documents",
    time: "5 mins",
    isComplete: false,
    navUrl: dashboardUrl.personalIfo,
    priority: 1,
    percentWeight: 20,
    logo: profileNavLogo,
    isRequired: true,
  },
  {
    name: "Profile",
    time: "5 mins",
    isComplete: false,
    navUrl: dashboardUrl.profile,
    priority: 1,
    percentWeight: 20,
    logo: profileNavLogo,
    logoNew: profileNavLogoNew,
    isRequired: true,
  },
  {
    name: "Bank Details",
    time: "3 mins",
    isComplete: false,
    navUrl: dashboardUrl.bank,
    priority: 2,
    percentWeight: 20,
    logo: bankNavLogo,
    logoNew: bankNavLogoNew,
    isRequired: true,
  },
  {
    name: "Product & Pricing",
    time: "2 mins",
    isComplete: false,
    navUrl: dashboardUrl.plan,
    priority: 3,
    percentWeight: 20,
    logo: planNavLogo,
    logoNew: planNavLogoNew,
    isRequired: true,
  },
  {
    name: "Additional Profile Information",
    time: "1 min",
    isComplete: false,
    navUrl: dashboardUrl.profiler,
    priority: 6,
    percentWeight: 20,
    logo: investNavLogo,
    logoNew: investNavLogoNew,
    isRequired: false,
  },
  {
    name: "Verify Personal Documents",
    time: "5 mins",
    isComplete: false,
    navUrl: dashboardUrl.doc,
    priority: 4,
    percentWeight: 20,
    logo: docNavLogo,
    logoNew: docNavLogoNew,
    isRequired: true,
  },
  {
    name: "E-Sign",
    time: "3 mins",
    isComplete: false,
    navUrl: dashboardUrl.eSign,
    priority: 7,
    percentWeight: 20,
    logo: esignNavLogo,
    logoNew: esignNavLogoNew,
    isRequired: false,
  },
];

export const fatherSpouseRelationList = [
  { value: "01", display: "Father" },
  { value: "02", display: "Spouse" },
];

export const containerUrl = {
  registration: "/",
  continue: "/continue",
  pan: "/pan",
  otp: "/otp",
  accexists: "/accexists",
  dgLocker: "/dgLocker",
  showDocuments: "/showDocuments",
  mailVerified: "/mailVerified",
  error: "/error",
  nri: "/nri",
  ocv: "/ocv",
  submit: "/submit",
  poa: "/poa",
  partner: "/partner",
  selfie: "/selfie",
};

export const statusUrl = {
  statusHome: "/status",
  statusVerified: "/status/already-exists",
  accountNotFound: "/status/not-found",
  docVerificationInProgress: "/status/doc-verification-in-progress",
  documentReject: "/status/doc-reject",
  statusContinue: "/status/continue",
};

export const ipvDocObj = {
  documentid: 365,
  documentcategory: "In Person Verification",
  documentname: "In Person Verification",
  documentcategoryid: 62,
  typeofaccount: null,
  entitytype: "INDIVIDUAL",
  contenttype: "IN PERSON VERIFICATION",
  documenttype: "IPV",
  ocr_flag: false,
};

export const loginUrl = "https://www.nuvamawealth.com/";
export const digiLockerUrl =
  "https://api.digitallocker.gov.in/public/oauth2/1/authorize";
export const checkAadharLinkedUrl =
  "https://resident.uidai.gov.in/verify-email-mobile";
export const digiLockerRegisterUrl =
  "https://accounts.digitallocker.gov.in/signup";

export const assignedTo = "0"; //hardCode
export const productCode = "TD"; //hardCode
export const addressType = "02"; //hardCode
export const clientGroup4 = "CAS"; //hardCode
export const retailAccCategory = "15"; //hardCode Account category for Retail user
export const employeeAccCategory = "17"; //hardCode Account category for nuvama Employee
export const PCGAccCategory = "12"; //hardCode Account category for HNI employee

export const incomeProofList = [
  { value: "latestSalarySlip", name: "Salary Slip", documentid: "126" },
  { value: "latestForm16", name: "Form 16", documentid: "127" },
  { value: "ITRacknoledgement", name: "ITR Acknoledgement", documentid: "128" },
  { value: "bankStatement", name: "Bank statement", documentid: "129" },
  {
    value: "dematHoldingStatement",
    name: "Demat Holding Statement",
    documentid: "130",
  },
  {
    value: "etworthCertificate",
    name: "Networth Certificate",
    documentid: "131",
  },
];

export const nriIdType: Array<IListObj> = [
  { value: "TIN", display: "TIN" },
  { value: "Others", display: "Others" },
];

export const ecomAgentTimeList: Array<IListObj> = [
  { value: "1", display: "08AM To 12PM" },
  { value: "2", display: "12PM To 04PM" },
  { value: "3", display: "04PM To 08PM" },
];

export const edelweissOfficeName = "Nuvama Wealth & investment Ltd";
export const edelweissOfficeAddress =
  "C/O. SSAI,CASA BLANCA, UNIT NO. - 803-805, SECTOR 11, PLOT NO. 45, OPP. IDBI TOWERS, NR. CBD BELAPUR STATION, CBD BELAPUR PIN CODE: 400614";

export const panDocumentId = 1;
export const photographId = 2;
export const DocumentCategoryIdForphotographId = 1;
export const firstChequeDocumnetId = 21;
export const secondChequeDocumnetId = 357;
export const thirdChequeDocumnetId = 359;
export const fourthChequeDocumnetId = 383;
export const fifthChequeDocumnetId = 385;

export const firstBankDocumentCategoryId = 9;
export const secondBankDocumentCategoryId = 60;
export const thirdBankDocumentCategoryId = 61;
export const fourthBankDocumentCategoryId = 70;
export const fifthBankDocumentCategoryId = 71;

export const firstBankStatementDocumnetId = 22;
export const secondBankStatementDocumnetId = 358;
export const thirdBankStatementDocumnetId = 360;
export const fourthBankStatementDocumnetId = 384;
export const fifthBankStatementDocumnetId = 386;


export const signatureDocumentId = 305;

export const aadhaarDocumnetId = 117;
export const passportDocumnetId = 114;
export const voterIdDocumentId = 116;
export const dlDocumentId = 118;

export const corrAadhaarDocumnetId = 10;
export const corrPassportDocumnetId = 11;
export const corrVoterIdDocumentId = 12;
export const corrDlDocumentId = 13;

export const DIY = "DIY";

export const subsource_ID = 38;

// checked in if condition
export const edelweissLite = "Nuvama Lite";
export const edelweissElite = "Nuvama Elite";

export const watsappNo = "7304470488";

export const poaTrueMsg =
  "DDPI is an authorization to access your demat account to meet pay-in obligations for settlement of trades executed by you";

export const poaFalseMsg = "The settlement would be done through e-DIS.";
