import React, { useEffect, useState } from "react";
import DeviceContext from "../../Services/identifyDevice";
import "../../UI/DocumentBrowse/DocumentBrowse.scss";

import Button, {
  SubmitButton,
  TransparentButton,
} from "../../UI/Button/Button";
import "./DocVerificationForm.scss";
import watsAppIcon from "../../../assets/icons/watsapp.png";
import watsIcon from "../../../assets/icons/Whatsapp_blue.png";
import uploadIcon from "../../../assets/icons/Upload _Blue.png";
import {
  AGetDocumentList,
  AGetPendingDocument,
  AGetUploadedDocument,
  AGetDocStatus,
  ADownloadDocument,
  ASaveDocumentDetails,
  AextractPan,
  AextractCheque,
  AextractAadhaar,
  AextractPassport,
  AextractVoterid,
  AextractDl,
  Afacecompare,
  AgetPdf,
  AcreateApplicationTicket,
  AmaskAadhaar,
  AgetDocumentAutoQcDetails,
  AextractBankStatement,
  ACheckLocation,
  ACheckPhotoLiveness,
} from "../../Services/dashboard/docVerificationAPIServices";
import DocumentBrowse, {
  DocDetailFormat,
} from "../../UI/DocumentBrowse/DocumentBrowse";
import Toggle from "../../UI/Toggle/Toggle";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import GlobalStore from "../../store/globalStore";
import { AUploadDocument } from "../../Services/dashboard/brokerageServices";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import {
  aadhaarDocumnetId,
  firstChequeDocumnetId,
  secondChequeDocumnetId,
  thirdChequeDocumnetId,
  fourthChequeDocumnetId,
  fifthChequeDocumnetId,
  firstBankStatementDocumnetId,
  secondBankStatementDocumnetId,
  thirdBankStatementDocumnetId,
  fourthBankStatementDocumnetId,
  fifthBankStatementDocumnetId,
  DIY,
  dlDocumentId,
  panDocumentId,
  passportDocumnetId,
  voterIdDocumentId,
  dashboardUrl,
  corrAadhaarDocumnetId,
  corrPassportDocumnetId,
  corrVoterIdDocumentId,
  corrDlDocumentId,
  watsappNo,
  geoLocationConfig,
  photographId,
  DocumentCategoryIdForphotographId,
  signatureDocumentId,
  ipvNavBarObj,
} from "../../utils/constants";
import {
  completeModuleStatus,
  utmDurationInfoObj,
  incompleteStageNavigation,
} from "../../Services/globalService";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import { useHistory } from "react-router-dom";
import gtmService from "../../Services/gtmService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import { ClevertapService } from "../../Services/clevertapService";
import Backdrop from "../../UI/Backdrop/Backdrop";
import isSeeding from "../../../assets/icons/seeding.png";
import { AgetBeneficiaryName } from "../../Services/dashboard/bankApiServices";
import EdelLogoCenter from "../../UI/EdelLogoCenter/EdelLogoCenter";
import {
  AGetPanUtiData,
  APanLinkedWithAadhar,
} from "../../Services/registration/panDobApiService";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import { error } from "console";
import { ASaveIPVerificationDetails } from "../../Services/dashboard/ipvApiServices";
import useGeoLocation from "../../../customHooks/useGeoLocation";
import { errorMessage } from "../../utils/errorMessage";
import InfoModal from "../../UI/InfoModal/InfoModal";
import moment from "moment";
import nomineeDocsIds from "../../../config/nomineeDocsId";

//import SignatureCanvas from "react-signature-canvas";
import prevIcon from "../../../assets/icons/eye.png";
import cropIcon from "../../../assets/icons/crop.png";
import NewSelfie from "../../Selfie/NewSelfie";
import { checkESignSubmitted } from "../../utils/eSignSubmitHandler";
export interface DocVerifcationFormProps {
  UTM_SOURCE: string;
  UTM_MEDIUM?: string;
  UTM_CAMPAIGN?: string;
}

export interface iGeoLocation {
  loaded: boolean;
  coordinates: { lat: string; lng: string };
  error: boolean;
}

export interface uploadDocObj {
  applicationid: string;
  base64document: string;
  filename: string;
}

export const initialDocObj: DocDetailFormat = {
  id: "",
  disable: true,
  uploadPer: 0,
  hideProgress: false,
  fileName: "",
  showFile: true,
  imgUrl: "",
  documentcategory: "",
  documentCategoryId: "",
  documentId: 0,
  mimeType: "",
  base64: "",
  isWatsappFlag: false,
};

export const initialPanDocObj: DocDetailFormat = {
  id: "",
  disable: false,
  uploadPer: 0,
  hideProgress: true,
  fileName: "",
  showFile: true,
  imgUrl: "",
  documentcategory: "",
  documentCategoryId: "",
  documentId: 0,
  mimeType: "",
  base64: "",
  iscropped: false,
  isWatsappFlag: false,
};

const DocVerifcationForm: React.FC<DocVerifcationFormProps> = (props) => {
  const history = useHistory();

  const [captureSigPad, setCaptureSigPad] = React.useState<boolean>(false);
  let sigPad: any = {};
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const [uploadDocFlag, setUploadDocFlag] = useState<boolean>(false);
  const [uploadVaiWatsAppFlag, setUploadViaWatsAppFlag] =
    useState<boolean>(false);
  const [togPAN, setTogPAN] = useState<boolean>(false);
  const [togFirstBank, setTogFirstBank] = useState<boolean>(false);
  const [togSecondBank, setTogSecondBank] = useState<boolean>(false);
  const [togThirdBank, setTogThirdBank] = useState<boolean>(false);
  const [togFourthBank, setTogFourthBank] = useState<boolean>(false);
  const [togFifthBank, setTogFifthBank] = useState<boolean>(false);

  const [togPermAdd, setTogPermAdd] = useState<boolean>(false);
  const [togCorrAdd, setTogCorrAdd] = useState<boolean>(false);
  const [togSig, setTogSig] = useState<boolean>(false);
  const [togIncome, setTogIncome] = useState<boolean>(false);
  const [firstBankList, setFirstBankList] = useState<any>([]);
  const [secondBankList, setSecondBankList] = useState<any>([]);
  const [thirdBankList, setThirdBankList] = useState<any>([]);
  const [fourthBankList, setFourthBankList] = useState<any>([]);
  const [fifthBankList, setFifthBankList] = useState<any>([]);
  const [permAddList, setPermAddList] = useState<any>([]);
  const [corrAddList, setCorrAddList] = useState<any>([]);
  const [incomeList, setIncomeList] = useState<any>([]);
  const [secondDoc, setSecondDoc] = useState<boolean>(false);
  const [corrSecondDoc, setCorrSecondDoc] = useState<boolean>(false);
  const [pendingDocObj, setPendingDocObj] = useState<any>();
  const [photoObj, setPhotoObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [panObj, setPanObj] = useState<DocDetailFormat>({
    ...initialPanDocObj,
  });
  const [firstBankObj, setFirstBankObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [secondBankObj, setSecondBankObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [thirdBankObj, setThirdBankObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [fourthBankObj, setFourthBankObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [fifthBankObj, setFifthBankObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });

  const [permSecondAddressObj, setPermSecondAddressObj] =
    useState<DocDetailFormat>({ ...initialPanDocObj });
  const [corrSecondAddressObj, setCorrSecondAddressObj] =
    useState<DocDetailFormat>({ ...initialPanDocObj });
  const [permAddressObj, setPermAddressObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [corrAddressObj, setCorrAddressObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [signatureObj, setSignatureObj] = useState<DocDetailFormat>({
    ...initialPanDocObj,
  });
  const [incomeObj, setIncomeObj] = useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [defaultAddress, setDefaultAddress] = useState<string>("");
  const [defaultCorrAddress, setDefaultCorrAddress] = useState<string>("");
  const [defaultFirstBankDoc, setDefaultFirstBankDoc] = useState<string>("");
  const [defaultSecondBankDoc, setDefaultSecondBankDoc] = useState<string>("");
  const [defaultThirdBankDoc, setDefaultThirdBankDoc] = useState<string>("");
  const [defaultFourthBankDoc, setDefaultFourthBankDoc] = useState<string>("");
  const [defaultFifthBankDoc, setDefaultFifthBankDoc] = useState<string>("");
  const [defaultIncomeDoc, setDefaultIncomeDoc] = useState<string>("");
  const [errCode, setErrCode] = React.useState<number | string>("");
  const [showRetake, setShowRetake] = useState<boolean>(false);
  const videoRefElement = React.useRef<HTMLVideoElement>(null);
  const canvasRefElement = React.useRef<HTMLCanvasElement>(null);
  const uploadedPhotoRefElement = React.useRef<HTMLTableSectionElement>(null);
  const [mediaRecorder, setMediaRecorder] = React.useState<MediaRecorder>();
  const [turnOfCamera, setTurnOfCamera] = React.useState<MediaStream>();
  const [showVidPerm, setShowVidPerm] = React.useState<boolean>(false);

  const [autoQc, setAutoQc] = React.useState<boolean>(false);
  const [photoBase64, setPhotoBase64] = React.useState<string>("");
  const [panBase64, setPanBase64] = React.useState<string>("");
  const [bankStage, setBankStage] = React.useState<boolean>(false);
  const [anotherFile, setAnotherFile] = React.useState<boolean>(false);
  const [corrAnotherFile, setCorrAnotherFile] = React.useState<boolean>(false);
  const [panName, setPanName] = React.useState<string>("");
  const [firstBankName, setFirstBankName] = React.useState<string>("");
  const [secondBankName, setSecondBankName] = React.useState<string>("");
  const [thirdBankName, setThirdBankName] = React.useState<string>("");
  const [fourthBankName, setFourthBankName] = React.useState<string>("");
  const [fifthBankName, setFifthBankName] = React.useState<string>("");

  const [firstBankDetails, setFirstBankDetails] = React.useState<any>("");
  const [secondBankDetails, setSecondBankDetails] = React.useState<any>("");
  const [thirdBankDetails, setThirdBankDetails] = React.useState<any>("");
  const [fourthBankDetails, setFourthBankDetails] = React.useState<any>("");
  const [fifthBankDetails, setFifthBankDetails] = React.useState<any>("");

  const [addressName, setAddressName] = React.useState<string>("");
  const [corrAddressName, setCorrAddressName] = React.useState<string>("");
  const [signatureName, setSignatureName] = React.useState<string>("");
  const [incomeName, setIncomeName] = React.useState<string>("");
  const [isPhotoPendingFlag, setIsPhotoPendingFlag] =
    React.useState<boolean>(false);

  const [isNewPhoto, setIsNewPhoto] = useState<boolean>(false);
  const [isNewPan, setIsNewPan] = useState<boolean>(false);
  const [isNewFirstBank, setIsNewFirstBank] = useState<boolean>(false);
  const [isNewSecondBank, setIsNewSecondBank] = useState<boolean>(false);
  const [isNewThirdBank, setIsNewThirdBank] = useState<boolean>(false);
  const [isNewFourthBank, setIsNewFourthBank] = useState<boolean>(false);
  const [isNewFifthBank, setIsNewFifthBank] = useState<boolean>(false);

  const [isNewPermAddress, setIsNewPermAddress] = useState<boolean>(false);
  const [isNewCorrAddress, setIsNewCorrAddress] = useState<boolean>(false);
  const [isNewSignature, setIsNewSignature] = useState<boolean>(false);
  const [isNewIncome, setIsNewIncome] = useState<boolean>(false);
  const [loaderSign, setLoaderSign] = React.useState<boolean>(false);
  const [showCorr, setShowCorr] = React.useState<boolean>(false);
  const [isPanAndDobMatch, setIsPanAndDobMatch] = useState<boolean>(true);
  const [seedingPopup, setSeedingPopup] = React.useState<boolean>(
    !GlobalStore.isSeeding
  );
  const [regectedDocStatus, setRegectedDocStatus] = React.useState<
    Array<object>
  >([{}]);
  const [aadharAddress, setAadharAddress] = React.useState<boolean>(true);
  const [corrAadharAddress, setCorrAadharAddress] =
    React.useState<boolean>(true);
  const [location, setLocation] = useState<any>("");
  const geoLoc: iGeoLocation = useGeoLocation();
  const [isGeoError, setIsGeoError] = React.useState<boolean>(false);
  const [isGeoErrorTimeFlag, setIsGeoErrorTimeFlag] =
    React.useState<boolean>(false);

  const [removePanImg, setRemovePanImg] = React.useState<boolean>(false);
  const [isManual, setIsManual] = React.useState<boolean>(false);

  let watchPositionId: number;

  //File specific error msg state

  const [panCardError, setPanCardError] = useState<any>("");
  const [firstBankListError, setFirstBankListError] = useState<any>("");
  const [secondBankListError, setSecondBankListError] = useState<any>("");
  const [thirdBankListError, setThirdBankListError] = useState<any>("");
  const [fourthBankListError, setFourthBankListError] = useState<any>("");
  const [fifthBankListError, setFifthBankListError] = useState<any>("");
  const [permAddListError, setPermAddListError] = useState<any>("");
  const [corrAddListError, setCorrAddListError] = useState<any>("");
  const [incomeListError, setIncomeListError] = useState<any>("");
  const [secondDocError, setSecondDocError] = useState<any>("");
  const [corrSecondDocError, setCorrSecondDocError] = useState<any>("");
  const [signatureListError, setSignatureListError] = useState<any>("");
  const [showBrowserPermissionModal, setsShowBrowserPermissionModal] =
    useState<boolean>(false);
  const [isSdkSelfie, setIsSdkSelfie] = useState<boolean>(true);
  const [browserPermission, setBrowserPermission] = useState<boolean>(true);
  const [locationPermission, setLocationPermission] = useState<boolean>(true);
  const [cameraPermission, setCameraPermission] = useState<boolean>(true);
  // To check if a default permanent address is available for the application
  const [defaultPermAddressProof, setDefaultPermAddressProof] = useState(false);
  //permAddButDisable

  const [nomineeFirstObj, setNomineeFirstObj] = useState<DocDetailFormat[]>([
    {
      ...initialDocObj,
      disable: false,
    },
    { ...initialDocObj, disable: false },
  ]);
  const [nomineeSecondObj, setNomineeSecondObj] = useState<DocDetailFormat[]>([
    {
      ...initialDocObj,
      disable: false,
    },
    { ...initialDocObj, disable: false },
  ]);
  const [nomineeThirdObj, setNomineeThirdObj] = useState<DocDetailFormat[]>([
    {
      ...initialDocObj,
      disable: false,
    },
    { ...initialDocObj, disable: false },
  ]);
  const [guardianFirstObj, setGuardianFirstObj] = useState<DocDetailFormat[]>([
    {
      ...initialDocObj,
      disable: false,
    },
    { ...initialDocObj, disable: false },
  ]);
  const [guardianSecondObj, setGuardianSecondObj] = useState<DocDetailFormat[]>(
    [
      {
        ...initialDocObj,
        disable: false,
      },
      { ...initialDocObj, disable: false },
    ]
  );
  const [guardianThirdObj, setGuardianThirdObj] = useState<DocDetailFormat[]>([
    {
      ...initialDocObj,
      disable: false,
    },
    { ...initialDocObj, disable: false },
  ]);
  const [nomineeFirstList, setNomineeFirstList] = useState([]);
  const [nomineeSecondList, setNomineeSecondList] = useState([]);
  const [nomineeThirdList, setNomineeThirdList] = useState([]);
  const [guardianFirstList, setGuardianFirstList] = useState([]);
  const [guardianSecondList, setGuardianSecondList] = useState([]);
  const [guardianThirdList, setGuardianThirdList] = useState([]);
  const [nomineeGuardianTog, setNomineeGuardianTog] = useState({
    nomineeFirst: false,
    nomineeSecond: false,
    nomineeThree: false,
    guardianFirst: false,
    guardianSecond: false,
    guardianThird: false,
  });
  const [nomineeGuardianError, setNomineeGuardianError] = useState({
    nomFirstPhotoId: "",
    nomFirstSignature: "",
    nomSecondPhotoId: "",
    nomSecondSignature: "",
    nomThirdPhotoId: "",
    nomThirdSignature: "",
    guardianFirstPhotoId: "",
    guardianFirstSignature: "",
    guardianSecondPhotoId: "",
    guardianSecondSignature: "",
    guardianThirdPhotoId: "",
    guardianThirdSignature: "",
  });

  const [isNomineeGuardian, setIsNomineeGuardian] = useState({
    isNomFirstPhoto: false,
    isNomFirstSignature: false,
    isNomSecondPhoto: false,
    isNomSecondSignature: false,
    isNomThirdPhoto: false,
    isNomThirdSignature: false,
    isGuardianFirstPhoto: false,
    isGuardianFirstSignature: false,
    isGuardianSecondPhoto: false,
    isGuardianSecondSignature: false,
    isGuardianThirdPhoto: false,
    isGuardianThirdSignature: false,
  });

  const [nomineeDisable, setNomineeDisable] = useState(false);
  const [guardianDisable, setGuardianDisable] = useState(false);

  //permAddButDisable
  const [permAddButDisable, setPermAddButDisable] = useState(false);
  const [panButDisable, setPanButDisable] = useState(false);
  const [liveLocationCheck, setLiveLocationCheck] = useState<any>({
    checkPhotoLiveness: false,
    checkLocation: false,
  });
  const [photoErrorMsg, setPhotoErrorMsg] = React.useState("");

  const [blockAadhar, setBlockAadhar] = React.useState(false);
  const [checkLocationCalled, setCheckLocationCalled] =
    React.useState<boolean>(false);

  const [ShowCanvas, setShowCanvas] = useState(true);

  let isPanLinkedToAadhar: boolean;

  React.useEffect(() => {
    // console.log("loca222", GlobalStore.location);
    window.scrollTo(0, 0);
    if (GlobalStore.location) {
      setLocation(GlobalStore.location);
    } else {
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(saveLocation, errorLocation, {
          enableHighAccuracy: geoLocationConfig.enableHighAccuracy,
          maximumAge: geoLocationConfig.maximumAge,
          timeout: geoLocationConfig.timeout,
        });
        setIsGeoErrorTimeFlag(true);
      }, geoLocationConfig.errorTimer);
    }

    // setIsLoading(true);
    AGetStageCompletionStatus(getClientStageDetails, GlobalStore.applicationID);
    // if kra Client to hide address logic
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    GlobalStore.isKRA || clientPartialDetails_ao.isKRA
      ? setBlockAadhar(true)
      : setBlockAadhar(false);

    // turnOnCamera();
    // showPhotoPreview(false);
    uploadedPhotoRefElement.current!.hidden = true;
  }, []);

  React.useEffect(() => {
    if (location && !checkLocationCalled && !isSdkSelfie) {
      GlobalStore.location = location;
      setIsGeoError(false);
      setIsGeoErrorTimeFlag(false);
      // const locationString;
      const locationObj = {
        lat: location.lat,
        lng: location.long,
      };
      ACheckLocation((res: any) => checkLocationRes(res), locationObj);
    }
  }, [location]);

  React.useEffect(() => {
    if (nomineeFirstList.length > 0) {
      let nomineeOne = [...nomineeFirstObj];
      for (let i = 0; i < nomineeOne.length; i++) {
        if (nomineeOne[i].imgUrl === "" || nomineeOne[i].fileName === "") {
          setNomineeDisable(true);
          break;
        } else {
          setNomineeDisable(false);
        }
      }
    }

    if (nomineeSecondList.length > 0) {
      let nomineeTwo = [...nomineeSecondObj];
      for (let i = 0; i < nomineeTwo.length; i++) {
        if (nomineeTwo[i].imgUrl === "" || nomineeTwo[i].fileName === "") {
          setNomineeDisable(true);
          break;
        } else {
          setNomineeDisable(false);
        }
      }
    }
    if (nomineeThirdList.length > 0) {
      let nomineeThree = [...nomineeThirdObj];
      for (let i = 0; i < nomineeThree.length; i++) {
        if (nomineeThree[i].imgUrl === "" || nomineeThree[i].fileName === "") {
          setNomineeDisable(true);
          break;
        } else {
          setNomineeDisable(false);
        }
      }
    }

    if (guardianFirstList.length > 0) {
      let guardianOne = [...guardianFirstObj];
      for (let i = 0; i < guardianOne.length; i++) {
        if (guardianOne[i].imgUrl === "" || guardianOne[i].fileName === "") {
          setGuardianDisable(true);
          break;
        } else {
          setGuardianDisable(false);
        }
      }
    }
    if (guardianSecondList.length > 0) {
      let guardianTwo = [...guardianSecondObj];
      for (let i = 0; i < guardianTwo.length; i++) {
        if (guardianTwo[i].imgUrl === "" || guardianTwo[i].fileName === "") {
          setGuardianDisable(true);
          break;
        } else {
          setGuardianDisable(false);
        }
      }
    }
    if (guardianThirdList.length > 0) {
      let guardianThree = [...guardianThirdObj];
      for (let i = 0; i < guardianThree.length; i++) {
        if (
          guardianThree[i].imgUrl === "" ||
          guardianThree[i].fileName === ""
        ) {
          setGuardianDisable(true);
          break;
        } else {
          setGuardianDisable(false);
        }
      }
    }
  }, [
    nomineeFirstList,
    nomineeSecondList,
    nomineeThirdList,
    guardianFirstList,
    guardianSecondList,
    guardianThirdList,
    nomineeFirstObj,
    nomineeSecondObj,
    nomineeThirdObj,
    guardianFirstObj,
    guardianSecondObj,
    guardianThirdObj,
  ]);

  React.useEffect(() => {
    console.log("UseEffect called");
    if (!isSdkSelfie) {
      watchPositionId = navigator.geolocation.watchPosition(
        successPosition,
        errorPosition
      );
      if (locationPermission === true && cameraPermission === true) {
        console.log("inside setter ");
        setBrowserPermission(true);
        setsShowBrowserPermissionModal(false);
        setIsGeoError(false);
      } else {
        console.log("inside setter else");
        setBrowserPermission(false);
        setsShowBrowserPermissionModal(true);
        setIsGeoError(true);
      }
    }
    // getLocation();
  }, [geoLoc, locationPermission, cameraPermission, isSdkSelfie]);

  React.useEffect(() => {
    if (photoObj.imgUrl && !isSdkSelfie) {
      ACheckPhotoLiveness(
        (res: any) => getExtractPhotoLiveness(res),
        photoObj.imgUrl.split(",")[1],
        GlobalStore.leadId
      );
    }
  }, [photoObj, isSdkSelfie]);

  React.useEffect(() => {
    return () => {
      turnOfCamera && turnOfCamera.getVideoTracks()[0].stop();
    };
  }, [turnOfCamera]);

  const getLocation = () => {
    if (geoLoc.loaded && geoLoc.error == false) {
      console.log("I'm here-", geoLoc);
      // setLocation(`${geoLoc.coordinates.lat}, ${geoLoc.coordinates.lng}`);
      setLocation({
        lat: geoLoc.coordinates.lat,
        long: geoLoc.coordinates.lng,
      });
    } else {
      if (navigator && navigator.geolocation) {
        console.log("I'm here in else-", navigator);
        navigator.geolocation.getCurrentPosition(saveLocation, errorLocation, {
          enableHighAccuracy: geoLocationConfig.enableHighAccuracy,
          maximumAge: geoLocationConfig.maximumAge,
          timeout: geoLocationConfig.timeout,
        });
        navigator.geolocation.getCurrentPosition(saveLocation);
        watchPositionId = navigator.geolocation.watchPosition(
          successPosition,
          errorPosition
        );
      } else {
        alert("Geo location is not supported by this browser");
      }
    }
  };

  const errorLocation = (err: any) => {
    if (!location && !GlobalStore.location) {
      console.log(location, "errorLoaction function log");
      console.log(`ERROR(${err.code}): ${err.message}`);
      geoLoc.error = true;
      setIsLoading(true);
      setIsGeoError(true);
      // getLocation()
    }
  };

  const saveLocation = (position: { coords: any }) => {
    watchPositionId = navigator.geolocation.watchPosition(
      successPosition,
      errorPosition
    );
    // setLocation(`${position.coords.latitude}, ${position.coords.longitude}`);
    setLocation({
      lat: position.coords.latitude,
      long: position.coords.longitude,
    });
    geoLoc.coordinates.lat = position.coords.latitude;
    geoLoc.coordinates.lng = position.coords.longitude;
    geoLoc.error = false;
    setIsGeoError(false);
    setIsGeoErrorTimeFlag(false);
    setIsLoading(false);
    console.log("In saveLocation", geoLoc);
    // GlobalStore.location = location;
  };

  function successPosition(data: any) {
    console.log("successPosition");
    setIsLoading(false);
    setLocationPermission(true);
    // setBrowserPermission(true);
    // setsShowBrowserPermissionModal(false);
    // setLocation(`${data.coords.latitude}, ${data.coords.longitude}`);
    setLocation({
      lat: data.coords.latitude,
      long: data.coords.longitude,
    });
    navigator.geolocation.clearWatch(watchPositionId);
    // GlobalStore.location = location;
  }

  function errorPosition(data: any) {
    console.log("errorPosition");
    setIsLoading(true);
    setLocationPermission(false);
    // setBrowserPermission(false);
    // setsShowBrowserPermissionModal(true);

    // if (!browserPermission) {
    //   getLocation();
    // }
  }
  const checkLocationRes = (res: any) => {
    if (!res || res.error) {
      console.log("LIVE", res);
      // setErrCode("116 - Error in fetching Pan details from our system.");
      // setErrCode(errorMessage.panDetailsFailed);
      if (errCode !== res.error) setErrCode(errCode ? errCode : "" + res.error);
      setIsLoading(false);
      setLiveLocationCheck({
        ...liveLocationCheck,
        checkLocation: true,
      });
      gtmService({ action: "CheckLocation", label: "ERROR || ERR999" });
    } else {
      setCheckLocationCalled(true);
      if (res.data && res.data.LocationInIndia) {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkLocation: false,
        });
      } else if (!res.data.LocationInIndia) {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkLocation: true,
        });
        if (errCode !== res.data.Message)
          setErrCode(errCode ? errCode : "" + res.data.Message);
      }
      setIsLoading(false);
    }
  };
  function getClientStageDetails(res: any) {
    if (!res || res.error) {
      //   setErrCode("106- Failed to fetch stage completion data.");
      setErrCode(errCode + res.error);
      gtmService({
        action: "GetStageCompletionStatus",
        label: "ERROR || ERR141",
      });
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        if (GlobalStore.isKRA || responseObj.iskra) {
          permAddressObj.disable = true;
          setPermAddressObj(permAddressObj);
          setBlockAadhar(true);
        } else {
          setBlockAadhar(false);
        }
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        clientPartialDetails_ao.isKYC = responseObj.iskyc;
        clientPartialDetails_ao.isKRA = responseObj.iskra;

        setIsManual(clientPartialDetails_ao.isManual);
        clientPartialDetails_ao.isDigiLocker =
          responseObj.isdigilockercompleted;
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        if (responseObj.isbankdetailscompleted) {
          setBankStage(true);
        } else {
          setBankStage(false);
        }

        if (responseObj.ispanlinkedwithaadhar === true) {
          GlobalStore.isSeeding = true;
          setSeedingPopup(false);
        } else if (responseObj.ispanlinkedwithaadhar === false) {
          GlobalStore.isSeeding = false;
          setSeedingPopup(true);
        }
      } else {
        gtmService({
          action: "GetStageCompletionStatus",
          label: "ERROR || ERR141-S",
        });
      }
    }

    AGetDocumentList(getDocList, GlobalStore.applicationID); //2
    AGetUploadedDocument(getUploadedDoc, GlobalStore.applicationID); //4
    AGetPendingDocument(getPendingDoc, GlobalStore.applicationID); //1
    AGetDocStatus(getDocumentStatus, GlobalStore.applicationID); //3
  }

  function showPhotoPreview(showPhotoPreview: boolean) {
    videoRefElement.current!.hidden = showPhotoPreview;
    canvasRefElement.current!.hidden = !showPhotoPreview;
  }

  async function turnOnCamera() {
    getLocation();
    const constraints = {
      audio: false,
      video: {
        facingMode: "user",
      },
    };

    function handleSuccess(turnOfCamera: MediaStream) {
      console.log("HANDLE success", turnOfCamera);
      // setBrowserPermission(true);
      // setsShowBrowserPermissionModal(false);
      setCameraPermission(true);
      setIsLoading(false);
      videoRefElement.current!.srcObject = turnOfCamera;
      setMediaRecorder(new MediaRecorder(turnOfCamera));
      setTurnOfCamera(turnOfCamera);
      setShowVidPerm(false);
    }

    function handleError(error: any) {
      console.log("HANDLE ERROR", error);
      setIsLoading(true);
      // setBrowserPermission(false);
      // setsShowBrowserPermissionModal(true);
      setCameraPermission(false);
      console.log("handle error line end");
      // if (!browserPermission) {
      //   turnOnCamera();
      // }
      setShowVidPerm(true);
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(handleSuccess)
      .catch(handleError);
  }

  const getDocumentStatus = (res: any) => {
    if (!res || res.error) {
      // setErrCode("164- Failed to get the document status for the application.");
      setErrCode(errCode + res.error);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR125" });
    } else {
      if (res.data) {
        const regectedDoc = JSON.parse(res.data).filter(
          (item: any) => item.Status === "Rejected"
        );
        setRegectedDocStatus(regectedDoc);
      } else {
        gtmService({
          action: "GetDocumentDetails",
          label: "ERROR || ERR125-S",
        });
      }
    }
  };

  const getDocList = (res: any) => {
    if (!res || res.error) {
      setErrCode(errCode + res.error);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR121" });
    } else {
      if (res.data) {
        let listObj = JSON.parse(res.data);
        const photoData = listObj.filter(
          (x: any) => x.DocumentCategory === "Photograph"
        );
        const panData = listObj.filter(
          (x: any) => x.DocumentCategory === "PAN Card"
        );
        const firstBankObjlist = listObj.filter(
          (x: any) => x.DocumentCategoryId === 9
        );
        const SecondBankObjlist = listObj.filter(
          (x: any) => x.DocumentCategoryId === 60
        );
        const thirdBankObjlist = listObj.filter(
          (x: any) => x.DocumentCategoryId === 61
        );
        const fourthBankObjlist = listObj.filter(
          (x: any) => x.DocumentCategoryId === 70
        );
        const fifthBankObjlist = listObj.filter(
          (x: any) => x.DocumentCategoryId === 71
        );
        const corrAddObjlist = listObj.filter(
          (x: any) => x.DocumentCategory === "Address Proof Correspondence"
        );
        const permAddObjlist = listObj.filter(
          (x: any) => x.DocumentCategory === "Address Proof Permanent"
        );
        const incomeObjlist = listObj.filter(
          (x: any) => x.DocumentCategory === "Income Proof"
        );
        const signatureData = listObj.filter(
          (x: any) => x.DocumentCategory === "Signature"
        );

        const nomineeFirstList = listObj.filter(
          (x: any) =>
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeOne.photo.documentCategoryId ||
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeOne.signature.documentCategoryId
        );
        const nomineeSecondList = listObj.filter(
          (x: any) =>
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeTwo.photo.documentCategoryId ||
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeTwo.signature.documentCategoryId
        );
        const nomineeThirdList = listObj.filter(
          (x: any) =>
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeThree.photo.documentCategoryId ||
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeThree.signature.documentCategoryId
        );
        const guardianFirstList = listObj.filter(
          (x: any) =>
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeOne.guardianDetails.photo
                .documentCategoryId ||
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeOne.guardianDetails.signature
                .documentCategoryId
        );

        const guardianSecondList = listObj.filter(
          (x: any) =>
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeTwo.guardianDetails.photo
                .documentCategoryId ||
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeTwo.guardianDetails.signature
                .documentCategoryId
        );
        const guardianThirdList = listObj.filter(
          (x: any) =>
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeThree.guardianDetails.photo
                .documentCategoryId ||
            x.DocumentCategoryId ===
              nomineeDocsIds.nomineeThree.guardianDetails.signature
                .documentCategoryId
        );
        if (photoData.length) {
          let photoCopy = { ...photoObj };
          photoCopy.documentCategoryId = photoData[0].DocumentCategoryId;
          photoCopy.documentId = photoData[0].DocumentId;
          photoCopy.documentcategory = photoData[0].DocumentCategory;
          setPhotoObj(photoCopy);
        }

        if (panData.length) {
          let panCopy = { ...panObj };
          panCopy.documentCategoryId = panData[0].DocumentCategoryId;
          panCopy.documentId = panData[0].DocumentId;
          panCopy.documentcategory = panData[0].DocumentCategory;
          setPanObj(panCopy);
        }

        if (firstBankObjlist.length) {
          let firstBankCopy = { ...firstBankObj };
          firstBankCopy.documentCategoryId =
            firstBankObjlist[0].DocumentCategoryId;
          firstBankCopy.documentcategory = firstBankObjlist[0].DocumentCategory;
          setFirstBankObj(firstBankCopy);
        }

        if (SecondBankObjlist.length) {
          let secondBankCopy = { ...secondBankObj };
          secondBankCopy.documentCategoryId =
            SecondBankObjlist[0].DocumentCategoryId;
          secondBankCopy.documentcategory =
            SecondBankObjlist[0].DocumentCategory;
          setSecondBankObj(secondBankCopy);
        }

        if (thirdBankObjlist.length) {
          let thirdBankCopy = { ...thirdBankObj };
          thirdBankCopy.documentCategoryId =
            thirdBankObjlist[0].DocumentCategoryId;
          thirdBankCopy.documentcategory = thirdBankObjlist[0].DocumentCategory;
          setThirdBankObj(thirdBankCopy);
        }

        if (fourthBankObjlist.length) {
          let fourthBankCopy = { ...fourthBankObj };
          fourthBankCopy.documentCategoryId =
            fourthBankObjlist[0].DocumentCategoryId;
          fourthBankCopy.documentcategory =
            fourthBankObjlist[0].DocumentCategory;
          setFourthBankObj(fourthBankCopy);
        }

        if (fifthBankObjlist.length) {
          let fifthBankCopy = { ...fifthBankObj };
          fifthBankCopy.documentCategoryId =
            fifthBankObjlist[0].DocumentCategoryId;
          fifthBankCopy.documentcategory = fifthBankObjlist[0].DocumentCategory;
          setFifthBankObj(fifthBankCopy);
        }

        if (permAddObjlist.length) {
          let permAddressCopy = { ...permAddressObj };
          permAddressCopy.documentcategory = "Address Proof Permanent";
          permAddressCopy.documentCategoryId =
            permAddObjlist[0].DocumentCategoryId;
          setPermAddressObj(permAddressCopy);
        }

        if (corrAddObjlist.length) {
          let corrAddressCopy = { ...corrAddressObj };
          corrAddressCopy.documentcategory = "Address Proof Correspondance";
          corrAddressCopy.documentCategoryId =
            corrAddObjlist[0].DocumentCategoryId;
          setCorrAddressObj(corrAddressCopy);
        }

        if (signatureData.length) {
          let signatureCopy = { ...signatureObj };
          signatureCopy.documentcategory = "Signature";
          signatureCopy.documentCategoryId =
            signatureData[0].DocumentCategoryId;
          signatureCopy.documentId = signatureData[0].DocumentId;
          setSignatureObj(signatureCopy);
        }

        if (incomeObjlist.length) {
          let incomeCopy = { ...incomeObj };
          incomeCopy.documentcategory = "Income Proof";
          incomeCopy.documentCategoryId = incomeObjlist[0].DocumentCategoryId;
          incomeCopy.documentId = incomeObjlist[0].DocumentId;
          setIncomeObj(incomeCopy);
        }
        setErrCode(errCode);
        setFirstBankList(firstBankObjlist);
        setSecondBankList(SecondBankObjlist);
        setThirdBankList(thirdBankObjlist);
        setFourthBankList(fourthBankObjlist);
        setFifthBankList(fifthBankObjlist);
        setPermAddList(permAddObjlist);
        setCorrAddList(corrAddObjlist);
        setIncomeList(incomeObjlist);
        setNomineeFirstList(nomineeFirstList);
        setNomineeSecondList(nomineeSecondList);
        setNomineeThirdList(nomineeThirdList);
        setGuardianFirstList(guardianFirstList);
        setGuardianSecondList(guardianSecondList);
        setGuardianThirdList(guardianThirdList);
      } else {
        // setErrCode(164)
        gtmService({
          action: "GetDocumentDetails",
          label: "ERROR || ERR121-S",
        });
      }
    }
  };

  const getUploadedDoc = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR124" });
    } else if (res.data) {
      const uplDoc = JSON.parse(res.data);
      const isPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategory === "Photograph" && x.DocumentId === photographId
      );

      const isPANCard = uplDoc.filter(
        (x: any) => x.DocumentCategory === "PAN Card"
      );
      const isFirstBankDocument = uplDoc.filter(
        (x: any) => x.DocumentCategoryId === 9
      );
      const isSecondBankDocument = uplDoc.filter(
        (x: any) => x.DocumentCategoryId === 60
      );
      const isThirdBankDocument = uplDoc.filter(
        (x: any) => x.DocumentCategoryId === 61
      );
      const isFourthBankDocument = uplDoc.filter(
        (x: any) => x.DocumentCategoryId === 70
      );
      const isFifthBankDocument = uplDoc.filter(
        (x: any) => x.DocumentCategoryId === 71
      );
      const isCorrAddressProof = uplDoc.filter(
        (x: any) => x.DocumentCategory === "Address Proof Correspondence"
      );
      const isPermAddressProof = uplDoc.filter(
        (x: any) => x.DocumentCategory === "Address Proof Permanent"
      );
      const isSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategory === "Signature" &&
          x.DocumentId == signatureDocumentId
      );
      const isIncomeProof = uplDoc.filter(
        (x: any) => x.DocumentCategory === "Income Proof"
      );
      const isNomineeFirstPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeOne.photo.documentCategoryId
      );
      const isNomineeFirstSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeOne.signature.documentCategoryId
      );
      const isNomineeSecondPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeTwo.photo.documentCategoryId
      );
      const isNomineeSecondSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeTwo.signature.documentCategoryId
      );
      const isNomineeThirdPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeThree.photo.documentCategoryId
      );
      const isNomineeThirdSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeThree.signature.documentCategoryId
      );
      const isGuardianFirstPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeOne.guardianDetails.photo.documentCategoryId
      );
      const isGuardianFirstSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeOne.guardianDetails.signature.documentCategoryId
      );
      const isGuardianSecondPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeTwo.guardianDetails.photo.documentCategoryId
      );
      const isGuardianSecondSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeTwo.guardianDetails.signature.documentCategoryId
      );
      const isGuardianThirdPhoto = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeThree.guardianDetails.photo.documentCategoryId
      );

      const isGuardianThirdSignature = uplDoc.filter(
        (x: any) =>
          x.DocumentCategoryId ===
          nomineeDocsIds.nomineeThree.guardianDetails.signature
            .documentCategoryId
      );

      if (isNomineeFirstPhoto.length > 0) {
        setIsLoading(true);
        let nomineeFirstPhoto = [...nomineeFirstObj];
        nomineeFirstPhoto[0].id = "photo";
        nomineeFirstPhoto[0].disable = false;
        nomineeFirstPhoto[0].uploadPer = 100;
        nomineeFirstPhoto[0].fileName = isNomineeFirstPhoto[0].FileName;
        nomineeFirstPhoto[0].showFile = false;
        nomineeFirstPhoto[0].hideProgress = false;
        nomineeFirstPhoto[0].mimeType = isNomineeFirstPhoto[0].MimeType;
        nomineeFirstPhoto[0].documentCategoryId =
          isNomineeFirstPhoto[0].DocumentCategoryId;
        nomineeFirstPhoto[0].documentId = isNomineeFirstPhoto[0].DocumentId;
        nomineeFirstPhoto[0].documentcategory =
          isNomineeFirstPhoto[0].DocumentCategory;
        setNomineeFirstObj(nomineeFirstPhoto);
        ADownloadDocument(
          (res: any) => NomineeFirstImage(res, "photo"),
          isNomineeFirstPhoto[0].FileName,
          isNomineeFirstPhoto[0].UploadedDocPath
        );
      }
      if (isNomineeFirstSignature.length > 0) {
        setIsLoading(true);
        let nomineeFirstSignature = [...nomineeFirstObj];

        nomineeFirstSignature[1].id = "signature";
        nomineeFirstSignature[1].disable = false;
        nomineeFirstSignature[1].uploadPer = 100;
        nomineeFirstSignature[1].fileName = isNomineeFirstSignature[0].FileName;
        nomineeFirstSignature[1].showFile = false;
        nomineeFirstSignature[1].hideProgress = false;
        nomineeFirstSignature[1].mimeType = isNomineeFirstSignature[0].MimeType;
        nomineeFirstSignature[1].documentCategoryId =
          isNomineeFirstSignature[0].DocumentCategoryId;
        nomineeFirstSignature[1].documentId =
          isNomineeFirstSignature[0].DocumentId;
        nomineeFirstSignature[1].documentcategory =
          isNomineeFirstSignature[0].DocumentCategory;
        setNomineeFirstObj(nomineeFirstSignature);
        ADownloadDocument(
          (res: any) => NomineeFirstImage(res, "signature"),
          isNomineeFirstSignature[0].FileName,
          isNomineeFirstSignature[0].UploadedDocPath
        );
      }
      if (isNomineeSecondPhoto.length > 0) {
        setIsLoading(true);
        let nomineeSecondPhoto = [...nomineeSecondObj];

        nomineeSecondPhoto[0].id = "photo";
        nomineeSecondPhoto[0].disable = false;
        nomineeSecondPhoto[0].uploadPer = 100;
        nomineeSecondPhoto[0].fileName = isNomineeSecondPhoto[0].FileName;
        nomineeSecondPhoto[0].showFile = false;
        nomineeSecondPhoto[0].hideProgress = false;
        nomineeSecondPhoto[0].mimeType = isNomineeSecondPhoto[0].MimeType;
        nomineeSecondPhoto[0].documentCategoryId =
          isNomineeSecondPhoto[0].DocumentCategoryId;
        nomineeSecondPhoto[0].documentId = isNomineeSecondPhoto[0].DocumentId;
        nomineeSecondPhoto[0].documentcategory =
          isNomineeSecondPhoto[0].DocumentCategory;
        setNomineeSecondObj(nomineeSecondPhoto);
        ADownloadDocument(
          (res: any) => NomineeSecondImage(res, "photo"),
          isNomineeSecondPhoto[0].FileName,
          isNomineeSecondPhoto[0].UploadedDocPath
        );
      }
      if (isNomineeSecondSignature.length > 0) {
        setIsLoading(true);
        let nomineeSecondSignature = [...nomineeSecondObj];

        nomineeSecondSignature[1].id = "signature";
        nomineeSecondSignature[1].disable = false;
        nomineeSecondSignature[1].uploadPer = 100;
        nomineeSecondSignature[1].fileName =
          isNomineeSecondSignature[0].FileName;
        nomineeSecondSignature[1].showFile = false;
        nomineeSecondSignature[1].hideProgress = false;
        nomineeSecondSignature[1].mimeType =
          isNomineeSecondSignature[0].MimeType;
        nomineeSecondSignature[1].documentCategoryId =
          isNomineeSecondSignature[0].DocumentCategoryId;
        nomineeSecondSignature[1].documentId =
          isNomineeSecondSignature[0].DocumentId;
        nomineeSecondSignature[1].documentcategory =
          isNomineeSecondSignature[0].DocumentCategory;
        setNomineeSecondObj(nomineeSecondSignature);
        ADownloadDocument(
          (res: any) => NomineeSecondImage(res, "signature"),
          isNomineeSecondSignature[0].FileName,
          isNomineeSecondSignature[0].UploadedDocPath
        );
      }
      if (isNomineeThirdPhoto.length > 0) {
        setIsLoading(true);
        let nomineeThirdPhoto = [...nomineeThirdObj];

        nomineeThirdPhoto[0].id = "photo";
        nomineeThirdPhoto[0].disable = false;
        nomineeThirdPhoto[0].uploadPer = 100;
        nomineeThirdPhoto[0].fileName = isNomineeThirdPhoto[0].FileName;
        nomineeThirdPhoto[0].showFile = false;
        nomineeThirdPhoto[0].hideProgress = false;
        nomineeThirdPhoto[0].mimeType = isNomineeThirdPhoto[0].MimeType;
        nomineeThirdPhoto[0].documentCategoryId =
          isNomineeThirdPhoto[0].DocumentCategoryId;
        nomineeThirdPhoto[0].documentId = isNomineeThirdPhoto[0].DocumentId;
        nomineeThirdPhoto[0].documentcategory =
          isNomineeThirdPhoto[0].DocumentCategory;
        setNomineeThirdObj(nomineeThirdPhoto);
        ADownloadDocument(
          (res: any) => NomineeThirdImage(res, "photo"),
          isNomineeThirdPhoto[0].FileName,
          isNomineeThirdPhoto[0].UploadedDocPath
        );
      }
      if (isNomineeThirdSignature.length > 0) {
        setIsLoading(true);
        let nomineeThirdSignature = [...nomineeThirdObj];

        nomineeThirdSignature[1].id = "signature";
        nomineeThirdSignature[1].disable = false;
        nomineeThirdSignature[1].uploadPer = 100;
        nomineeThirdSignature[1].fileName = isNomineeThirdSignature[0].FileName;
        nomineeThirdSignature[1].showFile = false;
        nomineeThirdSignature[1].hideProgress = false;
        nomineeThirdSignature[1].mimeType = isNomineeThirdSignature[0].MimeType;
        nomineeThirdSignature[1].documentCategoryId =
          isNomineeThirdSignature[0].DocumentCategoryId;
        nomineeThirdSignature[1].documentId =
          isNomineeThirdSignature[0].DocumentId;
        nomineeThirdSignature[1].documentcategory =
          isNomineeThirdSignature[0].DocumentCategory;
        setNomineeThirdObj(nomineeThirdSignature);
        ADownloadDocument(
          (res: any) => NomineeThirdImage(res, "signature"),
          isNomineeThirdSignature[0].FileName,
          isNomineeThirdSignature[0].UploadedDocPath
        );
      }

      // guardian start
      if (isGuardianFirstPhoto.length > 0) {
        setIsLoading(true);
        let guardianFirstPhoto = [...guardianFirstObj];

        guardianFirstPhoto[0].id = "photo";
        guardianFirstPhoto[0].disable = false;
        guardianFirstPhoto[0].uploadPer = 100;
        guardianFirstPhoto[0].fileName = isGuardianFirstPhoto[0].FileName;
        guardianFirstPhoto[0].showFile = false;
        guardianFirstPhoto[0].hideProgress = false;
        guardianFirstPhoto[0].mimeType = isGuardianFirstPhoto[0].MimeType;
        guardianFirstPhoto[0].documentCategoryId =
          isGuardianFirstPhoto[0].DocumentCategoryId;
        guardianFirstPhoto[0].documentId = isGuardianFirstPhoto[0].DocumentId;
        guardianFirstPhoto[0].documentcategory =
          isGuardianFirstPhoto[0].DocumentCategory;
        setGuardianFirstObj(guardianFirstPhoto);
        ADownloadDocument(
          (res: any) => GuardianFirstImage(res, "photo"),
          isGuardianFirstPhoto[0].FileName,
          isGuardianFirstPhoto[0].UploadedDocPath
        );
      }

      if (isGuardianFirstSignature.length > 0) {
        setIsLoading(true);
        let guardianFirstSignature = [...guardianFirstObj];

        guardianFirstSignature[1].id = "signature";
        guardianFirstSignature[1].disable = false;
        guardianFirstSignature[1].uploadPer = 100;
        guardianFirstSignature[1].fileName =
          isGuardianFirstSignature[0].FileName;
        guardianFirstSignature[1].showFile = false;
        guardianFirstSignature[1].hideProgress = false;
        guardianFirstSignature[1].mimeType =
          isGuardianFirstSignature[0].MimeType;
        guardianFirstSignature[1].documentCategoryId =
          isGuardianFirstSignature[0].DocumentCategoryId;
        guardianFirstSignature[1].documentId =
          isGuardianFirstSignature[0].DocumentId;
        guardianFirstSignature[1].documentcategory =
          isGuardianFirstSignature[0].DocumentCategory;
        setGuardianFirstObj(guardianFirstSignature);
        ADownloadDocument(
          (res: any) => GuardianFirstImage(res, "signature"),
          isGuardianFirstSignature[0].FileName,
          isGuardianFirstSignature[0].UploadedDocPath
        );
      }

      if (isGuardianSecondPhoto.length > 0) {
        setIsLoading(true);
        let guardianSecondPhoto = [...guardianSecondObj];

        guardianSecondPhoto[0].id = "photo";
        guardianSecondPhoto[0].disable = false;
        guardianSecondPhoto[0].uploadPer = 100;
        guardianSecondPhoto[0].fileName = isGuardianSecondPhoto[0].FileName;
        guardianSecondPhoto[0].showFile = false;
        guardianSecondPhoto[0].hideProgress = false;
        guardianSecondPhoto[0].mimeType = isGuardianSecondPhoto[0].MimeType;
        guardianSecondPhoto[0].documentCategoryId =
          isGuardianSecondPhoto[0].DocumentCategoryId;
        guardianSecondPhoto[0].documentId = isGuardianSecondPhoto[0].DocumentId;
        guardianSecondPhoto[0].documentcategory =
          isGuardianSecondPhoto[0].DocumentCategory;
        setGuardianSecondObj(guardianSecondPhoto);
        ADownloadDocument(
          (res: any) => GuardianSecondImage(res, "photo"),
          isGuardianSecondPhoto[0].FileName,
          isGuardianSecondPhoto[0].UploadedDocPath
        );
      }

      if (isGuardianSecondSignature.length > 0) {
        setIsLoading(true);
        let guardianSecondSignature = [...guardianSecondObj];

        guardianSecondSignature[1].id = "signature";
        guardianSecondSignature[1].disable = false;
        guardianSecondSignature[1].uploadPer = 100;
        guardianSecondSignature[1].fileName =
          isGuardianSecondSignature[0].FileName;
        guardianSecondSignature[1].showFile = false;
        guardianSecondSignature[1].hideProgress = false;
        guardianSecondSignature[1].mimeType =
          isGuardianSecondSignature[0].MimeType;
        guardianSecondSignature[1].documentCategoryId =
          isGuardianSecondSignature[0].DocumentCategoryId;
        guardianSecondSignature[1].documentId =
          isGuardianSecondSignature[0].DocumentId;
        guardianSecondSignature[1].documentcategory =
          isGuardianSecondSignature[0].DocumentCategory;
        setGuardianSecondObj(guardianSecondSignature);
        ADownloadDocument(
          (res: any) => GuardianSecondImage(res, "signature"),
          isGuardianSecondSignature[0].FileName,
          isGuardianSecondSignature[0].UploadedDocPath
        );
      }
      if (isGuardianThirdPhoto.length > 0) {
        setIsLoading(true);
        let guardianThirdPhoto = [...guardianThirdObj];

        guardianThirdPhoto[0].id = "photo";
        guardianThirdPhoto[0].disable = false;
        guardianThirdPhoto[0].uploadPer = 100;
        guardianThirdPhoto[0].fileName = isGuardianThirdPhoto[0].FileName;
        guardianThirdPhoto[0].showFile = false;
        guardianThirdPhoto[0].hideProgress = false;
        guardianThirdPhoto[0].mimeType = isGuardianThirdPhoto[0].MimeType;
        guardianThirdPhoto[0].documentCategoryId =
          isGuardianThirdPhoto[0].DocumentCategoryId;
        guardianThirdPhoto[0].documentId = isGuardianThirdPhoto[0].DocumentId;
        guardianThirdPhoto[0].documentcategory =
          isGuardianThirdPhoto[0].DocumentCategory;
        setGuardianThirdObj(guardianThirdPhoto);
        ADownloadDocument(
          (res: any) => GuardianThirdImage(res, "photo"),
          isGuardianThirdPhoto[0].FileName,
          isGuardianThirdPhoto[0].UploadedDocPath
        );
      }
      if (isGuardianThirdSignature.length > 0) {
        setIsLoading(true);
        let guardianThirdSignature = [...guardianThirdObj];

        guardianThirdSignature[1].id = "signature";
        guardianThirdSignature[1].disable = false;
        guardianThirdSignature[1].uploadPer = 100;
        guardianThirdSignature[1].fileName =
          isGuardianThirdSignature[0].FileName;
        guardianThirdSignature[1].showFile = false;
        guardianThirdSignature[1].hideProgress = false;
        guardianThirdSignature[1].mimeType =
          isGuardianThirdSignature[0].MimeType;
        guardianThirdSignature[1].documentCategoryId =
          isGuardianThirdSignature[0].DocumentCategoryId;
        guardianThirdSignature[1].documentId =
          isGuardianThirdSignature[0].DocumentId;
        guardianThirdSignature[1].documentcategory =
          isGuardianThirdSignature[0].DocumentCategory;
        setGuardianThirdObj(guardianThirdSignature);
        ADownloadDocument(
          (res: any) => GuardianThirdImage(res, "signature"),
          isGuardianThirdSignature[0].FileName,
          isGuardianThirdSignature[0].UploadedDocPath
        );
      }

      // guardian end
      if (isPhoto.length > 0) {
        setIsLoading(true);
        photoObj.id = "photo";
        photoObj.disable = false;
        photoObj.uploadPer = 100;
        photoObj.fileName = isPhoto[0].FileName;
        photoObj.showFile = false;
        photoObj.hideProgress = false;
        photoObj.mimeType = isPhoto[0].MimeType;
        photoObj.documentCategoryId = isPhoto[0].DocumentCategoryId;
        photoObj.documentId = isPhoto[0].DocumentId;
        photoObj.documentcategory = isPhoto[0].DocumentCategory;
        setPhotoObj(photoObj);
        ADownloadDocument(
          getPhotoImage,
          isPhoto[0].FileName,
          isPhoto[0].UploadedDocPath
        );
      }

      if (isPANCard.length > 0) {
        setIsLoading(true);
        panObj.id = "panCard";
        panObj.disable = false;
        panObj.uploadPer = 100;
        panObj.fileName = `${GlobalStore.applicationID}_Pan Card`;
        panObj.showFile = false;
        panObj.hideProgress = false;
        panObj.mimeType = isPANCard[0].MimeType;
        panObj.documentCategoryId = isPANCard[0].DocumentCategoryId;
        panObj.documentId = isPANCard[0].DocumentId;
        panObj.documentcategory = isPANCard[0].DocumentCategory;
        setPanObj(panObj);
        ADownloadDocument(
          getPanImage,
          isPANCard[0].FileName,
          isPANCard[0].UploadedDocPath
        );
      }

      if (isFirstBankDocument.length > 0) {
        setIsLoading(true);
        if (isFirstBankDocument[0].DocumentName === "Cancelled Cheque") {
          setDefaultFirstBankDoc("Cancelled Cheque");
        } else if (
          isFirstBankDocument[0].DocumentName ===
          "Bank Account Statement / Passbook"
        ) {
          setDefaultFirstBankDoc("Bank Account Statement / Passbook");
        }
        firstBankObj.id = "firstBankDoc";
        firstBankObj.disable = false;
        firstBankObj.uploadPer = 100;
        firstBankObj.fileName = `${GlobalStore.applicationID}_First Bank`;
        firstBankObj.showFile = false;
        firstBankObj.hideProgress = false;
        firstBankObj.documentId = isFirstBankDocument[0].DocumentId;
        firstBankObj.mimeType = isFirstBankDocument[0].MimeType;
        firstBankObj.documentcategory = isFirstBankDocument[0].DocumentCategory;
        firstBankObj.documentCategoryId =
          isFirstBankDocument[0].DocumentCategoryId;
        setFirstBankObj(firstBankObj);
        ADownloadDocument(
          getBankImage,
          isFirstBankDocument[0].FileName,
          isFirstBankDocument[0].UploadedDocPath
        );
        let firstBankDetails: any = {};
        firstBankDetails.BankName = isFirstBankDocument[0].BankName;
        firstBankDetails.AccountType = isFirstBankDocument[0].AccountType;
        setFirstBankDetails(firstBankDetails);
      }

      if (isSecondBankDocument.length > 0) {
        setIsLoading(true);
        if (isSecondBankDocument[0].DocumentName === "Cancelled Cheque") {
          setDefaultSecondBankDoc("Cancelled Cheque");
        } else if (
          isSecondBankDocument[0].DocumentName ===
          "Bank Account Statement / Passbook"
        ) {
          setDefaultSecondBankDoc("Bank Account Statement / Passbook");
        }
        secondBankObj.id = "secondBankDoc";
        secondBankObj.disable = false;
        secondBankObj.uploadPer = 100;
        secondBankObj.fileName = `${GlobalStore.applicationID}_Second Bank`;
        secondBankObj.showFile = false;
        secondBankObj.hideProgress = false;
        secondBankObj.documentId = isSecondBankDocument[0].DocumentId;
        secondBankObj.mimeType = isSecondBankDocument[0].MimeType;
        secondBankObj.documentcategory =
          isSecondBankDocument[0].DocumentCategory;
        secondBankObj.documentCategoryId =
          isSecondBankDocument[0].DocumentCategoryId;
        setSecondBankObj(secondBankObj);
        ADownloadDocument(
          getBankImage,
          isSecondBankDocument[0].FileName,
          isSecondBankDocument[0].UploadedDocPath
        );
        let secondBankDetails: any = {};
        secondBankDetails.BankName = isSecondBankDocument[0].BankName;
        secondBankDetails.AccountType = isSecondBankDocument[0].AccountType;
        setSecondBankDetails(secondBankDetails);
      }

      if (isThirdBankDocument.length > 0) {
        setIsLoading(true);
        if (isThirdBankDocument[0].DocumentName === "Cancelled Cheque") {
          setDefaultThirdBankDoc("Cancelled Cheque");
        } else if (
          isThirdBankDocument[0].DocumentName ===
          "Bank Account Statement / Passbook"
        ) {
          setDefaultThirdBankDoc("Bank Account Statement / Passbook");
        }

        thirdBankObj.id = "thirdBankDoc";
        thirdBankObj.disable = false;
        thirdBankObj.uploadPer = 100;
        thirdBankObj.fileName = `${GlobalStore.applicationID}_Third Bank`;
        thirdBankObj.showFile = false;
        thirdBankObj.hideProgress = false;
        thirdBankObj.documentId = isThirdBankDocument[0].DocumentId;
        thirdBankObj.mimeType = isThirdBankDocument[0].MimeType;
        thirdBankObj.documentcategory = isThirdBankDocument[0].DocumentCategory;
        thirdBankObj.documentCategoryId =
          isThirdBankDocument[0].DocumentCategoryId;
        setThirdBankObj(thirdBankObj);
        ADownloadDocument(
          getBankImage,
          isThirdBankDocument[0].FileName,
          isThirdBankDocument[0].UploadedDocPath
        );
        let thirdBankDetails: any = {};
        thirdBankDetails.BankName = isThirdBankDocument[0].BankName;
        thirdBankDetails.AccountType = isThirdBankDocument[0].AccountType;
        setThirdBankDetails(thirdBankDetails);
      }

      if (isFourthBankDocument.length > 0) {
        setIsLoading(true);
        if (isFourthBankDocument[0].DocumentName === "Cancelled Cheque") {
          setDefaultFourthBankDoc("Cancelled Cheque");
        } else if (
          isFourthBankDocument[0].DocumentName ===
          "Bank Account Statement / Passbook"
        ) {
          setDefaultFourthBankDoc("Bank Account Statement / Passbook");
        }

        fourthBankObj.id = "fourthBankDoc";
        fourthBankObj.disable = false;
        fourthBankObj.uploadPer = 100;
        fourthBankObj.fileName = `${GlobalStore.applicationID}_Fourth Bank`;
        fourthBankObj.showFile = false;
        fourthBankObj.hideProgress = false;
        fourthBankObj.documentId = isFourthBankDocument[0].DocumentId;
        fourthBankObj.mimeType = isFourthBankDocument[0].MimeType;
        fourthBankObj.documentcategory =
          isFourthBankDocument[0].DocumentCategory;
        fourthBankObj.documentCategoryId =
          isFourthBankDocument[0].DocumentCategoryId;
        setFourthBankObj(fourthBankObj);
        ADownloadDocument(
          getBankImage,
          isFourthBankDocument[0].FileName,
          isFourthBankDocument[0].UploadedDocPath
        );
        let fourthBankDetails: any = {};
        fourthBankDetails.BankName = isFourthBankDocument[0].BankName;
        fourthBankDetails.AccountType = isFourthBankDocument[0].AccountType;
        setFourthBankDetails(fourthBankDetails);
      }

      if (isFifthBankDocument.length > 0) {
        setIsLoading(true);
        if (isFifthBankDocument[0].DocumentName === "Cancelled Cheque") {
          setDefaultFifthBankDoc("Cancelled Cheque");
        } else if (
          isFifthBankDocument[0].DocumentName ===
          "Bank Account Statement / Passbook"
        ) {
          setDefaultFifthBankDoc("Bank Account Statement / Passbook");
        }

        fifthBankObj.id = "fifthBankDoc";
        fifthBankObj.disable = false;
        fifthBankObj.uploadPer = 100;
        fifthBankObj.fileName = `${GlobalStore.applicationID}_Fifth Bank`;
        fifthBankObj.showFile = false;
        fifthBankObj.hideProgress = false;
        fifthBankObj.documentId = isFifthBankDocument[0].DocumentId;
        fifthBankObj.mimeType = isFifthBankDocument[0].MimeType;
        fifthBankObj.documentcategory = isFifthBankDocument[0].DocumentCategory;
        fifthBankObj.documentCategoryId =
          isFifthBankDocument[0].DocumentCategoryId;
        setFifthBankObj(fifthBankObj);
        ADownloadDocument(
          getBankImage,
          isFifthBankDocument[0].FileName,
          isFifthBankDocument[0].UploadedDocPath
        );
        let fifthBankDetails: any = {};
        fifthBankDetails.BankName = isFifthBankDocument[0].BankName;
        fifthBankDetails.AccountType = isFifthBankDocument[0].AccountType;
        setFifthBankDetails(fifthBankDetails);
      }

      if (isPermAddressProof.length > 0) {
        setIsLoading(true);
        setDefaultPermAddressProof(true);
        if (isPermAddressProof[0].DocumentName === "Aadhaar Card") {
          setDefaultAddress("Aadhaar Card");
        } else if (isPermAddressProof[0].DocumentName === "Passport") {
          setDefaultAddress("Passport");
        } else if (isPermAddressProof[0].DocumentName === "Voter ID") {
          setDefaultAddress("Voter ID");
        } else if (isPermAddressProof[0].DocumentName === "Driving License") {
          setDefaultAddress("Driving License");
        } else if (isPermAddressProof[0].DocumentName === "Nrega Job Card") {
          setDefaultAddress("Nrega Job Card");
        }

        permAddressObj.id = "permAddress";
        permAddressObj.disable = blockAadhar;
        permAddressObj.uploadPer = 100;
        permAddressObj.fileName = `${GlobalStore.applicationID}_Permanent Address`;
        permAddressObj.showFile = false;
        permAddressObj.hideProgress = false;
        permAddressObj.documentCategoryId =
          isPermAddressProof[0].DocumentCategoryId;
        permAddressObj.documentcategory =
          isPermAddressProof[0].DocumentCategory;
        permAddressObj.documentId = isPermAddressProof[0].DocumentId;
        permAddressObj.mimeType = isPermAddressProof[0].MimeType;
        permAddressObj.documentCategoryId =
          isPermAddressProof[0].DocumentCategoryId;
        setPermAddressObj(permAddressObj);
        ADownloadDocument(
          getAddressImage,
          isPermAddressProof[0].FileName,
          isPermAddressProof[0].UploadedDocPath
        );
      }

      if (isCorrAddressProof.length > 0) {
        setIsLoading(true);
        if (isCorrAddressProof[0].DocumentName === "Aadhaar Card") {
          setDefaultCorrAddress("Aadhaar Card");
        } else if (isCorrAddressProof[0].DocumentName === "Passport") {
          setDefaultCorrAddress("Passport");
        } else if (isCorrAddressProof[0].DocumentName === "Voter ID") {
          setDefaultCorrAddress("Voter ID");
        } else if (isCorrAddressProof[0].DocumentName === "Driving License") {
          setDefaultCorrAddress("Driving License");
        } else if (isCorrAddressProof[0].DocumentName === "Nrega Job Card") {
          setDefaultCorrAddress("Nrega Job Card");
        }

        corrAddressObj.id = "corrAddress";
        corrAddressObj.disable = false;
        corrAddressObj.uploadPer = 100;
        corrAddressObj.fileName = `${GlobalStore.applicationID}_Corrospondance Address`;
        corrAddressObj.showFile = false;
        corrAddressObj.hideProgress = false;
        corrAddressObj.documentCategoryId =
          isCorrAddressProof[0].DocumentCategoryId;
        corrAddressObj.documentcategory =
          isCorrAddressProof[0].DocumentCategory;
        corrAddressObj.documentId = isCorrAddressProof[0].DocumentId;
        corrAddressObj.mimeType = isCorrAddressProof[0].MimeType;
        corrAddressObj.documentCategoryId =
          isCorrAddressProof[0].DocumentCategoryId;
        setCorrAddressObj(corrAddressObj);
        ADownloadDocument(
          getCorrAddressImage,
          isCorrAddressProof[0].FileName,
          isCorrAddressProof[0].UploadedDocPath
        );
      }
      console.log("isSignature", isSignature);
      if (isSignature.length > 0) {
        setIsLoading(true);
        setShowCanvas(false);
        signatureObj.id = "signature";
        signatureObj.disable = false;
        signatureObj.uploadPer = 100;
        signatureObj.fileName = `${GlobalStore.applicationID}_Signature`;
        signatureObj.showFile = false;
        signatureObj.hideProgress = false;
        signatureObj.mimeType = isSignature[0].MimeType;
        signatureObj.documentCategoryId = isSignature[0].DocumentCategoryId;
        signatureObj.documentId = isSignature[0].DocumentId;
        setSignatureObj(signatureObj);
        ADownloadDocument(
          getSignatureImage,
          isSignature[0].FileName,
          isSignature[0].UploadedDocPath
        );
      }

      if (isIncomeProof.length > 0) {
        setIsLoading(true);
        if (isIncomeProof[0].DocumentName === "Form 16") {
          setDefaultIncomeDoc("Form 16");
        } else if (isIncomeProof[0].DocumentName === "Salary Slip") {
          setDefaultIncomeDoc("Salary Slip");
        } else if (isIncomeProof[0].DocumentName === "ITR Acknowledgement") {
          setDefaultIncomeDoc("ITR Acknowledgement");
        } else if (isIncomeProof[0].DocumentName === "Bank Statement") {
          setDefaultIncomeDoc("Bank Statement");
        } else if (
          isIncomeProof[0].DocumentName === "Demat Holding Statement"
        ) {
          setDefaultIncomeDoc("Demat Holding Statement");
        } else if (isIncomeProof[0].DocumentName === "Networth Certificate") {
          setDefaultIncomeDoc("Networth Certificate");
        }

        incomeObj.id = "incomeDoc";
        incomeObj.disable = false;
        incomeObj.uploadPer = 100;
        incomeObj.fileName = `${GlobalStore.applicationID}_Income`;
        incomeObj.showFile = false;
        incomeObj.hideProgress = false;
        incomeObj.mimeType = isIncomeProof[0].MimeType;
        incomeObj.documentId = isIncomeProof[0].DocumentId;
        incomeObj.documentCategoryId = isIncomeProof[0].DocumentCategoryId;
        setIncomeObj(incomeObj);
        ADownloadDocument(
          getIncomeImage,
          isIncomeProof[0].FileName,
          isIncomeProof[0].UploadedDocPath
        );
      }
    } else {
      setPanObj(panObj);
      setFirstBankObj(firstBankObj);
      setPermAddressObj(permAddressObj);
      setCorrAddressObj(corrAddressObj);
      setSignatureObj(signatureObj);
      setIncomeObj(incomeObj);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR124-S" });
    }
    setIsLoading(false);
  };

  function getbase64(dataString: string, obj: DocDetailFormat) {
    return (
      "data:" +
      (obj.mimeType === "application/pdf" ? "application/pdf" : "image/jpeg") +
      ";base64," +
      dataString
    );
  }
  // NomineeSecondImage
  const NomineeFirstImage = (res: any, documentType: string) => {
    if (!res || res.error) {
      // setErrCode(164)
      // gtmService({
      //   action: "DownloadDocumentFromS3",
      //   label: "ERROR || ERR126",
      // });
    } else {
      if (res.data) {
        if (documentType === "photo") {
          const base64 = getbase64(res.data.base64document, nomineeFirstObj[0]);
          let imageOBJ = [...nomineeFirstObj];
          imageOBJ[0].imgUrl = base64;
          setNomineeFirstObj(imageOBJ);
        } else {
          const base64 = getbase64(res.data.base64document, nomineeFirstObj[1]);
          let imageOBJ = [...nomineeFirstObj];
          imageOBJ[1].imgUrl = base64;
          setNomineeFirstObj(imageOBJ);
        }
      } else {
        // gtmService({
        //   action: "DownloadDocumentFromS3",
        //   label: "ERROR || ERR126-S",
        // });
      }
    }
    setIsLoading(false);
  };
  const NomineeSecondImage = (res: any, documentType: string) => {
    if (!res || res.error) {
      // setErrCode(164)
      // gtmService({
      //   action: "DownloadDocumentFromS3",
      //   label: "ERROR || ERR126",
      // });
    } else {
      if (res.data) {
        if (documentType === "photo") {
          const base64 = getbase64(
            res.data.base64document,
            nomineeSecondObj[0]
          );
          let imageOBJ = [...nomineeSecondObj];
          imageOBJ[0].imgUrl = base64;
          setNomineeSecondObj(imageOBJ);
        } else {
          const base64 = getbase64(
            res.data.base64document,
            nomineeSecondObj[1]
          );
          let imageOBJ = [...nomineeSecondObj];
          imageOBJ[1].imgUrl = base64;
          setNomineeSecondObj(imageOBJ);
        }
      } else {
        // gtmService({
        //   action: "DownloadDocumentFromS3",
        //   label: "ERROR || ERR126-S",
        // });
      }
    }
    setIsLoading(false);
  };
  const NomineeThirdImage = (res: any, documentType: string) => {
    if (!res || res.error) {
      // setErrCode(164)
      // gtmService({
      //   action: "DownloadDocumentFromS3",
      //   label: "ERROR || ERR126",
      // });
    } else {
      if (res.data) {
        if (documentType === "photo") {
          const base64 = getbase64(res.data.base64document, nomineeThirdObj[0]);
          let imageOBJ = [...nomineeThirdObj];
          imageOBJ[0].imgUrl = base64;
          setNomineeThirdObj(imageOBJ);
        } else {
          const base64 = getbase64(res.data.base64document, nomineeThirdObj[1]);
          let imageOBJ = [...nomineeThirdObj];
          imageOBJ[1].imgUrl = base64;
          setNomineeThirdObj(imageOBJ);
        }
      } else {
        // gtmService({
        //   action: "DownloadDocumentFromS3",
        //   label: "ERROR || ERR126-S",
        // });
      }
    }
    setIsLoading(false);
  };

  const GuardianFirstImage = (res: any, documentType: string) => {
    if (!res || res.error) {
      // setErrCode(164)
      // gtmService({
      //   action: "DownloadDocumentFromS3",
      //   label: "ERROR || ERR126",
      // });
    } else {
      if (res.data) {
        if (documentType === "photo") {
          const base64 = getbase64(
            res.data.base64document,
            guardianFirstObj[0]
          );
          let imageOBJ = [...guardianFirstObj];
          imageOBJ[0].imgUrl = base64;
          setGuardianFirstObj(imageOBJ);
        } else {
          const base64 = getbase64(
            res.data.base64document,
            guardianFirstObj[1]
          );
          let imageOBJ = [...guardianFirstObj];
          imageOBJ[1].imgUrl = base64;
          setGuardianFirstObj(imageOBJ);
        }
      } else {
        // gtmService({
        //   action: "DownloadDocumentFromS3",
        //   label: "ERROR || ERR126-S",
        // });
      }
    }
    setIsLoading(false);
  };

  const GuardianSecondImage = (res: any, documentType: string) => {
    if (!res || res.error) {
      // setErrCode(164)
      // gtmService({
      //   action: "DownloadDocumentFromS3",
      //   label: "ERROR || ERR126",
      // });
    } else {
      if (res.data) {
        if (documentType === "photo") {
          const base64 = getbase64(
            res.data.base64document,
            guardianSecondObj[0]
          );
          let imageOBJ = [...guardianSecondObj];
          imageOBJ[0].imgUrl = base64;
          setGuardianSecondObj(imageOBJ);
        } else {
          const base64 = getbase64(
            res.data.base64document,
            guardianSecondObj[1]
          );
          let imageOBJ = [...guardianSecondObj];
          imageOBJ[1].imgUrl = base64;
          setGuardianSecondObj(imageOBJ);
        }
      } else {
        // gtmService({
        //   action: "DownloadDocumentFromS3",
        //   label: "ERROR || ERR126-S",
        // });
      }
    }
    setIsLoading(false);
  };
  const GuardianThirdImage = (res: any, documentType: string) => {
    if (!res || res.error) {
      // setErrCode(164)
      // gtmService({
      //   action: "DownloadDocumentFromS3",
      //   label: "ERROR || ERR126",
      // });
    } else {
      if (res.data) {
        if (documentType === "photo") {
          const base64 = getbase64(
            res.data.base64document,
            guardianThirdObj[0]
          );
          let imageOBJ = [...guardianThirdObj];
          imageOBJ[0].imgUrl = base64;
          setGuardianThirdObj(imageOBJ);
        } else {
          const base64 = getbase64(
            res.data.base64document,
            guardianThirdObj[1]
          );
          let imageOBJ = [...guardianThirdObj];
          imageOBJ[1].imgUrl = base64;
          setGuardianThirdObj(imageOBJ);
        }
      } else {
        // gtmService({
        //   action: "DownloadDocumentFromS3",
        //   label: "ERROR || ERR126-S",
        // });
      }
    }
    setIsLoading(false);
  };

  const getPhotoImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR126",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        setPhotoBase64(res.data.base64document);
        const base64 = getbase64(res.data.base64document, photoObj);
        let imageOBJ = { ...photoObj };
        imageOBJ.imgUrl = base64;
        setPhotoObj(imageOBJ);
        uploadedPhotoRefElement.current!.hidden = false;
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR126-S",
        });
      }
    }
  };

  const getPanImage = (res: any) => {
    console.log("getPanImage called", res);
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR127",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        setPanBase64(res.data.base64document);
        setPanButDisable(false);
        const base64 = getbase64(res.data.base64document, panObj);
        panObj.imgUrl = base64;
        panObj.imgUrl = base64;
        setPanObj(panObj);
        console.log("getPanImage base64", panObj);
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR127-S",
        });
      }
    }
  };

  const getBankImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR128",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, firstBankObj);
        firstBankObj.imgUrl = base64;
        setFirstBankObj(firstBankObj);
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR128-S",
        });
      }
    }
  };
  const getAddressImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR129",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, permAddressObj);
        permAddressObj.imgUrl = base64;
        permAddressObj.base64 = base64!.split(",")[1];

        setPermAddressObj(permAddressObj);
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR129-S",
        });
      }
    }
  };

  const getCorrAddressImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR130",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, corrAddressObj);
        corrAddressObj.imgUrl = base64;
        setCorrAddressObj(corrAddressObj);
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR130-S",
        });
      }
    }
  };

  const getSignatureImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR131",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, signatureObj);
        signatureObj.imgUrl = base64;
        setSignatureObj(signatureObj);
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR131-S",
        });
      }
    }
  };

  console.log("isLoading", isLoading, loaderSign);

  const getIncomeImage = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({
        action: "DownloadDocumentFromS3",
        label: "ERROR || ERR132",
      });
    } else {
      if (res.data) {
        setIsLoading(false);
        const base64 = getbase64(res.data.base64document, incomeObj);
        incomeObj.imgUrl = base64;
        setIncomeObj(incomeObj);
      } else {
        gtmService({
          action: "DownloadDocumentFromS3",
          label: "ERROR || ERR132-S",
        });
      }
    }
  };

  const getPendingDoc = (res: any) => {
    if (!res || res.error) {
      // setErrCode(164)
      setErrCode(errCode + res.error);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR122" });
    } else {
      if (res.data) {
        const penDoc = JSON.parse(res.data);
        const isPhotoPending = penDoc.filter(
          // prev api response
          //   (x: any) => x.documentcategory === "Photograph"
          (x: any) =>
            x.DocumentCategory == "Photograph" &&
            x.DocumentCategoryId == DocumentCategoryIdForphotographId
        );
        const isCorrPending = penDoc.filter(
          //   (x: any) => x.documentcategory === "Address Proof Correspondence"
          (x: any) => x.DocumentCategory == "Address Proof Correspondence"
        );

        const firstBankDetails = penDoc.filter(
          (x: any) => x.DocumentCategoryId == 9
        );
        const secondBankDetails = penDoc.filter(
          (x: any) => x.DocumentCategoryId == 60
        );
        const thirdBankDetails = penDoc.filter(
          (x: any) => x.DocumentCategoryId == 61
        );
        const fourthBankDetails = penDoc.filter(
          (x: any) => x.DocumentCategoryId == 70
        );
        const fifthBankDetails = penDoc.filter(
          (x: any) => x.DocumentCategoryId == 71
        );
        console.log(firstBankDetails);
        setFirstBankDetails(firstBankDetails[0]);
        console.log(secondBankDetails);
        setSecondBankDetails(secondBankDetails[0]);
        console.log(thirdBankDetails);
        setThirdBankDetails(thirdBankDetails[0]);
        console.log(fourthBankDetails);
        setFourthBankDetails(fourthBankDetails[0]);
        console.log(fifthBankDetails);
        setFifthBankDetails(fifthBankDetails[0]);

        if (isCorrPending.length > 0) {
          setShowCorr(true);
        }
        if (isPhotoPending.length === 0) {
          setIsPhotoPendingFlag(true);
          videoRefElement.current!.hidden = true;
          uploadedPhotoRefElement.current!.hidden = false;
          turnOfCamera && turnOfCamera.getVideoTracks()[0].stop();
          setShowRetake(true);
        } else {
          uploadedPhotoRefElement.current!.hidden = true;
          turnOnCamera();
        }
        if (penDoc.length > 0) {
          if (penDoc[0].Count === 0 && signatureObj.iscropped === true) {
            // const sendDataObjForQC = {
            //     "applicationid": GlobalStore.applicationID
            // }
            // ASendApplicationForQC(postQCData, sendDataObjForQC);
          } else {
            // const appTicketObj = {
            //     applicationid: GlobalStore.applicationID,
            //     clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId) || 0,
            //     stageinfoid: 6,
            // };
            // AcreateApplicationTicket(applicationTicketforDocuments, appTicketObj);
          }
        }
        const mandPenDoc = penDoc.filter(
          (x: any) =>
            // x.mandatory === true && x.documentcategory !== "Photograph"
            (x.Mandatory === true && x.DocumentCategory === "Photograph") ||
            (x.Mandatory === true && x.DocumentCategory === "Signature")
        );

        setPendingDocObj(
          mandPenDoc.filter(
            (y: any) => y.DocumentCategory !== "In Person Verification"
          )
        );
        if (GlobalStore.isDigiLocker) {
          setPendingDocObj(
            mandPenDoc.filter(
              (y: any) => y.DocumentCategory !== "Address Proof Permanent"
            )
          );
        }
      } else {
        // setErrCode(164)
        gtmService({
          action: "GetDocumentDetails",
          label: "ERROR || ERR122-S",
        });
      }
    }
  };

  const applicationTicketforDocuments = (res: any) => {
    if (!res || res.error) {
      setErrCode(148);
      setIsLoading(false);
    } else if (res.data) {
      console.log(res.data);
    }
  };

  const HandleNewSDKSelfieRes = (data: any) => {
    if (data && data.errMsg) {
      setBrowserPermission(true);
    } else if (data.is_valid == "1" && data.Latitude && data.Longitude) {
      setIsLoading(true);
      setIsNewPhoto(true);
      const new_File = new File(
        [Blob as unknown as BlobPart],
        "personalPhoto.jpeg",
        {
          type: "image/jpeg",
          lastModified: Date.now(),
        }
      );

      uploadedPhotoRefElement.current!.hidden = false;
      let base64 = data?.cropped_image;
      photoObj.imgUrl = `data:image/jpeg;base64, ${base64}`;
      setPhotoObj(photoObj);
      setPhotoBase64(base64);

      if (data?.Country && data?.Country.toLowerCase() == "india") {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkLocation: false,
          checkPhotoLiveness: false,
        });

        if (base64?.toString()) {
          let uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: base64,
            filename: new_File.name,
          };
          setIsLoading(true);
          setLoaderSign(true);

          // ACheckPhotoLiveness(
          //   (res: any) => getExtractPhotoLiveness(res),
          //   base64,
          //   GlobalStore.leadId
          // );

          AUploadDocument(
            (res: any) => getFetchResponse(res, new_File.name),
            uploadObj
          );
          const ipvObj = {
            leadid: GlobalStore.leadId,
            clientprimarydetailid: GlobalStore.clientPrimaryDetailId,
            applicationid: GlobalStore.applicationID,
            placeofDeclaration: "mumbai",
            empName: "diy user",
            designation: "online rm",
            branch: "mumbai",
            empCode: "0",
            organization: "Nuvama Wealth & investment Ltd",
            organizationCode: "2",
            // geo_location: location,
            latitude: data.Latitude,
            longitude: data.Longitude,
            ipaddress: "192.168.200.80",
            source: "DocVerification",
          };
          ASaveIPVerificationDetails(
            (res: any) => iPVerificationSaved(res, ipvObj),
            ipvObj
          );
        }
      } else {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkLocation: true,
        });

        setErrCode(
          errCode
            ? errCode
            : "" +
                "Oops, you are not in permitted range of account opening as per regulatory guidelines."
        );
      }
      setIsLoading(false);
    }
  };

  const getscreenShotURL = async (blob: Blob) => {
    const new_File = new File([blob as BlobPart], "personalPhoto.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    if (new_File.size > 50000000) {
      alert("video size exceeds maximum limit. Please upload it again");
      return false;
    }
    if (new_File.size < 1000) {
      alert("video size should be greater than 1KB. Please upload it again");
      return false;
    }
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      let base64 = (e.target && e.target.result)?.toString();
      photoObj.imgUrl = "";
      setPhotoObj(photoObj);
      setPhotoBase64(base64!.split(",")[1]);
      if (base64?.toString()) {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: base64!.split(",")[1],
          filename: new_File.name,
        };
        setIsLoading(true);
        setLoaderSign(true);

        ACheckPhotoLiveness(
          (res: any) => getExtractPhotoLiveness(res),
          base64!.split(",")[1],
          GlobalStore.leadId
        );
        AUploadDocument(
          (res: any) => getFetchResponse(res, new_File.name),
          uploadObj
        );
        const ipvObj = {
          leadid: GlobalStore.leadId,
          clientprimarydetailid: GlobalStore.clientPrimaryDetailId,
          applicationid: GlobalStore.applicationID,
          placeofDeclaration: "mumbai",
          empName: "diy user",
          designation: "online rm",
          branch: "mumbai",
          empCode: "0",
          organization: "Nuvama Wealth & investment Ltd",
          organizationCode: "2",
          // geo_location: location,
          latitude: location.lat,
          longitude: location.long,
          ipaddress: "192.168.200.80",
          source: "DocVerification",
        };
        ASaveIPVerificationDetails(
          (res: any) => iPVerificationSaved(res, ipvObj),
          ipvObj
        );
      }
    };
    fileReader.readAsDataURL(new_File);
  };

  const capturePhoto = () => {
    if (
      location !== "" &&
      location !== undefined &&
      location.lat !== "" &&
      location.lat !== undefined &&
      location.long !== "" &&
      location.long !== undefined
    ) {
      setIsLoading(true);
      setIsNewPhoto(true);
      setShowRetake(true);
      showPhotoPreview(true);
      let canvasPic: HTMLCanvasElement = document.getElementById(
        "capture"
      ) as HTMLCanvasElement;
      let videoPic: HTMLVideoElement = document.getElementById(
        "videoPersonal"
      ) as HTMLVideoElement;
      captureScreenShot(canvasPic, videoPic, getscreenShotURL);
      turnOfCamera && turnOfCamera.getVideoTracks()[0].stop();
    } else {
      setBrowserPermission(true);
    }
  };

  function captureScreenShot(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    callback: CallableFunction
  ) {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext("2d")!
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    canvas.toBlob((blob) => {
      callback(blob);
    });
  }

  const retakePhoto = () => {
    if (
      location !== undefined &&
      location !== "" &&
      location.lat !== "" &&
      location.lat !== undefined &&
      location.long !== "" &&
      location.long !== undefined
    ) {
      setIsNewPhoto(false);
      turnOnCamera();
      showPhotoPreview(false);
      setShowRetake(false);
      setIsPhotoPendingFlag(false);
      videoRefElement.current!.hidden = false;
      uploadedPhotoRefElement.current!.hidden = true;
    } else {
      setBrowserPermission(true);
    }
  };

  const iPVerificationSaved = async (res: any, ipvObj: any) => {
    console.log("CALLEDDDDD");
    if (!res || res.error) {
      //setErrCode('Error in saving geo-location details');
      //setIsLoading(false);
      setErrCode(errCode + res.error);
      gtmService({
        action: "SaveIpverificationDetails",
        label: "ERROR || ERR143",
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setLoaderSign(false);
      gtmService({
        action: "SaveIpverificationDetails",
        label: "ERROR || ERR143-S",
      });
    }
  };
  const getExtractPhotoLiveness = (res: any) => {
    if (!res || res.error) {
      // setErrCode("116 - Error in fetching Pan details from our system.");
      // setErrCode(errorMessage.panDetailsFailed);
      if (errCode !== res.error) setErrCode(errCode ? errCode : "" + res.error);
      setPhotoErrorMsg("");
      setLoaderSign(false);
      setLiveLocationCheck({
        ...liveLocationCheck,
        checkPhotoLiveness: true,
      });
      setIsLoading(false);
      gtmService({ action: "checkPhotoLiveness", label: "ERROR || ERR998" });
    } else {
      if (res.data && res.data.IsLive) {
        setPhotoErrorMsg(res.data.Message);
        liveLocationCheck.checkPhotoLiveness = false;
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkPhotoLiveness: false,
        });
      } else if (!res.data.IsLive) {
        setLiveLocationCheck({
          ...liveLocationCheck,
          checkPhotoLiveness: true,
        });
        console.log("res.data.Message", res.data.Message);
        setPhotoErrorMsg(res.data.Message);
        // if (errCode !== res.data.Message)
        //   setErrCode(errCode ? errCode : "" + res.data.Message);
      }
      setIsLoading(false);
      setLoaderSign(false);
    }
  };

  const getFetchResponse = (res: any, fileName: string) => {
    if (!res || res.error) {
      // setErrCode("1361- Error in uploading the file.");
      setErrCode(errCode + res.error);
      gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR139" });
      setIsLoading(false);
    } else {
      if (res.data) {
        console.log("getFetchResponse", res.data);
        setIsLoading(false);
        photoObj.fileName = res.data.Guid;
        photoObj.mimeType = res.data.Guid.split(".")[1];
        if (photoBase64 !== undefined && panBase64 !== undefined) {
          // setErrCode(errCode);
        }
      } else {
        setErrCode(errCode + res.error);
        gtmService({
          action: "UploadDocumentToS3",
          label: "ERROR || ERR139-S",
        });
      }
    }
  };

  function onRemove(doc: any, docType: string) {
    if (docType === "panObj") {
      setPanBase64("");
      setPanButDisable(true);
    } else if (docType === "permAddressObj") {
      console.log("perm call");
      permAddressObj.base64 = "";
      permAddressObj.imgUrl = "";
      setPermAddressObj(permAddressObj);
      setPermAddButDisable(true);
    }
  }
  function onPanDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    // console.log("current panObj", panObj);
    console.log("Response from Browse document val", val);
    // console.log("Response from Browse document val", fileName);
    // console.log("Response from Browse document val", id);
    // console.log("Response from Browse document val", type);
    setIsLoading(true);
    panObj.imgUrl = "";
    setPanObj(panObj);
    setPanBase64(val!.split(",")[1]);
    setPanButDisable(false);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function onFirstBankDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    firstBankObj.imgUrl = "";
    setFirstBankObj(firstBankObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function onSecondBankDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    secondBankObj.imgUrl = "";
    setSecondBankObj(secondBankObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function onThirdBankDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    thirdBankObj.imgUrl = "";
    setThirdBankObj(thirdBankObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function onFourthBankDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    fourthBankObj.imgUrl = "";
    setFourthBankObj(fourthBankObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function onFifthBankDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    fifthBankObj.imgUrl = "";
    setFifthBankObj(fifthBankObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function onFirstPermAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    permAddressObj.imgUrl = val;
    permAddressObj.base64 = val!.split(",")[1];
    permAddressObj.fileName = fileName;
    setPermAddressObj(permAddressObj);
    console.log(permAddressObj, "==>PERM-ADD-OBJ");
    if (fileName.split(".")[1] !== "pdf" && permAddressObj.documentId !== 125) {
      setSecondDoc(true);
      // permAddressObj.base64 = val!.split(',')[1];
      // permAddressObj.fileName = fileName;

      if (
        permAddressObj.documentId === aadhaarDocumnetId ||
        permAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: permAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: permAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      // if PDF
      setSecondDoc(false);
      setAnotherFile(false);

      if (
        permAddressObj.documentId === aadhaarDocumnetId ||
        permAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: permAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: permAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar PDF Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    }
  }

  function onSecondPermAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    permSecondAddressObj.imgUrl = "";
    permSecondAddressObj.fileName = fileName;
    permSecondAddressObj.base64 = val!.split(",")[1];
    setPermSecondAddressObj(permSecondAddressObj);
    if (
      fileName.split(".")[1] !== "pdf" &&
      permSecondAddressObj.documentId !== 125
    ) {
      setSecondDoc(true);
      if (
        permAddressObj.documentId === aadhaarDocumnetId ||
        permAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: permSecondAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: permSecondAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        const uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      setIsLoading(false);
      setSecondDoc(false);
      setAnotherFile(false);
    }
  }

  function onFirstCorrAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    corrAddressObj.imgUrl = "";
    corrAddressObj.base64 = val!.split(",")[1];
    corrAddressObj.fileName = fileName;
    setCorrAddressObj(corrAddressObj);
    if (fileName.split(".")[1] !== "pdf" && corrAddressObj.documentId !== 133) {
      setCorrSecondDoc(true);

      if (
        corrAddressObj.documentId === aadhaarDocumnetId ||
        corrAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: corrAddressObj.base64,
          documentcategory: "Corr Address Proof Permanent",
          documentid: corrAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      // if PDF
      setCorrSecondDoc(false);
      setCorrAnotherFile(false);

      if (
        corrAddressObj.documentId === aadhaarDocumnetId ||
        corrAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: corrAddressObj.base64,
          documentcategory: "Corr Address Proof Permanent",
          documentid: corrAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar PDF Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    }
  }

  function onSecondCorrAddressUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    corrSecondAddressObj.imgUrl = "";
    corrSecondAddressObj.fileName = fileName;
    corrSecondAddressObj.base64 = val!.split(",")[1];
    setCorrSecondAddressObj(corrSecondAddressObj);
    if (
      fileName.split(".")[1] !== "pdf" &&
      corrSecondAddressObj.documentId !== 133
    ) {
      setCorrSecondDoc(true);
      if (
        corrAddressObj.documentId === aadhaarDocumnetId ||
        corrAddressObj.documentId === corrAadhaarDocumnetId
      ) {
        const aadharObj = {
          applicationid: GlobalStore.applicationID,
          base64document: corrSecondAddressObj.base64,
          documentcategory: "Address Proof Permanent",
          documentid: corrSecondAddressObj.documentId,
          source: DIY,
        };
        console.log("Aadhar Detected");
        AmaskAadhaar(
          (res: any) => maskedAadhar(res, id, fileName, type),
          aadharObj
        );
      } else {
        const uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      setCorrSecondDoc(false);
      setCorrAnotherFile(false);
    }
  }

  const maskedAadhar = (
    res: any,
    id: string,
    fileName: string,
    type: string
  ) => {
    if (!res || res.error) {
      // setErrCode("Aadhar number masking failed");
      setIsLoading(false);
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR138" });
      if (res.data.error) {
        setErrCode(errCode + "Aadhar Masking failed: " + res.error);
        setIsLoading(false);
      } else {
        setErrCode(errCode + res.error);
      }
    } else if (res.data) {
      if (res.data.error) {
        setErrCode(errCode + "Aadhar Masking failed: " + res.error);
        setIsLoading(false);
      } else {
        // Masking API returns only JPG images, even if input is PDF.
        let uploadObj: uploadDocObj;
        if (id === "permAddress") {
          if (res.data.result.id_number_found) {
            permAddressObj.base64 = res.data.result.base64_image;
            permAddressObj.mimeType = "image/jpeg";
            permAddressObj.fileName =
              permAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setPermAddressObj(permAddressObj);
            setPermAddButDisable(false);
          }

          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: permAddressObj.base64 || "",
            filename: permAddressObj.fileName,
          };
        } else if (id === "permSecondAddress") {
          if (res.data.result.id_number_found) {
            permSecondAddressObj.base64 = res.data.result.base64_image;
            permSecondAddressObj.mimeType = "image/jpeg";
            permSecondAddressObj.fileName =
              permSecondAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setPermSecondAddressObj(permSecondAddressObj);
          }
          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: permSecondAddressObj.base64 || "",
            filename: permSecondAddressObj.fileName,
          };
        } else if (id === "corrAddress") {
          if (res.data.result.id_number_found) {
            corrAddressObj.base64 = res.data.result.base64_image;
            corrAddressObj.mimeType = "image/jpeg";
            corrAddressObj.fileName =
              corrAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setCorrAddressObj(corrAddressObj);
          }
          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: corrAddressObj.base64 || "",
            filename: corrAddressObj.fileName,
          };
        } else {
          if (res.data.result.id_number_found) {
            corrSecondAddressObj.base64 = res.data.result.base64_image;
            corrSecondAddressObj.mimeType = "image/jpeg";
            corrSecondAddressObj.fileName =
              corrSecondAddressObj.fileName.split(".")[0] + ".jpg";
            type = "image/jpeg";
            setCorrSecondAddressObj(corrSecondAddressObj);
          }
          uploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: corrSecondAddressObj.base64 || "",
            filename: corrSecondAddressObj.fileName,
          };
        }
        AUploadDocument(
          (res: any) =>
            getUploadRes(res, id, uploadObj.base64document, uploadObj.filename),
          uploadObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
      }
    } else {
      gtmService({
        action: "ExtractDocumentData",
        label: "ERROR || ERR138-S",
      });
    }
  };

  const getPDFData = (res: any) => {
    if (!res || res.error) {
      // setErrCode(137);
      setErrCode(errCode + res.error);
      gtmService({
        action: "MargeMultipleImagestoPDF",
        label: "ERROR || ERR136",
      });
      setIsLoading(false);
    } else {
      if (res.data) {
        setIsLoading(false);
        setAddressName(res.data.Guid);
        permAddressObj.mimeType = "application/pdf";
        const addressOCR = {
          applicationid: GlobalStore.applicationID,
          base64document: res.data.Mergedimagespdfbase64,
          documentcategory: permAddressObj.documentcategory,
          documentid: permAddressObj.documentId,
          source: DIY,
        };
        if (permAddressObj.documentId === aadhaarDocumnetId) {
          setIsLoading(true);
          AextractAadhaar(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (permAddressObj.documentId === passportDocumnetId) {
          setIsLoading(true);
          AextractPassport(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (permAddressObj.documentId === voterIdDocumentId) {
          setIsLoading(true);
          AextractVoterid(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (permAddressObj.documentId === dlDocumentId) {
          setIsLoading(true);
          AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
        }
      } else {
        gtmService({
          action: "MargeMultipleImagestoPDF",
          label: "ERROR || ERR136-S",
        });
      }
    }
  };

  const getCorrPDFData = (res: any) => {
    if (!res || res.error) {
      // setErrCode(137);
      setErrCode(errCode + res.error);
      gtmService({
        action: "MargeMultipleImagestoPDF",
        label: "ERROR || ERR137",
      });
      setIsLoading(false);
    } else {
      if (res.data) {
        setIsLoading(false);
        setCorrAddressName(res.data.Guid);
        corrAddressObj.mimeType = "application/pdf";
        const addressOCR = {
          applicationid: GlobalStore.applicationID,
          base64document: res.data.Mergedimagespdfbase64,
          documentcategory: corrAddressObj.documentcategory,
          documentid: corrAddressObj.documentId,
          source: DIY,
        };
        if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
          setIsLoading(true);
          AextractAadhaar(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (corrAddressObj.documentId === corrPassportDocumnetId) {
          setIsLoading(true);
          AextractPassport(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (corrAddressObj.documentId === corrVoterIdDocumentId) {
          setIsLoading(true);
          AextractVoterid(
            (res: any) => storeOcrData(res, addressOCR),
            addressOCR
          );
        } else if (corrAddressObj.documentId === corrDlDocumentId) {
          setIsLoading(true);
          AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
        }
      } else {
        gtmService({
          action: "MargeMultipleImagestoPDF",
          label: "ERROR || ERR137-S",
        });
      }
    }
  };
  function onNomineeUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string,
    index: number
  ) {
    switch (id) {
      case "nomFirstPhotoId":
        console.log("filename:", fileName);
        let fnpext = fileName.split(".").pop();
        console.log("extension:", fnpext);
        let fnpnewfileName = "firstNominee_Photo." + fnpext;
        console.log("newfileName:", fnpnewfileName);
        let nomFirstPhotCopy = [...nomineeFirstObj];
        nomFirstPhotCopy[0].documentCategoryId =
          nomineeDocsIds.nomineeOne.photo.documentCategoryId.toString();
        nomFirstPhotCopy[0].documentId =
          nomineeDocsIds.nomineeOne.photo.documentId;
        nomFirstPhotCopy[0].documentcategory = "Photograph";
        nomFirstPhotCopy[0].imgUrl = val;
        setNomineeFirstObj(nomFirstPhotCopy);
        let nomFirstPhotoObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fnpnewfileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          nomFirstPhotoObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "nomFirstSignature":
        let fnsext = fileName.split(".").pop();
        let fnsnewfileName = "firstNominee_Signature." + fnsext;
        let nomFirstSignatureCopy = [...nomineeFirstObj];
        nomFirstSignatureCopy[1].documentCategoryId =
          nomineeDocsIds.nomineeOne.signature.documentCategoryId.toString();
        nomFirstSignatureCopy[1].documentId =
          nomineeDocsIds.nomineeOne.signature.documentId;
        nomFirstSignatureCopy[1].documentcategory = "Signature";
        nomFirstSignatureCopy[1].imgUrl = val;
        setNomineeFirstObj(nomFirstSignatureCopy);
        let nomFirstSignatureObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fnsnewfileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          nomFirstSignatureObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "nomSecondPhotoId":
        let snpext = fileName.split(".").pop();
        let snpnewfileName = "secondNominee_Photo." + snpext;
        let nomSecondPhotoCopy = [...nomineeSecondObj];
        nomSecondPhotoCopy[0].documentCategoryId =
          nomineeDocsIds.nomineeTwo.photo.documentCategoryId.toString();
        nomSecondPhotoCopy[0].documentId =
          nomineeDocsIds.nomineeTwo.photo.documentId;
        nomSecondPhotoCopy[0].documentcategory = "Photograph";
        nomSecondPhotoCopy[0].imgUrl = val;
        setNomineeSecondObj(nomSecondPhotoCopy);
        let nomSecondPhotoObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: snpnewfileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          nomSecondPhotoObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "nomSecondSignature":
        let nssext = fileName.split(".").pop();
        let nssnewfileName = "secondNominee_Signature." + nssext;
        let nomSecondSignatureCopy = [...nomineeSecondObj];
        nomSecondSignatureCopy[1].documentCategoryId =
          nomineeDocsIds.nomineeTwo.signature.documentCategoryId.toString();
        nomSecondSignatureCopy[1].documentId =
          nomineeDocsIds.nomineeTwo.signature.documentId;
        nomSecondSignatureCopy[1].documentcategory = "Signature";
        nomSecondSignatureCopy[1].imgUrl = val;
        setNomineeSecondObj(nomSecondSignatureCopy);
        let nomSecondSignatureObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: nssnewfileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          nomSecondSignatureObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "nomThirdPhotoId":
        let ntpext = fileName.split(".").pop();
        let ntpnewfileName = "thirdNominee_Photo." + ntpext;
        let nomThirdPhotoCopy = [...nomineeThirdObj];
        nomThirdPhotoCopy[0].documentCategoryId =
          nomineeDocsIds.nomineeThree.photo.documentCategoryId.toString();
        nomThirdPhotoCopy[0].documentId =
          nomineeDocsIds.nomineeThree.photo.documentId;
        nomThirdPhotoCopy[0].documentcategory = "Photograph";
        nomThirdPhotoCopy[0].imgUrl = val;
        setNomineeThirdObj(nomThirdPhotoCopy);
        let nomThirdPhotoObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: ntpnewfileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          nomThirdPhotoObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "nomThirdSignature":
        let ntsext = fileName.split(".").pop();
        let ntsnewfileName = "thirdNominee_Signature." + ntsext;
        let nomThirdSignatureCopy = [...nomineeThirdObj];
        nomThirdSignatureCopy[1].documentCategoryId =
          nomineeDocsIds.nomineeThree.signature.documentCategoryId.toString();
        nomThirdSignatureCopy[1].documentId =
          nomineeDocsIds.nomineeThree.signature.documentId;
        nomThirdSignatureCopy[1].documentcategory = "Signature";
        nomThirdSignatureCopy[1].imgUrl = val;
        setNomineeThirdObj(nomThirdSignatureCopy);
        let nomThirdSignatureObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: ntsnewfileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          nomThirdSignatureObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;

      case "guardianFirstPhotoId":
        let guardFirstPhotoCopy = [...guardianFirstObj];
        guardFirstPhotoCopy[0].documentCategoryId =
          nomineeDocsIds.nomineeOne.guardianDetails.photo.documentCategoryId.toString();
        guardFirstPhotoCopy[0].documentId =
          nomineeDocsIds.nomineeOne.guardianDetails.photo.documentId;
        guardFirstPhotoCopy[0].documentcategory = "Photograph";
        guardFirstPhotoCopy[0].imgUrl = val;
        setGuardianFirstObj(guardFirstPhotoCopy);
        let guardianFirstPhotoObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          guardianFirstPhotoObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "guardianFirstSignature":
        let guardFirstSignatureCopy = [...guardianFirstObj];
        guardFirstSignatureCopy[1].documentCategoryId =
          nomineeDocsIds.nomineeOne.guardianDetails.signature.documentCategoryId.toString();
        guardFirstSignatureCopy[1].documentId =
          nomineeDocsIds.nomineeOne.guardianDetails.signature.documentId;
        guardFirstSignatureCopy[1].documentcategory = "Signature";
        guardFirstSignatureCopy[1].imgUrl = val;
        setGuardianFirstObj(guardFirstSignatureCopy);
        let guardianFirstSignatureObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          guardianFirstSignatureObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "guardianSecondPhotoId":
        let guardSecondPhotoCopy = [...guardianSecondObj];
        guardSecondPhotoCopy[0].documentCategoryId =
          nomineeDocsIds.nomineeTwo.guardianDetails.photo.documentCategoryId.toString();
        guardSecondPhotoCopy[0].documentId =
          nomineeDocsIds.nomineeTwo.guardianDetails.photo.documentId;
        guardSecondPhotoCopy[0].documentcategory = "Photograph";
        guardSecondPhotoCopy[0].imgUrl = val;
        setGuardianSecondObj(guardSecondPhotoCopy);
        let guardianSecondPhotoObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          guardianSecondPhotoObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "guardianSecondSignature":
        let guardSecondSignatureCopy = [...guardianSecondObj];
        guardSecondSignatureCopy[1].documentCategoryId =
          nomineeDocsIds.nomineeTwo.guardianDetails.signature.documentCategoryId.toString();
        guardSecondSignatureCopy[1].documentId =
          nomineeDocsIds.nomineeTwo.guardianDetails.signature.documentId;
        guardSecondSignatureCopy[1].documentcategory = "Signature";
        guardSecondSignatureCopy[1].imgUrl = val;
        setGuardianSecondObj(guardSecondSignatureCopy);
        let guardianSecondSignatureObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          guardianSecondSignatureObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "guardianThirdPhotoId":
        let guardThirdPhotoCopy = [...guardianThirdObj];
        guardThirdPhotoCopy[0].documentCategoryId =
          nomineeDocsIds.nomineeThree.guardianDetails.photo.documentCategoryId.toString();
        guardThirdPhotoCopy[0].documentId =
          nomineeDocsIds.nomineeThree.guardianDetails.photo.documentId;
        guardThirdPhotoCopy[0].documentcategory = "Photograph";
        guardThirdPhotoCopy[0].imgUrl = val;
        setGuardianThirdObj(guardThirdPhotoCopy);
        let guardianThirdPhotoObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          guardianThirdPhotoObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;
      case "guardianThirdSignature":
        let guardThirdSignatureCopy = [...guardianThirdObj];
        guardThirdSignatureCopy[1].documentCategoryId =
          nomineeDocsIds.nomineeThree.guardianDetails.signature.documentCategoryId.toString();
        guardThirdSignatureCopy[1].documentId =
          nomineeDocsIds.nomineeThree.guardianDetails.signature.documentId;
        guardThirdSignatureCopy[1].documentcategory = "Signature";
        guardThirdSignatureCopy[1].imgUrl = val;
        setGuardianThirdObj(guardThirdSignatureCopy);
        let guardianThirdSignatureObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };
        AUploadDocument(
          (res: any) => getUploadRes(res, id, val, fileName),
          guardianThirdSignatureObj,
          (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
        );
        break;

      default:
        break;
    }
  }

  function handleRemoveDoc(index: number, id: string, docObj: any) {
    switch (id) {
      case "nomFirstPhotoId":
        let nomFirstPhotCopy = [...nomineeFirstObj];
        nomFirstPhotCopy[0].imgUrl = "";
        nomFirstPhotCopy[0].fileName = "";
        setNomineeFirstObj(nomFirstPhotCopy);
        break;
      case "nomFirstSignature":
        let nomFirstSignatureCopy = [...nomineeFirstObj];
        nomFirstSignatureCopy[1].imgUrl = "";
        nomFirstSignatureCopy[1].fileName = "";

        setNomineeFirstObj(nomFirstSignatureCopy);
        break;
      case "nomSecondPhotoId":
        let nomSecondPhotoCopy = [...nomineeSecondObj];
        nomSecondPhotoCopy[0].imgUrl = "";
        nomSecondPhotoCopy[0].fileName = "";

        setNomineeSecondObj(nomSecondPhotoCopy);
        break;
      case "nomSecondSignature":
        let nomSecondSignatureCopy = [...nomineeSecondObj];
        nomSecondSignatureCopy[1].imgUrl = "";
        nomSecondSignatureCopy[1].fileName = "";

        setNomineeSecondObj(nomSecondSignatureCopy);
        break;
      case "nomThirdPhotoId":
        let nomThirdPhotoCopy = [...nomineeThirdObj];

        nomThirdPhotoCopy[0].imgUrl = "";
        nomThirdPhotoCopy[0].fileName = "";

        setNomineeThirdObj(nomThirdPhotoCopy);
        break;
      case "nomThirdSignature":
        let nomThirdSignatureCopy = [...nomineeThirdObj];
        nomThirdSignatureCopy[1].documentCategoryId =
          nomThirdSignatureCopy[1].imgUrl = "";
        nomThirdSignatureCopy[1].fileName = "";

        setNomineeThirdObj(nomThirdSignatureCopy);
        break;
      case "guardianFirstPhotoId":
        let guardFirstPhotoCopy = [...guardianFirstObj];
        guardFirstPhotoCopy[0].imgUrl = "";
        guardFirstPhotoCopy[0].fileName = "";

        setGuardianFirstObj(guardFirstPhotoCopy);
        break;
      case "guardianFirstSignature":
        let guardFirstSignatureCopy = [...guardianFirstObj];
        guardFirstSignatureCopy[1].imgUrl = "";
        guardFirstSignatureCopy[1].fileName = "";

        setGuardianFirstObj(guardFirstSignatureCopy);
        break;
      case "guardianSecondPhotoId":
        let guardSecondPhotoCopy = [...guardianSecondObj];
        guardSecondPhotoCopy[0].imgUrl = "";
        guardSecondPhotoCopy[0].fileName = "";

        setGuardianSecondObj(guardSecondPhotoCopy);
        break;
      case "guardianSecondSignature":
        let guardSecondSignatureCopy = [...guardianSecondObj];
        guardSecondSignatureCopy[1].imgUrl = "";
        guardSecondSignatureCopy[1].fileName = "";

        setGuardianSecondObj(guardSecondSignatureCopy);
        break;
      case "guardianThirdPhotoId":
        let guardThirdPhotoCopy = [...guardianThirdObj];
        guardThirdPhotoCopy[0].imgUrl = "";
        guardThirdPhotoCopy[0].fileName = "";

        setGuardianThirdObj(guardThirdPhotoCopy);
        break;
      case "guardianThirdSignature":
        let guardThirdSignatureCopy = [...guardianThirdObj];
        guardThirdSignatureCopy[1].imgUrl = "";
        guardThirdSignatureCopy[1].fileName = "";

        setGuardianThirdObj(guardThirdSignatureCopy);
        break;
      default:
        break;
    }
  }

  console.log("signatureObj", signatureObj);
  function onSignatureUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    console.log("onSignatureUploadComplete", val);
    setIsLoading(true);
    signatureObj.imgUrl = "";
    setSignatureObj(signatureObj);
    let fileNameCheck = fileName.includes(".") ? true : false;
    let typeCreater = type.includes("/") ? type.split("/")[1] : type;

    if (signatureObj.base64 !== (undefined || "")) {
      let uploadObj = {
        applicationid: GlobalStore.applicationID,
        base64document: signatureObj.base64!.split(",")[1],
        filename: fileNameCheck
          ? `${GlobalStore.applicationID}_Signature.${typeCreater}`
          : `${GlobalStore.applicationID}_Signature}.${typeCreater}`,
        DocType: "1",
        QltyByPassFlag: "0",
      };
      AUploadDocument(
        (res: any) => getUploadRes(res, id, val, fileName),
        uploadObj,
        (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
      );
    } else {
      let uploadObj = {
        applicationid: GlobalStore.applicationID,
        base64document: val!.split(",")[1],
        filename: `${GlobalStore.applicationID}_Signature.${
          type!.split("/")[1]
        }`,
        DocType: "1",
        QltyByPassFlag: "0",
      };
      AUploadDocument(
        (res: any) => getUploadRes(res, id, val, fileName),
        uploadObj,
        (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
      );
    }
  }

  function onIncomeUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setIsLoading(true);
    incomeObj.imgUrl = "";
    setIncomeObj(incomeObj);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) => getUploadRes(res, id, val, fileName),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  function handErrorForFileUpload(id: string, res: any) {
    if (id === "firstBankDoc") {
      setFirstBankListError(res.error);
    }
    if (id === "secondBankDoc") {
      setSecondBankListError(res.error);
    }
    if (id === "thirdBankDoc") {
      setThirdBankListError(res.error);
    }
    if (id === "fourthBankDoc") {
      setFourthBankListError(res.error);
    }
    if (id === "fifthBankDoc") {
      setFifthBankListError(res.error);
    }
    if (id === "permAddress") {
      setPermAddListError(res.error);
    }
    if (id === "corrAddress") {
      setCorrAddListError(res.error);
    }
    if (id === "signature") {
      setSignatureListError(res.error);
    }
    if (id === "incomeDoc") {
      setIncomeListError(res.error);
    }
    if (id === "permSecondAddress") {
      setSecondDocError(res.error);
    }
    if (id === "corrSecondAddress") {
      setCorrSecondDocError(res.error);
    }

    if (id === "nomFirstPhotoId") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        nomFirstPhotoId: res.error,
      });
    }
    if (id === "nomFirstSignature") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        nomFirstSignature: res.error,
      });
    }
    if (id === "nomSecondPhotoId") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        nomSecondPhotoId: res.error,
      });
    }
    if (id === "nomSecondSignature") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        nomSecondSignature: res.error,
      });
    }
    if (id === "nomThirdPhotoId") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        nomThirdPhotoId: res.error,
      });
    }
    if (id === "nomThirdSignature") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        nomThirdSignature: res.error,
      });
    }
    if (id === "guardianFirstPhotoId") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        guardianFirstPhotoId: res.error,
      });
    }
    if (id === "guardianFirstSignature") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        guardianFirstSignature: res.error,
      });
    }
    if (id === "guardianSecondPhotoId") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        guardianSecondPhotoId: res.error,
      });
    }
    if (id === "guardianSecondSignature") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        guardianSecondSignature: res.error,
      });
    }
    if (id === "guardianThirdPhotoId") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        guardianThirdPhotoId: res.error,
      });
    }
    if (id === "guardianThirdSignature") {
      setNomineeGuardianError({
        ...nomineeGuardianError,
        guardianThirdSignature: res.error,
      });
    }
  }

  const getUploadRes = (res: any, id: string, val: any, fileName: string) => {
    if (!res || res.error) {
      gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR140" });
      setIsLoading(false);
      // setErrCode("1361- Error in uploading the file.");\
      handErrorForFileUpload(id, res);
      if (
        !res?.errorCode?.includes("C11BE292") &&
        !res?.errorCode?.includes("C11BE422")
      ) {
        setErrCode(errCode + res.error);
      }
    } else {
      if (res.data) {
        setErrCode(errCode);
        if (id === "panCard") {
          setIsLoading(true);
          setIsNewPan(true);
          setPanName(res.data.Guid);
          panObj.documentId = panDocumentId;
          const panOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: val!.split(",")[1],
            documentcategory: "PAN Card",
            documentid: panObj.documentId,
            source: DIY,
          };

          AextractPan((res: any) => storeOcrData(res, panOCR), panOCR);
          if (photoBase64 !== undefined && panBase64 !== undefined) {
            setErrCode(errCode);
          }
        } else if (id === "firstBankDoc") {
          setIsLoading(false);
          setFirstBankListError("");
          setIsNewFirstBank(true);
          setFirstBankName(res.data.Guid);

          const firstBankOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: val!.split(",")[1],
            documentcategory: firstBankObj.documentcategory,
            documentid: firstBankObj.documentId,
            source: DIY,
            bankName: firstBankDetails.BankName,
            accountType: firstBankDetails.AccountType,
          };
          if (firstBankObj.documentId === firstChequeDocumnetId) {
            setIsLoading(true);
            AextractCheque(
              (res: any) => storeOcrData(res, firstBankOCR),
              firstBankOCR
            );
          } else if (firstBankObj.documentId === firstBankStatementDocumnetId) {
            setIsLoading(true);
            AextractBankStatement(
              (res: any) => storeOcrData(res, firstBankOCR),
              firstBankOCR
            );
          } else {
            setIsLoading(false);
          }
        } else if (id === "secondBankDoc") {
          setIsLoading(false);
          setSecondBankListError("");
          setIsNewSecondBank(true);
          setSecondBankName(res.data.Guid);

          const secondBankOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: val!.split(",")[1],
            documentcategory: secondBankObj.documentcategory,
            documentid: secondBankObj.documentId,
            source: DIY,
            bankName: secondBankDetails.BankName,
            accountType: secondBankDetails.AccountType,
          };
          if (secondBankObj.documentId === secondChequeDocumnetId) {
            setIsLoading(true);
            AextractCheque(
              (res: any) => storeOcrData(res, secondBankOCR),
              secondBankOCR
            );
          } else if (
            secondBankObj.documentId === secondBankStatementDocumnetId
          ) {
            setIsLoading(true);
            AextractBankStatement(
              (res: any) => storeOcrData(res, secondBankOCR),
              secondBankOCR
            );
          } else {
            setIsLoading(false);
          }
        } else if (id === "thirdBankDoc") {
          setIsLoading(false);
          setThirdBankListError("");
          setIsNewThirdBank(true);
          setThirdBankName(res.data.Guid);

          const thirdBankOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: val!.split(",")[1],
            documentcategory: thirdBankObj.documentcategory,
            documentid: thirdBankObj.documentId,
            source: DIY,
            bankName: thirdBankDetails.BankName,
            accountType: thirdBankDetails.AccountType,
          };
          if (thirdBankObj.documentId === thirdChequeDocumnetId) {
            setIsLoading(true);
            AextractCheque(
              (res: any) => storeOcrData(res, thirdBankOCR),
              thirdBankOCR
            );
          } else if (thirdBankObj.documentId === thirdBankStatementDocumnetId) {
            setIsLoading(true);
            AextractBankStatement(
              (res: any) => storeOcrData(res, thirdBankOCR),
              thirdBankOCR
            );
          } else {
            setIsLoading(false);
          }
        } else if (id === "fourthBankDoc") {
          setIsLoading(false);
          setFourthBankListError("");
          setIsNewFourthBank(true);
          setFourthBankName(res.data.Guid);
          const fourthBankOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: val!.split(",")[1],
            documentcategory: fourthBankObj.documentcategory,
            documentid: fourthBankObj.documentId,
            source: DIY,
            bankName: fourthBankDetails.BankName,
            accountType: fourthBankDetails.AccountType,
          };
          if (fourthBankObj.documentId === fourthChequeDocumnetId) {
            setIsLoading(true);
            AextractCheque(
              (res: any) => storeOcrData(res, fourthBankOCR),
              fourthBankOCR
            );
          } else if (
            fourthBankObj.documentId === fourthBankStatementDocumnetId
          ) {
            setIsLoading(true);
            AextractBankStatement(
              (res: any) => storeOcrData(res, fourthBankOCR),
              fourthBankOCR
            );
          } else {
            setIsLoading(false);
          }
        } else if (id === "fifthBankDoc") {
          setIsLoading(false);
          setFifthBankListError("");
          setIsNewFifthBank(true);
          setFifthBankName(res.data.Guid);
          const fifthBankOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: val!.split(",")[1],
            documentcategory: fifthBankObj.documentcategory,
            documentid: fifthBankObj.documentId,
            source: DIY,
            bankName: fifthBankDetails.BankName,
            accountType: fifthBankDetails.AccountType,
          };
          if (fifthBankObj.documentId === fifthChequeDocumnetId) {
            setIsLoading(true);
            AextractCheque(
              (res: any) => storeOcrData(res, fifthBankOCR),
              fifthBankOCR
            );
          } else if (fifthBankObj.documentId === fifthBankStatementDocumnetId) {
            setIsLoading(true);
            AextractBankStatement(
              (res: any) => storeOcrData(res, fifthBankOCR),
              fifthBankOCR
            );
          } else {
            setIsLoading(false);
          }
        } else if (id === "permAddress" && fileName.split(".")[1] === "pdf") {
          setIsLoading(false);
          setPermAddListError("");
          setIsNewPermAddress(true);
          setAddressName(res.data.Guid);
          const addressOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: permAddressObj.base64,
            documentcategory: permAddressObj.documentcategory,
            documentid: permAddressObj.documentId,
            source: DIY,
          };
          if (permAddressObj.documentId === aadhaarDocumnetId) {
            setIsLoading(true);
            AextractAadhaar(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (permAddressObj.documentId === passportDocumnetId) {
            setIsLoading(true);
            AextractPassport(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (permAddressObj.documentId === voterIdDocumentId) {
            setIsLoading(true);
            AextractVoterid(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (permAddressObj.documentId === dlDocumentId) {
            setIsLoading(true);
            AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
          }
        } else if (id === "permAddress" && fileName.split(".")[1] !== "pdf") {
          setPermAddListError("");
          setIsNewPermAddress(true);
          setAddressName(res.data.Guid);
          if (permAddressObj.documentId === aadhaarDocumnetId) {
            setIsLoading(true);
            const aadharOCR = {
              applicationid: GlobalStore.applicationID,
              base64document: permAddressObj.base64,
              documentcategory: permAddressObj.documentcategory,
              documentid: permAddressObj.documentId,
              source: DIY,
            };
            AextractAadhaar(
              (res: any) => storeOcrData(res, aadharOCR),
              aadharOCR
            );
          } else setIsLoading(false);
        } else if (id === "permSecondAddress") {
          setSecondDocError("");
          setIsLoading(true);
          setIsNewPermAddress(true);
          let uploadImageObj = {
            applicationId: GlobalStore.applicationID,
            image1: permAddressObj.base64,
            image2: permSecondAddressObj.base64,
            imagename1: permAddressObj.fileName,
            imagename2: permSecondAddressObj.fileName,
          };
          AgetPdf(getPDFData, uploadImageObj);
        } else if (id === "corrAddress" && fileName.split(".")[1] === "pdf") {
          setIsLoading(false);
          setCorrAddListError("");
          setIsNewCorrAddress(true);
          setCorrAddressName(res.data.Guid);
          const addressOCR = {
            applicationid: GlobalStore.applicationID,
            base64document: corrAddressObj.base64,
            documentcategory: corrAddressObj.documentcategory,
            documentid: corrAddressObj.documentId,
            source: DIY,
          };
          if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
            setIsLoading(true);
            AextractAadhaar(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (corrAddressObj.documentId === corrPassportDocumnetId) {
            setIsLoading(true);
            AextractPassport(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (corrAddressObj.documentId === corrVoterIdDocumentId) {
            setIsLoading(true);
            AextractVoterid(
              (res: any) => storeOcrData(res, addressOCR),
              addressOCR
            );
          } else if (corrAddressObj.documentId === corrDlDocumentId) {
            setIsLoading(true);
            AextractDl((res: any) => storeOcrData(res, addressOCR), addressOCR);
          }
        } else if (id === "corrAddress" && fileName.split(".")[1] !== "pdf") {
          setIsNewCorrAddress(true);
          setCorrAddListError("");
          setCorrAddressName(res.data.Guid);
          if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
            setIsLoading(true);
            const aadharOCR = {
              applicationid: GlobalStore.applicationID,
              base64document: corrAddressObj.base64,
              documentcategory: corrAddressObj.documentcategory,
              documentid: corrAddressObj.documentId,
              source: DIY,
            };
            AextractAadhaar(
              (res: any) => storeOcrData(res, aadharOCR),
              aadharOCR
            );
          } else setIsLoading(false);
        } else if (
          id === "corrAddress" &&
          fileName.split(".")[1] !== "pdf" &&
          corrSecondAddressObj.base64 !== undefined
        ) {
          setIsLoading(true);
          setCorrAddListError("");
          setIsNewCorrAddress(true);
          let uploadImageObj = {
            applicationId: GlobalStore.applicationID,
            image1: corrAddressObj.base64,
            image2: corrSecondAddressObj.base64,
            imagename1: corrAddressObj.fileName,
            imagename2: corrSecondAddressObj.fileName,
          };
          AgetPdf(getCorrPDFData, uploadImageObj);
        } else if (id === "corrSecondAddress") {
          setIsLoading(true);
          setCorrSecondDocError("");
          setIsNewCorrAddress(true);
          let uploadImageObj = {
            applicationId: GlobalStore.applicationID,
            image1: corrAddressObj.base64,
            image2: corrSecondAddressObj.base64,
            imagename1: corrAddressObj.fileName,
            imagename2: corrSecondAddressObj.fileName,
          };
          AgetPdf(getCorrPDFData, uploadImageObj);
        } else if (id === "signature") {
          setIsLoading(false);
          setSignatureListError("");

          setIsNewSignature(true);
          setSignatureName(res.data.Guid);
          signatureObj.documentId = signatureDocumentId;
        } else if (id === "incomeDoc") {
          setIsLoading(false);
          setIncomeListError("");
          setIsNewIncome(true);
          setIncomeName(res.data.Guid);
        } else if (id === "nomFirstPhotoId") {
          let nomineeOneCopy = [...nomineeFirstObj];
          nomineeOneCopy[0].fileName = res.data.Guid;
          setNomineeFirstObj(nomineeOneCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            nomFirstPhotoId: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isNomFirstPhoto: true,
          });
        } else if (id === "nomFirstSignature") {
          let nomineeOneCopy = [...nomineeFirstObj];
          nomineeOneCopy[1].fileName = res.data.Guid;
          setNomineeFirstObj(nomineeOneCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            nomFirstSignature: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isNomFirstSignature: true,
          });
        } else if (id === "nomSecondPhotoId") {
          let nomineeTwoCopy = [...nomineeSecondObj];
          nomineeTwoCopy[0].fileName = res.data.Guid;
          setNomineeSecondObj(nomineeTwoCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            nomSecondPhotoId: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isNomSecondPhoto: true,
          });
        } else if (id === "nomSecondSignature") {
          let nomineeTwoCopy = [...nomineeSecondObj];
          nomineeTwoCopy[1].fileName = res.data.Guid;
          setNomineeSecondObj(nomineeTwoCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            nomSecondSignature: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isNomSecondSignature: true,
          });
        } else if (id === "nomThirdPhotoId") {
          let nomineeThreeCopy = [...nomineeThirdObj];
          nomineeThreeCopy[0].fileName = res.data.Guid;
          setNomineeThirdObj(nomineeThreeCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            nomThirdPhotoId: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isNomThirdPhoto: true,
          });
        } else if (id === "nomThirdSignature") {
          let nomineeThreeCopy = [...nomineeThirdObj];
          nomineeThreeCopy[1].fileName = res.data.Guid;
          setNomineeThirdObj(nomineeThreeCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            nomThirdSignature: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isNomThirdSignature: true,
          });
        } else if (id === "guardianFirstPhotoId") {
          let GuardianOneCopy = [...guardianFirstObj];
          GuardianOneCopy[0].fileName = res.data.Guid;
          setGuardianFirstObj(GuardianOneCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            guardianFirstPhotoId: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isGuardianFirstPhoto: true,
          });
        } else if (id === "guardianFirstSignature") {
          let GuardianOneCopy = [...guardianFirstObj];
          GuardianOneCopy[1].fileName = res.data.Guid;
          setGuardianFirstObj(GuardianOneCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            guardianFirstSignature: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isGuardianFirstSignature: true,
          });
        } else if (id === "guardianSecondPhotoId") {
          let GuardianTwoCopy = [...guardianSecondObj];
          GuardianTwoCopy[0].fileName = res.data.Guid;
          setGuardianSecondObj(GuardianTwoCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            guardianSecondPhotoId: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isGuardianSecondPhoto: true,
          });
        } else if (id === "guardianSecondSignature") {
          setIsLoading(false);
          let GuardianTwoCopy = [...guardianSecondObj];
          GuardianTwoCopy[1].fileName = res.data.Guid;
          setGuardianSecondObj(GuardianTwoCopy);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            guardianSecondSignature: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isGuardianSecondSignature: true,
          });
        } else if (id === "guardianThirdPhotoId") {
          let GuardianThreeCopy = [...guardianThirdObj];
          GuardianThreeCopy[0].fileName = res.data.Guid;
          setGuardianThirdObj(GuardianThreeCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            guardianThirdPhotoId: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isGuardianThirdPhoto: true,
          });
        } else if (id === "guardianThirdSignature") {
          let GuardianThreeCopy = [...guardianThirdObj];
          GuardianThreeCopy[1].fileName = res.data.Guid;
          setGuardianThirdObj(GuardianThreeCopy);
          setIsLoading(false);
          setNomineeGuardianError({
            ...nomineeGuardianError,
            guardianThirdSignature: "",
          });
          setIsNomineeGuardian({
            ...isNomineeGuardian,
            isGuardianThirdSignature: true,
          });
        }
      } else {
        setErrCode(errCode + res.error);
        gtmService({
          action: "UploadDocumentToS3",
          label: "ERROR || ERR140-S",
        });
      }
    }
  };

  const onUploadProgress = (
    event: ProgressEvent,
    id: string,
    fileName: string,
    type: string
  ) => {
    const { loaded, total } = event;
    let percentage = Math.floor((loaded * 100) / total);
    let panCopy = { ...panObj };
    let firstBankCopy = { ...firstBankObj };
    let secondBankCopy = { ...secondBankObj };
    let thirdBankCopy = { ...thirdBankObj };
    let fourthBankCopy = { ...fourthBankObj };
    let fifthBankCopy = { ...fifthBankObj };
    let permAddressCopy = { ...permAddressObj };
    let permSecondAddressCopy = { ...permSecondAddressObj };
    let corrAddressCopy = { ...corrAddressObj };
    let corrSecondAddressCopy = { ...corrSecondAddressObj };
    let signatureCopy = { ...signatureObj };
    let incomeCopy = { ...incomeObj };
    let nomineeOneCopy = [...nomineeFirstObj];
    let nomineeTwoCopy = [...nomineeSecondObj];
    let nomineeThreeCopy = [...nomineeThirdObj];
    let GuardianOneCopy = [...guardianFirstObj];
    let GuardianTwoCopy = [...guardianSecondObj];
    let GuardianThreeCopy = [...guardianThirdObj];

    if (percentage < 100) {
      if (id === "panCard") {
        panCopy.id = id;
        panCopy.disable = false;
        panCopy.uploadPer = percentage;
        panCopy.hideProgress = true;
        panCopy.showFile = false;
        setPanObj(panCopy);
      } else if (id === "firstBankDoc") {
        firstBankCopy.id = id;
        firstBankCopy.disable = false;
        firstBankCopy.uploadPer = percentage;
        firstBankCopy.hideProgress = true;
        firstBankCopy.showFile = false;
        setFirstBankObj(firstBankCopy);
      } else if (id === "secondBankDoc") {
        secondBankCopy.id = id;
        secondBankCopy.disable = false;
        secondBankCopy.uploadPer = percentage;
        secondBankCopy.hideProgress = true;
        secondBankCopy.showFile = false;
        setSecondBankObj(secondBankCopy);
      } else if (id === "thirdBankDoc") {
        thirdBankCopy.id = id;
        thirdBankCopy.disable = false;
        thirdBankCopy.uploadPer = percentage;
        thirdBankCopy.hideProgress = true;
        thirdBankCopy.showFile = false;
        setThirdBankObj(thirdBankCopy);
      } else if (id === "fourthBankDoc") {
        fourthBankCopy.id = id;
        fourthBankCopy.disable = false;
        fourthBankCopy.uploadPer = percentage;
        fourthBankCopy.hideProgress = true;
        fourthBankCopy.showFile = false;
        setFourthBankObj(fourthBankCopy);
      } else if (id === "fifthBankDoc") {
        fifthBankCopy.id = id;
        fifthBankCopy.disable = false;
        fifthBankCopy.uploadPer = percentage;
        fifthBankCopy.hideProgress = true;
        fifthBankCopy.showFile = false;
        setFifthBankObj(fifthBankCopy);
      } else if (id === "permAddress") {
        permAddressCopy.id = id;
        permAddressCopy.disable = false;
        permAddressCopy.uploadPer = percentage;
        permAddressCopy.hideProgress = true;
        permAddressCopy.showFile = false;
        setPermAddressObj(permAddressCopy);
      } else if (id === "permSecondAddress") {
        permSecondAddressCopy.id = id;
        permSecondAddressCopy.disable = false;
        permSecondAddressCopy.uploadPer = percentage;
        permSecondAddressCopy.hideProgress = true;
        permSecondAddressCopy.showFile = false;
        setPermSecondAddressObj(permSecondAddressCopy);
      } else if (id === "corrAddress") {
        corrAddressCopy.id = id;
        corrAddressCopy.disable = false;
        corrAddressCopy.uploadPer = percentage;
        corrAddressCopy.hideProgress = true;
        corrAddressCopy.showFile = false;
        setCorrAddressObj(corrAddressCopy);
      } else if (id === "corrSecondAddress") {
        corrSecondAddressCopy.id = id;
        corrSecondAddressCopy.disable = false;
        corrSecondAddressCopy.uploadPer = percentage;
        corrSecondAddressCopy.hideProgress = true;
        corrSecondAddressCopy.showFile = false;
        setCorrSecondAddressObj(corrSecondAddressCopy);
      } else if (id === "signature") {
        signatureCopy.id = id;
        signatureCopy.disable = false;
        signatureCopy.uploadPer = percentage;
        signatureCopy.hideProgress = true;
        signatureCopy.showFile = false;
        setSignatureObj({ ...signatureCopy });
      } else if (id === "incomeDoc") {
        incomeCopy.id = id;
        incomeCopy.disable = false;
        incomeCopy.uploadPer = percentage;
        incomeCopy.hideProgress = true;
        incomeCopy.showFile = false;
        setIncomeObj(incomeCopy);
      } else if (id === "nomFirstPhotoId") {
        nomineeOneCopy[0].disable = false;
        nomineeOneCopy[0].uploadPer = percentage;
        nomineeOneCopy[0].hideProgress = true;
        nomineeOneCopy[0].showFile = false;
        setNomineeFirstObj(nomineeOneCopy);
      } else if (id === "nomFirstSignature") {
        nomineeOneCopy[1].disable = false;
        nomineeOneCopy[1].uploadPer = percentage;
        nomineeOneCopy[1].hideProgress = true;
        nomineeOneCopy[1].showFile = false;
        setNomineeFirstObj(nomineeOneCopy);
      } else if (id === "nomSecondPhotoId") {
        nomineeTwoCopy[0].disable = false;
        nomineeTwoCopy[0].uploadPer = percentage;
        nomineeTwoCopy[0].hideProgress = true;
        nomineeTwoCopy[0].showFile = false;
        setNomineeSecondObj(nomineeTwoCopy);
      } else if (id === "nomSecondSignature") {
        nomineeTwoCopy[1].disable = false;
        nomineeTwoCopy[1].uploadPer = percentage;
        nomineeTwoCopy[1].hideProgress = true;
        nomineeTwoCopy[1].showFile = false;
        setNomineeSecondObj(nomineeTwoCopy);
      } else if (id === "nomThirdPhotoId") {
        nomineeThreeCopy[0].disable = false;
        nomineeThreeCopy[0].uploadPer = percentage;
        nomineeThreeCopy[0].hideProgress = true;
        nomineeThreeCopy[0].showFile = false;
        setNomineeThirdObj(nomineeThreeCopy);
      } else if (id === "nomThirdSignature") {
        nomineeThreeCopy[1].disable = false;
        nomineeThreeCopy[1].uploadPer = percentage;
        nomineeThreeCopy[1].hideProgress = true;
        nomineeThreeCopy[1].showFile = false;
        setNomineeThirdObj(nomineeThreeCopy);
      } else if (id === "guardianFirstPhotoId") {
        GuardianOneCopy[0].disable = false;
        GuardianOneCopy[0].uploadPer = percentage;
        GuardianOneCopy[0].hideProgress = true;
        GuardianOneCopy[0].showFile = false;
        setGuardianFirstObj(GuardianOneCopy);
      } else if (id === "guardianFirstSignature") {
        GuardianOneCopy[1].disable = false;
        GuardianOneCopy[1].uploadPer = percentage;
        GuardianOneCopy[1].hideProgress = true;
        GuardianOneCopy[1].showFile = false;
        setGuardianFirstObj(GuardianOneCopy);
      } else if (id === "guardianSecondPhotoId") {
        GuardianTwoCopy[0].disable = false;
        GuardianTwoCopy[0].uploadPer = percentage;
        GuardianTwoCopy[0].hideProgress = true;
        GuardianTwoCopy[0].showFile = false;
        setGuardianSecondObj(GuardianTwoCopy);
      } else if (id === "guardianSecondSignature") {
        GuardianTwoCopy[1].disable = false;
        GuardianTwoCopy[1].uploadPer = percentage;
        GuardianTwoCopy[1].hideProgress = true;
        GuardianTwoCopy[1].showFile = false;
        setGuardianSecondObj(GuardianTwoCopy);
      } else if (id === "guardianThirdPhotoId") {
        GuardianThreeCopy[0].disable = false;
        GuardianThreeCopy[0].uploadPer = percentage;
        GuardianThreeCopy[0].hideProgress = true;
        GuardianThreeCopy[0].showFile = false;
        setGuardianThirdObj(GuardianThreeCopy);
      } else if (id === "guardianThirdSignature") {
        GuardianThreeCopy[1].disable = false;
        GuardianThreeCopy[1].uploadPer = percentage;
        GuardianThreeCopy[1].hideProgress = true;
        GuardianThreeCopy[1].showFile = false;
        setGuardianThirdObj(GuardianThreeCopy);
      }
    } else if (percentage === 100) {
      if (id === "panCard") {
        panCopy.id = id;
        panCopy.disable = false;
        panCopy.uploadPer = percentage;
        panCopy.showFile = false;
        panCopy.hideProgress = false;
        panCopy.fileName = fileName;
        panCopy.mimeType = type;
        setPanObj(panCopy);
      } else if (id === "firstBankDoc") {
        firstBankCopy.id = id;
        firstBankCopy.disable = false;
        firstBankCopy.uploadPer = percentage;
        firstBankCopy.fileName = fileName;
        firstBankCopy.showFile = false;
        firstBankCopy.hideProgress = false;
        firstBankCopy.mimeType = type;
        setFirstBankObj(firstBankCopy);
      } else if (id === "secondBankDoc") {
        secondBankCopy.id = id;
        secondBankCopy.disable = false;
        secondBankCopy.uploadPer = percentage;
        secondBankCopy.fileName = fileName;
        secondBankCopy.showFile = false;
        secondBankCopy.hideProgress = false;
        secondBankCopy.mimeType = type;
        setSecondBankObj(secondBankCopy);
      } else if (id === "thirdBankDoc") {
        thirdBankCopy.id = id;
        thirdBankCopy.disable = false;
        thirdBankCopy.uploadPer = percentage;
        thirdBankCopy.fileName = fileName;
        thirdBankCopy.showFile = false;
        thirdBankCopy.hideProgress = false;
        thirdBankCopy.mimeType = type;
        setThirdBankObj(thirdBankCopy);
      } else if (id === "fourthBankDoc") {
        fourthBankCopy.id = id;
        fourthBankCopy.disable = false;
        fourthBankCopy.uploadPer = percentage;
        fourthBankCopy.fileName = fileName;
        fourthBankCopy.showFile = false;
        fourthBankCopy.hideProgress = false;
        fourthBankCopy.mimeType = type;
        setFourthBankObj(fourthBankCopy);
      } else if (id === "fifthBankDoc") {
        fifthBankCopy.id = id;
        fifthBankCopy.disable = false;
        fifthBankCopy.uploadPer = percentage;
        fifthBankCopy.fileName = fileName;
        fifthBankCopy.showFile = false;
        fifthBankCopy.hideProgress = false;
        fifthBankCopy.mimeType = type;
        setFifthBankObj(fifthBankCopy);
      } else if (id === "permAddress") {
        permAddressCopy.id = id;
        permAddressCopy.disable = false;
        permAddressCopy.uploadPer = percentage;
        permAddressCopy.showFile = false;
        permAddressCopy.hideProgress = false;
        permAddressCopy.mimeType = type;
        permAddressCopy.fileName = fileName;
        setPermAddressObj(permAddressCopy);
      } else if (id === "permSecondAddress") {
        permSecondAddressCopy.id = id;
        permSecondAddressCopy.disable = false;
        permSecondAddressCopy.uploadPer = percentage;
        permSecondAddressCopy.showFile = false;
        permSecondAddressCopy.hideProgress = false;
        permSecondAddressCopy.mimeType = type;
        permSecondAddressCopy.fileName = fileName;
        setPermSecondAddressObj(permSecondAddressCopy);
      } else if (id === "corrAddress") {
        corrAddressCopy.id = id;
        corrAddressCopy.disable = false;
        corrAddressCopy.uploadPer = percentage;
        corrAddressCopy.showFile = false;
        corrAddressCopy.hideProgress = false;
        corrAddressCopy.mimeType = type;
        corrAddressCopy.fileName = fileName;
        setCorrAddressObj(corrAddressCopy);
      } else if (id === "corrSecondAddress") {
        corrSecondAddressCopy.id = id;
        corrSecondAddressCopy.disable = false;
        corrSecondAddressCopy.uploadPer = percentage;
        corrSecondAddressCopy.showFile = false;
        corrSecondAddressCopy.hideProgress = false;
        corrSecondAddressCopy.mimeType = type;
        corrSecondAddressCopy.fileName = fileName;
        setCorrSecondAddressObj(corrSecondAddressCopy);
      } else if (id === "signature") {
        signatureCopy.id = id;
        signatureCopy.disable = false;
        signatureCopy.uploadPer = percentage;
        signatureCopy.showFile = false;
        signatureCopy.hideProgress = false;
        signatureCopy.mimeType = type;
        signatureCopy.fileName = fileName;
        setSignatureObj(signatureCopy);
      } else if (id === "incomeDoc") {
        incomeCopy.id = id;
        incomeCopy.disable = false;
        incomeCopy.uploadPer = percentage;
        incomeCopy.showFile = false;
        incomeCopy.hideProgress = false;
        incomeCopy.mimeType = type;
        incomeCopy.fileName = fileName;
        setIncomeObj(incomeCopy);
      } else if (id === "nomFirstPhotoId") {
        nomineeOneCopy[0].disable = false;
        nomineeOneCopy[0].uploadPer = percentage;
        nomineeOneCopy[0].showFile = false;
        nomineeOneCopy[0].hideProgress = false;
        nomineeOneCopy[0].mimeType = type;
        setNomineeFirstObj(nomineeOneCopy);
      } else if (id === "nomFirstSignature") {
        nomineeOneCopy[1].disable = false;
        nomineeOneCopy[1].uploadPer = percentage;
        nomineeOneCopy[1].showFile = false;
        nomineeOneCopy[1].hideProgress = false;
        nomineeOneCopy[1].mimeType = type;
        setNomineeFirstObj(nomineeOneCopy);
      } else if (id === "nomSecondPhotoId") {
        nomineeTwoCopy[0].disable = false;
        nomineeTwoCopy[0].uploadPer = percentage;
        nomineeTwoCopy[0].showFile = false;
        nomineeTwoCopy[0].hideProgress = false;
        nomineeTwoCopy[0].mimeType = type;
        setNomineeSecondObj(nomineeTwoCopy);
      } else if (id === "nomSecondSignature") {
        nomineeTwoCopy[1].disable = false;
        nomineeTwoCopy[1].uploadPer = percentage;
        nomineeTwoCopy[1].showFile = false;
        nomineeTwoCopy[1].hideProgress = false;
        nomineeTwoCopy[1].mimeType = type;
        setNomineeSecondObj(nomineeTwoCopy);
      } else if (id === "nomThirdPhotoId") {
        nomineeThreeCopy[0].disable = false;
        nomineeThreeCopy[0].uploadPer = percentage;
        nomineeThreeCopy[0].showFile = false;
        nomineeThreeCopy[0].hideProgress = false;
        nomineeThreeCopy[0].mimeType = type;
        setNomineeThirdObj(nomineeThreeCopy);
      } else if (id === "nomThirdSignature") {
        nomineeThreeCopy[1].disable = false;
        nomineeThreeCopy[1].uploadPer = percentage;
        nomineeThreeCopy[1].showFile = false;
        nomineeThreeCopy[1].hideProgress = false;
        nomineeThreeCopy[1].mimeType = type;
        setNomineeThirdObj(nomineeThreeCopy);
      } else if (id === "guardianFirstPhotoId") {
        GuardianOneCopy[0].disable = false;
        GuardianOneCopy[0].uploadPer = percentage;
        GuardianOneCopy[0].showFile = false;
        GuardianOneCopy[0].hideProgress = false;
        GuardianOneCopy[0].mimeType = type;
        setGuardianFirstObj(GuardianOneCopy);
      } else if (id === "guardianFirstSignature") {
        GuardianOneCopy[1].disable = false;
        GuardianOneCopy[1].uploadPer = percentage;
        GuardianOneCopy[1].showFile = false;
        GuardianOneCopy[1].hideProgress = false;
        GuardianOneCopy[1].mimeType = type;
        setGuardianFirstObj(GuardianOneCopy);
      } else if (id === "guardianSecondPhotoId") {
        GuardianTwoCopy[0].disable = false;
        GuardianTwoCopy[0].uploadPer = percentage;
        GuardianTwoCopy[0].showFile = false;
        GuardianTwoCopy[0].hideProgress = false;
        GuardianTwoCopy[0].mimeType = type;
        setGuardianSecondObj(GuardianTwoCopy);
      } else if (id === "guardianSecondSignature") {
        GuardianTwoCopy[1].disable = false;
        GuardianTwoCopy[1].uploadPer = percentage;
        GuardianTwoCopy[1].showFile = false;
        GuardianTwoCopy[1].hideProgress = false;
        GuardianTwoCopy[1].mimeType = type;
        setGuardianSecondObj(GuardianTwoCopy);
      } else if (id === "guardianThirdPhotoId") {
        GuardianThreeCopy[0].disable = false;
        GuardianThreeCopy[0].uploadPer = percentage;
        GuardianThreeCopy[0].showFile = false;
        GuardianThreeCopy[0].hideProgress = false;
        GuardianThreeCopy[0].mimeType = type;
        setGuardianThirdObj(GuardianThreeCopy);
      } else if (id === "guardianThirdSignature") {
        GuardianThreeCopy[1].disable = false;
        GuardianThreeCopy[1].uploadPer = percentage;
        GuardianThreeCopy[1].showFile = false;
        GuardianThreeCopy[1].hideProgress = false;
        GuardianThreeCopy[1].mimeType = type;
        setGuardianThirdObj(GuardianThreeCopy);
      }
    }
  };

  const storeOcrData = (res: any, id: any) => {
    if (!res || res.error) {
      // setErrCode("Invalid Image");
      setRemovePanImg(true);
      setIsNewPan(false);
      // setErrCode(res.data.message);
      setErrCode(errCode + res.error);
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR134" });
      setIsLoading(false);
    } else if (res.data) {
      setErrCode(errCode);
      setIsLoading(false);
      if (id.documentid === panDocumentId) {
        setRemovePanImg(false);
        setIsNewPan(true);
        setPanCardError("");

        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        if (
          clientPartialDetails_ao.pan !==
            res.data.result.extraction_output.id_number &&
          moment(clientPartialDetails_ao.birthDate).format("YYYY-MM-DD") !==
            res.data.result.extraction_output.date_of_birth
        ) {
          setIsPanAndDobMatch(false);
          setErrCode(errorMessage.imgNotMatchWithPan);
          setPanCardError(errorMessage.imgNotMatchWithPan);
        } else {
          setIsPanAndDobMatch(true);
          setPanCardError("");
          setErrCode("");
        }

        GlobalStore.panOCRObj = res.data.result.extraction_output;
      } else if (
        id.documentid === firstChequeDocumnetId ||
        id.documentid === firstBankStatementDocumnetId
      ) {
        if (!bankStage) {
          GlobalStore.chequeOCRObj = res.data.result.extraction_output;
        }
      } else if (
        id.documentid === secondChequeDocumnetId ||
        id.documentid === secondBankStatementDocumnetId
      ) {
        if (!bankStage) {
          GlobalStore.chequeOCRObj = res.data.result.extraction_output;
        }
      } else if (
        id.documentid === thirdChequeDocumnetId ||
        id.documentid === thirdBankStatementDocumnetId
      ) {
        if (!bankStage) {
          GlobalStore.chequeOCRObj = res.data.result.extraction_output;
        }
      } else if (
        id.documentid === fourthChequeDocumnetId ||
        id.documentid === fourthBankStatementDocumnetId
      ) {
        if (!bankStage) {
          GlobalStore.chequeOCRObj = res.data.result.extraction_output;
        }
      } else if (
        id.documentid === fifthChequeDocumnetId ||
        id.documentid === fifthBankStatementDocumnetId
      ) {
        if (!bankStage) {
          GlobalStore.chequeOCRObj = res.data.result.extraction_output;
        }
      } else if (id.documentid === aadhaarDocumnetId) {
        setAadharAddress(false);
        // if (anotherFile)
        //     GlobalStore.aadhaarOCRObj = { ...GlobalStore.aadhaarOCRObj, ...res.data.result.extraction_output };
        // else
        GlobalStore.aadhaarOCRObj =
          res.data.result !== null ? res.data.result.extraction_output : "";
        // console.log('GlobalStore.aadhaarOCRObj: ', GlobalStore.aadhaarOCRObj);
        if (!GlobalStore.aadhaarOCRObj.address) {
          alert(
            // "Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
        } else {
          setAadharAddress(true);
        }
      } else if (id.documentid === passportDocumnetId) {
        GlobalStore.passportOCRObj = res.data.result.extraction_output;
      } else if (id.documentid === voterIdDocumentId) {
        GlobalStore.voterIdOCRObj = res.data.result.extraction_output;
      } else if (id.documentid === dlDocumentId) {
        GlobalStore.dlOCRObj = res.data.result.extraction_output;
      } else if (id.documentid === corrAadhaarDocumnetId) {
        setCorrAadharAddress(false);
        // if (corrAnotherFile)
        //     GlobalStore.corrAadhaarOCRObj = {
        //         ...GlobalStore.corrAadhaarOCRObj,
        //         ...res.data.result.extraction_output,
        //     };
        // else
        GlobalStore.corrAadhaarOCRObj = res.data.result.extraction_output;
        // console.log('GlobalStore.corrAadhaarOCRObj: ', GlobalStore.corrAadhaarOCRObj);
        if (!GlobalStore.corrAadhaarOCRObj.address) {
          alert(
            //"Address is not present in the uploaded file, upload back side of the Aadhar as well."
            "Address is not available, please ensure that you upload the 2nd Side of Aadhar"
          );
        } else {
          setCorrAadharAddress(true);
        }
      } else if (id.documentid === corrPassportDocumnetId) {
        GlobalStore.corrPassportOCRObj = res.data.result.extraction_output;
      } else if (id.documentid === corrVoterIdDocumentId) {
        GlobalStore.corrVoterIdOCRObj = res.data.result.extraction_output;
      } else if (id.documentid === corrDlDocumentId) {
        GlobalStore.corrDlOCRObj = res.data.result.extraction_output;
      }
    } else {
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR134-S" });
    }
  };

  const uploadDoc = () => {
    gtmService({
      action: "DOCUMENT_VERIFICATION",
      label: "DOCUMENT_UPLOAD | UPLOAD",
    });
    setUploadDocFlag(true);
    setUploadViaWatsAppFlag(false);
  };

  const uploadViaWatsApp = () => {
    gtmService({
      action: "DOCUMENT_VERIFICATION",
      label: "DOCUMENT_UPLOAD | WHATSAPP",
    });
    setUploadDocFlag(false);
    setUploadViaWatsAppFlag(true);
  };

  const saveViaWatsApp = () => {
    const saveDetails = [];
    if (photoObj && photoObj.fileName === "") {
      let savePhotoObj = {
        documentCategoryId: photoObj.documentCategoryId?.toString(),
        documentId: photoObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        personaldetailsid: "0",
        autoqc: autoQc,
        iskycdocument: false,
        isWatsappFlag: true,
      };
      saveDetails.push(savePhotoObj);
    }

    if (
      photoObj &&
      isNewPhoto &&
      (photoObj.fileName !== "" || photoObj.fileName !== undefined)
    ) {
      let savePhotoObj = {
        documentCategoryId: photoObj.documentCategoryId?.toString(),
        documentId: photoObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: photoObj.fileName,
        isfilecropped: true,
        mimeType: photoObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        personaldetailsid: "0",
        autoqc: autoQc,
        iskycdocument: false,
        isWatsappFlag: false,
      };
      saveDetails.push(savePhotoObj);
    }
    if (panObj && panObj.fileName === "") {
      console.log("panObj && panObj.fileName === ");
      let savePanObj = {
        documentCategoryId: panObj.documentCategoryId?.toString(),
        documentId: panObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(savePanObj);
      console.log("savePanObj", savePanObj);
    }

    if (
      firstBankObj &&
      firstBankObj.fileName === "" &&
      firstBankObj.documentCategoryId !== ""
    ) {
      let firstBanObj = {
        documentCategoryId: firstBankObj.documentCategoryId?.toString(),
        documentId: firstBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(firstBanObj);
    }

    if (
      secondBankObj &&
      secondBankObj.fileName === "" &&
      secondBankObj.documentCategoryId !== ""
    ) {
      let saveSecondBankObj = {
        documentCategoryId: secondBankObj.documentCategoryId?.toString(),
        documentId: secondBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveSecondBankObj);
    }

    if (
      thirdBankObj &&
      thirdBankObj.fileName === "" &&
      thirdBankObj.documentCategoryId !== ""
    ) {
      let saveThirdBankObj = {
        documentCategoryId: thirdBankObj.documentCategoryId?.toString(),
        documentId: thirdBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveThirdBankObj);
    }

    if (
      fourthBankObj &&
      fourthBankObj.fileName === "" &&
      fourthBankObj.documentCategoryId !== ""
    ) {
      let saveFourthBankObj = {
        documentCategoryId: fourthBankObj.documentCategoryId?.toString(),
        documentId: fourthBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveFourthBankObj);
    }

    if (
      fifthBankObj &&
      fifthBankObj.fileName === "" &&
      fifthBankObj.documentCategoryId !== ""
    ) {
      let saveFifthBankObj = {
        documentCategoryId: fifthBankObj.documentCategoryId?.toString(),
        documentId: fifthBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveFifthBankObj);
    }

    if (
      permAddressObj &&
      permAddressObj.fileName === "" &&
      permAddressObj.documentCategoryId !== ""
    ) {
      let savepermAddressObj = {
        documentCategoryId: permAddressObj.documentCategoryId?.toString(),
        documentId: permAddressObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(savepermAddressObj);
    }
    if (
      corrAddressObj &&
      corrAddressObj.fileName === "" &&
      corrAddressObj.documentCategoryId !== ""
    ) {
      let saveCorrAddressObj = {
        documentCategoryId: corrAddressObj.documentCategoryId?.toString(),
        documentId: corrAddressObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveCorrAddressObj);
    }

    if (
      signatureObj &&
      signatureObj.fileName === "" &&
      signatureObj.documentCategoryId !== ""
    ) {
      let saveSignatureObj = {
        documentCategoryId: signatureObj.documentCategoryId?.toString(),
        documentId: signatureObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveSignatureObj);
    }

    if (
      incomeObj &&
      incomeObj.fileName === "" &&
      incomeObj.documentCategoryId !== ""
    ) {
      let saveIncomeObj = {
        documentCategoryId: incomeObj.documentCategoryId?.toString(),
        documentId: incomeObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: ".",
        isfilecropped: true,
        mimeType: ".",
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: true,
        personaldetailsid: "0",
        autoqc: false,
        iskycdocument: false,
      };
      saveDetails.push(saveIncomeObj);
    }

    const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");

    const saveObj = {
      applicationId: GlobalStore.applicationID,
      clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
      documentInfo: saveDetails,
      leadId: GlobalStore.leadId,
      objUTM_Info: objUTM_Info,
    };
    console.log("LineNO-2698 saveObj", saveObj);
    ASaveDocumentDetails(getSaveStatus, saveObj);
  };

  const sendViaWatsapp = (val: string) => {
    if (val === "Pan") {
      if (togPAN === true) {
        setTogPAN(false);
      } else {
        panObj.isWatsappFlag = true;
        panObj.fileName = ".";
        panObj.mimeType = ".";
        setTogPAN(true);
      }
    } else if (val === "FirstBank") {
      if (togFirstBank === true) {
        setTogFirstBank(false);
      } else {
        incomeObj.isWatsappFlag = true;
        incomeObj.fileName = ".";
        incomeObj.mimeType = ".";
        setTogFirstBank(true);
      }
    } else if (val === "SecondBank") {
      if (togSecondBank === true) {
        setTogSecondBank(false);
      } else {
        incomeObj.isWatsappFlag = true;
        incomeObj.fileName = ".";
        incomeObj.mimeType = ".";
        setTogSecondBank(true);
      }
    } else if (val === "ThirdBank") {
      if (togFirstBank === true) {
        setTogThirdBank(false);
      } else {
        incomeObj.isWatsappFlag = true;
        incomeObj.fileName = ".";
        incomeObj.mimeType = ".";
        setTogThirdBank(true);
      }
    } else if (val === "FourthBank") {
      if (togFourthBank === true) {
        setTogFourthBank(false);
      } else {
        incomeObj.isWatsappFlag = true;
        incomeObj.fileName = ".";
        incomeObj.mimeType = ".";
        setTogFourthBank(true);
      }
    } else if (val === "FifthBank") {
      if (togFifthBank === true) {
        setTogFirstBank(false);
      } else {
        incomeObj.isWatsappFlag = true;
        incomeObj.fileName = ".";
        incomeObj.mimeType = ".";
        setTogFifthBank(true);
      }
    } else if (val === "PermAddress") {
      if (togPermAdd === true) {
        setTogPermAdd(false);
      } else {
        permAddressObj.isWatsappFlag = true;
        permAddressObj.fileName = ".";
        permAddressObj.mimeType = ".";
        setTogPermAdd(true);
      }
    } else if (val === "CorrAddress") {
      if (togCorrAdd === true) {
        setTogCorrAdd(false);
      } else {
        corrAddressObj.isWatsappFlag = true;
        corrAddressObj.fileName = ".";
        corrAddressObj.mimeType = ".";
        setTogCorrAdd(true);
      }
    } else if (val === "Signature") {
      if (togSig === true) {
        setTogSig(false);
      } else {
        signatureObj.isWatsappFlag = true;
        signatureObj.fileName = ".";
        signatureObj.mimeType = ".";
        setTogSig(true);
      }
    } else if (val === "Income") {
      if (togIncome === true) {
        setTogIncome(false);
      } else {
        incomeObj.isWatsappFlag = true;
        incomeObj.fileName = ".";
        incomeObj.mimeType = ".";
        setTogIncome(true);
      }
    } else if (val === "nomineeFirst") {
      if (nomineeGuardianTog.nomineeFirst === true) {
        setNomineeGuardianTog({ ...nomineeGuardianTog, nomineeFirst: false });
      } else {
        let nomineeFirst = [...nomineeFirstObj];
        nomineeFirst[0].isWatsappFlag = true;
        nomineeFirst[0].fileName = ".";
        nomineeFirst[0].mimeType = ".";
        nomineeFirst[1].isWatsappFlag = true;
        nomineeFirst[1].fileName = ".";
        nomineeFirst[1].mimeType = ".";
        setNomineeFirstObj(nomineeFirst);
        setNomineeGuardianTog({ ...nomineeGuardianTog, nomineeFirst: true });
      }
    } else if (val === "nomineeSecond") {
      if (nomineeGuardianTog.nomineeSecond === true) {
        setNomineeGuardianTog({ ...nomineeGuardianTog, nomineeSecond: false });
      } else {
        let nomineeSecond = [...nomineeSecondObj];
        nomineeSecond[0].isWatsappFlag = true;
        nomineeSecond[0].fileName = ".";
        nomineeSecond[0].mimeType = ".";
        nomineeSecond[1].isWatsappFlag = true;
        nomineeSecond[1].fileName = ".";
        nomineeSecond[1].mimeType = ".";
        setNomineeSecondObj(nomineeSecond);
        setNomineeGuardianTog({ ...nomineeGuardianTog, nomineeSecond: true });
      }
    } else if (val === "nomineeThree") {
      if (nomineeGuardianTog.nomineeThree === true) {
        setNomineeGuardianTog({ ...nomineeGuardianTog, nomineeThree: false });
      } else {
        let nomineeThree = [...nomineeSecondObj];
        nomineeThree[0].isWatsappFlag = true;
        nomineeThree[0].fileName = ".";
        nomineeThree[0].mimeType = ".";
        nomineeThree[1].isWatsappFlag = true;
        nomineeThree[1].fileName = ".";
        nomineeThree[1].mimeType = ".";
        setNomineeSecondObj(nomineeThree);
        setNomineeGuardianTog({ ...nomineeGuardianTog, nomineeThree: true });
      }
    } else if (val === "guardianFirst") {
      if (nomineeGuardianTog.guardianFirst === true) {
        setNomineeGuardianTog({ ...nomineeGuardianTog, guardianFirst: false });
      } else {
        let guardianFirst = [...guardianFirstObj];
        guardianFirst[0].isWatsappFlag = true;
        guardianFirst[0].fileName = ".";
        guardianFirst[0].mimeType = ".";
        guardianFirst[1].isWatsappFlag = true;
        guardianFirst[1].fileName = ".";
        guardianFirst[1].mimeType = ".";
        setGuardianFirstObj(guardianFirst);
        setNomineeGuardianTog({ ...nomineeGuardianTog, guardianFirst: true });
      }
    } else if (val === "guardianSecond") {
      if (nomineeGuardianTog.guardianSecond === true) {
        setNomineeGuardianTog({ ...nomineeGuardianTog, guardianSecond: false });
      } else {
        let guardianSecond = [...guardianSecondObj];
        guardianSecond[0].isWatsappFlag = true;
        guardianSecond[0].fileName = ".";
        guardianSecond[0].mimeType = ".";
        guardianSecond[1].isWatsappFlag = true;
        guardianSecond[1].fileName = ".";
        guardianSecond[1].mimeType = ".";
        setGuardianSecondObj(guardianSecond);
        setNomineeGuardianTog({ ...nomineeGuardianTog, guardianSecond: true });
      }
    } else if (val === "guardianThird") {
      if (nomineeGuardianTog.guardianThird === true) {
        setNomineeGuardianTog({ ...nomineeGuardianTog, guardianThird: false });
      } else {
        let guardianThird = [...guardianSecondObj];
        guardianThird[0].isWatsappFlag = true;
        guardianThird[0].fileName = ".";
        guardianThird[0].mimeType = ".";
        guardianThird[1].isWatsappFlag = true;
        guardianThird[1].fileName = ".";
        guardianThird[1].mimeType = ".";
        setGuardianThirdObj(guardianThird);
        setNomineeGuardianTog({ ...nomineeGuardianTog, guardianThird: true });
      }
    }
  };
  const handleBankDoc = (val: any) => {
    console.log("handleBankDoc", val);
    if (val) {
      if (val.DocumentCategoryId === 9) {
        firstBankObj.disable = false;
        firstBankObj.documentId = val.DocumentId;
        setFirstBankObj(firstBankObj);
        setDefaultFirstBankDoc(val.DocumentName);
      } else if (val.DocumentCategoryId === 60) {
        secondBankObj.disable = false;
        secondBankObj.documentId = val.DocumentId;
        setSecondBankObj(secondBankObj);
        setDefaultSecondBankDoc(val.DocumentName);
      } else if (val.DocumentCategoryId === 61) {
        thirdBankObj.disable = false;
        thirdBankObj.documentId = val.DocumentId;
        setThirdBankObj(thirdBankObj);
        setDefaultThirdBankDoc(val.DocumentName);
      } else if (val.DocumentCategoryId === 70) {
        fourthBankObj.disable = false;
        fourthBankObj.documentId = val.DocumentId;
        setFourthBankObj(fourthBankObj);
        setDefaultFourthBankDoc(val.DocumentName);
      } else if (val.DocumentCategoryId === 71) {
        fifthBankObj.disable = false;
        fifthBankObj.documentId = val.DocumentId;
        setFifthBankObj(fifthBankObj);
        setDefaultFifthBankDoc(val.DocumentName);
      }
    }
  };

  const handlePermanentAddress = (val: any) => {
    console.log("handlePermanentAddress", val);
    if (val) {
      if (
        val.DocumentId === 125 ||
        permAddressObj.mimeType === "application/pdf"
      ) {
        setSecondDoc(false);
        setAnotherFile(false);
      } else {
        setSecondDoc(true);
      }
      // setIsFirstAdd(false);
      permAddressObj.disable = blockAadhar;
      permAddressObj.documentId = val.DocumentId;
      setPermAddressObj(permAddressObj);
      setDefaultAddress(val.DocumentName);
    }
  };

  const handleCorrespondenceAddress = (val: any) => {
    if (val) {
      if (
        val.DocumentId === 133 ||
        corrAddressObj.mimeType === "application/pdf"
      ) {
        setCorrSecondDoc(false);
        setCorrAnotherFile(false);
      } else {
        setCorrSecondDoc(true);
      }
      corrAddressObj.disable = false;
      corrAddressObj.documentId = val.DocumentId;
      setCorrAddressObj(corrAddressObj);
      setDefaultCorrAddress(val.DocumentName);
    }
  };

  const handleIncomeProof = (val: any) => {
    console.log("handleIncomeProof", val);
    if (val !== "") {
      incomeObj.disable = false;
      incomeObj.documentId = val.DocumentId;
      setIncomeObj(incomeObj);
      setDefaultIncomeDoc(val.DocumentName);
    }
  };

  const addDocument = () => {
    if (secondDoc) {
      setAnotherFile(true);
    } else {
      setAnotherFile(false);
    }
  };

  const addCorrDocument = () => {
    if (corrSecondDoc) {
      setCorrAnotherFile(true);
    } else {
      setCorrAnotherFile(false);
    }
  };

  const handleSubmit = () => {
    setLoaderSign(true);
    setIsLoading(true);
    // let listOfAutoQcDocumentObj: any = [];
    // if (photoObj && (photoObj.imgUrl || photoBase64)) {
    //   listOfAutoQcDocumentObj.push({
    //     Base64Document: photoObj.imgUrl
    //       ? photoObj.imgUrl.val!.split(",")[1]
    //       : photoBase64,
    //     DocumentId: photographId,
    //   });
    // }
    // if (panObj && (panObj.imgUrl || panBase64)) {
    //   listOfAutoQcDocumentObj.push({
    //     Base64Document: panObj.imgUrl ? panObj.imgUrl.split(",")[1] : panBase64,
    //     DocumentId: panDocumentId,
    //   });
    // }
    // if (permAddressObj && (permAddressObj.imgUrl || permAddressObj.base64)) {
    //   listOfAutoQcDocumentObj.push({
    //     Base64Document: permAddressObj.imgUrl
    //       ? permAddressObj.imgUrl.split(",")[1]
    //       : permAddressObj.base64,
    //     DocumentId: permAddressObj.documentId,
    //   });
    // }
    saveDocument();

    // AgetDocumentAutoQcDetails(
    //   (res: any) => getDocumentAutoQcRes(res),
    //   listOfAutoQcDocumentObj,
    //   GlobalStore.applicationID,
    //   "Selfie"
    // );
    // }

    // if (location !== undefined && location !== "") {
    //   if (
    //     photoBase64 !== undefined &&
    //     photoBase64 !== "" &&
    //     panBase64 !== undefined &&
    //     panBase64 !== ""
    //   ) {
    //     const faceObj = {
    //       base64document1: photoBase64,
    //       base64document2: panBase64,
    //       source: "DIY",
    //     };
    //     if (!isPhotoPendingFlag) {
    //       Afacecompare(getFaceCompareResponse, faceObj);
    //     } else {
    //       saveDocument();
    //     }
    //   } else {
    //     saveDocument();
    //   }
    // } else {
    //   setBrowserPermission(false);
    // }
  };

  const getDocumentAutoQcRes = (res: any) => {
    // saveDocument(true,"1");
    if (!res || res.error) {
      setErrCode(errCode + res.error);
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR135" });
      setIsLoading(false);
      if (!panObj.isWatsappFlag) {
        // setErrCode("Please Upload Pan Card and take selfie");
        setErrCode(errCode + res.error);
      }
      setLoaderSign(false);
    } else {
      let resObj = res.data;
      if (resObj && resObj.IsAutoQC) {
        saveDocument(resObj.IsAutoQC, res.data.AutoQcWithDocumentId);
      } else {
        setErrCode(res.data.Message);
      }
      setIsLoading(false);
      setLoaderSign(false);
    }
  };
  // const getFaceCompareResponse = (res: any) => {
  //   if (!res || res.error) {
  //     gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR135" });
  //     setIsLoading(false);
  //     if (!panObj.isWatsappFlag) {
  //       // setErrCode("Please Upload Pan Card and take selfie");
  //       setErrCode(res.error);
  //     }
  //     setLoaderSign(false);
  //     saveDocument();
  //     //setErrCode(155);
  //   } else {
  //     if (res.data) {
  //       if (res.data.result !== undefined) {
  //         if (res.data.result.match_score > 60) {
  //           setAutoQc(true);
  //           saveDocument();
  //         } else if (
  //           res.data.result.match_score > 40 &&
  //           res.data.result.match_score <= 60
  //         ) {
  //           setAutoQc(false);
  //           saveDocument();
  //         } else {
  //           setAutoQc(false);
  //           saveDocument();
  //         }
  //       } else {
  //         setAutoQc(false);
  //         saveDocument();
  //         gtmService({
  //           action: "ExtractDocumentData",
  //           label: "ERROR || ERR135-S",
  //         });
  //       }
  //     }
  //   }
  // };
  const saveDocument = (autoQc?: boolean, autoQcWithDocumentId?: string) => {
    const saveDetails = [];
    if (photoObj && isNewPhoto) {
      let savePhotoObj = {
        documentCategoryId: photoObj.documentCategoryId?.toString(),
        documentId: photoObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: photoObj.fileName,
        isfilecropped: true,
        mimeType: photoObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        personaldetailsid: "0",
        autoqc: false,
        isWatsappFlag: false,
        // "autoqc": autoQc
        AutoQcWithDocumentId: autoQcWithDocumentId,
      };
      if (!autoQcWithDocumentId) {
        delete savePhotoObj.AutoQcWithDocumentId;
      }
      saveDetails.push(savePhotoObj);
    }

    if ((panObj.uploadPer === 100 && isNewPan) || panObj.isWatsappFlag) {
      let savePanObj = {
        documentCategoryId: panObj.documentCategoryId?.toString(),
        documentId: panObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: panName || panObj.fileName,
        isfilecropped: true,
        mimeType: panObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: panObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(savePanObj);
    }
    console.log("LineNo-2977 saveDetails", saveDetails);

    if (
      (firstBankObj.uploadPer === 100 && isNewFirstBank) ||
      firstBankObj.isWatsappFlag
    ) {
      let firstBanObj = {
        documentCategoryId: firstBankObj.documentCategoryId?.toString(),
        documentId: firstBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: firstBankName || firstBankObj.fileName,
        isfilecropped: true,
        mimeType: firstBankObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: firstBankObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(firstBanObj);
    }

    if (
      (secondBankObj.uploadPer === 100 && isNewSecondBank) ||
      secondBankObj.isWatsappFlag
    ) {
      let saveSecondBankObj = {
        documentCategoryId: secondBankObj.documentCategoryId?.toString(),
        documentId: secondBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: secondBankName || secondBankObj.fileName,
        isfilecropped: true,
        mimeType: secondBankObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: secondBankObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveSecondBankObj);
    }

    if (
      (thirdBankObj.uploadPer === 100 && isNewThirdBank) ||
      thirdBankObj.isWatsappFlag
    ) {
      let saveThirdBankObj = {
        documentCategoryId: thirdBankObj.documentCategoryId?.toString(),
        documentId: thirdBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: thirdBankName || thirdBankObj.fileName,
        isfilecropped: true,
        mimeType: thirdBankObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: thirdBankObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveThirdBankObj);
    }

    if (
      (fourthBankObj.uploadPer === 100 && isNewFourthBank) ||
      fourthBankObj.isWatsappFlag
    ) {
      let saveFourthBankObj = {
        documentCategoryId: fourthBankObj.documentCategoryId?.toString(),
        documentId: fourthBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: fourthBankName || fourthBankObj.fileName,
        isfilecropped: true,
        mimeType: fourthBankObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: fourthBankObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveFourthBankObj);
    }

    if (
      (fifthBankObj.uploadPer === 100 && isNewFifthBank) ||
      fifthBankObj.isWatsappFlag
    ) {
      let saveFifthBankObj = {
        documentCategoryId: fifthBankObj.documentCategoryId?.toString(),
        documentId: fifthBankObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: fifthBankName || fifthBankObj.fileName,
        isfilecropped: true,
        mimeType: fifthBankObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: fifthBankObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveFifthBankObj);
    }
    console.log("LineNo-3072 saveDetails", saveDetails);

    if (
      (permAddressObj.uploadPer === 100 && isNewPermAddress) ||
      permAddressObj.isWatsappFlag
    ) {
      let savepermAddressObj = {
        documentCategoryId: permAddressObj.documentCategoryId?.toString(),
        documentId: permAddressObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: addressName || permAddressObj.fileName,
        isfilecropped: true,
        mimeType: permAddressObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: permAddressObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(savepermAddressObj);
    }
    if (
      (corrAddressObj.uploadPer === 100 && isNewCorrAddress) ||
      corrAddressObj.isWatsappFlag
    ) {
      let saveCorrAddressObj = {
        documentCategoryId: corrAddressObj.documentCategoryId?.toString(),
        documentId: corrAddressObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: corrAddressName || corrAddressObj.fileName,
        isfilecropped: true,
        mimeType: corrAddressObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: corrAddressObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveCorrAddressObj);
    }
    console.log("LineNo-3110 saveDetails", saveDetails);

    if (
      (signatureObj.uploadPer === 100 && isNewSignature) ||
      signatureObj.isWatsappFlag
    ) {
      let saveSignatureObj = {
        documentCategoryId: signatureObj.documentCategoryId?.toString(),
        documentId: signatureObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: signatureName || signatureObj.fileName,
        isfilecropped: true,
        mimeType: signatureObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: signatureObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveSignatureObj);
    }
    console.log("LineNo-3130 saveDetails", saveDetails);

    if (
      (incomeObj.uploadPer === 100 && isNewIncome) ||
      incomeObj.isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: incomeObj.documentCategoryId?.toString(),
        documentId: incomeObj.documentId?.toString(),
        documentexpirydate: "",
        fileName: incomeName || incomeObj.fileName,
        isfilecropped: true,
        mimeType: incomeObj.mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: incomeObj.isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (nomineeFirstObj[0].uploadPer === 100 &&
        isNomineeGuardian.isNomFirstPhoto) ||
      nomineeFirstObj[0].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: nomineeFirstObj[0].documentCategoryId?.toString(),
        documentId: nomineeFirstObj[0].documentId?.toString(),
        documentexpirydate: "",
        fileName: nomineeFirstObj[0].fileName,
        isfilecropped: true,
        mimeType: nomineeFirstObj[0].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: nomineeFirstObj[0].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (nomineeFirstObj[1].uploadPer === 100 &&
        isNomineeGuardian.isNomFirstSignature) ||
      nomineeFirstObj[1].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: nomineeFirstObj[1].documentCategoryId?.toString(),
        documentId: nomineeFirstObj[1].documentId?.toString(),
        documentexpirydate: "",
        fileName: nomineeFirstObj[1].fileName,
        isfilecropped: true,
        mimeType: nomineeFirstObj[1].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: nomineeFirstObj[1].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (nomineeSecondObj[0].uploadPer === 100 &&
        isNomineeGuardian.isNomSecondPhoto) ||
      nomineeSecondObj[0].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: nomineeSecondObj[0].documentCategoryId?.toString(),
        documentId: nomineeSecondObj[0].documentId?.toString(),
        documentexpirydate: "",
        fileName: nomineeSecondObj[0].fileName,
        isfilecropped: true,
        mimeType: nomineeSecondObj[0].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: nomineeSecondObj[0].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (nomineeSecondObj[1].uploadPer === 100 &&
        isNomineeGuardian.isNomSecondSignature) ||
      nomineeSecondObj[1].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: nomineeSecondObj[1].documentCategoryId?.toString(),
        documentId: nomineeSecondObj[1].documentId?.toString(),
        documentexpirydate: "",
        fileName: nomineeSecondObj[1].fileName,
        isfilecropped: true,
        mimeType: nomineeSecondObj[1].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: nomineeSecondObj[1].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (nomineeThirdObj[0].uploadPer === 100 &&
        isNomineeGuardian.isNomThirdPhoto) ||
      nomineeThirdObj[0].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: nomineeThirdObj[0].documentCategoryId?.toString(),
        documentId: nomineeThirdObj[0].documentId?.toString(),
        documentexpirydate: "",
        fileName: nomineeThirdObj[0].fileName,
        isfilecropped: true,
        mimeType: nomineeThirdObj[0].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: nomineeThirdObj[0].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (nomineeThirdObj[1].uploadPer === 100 &&
        isNomineeGuardian.isNomThirdSignature) ||
      nomineeThirdObj[1].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: nomineeThirdObj[1].documentCategoryId?.toString(),
        documentId: nomineeThirdObj[1].documentId?.toString(),
        documentexpirydate: "",
        fileName: nomineeThirdObj[1].fileName,
        isfilecropped: true,
        mimeType: nomineeThirdObj[1].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: nomineeThirdObj[1].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (guardianFirstObj[0].uploadPer === 100 &&
        isNomineeGuardian.isGuardianFirstPhoto) ||
      guardianFirstObj[0].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: guardianFirstObj[0].documentCategoryId?.toString(),
        documentId: guardianFirstObj[0].documentId?.toString(),
        documentexpirydate: "",
        fileName: guardianFirstObj[0].fileName,
        isfilecropped: true,
        mimeType: guardianFirstObj[0].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: guardianFirstObj[0].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (guardianFirstObj[1].uploadPer === 100 &&
        isNomineeGuardian.isGuardianFirstSignature) ||
      guardianFirstObj[1].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: guardianFirstObj[1].documentCategoryId?.toString(),
        documentId: guardianFirstObj[1].documentId?.toString(),
        documentexpirydate: "",
        fileName: guardianFirstObj[1].fileName,
        isfilecropped: true,
        mimeType: guardianFirstObj[1].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: guardianFirstObj[1].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (guardianSecondObj[0].uploadPer === 100 &&
        isNomineeGuardian.isGuardianSecondPhoto) ||
      guardianSecondObj[0].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: guardianSecondObj[0].documentCategoryId?.toString(),
        documentId: guardianSecondObj[0].documentId?.toString(),
        documentexpirydate: "",
        fileName: guardianSecondObj[0].fileName,
        isfilecropped: true,
        mimeType: guardianSecondObj[0].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: guardianSecondObj[0].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (guardianSecondObj[1].uploadPer === 100 &&
        isNomineeGuardian.isGuardianSecondSignature) ||
      guardianSecondObj[1].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: guardianSecondObj[1].documentCategoryId?.toString(),
        documentId: guardianSecondObj[1].documentId?.toString(),
        documentexpirydate: "",
        fileName: guardianSecondObj[1].fileName,
        isfilecropped: true,
        mimeType: guardianSecondObj[1].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: guardianSecondObj[1].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }

    if (
      (guardianThirdObj[0].uploadPer === 100 &&
        isNomineeGuardian.isGuardianThirdPhoto) ||
      guardianSecondObj[0].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: guardianThirdObj[0].documentCategoryId?.toString(),
        documentId: guardianThirdObj[0].documentId?.toString(),
        documentexpirydate: "",
        fileName: guardianThirdObj[0].fileName,
        isfilecropped: true,
        mimeType: guardianThirdObj[0].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: guardianThirdObj[0].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    if (
      (guardianThirdObj[1].uploadPer === 100 &&
        isNomineeGuardian.isGuardianThirdSignature) ||
      guardianSecondObj[1].isWatsappFlag
    ) {
      let saveIncomeObj = {
        documentCategoryId: guardianThirdObj[1].documentCategoryId?.toString(),
        documentId: guardianThirdObj[1].documentId?.toString(),
        documentexpirydate: "",
        fileName: guardianThirdObj[1].fileName,
        isfilecropped: true,
        mimeType: guardianThirdObj[1].mimeType,
        panno: GlobalStore.personaldetails.pan,
        isWatsappFlag: guardianThirdObj[1].isWatsappFlag,
        personaldetailsid: "0",
        autoqc: false,
      };
      saveDetails.push(saveIncomeObj);
    }
    const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");

    const saveObj = {
      applicationId: GlobalStore.applicationID,
      clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
      documentInfo: saveDetails,
      leadId: GlobalStore.leadId,
      objUTM_Info: objUTM_Info,
    };
    setIsLoading(true);
    ASaveDocumentDetails(getSaveStatus, saveObj);
  };

  const getSaveStatus = (res: any) => {
    if (!res || res.error) {
      //setErrCode(1641)
      setErrCode(errCode + res.error);
      gtmService({
        action: "SaveUploadedDocumentDetails",
        label: "ERROR || ERR133",
      });
      setIsLoading(false);
      setLoaderSign(false);
    } else {
      if (res.data) {
        setIsLoading(true);
        // Clevertap event: submit documents data
        const data = {
          EventName:
            cleverTapEvents.registration.documentVerificationPage
              .CLICK_ON_SUBMIT,
          InitiationPlatform: "",
          UTM_SOURCE: props.UTM_SOURCE || "",
          UTM_MEDIUM: props.UTM_MEDIUM || "",
          UTM_CAMPAIGN: props.UTM_CAMPAIGN || "",
          KRA: GlobalStore.isKRA,
          ADHAR_LINKED_WITH_MOBILE: "",
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          ADDRESS_PROOF_SUBMITTED_VIA: uploadVaiWatsAppFlag
            ? "whattsapp"
            : "upload_now",
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
        };

        ClevertapService.pushEvent(data);
        // Clevertap ends here

        // Apps flyer event for click on proceed on plan details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.documentVerificationPage.CLICK_ON_SUBMIT
        );

        AGetPendingDocument(pendingDocQC, GlobalStore.applicationID);
      } else {
        setIsLoading(false);
        gtmService({
          action: "SaveUploadedDocumentDetails",
          label: "ERROR || ERR133-S",
        });
      }
    }
  };

  function pendingDocQC(res: any) {
    if (!res || res.error) {
      //   setErrCode("1456- Error in fetching pending documents list.");
      setErrCode(errCode + res.error);
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR123" });
      setIsLoading(false);
    } else {
      const responseObj = JSON.parse(res.data);
      if (responseObj && responseObj.length > 0) {
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );

        if (
          GlobalStore.isDigiLocker ||
          clientPartialDetails_ao.isDigiLocker ||
          GlobalStore.isKRA ||
          clientPartialDetails_ao.isKRA
        ) {
          gtmService({
            action: "DOCUMENT_VERIFICATION",
            label: "Step_Complete",
          });
          completeModuleStatus(dashboardUrl.doc);
          history.push(incompleteStageNavigation(dashboardUrl.doc));
        } else {
          const onlyIpvPending: Array<any> = responseObj.filter(
            (item: any) =>
              //   item.documentcategoryid !== 36 &&
              //   item.documentcategoryid !== 30 &&
              // //   item.documentcategoryid !== 62
              // item.DocumentCategoryId !== 36 &&
              // item.DocumentCategoryId !== 30 &&
              // item.DocumentCategoryId !== 62
              item.Mandatory === true
          );
          if (onlyIpvPending.length === 0) {
            gtmService({
              action: "DOCUMENT_VERIFICATION",
              label: "Step_Complete",
            });
          } else if (onlyIpvPending.length > 0) {
            let isIpvExist = GlobalStore.leftNavBarState.filter(
              (data: any) => data.navUrl === "/ipv"
            );
            if (isIpvExist.length === 0) {
              GlobalStore.leftNavBarState.push(ipvNavBarObj);
            }
          }
          completeModuleStatus(dashboardUrl.doc);
          history.push(incompleteStageNavigation(dashboardUrl.doc));
        }
      } else {
        gtmService({
          action: "GetDocumentDetails",
          label: "ERROR || ERR123-S",
        });
      }
    }
  }

  const checkFilledForm = () => {
    // Check if photo is taken
    if (!photoObj.imgUrl && !isNewPhoto) {
      return true;
    } else if ((!panObj.imgUrl && !isNewPan) || !isPanAndDobMatch) {
      return true;
    }
    if (!permAddressObj.base64 || !permAddressObj.imgUrl) {
      return true;
    }

    // Checking if Address field is present in the uploaded Aadhar files
    else if (permAddressObj.documentId === aadhaarDocumnetId) {
      return aadharAddress ? false : true;
    } else if (corrAddressObj.documentId === corrAadhaarDocumnetId) {
      return corrAadharAddress ? false : true;
    } else {
      return false;
    }
  };

  const closeSeedingPopup = () => {
    setSeedingPopup(false);
    // saveDocument();
  };

  const reCheck = () => {
    setIsLoading(true);
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    // const actObj = {
    //     applicationid: GlobalStore.applicationID,
    //     account_number: GlobalStore.account_no || clientPartialDetails_ao.account_no,
    //     ifsc: GlobalStore.ifsc_code || clientPartialDetails_ao.ifsc_code,
    //     name: GlobalStore.personaldetails.PANName
    // };
    // const actObj = {
    //     "panno": clientPartialDetails_ao.pan
    // }
    AGetPanUtiData(getSeedingData, clientPartialDetails_ao.pan);
    // AgetBeneficiaryName(getAccountHolderName, actObj);
  };

  // const getSeedingData = (res: any) => {
  //   if (!res || res.error) {
  //     // setErrCode("170  - Error in fetching Pan details from PAN UTI system.");
  //     console.log(res.errMsg);
  //     setErrCode(res.error);
  //   } else {
  //     if (res.data) {
  //       setIsLoading(false);
  //       let responseObj = res.data.panDetails;

  //       if (responseObj?.AadharSeedingStatus.includes("Aadhaar not Seeded")) {
  //         GlobalStore.isSeeding = false;
  //         setSeedingPopup(true);
  //       } else if (
  //         responseObj?.AadharSeedingStatus.includes(
  //           "Aadhaar Seeding is Successful"
  //         )
  //       ) {
  //         GlobalStore.isSeeding = true;
  //         setSeedingPopup(false);
  //       }
  //     }
  //   }
  // };
  const getSeedingData = (res: any) => {
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    if (!res || res.error) {
      console.log(res.errMsg);
      setErrCode(errCode + res.error);
      gtmService({ action: "GetPanDetails", label: "ERROR || ERR142" });
    } else {
      if (res.data) {
        setIsLoading(false);
        // let responseObj = JSON.parse(res.data.panDetails);
        let responseObj = res.data.AadharSeedingStatus
          ? res.data.AadharSeedingStatus
          : "";
        //if (responseObj.includes("Aadhaar not Seeded")) {
        if (res.data.IsAadharSeeded == "N") {
          GlobalStore.isSeeding = false;
          isPanLinkedToAadhar = false;
          APanLinkedWithAadhar(
            APanLinkedWithAadharRes,
            clientPartialDetails_ao.pan,
            clientPartialDetails_ao.aadharNo
          );
          setSeedingPopup(true);
          // } else if (responseObj.includes("Aadhaar Seeding is Successful")) {
        } else if (
          res.data.IsAadharSeeded == "Y" ||
          res.data.IsAadharSeeded == "NA"
        ) {
          GlobalStore.isSeeding = true;
          isPanLinkedToAadhar = true;
          setSeedingPopup(false);
        }
      } else {
        gtmService({ action: "GetPanDetails", label: "ERROR || ERR142-S" });
      }
    }
  };

  function APanLinkedWithAadharRes(res: any) {
    if (!res || res.error) {
      setIsLoading(false);
      setErrCode(res.error);
    } else {
      if (res.data?.isvalid == true) {
        isPanLinkedToAadhar = res.data.islinked == true ? true : false;
        if (res.data.islinked) {
          setIsLoading(true);
          setSeedingPopup(false);
        } else {
          setSeedingPopup(true);
          setIsLoading(false);
        }
        // AIsPANExists(isPANExists, values.pan);
      } else {
        // alert(res.data?.message);
        setIsLoading(false);
      }
    }
  }
  const navToLink = () => {
    window.open(
      "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar",
      "_blank"
    );
  };

  console.log(nomineeFirstObj, "nomineeFirstObj works");
  console.log(nomineeSecondObj, "nomineeSecondObj works");
  console.log(nomineeThirdObj, "nomineeThirdObj works");
  console.log(guardianFirstObj, "guardianFirstObj works");
  console.log(guardianSecondObj, "guardianSecondObj works");
  console.log(guardianThirdObj, "guardianThirdObj works");
  console.log(isNomineeGuardian, "key works");
  console.log(isManual, "Manual check");

  return (
    <div>
      <div className="mgt40">
        <div className="fieldLblRight">
          <div className="explain">
            {showBrowserPermissionModal && (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InfoModal width={DeviceContext().isMobile ? "328px" : "354px"}>
                  <div className="explainInfo w-100">
                    <label>
                      To open your account online, we would mandatorily need
                      access to your geo locations and camera. In your browser
                      notification, click on 'Allow' to proceed ahead
                    </label>
                  </div>
                  <div>
                    <Button
                      btnName="OK"
                      onClick={() => {
                        turnOnCamera();
                        // setsShowBrowserPermissionModal(false);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </InfoModal>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={"docForm frame" + (isLoading ? " op05" : "")}>
        {/* <Backdrop
          isShow={seedingPopup}
          onClose={() => closeSeedingPopup()}
          isCloseIcon={false}
        >
          <div className="isSeeding"> */}
        {/* {DeviceContext().isMobile && <EdelLogoCenter />} */}
        {/* <img className="seedingIcon" src={isSeeding} alt="isSeeding" />
            <label className="seedingLabel">PAN Aadhar Link</label>
            <label className="subLAbel">
              We observe that your PAN and Aadhar is not linked. There are
              higher chances of your PAN getting deactivated.
            </label>
            <div className="seedingCTA">
              <div className="borderBlue">
                <TransparentButton
                  isLoading={isLoading}
                  btnName="Re-Check"
                  onClick={() => reCheck()}
                />
              </div>
              <div>
                <Button btnName="Link PAN & Aadhar" onClick={navToLink} />
              </div>
            </div>
          </div>
        </Backdrop> */}

        <fieldset disabled={isLoading}>
          <>
            <label className="heading">Personal Photo</label>
            <label className="photoSubHeading">
              Click your own picture making sure your face is clearly visible.
            </label>
            <div className="imageBox">
              {isSdkSelfie ? (
                <NewSelfie
                  callback={(data: any) => {
                    HandleNewSDKSelfieRes(data);
                  }}
                  onFailedToOpenSdk={setIsSdkSelfie}
                />
              ) : (
                <>
                  <video
                    className="videoBox"
                    id="videoPersonal"
                    ref={videoRefElement}
                    autoPlay
                    playsInline
                  />
                  {!showRetake && <div className="photoOutline"></div>}
                  {!showRetake && <div className="captureFrame"></div>}
                  {!showBrowserPermissionModal && (
                    <div>
                      {!showRetake && !isGeoError ? (
                        <div className="cameraBtn">
                          <span
                            className="edel-icon-camera"
                            onClick={capturePhoto}
                          />
                        </div>
                      ) : null}
                      {showRetake && !isGeoError ? (
                        <div className="cameraBtn">
                          <span
                            className="edel-icon-retake"
                            onClick={retakePhoto}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                  <canvas
                    ref={canvasRefElement}
                    id="capture"
                    className="canvasImg"
                  />
                </>
              )}

              <section className="photo-cont" ref={uploadedPhotoRefElement}>
                <img src={photoObj.imgUrl} alt="photograph" />
              </section>
            </div>
            {/* {isGeoError && !isGeoErrorTimeFlag ? (
              <>
                <div className="geoErrorMsg">
                  <p>
                    {" "}
                    There is a delay in loading the contents, please wait for a
                    moment !!
                  </p>
                </div>
              </>
            ) : null} */}

            {/* {photoErrorMsg != "" && (
              <div className="errMsgBlck errWidth mrt40">
                <ErrMsg>
                  <label>{photoErrorMsg}</label>
                </ErrMsg>
              </div>
            )} */}

            {/* {isGeoError && isGeoErrorTimeFlag ? (
              <>
                <div className="geoErrorMsg">
                  <p>
                    {" "}
                    There was a delay in loading the contents, refresh the
                    page!!{" "}
                  </p>
                </div>
              </>
            ) : null} */}
          </>
          {regectedDocStatus &&
            regectedDocStatus.map(function (item: any, index: number) {
              return (
                <div key={index} className="mrt20">
                  {" "}
                  {item.DocumentType === "PHOTOGRAPH" && (
                    <ErrMsg>
                      <>
                        <label>
                          Reason for Rejection: {item?.RejectionReason}
                        </label>
                      </>
                    </ErrMsg>
                  )}
                </div>
              );
            })}
          {uploadDocFlag ? (
            <>
              <div className="uploadLayout">
                {firstBankList.length !== 0 ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">
                      {firstBankObj.documentcategory} Document
                    </label>
                    <DropdownSearch
                      name="firstBankDoc"
                      searchLength={0}
                      options={firstBankList}
                      searchKey="DocumentName"
                      placeholder="Select Bank Document"
                      isDownArrow={true}
                      defaultValue={defaultFirstBankDoc}
                      onOptionSelect={(val) => handleBankDoc(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Upload document with your Name, Account Number, IFSC and
                      MICR code
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="firstBankDoc"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={firstBankObj}
                        onDocUploadComplete={onFirstBankDocUploadComplete}
                      />
                      {firstBankListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{firstBankListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togFirstBank}
                          onChange={() => sendViaWatsapp("FirstBank")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togFirstBank && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {regectedDocStatus &&
                      regectedDocStatus.map(function (item: any) {
                        return (
                          <div className="mrt20">
                            {" "}
                            {item.ContentType ===
                              firstBankObj.documentcategory && (
                              <ErrMsg>
                                <>
                                  <label>
                                    Reason for Rejection: {item.RejectionReason}
                                  </label>
                                </>
                              </ErrMsg>
                            )}
                          </div>
                        );
                      })}
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                {secondBankList.length !== 0 ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">
                      {secondBankObj.documentcategory} Document
                    </label>
                    <DropdownSearch
                      name="secondBankDoc"
                      searchLength={0}
                      options={secondBankList}
                      searchKey="DocumentName"
                      placeholder="Select Bank Document"
                      isDownArrow={true}
                      defaultValue={defaultSecondBankDoc}
                      onOptionSelect={(val) => handleBankDoc(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Upload document with your Name, Account Number, IFSC and
                      MICR code
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="secondBankDoc"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={secondBankObj}
                        onDocUploadComplete={onSecondBankDocUploadComplete}
                      />
                      {secondBankListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{secondBankListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togSecondBank}
                          onChange={() => sendViaWatsapp("SecondBank")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togSecondBank && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {regectedDocStatus &&
                      regectedDocStatus.map(function (item: any) {
                        return (
                          <div className="mrt20">
                            {" "}
                            {item.ContentType ===
                              secondBankObj.documentcategory && (
                              <ErrMsg>
                                <>
                                  <label>
                                    Reason for Rejection: {item.RejectionReason}
                                  </label>
                                </>
                              </ErrMsg>
                            )}
                          </div>
                        );
                      })}
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                {thirdBankList.length !== 0 ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">
                      {thirdBankObj.documentcategory} Document
                    </label>
                    <DropdownSearch
                      name="thirdBankDoc"
                      searchLength={0}
                      options={thirdBankList}
                      searchKey="DocumentName"
                      placeholder="Select Bank Document"
                      isDownArrow={true}
                      defaultValue={defaultThirdBankDoc}
                      onOptionSelect={(val) => handleBankDoc(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Upload document with your Name, Account Number, IFSC and
                      MICR code
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="thirdBankDoc"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={thirdBankObj}
                        onDocUploadComplete={onThirdBankDocUploadComplete}
                      />
                      {thirdBankListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{thirdBankListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togThirdBank}
                          onChange={() => sendViaWatsapp("ThirdBank")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togThirdBank && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {regectedDocStatus &&
                      regectedDocStatus.map(function (item: any) {
                        return (
                          <div className="mrt20">
                            {" "}
                            {item.ContentType ===
                              thirdBankObj.documentcategory && (
                              <ErrMsg>
                                <>
                                  <label>
                                    Reason for Rejection: {item.RejectionReason}
                                  </label>
                                </>
                              </ErrMsg>
                            )}
                          </div>
                        );
                      })}
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                {fourthBankList.length !== 0 ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">
                      {fourthBankObj.documentcategory} Document
                    </label>
                    <DropdownSearch
                      name="fourthBankDoc"
                      searchLength={0}
                      options={fourthBankList}
                      searchKey="DocumentName"
                      placeholder="Select Bank Document"
                      isDownArrow={true}
                      defaultValue={defaultFourthBankDoc}
                      onOptionSelect={(val) => handleBankDoc(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Upload document with your Name, Account Number, IFSC and
                      MICR code
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="fourthBankDoc"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={fourthBankObj}
                        onDocUploadComplete={onFourthBankDocUploadComplete}
                      />
                      {fourthBankListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{fourthBankListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togFourthBank}
                          onChange={() => sendViaWatsapp("FourthBank")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togFourthBank && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {regectedDocStatus &&
                      regectedDocStatus.map(function (item: any) {
                        return (
                          <div className="mrt20">
                            {" "}
                            {item.ContentType ===
                              fourthBankObj.documentcategory && (
                              <ErrMsg>
                                <>
                                  <label>
                                    Reason for Rejection: {item.RejectionReason}
                                  </label>
                                </>
                              </ErrMsg>
                            )}
                          </div>
                        );
                      })}
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                {fifthBankList.length !== 0 ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">
                      {fifthBankObj.documentcategory} Document
                    </label>
                    <DropdownSearch
                      name="fifthBankDoc"
                      searchLength={0}
                      options={fifthBankList}
                      searchKey="DocumentName"
                      placeholder="Select Bank Document"
                      isDownArrow={true}
                      defaultValue={defaultFifthBankDoc}
                      onOptionSelect={(val) => handleBankDoc(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Upload document with your Name, Account Number, IFSC and
                      MICR code
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="fifthBankDoc"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={fifthBankObj}
                        onDocUploadComplete={onFifthBankDocUploadComplete}
                      />
                      {fifthBankListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{fifthBankListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togFifthBank}
                          onChange={() => sendViaWatsapp("FifthBank")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togFifthBank && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {regectedDocStatus &&
                      regectedDocStatus.map(function (item: any) {
                        return (
                          <div className="mrt20">
                            {" "}
                            {item.ContentType ===
                              fifthBankObj.documentcategory && (
                              <ErrMsg>
                                <>
                                  <label>
                                    Reason for Rejection: {item.RejectionReason}
                                  </label>
                                </>
                              </ErrMsg>
                            )}
                          </div>
                        );
                      })}
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                {/* {!GlobalStore.isDigiLocker && ( */}
                {!GlobalStore.isDigiLocker &&
                  // For full CKYC cases without permenant address document
                  (!GlobalStore.isKYC ||
                    !GlobalStore.isKRA ||
                    !defaultPermAddressProof) &&
                  !isManual && (
                    <div className="mrt60 row">
                      <label className="bankDoc">Personal Address Proof</label>
                      <DropdownSearch
                        name="firstPermAdd"
                        searchLength={0}
                        options={permAddList}
                        searchKey="DocumentName"
                        placeholder="Select Personal Address Proof"
                        isDownArrow={true}
                        defaultValue={defaultAddress}
                        disabled={blockAadhar}
                        onOptionSelect={(val) => handlePermanentAddress(val)}
                      />
                      <label className="bankDocSubHeading mrt10">
                        Upload clear scanned image of both front and back of the
                        card or upload them in a single PDF.
                      </label>
                      <div className="mrt20">
                        {(permAddressObj.disable = blockAadhar)}
                        <DocumentBrowse
                          id="permAddress"
                          docObj={permAddressObj}
                          previewImageexternalClassName={"maxSizewithAnother1"}
                          onDocUploadComplete={onFirstPermAddressUploadComplete}
                          isRemove={(docOj) =>
                            onRemove(docOj, "permAddressObj")
                          }
                        />
                        {permAddListError != "" ? (
                          <div>
                            {" "}
                            <br />
                            <ErrMsg>
                              <>
                                <label>{permAddListError}</label>
                              </>
                            </ErrMsg>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {anotherFile && (
                        <div className="row">
                          <div className="mrt20">
                            <DocumentBrowse
                              id="permSecondAddress"
                              previewImageexternalClassName={"maxSizewithAnother"}
                              docObj={permSecondAddressObj}
                              onDocUploadComplete={
                                onSecondPermAddressUploadComplete
                              }
                            />
                            {secondDocError != "" ? (
                              <div>
                                {" "}
                                <br />
                                <ErrMsg>
                                  <>
                                    <label>{secondDocError}</label>
                                  </>
                                </ErrMsg>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                      <div className="mrt20">
                        <label
                          className={secondDoc ? "addDoc" : "addDocDisable"}
                          onClick={() => addDocument()}
                        >
                          <span className="edel-icon-plus"></span> Add Document
                        </label>
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          {/* <Toggle
                            isOn={togPermAdd}
                            onChange={() => sendViaWatsapp("PermAddress")}
                          /> */}
                          <Toggle isOn={false} onChange={() => {}} />
                        </div>
                      </div>
                      <div>
                        {togPermAdd && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      {regectedDocStatus &&
                        regectedDocStatus.map(function (item: any) {
                          return (
                            <div className="mrt20">
                              {" "}
                              {item.ContentType ===
                                "PROOF OF ADDRESS PERMANENT" && (
                                <ErrMsg>
                                  <>
                                    <label>
                                      Reason for Rejection:{" "}
                                      {item.RejectionReason}
                                    </label>
                                  </>
                                </ErrMsg>
                              )}
                            </div>
                          );
                        })}
                      <div className="recDiv"></div>
                    </div>
                  )}

                {corrAddList.length !== 0 && showCorr ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">
                      Correspondence Address Proof
                    </label>
                    <DropdownSearch
                      name="firstCorrmAdd"
                      searchLength={0}
                      options={corrAddList}
                      searchKey="DocumentName"
                      placeholder="Select Correspondence Address Proof"
                      isDownArrow={true}
                      defaultValue={defaultCorrAddress}
                      onOptionSelect={(val) => handleCorrespondenceAddress(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Upload clear scanned image of both front and back of the
                      card or upload them in a single PDF.
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="corrAddress"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={corrAddressObj}
                        onDocUploadComplete={onFirstCorrAddressUploadComplete}
                      />
                      {corrAddListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{corrAddListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {corrAnotherFile && (
                      <div className="row">
                        <div className="mrt20">
                          <DocumentBrowse
                            id="corrSecondAddress"
                            previewImageexternalClassName={"maxSizewithAnother"}
                            docObj={corrSecondAddressObj}
                            onDocUploadComplete={
                              onSecondCorrAddressUploadComplete
                            }
                          />
                          {corrSecondDocError != "" ? (
                            <div>
                              {" "}
                              <br />
                              <ErrMsg>
                                <>
                                  <label>{corrSecondDocError}</label>
                                </>
                              </ErrMsg>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                    <div className="mrt20">
                      <label
                        className={corrSecondDoc ? "addDoc" : "addDocDisable"}
                        onClick={() => addCorrDocument()}
                      >
                        <span className="edel-icon-plus"></span> Add Document
                      </label>
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togCorrAdd}
                          onChange={() => sendViaWatsapp("CorrAddress")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togCorrAdd && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                {incomeList.length !== 0 ? (
                  <div className="mrt60 row">
                    <label className="bankDoc">Income Proof (Optional)</label>
                    <DropdownSearch
                      name="incomeProof"
                      searchLength={0}
                      options={incomeList}
                      searchKey="DocumentName"
                      placeholder="Select Income Proof"
                      isDownArrow={true}
                      defaultValue={defaultIncomeDoc}
                      onOptionSelect={(val) => handleIncomeProof(val)}
                    />
                    <label className="bankDocSubHeading mrt10">
                      Note: Income Proof is required for trading in derivative
                      segment
                    </label>
                    <div className="mrt20">
                      <DocumentBrowse
                        id="incomeDoc"
                        previewImageexternalClassName={"maxSizewithAnother"}
                        docObj={{ ...incomeObj }}
                        onDocUploadComplete={onIncomeUploadComplete}
                      />
                      {incomeListError != "" ? (
                        <div>
                          {" "}
                          <br />
                          <ErrMsg>
                            <>
                              <label>{incomeListError}</label>
                            </>
                          </ErrMsg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="togContainer mrt40">
                      <label className="isTogLabel">
                        Would share this document later via WhatsApp
                      </label>
                      <div className="togSize">
                        {/* <Toggle
                          isOn={togIncome}
                          onChange={() => sendViaWatsapp("Income")}
                        /> */}
                        <Toggle isOn={false} onChange={() => {}} />
                      </div>
                    </div>
                    <div>
                      {togIncome && (
                        <div className="watsLayout">
                          <label className="shareDoc">Share Documents on</label>
                          <div className="watsNum">
                            <label className="watsapp">WhatsApp</label>
                            <label className="watsAppNum">
                              <span>
                                <img className="watsappImg" src={watsAppIcon} />
                              </span>
                              {watsappNo}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {regectedDocStatus &&
                      regectedDocStatus.map(function (item: any) {
                        return (
                          <div className="mrt20">
                            {" "}
                            {item.ContentType === "PROOF OF INCOME" && (
                              <ErrMsg>
                                <>
                                  <label>
                                    Reason for Rejection: {item.RejectionReason}
                                  </label>
                                </>
                              </ErrMsg>
                            )}
                          </div>
                        );
                      })}
                    <div className="recDiv"></div>
                  </div>
                ) : null}

                <div className="mrt60 row">
                  <label className="shareDoc">Signature</label>
                  <label className="photoSubHeading mrt10">
                    Upload a scanned copy of your signature on blank white paper
                    with Blue/ Black ink.
                  </label>
                  <div className="mrt20">
                    <DocumentBrowse
                      id="signature"
                      previewImageexternalClassName={"maxSizewithAnother"}
                      docObj={signatureObj}
                      onDocUploadComplete={onSignatureUploadComplete}
                      // mandateRemove={true}
                    />
                    {signatureListError != "" ? (
                      <div>
                        {" "}
                        <br />
                        <ErrMsg>
                          <>
                            <label>{signatureListError}</label>
                          </>
                        </ErrMsg>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="togContainer mrt40">
                    <label className="isTogLabel">
                      Would share this document later via WhatsApp
                    </label>
                    <div className="togSize">
                      {/* <Toggle
                        isOn={togSig}
                        onChange={() => sendViaWatsapp("Signature")}
                      /> */}
                      <Toggle isOn={false} onChange={() => {}} />
                    </div>
                  </div>
                  <div>
                    {togSig && (
                      <div className="watsLayout">
                        <label className="shareDoc">Share Documents on</label>
                        <div className="watsNum">
                          <label className="watsapp">WhatsApp</label>
                          <label className="watsAppNum">
                            <span>
                              <img className="watsappImg" src={watsAppIcon} />
                            </span>
                            {watsappNo}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  {regectedDocStatus &&
                    regectedDocStatus.map(function (item: any) {
                      return (
                        <div className="mrt20">
                          {" "}
                          {item.DocumentType === "SIGNATURE" && (
                            <ErrMsg>
                              <>
                                <label>
                                  Reason for Rejection: {item?.RejectionReason}
                                </label>
                              </>
                            </ErrMsg>
                          )}
                        </div>
                      );
                    })}
                </div>
                {/* nomineefirst Start */}
                {nomineeFirstList && nomineeFirstList.length > 0 && (
                  <>
                    <div className="mrt60 row">
                      <label className="shareDoc">Nominee 1</label>
                      <div className="mrt20">
                        {nomineeFirstList.map((data: any, index: number) => {
                          if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeOne.photo.documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Photo</p>
                                <DocumentBrowse
                                  id="nomFirstPhotoId"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={nomineeFirstObj[0]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomFirstPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.nomFirstPhotoId != "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {nomineeGuardianError.nomFirstPhotoId}
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeOne.photo
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          } else if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeOne.signature
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Signature</p>
                                <DocumentBrowse
                                  id="nomFirstSignature"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={nomineeFirstObj[1]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomFirstSignature",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.nomFirstSignature !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.nomFirstSignature
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeOne.signature
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          <Toggle
                            isOn={nomineeGuardianTog.nomineeFirst}
                            onChange={() => sendViaWatsapp("nomineeFirst")}
                          />
                        </div>
                      </div>
                      <div>
                        {nomineeGuardianTog.nomineeFirst && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* nomineeFirst End */}
                {/* GuardianFirst Start */}

                {guardianFirstList && guardianFirstList.length > 0 && (
                  <>
                    <div className="mrt60 row">
                      <label className="shareDoc">Guardian 1</label>
                      <div className="mrt20">
                        {guardianFirstList.map((data: any, index: number) => {
                          if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeOne.guardianDetails.photo
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Photo</p>
                                <DocumentBrowse
                                  id="guardianFirstPhotoId"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={guardianFirstObj[0]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "guardianFirstPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.guardianFirstPhotoId !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.guardianFirstPhotoId
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeOne
                                            .guardianDetails.photo
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          } else if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeOne.guardianDetails.signature
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Signature</p>
                                <DocumentBrowse
                                  id="guardianFirstSignature"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={guardianFirstObj[1]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "guardianFirstSignature",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.guardianFirstSignature !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.guardianFirstSignature
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeOne
                                            .guardianDetails.signature
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          <Toggle
                            isOn={nomineeGuardianTog.guardianFirst}
                            onChange={() => sendViaWatsapp("guardianFirst")}
                          />
                        </div>
                      </div>
                      <div>
                        {nomineeGuardianTog.guardianFirst && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* GuardianFirst End */}

                {/* nomineeSecond start */}
                {nomineeSecondList && nomineeSecondList.length > 0 && (
                  <>
                    <div className="mrt60 row">
                      <label className="shareDoc">Nominee 2</label>
                      <div className="mrt20">
                        {nomineeSecondList.map((data: any, index: number) => {
                          if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeTwo.photo.documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Photo</p>
                                <DocumentBrowse
                                  id="nomSecondPhotoId"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={nomineeSecondObj[0]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomSecondPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.nomSecondPhotoId != "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.nomSecondPhotoId
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeTwo.photo
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          } else if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeTwo.signature
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Signature</p>
                                <DocumentBrowse
                                  id="nomSecondSignature"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={nomineeSecondObj[1]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomSecondSignature",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.nomSecondSignature !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.nomSecondSignature
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeTwo.signature
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          <Toggle
                            isOn={nomineeGuardianTog.nomineeSecond}
                            onChange={() => sendViaWatsapp("nomineeSecond")}
                          />
                        </div>
                      </div>
                      <div>
                        {nomineeGuardianTog.nomineeSecond && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* nomineeSecond End */}
                {/* GuardianSecond Start */}
                {guardianSecondList && guardianSecondList.length > 0 && (
                  <>
                    <div className="mrt60 row">
                      <label className="shareDoc">Guardian 2</label>
                      <div className="mrt20">
                        {guardianSecondList.map((data: any, index: number) => {
                          if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeTwo.guardianDetails.photo
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Photo</p>
                                <DocumentBrowse
                                  id="guardianSecondPhotoId"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={guardianSecondObj[0]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "guardianSecondPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.guardianSecondPhotoId !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.guardianSecondPhotoId
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeTwo
                                            .guardianDetails.photo
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          } else if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeTwo.guardianDetails.signature
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Signature</p>
                                <DocumentBrowse
                                  id="guardianSecondSignature"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={guardianSecondObj[1]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "guardianSecondSignature",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.guardianSecondSignature !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.guardianSecondSignature
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeTwo
                                            .guardianDetails.signature
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          <Toggle
                            isOn={nomineeGuardianTog.guardianSecond}
                            onChange={() => sendViaWatsapp("guardianSecond")}
                          />
                        </div>
                      </div>
                      <div>
                        {nomineeGuardianTog.guardianSecond && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* GuardianSecond End */}
                {/* nomineeThird */}
                {nomineeThirdList && nomineeThirdList.length > 0 && (
                  <>
                    <div className="mrt60 row">
                      <label className="shareDoc">Nominee 3</label>
                      <div className="mrt20">
                        {nomineeThirdList.map((data: any, index: number) => {
                          if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeThree.photo.documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Photo</p>
                                <DocumentBrowse
                                  id="nomThirdPhotoId"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={nomineeThirdObj[0]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomThirdPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.nomThirdPhotoId != "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {nomineeGuardianError.nomThirdPhotoId}
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeThree.photo
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          } else if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeThree.signature
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Signature</p>
                                <DocumentBrowse
                                  id="nomThirdSignature"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={nomineeThirdObj[1]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomThirdSignature",
                                      removeDocObj
                                    )
                                  }
                                />

                                {nomineeGuardianError.nomThirdSignature !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.nomThirdSignature
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeThree.signature
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          <Toggle
                            isOn={nomineeGuardianTog.nomineeThree}
                            onChange={() => sendViaWatsapp("nomineeThree")}
                          />
                        </div>
                      </div>
                      <div>
                        {nomineeGuardianTog.nomineeThree && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* NomineeThird Start */}

                {/* GuardianThird Start */}
                {guardianThirdList && guardianThirdList.length > 0 && (
                  <>
                    <div className="mrt60 row">
                      <label className="shareDoc">Guardian 3</label>
                      <div className="mrt20">
                        {guardianThirdList.map((data: any, index: number) => {
                          if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeThree.guardianDetails.photo
                              .documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Photo</p>
                                <DocumentBrowse
                                  id="guardianThirdPhotoId"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={guardianThirdObj[0]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "guardianThirdPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                                {nomineeGuardianError.guardianThirdPhotoId !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.guardianThirdPhotoId
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeThree
                                            .guardianDetails.photo
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          } else if (
                            data.DocumentCategoryId ===
                            nomineeDocsIds.nomineeThree.guardianDetails
                              .signature.documentCategoryId
                          ) {
                            return (
                              <>
                                <p>Signature</p>
                                <DocumentBrowse
                                  id="guardianThirdSignature"
                                  previewImageexternalClassName={"maxSizewithAnother"}
                                  docObj={guardianThirdObj[1]}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onNomineeUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "guardianThirdSignature",
                                      removeDocObj
                                    )
                                  }
                                />

                                {nomineeGuardianError.guardianThirdSignature !=
                                "" ? (
                                  <div>
                                    {" "}
                                    <br />
                                    <ErrMsg>
                                      <>
                                        <label>
                                          {
                                            nomineeGuardianError.guardianThirdSignature
                                          }
                                        </label>
                                      </>
                                    </ErrMsg>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {regectedDocStatus &&
                                  regectedDocStatus.map(function (item: any) {
                                    return (
                                      <div className="mrt20">
                                        {" "}
                                        {item.DocumentCategoryId ===
                                          nomineeDocsIds.nomineeThree
                                            .guardianDetails.signature
                                            .documentCategoryId && (
                                          <ErrMsg>
                                            <>
                                              <label>
                                                Reason for Rejection:{" "}
                                                {item?.RejectionReason}
                                              </label>
                                            </>
                                          </ErrMsg>
                                        )}
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          }
                        })}
                      </div>
                      <div className="togContainer mrt40">
                        <label className="isTogLabel">
                          Would share this document later via WhatsApp
                        </label>
                        <div className="togSize">
                          <Toggle
                            isOn={nomineeGuardianTog.guardianThird}
                            onChange={() => sendViaWatsapp("guardianThird")}
                          />
                        </div>
                      </div>
                      <div>
                        {nomineeGuardianTog.guardianThird && (
                          <div className="watsLayout">
                            <label className="shareDoc">
                              Share Documents on
                            </label>
                            <div className="watsNum">
                              <label className="watsapp">WhatsApp</label>
                              <label className="watsAppNum">
                                <span>
                                  <img
                                    className="watsappImg"
                                    src={watsAppIcon}
                                  />
                                </span>
                                {watsappNo}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* GuardianThird End */}

                {/* {DeviceContext().isMobile && (togPAN || togFirstBank || togSecondBank || togThirdBank || togFourthBank || togFifthBank || togPermAdd || togCorrAdd || togSig || togIncome) && <div className="watsLayout">
                                    <label className="shareDoc">Share Documents on</label>
                                    <div className="watsNum">
                                        <label className="watsapp">WhatsApp</label>
                                        <label className="watsAppNum"><span><img className="watsappImg" src={watsAppIcon} /></span>{watsappNo}</label>
                                    </div>
                                </div>} */}

                {/* Rejection Error Msg Start*/}
                {/* {
                                    regectedDocStatus && regectedDocStatus.map((item: any, index: number) => <div key={index} className='errMsgBlck errWidth mrt40'>
                                        <ErrMsg>
                                            <>
                                                <label>Reason for Rejection: {item.rejectionreason}</label>
                                            </>
                                        </ErrMsg>
                                    </div>)
                                } */}
                {/* Rejection Error Msg End*/}

                {/* Error Msg Start*/}
                {errCode && (
                  <div className="errMsgBlck errWidth mrt40">
                    <ErrMsg>
                      {typeof errCode === "number" ? (
                        <>
                          <label>We are facing some technical issue.</label>
                          <label>
                            Please try after sometime (error code:{errCode})
                          </label>
                        </>
                      ) : (
                        <label>{errCode}</label>
                      )}
                    </ErrMsg>
                  </div>
                )}
                {/* Error Msg End*/}

                {/* Photo Error Msg Start*/}
                {photoErrorMsg != "" && (
                  <div className="errMsgBlck errWidth mrt40">
                    <ErrMsg>
                      <label>{photoErrorMsg}</label>
                    </ErrMsg>
                  </div>
                )}
                {/* Photo Error Msg End*/}

                <div className="docSubBtn">
                  <SubmitButton
                    isLoading={loaderSign || isLoading}
                    btnName="Submit"
                    onClick={handleSubmit}
                    disabled={
                      (typeof errCode === "string" && errCode != "") ||
                      checkESignSubmitted(GlobalStore.applicationID) ||
                      signatureListError ||
                      checkFilledForm() ||
                      permAddButDisable ||
                      panButDisable ||
                      // || nomineeDisable ||
                      // guardianDisable
                      liveLocationCheck.checkPhotoLiveness
                      // ||
                      // liveLocationCheck.checkLocation
                    }
                  />
                </div>
              </div>
            </>
          ) : uploadVaiWatsAppFlag ? (
            <>
              <div className="watsLayout">
                <label className="shareDoc">Share Documents on</label>
                <div className="watsNum">
                  <label className="watsapp">WhatsApp</label>
                  <label className="watsAppNum">
                    <span>
                      <img className="watsappImg" src={watsAppIcon} />
                    </span>
                    {watsappNo}
                  </label>
                </div>
              </div>
              <div className="uploadLayout mrt60">
                <Button
                  isLoading={loaderSign || isLoading}
                  btnName="Save and Proceed"
                  onClick={() => saveViaWatsApp()}
                />
              </div>
            </>
          ) : (
            <div
              className={DeviceContext().isMobile ? "mrt40" : "selectOption"}
            >
              <label className="heading">Document Upload</label>
              <label className="uploadSubHeading">
                You need to upload your documents for verification. How would
                you like to share the required documents listed below?
              </label>
              <ul className="mrl-25 mrt20 list">
                {pendingDocObj &&
                  pendingDocObj.map((item: any, index: number) => {
                    return <li key={index}>{item.DocumentCategory}</li>;
                  })}
              </ul>
              <div className="btnContainer mrt40">
                {/* <div className="borderBlue spaceBetweem">
                  <TransparentButton
                    btnName="Via WhatsApp"
                    onClick={() => uploadViaWatsApp()}
                    isWhatsapp={true}Nomi
                  />
                </div> */}
                <div className="borderBlue">
                  <TransparentButton
                    btnName="Upload Now"
                    onClick={() => uploadDoc()}
                    isUpload={true}
                  />
                </div>
              </div>
            </div>
          )}
        </fieldset>
      </div>
    </div>
  );
};

export default DocVerifcationForm;
