import React from "react";
import "./AgentVisitForm.scss";
import InputField from "../../../UI/InputField/InputField";
import { betweenNChars } from "../../../utils/regex";
import DropdownSearch from "../../../UI/DropdownSearch/DropdownSearch";
import { EsignTag } from "../../../UI/Tag/Tag";
import Button from "../../../UI/Button/Button";
import {
  IAddressFormat,
  addressErrorObj,
  addressErrorMsg,
} from "../../../Profile/AddressField/AddressField";
import { IListObj, IListInfoObj } from "../../../types/genericTypes";
import {
  AGetArea,
  AGetFullAddress,
} from "../../../Services/dashboard/profileApiService";
import {
  AAgentDatesList,
  AAgentTimesList,
  ASaveAgentVisitDetails,
} from "../../../Services/dashboard/eSignApiServices";
import { stringToDate, dateStringToDay } from "../../../Services/globalService";
import GlobalStore from "../../../store/globalStore";
import { containerUrl } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import gtmService from "../../../Services/gtmService";

export interface AgentVisitFormProps {
  addressObj: IAddressFormat;
  ocvDate: string;
  ocvTime: string;
  agentTimesList?: Array<IListObj>;
  setErrCode: (value: number | string) => void;
  ocvDateAndTimeChange: (type: "date" | "time", value: any) => void;
  onAddressChange: (addressObj: IAddressFormat) => void;
  setIsLoading: (value: boolean) => void;
  handleSubmit?: () => void;
  isLoading: boolean;
  registerCleverTapScheduleAgentEvent?: (isInitial: boolean) => void;
}

const AgentVisitForm: React.FC<AgentVisitFormProps> = (props) => {
  const history = useHistory();
  const [addressObj, setAddressObj] = React.useState<IAddressFormat>(
    props.addressObj
  );
  const [areaList, setAreaList] = React.useState<Array<IListObj>>([]);
  const [agentDatesList, setAgentDatesList] = React.useState<
    Array<IListInfoObj>
  >([]);
  const [agentTimesList, setAgentTimesList] = React.useState<Array<IListObj>>(
    []
  );
  const [errors, setErrors] = React.useState<{
    [key in keyof IAddressFormat]?: string;
  }>({ ...addressErrorObj });

  React.useEffect(() => {
    props.setIsLoading(true);
    AGetArea(getAreaList, addressObj.PinCode);
    AAgentDatesList(getAgentDates);
    !props.agentTimesList && AAgentTimesList(getAgentTimes);
    document.getElementById("OcvAddress")?.focus();
  }, [addressObj.PinCode]);

  function getAgentDates(res: any) {
    props.setIsLoading(true);
    if (!res || res.error) {
    } else {
      if (res.data && res.data.ecoAgentVisitDates) {
        const arr: Array<IListInfoObj> = [];
        res.data.ecoAgentVisitDates.forEach((date: any) => {
          let obj: any = {};
          obj.value = date.agentVisitDate;
          obj.display = stringToDate(date.agentVisitDate);
          obj.info = dateStringToDay(date.agentVisitDate);
          arr.push(obj);
        });
        setAgentDatesList(arr);
      }
    }
    props.setIsLoading(false);
  }

  function getAgentTimes(res: any) {
    props.setIsLoading(true);
    if (!res || res.error) {
    } else {
      if (res.data && res.data.ecoAgentVisitTimes) {
        const arr: Array<IListInfoObj> = [];
        res.data.ecoAgentVisitTimes.forEach((date: any) => {
          let obj: any = {};
          obj.display = date.agentVisitTime;
          obj.value = date.agentVisitTime;
          arr.push(obj);
        });
        setAgentTimesList(arr);
      }
    }
    props.setIsLoading(false);
  }

  function handleChangeAddress(key: keyof IAddressFormat, value: string) {
    if (key === "Area") {
      AGetFullAddress(getFullAddress, value, addressObj.PinCode);
    }
    setErrors({ ...errors, [key]: undefined });
    setAddressObj({ ...addressObj, [key]: value });
    props.onAddressChange({ ...addressObj, [key]: value });
  }

  function getAreaList(res: any) {
    props.setIsLoading(true);
    if (!res || res.error) {
      props.setErrCode(res.error);
    } else {
      if (res.data && res.data.areas) {
        const arr: Array<IListObj> = [];
        res.data.areas.forEach((item: string) => {
          let obj: IListObj = {
            value: item,
            display: item,
          };
          arr.push(obj);
        });
        setAreaList(arr);
      } else {
        props.setErrCode("130 - Failed to fetch the area list for the pincode");
      }
    }
    props.setIsLoading(false);
  }

  function getFullAddress(res: any) {
    if (!res || res.error) {
      //   props.setErrCode(
      //     "131 - Failed to fetch details corresponding to pincode and area"
      //   );
      props.setErrCode(res.error);
    } else {
      if (res.data) {
        let obj: any = {};
        obj.pincode = res.data.pinCode;
        obj.area = res.data.area;
        obj.district = res.data.district;
        obj.country = res.data.country;
        obj.city = res.data.city;
        obj.state = res.data.state;
        obj.statecode = res.data.stateCode;
        obj.countrycode = res.data.countryCode;
        setAddressObj({ ...addressObj, ...obj });
        props.onAddressChange({ ...addressObj, ...obj });
      } else {
        props.setErrCode(
          "131 - Failed to fetch details corresponding to pincode and area"
        );
      }
    }
  }

  function handleBlurAddress(key: keyof IAddressFormat, value: any) {
    switch (key) {
      case "addressLine1":
        setErrors({
          ...errors,
          addressLine1: value.pattern
            ? undefined
            : addressErrorMsg.addressLine1,
        });
        break;
      case "Area":
        if (typeof value === "object") {
          setErrors({ ...errors, Area: undefined });
        } else {
          setErrors({ ...errors, Area: addressErrorMsg.area });
        }
        break;
    }
  }

  function submitOcv() {
    const agentVisitObj = {
      address: addressObj.addressLine1,
      applicationid: GlobalStore.applicationID,
      area: addressObj.Area,
      city: addressObj.City,
      created_by: 0,
      date_for_visit: props.ocvDate,
      pincode: addressObj.PinCode,
      state: addressObj.State,
      statecode: 0,
      time_for_visit: props.ocvTime,
    };
    props.setIsLoading(true);
    if (props.handleSubmit) props.handleSubmit();
    else {
      gtmService({ action: "AGENT_VISIT", label: "Step_Complete" });
      ASaveAgentVisitDetails(agentVisitScheduled, agentVisitObj);
    }
  }

  function agentVisitScheduled(res: any) {
    if (!res || res.error) {
    } else {
      if (res.data) {
        if (props.registerCleverTapScheduleAgentEvent)
          props.registerCleverTapScheduleAgentEvent(false);
        history.replace(containerUrl.ocv);
      }
    }
  }

  return (
    <div className="agentVisitForm">
      <label className="headingLbl">Pick-Up Address</label>

      <div className="mgt30">
        <label className="fieldLbl">Address</label>
        <InputField
          id="OcvAddress"
          pattern={betweenNChars(1)}
          onBlur={(error) => handleBlurAddress("addressLine1", error)}
          placeholder="Eg. Address"
          onChange={(value) => handleChangeAddress("addressLine1", value)}
          errorText={errors.addressLine1}
          name={`OcvAddress`}
        />
      </div>

      <div className="mgt40">
        <label className="fieldLbl">Area</label>
        <DropdownSearch
          name={`OcvArea`}
          onOptionSelect={(value) =>
            handleChangeAddress("Area", value["value"] as string)
          }
          searchLength={0}
          options={areaList}
          onBlur={(val) => handleBlurAddress("Area", val)}
          searchKey="display"
          placeholder="Eg. Thane"
          noDataMsg="Area with similar name not found"
          errorText={errors.Area}
          isDownArrow={true}
        />
      </div>

      <div className="mgt40">
        <label className="fieldLbl">City/State</label>
        <InputField
          defaultValue={
            addressObj.City || addressObj.State
              ? addressObj.City + " / " + addressObj.State
              : ""
          }
          placeholder="Eg. Mumbai/Maharashtra"
          disabled={true}
          type="text"
          name={`OcvCityState`}
        />
      </div>

      <div className="mgt40">
        <label className="ocvFieldLbl">Select Date for Agent Visit</label>
        <EsignTag
          isInfo
          defaultValue={props.ocvDate}
          onSelect={(val) => props.ocvDateAndTimeChange("date", val)}
          tagList={agentDatesList}
        />
      </div>

      <div className="mgt40">
        <label className="ocvFieldLbl">Select Time for Agent Visit</label>
        <EsignTag
          defaultValue={props.ocvTime}
          onSelect={(val) => props.ocvDateAndTimeChange("time", val)}
          tagList={props.agentTimesList || agentTimesList}
        />
      </div>

      <div className="scheduleBtn">
        <Button
          onClick={submitOcv}
          btnName="Schedule"
          isLoading={props.isLoading}
          disabled={
            Object.values(errors).some((error) => error) ||
            Object.values(addressObj).some((value) => !value) ||
            props.ocvTime === "" ||
            props.ocvDate === ""
          }
        />
      </div>
    </div>
  );
};

export default AgentVisitForm;
