import React from 'react';
import './ProfileImage.scss';

export interface ProfileImageProps {
    color?: string;
    className?: string;
}

const ProfileImage: React.FC<ProfileImageProps> = (props) => {
    return (
        <div className={`prflContainer ${props.className}`}>
            <div className={`prflImage`} style={{ backgroundColor: `${props.color}` }}>
                {props.children}
            </div>
        </div>

    )
}

export default ProfileImage;