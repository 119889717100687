import React from "react";
import "./ShareAdd.scss";
import ProfileImage from "../../UI/ProfileImage/ProfileImage";
import Button from "../../UI/Button/Button";
import { useLocation, useHistory } from "react-router-dom";
import domain from "../../../config/domain";
import {
  AGenerateAccessToken,
  AGetDocListFromDigilocker,
  AGetSelectedDocument,
} from "../../Services/registration/digiLockerApiService";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import GlobalStore from "../../store/globalStore";
import { dashboardUrl, containerUrl } from "../../utils/constants";
import {
  clientPartialDetailsToStore,
  updateUtmInfo,
} from "../../Services/globalService";
import shareAdd from "../../../assets/icons/shareAdd.jpg";
import { errorMessage } from "../../utils/errorMessage";

export interface ShareAddProps {}

export function getProfileDetailsFromAadhar(response: any) {
  GlobalStore.personaldetails.aadharName = response.AadharName;
  GlobalStore.personaldetails.addressLine1 = response.CoresAddress;//.replace(/[^a-zA-Z0-9 ]/g," ");
  GlobalStore.personaldetails.city = response.PermanentCity;
  GlobalStore.personaldetails.pincode = response.CoresPincode;
  GlobalStore.personaldetails.gender = response.Gender;
  const clientPartialDetails_ao = JSON.parse(
    window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  );
  clientPartialDetails_ao.addressLine1 =
    GlobalStore.personaldetails.addressLine1;
  clientPartialDetails_ao.pincode =
    GlobalStore.personaldetails?.pincode?.toString();

  window.localStorage.setItem(
    "clientPartialDetails_ao",
    JSON.stringify(clientPartialDetails_ao)
  );
}

const ShareAdd: React.FC<ShareAddProps> = (props) => {
  const [errCode, setErrCode] = React.useState<number | string>("");
  const [aadharDocDetails, setAadharDocDetails] = React.useState<any>();
  const [accessToken, setAccessToken] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const errRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    clientPartialDetailsToStore();
    if(domain.digiLockerSource==1)
    {
    window.sessionStorage.setItem("stageInfoId", "9");
    const queryObject = Object.fromEntries(
    new URLSearchParams(location.search)
    );
    if (
      typeof queryObject.code !== "undefined" &&
      queryObject.state !== "undefined"
    ) {
      let accessTokenObj: any = {};
      accessTokenObj.code = queryObject.code;
      accessTokenObj.reDirectUrl = domain.redirectUri + "?";
      accessTokenObj.client_Id = domain.clientId;
      accessTokenObj.SourceId = domain.digiLockerSource;
      AGenerateAccessToken(
        (res: any) => getAccessToken(res, accessTokenObj),
        accessTokenObj
      );
      setIsLoading(true);
    } else {
      setErrCode("123");
    }
    }
    if(domain.digiLockerSource==2)
    {
    window.sessionStorage.setItem("stageInfoId", "9");
      const clientPartialDetails_ao = JSON.parse(
        window.localStorage.getItem("clientPartialDetails_ao") || "{}"
      );
      let signzy_access_token = clientPartialDetails_ao?.signzydt;
      if (signzy_access_token) {
        let digiDocInfo: any = {};
        digiDocInfo.access_token = signzy_access_token;
        setAccessToken(signzy_access_token);
        AGetDocListFromDigilocker(getDocListFromDigilocker, digiDocInfo);
      }else
      {
        setErrCode(errorMessage.newDigilockerError);
      }
    }
    updateUtmInfo(updateUtmDetails); // here updating utmcreative and content.
  }, []);

  React.useEffect(() => {
    if (errCode) {
      errRef.current?.scrollIntoView();
    }
  }, [errCode]);

  function updateUtmDetails(res: any) {
    if (!res || res.error) {
      // setErrCode(123);
    }
  }

  function getAccessToken(res: any, accessTokenObj: any) {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode("1241 - Error with Access Token generation");
      setErrCode(res.error);
    } else {
      if (res.data) {
        // let responseObj = JSON.parse(res.data);
        let responseObj = res.data;
        if (responseObj.access_token) {
          const digiDocInfo = { ...accessTokenObj };
          digiDocInfo.access_token = responseObj.access_token;
          digiDocInfo.SourceId = domain.digiLockerSource;
          setAccessToken(responseObj.access_token);
          AGetDocListFromDigilocker(getDocListFromDigilocker, digiDocInfo);
        } else {
          if (res.data.eaadhaar === "N")
            setErrCode(errorMessage.newDigilockerError);
          // setErrCode(
          //   "Please link your aadhaar details with digilocker and refresh the page and click on continue to complete the process."
          // );
        }
      } else {
        setIsLoading(false);
        setErrCode(errorMessage.newDigilockerError);
        // setErrCode("1242 - Error with Access Token generation");
      }
    }
  }

  function getDocListFromDigilocker(res: any) {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode("1251 - Error in getting documents list");
      console.log(res.errMsg);
      setErrCode(res.error);
    } else {
      if (res.data) {
        debugger;
        const adhar = res.data.items.filter(
          (x: any) => x.name === "Aadhaar Card"
        );
        if (adhar.length > 0) {
          setAadharDocDetails(adhar[0]);
        }
      } else setErrCode(res.error);
      setIsLoading(false);
    }
  }

  function fetchPDF() {
    if (aadharDocDetails !== null && aadharDocDetails !== undefined) {
      debugger;
      setIsLoading(true);
      //Example uri = "in.gov.uidai-ADHAR-000000000000"
      const adharArr: Array<string> = aadharDocDetails.uri.split("-");
      let adharNo = "";
      if (adharArr.length === 3) {
        const reg = new RegExp("^[0-9]+$");
        if (reg.test(adharArr[2])) {
          adharNo = adharArr[2];
        } else {
          adharNo = "000000000000";
        }
      } else {
        adharNo = "000000000000";
      }
      const utmObj = JSON.parse(
        window.sessionStorage.getItem("objUTM_Info") || "{}"
      );

      let data = {
        aadharNo: adharNo,
        fileUri: aadharDocDetails.uri,
        filename: "",
        token: accessToken,
        applicationId: GlobalStore.applicationID,
        clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId,
        leadId: GlobalStore.leadId,
        panno: GlobalStore.personaldetails.pan,
        utmObj: utmObj,
      };



      AGetSelectedDocument(getSelectedDocument, data);
    }
  }

  function getSelectedDocument(res: any) {
    if (!res || res.error) {
      // setErrCode(
      //   "Error while processing aadhar card code: 126, Please contact support team"
      // );
      setErrCode(res.error);
      setIsLoading(false);
      return;
    } else {
      if (res.data) {
        setIsLoading(false);
        if (res.data === "Error in DigiLocker") {
          setErrCode(res.error);
          return false;
        }
        if (res.data === "PersonalEditable") {
          setErrCode("");
          history.push(dashboardUrl.profile);
          return false;
        }
        getProfileDetailsFromAadhar(res.data);
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );

        clientPartialDetails_ao.isDigiLocker = true;
        clientPartialDetails_ao.signzydt = "";

        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        history.push(dashboardUrl.profile);
      }
    }
  }

  function handleTryAgain() {
    history.push(containerUrl.dgLocker);
  }

  function handleContinue() {
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    clientPartialDetails_ao.aadharLinkedChoice = false;
    window.localStorage.setItem(
      "clientPartialDetails_ao",
      JSON.stringify(clientPartialDetails_ao)
    );
    history.push(dashboardUrl.profile);
  }

  return (
    <div className={"sa_div" + (isLoading ? " op05" : "")}>
      <ProfileImage>
        <img src={shareAdd} alt="Share Address" className="pfImg" />
      </ProfileImage>
      <div className="sa_headDiv">
        <p className="sa_heading">Allow Access to Address Proof</p>
      </div>
      <div className="sa_infoDiv">
        <p className="sa_info">
          Click on confirm below to share a digital copy of your Aadhaar card
          with us.
        </p>
        <p className="sa_info ln2">
          <span style={{ color: "black" }}>Please Note:</span> Digilocker does
          not reveal your 12 digit Aadhar number to us.
        </p>
      </div>

      {/* Error Msg Start*/}
      {errCode && (
        <div className="errMsgBlck" ref={errRef}>
          <ErrMsg>
            {typeof errCode === "number" ? (
              <label>
                Looks like there might be an issue with DigiLocker, would you
                like to try again or continue without DigiLocker?
              </label>
            ) : (
              <label>{errCode}</label>
            )}
          </ErrMsg>
        </div>
      )}
      {/* Error Msg End*/}

      {errCode ? (
        <>
          <div className="sa_btn">
            <Button
              btnName="Try Again"
              isLoading={isLoading}
              onClick={handleTryAgain}
            />
          </div>
          <div className="withoutDigi" onClick={handleContinue}>
            Continue Without DigiLocker
          </div>
        </>
      ) : (
        <div className="sa_btn">
          <Button
            btnName="Confirm"
            disabled={
              isLoading ||
              aadharDocDetails === null ||
              aadharDocDetails === undefined
            }
            isLoading={isLoading}
            onClick={fetchPDF}
          />
        </div>
      )}
    </div>
  );
};

export default ShareAdd;
