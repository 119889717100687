import React from "react";
import InputWithLabel from "../../../UI/InputWithLabel/InputWithLabel";
import InputField from "../../../UI/InputField/InputField";
import Button from "../../../UI/Button/Button";
import DropdownSearch from "../../../UI/DropdownSearch/DropdownSearch";
import { Verify } from "../../../UI/Button/Button";
import {
  AValidateMobileOtp,
  AGetMobileOtp,
  AGetOtpNew,
  AValidateOtpNew,
} from "../../../Services/registration/registrationApiService";
import "./CheckStatusForm.scss";
import {
  alphabetExp,
  integerExp,
  phoneNo,
  emailRegex,
  pan,
} from "../../../utils/regex";
import RegOtp from "../../../Registration/RegOtp/RegOtp";
import ErrMsg from "../../../UI/ErrMsg/ErrMsg";
import GlobalStore from "../../../store/globalStore";
import {
  productCode,
  assignedTo,
  containerUrl,
  loginUrl,
  statusUrl,
  dashboardUrl,
} from "../../../utils/constants";
import { clientContinue, setStageData } from "../../../Services/globalService";
import gtmService from "../../../Services/gtmService";
import { ClevertapService } from "../../../Services/clevertapService";
import { AppsFlyerService } from "../../../Services/appsFlyerService";
import cleverTapEvents from "../../../../config/cleverTapEvents";
import appsFlyerEvents from "../../../../config/appsFlyerEvents";
import useQuery from "../../../../customHooks/useQuery";
import { AGetApplicationStatusForKnowStatus } from "../../../Services/knowYourStatus/knowYourStatusApiService";
import { useHistory } from "react-router-dom";
import { AGetClientDetailsByPan } from "../../../Services/registration/panDobApiService";
import { AGetStageCompletionStatus } from "../../../Services/registration/continueApiService";
import { checkModuleCompleteness } from "../../../Profile/ProfileNavBar/ProfileNavBar";
import { APendingUploadList } from "../../../Services/dashboard/eSignApiServices";
import { errorMessage } from "../../../utils/errorMessage";
import { errorCode, errorMsg } from "../../../../ErrorCode/ErrorCode";
export interface ILeadFormFields {
  pan: string;
  mobile: string;
  rmCode: string;
}

const RegistrationForm = (props: any) => {
  const history = useHistory();
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";
  const UTM_PLACEMENT = quryParams.get("utm_placement")
    ? quryParams.get("utm_placement")
    : "";

  const [showRmCode, setShowRmCode] = React.useState(false);
  const [cities, setCities] = React.useState([]);

  const [values, setValues] = React.useState({ pan: "", mobile: "" });
  const [rmCode, setRmCode] = React.useState("");
  const [errCode, setErrCode] = React.useState("");
  const [showMobileBtn, setShowMobileBtn] = React.useState<boolean>(true);
  const [showMobileOtp, setShowMobileOtp] = React.useState<boolean>(false);
  const [otpId, setOtpId] = React.useState<string>("");
  const [disableOtp, setDisableOtp] = React.useState<boolean>(false);
  const [isMobileVerified, setIsMobileVerified] =
    React.useState<boolean>(false);
  const [invalidOtp, setInvalidOtp] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [whatsappMsgSuccess, setWhatsappMsgSuccess] =
    React.useState<boolean>(false);
  const [panDetails, setPanDetails] = React.useState<any>();
  let isUploadDocumentsCompleted = false;

  // used to store the field wise errors
  const [errors, setErrors] = React.useState<{
    [key in keyof ILeadFormFields]?: string;
  }>({
    pan: undefined,
    mobile: undefined,
  });
  const setPanDetailsinLoal = () => {
    const panFromLocalStorage = window.localStorage.getItem(
      "clientPartialDetails_ao"
    );
    if (
      panFromLocalStorage !== null &&
      panFromLocalStorage !== undefined &&
      panFromLocalStorage !== ""
    ) {
      let panDetailsLocal = JSON.parse(panFromLocalStorage);
      if (panDetailsLocal !== null && panDetailsLocal !== undefined) {
        setPanDetails(panDetailsLocal.panDetails);
      }
    }
  };
  React.useEffect(() => {
    setPanDetailsinLoal();
    window.localStorage.removeItem("clientPartialDetails_ao");
  }, []);

  // Field onchange method
  function onChange(key: keyof ILeadFormFields, value: string) {
    if (key === "mobile" && !integerExp.test(value)) {
      value = values[key];
    }

    key === "rmCode"
      ? setRmCode(value)
      : setValues({ ...values, [key]: value });

    if (key === "mobile") {
      if (
        GlobalStore.user.mobile !== "" &&
        GlobalStore.user.mobile === value &&
        isMobileVerified
      ) {
        setShowMobileBtn(false);
      } else {
        setShowMobileBtn(true);
      }
    }
    setErrors({ ...errors, [key]: "" });
    return value;
  }

  // OTP send method
  function sendOtp() {
    setIsLoading(true);
    //    AGetMobileOtp(otpSent, values.mobile);
    AGetOtpNew(
      (res: any) => {
        console.log("MON", res);
        otpSentNew(res);
      },
      values.mobile,
      "SMS"
    );
  }
  function otpSentNew(res: any) {
    if (
      res.data &&
      res.data.success &&
      res?.data?.data !== null &&
      res?.data?.data !== undefined &&
      !!res?.data?.data?.OTPId &&
      res?.data?.data?.OTPId !== ""
    ) {
      setOtpId(res?.data?.data?.OTPId);
      setIsLoading(false);
      setShowMobileBtn(false);
      setShowMobileOtp(true);
      setErrCode("");
    } else {
      // setErrCode('114 - Failed to send OTP, please retry.');
      setErrCode(res.error);
    }
  }

  function otpSent(res: any) {
    if (res.data && res.data.Success) {
      setIsLoading(false);
      setShowMobileBtn(false);
      setShowMobileOtp(true);
      setErrCode("");
    } else {
      // setErrCode('114 - Failed to send OTP, please retry.');
      setErrCode(res.error);
    }
  }

  // OTP validation method
  function validateOtp(val: string) {
    if (invalidOtp) {
      setInvalidOtp(false);
    }
    if (val.length === 4) {
      setIsLoading(true);
      setDisableOtp(true);
      AValidateOtpNew(
        (res: any) => {
          checkMobileValidateOtpNew(res);
        },
        otpId,
        val
      );
   //   AValidateMobileOtp(checkMobileValidateOtp, values.mobile.toString(), val);
    }
  }
  function checkMobileValidateOtpNew(res: any) {
    setIsLoading(false);
    setDisableOtp(false);
    if (res.error) {
      // setErrCode('115 - Failed to validate OTP, please retry.');
      // setErrCode(errorMessage.mobValidateOtp);
      setErrCode(res.error);
    } else {
      if (
        res.data &&
        res?.data?.data &&
        !!res?.data?.success &&
        res?.data?.success == true
      ) {
        const utmObj = JSON.parse(
          window.sessionStorage.getItem("objUTM_Info") || "{}"
        );
        if (
          utmObj &&
          utmObj.utm_source &&
          utmObj.utm_source.toUpperCase() === "PLATFORM"
        ) {
          setShowRmCode(true);
        }
        // GlobalStore.user.mobile = values.mobile;
        setIsMobileVerified(true);
        setShowMobileOtp(false);
      } else setInvalidOtp(true);
      setErrCode("");
    }
  }

  function checkMobileValidateOtp(res: any) {
    setIsLoading(false);
    setDisableOtp(false);
    if (res.error) {
      // setErrCode('115 - Failed to validate OTP, please retry.');
      // setErrCode(errorMessage.mobValidateOtp);
      setErrCode(res.error);
    } else {
      if (res.data == "true") {
        const utmObj = JSON.parse(
          window.sessionStorage.getItem("objUTM_Info") || "{}"
        );
        if (
          utmObj &&
          utmObj.utm_source &&
          utmObj.utm_source.toUpperCase() === "PLATFORM"
        ) {
          setShowRmCode(true);
        }
        // GlobalStore.user.mobile = values.mobile;
        setIsMobileVerified(true);
        setShowMobileOtp(false);
      } else setInvalidOtp(true);
      setErrCode("");
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    clientPartialDetails_ao.pan = values.pan;
    clientPartialDetails_ao.panDetails = panDetails;
    // clientPartialDetails_ao.mobile = values.mobile;
    window.localStorage.setItem(
      "clientPartialDetails_ao",
      JSON.stringify(clientPartialDetails_ao)
    );
    GlobalStore.personaldetails.pan = values.pan;
    // GlobalStore.user.mobile = values.mobile;
    // GlobalStore.user.email = 'example@email.com';
    AGetClientDetailsByPan(checkApplicationStatus, values.pan);
  }

  function checkApplicationStatus(res: any) {
    if (!res || res.error) {
      //   setErrCode("701- Error in fetching Client details from our system.");
      setErrCode(res.error);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (res?.data == null || res?.data == "Records Not Found")
        history.push(statusUrl.accountNotFound);
      else if (res?.data !== null) {
        GlobalStore.applicationID = !GlobalStore.applicationID
          ? res?.data?.ApplicatoinId
          : GlobalStore.applicationID;
        GlobalStore.token = !GlobalStore.token
          ? res?.data?.Token
          : GlobalStore.token;

        GlobalStore.personaldetails.pan = !GlobalStore.personaldetails.pan
          ? res?.data?.PAN
          : GlobalStore.personaldetails.pan;

        // GlobalStore.dob = GlobalStore?.

        history.push(statusUrl.statusContinue);
      }
    }
  }

  return (
    <div className={"registrationForm" + (isLoading ? " op05" : "")}>
      <div className="checkStatusHeading">
        <label>Check Account Opening Status</label>
      </div>
      <form>
        <fieldset className="regForm" disabled={isLoading}>
          {/* Email Start*/}
          <div className="email">
            <div className="row emlInput">
              <label className="inptLbl">Enter PAN </label>
              <InputField
                onChange={(value) => onChange("pan", value.toUpperCase())}
                placeholder="Eg. ABCDE1234F"
                pattern={pan}
                onBlur={(error) =>
                  setErrors({
                    ...errors,
                    pan: error.pattern ? "" : errorMsg(errorCode.FE07),
                  })
                }
                errorText={errors.pan}
                type="text"
                maxLength={10}
                // defaultValue="CWLPA4946C" //To be removed
              />
            </div>
          </div>
          {/* <label className='emailInfoMsg'>
                        By entering the email address you confirm to receive all contract notes and other regulatory communication through digital / ECN mode
                    </label> */}

          {/* Email End*/}

          {/* Mobile Phone Start*/}
          <>
            <div className="email">
              <div className="row emlInput">
                <label className="inptLbl">Mobile Number</label>
                <InputField
                  pattern={phoneNo}
                  onChange={(value) => onChange("mobile", value)}
                  onBlur={(error) =>
                    setErrors({
                      ...errors,
                      mobile: error.pattern ? "" : errorMsg(errorCode.FE08),
                    })
                  }
                  placeholder="Eg. 9876543210"
                  errorText={errors.mobile}
                  type="tel"
                  suffix={
                    GlobalStore.user.mobile === values.mobile &&
                    values.mobile !== "" &&
                    isMobileVerified ? (
                      <i className="edel-icon-tick" />
                    ) : (
                      <></>
                    )
                  }
                  maxLength={10}
                  // defaultValue="987654321" //To be removed
                />
              </div>
              {showMobileBtn && (
                <div className="emlVrfy">
                  <Verify
                    btnName="Verify"
                    disabled={
                      Object.values([errors.pan, errors.mobile]).some(
                        (error) => error
                      ) ||
                      Object.values([values.pan, values.mobile]).some(
                        (value) => !value
                      )
                    }
                    onClick={() => {
                      gtmService({ action: "LEAD_PAGE", label: "MOB_VERIFY" });
                      sendOtp();
                    }}
                  />
                </div>
              )}
            </div>
            {showMobileOtp && !showMobileBtn && (
              <div className="otpSection">
                <RegOtp
                  label="Enter Mobile OTP"
                  otpTimer={15}
                  eleId="mob_"
                  onChange={validateOtp}
                  resendClicked={() => {
                    gtmService({ action: "LEAD_PAGE", label: "OTP_RESEND" });
                    sendOtp();
                  }}
                  disableOtp={disableOtp}
                  invalidOtp={invalidOtp}
                />
              </div>
            )}
          </>
          {/* Mobile Phone End*/}

          {isMobileVerified && showRmCode && (
            <>
              <div className="row">
                <label className="rmCode">Have RM/Partner code?</label>
              </div>

              <div className="row les_margin">
                <label className="rmCodeLabel">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aliquam interdum, felis vel rhoncus ullamcorper.
                </label>
                <div className="row les_margin">
                  <InputWithLabel
                    onChange={(value) => onChange("rmCode", value)}
                    label="Enter Code (Optional)"
                    placeholder="Eg. ABC1234"
                    type="text"
                    onBlur={() => {}}
                  />
                </div>
              </div>
            </>
          )}

          {/* Error Msg Start*/}
          {errCode && (
            <div className="errMsgBlck">
              <ErrMsg>
                <label>We are facing some technical issue.</label>
                <label>Please try after sometime (error code:{errCode})</label>
              </ErrMsg>
            </div>
          )}
          {/* Error Msg End*/}

          {isMobileVerified && (
            <>
              <div className="row">
                <p className="whatsappInfo">
                  By signing up you agree to receive communication on WhatsApp
                </p>
              </div>
              <div className="row les_margin">
                <Button
                  onClick={handleSubmit}
                  btnName="Check Status"
                  disabled={
                    Object.values(errors).some((error) => error) ||
                    Object.values(values).some((value) => !value) ||
                    !isMobileVerified
                  }
                  isLoading={isLoading}
                  type="submit"
                />
              </div>
            </>
          )}
        </fieldset>
      </form>
    </div>
  );
};

export default RegistrationForm;
