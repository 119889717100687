import config from "../../config/domain";
import GlobalStore from "../store/globalStore";
import requestMapper from "./mapper/serviceMapper";
import servicesList from "./serviceList";

interface callReq {
  url: string;
  body: {};
  method?: string;
  apiBaseUrl?: string;
  tempToken?: string;
  serviceName?: string;
  isNewUrl?: boolean;
}

export const xhrApiService = async (
  req: callReq,
  callback: CallableFunction,
  onProgress?: Function,
  method?: string,
  apiBaseUrl?: string,
  serviceName?: string,
  tempToken?: string,
  isNewUrl?: boolean
) => {
  let xhr = new XMLHttpRequest();

  // track upload progress
  xhr.upload.onprogress = function (event) {
    onProgress && onProgress(event);
  };

  // track completion: both successful or not
  xhr.onloadend = async function (res) {
    resolveResult(res);
    // if (serviceName && serviceName !== "") {
    //   let result = await requestMapper(this.responseText, serviceName);
    //   callback(result);
    // } else {
    //   callback(res);
    // }
    // if (xhr.status === 200) {
    //   // callback(JSON.parse(this.responseText));
    // } else {
    //   callback(undefined);
    // }
  };

  const resolveResult = async (res: any) => {
    console.log("resolveResult", serviceName, res.currentTarget.responseText);
    if (res.currentTarget) {
      if (res.currentTarget.responseText) {
        let result = await requestMapper(
          JSON.parse(res.currentTarget.responseText),
          servicesList.uploadDocumentToS3
        );
        if (result) {
          callback(result);
        }
      } else {
        callback(res.currentTarget);
      }
    }
  };

  xhr.open(method ? method : "POST", `${config.diyNewApiUrl}${req.url}`);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("api-key", config.apiKey);
  let clientPartialDetails = JSON.parse(
    window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  );
  if (GlobalStore.token !== "") {
    xhr.setRequestHeader("Authorization", `Bearer ${GlobalStore.token}`);
  }

  if (!GlobalStore.token && clientPartialDetails.token) {
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${clientPartialDetails.token}`
    );
  }
  xhr.send(JSON.stringify(req.body));
};
