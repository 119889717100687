import React, { Component } from 'react';
import './OtpTimer.scss'

interface IOtpTimerProps {
    timer: number
}

let timer: any;
class OtpTimer extends Component<IOtpTimerProps> {

    state = {
        timer: this.props.timer
    }

    startTimer = () => {
        timer = setInterval(() => {
            let temp = this.state.timer - 1;
            if (temp === 0) {
                this.setState({ timer: this.props.timer })
                clearInterval(timer);
            } else {
                this.setState({ timer: temp })
            }
        }, 1000)
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval(timer);
    }

    render() {

        return (
            <div className="circular">
                <div className="inner">
                </div>
                <div className="outer">
                </div>
                <div className="numb">
                    {this.state.timer}
                </div>
                <div className="circle">
                    <div className="bar right">
                        <div className="progress"
                            style={{
                                animation: `left ${(this.props.timer) / 2}s linear both`,
                                animationDelay: `${(this.props.timer) / 2}s`
                            }}>
                        </div>
                    </div>
                    <div className="bar left">
                        <div className="progress"
                            style={{
                                animation: `left ${(this.props.timer) / 2}s linear both`
                            }}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OtpTimer