import React from 'react';
import ProfileImage from '../../UI/ProfileImage/ProfileImage'
import './MailVerified.scss'

const MailVerified = () => (
    <div className='mailVer'>
        <div className='mailVerLogo'>
            <ProfileImage color='rgba(6, 99, 226, 0.1)'/>
        </div>
        <p className='mailVerHeading'>Verification Mail Sent</p>
        <p className='mailVerInfo'>We have sent a verification link to your email id a********@g***.com . Kindly check your inbox.</p>
    </div>
)

export default MailVerified;