import GlobalStore from "../store/globalStore";

declare var dataLayer: any;

export interface IGtmObjInterface {
	event?: string;
	category?: string;
	action: string;
	label: string;
	Lead_ID?: string;
	REF_ID?: string;
}

/**
 * Single function that handles GA events
 * @param gtmObj IGtmObjInterface keys should be passed. 
 */
export default function gtmService(gtmObj: IGtmObjInterface) {
	const data: IGtmObjInterface = {
		event: gtmObj.category || 'event_New_Account_Opening',
		category: gtmObj.category || 'New_Account_Opening',
		action: gtmObj.action,
		label: gtmObj.label,
	};
	if (GlobalStore.leadId) {
		data.Lead_ID = GlobalStore.leadId
	}
	if (GlobalStore.applicationID) {
		data.REF_ID = GlobalStore.applicationID
	}
	console.log(data);
	dataLayer.push(data);
}
