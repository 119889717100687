import React, { useEffect, useState } from "react";
import regbanner from "../../../assets/icons/panDob.svg";
import InputField from "../../UI/InputField/InputField";
import Button, {
  TransparentButton,
  SubmitButton,
} from "../../UI/Button/Button";
import watsAppIcon from "../../../assets/icons/watsapp.png";
import Radio from "../../UI/Radio/Radio";
import isSeeding from "../../../assets/icons/seeding.png";
import {
  aadhaarDocumnetId,
  containerUrl,
  corrAadhaarDocumnetId,
  dashboardUrl,
  DIY,
  employeeAccCategory,
  retailAccCategory,
  statusUrl,
  watsappNo,
  geoLocationConfig,
} from "../../utils/constants";

import { pan, alphaNumExp, betweenNChars } from "../../utils/regex";
import "./PANInfo.scss";
import {
  ACheckPanIbeats,
  AGetPanUtiData,
  AGetClientDetailsByPan,
  AGetClientDetailsByLeadId,
  AWhatsappCom,
  AIsPANExists,
  ASavePanDobDetails,
  AGetCkycNumber,
  AGetKraDetails,
  AGetEmployeeByPan,
  AGetCkycDetails,
  APanLinkedWithAadhar,
  ACheckUCCExists,
  APanVerification,
} from "../../Services/registration/panDobApiService";
import {
  ACreateLead,
  ACreateLeadSource,
} from "../../Services/registration/registrationApiService";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import GlobalStore from "../../store/globalStore";
import { useHistory } from "react-router-dom";
import {
  clientContinue,
  utmDurationInfoObj,
  updateUtmInfo,
  setStageData,
  ageCalculator,
  splitFullName,
} from "../../Services/globalService";
import { APendingUploadList } from "../../Services/dashboard/eSignApiServices";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import { checkModuleCompleteness } from "../../Profile/ProfileNavBar/ProfileNavBar";
import { ARedisDigilockerDoc } from "../../Services/dashboard/profileApiService";
import InfoModal from "../../UI/InfoModal/InfoModal";
import DeviceContext from "../../Services/identifyDevice";
import CheckDigilockerPopup from "./CheckDigilockerPopup/CheckDigilockerPopup";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Calender from "../../UI/Calender/Calender";
import moment from "moment";
import gtmService from "../../Services/gtmService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import useQuery from "../../../customHooks/useQuery";
import { ClevertapService } from "../../Services/clevertapService";
import DocumentBrowse, {
  DocDetailFormat,
} from "../../UI/DocumentBrowse/DocumentBrowse";
import {
  initialDocObj,
  initialPanDocObj,
} from "../../DocumentVerification/DocVerificationForm/DocVerificationForm";
import {
  ACheckLocation,
  ACheckPhotoLiveness,
  AextractAadhaar,
  AextractPan,
  AextractPanNew,
  AmaskAadhaar,
  ASaveDocumentDetails,
} from "../../Services/dashboard/docVerificationAPIServices";
import { AUploadDocument } from "../../Services/dashboard/brokerageServices";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Toggle from "../../UI/Toggle/Toggle";
import config from "../../../config/domain";
import { errorMessage } from "../../utils/errorMessage";
import {
  ASaveIPVerificationDetails,
  ASendSms,
} from "../../Services/dashboard/ipvApiServices";
import useGeoLocation from "../../../customHooks/useGeoLocation";
import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";
import Loader from "../../UI/Loader/Loader";

export interface IPanDobFields {
  pan: string;
  dob: Date | null;
  aadharLinked: "No" | "Yes" | "Not Sure";
  aadhaarNo?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

const panValidationKeyList: any = {
  PanStatus: "PanStatusMsg",
  Name: "NameMsg",
  Dob: "DobMsg",
  SeedingStatus: "SeedingStatusMsg",
};

export interface iGeoLocation {
  loaded: boolean;
  coordinates: { lat: string; lng: string };
  error: boolean;
}

export type saveDocumentObj = {
  documentCategoryId?: string;
  documentId?: string;
  fileName: string;
  isfilecropped: boolean;
  mimeType: string | undefined;
  panno: string;
  personaldetailsid: string;
  autoqc: boolean;
  iskycdocument: boolean;
  documentNumber?: string;
};

export interface PANInfoProps {}
let isPanLinkedToAadhar: boolean;
const PANInfo: React.FC<PANInfoProps> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";
  let localURL = localStorage.getItem("currentURL"),
    navigateURL: string = "";
  if (
    localURL !== null &&
    localURL !== undefined &&
    localURL !== "" &&
    localURL?.length > 0
  ) {
    navigateURL = window.atob(localURL);
    localURL = window.atob(localURL);
  }
  const [values, setValues] = React.useState<IPanDobFields>({
    pan: "",
    aadharLinked: "No",
    dob: null,
    //aadhaarNo: "",
    firstName: "",
    middleName: "",
    lastName: "",
  });
  const [validPan, setValidPan] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errCode, setErrCode] = React.useState<any>("");
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const [showExplain, setShowExplain] = React.useState<boolean>(false);
  const [showDigiRedirection, setShowDigiRedirection] =
    React.useState<boolean>(false);
  let isEmployee: boolean = false;
  const [errors, setErrors] = React.useState<{
    [key in keyof IPanDobFields]?: string;
  }>({
    pan: undefined,
    dob: undefined,
    aadharLinked: undefined,
    aadhaarNo: undefined,
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
  });
  const history = useHistory();
  const [removePanImg, setRemovePanImg] = React.useState<boolean>(false);
  const [utmObj, setUtmObj] = React.useState<any>(null);
  const [panObj, setPanObj] = React.useState<DocDetailFormat>({
    ...initialPanDocObj,
  });
  const [signatureObj, setSignatureObj] = React.useState<DocDetailFormat>({
    ...initialPanDocObj,
  });
  const [photoObj, setPhotoObj] = React.useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [permAddressObj, setPermAddressObj] = React.useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [consent, setConsent] = React.useState<boolean>(true);
  const videoRefElement = React.useRef<HTMLVideoElement>(null);
  const canvasRefElement = React.useRef<HTMLCanvasElement>(null);
  const [showRetake, setShowRetake] = React.useState<boolean>(false);
  const [panBase64, setPanBase64] = React.useState<string>("");
  const [togPhoto, setTogPhoto] = React.useState<boolean>(false);
  const [streams, setStreams] = React.useState<MediaStream>();
  const [hasPhoto, setHasPhoto] = React.useState<boolean>(false);
  const [panNumberMatched, setPanNumberMatched] = React.useState(false);
  const [dobChecked, setDobChecked] = React.useState(false);
  const [isNormalClient, setIsNormalClient] = React.useState<boolean>(false);
  const [panDobState, setPanDobState] = React.useState("");
  const [panValState, setPanValState] = React.useState("");
  const [location, setLocation] = useState<any>("");
  const geoLoc: iGeoLocation = useGeoLocation();
  const [isGeoError, setIsGeoError] = React.useState<boolean>(false);
  const [isGeoErrorTimeFlag, setIsGeoErrorTimeFlag] =
    React.useState<boolean>(false);
  const [showBrowserPermissionModal, setsShowBrowserPermissionModal] =
    useState<boolean>(false);
  const [browserPermission, setBrowserPermission] = useState<boolean>(true);
  const [locationPermission, setLocationPermission] = useState<boolean>(true);
  const [cameraPermission, setCameraPermission] = useState<boolean>(true);
  const [errorFoundInPanValidation, setErrorFoundInPanValidation] =
    useState<boolean>(false);
  //setModalDisplayText

  const [panUploadObject, setPanUploadObject] = React.useState<any>("");
  const [panUploaded, setPanUploaded] = React.useState<boolean>(false);

  const [liveLocationCheck, setLiveLocationCheck] = useState<any>({
    checkPhotoLiveness: false,
    checkLocation: false,
  });
  const [photoErrorMsg, setPhotoErrorMsg] = React.useState("");
  const [seedingPopup, setSeedingPopup] = React.useState<boolean>(false);
  const [checkpanaaddharflag, setCheckpanaaddharflag] = React.useState<boolean>(
    GlobalStore.stageStatus.checkpanaaddharflag === false ? true : false
  );
  const [panOCR, setPanOCR] = useState<any>(null);
  const [willDoLater, setWillDoLater] = useState<boolean>(false);
  const [panFileUploaded, setPanFileUploaded] = useState<boolean>(false);
  const [panFileUploadedLoader, setPanFileUploadedLoader] =
    useState<boolean>(false);
  const [invalidPanFieldUploadedMsg, setInvalidPanFileUploadedMsg] =
    React.useState<string>("");
  const [applicationModeType, setApplicationModeType] = useState<any>("");
  const [modalDisplayText, setModalDisplayText] = useState<any>("");
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [panInvalidMsg, setPanInvalidMsg] = React.useState<string>("");
  //  const [panValidationResp,setPanValidationResp]=React.useState<any>();
  let panValidationResp: any;
  const [applicationInitializedFlag, setApplicationInitializedFlag] =
    useState<boolean>(false);
  const [submitFlag, setSubmitFlag] = useState<boolean>(false);
  const [extractPanResponse, setExtractPanResponse] = React.useState<{
    age: number;
    date_of_birth: string;
    date_of_issue: string;
    fathers_name: string;
    id_number: string;
    is_scanned: boolean;
    minor: boolean;
    name_on_card: string;
    pan_type: string;
  }>();
  const [isSelfieCaptured, setisSelfieCaptured] =
    React.useState<boolean>(false); //Added By Ravi
  const [panVerificationMatch, setPanVerificationMatched] =
    React.useState<boolean>(false);

  const [documentsInfo, setDocumentInfo] = useState({
    totalDocs: [],
    totalDocsCount: 0,
    uploadedDocsCount: 0,
  });

  let watchPositionId: number;
  let panDob: string;
  const saveDetails: saveDocumentObj[] = [];
  const saveKraDetails: saveDocumentObj[] = [];

  let base64String: string;
  let docCount = 0;
  let ckycDocs = 0;
  let kraDocCount = 0;
  let kraDocs = 0;
  // let totalDocs: Array<string> = [];
  // let totalDocsCount = 0;
  // let uploadedDocsCount = 0;

  let isPanLinkedToAadhar: boolean;

  const checkAutoQcForPan = () => {
    let name =
      (values.firstName as string) + values.middleName + values.lastName;

    if (
      values.pan === extractPanResponse?.id_number &&
      moment(values.dob).format("YYYY-MM-DD") ===
        extractPanResponse?.date_of_birth &&
      name === extractPanResponse?.name_on_card.replace(/\s/g, "")
    ) {
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    // Assign utm object into local state
    const objUtm = JSON.parse(
      window.sessionStorage.getItem("objUTM_Info") || "{}"
    );
    setUtmObj(objUtm);

    if (
      containerUrl.continue === clientContinue() &&
      !GlobalStore.isPartialFilledClient
    ) {
      console.log("showSubmitModal2", showSubmitModal);
      history.push(containerUrl.continue);
    } else {
      console.log("GlobalStore.leadId check =", GlobalStore.leadId);
      if (!GlobalStore.leadId || GlobalStore.leadId === "") {
        console.log("registration push");
        // history.push(containerUrl.registration);
      } else {
        init();
        window.scroll(0, 0);
      }
    }
    if (!GlobalStore.user.email || !GlobalStore.user.mobile) {
      if (!!navigateURL) {
        window.location.href = navigateURL;
      }
    }
  }, []);

  // React.useEffect(() => {
  //   if (
  //     moment(values.dob).format("YYYY-MM-DD") == panDobState &&
  //     values.pan == panValState
  //   ) {
  //     GlobalStore.dateofbirth = moment(values.dob).format("MM/DD/YYYY");
  //     GlobalStore.dob = moment(values.dob).format("DD/MM/YYYY");
  //     GlobalStore.birthDate = moment(values.dob).format("MM/DD/YYYY");

  //     if (panUploaded == false) {
  //       setRemovePanImg(false);
  //       setDobChecked(true);
  //       panUploadObject.savePanObj.autoqc = true;
  //       AUploadDocument(
  //         (res: any) => getPanUploadRes(res, panUploadObject.savePanObj),
  //         panUploadObject.uploadObj,
  //         (event: ProgressEvent) =>
  //           onUploadProgress(
  //             event,
  //             panUploadObject.id,
  //             panUploadObject.fileName,
  //             panUploadObject.type
  //           )
  //       );
  //     }

  //     const objUTM_Info = utmDurationInfoObj(startTime, "registrationdur");
  //     let objAccType = window.sessionStorage.getItem("objAccType") || ""; // only demat getting parameter type
  //     const panDobObj = {
  //       leadId: GlobalStore.leadId,
  //       emailID: GlobalStore.user.email,
  //       mobileno: GlobalStore.user.mobile,
  //       isemployeepan: isEmployee,
  //       panNo: values.pan,
  //       dob: GlobalStore.dateofbirth,
  //       aadhaar: values.aadhaarNo,
  //       ispanaadhaarlinked: isPanLinkedToAadhar,
  //       platform: 1,
  //       relationship: false,
  //       relationName: "",
  //       others: "",
  //       objUTM_Info: objUTM_Info,
  //       response: "",
  //       TransactionId: "",
  //       kycverified: "",
  //       product: objAccType === "DMT" ? "D" : "TD",
  //       panFName: values?.firstName,
  //       panMName: values?.middleName,
  //       panLName: values?.lastName,
  //       SendSelfieLink: togPhoto, //((photoObj.base64==null || photoObj.base64=="") && togPhoto==false) ? true:
  //       diyReferralLink: localURL,
  //     };
  //     console.log("called here ===>");
  //     ASavePanDobDetails((res: any) => {
  //       if (res && res.data) {
  //         localStorage.removeItem("currentURL");
  //         GlobalStore.token = res.data?.token;
  //       }
  //     }, panDobObj);
  //     const clientPartialDetails_ao = JSON.parse(
  //       window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  //     );
  //     clientPartialDetails_ao.dob = GlobalStore.dob;
  //     clientPartialDetails_ao.birthDate = GlobalStore.birthDate;
  //     clientPartialDetails_ao.token = GlobalStore.token;
  //     window.localStorage.setItem(
  //       "clientPartialDetails_ao",
  //       JSON.stringify(clientPartialDetails_ao)
  //     );
  //   }
  // }, [values.dob, values.pan]);

  // Clervertap event: pan screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.panPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: "",
    };
    ClevertapService.pushEvent(data);
  }, []);

  React.useEffect(() => {
    console.log("applicationModeType", applicationModeType);
    if (applicationModeType === "isKRA") {
      setModalDisplayText(
        "As per SEBI Regulations, account would be opened as per the Name available on the Income Tax Website. We identify that you are KRA client we have prefilled your application."
      );
    } else if (applicationModeType === "isDigiLocker") {
      setModalDisplayText(
        " As per SEBI Regulations, account would be opened as per the Name available on the Income Tax Website. You can opt for Digi locker flow and speed up the account opening process."
      );
    }
  }, [applicationModeType]);

  function errorPosition(data: any) {
    setIsLoading(true);
    setLocationPermission(false);

    // setBrowserPermission(false);
    // setsShowBrowserPermissionModal(true);
    // if (!browserPermission) {
    //   getLocation();
    // }
  }

  const registerCleverTapEventAfterProceed = () => {
    const data = {
      EventName: cleverTapEvents.registration.panPage.CLICK_ON_PROCEED,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
      KRA: GlobalStore.isKRA,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
    };
    ClevertapService.pushEvent(data);

    // Apps flyer event for click on proceed on pan details page
    AppsFlyerService.pushEvent(
      appsFlyerEvents.registration.panPage.CLICK_ON_PROCEED
    );
  };

  function init() {
    //CleverTap log start
    /*
        var data = {
            CurrentPage: $location.path(),
            Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
            EventName: 'Pan_dob_view'
        };
        clevertap.event.push(data.EventName, data);
        */
    //CleverTap log end

    window.sessionStorage.setItem("isSeniorCitizen", "false");
    window.localStorage.removeItem("isAadhaarVerified");
    GlobalStore.personaldetails = {
      firstname: GlobalStore.user.firstname,
      lastname: GlobalStore.user.lastName,
      middlename: "",
      mobile: GlobalStore.user.mobile,
      emailId: GlobalStore.user.email,
      aadhaar: "",
      pan: "",
      gender: "",
      dob: "",
      additionalemail: "",
      additionalmobile: "",
      PANName: "",
      adrLine1: "",
      adrLine2: "",
      city: "",
      state: "",
      pincode: "",
    };
  }

  function onChange(key: keyof IPanDobFields, value: string | Date) {
    if (
      key === "pan" &&
      typeof value === "string" &&
      !alphaNumExp.test(value)
    ) {
      value = values[key];
    }
    if (key === "pan") setValidPan(false);
    if (key === "aadharLinked" && typeof value === "string")
      gtmService({
        action: "PAN_DOB",
        label: `AADHAR_LINK | ${value.toUpperCase()}`,
      });
    if (key === "dob" && typeof value !== "string") {
      console.log("onChange 1");
      setErrCode(errCode);
      if (ageCalculator(value) >= 60)
        window.sessionStorage.setItem("isSeniorCitizen", "true");
      else window.sessionStorage.setItem("isSeniorCitizen", "false");
      console.log(moment(value).format("YYYY-MM-DD"), " || ", panDobState);
      if (moment(value).format("YYYY-MM-DD") === panDobState) {
        setDobChecked(true);
        setRemovePanImg(false);
        setErrCode("");
        console.log("onChange 2");
      } else {
        console.log("onChange 3");
        setDobChecked(false);
      }
    }
    console.log("errors", errors);
    setErrors({ ...errors, [key]: "" });
    setValues({ ...values, [key]: value });
  }

  function onBlurPan(error: any) {
    setErrors({
      ...errors,
      pan: error.pattern ? "" : errorMsg(errorCode.FE07),
    });
    setErrors({
      ...errors,
      pan: error.pattern ? "" : errorMsg(errorCode.FE09),
    });
    if (error.pattern) {
      setValidPan(true);
    } else {
      setValidPan(false);
    }
  }

  function onBlurDob(event: React.FocusEvent<HTMLInputElement>) {
    if (!values.dob) {
      // setErrors({ ...errors, dob: 'Please select a valid date' });
      setErrors({ ...errors, dob: errorMsg(errorCode.FE10) });
    }
  }

  function onBlurAadhaar(error: any) {
    setErrors({
      ...errors,
      aadhaarNo: error.pattern ? "" : errorMsg(errorCode.FE46),
    });
  }

  function handleSubmit() {
    //GA Code
    /*
        var data = {
            category: 'ACCOUNT_OPENING',
            action: 'STEP-2',
            label: 'CONTINUE',
            event: 'event account opening'
        }
    
        dataLayer.push(data);
        */
    setErrCode("");
    setIsLoading(true);
    setSubmitFlag(false);

    let isValidPan = pan.test(values.pan);
    if ((values.pan !== "" || values.pan !== undefined) && isValidPan) {
      console.log("values", values);
      AGetEmployeeByPan(checkIsPanEmployee, values.pan);
      // ACheckPanIbeats(checkedPanInIbeats, values.pan);
      let panFullName = values.firstName + " ";
      if (values?.middleName !== undefined && values.middleName?.length > 0) {
        panFullName += values.middleName?.split(" ").join(" ");
      }
      panFullName += values?.lastName ?? "";
      panValidation(
        {
          panNo: values.pan,
          name:
            values.firstName +
            " " +
            (values?.middleName ?? "" + " ") +
            values.lastName,
          dob: moment(values.dob).format("DD/MM/YYYY"),
          source: "DIY",
        },
        () => {
          AIsPANExists(isPANExists, values.pan);
        }
      );
      // if(values?.pan?.length > 0 && extractPanResponse && extractPanResponse?.id_number?.length > 0 && values.pan===extractPanResponse.id_number){
      //   setPanInvalidMsg("");

      // }else{
      //   setPanInvalidMsg("Oops!! Pan Number is incorrect");
      //   setIsLoading(false);
      // }
      // panValidation({panNo:values.pan,name:values.firstName+" "+values?.middleName??""+
      // values.lastName,dob:moment(values.dob).format("DD/MM/YYYY"),source:"DIY"}, ()=>{AIsPANExists(isPANExists, values.pan)});
    } else {
      setsShowBrowserPermissionModal(true);
    }
  }

  function CheckUCCExists(res: any) {
    if (!res || res.error) {
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }

      setIsLoading(false);
      gtmService({ action: "CheckUCCExists", label: "ERROR || ERR105" });
    } else {
      console.log("UCC Check 3");

      savePanDob();
    }
  }

  function checkIsPanEmployee(res: any) {
    if (res && res.status) {
      const responseDataObj = res.data;
      if (responseDataObj.result.Flag === "Y") isEmployee = true;
    } else {
      if (!res || res.error) {
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        gtmService({
          action: "GetEmployeeDetailsByPAN",
          label: "ERROR || ERR109",
        });
      }
    }
  }

  function checkedPanInIbeats(res: any) {
    if (!res || res.error) {
      // setErrCode("116 - Error in fetching Pan details from our system.");
      // setErrCode(errorMessage.panDetailsFailed);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      setIsLoading(false);

      gtmService({ action: "CheckPanInSystem", label: "ERROR || ERR100" });
    } else {
      if (res.data) {
        registerCleverTapEventAfterProceed();
        gtmService({ action: "PAN_DOB", label: "Step_Complete" });
        history.push(containerUrl.accexists);
        setIsLoading(false);
      } else {
        if (isPanLinkedToAadhar != true) {
          // APanLinkedWithAadhar(
          //   APanLinkedWithAadharRes,
          //   values.pan,
          //   values.aadhaarNo
          // );
          // isPanLinkedToAadhar = false;
          // AIsPANExists(isPANExists, values.pan);
        } else {
          AGetClientDetailsByPan(searchByPan, values.pan);
        }
      }
    }
  }

  function APanLinkedWithAadharRes(res: any) {
    if (!res || res.error) {
      setIsLoading(false);

      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
    } else {
      if (res.data?.isvalid == true) {
        isPanLinkedToAadhar = res.data.islinked == true ? true : false;
        if (res.data.islinked) {
          setIsLoading(true);
          setSeedingPopup(false);
        } else {
          setSeedingPopup(true);
          setIsLoading(false);
        }
        // AIsPANExists(isPANExists, values.pan);
      } else {
        // alert(res.data?.message);
        setIsLoading(false);
      }
    }
  }

  function isPANExists(res: any) {
    if (!res || res.error) {
      // setErrCode('117 - Error in fetching Pan details from our system.');
      // setErrCode(errorMessage.panExist);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      setIsLoading(false);

      gtmService({ action: "CheckPanInSystem", label: "ERROR || ERR105" });
    } else {
      let isValidPan = pan.test(values.pan);
      if (
        res.data !== undefined &&
        (values.pan !== "" || values.pan !== undefined) &&
        isValidPan
      ) {
        if (res.data == false) {
          validatePANNew(
            panValidationResp?.SeedingStatus,
            panValidationResp?.SeedingStatusMsg,
            panValidationResp?.PanStatus
          );
          //  AGetPanUtiData(validatePAN, values.pan);
        } else if (res.data == true) {
          setErrCode(errorMessage.ApplicationInProcess);
          setIsLoading(false);
        }
      } else {
        setErrors({
          ...errors,
          pan: isValidPan ? "" : errorMsg(errorCode.FE09),
        });
      }
    }
  }
  const validatePANNew = (
    seedingStatus?: string,
    SeedingStatusMsg?: string,
    PanStatus?: string
  ) => {
    let PANFirstName = values?.firstName;
    let PANMiddleName = values.middleName ?? "";
    let PANlastName = values?.lastName ?? "";

    if (PANFirstName === "" && PANlastName !== "") {
      PANFirstName = PANlastName;
      GlobalStore.personaldetails.PANName = PANFirstName;
      PANlastName = "";
      if (PANMiddleName !== "") {
        GlobalStore.personaldetails.PANName =
          GlobalStore.personaldetails.PANName + " " + PANMiddleName;
      }
    } else if (
      PANFirstName === "" &&
      PANMiddleName === "" &&
      PANlastName === ""
    ) {
      GlobalStore.personaldetails.PANName = "";
    } else if (PANMiddleName === "") {
      GlobalStore.personaldetails.PANName = PANFirstName + " " + PANlastName;
      PANMiddleName = "";
    } else {
      GlobalStore.personaldetails.PANName =
        PANFirstName + " " + PANMiddleName + " " + PANlastName;
    }

    //Modify personal details based on this response.
    console.log(
      "PAN DETails",
      GlobalStore?.personaldetails?.panDetails,
      SeedingStatusMsg,
      PanStatus
    );
    GlobalStore.personaldetails.firstname = PANFirstName;
    GlobalStore.personaldetails.lastname = PANlastName;
    GlobalStore.personaldetails.middlename = PANMiddleName;
    GlobalStore.personaldetails.panDetails = {
      PanNo: values.pan,
      Firstname: PANFirstName,
      Middlename: PANMiddleName,
      Lastname: PANlastName,
      PANIssueDate: "",
      AadharSeedingStatus: SeedingStatusMsg,
      VendorName: "",
      IsValid: PanStatus === "Y" ? true : false,
      IsAadharSeeded: seedingStatus,
    };
    console.log("PAN DETails", GlobalStore?.personaldetails?.panDetails);
    if (seedingStatus === "N") {
      GlobalStore.isSeeding = false;
    } else {
      GlobalStore.isSeeding = true;
    }
    ACheckPanIbeats(checkedPanInIbeats, values.pan);
  };
  function validatePAN(res: any) {
    if (!res || res.error) {
      // setErrCode('107  - Error in fetching Pan details from PAN UTI system.');
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "GetPanDetails", label: "ERROR || ERR101" });
      setIsLoading(false);
    } else {
      if (res && res.data && res.data.PanNo && res.data.IsValid == true) {
        //CleverTap log Start
        // var data = {
        //     CurrentPage: $location.path(),
        //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
        //     EventName: 'pan_dob_success',
        //     CTA: 'Continue'
        // };
        // clevertap.event.push(data.EventName, data);
        //CleverTap log End

        let PANFirstName = res.data.Firstname ? res.data.Firstname : "";
        let PANMiddleName = res.data.Middlename ? res.data.Middlename : "";
        let PANlastName = res.data.Lastname ? res.data.Lastname : "";

        if (PANFirstName === "" && PANlastName !== "") {
          PANFirstName = PANlastName;
          GlobalStore.personaldetails.PANName = PANFirstName;
          PANlastName = "";
          if (PANMiddleName !== "") {
            GlobalStore.personaldetails.PANName =
              GlobalStore.personaldetails.PANName + " " + PANMiddleName;
          }
        } else if (
          PANFirstName === "" &&
          PANMiddleName === "" &&
          PANlastName === ""
        ) {
          GlobalStore.personaldetails.PANName = "";
        } else if (PANMiddleName === "") {
          GlobalStore.personaldetails.PANName =
            PANFirstName + " " + PANlastName;
          PANMiddleName = "";
        } else {
          GlobalStore.personaldetails.PANName =
            PANFirstName + " " + PANMiddleName + " " + PANlastName;
        }

        //Modify personal details based on this response.

        GlobalStore.personaldetails.firstname = PANFirstName;
        GlobalStore.personaldetails.lastname = PANlastName;
        GlobalStore.personaldetails.middlename = PANMiddleName;
        GlobalStore.personaldetails.panDetails = res.data;

        let responseObj = res.data.AadharSeedingStatus
          ? res.data.AadharSeedingStatus
          : "";

        // if (responseObj.includes("Aadhaar not Seeded")) {
        console.log(responseObj, " RESP }}");
        setIsLoading(true);
        if (res.data.IsAadharSeeded == "N") {
          GlobalStore.isSeeding = false;
          isPanLinkedToAadhar = false;
          setSeedingPopup(!isPanLinkedToAadhar);
          setIsLoading(false);
          ACheckPanIbeats(checkedPanInIbeats, values.pan);
        }
        // else if (responseObj.includes("Aadhaar Seeding is Successful")) {
        else if (
          res.data.IsAadharSeeded == "Y" ||
          res.data.IsAadharSeeded == "NA"
        ) {
          GlobalStore.isSeeding = true;
          isPanLinkedToAadhar = true;
          setSeedingPopup(!isPanLinkedToAadhar);
          ACheckPanIbeats(checkedPanInIbeats, values.pan);
        }
        // ACheckPanIbeats(checkedPanInIbeats, values.pan);
        //-------- After Pan validation Navigate to DigiLocker Page -------

        //Dudupe Logic here//
        // AGetClientDetailsByPan(searchByPan, values.pan);
      } else if (
        res &&
        res.data &&
        res.data.PanNo &&
        res.data.IsValid == false
      ) {
        // setErrCode('Not in ITD Database. Please enter valid PAN.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + (res.error || ""));
        }
        //        setErrCode(errCode + (res.error || ""));
        setIsLoading(false);

        //CleverTap log start
        // var data = {
        //     CurrentPage: $location.path(),
        //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
        //     EventName: 'pan_dob_failure',
        //     Error: 'Not in ITD Database.Please enter valid PAN'
        // };
        // clevertap.event.push(data.EventName, data);
        //CleverTap log end
        // } else if (
        //     res &&
        //     res.data &&
        //     res.data.panDetails &&
        //     res.data.panDetails.indexOf('Not in ITD Database') === -1
        // ) {
        //     // setErrCode('Not in ITD Database.Please enter valid PAN.');
        //     setErrCode(errorMessage.clientDetailsByPan);
        //     setIsLoading(false);
        //CleverTap log start
        // var data = {
        //     CurrentPage: $location.path(),
        //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
        //     EventName: 'pan_dob_failure',
        //     Error: 'Not in ITD Database.Please enter valid PAN'
        // };
        // clevertap.event.push(data.EventName, data);
        //CleverTap log end
      } else {
        // setErrCode('PAN UTI server down');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + (res.error || ""));
        }
        //setErrCode(errCode + (res.error || ""));
        setIsLoading(false);
      }
    }
  }
  // function validatePAN(res: any) {
  //   if (!res || res.error) {
  //     // setErrCode('107  - Error in fetching Pan details from PAN UTI system.');
  //     // setErrCode(errorMessage.validatePAN);
  //     setErrCode(res.error);
  //     console.log(res.errMsg);
  //     setIsLoading(false);
  //   } else {
  //     if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails?.AadharSeedingStatus.indexOf(
  //         "Existing and Valid"
  //       ) !== -1
  //     ) {
  //       //CleverTap log Start
  //       // var data = {
  //       //     CurrentPage: $location.path(),
  //       //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
  //       //     EventName: 'pan_dob_success',
  //       //     CTA: 'Continue'
  //       // };
  //       // clevertap.event.push(data.EventName, data);
  //       //CleverTap log End

  //       let PANFirstName = res.data.panDetails.Firstname
  //         ? res.data.panDetails.Firstname
  //         : "";
  //       let PANMiddleName = res.data.panDetails.Middlename
  //         ? res.data.panDetails.Middlename
  //         : "";
  //       let PANlastName = res.data.panDetails.Lastname
  //         ? res.data.panDetails.Lastname
  //         : "";

  //       if (PANFirstName === "null" && PANlastName !== "null") {
  //         PANFirstName = PANlastName;
  //         GlobalStore.personaldetails.PANName = PANFirstName;
  //         PANlastName = "";
  //         if (PANMiddleName !== "null") {
  //           GlobalStore.personaldetails.PANName =
  //             GlobalStore.personaldetails.PANName + " " + PANMiddleName;
  //         }
  //       } else if (
  //         PANFirstName === "null" &&
  //         PANMiddleName === "null" &&
  //         PANlastName === "null"
  //       ) {
  //         GlobalStore.personaldetails.PANName = "";
  //       } else if (PANMiddleName === "null") {
  //         GlobalStore.personaldetails.PANName =
  //           PANFirstName + " " + PANlastName;
  //         PANMiddleName = "";
  //       } else {
  //         GlobalStore.personaldetails.PANName =
  //           PANFirstName + " " + PANMiddleName + " " + PANlastName;
  //       }

  //       //Modify personal details based on this response.

  //       GlobalStore.personaldetails.firstname = PANFirstName;
  //       GlobalStore.personaldetails.lastname = PANlastName;
  //       GlobalStore.personaldetails.middlename = PANMiddleName;
  //       GlobalStore.personaldetails.panDetails = res.data.panDetails;

  //       let responseObj = res.data.panDetails;

  //       if (responseObj?.AadharSeedingStatus.includes("Aadhaar not Seeded")) {
  //         GlobalStore.isSeeding = false;
  //       } else if (
  //         responseObj?.AadharSeedingStatus.includes(
  //           "Aadhaar Seeding is Successful"
  //         )
  //       ) {
  //         GlobalStore.isSeeding = true;
  //       }

  //       //-------- After Pan validation Navigate to DigiLocker Page-------

  //       //Dudupe Logic here//
  //       AGetClientDetailsByPan(searchByPan, values.pan);
  //     } else if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails?.AadharSeedingStatus.indexOf("Invalid PAN") === -1
  //     ) {
  //       // setErrCode('Not in ITD Database. Please enter valid PAN.');
  //       setErrCode(errorMessage.newPanUtiError);

  //       setIsLoading(false);
  //       //CleverTap log start
  //       // var data = {
  //       //     CurrentPage: $location.path(),
  //       //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
  //       //     EventName: 'pan_dob_failure',
  //       //     Error: 'Not in ITD Database.Please enter valid PAN'
  //       // };
  //       // clevertap.event.push(data.EventName, data);
  //       //CleverTap log end
  //     } else if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails?.AadharSeedingStatus.indexOf(
  //         "Not in ITD Database"
  //       ) === -1
  //     ) {
  //       // setErrCode('Not in ITD Database.Please enter valid PAN.');
  //       setErrCode(errorMessage.newPanUtiError);
  //       setIsLoading(false);
  //       //CleverTap log start
  //       // var data = {
  //       //     CurrentPage: $location.path(),
  //       //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
  //       //     EventName: 'pan_dob_failure',
  //       //     Error: 'Not in ITD Database.Please enter valid PAN'
  //       // };
  //       // clevertap.event.push(data.EventName, data);
  //       //CleverTap log end
  //     } else {
  //       // setErrCode('PAN UTI server down');
  //       setErrCode(errorMessage.newPanUtiError);
  //       setIsLoading(false);
  //     }
  //   }
  // }
  // function validatePAN(res: any) {
  //   console.log("validatePAN", res);
  //   if (!res || res.error) {
  //     // setErrCode('107  - Error in fetching Pan details from PAN UTI system.');
  //     setErrCode(errorMessage.validatePAN);
  //     setIsLoading(false);
  //   } else {
  //     if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails.indexOf("Existing and Valid") !== -1
  //     ) {
  //       //CleverTap log Start
  //       // var data = {
  //       //     CurrentPage: $location.path(),
  //       //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
  //       //     EventName: 'pan_dob_success',
  //       //     CTA: 'Continue'
  //       // };
  //       // clevertap.event.push(data.EventName, data);
  //       //CleverTap log End

  //       let PANFirstName =
  //         res.data.panDetails.split("^")[1].trim() !== null &&
  //         res.data.panDetails.split("^")[1].trim() !== ""
  //           ? res.data.panDetails.split("^")[1].trim()
  //           : "";
  //       let PANMiddleName =
  //         res.data.panDetails.split("^")[2].trim() !== null &&
  //         res.data.panDetails.split("^")[2].trim() !== ""
  //           ? res.data.panDetails.split("^")[2].trim()
  //           : "";
  //       let PANlastName =
  //         res.data.panDetails.split("^")[3].trim() !== null &&
  //         res.data.panDetails.split("^")[3].trim() !== ""
  //           ? res.data.panDetails.split("^")[3].trim()
  //           : "";

  //       if (PANFirstName === "null" && PANlastName !== "null") {
  //         PANFirstName = PANlastName;
  //         GlobalStore.personaldetails.PANName = PANFirstName;
  //         PANlastName = "";
  //         if (PANMiddleName !== "null") {
  //           GlobalStore.personaldetails.PANName =
  //             GlobalStore.personaldetails.PANName + " " + PANMiddleName;
  //         }
  //       } else if (
  //         PANFirstName === "null" &&
  //         PANMiddleName === "null" &&
  //         PANlastName === "null"
  //       ) {
  //         GlobalStore.personaldetails.PANName = "";
  //       } else if (PANMiddleName === "null") {
  //         GlobalStore.personaldetails.PANName =
  //           PANFirstName + " " + PANlastName;
  //         PANMiddleName = "";
  //       } else {
  //         GlobalStore.personaldetails.PANName =
  //           PANFirstName + " " + PANMiddleName + " " + PANlastName;
  //       }

  //       //Modify personal details based on this response.

  //       GlobalStore.personaldetails.firstname = PANFirstName;
  //       GlobalStore.personaldetails.lastname = PANlastName;
  //       GlobalStore.personaldetails.middlename = PANMiddleName;
  //       GlobalStore.personaldetails.panDetails = res.data.panDetails;

  //       let responseObj = JSON.parse(res.data.panDetails);

  //       if (responseObj.includes("Aadhaar not Seeded")) {
  //         GlobalStore.isSeeding = false;
  //       } else if (responseObj.includes("Aadhaar Seeding is Successful")) {
  //         GlobalStore.isSeeding = true;
  //       }

  //       //-------- After Pan validation Navigate to DigiLocker Page-------

  //       //Dudupe Logic here//
  //       AGetClientDetailsByPan(searchByPan, values.pan);
  //     } else if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails.indexOf("Invalid PAN") === -1
  //     ) {
  //       // setErrCode('Not in ITD Database. Please enter valid PAN.');
  //       setErrCode(errorMessage.clientDetailsByPan);

  //       setIsLoading(false);
  //       //CleverTap log start
  //       // var data = {
  //       //     CurrentPage: $location.path(),
  //       //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
  //       //     EventName: 'pan_dob_failure',
  //       //     Error: 'Not in ITD Database.Please enter valid PAN'
  //       // };
  //       // clevertap.event.push(data.EventName, data);
  //       //CleverTap log end
  //     } else if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails.indexOf("Not in ITD Database") === -1
  //     ) {
  //       // setErrCode('Not in ITD Database.Please enter valid PAN.');
  //       setErrCode(errorMessage.clientDetailsByPan);
  //       setIsLoading(false);
  //       //CleverTap log start
  //       // var data = {
  //       //     CurrentPage: $location.path(),
  //       //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
  //       //     EventName: 'pan_dob_failure',
  //       //     Error: 'Not in ITD Database.Please enter valid PAN'
  //       // };
  //       // clevertap.event.push(data.EventName, data);
  //       //CleverTap log end
  //     } else {
  //       // setErrCode('PAN UTI server down');
  //       setErrCode(errorMessage.clientDetailsByPanServerDown);
  //       setIsLoading(false);
  //     }
  //   }
  // }

  function searchByPan(res: any) {
    if (!res || res.error) {
      // setErrCode('118 - Error in fetching Client details from our system.');
      // setErrCode(errorMessage.searchByPan);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({
        action: "GetClientDataByPanOrLead",
        label: "ERROR || ERR102",
      });
      setIsLoading(false);
    } else {
      if (res.data) {
        if (res.data && res.data != "Records Not Found") {
          GlobalStore.personaldetails.pan = res.data.PAN;
          GlobalStore.token = res.data.Token;
          GlobalStore.applicationID = res.data.ApplicatoinId;
          GlobalStore.leadId = res.data.LeadId;
          GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;

          // GlobalStore.personaldetails.pan = res.data.data.pan;
          // GlobalStore.token = res.data.data.token;
          // GlobalStore.applicationID = res.data.data.applicatoinId;
          // GlobalStore.leadId = res.data.data.leadId;
          // GlobalStore.clientPrimaryDetailId =
          //   res.data.data.clientprimarydetailid;
          // console.log("GlobalStore====> ", GlobalStore);

          APendingUploadList(checkPendingUpload, GlobalStore.applicationID);
        } else if (res.data == "Records Not Found") {
          AGetClientDetailsByLeadId(searchByLeadId, GlobalStore.leadId);
        }
      } else {
        // setErrCode('1182 - Error in fetching Client details from our system.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setIsLoading(false);
      }
    }
  }

  function searchByLeadId(res: any) {
    if (!res || res.error) {
      // setErrCode('119 - Error in fetching Client details from our system.');
      // setErrCode(errorMessage.searchByLeadId);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({
        action: "GetClientDataByPanOrLead",
        label: "ERROR || ERR103",
      });
      setIsLoading(false);
    } else {
      console.log("UCC Check 1");
      if (res.data && res.data != null) {
        if (
          GlobalStore.personaldetails.pan.toUpperCase().trim() ===
          res.data.PAN.toUpperCase().trim()
        ) {
          GlobalStore.token = res.data.Token;
          GlobalStore.applicationID = res.data.ApplicatoinId;
          GlobalStore.personaldetails.pan = res.data.PAN;
          GlobalStore.leadId = res.data.LeadId;
          GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;
          APendingUploadList(checkPendingUpload, GlobalStore.applicationID);
        } else {
          console.log("DUP block");
          // create Lead without dudupe
          // GlobalStore.createLeadDudupeParam.byPassDedupe = true;
          // ACreateLead(leadCreated, GlobalStore.createLeadDudupeParam);
          ACreateLeadSource(leadCreated, GlobalStore.createLeadDudupeParam);
          // savePanDob();
        }
      } else if (res.data == null) {
        console.log("UCC Check 2");
        ACheckUCCExists(
          CheckUCCExists,
          values.pan,
          GlobalStore.user.mobile,
          GlobalStore.user.email,
          GlobalStore.leadId
        );
        //savePanDob();
      } else {
        // setErrCode('1192 - Error in fetching Client details from our system.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setIsLoading(false);
      }
    }
  }

  function leadCreated(res: any) {
    if (!res || res.error) {
      // setErrCode('103 - Error in generating new Lead.');
      // setErrCode(errorMessage.leadCreated);
      setErrCode(res.error);
      gtmService({ action: "CreateLead", label: "ERROR || ERR111" });
      setIsLoading(false);
    } else {
      if (res && res.data) {
        const response = JSON.parse(res.data);
        if (response && response.LeadId) {
          GlobalStore.leadId = response.LeadId;
          savePanDob();
        }
      } else {
        // setErrCode('104 - Error in generating new Lead.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setIsLoading(false);
      }
    }
  }

  function savePanDob(applicationIdExists?: boolean) {
    console.log("panlink log", isPanLinkedToAadhar);
    if (
      applicationIdExists &&
      (GlobalStore.stageStatus.ispersonaldetailscompleted ||
        GlobalStore.stageStatus.isprofiledetailscompleted)
    ) {
      const clientPartialDetails_ao = JSON.parse(
        window.localStorage.getItem("clientPartialDetails_ao") || "{}"
      );

      clientPartialDetails_ao.PANName = GlobalStore.personaldetails.PANName;
      clientPartialDetails_ao.aadharLinkedChoice =
        GlobalStore.aadharLinkedChoice;
      clientPartialDetails_ao.applicationId = GlobalStore.applicationID;
      clientPartialDetails_ao.birthDate = GlobalStore.birthDate;
      clientPartialDetails_ao.ckycNo = GlobalStore.stageStatus.ckycnumber;
      clientPartialDetails_ao.clientPrimaryDetailId =
        GlobalStore.clientPrimaryDetailId;
      clientPartialDetails_ao.dob = GlobalStore.dob;
      clientPartialDetails_ao.emailID = GlobalStore.user.email;
      clientPartialDetails_ao.isDigiLocker = GlobalStore.isDigiLocker;
      clientPartialDetails_ao.isKRA = GlobalStore.stageStatus.iskra;
      clientPartialDetails_ao.isKYC = GlobalStore.stageStatus.iskyc;

      clientPartialDetails_ao.isSeniorCitizen =
        window.sessionStorage.getItem("isSeniorCitizen") === "true"
          ? true
          : false;
      clientPartialDetails_ao.leadID = GlobalStore.leadId;
      clientPartialDetails_ao.mobile = GlobalStore.user.mobile;
      clientPartialDetails_ao.pan = values.pan;
      clientPartialDetails_ao.panDetails =
        GlobalStore.personaldetails.panDetails;
      clientPartialDetails_ao.token = GlobalStore.token;
      clientPartialDetails_ao.isSeeding = GlobalStore.isSeeding;

      window.localStorage.setItem(
        "clientPartialDetails_ao",
        JSON.stringify(clientPartialDetails_ao)
      );
      registerCleverTapEventAfterProceed();
      gtmService({ action: "PAN_DOB", label: "Step_Complete" });
      navigate(applicationIdExists);
    } else {
      GlobalStore.dateofbirth = moment(values.dob).format("MM/DD/YYYY");
      GlobalStore.dob = moment(values.dob).format("DD/MM/YYYY");
      GlobalStore.birthDate = moment(values.dob).format("MM/DD/YYYY");

      if (isPanLinkedToAadhar == true) {
        AGetCkycNumber(
          (res: any) => getCkycNumber(res, applicationIdExists),
          values.pan
        );
      } else {
        GlobalStore.isKYC = false;
        GlobalStore.isKRA = false;
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        clientPartialDetails_ao.isKRA = GlobalStore.isKRA;
        clientPartialDetails_ao.isKYC = GlobalStore.isKYC;
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        dobMatched({});
      }
    }
  }

  function getCkycNumber(res: any, applicationIdExists?: boolean) {
    if (!res || res.error) {
      // setErrCode('1223 - Error in fetching CKYC Number.');
      // setErrCode(errorMessage.getCkycNumber);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "GetCkycNumber", label: "ERROR || ERR107" });
      navigate(applicationIdExists);
    } else {
      if (res.data) {
        // const responseObj = JSON.parse(res.data);
        const reqObj = {
          dbo: `${moment(values.dob).format("DD/MM/YYYY")}`,
          panno: values.pan,
          applicationId: GlobalStore.applicationID,
        };

        AGetKraDetails(
          (res: any) => kraDobCheck(res, applicationIdExists),
          reqObj
        );
        // if (responseObj?.CKYC_NO !== "") {
        //   // const reqObj = {
        //   //   applicationid: values.pan,
        //   //   dob: `${moment(values.dob).format("DD-MM-YYYY")}`,
        //   //   panno: values.pan,
        //   // };
        //   // AGetCkycDetails(
        //   //   (res: any) => getCkycDetails(res, applicationIdExists),
        //   //   reqObj
        //   // );
        //   const reqObj = {
        //     dbo: `${moment(values.dob).format("DD/MM/YYYY")}`,
        //     panno: values.pan,
        //     applicationId: GlobalStore.applicationID,
        //   };
        //   AGetKraDetails(
        //     (res: any) => kraDobCheck(res, applicationIdExists),
        //     reqObj
        //   );
        // } else {
        //   const reqObj = {
        //     dbo: `${moment(values.dob).format("DD/MM/YYYY")}`,
        //     panno: values.pan,
        //     applicationId: GlobalStore.applicationID,
        //   };
        //   AGetKraDetails(
        //     (res: any) => kraDobCheck(res, applicationIdExists),
        //     reqObj
        //   );
        //   // dobMatched(res.data, applicationIdExists);  //By passing DoB check
        // }
      } else {
        // setErrCode('1224 - Error in fetching CKYC Number.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        navigate(applicationIdExists);
      }
    }
  }

  // function getCkycDetails(res: any, applicationIdExists?: boolean) {
  //   if (!res || res.error) {

  //     setErrCode(res.error);
  //     setIsLoading(false);
  //     gtmService({ action: "GetCKYCDetails", label: "ERROR || ERR110" });
  //     navigate(applicationIdExists);
  //   } else {
  //     if (res.data && res.data.PersonalDetails) {
  //       dobMatched(res.data, applicationIdExists);
  //     } else {
  //       setIsLoading(false);
  //       alert("Date of Birth is not matching with the PAN.");
  //       GlobalStore.isKYC = false;
  //       const clientPartialDetails_ao = JSON.parse(
  //         window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  //       );
  //       clientPartialDetails_ao.isKYC = GlobalStore.isKYC;
  //       window.localStorage.setItem(
  //         "clientPartialDetails_ao",
  //         JSON.stringify(clientPartialDetails_ao)
  //       );
  //       dobMatched(res.data, applicationIdExists);
  //     }
  //   }
  // }

  const kraDobCheck = (res: any, applicationIdExists?: boolean) => {
    if (!res || res.error) {
      // setErrCode(1227);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "GetKraDetails", label: "ERROR || ERR108" });
      navigate(applicationIdExists);
    } else {
      const responseObj = res.data;
      if (responseObj.AppErrorDesc?.includes("INVALID DOB")) {
        setIsLoading(false);

        // setErrCode('Date of Birth is not matching with the PAN');
        setErrCode(errCode ? errCode : "" + (res.error || ""));
        setErrors({ ...errors, dob: errorMsg(errorCode.FE11) });
      } else {
        dobMatched(responseObj, applicationIdExists);
      }
    }
  };

  function dobMatched(kycobj: any, applicationIdExists?: boolean) {
    const objUTM_Info = utmDurationInfoObj(startTime, "registrationdur");
    let objAccType = window.sessionStorage.getItem("objAccType") || ""; // only demat getting parameter type
    const panDobObj = {
      leadId: GlobalStore.leadId,
      emailID: GlobalStore.user.email,
      mobileno: GlobalStore.user.mobile,
      isemployeepan: isEmployee,
      panNo: values.pan,
      dob: GlobalStore.dateofbirth,
      // static data need to remove later
      aadhaar: values.aadhaarNo,
      ispanaadhaarlinked: isPanLinkedToAadhar,
      relationship: false,
      relationName: "",
      others: "",
      objUTM_Info: objUTM_Info,
      response: "",
      TransactionId: "",
      kycverified: "",
      product: objAccType === "DMT" ? "D" : "TD",
      platform: 1,
      panFName: values?.firstName,
      panMName: values?.middleName,
      panLName: values?.lastName,
      SendSelfieLink: togPhoto, //((photoObj.base64==null || photoObj.base64=="") && togPhoto==false) ? true:
      diyReferralLink: localURL,
    };
    console.log("called here ===> 2");
    ASavePanDobDetails(
      (res: any) => applicationInitialized(res, kycobj, applicationIdExists),
      panDobObj
    );
  }

  function applicationInitialized(
    res: any,
    kycobj: any,
    applicationIdExists?: boolean
  ) {
    console.log("function applicationInitialized : ", kycobj, "||", res);
    if (!res || res.error) {
      console.log("applicationInitialized 00");
      // setErrCode('121 - Error in Application initialization.');
      // setErrCode(errorMessage.applicationInitialized);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "SavePanDobDetails", label: "ERROR || ERR106" });
      setIsLoading(false);
    } else {
      console.log("applicationInitialized 11");
      if (res.data && res.data.LeadId) {
        GlobalStore.leadId = res.data.LeadId;
        GlobalStore.token = res.data.Token;
        GlobalStore.applicationID = res.data.ApplicationID;
        GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;
        GlobalStore.aadharLinkedChoice =
          values.aadharLinked === "Yes" ? true : false;
        GlobalStore.personaldetails.pan = values.pan;
        //  GlobalStore.personaldetails.aadharNo = values.aadhaarNo.slice(-4);
        // Create link for capturing selfie from mobile
        const encodedPan = btoa(`${values.pan}`);
        const stringParams = `selfie?id=${encodedPan}`;
        const selfieUrl = `${config.root}${stringParams}`;
        GlobalStore.selfieUrl = selfieUrl;
        const clientObj = {
          clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId,
          applicationId: GlobalStore.applicationID,
        };
        window.sessionStorage.setItem(
          "clientDetails",
          JSON.stringify(clientObj)
        );

        //extract pan
        console.log("applicationInitialized 22", GlobalStore);
        if (!willDoLater) {
          let panOCRval = panOCR;
          panOCRval.PayloadObj.applicationid = GlobalStore.applicationID;
          setPanOCR(panOCRval);
          uploadPanFile(panOCR.val, panOCR.fileName, panOCR.id, panOCR.type);
          // AextractPan(
          //   (res: any) =>
          //     storeOcrData(
          //       res,
          //       panOCR.val,
          //       panOCR.fileName,
          //       panOCR.id,
          //       panOCR.type
          //     ),
          //   panOCR.PayloadObj
          // );
        }

        const clientPartialDetails_ao = {
          clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId,
          token: GlobalStore.token,
          applicationId: GlobalStore.applicationID,
          dob: GlobalStore.dob,
          birthDate: GlobalStore.birthDate,
          pan: values.pan,
          aadharLinkedChoice: GlobalStore.aadharLinkedChoice,
          leadID: GlobalStore.leadId,
          emailID: GlobalStore.user.email,
          mobile: GlobalStore.user.mobile,
          panDetails: GlobalStore.personaldetails.panDetails,
          PANName: GlobalStore.personaldetails.PANName,
          isSeniorCitizen:
            window.sessionStorage.getItem("isSeniorCitizen") === "true"
              ? true
              : false,
          isDigiLocker: GlobalStore.isDigiLocker,
          isKRA: false,
          isKYC: false,
          isSeeding: GlobalStore.isSeeding,
          selfieUrl: GlobalStore.selfieUrl,
          isManual: false,
          aadharNo: GlobalStore.personaldetails.aadharNo,
        };
        window.localStorage.setItem(
          "aadharNumber",
          GlobalStore.personaldetails.aadharNo
        );
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );

        nextprocess(kycobj);
        // updateUtmInfo(() => {}); // here updating utmcreative and content.
        // //commented due to whatsapp diable requirements on 20-1-23
        // // AWhatsappCom(whatsappCom, whatsappReq);

        // // Send link for capturing selfie from mobile
        // if (togPhoto) {
        //   debugger;
        //   const smsBody = {
        //     mobNo: GlobalStore.user.mobile,
        //     // selfieLinkText: `Give a Smile and complete your Selfie process for Online Account Opening, click here ${selfieUrl} to complete the process. Regards, Nuvama`,
        //     selfieLinkText: selfieUrl,
        //   };
        //   ASendSms((res: any) => {
        //     if (!res || res.error) {
        //       setErrCode(errCode + res.error);
        //     } else {
        //       if (res && res.data) {
        //       }
        //     }
        //   }, smsBody);
        // }

        // // Storing CKYC details & Documents begin here
        // // if (kycobj.PersonalDetails) {
        // //   // AGetCkycNumber((res: any) => getCkycNumber(res, applicationIdExists), values.pan);
        // //   const kycDetails = kycobj.PersonalDetails;

        // //   GlobalStore.ckycNo = kycDetails.CkycNumber;
        // //   GlobalStore.isKYC = true;
        // //   GlobalStore.personaldetails.fsfullName = `${kycDetails.FatherFName} ${kycDetails.FatherMName} ${kycDetails.FatherLName}`;
        // //   GlobalStore.personaldetails.motherfullName = `${kycDetails.MotherFName} ${kycDetails.MotherMName} ${kycDetails.MotherLName}`;
        // //   if (GlobalStore.personaldetails.fsfullName.trim() === "")
        // //     GlobalStore.personaldetails.fsfullName = "";
        // //   if (GlobalStore.personaldetails.motherfullName.trim() === "")
        // //     GlobalStore.personaldetails.motherfullName = "";
        // //   GlobalStore.ckycInfoObject.addressLine1 =
        // //     `${kycDetails.PermLine1}` +
        // //     " " +
        // //     `${kycDetails.PermLine2 || ""}` +
        // //     " " +
        // //     `${kycDetails.PermLine3 || ""}`;
        // //   GlobalStore.ckycInfoObject.sameAsPermanent =
        // //     kycDetails.PermanentSameAsCorrespondaceFlag === "N" ? false : true;
        // //   GlobalStore.ckycInfoObject.corrAddress =
        // //     `${kycDetails.CorresLine1}` +
        // //     " " +
        // //     `${kycDetails.CorresLine2 || ""}` +
        // //     " " +
        // //     `${kycDetails.CorresLine3 || ""}`;
        // //   GlobalStore.ckycInfoObject.pincode = kycDetails.PermPin;
        // //   GlobalStore.ckycInfoObject.corrPincode = kycDetails.CorresPin;
        // //   GlobalStore.personaldetails.gender = kycDetails.Gender;
        // //   const clientPartialDetails_ao = JSON.parse(
        // //     window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        // //   );
        // //   clientPartialDetails_ao.ckycNo = GlobalStore.ckycNo;
        // //   clientPartialDetails_ao.isKYC = GlobalStore.isKYC;
        // //   clientPartialDetails_ao.fsfullName =
        // //     GlobalStore.personaldetails.fsfullName;
        // //   clientPartialDetails_ao.motherfullName =
        // //     GlobalStore.personaldetails.motherfullName;
        // //   clientPartialDetails_ao.addressLine1 =
        // //     GlobalStore.ckycInfoObject.addressLine1;
        // //   clientPartialDetails_ao.sameAsPermanent =
        // //     GlobalStore.ckycInfoObject.sameAsPermanent;
        // //   clientPartialDetails_ao.corrAddress =
        // //     GlobalStore.ckycInfoObject.corrAddress;
        // //   clientPartialDetails_ao.pincode = GlobalStore.ckycInfoObject.pincode;
        // //   clientPartialDetails_ao.corrPincode =
        // //     GlobalStore.ckycInfoObject.corrPincode;
        // //   window.localStorage.setItem(
        // //     "clientPartialDetails_ao",
        // //     JSON.stringify(clientPartialDetails_ao)
        // //   );
        // //   // If no images from CKYC
        // // }
        // if (isPanLinkedToAadhar == true) {
        //   // const reqObj = {
        //   //   applicationid: GlobalStore.applicationID,
        //   //   dbo: `${moment(values.dob).format("DD/MM/YYYY")}`,
        //   //   panno: values.pan,
        //   // };
        //   // AGetKraDetails(
        //   //   (res: any) => getKraDetails(res, applicationIdExists),
        //   //   reqObj
        //   // );
        //   getKraDetails(kycobj);
        // } else {
        //   setIsNormalClient(true);
        //   GlobalStore.isNormalClient = true;
        //   const clientPartialDetails_ao = JSON.parse(
        //     window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        //   );
        //   clientPartialDetails_ao.isNormalClient = GlobalStore.isNormalClient;
        //   window.localStorage.setItem(
        //     "clientPartialDetails_ao",
        //     JSON.stringify(clientPartialDetails_ao)
        //   );
        //   setIsLoading(false);
        // }
      } else {
        // setErrCode('120 - Error in Application initialization.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setIsLoading(false);
      }
    }
    //setApplicationInitializedFlag(true);
  }

  React.useEffect(() => {
    if (applicationInitializedFlag && applicationModeType === "isDigiLocker") {
      setShowSubmitModal(true);
    }
  }, [applicationInitializedFlag, applicationModeType]);

  function checkPendingUpload(res: any) {
    if (!res || res.error) {
      // setErrCode('1221 - Error in fetching pending document upload list.');
      //   setErrCode(errorMessage.checkPendingUpload);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "GetDocumentDetails", label: "ERROR || ERR112" });
      setIsLoading(false);
    } else {
      if (res && res.data) {
        const list: Array<any> = JSON.parse(res.data).filter(
          (item: any) =>
            // Prev api response
            // item.documentcategoryid !== 36 &&
            // item.documentcategoryid !== 30 &&
            // item.documentcategoryid !== 62

            // Current Api response
            item.Mandatory == true
        );
        let isUploadDocumentsCompleted: boolean = false;
        if (list.length === 0 || list[0].count === 0)
          isUploadDocumentsCompleted = true;
        const redisReqObj = {
          applicationId: GlobalStore.applicationID,
          clientPrimaryDetailsId: GlobalStore.clientPrimaryDetailId,
        };
        // ARedisDigilockerDoc(
        //   (res: any) => getRedisData(res, isUploadDocumentsCompleted),
        //   redisReqObj
        // );
        AGetStageCompletionStatus(
          (res: any) => getClientStageDetails(res, isUploadDocumentsCompleted),
          GlobalStore.applicationID
        );
      } else {
        // setErrCode('1222 - Error in fetching pending document upload list.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setIsLoading(false);
      }
    }
  }

  function getRedisData(res: any, isUploadDocumentsCompleted: boolean) {
    if (!res || res.error) {
      // setErrCode('66341- Error in fetching Digilocker document.');
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({
        action: "SavePersonalProfileDetails",
        label: "ERROR || ERR114",
      });
      setIsLoading(false);
    } else {
      if (res.data && res.data.coresAddress) {
        GlobalStore.isDigiLocker = true;
        console.log("setApplicationModeType");
        setApplicationModeType("isDigiLocker");
      } else {
        GlobalStore.isDigiLocker = false;
      }
      AGetStageCompletionStatus(
        (res: any) => getClientStageDetails(res, isUploadDocumentsCompleted),
        GlobalStore.applicationID
      );
    }
  }

  function getClientStageDetails(
    res: any,
    isUploadDocumentsCompleted: boolean
  ) {
    if (!res || res.error) {
      // setErrCode('1061 - Error in fetching stage completion details.');
      // setErrCode(errorMessage.getClientStageDetails);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({
        action: "GetStageCompletionStatus",
        label: "ERROR || ERR113",
      });
      setIsLoading(false);
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        //set stage api data to GlobalStore
        setStageData(responseObj);
        //check completeness of stages
        checkModuleCompleteness(isUploadDocumentsCompleted);
        setCheckpanaaddharflag(responseObj.checkpanaaddharflag);

        if (responseObj.isdigilockercompleted) {
          GlobalStore.isDigiLocker = true;
          console.log("setApplicationModeType 2");
          setApplicationModeType("isDigiLocker");
        }
        if (responseObj.iskra) {
          GlobalStore.isKRA = true;
          console.log("setApplicationModeType 3");
          setApplicationModeType("isKRA");
        }
        if (responseObj.iskyc) GlobalStore.isKYC = true;
        //route according to stage api response
        // if (
        //     !responseObj.isdigilockercompleted &&
        //     values.aadharLinked === 'Yes' &&
        //     !GlobalStore.isDigiLocker &&
        //     !responseObj.ispersonaldetailscompleted
        // )
        //     GlobalStore.nextIncompleteStage = containerUrl.dgLocker;
        if (
          !(
            responseObj.isprofiledetailscompleted &&
            responseObj.ispersonaldetailscompleted
          )
        )
          GlobalStore.nextIncompleteStage = dashboardUrl.profile;
        else if (!responseObj.isbankdetailscompleted) {
          GlobalStore.nextIncompleteStage = dashboardUrl.bank;
        } else if (
          !responseObj.isbrokeragesegmentcompleted ||
          !responseObj.isbrokerageschemecompleted
        )
          GlobalStore.nextIncompleteStage = dashboardUrl.plan;
        else if (!isUploadDocumentsCompleted)
          GlobalStore.nextIncompleteStage = dashboardUrl.doc;
        else if (
          !GlobalStore.isDigiLocker &&
          !GlobalStore.isKRA &&
          !GlobalStore.isKYC &&
          !responseObj.isipvarificationcompleted
        )
          GlobalStore.nextIncompleteStage = dashboardUrl.ipv;
        else if (responseObj.isagentvisitcompleted)
          GlobalStore.nextIncompleteStage = containerUrl.ocv;
        else if (!responseObj.isesigncompleted)
          GlobalStore.nextIncompleteStage = dashboardUrl.eSign;
        else if (responseObj.bpm_pd_status === 0)
          history.push(statusUrl.docVerificationInProgress);
        else if (responseObj.bpm_pd_status === 1)
          history.push(containerUrl.poa);

        savePanDob(true);
      } else {
        // setErrCode('1062 - Error in fetching stage completion details.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setIsLoading(false);
      }
    }
  }

  function whatsappCom(res: any) {
    if (!res || res.error) {
      // setErrCode('1241  - Failed to provide consent for WhatsApp Notifications.');
      // setErrCode(errorMessage.whatsAppNotify);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({
        action: "WhatsUpAppInitiateCommunication",
        label: "ERROR || ERR104",
      });
    } else {
      if (res.data) {
      } else {
        // setErrCode('1242 - Failed to provide consent for WhatsApp Notifications.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
      }
    }
  }

  const maskedAadhar = (
    res: any,
    savePermAddressObj: any,
    aadharOCR: any,
    applicationIdExists?: boolean
  ) => {
    if (!res || res.error) {
      // setErrCode('Error in masking Aadhar number of the uploaded file.');
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR114-4" });
      // setErrCode(res.error);
    } else if (res.data) {
      if (res.data.result.id_number_found) {
        // Masking API returns only JPG images, even if input is PDF.
        aadharOCR.base64document = res.data.result.base64_image;
        savePermAddressObj.mimeType = "image/jpeg";
        savePermAddressObj.fileName = permAddressObj.fileName + ".jpg";
      }
      AextractAadhaar(
        (res: any) =>
          storeAadharData(
            res,
            savePermAddressObj,
            aadharOCR,
            applicationIdExists
          ),
        aadharOCR
      );
    }
  };

  const storeAadharData = (
    res: any,
    savePermAddressObj: any,
    aadharOCR: any,
    applicationIdExists?: boolean
  ) => {
    if (!res || res.error) {
      // setErrCode('Error in reading Aadhar details of the uploaded file.');
      // setErrCode(errorMessage.storeAadharData);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR114-2" });
    } else if (res.data) {
      if (res.data.result)
        GlobalStore.aadhaarOCRObj = res.data.result.extraction_output;
      const addressUploadObj = {
        applicationid: GlobalStore.applicationID,
        base64document: aadharOCR.base64document,
        filename: savePermAddressObj.fileName,
      };
      AUploadDocument(
        (res: any) =>
          getUploadResponse(res, savePermAddressObj, applicationIdExists),
        addressUploadObj
      );
    }
  };

  const getSelfieUploadResponse = (
    res: any,
    uploadObj: saveDocumentObj,
    applicationIdExists?: boolean
  ) => {
    if (!res || res.error) {
      // setErrCode('1361- Error in uploading the file.');
      // setErrCode(errorMessage.getUploadResponse);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR114-7" });
      setIsLoading(false);
    } else {
      if (res.data && res.data.Result == true) {
        uploadObj.fileName = res.data.Guid;
      } else {
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
      }
    }
    const selfieDetails = [];
    selfieDetails.push(uploadObj);
    const saveobjUTM_Info = utmDurationInfoObj(startTime, "panInfoDuration");
    const objUTM_Info = utmDurationInfoObj(startTime, "registrationdur");
    let objAccType = window.sessionStorage.getItem("objAccType") || "";
    const saveObj = {
      applicationId: GlobalStore.applicationID,
      clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
      documentInfo: selfieDetails,
      leadId: GlobalStore.leadId,
      objUTM_Info: objUTM_Info,
    };
    ASaveDocumentDetails(
      () => documentDetailsSaved(res, applicationIdExists, uploadObj),
      saveObj
    );
  };

  const getUploadResponse = (
    res: any,
    uploadObj: saveDocumentObj,
    applicationIdExists?: boolean
  ) => {
    if (!res || res.error) {
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      // setErrCode("1361- Error in uploading the file.");
      gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR114-6" });
      setIsLoading(false);
    } else {
      if (res.data && res.data.Result == true) {
        uploadObj.fileName = res.data.Guid;
        docCount += 1;
      } else {
        // setErrCode('1361- Error in uploading the file.');
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
      }
    }
    saveDetails.push(uploadObj);
    const saveobjUTM_Info = utmDurationInfoObj(startTime, "panInfoDuration");
    const objUTM_Info = utmDurationInfoObj(startTime, "registrationdur");
    const saveObj = {
      applicationId: GlobalStore.applicationID,
      clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
      documentInfo: saveDetails,
      leadId: GlobalStore.leadId,
      objUTM_Info: objUTM_Info,
    };
    if (ckycDocs === docCount) {
      ASaveDocumentDetails(
        () => documentDetailsSaved(res, applicationIdExists),
        saveObj
      );
    }
  };
  const getUploadKraResponse = (
    res: any,
    uploadObj: saveDocumentObj,
    applicationIdExists?: boolean
  ) => {
    if (!res || res.error) {
      setErrCode(res.error);
      // setErrCode("1361- Error in uploading the file.");
      gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR114-6" });
      setIsLoading(false);
    } else {
      if (res.data && res.data.Result == true) {
        uploadObj.fileName = res.data.Guid;
        kraDocCount += 1;
      } else {
        // setErrCode('1361- Error in uploading the file.');
        setErrCode(errorMessage.getUploadResponse);
      }
    }
    saveKraDetails.push(uploadObj);
    const saveobjUTM_Info = utmDurationInfoObj(startTime, "panInfoDuration");
    const objUTM_Info = utmDurationInfoObj(startTime, "registrationdur");
    const saveObj = {
      applicationId: GlobalStore.applicationID,
      clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
      documentInfo: saveKraDetails,
      leadId: GlobalStore.leadId,
      objUTM_Info: objUTM_Info,
    };

    if (kraDocs === kraDocCount) {
      ASaveDocumentDetails(
        (res: any) =>
          kraDocumentDetailsSaved(res, applicationIdExists, uploadObj),
        saveObj
      );
    }
  };
  const kraDocumentDetailsSaved = (
    res: any,
    applicationIdExists?: boolean,
    uploadObj?: saveDocumentObj
  ) => {
    console.log("SaveUploadedDocumentDetails", kraDocs, kraDocCount);

    if (!res || res.error) {
      // setErrCode('1641- Error in saving document details of the application.');
      // setErrCode(errorMessage.documentDetailsSaved);
      setErrCode(res.error);
      gtmService({
        action: "SaveUploadedDocumentDetails",
        label: "ERROR || ERR114-5",
      });
      setIsLoading(false);
    } else {
      setDocumentInfo((prev) => {
        console.log(
          "prev.totalDocs",
          prev.totalDocs,
          uploadObj?.fileName,
          prev.totalDocs.filter((x) => x != uploadObj?.fileName)
        );
        return {
          ...prev,
          uploadedDocsCount: prev.uploadedDocsCount + 1,
          totalDocs: prev.totalDocs.filter((x) => x !== uploadObj?.fileName),
        };
      });
      setShowSubmitModal(true);
      // setIsLoading(false);
      // setShowSubmitModal(true);
    }
  };
  const documentDetailsSaved = (
    res: any,
    applicationIdExists?: boolean,
    uploadObj?: saveDocumentObj
  ) => {
    if (!res || res.error) {
      // setErrCode('1641- Error in saving document details of the application.');
      // setErrCode(errorMessage.documentDetailsSaved);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({
        action: "SaveUploadedDocumentDetails",
        label: "ERROR || ERR114-5",
      });
      setIsLoading(false);
    } else {
      const reqObj = {
        applicationid: GlobalStore.applicationID,
        createdby: 0,
        dbo: `${moment(values.dob).format("DD/MM/YYYY")}`,
        panno: values.pan,
      };
      setDocumentInfo((prev) => {
        console.log(
          "prev.totalDocs",
          prev.totalDocs,
          uploadObj?.fileName,
          prev.totalDocs.filter((x) => x !== uploadObj?.fileName)
        );
        return {
          ...prev,
          uploadedDocsCount: prev.uploadedDocsCount + 1,
          totalDocs: prev.totalDocs.filter((x) => x != uploadObj?.fileName),
        };
      });

      AGetKraDetails(
        (res: any) => getKraDetails(res, applicationIdExists),
        reqObj
      );
    }
  };
  function getKraDocumentUpload(kraRes: any, applicationIdExists?: boolean) {
    kraDocs = kraRes.ImageDetails.length;

    const images = kraRes?.ImageDetails?.map((image: any) => {
      switch (image.ImageCode) {
        case "31":
        case "00":
          const savePermAddressObj: saveDocumentObj = {
            documentCategoryId: image.DocumentCategoryId?.toString(),
            documentId: image.DocumentId?.toString(),
            fileName:
              `${GlobalStore.applicationID}_Permanent_Address` +
              (image.MimeType === "application/pdf" ? ".pdf" : ".jpg"),
            isfilecropped: true,
            mimeType: image.MimeType || ".",
            panno: GlobalStore.personaldetails.pan,
            personaldetailsid: "0",
            autoqc: true,
            iskycdocument: false,
            documentNumber: image.DocumentNumber,
          };
          const addressUploadObj = {
            applicationid: GlobalStore.applicationID,
            base64document: image.Image,
            filename: savePermAddressObj.fileName,
            QltyByPassFlag: 1,
          };

          setDocumentInfo((prev: any) => {
            let isDocsAvailable = !prev.totalDocs.includes(
              savePermAddressObj.fileName
            );
            return {
              ...prev,
              totalDocsCount: isDocsAvailable
                ? prev.totalDocsCount + 1
                : prev.totalDocsCount,
              totalDocs: isDocsAvailable
                ? [...prev.totalDocs, savePermAddressObj.fileName]
                : [...prev.totalDocs],
            };
          });

          AUploadDocument(
            (res: any) =>
              getUploadKraResponse(
                res,
                savePermAddressObj,
                applicationIdExists
              ),
            addressUploadObj
          );
          break;

        default:
          const savePermAddressDefaultObj: saveDocumentObj = {
            documentCategoryId: image.DocumentCategoryId?.toString(),
            documentId: image.DocumentId?.toString(),
            fileName:
              `${GlobalStore.applicationID}_Permanent_Address` +
              (image.MimeType === "application/pdf" ? ".pdf" : ".jpg"),
            isfilecropped: true,
            mimeType: image.MimeType || ".",
            panno: GlobalStore.personaldetails.pan,
            personaldetailsid: "0",
            autoqc: true,
            iskycdocument: false,
            documentNumber: image.DocumentNumber,
          };
          const addressUploadDefaultObj = {
            applicationid: GlobalStore.applicationID,
            base64document: image.Image,
            filename: savePermAddressDefaultObj.fileName,
            QltyByPassFlag: 1,
          };

          setDocumentInfo((prev: any) => {
            let isDocsAvailable = !prev.totalDocs.includes(
              savePermAddressDefaultObj.fileName
            );
            return {
              ...prev,
              totalDocsCount: isDocsAvailable
                ? prev.totalDocsCount + 1
                : prev.totalDocsCount,
              totalDocs: isDocsAvailable
                ? [...prev.totalDocs, savePermAddressDefaultObj.fileName]
                : [...prev.totalDocs],
            };
          });

          AUploadDocument(
            (res: any) =>
              getUploadKraResponse(
                res,
                savePermAddressDefaultObj,
                applicationIdExists
              ),
            addressUploadDefaultObj
          );

          break;
      }
    });
  }
  function getKraDetails(res: any, applicationIdExists?: boolean) {
    console.log("KRA console", res);
    if (!res || res.error) {
      // setErrCode('1227- Error in fetching details from KRA portal.');
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "GetKraDetails", label: "ERROR || ERR108-1" });
      navigate(applicationIdExists);
    } else {
      const responseObj = res.data ? res.data : res;
      if (
        responseObj &&
        !responseObj.ErrorCode &&
        responseObj.AppStatus === "07" //responseObj.AppStatus === "02" ||
      ) {
        GlobalStore.isKRA = true;
        if (applicationModeType !== "isKRA") {
          setApplicationModeType("isKRA");
        }
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        GlobalStore.KRA_email = responseObj.KraEmail
          ? responseObj.KraEmail
          : "";
        GlobalStore.KRA_mobile = responseObj.KraMobile
          ? responseObj.KraMobile
          : "";
        GlobalStore.KRA_mode = responseObj.kra_mode ? responseObj.kra_mode : 0;

        // if (!GlobalStore.isKYC) {
        const resultObj = responseObj;
        GlobalStore.kraInfoObject.addressLine1 = `${resultObj.AppPerAdd1} ${
          resultObj.AppPerAdd2 || ""
        } ${resultObj.AppPerAdd3 || ""}`;
        GlobalStore.kraInfoObject.addressLine1 =
          GlobalStore.kraInfoObject.addressLine1; //.replace(/[^a-zA-Z0-9 ]/g, " ")

        GlobalStore.kraInfoObject.corrAddress = `${resultObj.AppCorAdd1} ${
          resultObj.AppCorAdd2 || ""
        } ${resultObj.AppCorAdd3 || ""}`;
        GlobalStore.kraInfoObject.corrAddress =
          GlobalStore.kraInfoObject.corrAddress; //.replace(/[^a-zA-Z0-9 ]/g, " ")
        GlobalStore.kraInfoObject.pincode = resultObj.AppPerPincd;
        GlobalStore.kraInfoObject.corrPincode = resultObj.AppCorPincd;
        GlobalStore.personaldetails.fsfullName = resultObj.FatherName
          ? resultObj.FatherName
          : GlobalStore.personaldetails.fsfullName;
        clientPartialDetails_ao.isKRA = GlobalStore.isKRA;
        clientPartialDetails_ao.addressLine1 =
          GlobalStore.kraInfoObject.addressLine1;
        clientPartialDetails_ao.pincode = GlobalStore.kraInfoObject.pincode;
        clientPartialDetails_ao.corrAddress =
          GlobalStore.kraInfoObject.corrAddress;
        clientPartialDetails_ao.corrPincode =
          GlobalStore.kraInfoObject.corrPincode;
        clientPartialDetails_ao.fsfullName =
          GlobalStore.personaldetails.fsfullName;
        clientPartialDetails_ao.sameAsPermanent =
          GlobalStore.kraInfoObject.addressLine1 ==
          GlobalStore.kraInfoObject.corrAddress
            ? true
            : false;
        // } else {
        //     clientPartialDetails_ao.isKRA = GlobalStore.isKRA;
        // }

        getKraDocumentUpload(responseObj);
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        registerCleverTapEventAfterProceed();
        gtmService({ action: "PAN_DOB", label: "Step_Complete" });

        if (applicationIdExists) history.push(GlobalStore.nextIncompleteStage);
        // else {
        //   // history.push(dashboardUrl.profile);
        //   // setIsLoading(false);
        //   setShowSubmitModal(true);
        // }
      } else {
        if (!GlobalStore.isKRA) {
          console.log("setApplicationModeType 5");
          setApplicationModeType("isDigiLocker");
          setIsNormalClient(true);
          GlobalStore.isNormalClient = true;
          const clientPartialDetails_ao = JSON.parse(
            window.localStorage.getItem("clientPartialDetails_ao") || "{}"
          );
          clientPartialDetails_ao.isNormalClient = GlobalStore.isNormalClient;
          window.localStorage.setItem(
            "clientPartialDetails_ao",
            JSON.stringify(clientPartialDetails_ao)
          );
          setIsLoading(false);
          navigate(applicationIdExists);
        } else navigate(applicationIdExists);
      }
    }
  }

  const checkPanValidationMsgs = (response: any) => {
    let result = "";
    Object.keys(panValidationKeyList).forEach((x) => {
      if (response[x] === "N") {
        // console.log(data[panValidationKeyList[x]])
        result = result + "\n" + response[panValidationKeyList[x]];
      }
    });
    return result;
  };

  const panValidationCallback = (res: any, callBackFunction: Function) => {
    if (!res || res.error) {
      if (panInvalidMsg?.includes(res.error)) {
        setPanInvalidMsg(panInvalidMsg);
      } else {
        setPanInvalidMsg(panInvalidMsg + res.error);
      }
      setIsLoading(false);
    } else {
      /*
        new api added for a pan api check
      */
      if (res && res.data) {
        panValidationResp = res.data;
        if (
          res &&
          res.data &&
          (res.data?.Dob === "N" ||
            res.data?.Name === "N" ||
            res.data?.PanStatus === "N" ||
            res.data?.SeedingStatus === "N")
        ) {
          setErrorFoundInPanValidation(false);

          setPanInvalidMsg(checkPanValidationMsgs(res.data));
          // if (panInvalidMsg?.includes(res.data?.DobMsg)) {
          //   setPanInvalidMsg(panInvalidMsg);
          // } else {
          //   setPanInvalidMsg(panInvalidMsg + res.data?.DobMsg);
          // }
        }
        // if (
        //   res &&
        //   res.data &&
        //   res.data.Name?.length > 0 &&
        //   res.data?.Name === "N"
        // ) {
        //   setErrorFoundInPanValidation(false);
        //   if (panInvalidMsg?.includes(res.data?.NameMsg)) {
        //     setPanInvalidMsg(panInvalidMsg);
        //   } else {
        //     setPanInvalidMsg(panInvalidMsg + res.data?.NameMsg);
        //   }
        // }
        // if (
        //   res &&
        //   res.data &&
        //   res.data.PanStatus?.length > 0 &&
        //   res.data?.PanStatus === "N"
        // ) {
        //   setErrorFoundInPanValidation(false);

        //   if (panInvalidMsg?.includes(res.data?.PanStatusMsg)) {
        //     setPanInvalidMsg(panInvalidMsg);
        //   } else {
        //     setPanInvalidMsg(panInvalidMsg + res.data?.PanStatusMsg);
        //   }
        // }

        if (res?.data?.SeedingStatus === "Y") {
          isPanLinkedToAadhar = true;
        } else {
          isPanLinkedToAadhar = false;
        }
        if (
          (res && res.data && res.data?.Dob === "N") ||
          res.data?.Name === "N" ||
          res.data?.PanStatus === "N" ||
          res.data?.SeedingStatus === "N"
        ) {
          setIsLoading(false);
        } else {
          setErrorFoundInPanValidation(true);
          //     console.log("values.dob===extractPanResponse?.date_of_birth",values.dob===extractPanResponse?.date_of_birth,values,extractPanResponse)
          if (
            values.pan === extractPanResponse?.id_number &&
            moment(values.dob).format("YYYY-MM-DD") ===
              extractPanResponse?.date_of_birth
          ) {
            setDobChecked(true);
          } else {
            setDobChecked(false);
          }

          callBackFunction();
        }
      }
    }
  };
  const panValidation = (
    panObject: {
      panNo: string;
      name: string;
      dob: string;
      source: string;
    },
    callBackFunction: Function
  ) => {
    APanVerification((res: any) => {
      panValidationCallback(res, callBackFunction);
    }, panObject);
  };
  function navigate(applicationIdExists: boolean | undefined) {
    registerCleverTapEventAfterProceed();
    gtmService({ action: "PAN_DOB", label: "Step_Complete" });
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    console.log(
      "ckyc,kra-values-",
      clientPartialDetails_ao.ckycNo,
      "||",
      clientPartialDetails_ao.isKRA
    );
    console.log("showSubmitModal", showSubmitModal);
    if (showSubmitModal) {
      if (
        !clientPartialDetails_ao.ckycNo ||
        clientPartialDetails_ao.ckycNo === "" ||
        !clientPartialDetails_ao.isKRA
      ) {
        console.log("oabove ddglocker");
        history.push(containerUrl.dgLocker);
      } else if (applicationIdExists) {
        console.log("oabove ddglocker 1");
        history.push(GlobalStore.nextIncompleteStage);
      }
      // else if (values.aadharLinked !== 'No') history.push(containerUrl.dgLocker);
      else {
        console.log("oabove ddglocker 2");
        if (!errCode && errCode.length === 0)
          history.push(dashboardUrl.profile);
      }
    }
    if (!showSubmitModal) {
      setShowSubmitModal(true);
      setIsLoading(false);
    }
  }

  const handleConsent = () => {
    // console.log('Checked');
    // setConsent(!consent);
  };

  const sendFromMobile = (val: string) => {
    if (val === "Photo") {
      if (togPhoto === true) {
        setTogPhoto(false);
      } else {
        photoObj.isWatsappFlag = true;
        photoObj.fileName = ".";
        photoObj.mimeType = ".";
        setTogPhoto(true);
      }
    }
  };

  // This function reads the browsed file and returns the base64 string and other details
  function onPanDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string
  ) {
    setPanFileUploadedLoader(true);
    setIsLoading(true);
    panObj.imgUrl = "";
    panObj.documentId = 1;
    panObj.uploadPer = 100;
    setPanObj(panObj);

    const panOCR = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      documentcategory: "PAN Card",
      documentid: panObj.documentId,
      source: DIY,
    };

    setPanOCR({
      PayloadObj: panOCR,
      val: val,
      fileName: fileName,
      id: id,
      type: type,
    });

    // AextractPan(
    //   (res: any) => storeOcrData(res, val, fileName, id, type),
    //   panOCR
    // );
    AextractPanNew((res: any) => {
      if (!res || res.error) {
        setIsLoading(false);
        // setErrCode('Invalid Image');
        // setErrCode(errorMessage.imgNotMatchWithPan);
        if (errCode?.includes(res.error)) {
          setErrCode(errCode);
        } else {
          setErrCode(errCode + res.error);
        }
        setPanFileUploaded(true);
        setPanFileUploadedLoader(false);
        // alert('Pan Card number entered and the uploaded image do not match. Please upload correct PAN');
      } else {
        if (res && res?.data?.result) {
          setExtractPanResponse(res?.data?.result?.extraction_output);
          if (
            res?.data?.result?.extraction_output?.id_number === "" ||
            res?.data?.result?.extraction_output?.id_number === null ||
            res?.data?.result?.extraction_output?.id_number === undefined
          ) {
            setInvalidPanFileUploadedMsg(
              "Oops!! You seem to have uploaded the wrong document. Please upload Pan Card!!"
            );
            setPanFileUploaded(false);
            setPanFileUploadedLoader(false);
          } else {
            setInvalidPanFileUploadedMsg("");
          }
          setValues((values) => {
            values.pan = res?.data?.result?.extraction_output?.id_number;
            values.dob = new Date(
              res?.data?.result?.extraction_output?.date_of_birth
            );
            let fullName =
              res?.data?.result?.extraction_output?.name_on_card?.split(" ");
            values.firstName = fullName?.length > 0 ? fullName[0] ?? "" : "";
            values.lastName =
              fullName?.length > 0 ? fullName[fullName?.length - 1] ?? "" : "";
            console.log("splice", fullName);
            fullName.splice(0, 1);
            fullName.splice(fullName?.length - 1);
            values.middleName = fullName.join(" ");
            return values;
          });
          setPanFileUploadedLoader(false);
          if (
            val !== null &&
            val !== undefined &&
            fileName?.length > 0 &&
            id?.length > 0
          ) {
            setPanFileUploaded(true);
          } else {
            setPanFileUploaded(false);
          }
        }
      }
    }, panOCR);
    setIsLoading(false);
  }
  const uploadPanFile = (
    val: any,
    fileName: string,
    id: string,
    type: string
  ) => {
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    const savePanObj: saveDocumentObj = {
      documentCategoryId: panObj.documentCategoryId?.toString(),
      documentId: panObj.documentId?.toString(),
      fileName:
        panObj.fileName + (type === "application/pdf" ? ".pdf" : ".jpg"),
      isfilecropped: false,
      mimeType: type,
      panno: GlobalStore.personaldetails.pan,
      personaldetailsid: "0",
      autoqc: false,
      iskycdocument: false,
    };
    let PanUploadObject = {
      savePanObj: savePanObj,
      uploadObj: uploadObj,
      id: id,
      fileName: fileName,
      type: type,
    };
    setPanUploadObject(PanUploadObject);
    GlobalStore.panOCRObj = extractPanResponse;
    if (checkAutoQcForPan()) {
      savePanObj.autoqc = true;
    } else {
      savePanObj.autoqc = false;
    }

    setDocumentInfo((prev: any) => {
      return {
        ...prev,
        totalDocsCount: prev.totalDocsCount + 1,
        totalDocs: !prev.totalDocs.includes(savePanObj.fileName)
          ? [...prev.totalDocs, savePanObj.fileName]
          : [...prev.totalDocs],
      };
    });

    AUploadDocument(
      (res: any) => getPanUploadRes(res, savePanObj),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  };

  const storeOcrData = (
    res: any,
    val: any,
    fileName: string,
    id: string,
    type: string
  ) => {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode('Invalid Image');
      // setErrCode(errorMessage.imgNotMatchWithPan);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      // alert('Pan Card number entered and the uploaded image do not match. Please upload correct PAN');
      gtmService({ action: "ExtractDocumentData", label: "ERROR || ERR114-3" });
      alert(
        "Entered Pan do not match with Pan Image. Rectify the Pan Entry or Image"
      );
    } else if (res.data) {
      // Checking if PAN number extracted matches with PAN number entered
      if (
        res.data.result != null &&
        res.data.result.extraction_output.id_number === values.pan
      ) {
        setPanNumberMatched(true);
        let panVal = res.data.result.extraction_output.id_number;
        panDob = res.data.result.extraction_output.date_of_birth;
        setPanDobState(panDob);
        setPanValState(panVal);

        let uploadObj = {
          applicationid: GlobalStore.applicationID,
          base64document: val!.split(",")[1],
          filename: fileName,
        };

        const savePanObj: saveDocumentObj = {
          documentCategoryId: panObj.documentCategoryId?.toString(),
          documentId: panObj.documentId?.toString(),
          fileName:
            panObj.fileName + (type === "application/pdf" ? ".pdf" : ".jpg"),
          isfilecropped: false,
          mimeType: type,
          panno: GlobalStore.personaldetails.pan,
          personaldetailsid: "0",
          autoqc: false,
          iskycdocument: false,
        };

        let PanUploadObject = {
          savePanObj: savePanObj,
          uploadObj: uploadObj,
          id: id,
          fileName: fileName,
          type: type,
        };
        console.log("panObj.fileName", savePanObj.fileName);

        setPanUploadObject(PanUploadObject);
        GlobalStore.panOCRObj = res.data.result.extraction_output;

        if (panDob === moment(values.dob).format("YYYY-MM-DD")) {
          setRemovePanImg(false);
          setDobChecked(true);
          savePanObj.autoqc = true;

          setDocumentInfo((prev: any) => {
            return {
              ...prev,
              totalDocsCount: prev.totalDocsCount + 1,
              totalDocs: !prev.totalDocs.includes(savePanObj.fileName)
                ? [...prev.totalDocs, savePanObj.fileName]
                : [...prev.totalDocs],
            };
          });

          AUploadDocument(
            (res: any) => getPanUploadRes(res, savePanObj),
            uploadObj,
            (event: ProgressEvent) =>
              onUploadProgress(event, id, fileName, type)
          );
          GlobalStore.panOCRObj = res.data.result.extraction_output;
          setErrCode("");
          setShowSubmitModal(true);
        } else {
          // setIsNormalClient(true);
          // GlobalStore.isNormalClient = true;
          // const clientPartialDetails_ao = JSON.parse(
          //   window.localStorage.getItem("clientPartialDetails_ao") || "{}"
          // );
          // clientPartialDetails_ao.isNormalClient = GlobalStore.isNormalClient;
          // window.localStorage.setItem(
          //   "clientPartialDetails_ao",
          //   JSON.stringify(clientPartialDetails_ao)
          // );
          console.log("HERE------ ");
          setRemovePanImg(true);
          setDobChecked(false);
          setIsLoading(false);
          // setErrCode('DoB entered does not match with that on PAN. Please enter correct DoB.');
          setShowSubmitModal(false);
          setErrCode(errorMessage.imgNotMatchWithPan);
        }
      } else {
        setPanNumberMatched(false);
        setIsLoading(false);
        // setErrCode('Pan Card number entered and the uploaded image do not match. Please upload correct PAN.');
        setShowSubmitModal(false);
        setErrCode(errorMessage.imgNotMatchWithPan);
      }
    }
  };

  // This function takes care of waiting till the uploading of file is completed b4 getPanUploadRes fn is called
  const onUploadProgress = (
    event: ProgressEvent,
    id: string,
    fileName: string,
    type: string
  ) => {
    const { loaded, total } = event;
    let percentage = Math.floor((loaded * 100) / total);
    let panCopy = { ...panObj };
    if (percentage < 100) {
      panCopy.id = id;
      panCopy.disable = false;
      panCopy.uploadPer = percentage;
      panCopy.hideProgress = true;
      panCopy.showFile = false;
      setPanObj(panCopy);
    } else if (percentage === 100) {
      panCopy.id = id;
      panCopy.disable = false;
      panCopy.uploadPer = percentage;
      panCopy.showFile = false;
      panCopy.hideProgress = false;
      panCopy.fileName = fileName;
      panCopy.mimeType = type;
      setPanObj(panCopy);
    }
  };

  // This fn is called after uploading of file is completed
  const getPanUploadRes = (res: any, savePanObj: saveDocumentObj) => {
    if (!res || res.error) {
      // setErrCode('1361- Error in uploading the file.');
      // setErrCode(errorMessage.getUploadResponse);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "UploadDocumentToS3", label: "ERROR || ERR114-8" });
      setIsLoading(false);
      setPanUploaded(false);
    } else {
      
      if (res.data && res.data.Result) {
        savePanObj.fileName = res.data.Guid;
      }
      GlobalStore.isPANVerified = true;
      setPanUploaded(true);
      const clientPartialDetails_ao = JSON.parse(
        window.localStorage.getItem("clientPartialDetails_ao") || "{}"
      );
      clientPartialDetails_ao.isPANVerified = GlobalStore.isPANVerified;
      window.localStorage.setItem(
        "clientPartialDetails_ao",
        JSON.stringify(clientPartialDetails_ao)
      );

      setIsLoading(false);
      saveDetails.push(savePanObj);
      const saveobjUTM_Info = utmDurationInfoObj(startTime, "panInfoDuration");
      const objUTM_Info = utmDurationInfoObj(startTime, "registrationdur");
      const saveObj = {
        applicationId: GlobalStore.applicationID,
        clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
        documentInfo: saveDetails,
        leadId: GlobalStore.leadId,
        objUTM_Info: objUTM_Info,
      };
      ASaveDocumentDetails((res: any) => {
        if (!res || res.error) {
          if (errCode?.includes(res.error)) {
            setErrCode(errCode);
          } else {
            setErrCode(errCode + res.error);
          }
          if(isPanLinkedToAadhar!==true){
            setApplicationInitializedFlag(true)
          }
        } else {
          if (res && res.data) {
            if(isPanLinkedToAadhar!==true){
              setApplicationInitializedFlag(true)
            }
            setDocumentInfo((prev: any) => {
              console.log(
                "prev.totalDocs",
                prev.totalDocs,
                savePanObj.fileName,
                prev.totalDocs.filter((x: string) => x != savePanObj.fileName)
              );
              return {
                ...prev,
                uploadedDocsCount: prev.uploadedDocsCount + 1,
                totalDocs: prev.totalDocs.filter(
                  (x: string) => x != savePanObj.fileName
                ),
              };
            });
          }
        }
      }, saveObj);
    }
  };

  const handleContinue = () => {
    setErrCode("");
    if (!GlobalStore.leadId || GlobalStore.leadId === "") navigate(false);
    console.log(
      " Object.values(errors).some((error) => error)",
      Object.values(errors).some((error) => error)
    );
  };

  const closeSeedingPopup = () => {
    setSeedingPopup(false);
    // saveDocument();
  };

  const reCheck = () => {
    setIsLoading(true);
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );

    AGetPanUtiData(getSeedingData, values.pan);
  };

  const getSeedingData = (res: any) => {
    if (!res || res.error) {
      console.log(res.errMsg);
      if (errCode?.includes(res.error)) {
        setErrCode(errCode);
      } else {
        setErrCode(errCode + res.error);
      }
      gtmService({ action: "GetPanDetails", label: "ERROR || ERR142" });
    } else {
      if (res.data) {
        setIsLoading(false);
        // let responseObj = JSON.parse(res.data.panDetails);
        let responseObj = res.data.AadharSeedingStatus
          ? res.data.AadharSeedingStatus
          : "";
        // if (responseObj.includes("Aadhaar not Seeded")) {
        if (res.data.IsAadharSeeded == "N") {
          GlobalStore.isSeeding = false;
          isPanLinkedToAadhar = false;
          setSeedingPopup(true);
          // APanLinkedWithAadhar(
          //   APanLinkedWithAadharRes,
          //   values.pan,
          //   values.aadhaarNo
          // );
        }
        // else if (responseObj.includes("Aadhaar Seeding is Successful")) {
        else if (
          res.data.IsAadharSeeded == "Y" ||
          res.data.IsAadharSeeded == "NA"
        ) {
          GlobalStore.isSeeding = true;
          isPanLinkedToAadhar = true;
          setSeedingPopup(false);
        }
      } else {
        gtmService({ action: "GetPanDetails", label: "ERROR || ERR142-S" });
      }
      if (GlobalStore.applicationID)
        AGetStageCompletionStatus(
          getClientStageDetails,
          GlobalStore.applicationID
        );
    }
  };

  const navToLink = () => {
    window.open(
      "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/bl-link-aadhaar",
      "_blank"
    );
  };

  console.log(
    "isNormalClient =",
    isNormalClient,
    "|| panNumberMatched =",
    panNumberMatched,
    "|| dobChecked=,",
    dobChecked,
    "|| removePanImg=",
    removePanImg
  );

  const doItLaterFlag = () => {
    setWillDoLater(true);
    clear_Error();
  };

  const handleRemove = () => {
    setPanFileUploaded(false);
    setPanFileUploadedLoader(false);
    setPanInvalidMsg("");
    setInvalidPanFileUploadedMsg("");
    setPanOCR(null);
    setErrors({
      pan: undefined,
      dob: undefined,
      aadharLinked: undefined,
      aadhaarNo: undefined,
      firstName: undefined,
      middleName: undefined,
      lastName: undefined,
    });
  };

  React.useEffect(() => {
    if (submitFlag && errCode == "") {
      handleSubmit();
    }
  }, [submitFlag, errCode]);

  const clear_Error = () => {
    setErrCode("");
    setSubmitFlag(true);
  };

  // disabled={
  //   Object.values(errors).some((error) => error) ||
  //   Object.values(values).some((value) => !value) ||
  //   (isNormalClient &&
  //     (!panNumberMatched || !dobChecked || removePanImg)) ||
  //   !consent ||
  //   !panOCR
  // }

  function nextprocess(kycobj: any) {
    updateUtmInfo(() => {});

    if (false) {
      //togPhoto
      const smsBody = {
        mobNo: GlobalStore.user.mobile,
        // selfieLinkText: `Give a Smile and complete your Selfie process for Online Account Opening, click here ${selfieUrl} to complete the process. Regards, Nuvama`,
        //selfieLinkText: selfieUrl,
        selfieLinkText: GlobalStore.selfieUrl,
      };
      ASendSms((res: any) => {
        if (!res || res.error) {
          setErrCode(errCode + res.error);
        } else {
          if (res && res.data) {
          }
        }
      }, smsBody);
    }
    if (isPanLinkedToAadhar == true) {
      getKraDetails(kycobj);
    } else {
      setIsNormalClient(true);
      GlobalStore.isNormalClient = true;
      const clientPartialDetails_ao = JSON.parse(
        window.localStorage.getItem("clientPartialDetails_ao") || "{}"
      );
      clientPartialDetails_ao.isNormalClient = GlobalStore.isNormalClient;
      window.localStorage.setItem(
        "clientPartialDetails_ao",
        JSON.stringify(clientPartialDetails_ao)
      );
      setIsLoading(false);
    }
  }

  return (
    <>
      {/* <Backdrop isCloseIcon isShow={showDigiRedirection} onClose={() => setShowDigiRedirection(false)}>
                <CheckDigilockerPopup onClose={setShowDigiRedirection} />
            </Backdrop> */}
      <div className={"pan" + (isLoading ? " op05" : "")}>
        <div className="banner">
          <img className="bannerImg" src={regbanner} alt="banner" />
        </div>
        <div className="panHeading">
          <label>Let’s Get Started</label>
        </div>

        <div className="panInfo">
          <span>Please provide your PAN Card details below to proceed</span>
        </div>

        <div className="panForm">
          <form>
            <fieldset disabled={isLoading}>
              <div className="row">
                <div className={isLoading ? "not-allowed" : ""}>
                  <div className="uploadLayout">
                    <div className="mrt60 row">
                      <label className="photoSubHeading mrt10">
                        Upload a clear digital copy of your PAN Card.
                      </label>
                      <div className="mrt20">
                        <DocumentBrowse
                          id="panCard"
                          docObj={panObj}
                          previewImageexternalClassName={"maxSizewithAnother"}
                          onDocUploadComplete={onPanDocUploadComplete}
                          isRemove={handleRemove}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {panFileUploadedLoader && (
                <div className="row">
                  <Loader size={20} />
                </div>
              )}
              {invalidPanFieldUploadedMsg != "" && (
                <div className="errMsgBlck">
                  <ErrMsg>
                    <label>{invalidPanFieldUploadedMsg}</label>
                  </ErrMsg>
                </div>
              )}
              {panFileUploaded &&
                !panFileUploadedLoader &&
                invalidPanFieldUploadedMsg == "" && (
                  <>
                    <fieldset disabled={errorFoundInPanValidation}>
                      <div className="row">
                        <label className="inptLbl">First Name</label>
                        <InputField
                          className="panInput"
                          defaultValue={values?.firstName} //To be removed
                          onChange={(value) => onChange("firstName", value)}
                          required={true}
                          onBlur={(error: any) => {
                            if (error.required) {
                              setErrors({
                                ...errors,
                                firstName: "This field is required",
                              });
                            } else if (
                              !!values?.firstName &&
                              (values.firstName as string).slice(-1) == " "
                            ) {
                              setErrors({
                                ...errors,
                                firstName: "only spaces are not allowed",
                              });
                            }
                          }}
                          type="text"
                          placeholder="Eg. Pranav"
                          //  pattern={pan}
                          errorText={errors.firstName}
                          maxLength={20}
                          // suffix={validPan ? <i className="edel-icon-tick" /> : <></>}
                        />
                      </div>
                      <div className="row">
                        <label className="inptLbl">Middle Name</label>
                        <InputField
                          className="panInput"
                          defaultValue={values?.middleName}
                          // defaultValue="CWLPA4946C" //To be removed
                          onChange={(value) => onChange("middleName", value)}
                          onBlur={(error: any) => {
                            if (
                              !!values?.middleName &&
                              (values.middleName as string).slice(-1) == " "
                            ) {
                              setErrors({
                                ...errors,
                                middleName: "only spaces are not allowed",
                              });
                            }
                          }}
                          type="text"
                          placeholder="Eg. Prashant"
                          //  pattern={pan}
                          errorText={errors.middleName}
                          maxLength={20}
                          // suffix={validPan ? <i className="edel-icon-tick" /> : <></>}
                        />
                      </div>
                      <div className="row">
                        <label className="inptLbl">Last Name</label>
                        <InputField
                          className="panInput"
                          defaultValue={values?.lastName}
                          // defaultValue="CWLPA4946C" //To be removed
                          onChange={(value) => onChange("lastName", value)}
                          onBlur={(error) => {}}
                          type="text"
                          placeholder="Eg. Joshi"
                          //  pattern={pan}
                          errorText={errors.lastName}
                          maxLength={20}
                          // suffix={validPan ? <i className="edel-icon-tick" /> : <></>}
                        />
                      </div>
                      {/* Pan Field start */}
                      <div className="row">
                        <label className="inptLbl">Enter PAN</label>
                        <InputField
                          className="panInput"
                          defaultValue={values?.pan}
                          // defaultValue="CWLPA4946C" //To be removed
                          onChange={(value) => {
                            setPanInvalidMsg("");
                            onChange("pan", value.toUpperCase());
                          }}
                          onBlur={(error) => onBlurPan(error)}
                          type="text"
                          placeholder="Eg. ABCDE1234F"
                          pattern={pan}
                          errorText={errors.pan}
                          maxLength={10}
                          suffix={
                            validPan ? <i className="edel-icon-tick" /> : <></>
                          }
                        />
                      </div>
                      {/* Pan Field end */}

                      {/* DOB start */}
                      <div className="row">
                        <label className="inptLbl">Date of Birth</label>
                        <Calender
                          // defaultDate={new Date('December 24, 1996 00:00:00')} //To be removed
                          onDateSelect={(val) => onChange("dob", val)}
                          defaultDate={values?.dob ? values.dob : undefined}
                          placeholderText={"Select Date of Birth"}
                          maxDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 18
                              )
                            )
                          }
                          minDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 100
                              )
                            )
                          }
                          onBlur={onBlurDob}
                          isError={errors.dob ? true : false}
                        />
                      </div>

                      {errors.dob && (
                        <p className="help_text red">{errors.dob}</p>
                      )}
                      {/* DOB end */}
                    </fieldset>

                    {/* Aadhar no. field  start*/}
                    {/* <div className="row">
                <label className="inptLbl">Enter Aadhaar No.</label>
                <InputField
                  onChange={(val) => onChange("aadhaarNo", val)}
                  pattern={betweenNChars(12, 12)}
                  placeholder="Eg. 1234"
                  name="aadhaarNo"
                  maxLength={12}
                  integer={true}
                  type="password"
                  onBlur={(error) => onBlurAadhaar(error)}
                  errorText={errors.aadhaarNo}
                />
              </div> */}
                    {/* Aadhar no. field  end*/}

                    {/* <div className="fieldLabel row">
                                <label>Is your Aadhar linked with your mobile?</label>
                                <div className="explain">
                                    <label
                                        className="explainLbl"
                                        onMouseOver={() => setShowExplain(true)}
                                        onMouseLeave={() => setShowExplain(false)}
                                    >
                                        Why?
                                    </label>
                                    {showExplain && (
                                        <InfoModal width={DeviceContext().isMobile ? '328px' : '354px'}>
                                            <div className="explainInfo">
                                                <label>
                                                    We check if your Aadhaar and mobile number are linked to simplify
                                                    your account opening journey. It will be used to pull your documents
                                                    from Digi Locker as well as be used for E-Signing the account
                                                    opening form at the end of this process
                                                </label>
                                            </div>
                                        </InfoModal>
                                    )}
                                </div>
                                <div className="radioSet">
                                    <div className="inlineDiv">
                                        <Radio
                                            checked={values.aadharLinked === 'Yes'}
                                            onChange={() => onChange('aadharLinked', 'Yes')}
                                            name="aadharLinked"
                                            label="Yes"
                                        />
                                    </div>
                                    <div className="inlineDiv noRadio">
                                        <Radio
                                            checked={values.aadharLinked === 'No'}
                                            onChange={() => onChange('aadharLinked', 'No')}
                                            name="aadharLinked"
                                            label="No"
                                        />
                                    </div>
                                    <div className="inlineDiv noRadio">
                                        <Radio
                                            checked={values.aadharLinked === 'Not Sure'}
                                            onChange={() => onChange('aadharLinked', 'Not Sure')}
                                            name="aadharLinked"
                                            label="Not Sure"
                                        />
                                    </div>
                                </div>
                            </div>
                            {values.aadharLinked !== 'Yes' && (
                                <div className="panLink">
                                    <label
                                        onClick={() => {
                                            gtmService({ action: 'PAN_DOB', label: 'Aadhar_Mobile_Link_Click' });
                                            setShowDigiRedirection(true);
                                        }}
                                    >
                                        Check if your Aadhar and mobile are linked
                                    </label>
                                </div>
                            )} */}
                    {showSubmitModal && dobChecked && (
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          height: "160px",
                        }}
                      >
                        <Backdrop
                          width="width50"
                          isShow={modalDisplayText}
                          isCloseIcon={false}
                        >
                          <h3>Please Note!</h3>
                          <div className="explainInfo">
                            {modalDisplayText ? (
                              <div
                                className="modaltext-Div"
                                style={{ fontSize: "20px" }}
                              >
                                {modalDisplayText}
                              </div>
                            ) : (
                              "Loading..."
                            )}
                          </div>
                          <div className="mrt16  mrb16 sunBtn">
                            <button
                              className={`panpage-modalSubmit ${
                                documentsInfo.totalDocsCount !==
                                documentsInfo.uploadedDocsCount
                                  ? "disable"
                                  : ""
                              }`}
                              onClick={() => {
                                if (applicationModeType === "isKRA")
                                  history.push(dashboardUrl.profile);
                                if (applicationModeType === "isDigiLocker")
                                  history.push(containerUrl.dgLocker);
                              }}
                              type="submit"
                              disabled={
                                documentsInfo.totalDocsCount !==
                                documentsInfo.uploadedDocsCount
                              }
                            >
                              {documentsInfo.totalDocsCount !==
                              documentsInfo.uploadedDocsCount
                                ? "Please Wait"
                                : "OK, Proceed"}
                            </button>
                          </div>
                        </Backdrop>
                      </div>
                    )}

                    {/* <div className="row">
                <div className={isLoading ? "not-allowed" : ""}>
                  <div className="uploadLayout">
                    <div className="mrt60 row">
                      <label className="photoSubHeading mrt10">
                        Upload a clear digital copy of your PAN Card.
                      </label>
                      <div className="mrt20">
                        <DocumentBrowse
                          id="panCard"
                          docObj={panObj}
                          onDocUploadComplete={onPanDocUploadComplete}
                          isRemove={handleRemove}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                    <div className="termList mrt41">
                      <Checkbox
                        checked={consent}
                        name="termone"
                        onChange={() => handleConsent()}
                      />
                      <label className="mrl13 linkLabel">
                        I authorise Nuvama to pull KYC records through C-KYC or
                        KRA
                      </label>
                    </div>

                    {utmObj &&
                      Object.keys(utmObj).length &&
                      utmObj.utm_source &&
                      utmObj.utm_content &&
                      utmObj.utm_campaign && (
                        <div className="termList mrt41">
                          <label className="linkLabel assisted-label">
                            You are being assisted
                          </label>
                        </div>
                      )}

                    {/* Error Msg Start*/}
                    {errCode && (
                      <div className="errMsgBlck">
                        <ErrMsg>
                          {typeof errCode === "number" ? (
                            <>
                              <label>We are facing some technical issue.</label>
                              <label>
                                Please try after sometime (error code:{errCode})
                              </label>
                            </>
                          ) : (
                            <label>{errCode}</label>
                          )}
                        </ErrMsg>
                      </div>
                    )}
                    {/* Error Msg End*/}
                    {/* Pan Matching  Error Msg Start */}
                    {panInvalidMsg !== "" && panInvalidMsg?.length > 0 && (
                      <div className="errMsgBlck">
                        <ErrMsg>
                          <label>{panInvalidMsg}</label>
                        </ErrMsg>
                      </div>
                    )}
                    {/* Pan Matching  Error Msg End */}
                    {/* Photo Error Msg Start*/}
                    {photoErrorMsg != "" && (
                      <div className="errMsgBlck">
                        <ErrMsg>
                          <label>{photoErrorMsg}</label>
                        </ErrMsg>
                      </div>
                    )}
                    {/* Photo Error Msg End*/}

                    <div className="row">
                      <Button
                        onClick={handleSubmit} //isNormalClient ? handleContinue :
                        btnName={isNormalClient ? "Continue" : "Proceed"}
                        // onClick={handleSubmit}
                        // btnName={"Proceed"}
                        disabled={
                          //                  Object.values(errors).some((error) => error) ||
                          //                    Object.values(values).some((value) => !value) ||
                          !(
                            errors?.dob != null ||
                            (errors?.dob != undefined &&
                              errors.firstName != null) ||
                            errors.firstName != undefined ||
                            (errors.firstName !== "" && errors?.pan != null) ||
                            errors?.pan != undefined ||
                            errors?.pan !== ""
                          ) ||
                          values?.firstName == "" ||
                          values?.firstName == undefined ||
                          values.firstName == null ||
                          (values?.firstName?.length == 0 &&
                            values?.pan?.length == 0 &&
                            values?.dob === null) ||
                          values?.dob === undefined ||
                          (errCode == "" &&
                            isNormalClient &&
                            (!panNumberMatched || !dobChecked)) ||
                          !consent ||
                          liveLocationCheck.checkPhotoLiveness ||
                          liveLocationCheck.checkLocation ||
                          !panOCR ||
                          errCode
                        }
                        isLoading={isLoading}
                        type="submit"
                      />
                    </div>
                  </>
                )}
            </fieldset>
          </form>
          {/* {false ? (
            ""
          ) : (
            <div className="doitlater_parent">
              I am not handy with the document.
              <span
                className="doItLater"
                onClick={() => {
                  doItLaterFlag();
                  clear_Error();
                }}
              >
                I will do it later
              </span>
            </div>
          )} */}
        </div>
      </div>
      <div className={"docForm frame" + (isLoading ? " op05" : "")}>
        <Backdrop
          isShow={seedingPopup}
          onClose={() => closeSeedingPopup()}
          isCloseIcon={false}
        >
          <div className="isSeeding">
            {/* {DeviceContext().isMobile && <EdelLogoCenter />} */}
            <img className="seedingIcon" src={isSeeding} alt="isSeeding" />
            <label className="seedingLabel">PAN Aadhar Link</label>
            <label className="subLAbel">
              We observe that your PAN and Aadhar is not linked. There are
              higher chances of your PAN getting deactivated.
            </label>
            <div className="seedingCTA">
              <div className="borderBlue">
                <TransparentButton
                  isLoading={isLoading}
                  btnName="Re-Check"
                  onClick={() => reCheck()}
                  disabled={!checkpanaaddharflag}
                />
              </div>
              <div>
                <Button btnName="Link PAN & Aadhar" onClick={navToLink} />
              </div>
            </div>
            {/* <div className="seedingContinue">
              <Button
                btnName="Continue"
                onClick={() => {
                  setIsLoading(true);
                  ACheckPanIbeats(checkedPanInIbeats, values.pan);
                }}
              />
            </div> */}
          </div>
        </Backdrop>
      </div>
    </>
  );
};

export default PANInfo;
