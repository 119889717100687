import React from "react";
import "./DocStatus.scss";
import DocStatusList from "./DocStatusList/DocStatusList";
import { useHistory } from "react-router-dom";
import { dashboardUrl } from "../../utils/constants";

export interface DocStatusProps {
  isPendingUpload: boolean;
  isPendingApproval: boolean;
  showResult: boolean;
  pendingUploadList: Array<any>;
  pendingApprovalList: Array<any>;
}

const DocStatus: React.FC<DocStatusProps> = (props: DocStatusProps) => {
  const history = useHistory();

  function uploadPendingDoc() {
    history.push(dashboardUrl.doc);
  }

  return (
    <>
      {props.showResult &&
        (props.isPendingUpload ? (
          <>
            <label className="docStatusHeading">
              The following set of documents are yet to be received on WhatsApp
            </label>
            <div className="docList docListFlex">
              {props.pendingUploadList.map((item, index) => (
                <div key={index}>
                  <DocStatusList
                    docName={item.DocumentCategory}
                    docStatus="Pending Upload"
                    docInfo={""}
                  />
                </div>
              ))}
            </div>
            <div className="pendingDocLink">
              <span className="edel-icon-back-arrow" />
              <label className="pendingDocLinkLbl" onClick={uploadPendingDoc}>
                Upload Pending Documents Now
              </label>
            </div>
          </>
        ) : props.isPendingApproval ? (
          <div className="docListApproval">
            {props.pendingApprovalList.map((item, index) => (
              <div key={index}>
                <DocStatusList
                  docName={item.ContentType || item.contenttype}
                  docStatus={item.Status || item.status}
                  docInfo={item.DocumentType || item.documenttype}
                />
              </div>
            ))}
          </div>
        ) : (
          <></>
        ))}
    </>
  );
};

export default DocStatus;
