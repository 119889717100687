import React from 'react';
import './PendingModule.scss'
import { useHistory } from 'react-router-dom';

export interface PendingModuleProps {
    moduleName: string;
    moduleLink: string;
}

const PendingModule: React.FC<PendingModuleProps> = (props) => {

    const history = useHistory();

    function redirectToUrl() {
        history.push(props.moduleLink);
    }

    return (
        <div className='pendingModule' onClick={redirectToUrl}>
            <span className='edel-icon-back-arrow' />
            <label className='pmName'>
                {props.moduleName}
            </label>
        </div>
    );
}

export default PendingModule;