import React, { useState } from "react";
import InputWithLabel from "../../UI/InputWithLabel/InputWithLabel";
import InputField from "../../UI/InputField/InputField";
import Button from "../../UI/Button/Button";
import { Verify } from "../../UI/Button/Button";
import {
  AGetCities,
  AValidateMobileOtp,
  ACreateLead,
  ACreateLeadSource,
  ASearchLeadByName,
  AGetMobileOtp,
  AWhatsappNotify,
  AGetEmployeeDetails,
  AGetEmailOtp,
  AValidateEmailOtp,
  CreateUCCRegCode,
  AGetOtpNew,
  AValidateOtpNew,
} from "../../Services/registration/registrationApiService";
import "./RegistrationForm.scss";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import {
  alphabetExp,
  integerExp,
  phoneNo,
  emailRegex,
} from "../../utils/regex";
import RegOtp from "../RegOtp/RegOtp";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import GlobalStore from "../../store/globalStore";
import {
  productCode,
  assignedTo,
  containerUrl,
  loginUrl,
  statusUrl,
  emailVerificationBody,
} from "../../utils/constants";
import { clientContinue } from "../../Services/globalService";
import gtmService from "../../Services/gtmService";
import { ClevertapService } from "../../Services/clevertapService";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import useQuery from "../../../customHooks/useQuery";
import { rmCodeValues } from "./rmCode";
import Checkbox from "../../UI/Checkbox/Checkbox";
import { errorMessage } from "../../utils/errorMessage";
import { getLeadSourceId } from "./leadSource";
import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";
import {
  ACheckRAAccount,
  AGetRelationshipData,
} from "../../Services/dashboard/profileApiService";

import { debug } from "console";
export interface ILeadFormFields {
  firstname: string;
  lastName: string;
  email: string;
  mobile: string;
  city: string | number;
  rmCode: string;
  rmCodeAssisted: string;
}

let timer: any;

const RegistrationForm = (props: any) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";
  const UTM_PLACEMENT = quryParams.get("utm_placement")
    ? quryParams.get("utm_placement")
    : "";
  const [showRmCode, setShowRmCode] = React.useState(false);
  const [cities, setCities] = React.useState([]);

  const [values, setValues] = React.useState({
    firstname: "",
    lastName: "",
    email: "",
    mobile: "",
    city: "",
  });
  const [rmCode, setRmCode] = React.useState("");
  const [errCode, setErrCode] = React.useState("");
  const [showMobileBtn, setShowMobileBtn] = React.useState<boolean>(true);
  const [showMobileMailBtn, setShowMobileMailBtn] =
    React.useState<boolean>(true);
  const [showMobileOtp, setShowMobileOtp] = React.useState<boolean>(false);
  const [disableOtp, setDisableOtp] = React.useState<boolean>(false);
  var CreateNewUCC: any;

  // to stop auto check email and mobile verification
  // const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(true);
  // const [isMobileVerified, setIsMobileVerified] =
  //   React.useState<boolean>(true);
  const [isMobileVerified, setIsMobileVerified] =
    React.useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(false);

  const [invalidOtp, setInvalidOtp] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [whatsappMsgSuccess, setWhatsappMsgSuccess] =
    React.useState<boolean>(false);
  const [beingAssistedCheckbox, setBeingAssistedCheckbox] =
    React.useState<boolean>(false);
  const [rmCodeAssisted, setRmCodeAssisted] = React.useState("");
  const [rmCodeAssistedName, setRmCodeAssistedName] = React.useState<any>("");
  const [utmCampaignCode, setUtmCampaignCode] = React.useState<any>(null);
  const [sourceNames, setSourceNames] = React.useState<any>("");
  const [planAsPerSource, setPlanAsPerSource] = React.useState("");

  const [isAssistedDataValid, setIsAssistedDataValid] =
    React.useState<any>(false);
  const [showEmailOtp, setShowEmailOtp] = React.useState<boolean>(false);
  const [disableEmailOtp, setDisableEmailOtp] = React.useState<boolean>(false);
  const [invalidEmailOtp, setInvalidEmailOtp] = React.useState<boolean>(false);
  const [otpIds, setOtpIds] = React.useState<{
    emailOtpId?: string;
    mobileOtpId?: string;
  }>({
    emailOtpId: undefined,
    mobileOtpId: undefined,
  });
  const [relationShipTagError, setRelationShipTagError] =
    React.useState<any>("");
  const [searchLeadFlag, setSearchLeadFlag] = React.useState<any>("");
  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [
    email_mobile_verificationCheckFlag,
    setEmail_mobile_verificationCheckFlag,
  ] = React.useState<boolean>(false);
  const [skipCreateLead, setSkipCreateLead] = React.useState<boolean>(false);
  const [mobileVerifiedFE, setMobileVerifiedFE] =
    React.useState<boolean>(false);
  const [mailVerifiedFE, setMailVerifiedFE] = React.useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [hideAreBeingSection, setHideAreBeingSection] = React.useState(false);
  // used to store the field wise errors
  const [errors, setErrors] = React.useState<{
    [key in keyof ILeadFormFields]?: string;
  }>({
    firstname: undefined,
    lastName: undefined,
    email: undefined,
    mobile: undefined,
    city: undefined,
    rmCodeAssisted: undefined,
  });

  const [allreadyExistMobOrEmail, setAllreadyExistMobOrEmail] = useState({
    mob: false,
    email: false,
  });
  const [createNewUCC, setCreateNewUCC] = React.useState("");

  const partner = JSON.parse(window.sessionStorage.getItem("partner") || "{}");
  var CreateNewUCC: any;

  React.useEffect(() => {
    if (
      containerUrl.continue === clientContinue() &&
      !GlobalStore.isPartialFilledClient
    ) {
      props.history.push(containerUrl.continue);
    } else {
      AGetCities(getCities);
    }
  }, []);

  // Clervertap event: personal details screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.personalDetailsPage.PAGE_LOADED,
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
    };

    ClevertapService.pushEvent(data);
  }, []);

  function getCities(res: any) {
    if (!res || res.error) setErrCode(res.error);
    //setErrCode('113 - Failed to fetch the list of cities, please reload.');
    else {
      if (res.data && res.data.city) {
        setCities(res.data.city);
        setErrCode("");
      }
    }
  }

  function onChange(key: keyof ILeadFormFields, value: string) {
    if (
      (key === "firstname" && !alphabetExp.test(value)) ||
      (key === "lastName" && !alphabetExp.test(value)) ||
      (key === "mobile" && !integerExp.test(value))
    ) {
      value = values[key];
    }

    key === "rmCode"
      ? setRmCode(value)
      : setValues({ ...values, [key]: value });

    if (key === "mobile") {
      if (
        GlobalStore.user.mobile !== "" &&
        GlobalStore.user.mobile === value &&
        isMobileVerified
      ) {
        setShowMobileBtn(false);
      } else {
        setShowMobileBtn(true);
      }
    }
    if (key === "email") {
      if (
        GlobalStore.user.email !== "" &&
        GlobalStore.user.email === value &&
        isEmailVerified
      ) {
        setShowMobileMailBtn(false);
      } else {
        setShowMobileMailBtn(true);
      }
    }
    setErrors({ ...errors, [key]: "" });
    return value;
  }

  function handleCityBlur(value: any) {
    if (typeof value === "object") {
      setErrors({ ...errors, city: "" });
    } else {
      // setErrors({ ...errors, city: 'Please select a city from dropdown' });
      setErrors({ ...errors, city: errorMsg(errorCode.FE02) });
    }
  }

  function debounce(value: string, timeout = 1000) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      checkRMCodeValue(value);
    }, timeout);
  }

  function checkRMCodeValue(value: string) {
    if (value !== "") {
      AGetEmployeeDetails(checkEmployeesDetails, { username: value });
    } else {
      setErrors({ ...errors, rmCodeAssisted: errorMsg(errorCode.FE06) });
      setRmCodeAssistedName("");
      setSourceNames("");
      setUtmCampaignCode(null);
      setPlanAsPerSource("");
    }
  }

  function checkEmployeesDetails(res: any) {
    if (res.error) {
      // setErrCode('115');
      // setErrCode(errorMessage.mobValidateOtp);
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data && res.data._employeedetail) {
        if (Object.keys(res.data._employeedetail).length > 0) {
          const rmCodeName = res.data._employeedetail;
          // setRmCodeAssistedName(rmCodeName?.EmpName ?? rmCodeName.UserName);
          setRmCodeAssistedName(
            rmCodeName?.EmpName == "" ? rmCodeName.UserName : rmCodeName.EmpName
          );
          setUtmCampaignCode(rmCodeName?.EmpCode);
          setSourceNames(rmCodeName?.Source);
          setPlanAsPerSource(rmCodeName.Plan);
          setErrors({ ...errors, rmCodeAssisted: undefined });
          setIsAssistedDataValid(false);
        }
      } else {
        setErrors({
          ...errors,
          rmCodeAssisted: "Please Enter correct RM code",
        });
        setRmCodeAssistedName("");
        setUtmCampaignCode(null);
        setSourceNames("");
        setPlanAsPerSource("");
      }
      setErrCode("");
      setIsLoading(false);
    }
  }

  // function checkEmployeesDetails(res: any) {
  //   if (res.error) {
  //     // setErrCode('115');
  //     // setErrCode(errorMessage.mobValidateOtp);
  //     setErrCode(res.error);
  //     setIsLoading(false);
  //   } else {
  //     if (res.data && res.data._employeedetail) {
  //       if (Object.keys(res.data._employeedetail).length > 0) {
  //         const rmCodeName = res.data._employeedetail;
  //         setRmCodeAssistedName(rmCodeName?.EmpName ?? rmCodeName.UserName);
  //         setUtmCampaignCode(rmCodeName?.EmpCode);
  //         setSourceNames(rmCodeName?.Source);
  //         setErrors({ ...errors, rmCodeAssisted: undefined });
  //         setIsAssistedDataValid(false);
  //       }
  //      }
  //      else {
  //         setErrors({
  //           ...errors,
  //           rmCodeAssisted: errorMsg(errorCode.FE06),
  //         });
  //         setRmCodeAssistedName("");
  //         setUtmCampaignCode(null);
  //         setSourceNames("");
  //       }
  //     }
  //     setErrCode("");
  //     setIsLoading(false);
  //   }
  // }

  function sendOtp() {
    setIsLoading(true);
    //AGetMobileOtp(otpSent, values.mobile);
    AGetOtpNew(
      (res: any) => {
        console.log("MON", res);
        mobileOtpSentNew(res);
      },
      values.mobile,
      "SMS"
    );
  }
  /* .. res?.data?.data !== null &&
        res?.data?.data !== undefined &&
        !!res?.data?.data?.OTPId &&
        res?.data?.data?.OTPId !== "" */
  function otpSent(res: any) {
    console.log(res);
    if (res.data && res.data.Success) {
      setIsLoading(false);
      setShowMobileBtn(false);
      setShowMobileOtp(true);
      setErrCode("");
    } else {
      //setErrCode('114 - Failed to send OTP, please retry.');
      setErrCode(res.error);
    }
  }
  function mobileOtpSentNew(res: any) {
    // console.log(res);

    if (
      res?.data?.data !== null &&
      res?.data?.data !== undefined &&
      !!res?.data?.data?.OTPId &&
      res?.data?.data?.OTPId !== ""
    ) {
      setOtpIds((otpIds) => {
        otpIds.mobileOtpId = res?.data?.data?.OTPId;
        return otpIds;
      });
      setIsLoading(false);
      setShowMobileBtn(false);
      setShowMobileOtp(true);
      setErrCode("");
    } else {
      if (res?.error) {
        setErrCode(res.error);
      } else {
        setErrCode("Failed to send OTP, please retry.");
      }
      setIsLoading(false);
      setShowMobileOtp(false);
    }
    //  else {
    //   //setErrCode('114 - Failed to send OTP, please retry.');
    //   setErrCode(res.error);
    // }
  }

  function validateOtp(otpType: "email" | "mobile", val: string) {
    if (invalidOtp) {
      setInvalidOtp(false);
    }
    if (invalidEmailOtp) {
      setInvalidEmailOtp(false);
    }
    if (val.length === 4) {
      setIsLoading(true);
      if (otpType === "email") {
        setDisableEmailOtp(true);
      } else if (otpType === "mobile") {
        setDisableOtp(true);
      }
      AValidateOtpNew(
        (res: any) => {
          if (otpType === "email") {
            checkEmailValidateOtpNew(res);
          } else {
            checkMobileValidateOtpNew(res);
          }
        },
        otpType == "email" ? otpIds?.emailOtpId : otpIds?.mobileOtpId,
        val
      );
    }

    // if (val.length === 4) {
    //   if (otpType === "email") {
    //     setDisableEmailOtp(true);
    //     AValidateEmailOtp(
    //       (res: any) => checkEmailValidateOtp(res),
    //       values.email,
    //       val
    //     );
    //   } else if (otpType === "mobile") {
    //     setDisableOtp(true);
    //     AValidateMobileOtp(
    //       checkMobileValidateOtp,
    //       values.mobile.toString(),
    //       val
    //     );
    //   }
    // }
  }
  function checkEmailValidateOtpNew(res: any) {
    setDisableEmailOtp(false);
    if (!res || res.error) {
      //setErrCode('102 - Failed to fetch existing Leads');
      // setErrCode(errorMessage.searchLead);
      setErrCode(res.error);
    } else {
      console.log(res, "works");
      if (
        res.data &&
        res?.data?.data &&
        !!res?.data?.success &&
        res?.data?.success == true
      ) {
        GlobalStore.user.email = values.email;
        setIsEmailVerified(true);
        setShowEmailOtp(false);
        setMailVerifiedFE(true);
      } else {
        setInvalidEmailOtp(true);
        // if (res.data && res.data !== "false") {
        //   GlobalStore.user.email = values.email;
        //   setIsEmailVerified(true);
        //   setShowEmailOtp(false);
        //   setMailVerifiedFE(true);
        //   // if (GlobalStore.user.mobile !== values.mobile) {
        //   //   // setShowMobileBtn(true);
        //   // }
        // } else setInvalidEmailOtp(true);
      }

      setErrCode("");
    }
    setIsLoading(false);
  }
  function checkMobileValidateOtpNew(res: any) {
    setIsLoading(false);
    setDisableOtp(false);
    if (res.error) {
      //setErrCode('115 - Failed to validate OTP, please retry.');
      setErrCode(res.error);
    } else {
      if (
        res.data &&
        res?.data?.data &&
        !!res?.data?.success &&
        res?.data?.success == true
      ) {
        const utmObj = JSON.parse(
          window.sessionStorage.getItem("objUTM_Info") || "{}"
        );
        if (
          utmObj &&
          utmObj.utm_source &&
          utmObj.utm_source.toUpperCase() === "PLATFORM"
        ) {
          setShowRmCode(true);
        }
        GlobalStore.user.mobile = values.mobile;
        setIsMobileVerified(true);
        setShowMobileOtp(false);
        setMobileVerifiedFE(true);
      } else {
        setInvalidOtp(true);
      }
      setErrCode("");
      // if (res.data == "true") {
      //   const utmObj = JSON.parse(
      //     window.sessionStorage.getItem("objUTM_Info") || "{}"
      //   );
      //   if (
      //     utmObj &&
      //     utmObj.utm_source &&
      //     utmObj.utm_source.toUpperCase() === "PLATFORM"
      //   ) {
      //     setShowRmCode(true);
      //   }
      //   GlobalStore.user.mobile = values.mobile;
      //   setIsMobileVerified(true);
      //   setShowMobileOtp(false);
      //   setMobileVerifiedFE(true);
      // } else setInvalidOtp(true);
    }
  }
  function checkEmailValidateOtp(res: any) {
    setDisableEmailOtp(false);
    if (!res || res.error) {
      //setErrCode('102 - Failed to fetch existing Leads');
      // setErrCode(errorMessage.searchLead);
      setErrCode(res.error);
    } else {
      console.log(res, "works");
      if (res.data && res.data !== "false") {
        GlobalStore.user.email = values.email;
        setIsEmailVerified(true);
        setShowEmailOtp(false);
        setMailVerifiedFE(true);
        // if (GlobalStore.user.mobile !== values.mobile) {
        //   // setShowMobileBtn(true);
        // }
      } else setInvalidEmailOtp(true);
      setErrCode("");
    }
    setIsLoading(false);
  }
  function checkMobileValidateOtp(res: any) {
    setIsLoading(false);
    setDisableOtp(false);
    if (res.error) {
      //setErrCode('115 - Failed to validate OTP, please retry.');
      setErrCode(res.error);
    } else {
      if (res.data == "true") {
        const utmObj = JSON.parse(
          window.sessionStorage.getItem("objUTM_Info") || "{}"
        );
        if (
          utmObj &&
          utmObj.utm_source &&
          utmObj.utm_source.toUpperCase() === "PLATFORM"
        ) {
          setShowRmCode(true);
        }
        GlobalStore.user.mobile = values.mobile;
        setIsMobileVerified(true);
        setShowMobileOtp(false);
        setMobileVerifiedFE(true);
      } else setInvalidOtp(true);
      setErrCode("");
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setIsButtonDisabled(true);
    if (Object.values(values).every((value) => value)) {
      setIsLoading(true);

      GlobalStore.user = {
        firstname: values.firstname,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
        city: values.city,
        // rmCode: rmCode
      };
      if (whatsappMsgSuccess) {
        searchLeadByName();
      } else {
        const msgReq = {
          type: "optin",
          recipients: [
            {
              recipient: values.mobile,
              source: "edel-web",
              user_agent: navigator.appName,
            },
          ],
        };

        AWhatsappNotify(whatsappNotify, msgReq);
      }
    }
  }

  function whatsappNotify(res: any) {
    if (res && res.status) {
      // setErrCode('101 - Failed to provide consent for WhatsApp Notifications.');
      // setErrCode(errorMessage.whatsAppNotify);
      // setErrCode("here is the error");

      // changes code
      // setWhatsappMsgSuccess(false);
      setIsLoading(false);
      setWhatsappMsgSuccess(true);
      searchLeadByName();
    } else {
      setIsLoading(false);
      setWhatsappMsgSuccess(true);
      searchLeadByName();
      // if (!res || res.error) {
      //   setErrCode(res.error);
      // }
    }
    //  else {
    //   setWhatsappMsgSuccess(true);
    //   searchLeadByName();
    // }
  }

  function getRelationshipData(res: any) {
    let availableMobileTagSum: any;
    let availableEmailTagSum: any;
    setIsLoading(true);
    if (!res || res.error) {
      // setErrCode('132 - Failed to fetch relationship data');
      //   setErrCode(errorMessage.getRelationshipData);
      setErrCode(res.error);
    } else {
      if (res.data) {
        console.log("count log", res.data);
        if (
          res.data.emailMatchedList.length !== 0 ||
          res.data.mobileMatchedList.length !== 0
        ) {
          let AvailableRelationshipTagEmail: object =
            res.data.AvailableRelationshipTagEmail;
          let AvailableRelationshipTagMobile: object =
            res.data.AvailableRelationshipTagMobile;
          //logic for sum
          availableEmailTagSum = Object.values(
            AvailableRelationshipTagEmail
          ).reduce((a: number, b: number) => a + b, 0);
          availableMobileTagSum = Object.values(
            AvailableRelationshipTagMobile
          ).reduce((a: number, b: number) => a + b, 0);
          //logic for sum end
          if (availableMobileTagSum === 0 || availableEmailTagSum === 0) {
            // console.log("here");
            setRelationShipTagError(errorMsg(errorCode.BE384));
          }
        }
      } else {
        // setErrCode('132 - Failed to fetch relationship data');
        setErrCode(res.error);
      }
    }
    setIsLoading(false);
  }
  React.useEffect(() => {
    if (isEmailVerified && isMobileVerified) setIsVerified(true);
  }, [isEmailVerified, isMobileVerified]);

  function searchLeadByName() {
    const objAccType =
      window.sessionStorage.getItem("objAccType") || productCode;
    const viewLeadObj = {
      assignedTo: assignedTo,
      emailId: values.email,
      firstName: values.firstname,
      lastName: values.lastName,
      mobile: values.mobile,
      productCode: objAccType === "DMT" ? "D" : "TD",
    };
    window.localStorage.setItem(
      "clientName",
      values.firstname.trim() + " " + values.lastName.trim()
    );
    GlobalStore.LeadIdMsg = "";

    ASearchLeadByName(searchedLead, viewLeadObj);
    console.log("viewLeadObj", viewLeadObj);
  }

  React.useEffect(() => {
    const objAccType =
      window.sessionStorage.getItem("objAccType") || productCode;
    const viewLeadObj = {
      assignedTo: assignedTo,
      emailId: values.email,
      firstName: values.firstname,
      lastName: values.lastName,
      mobile: values.mobile,
      productCode: objAccType === "DMT" ? "D" : "TD",
    };
    window.localStorage.setItem(
      "clientName",
      values.firstname.trim() + " " + values.lastName.trim()
    );
    GlobalStore.LeadIdMsg = "";
    values.mobile &&
      values.email &&
      ASearchLeadByName(searchedLeadCheck, viewLeadObj);
  }, [email_mobile_verificationCheckFlag]);

  React.useEffect(() => {
    if (allreadyExistMobOrEmail.email || allreadyExistMobOrEmail.mob) {
      setIsButtonDisabled(false);

      if (allreadyExistMobOrEmail.mob) {
        setShowMobileBtn(true);

        setIsMobileVerified(false);
      } else if (allreadyExistMobOrEmail.email) {
        setShowMobileMailBtn(true);

        setIsEmailVerified(false);
      }
    }
  }, [allreadyExistMobOrEmail]);

  function searchLeadByName_Check() {
    setEmail_mobile_verificationCheckFlag(true);
  }

  function searchedLeadCheck(res: any) {
    if (email_mobile_verificationCheckFlag) {
      console.log("lead logger 4-", email_mobile_verificationCheckFlag);
      if (!res || res.error) {
        console.log("lead logger 5-");
        //setErrCode('102 - Failed to fetch existing Leads');
        // setErrCode(errorMessage.searchLead);
        setErrCode(res.error);
        setAllreadyExistMobOrEmail({
          ...res?.existedFieldDetails,
        });
      } else if (email_mobile_verificationCheckFlag) {
        console.log("lead logger 6-");
        setErrCode("");
        if (res.data) {
          console.log("lead logger 7");
          let responseObj = JSON.parse(res.data);
          console.log("SEARCHLEAD", responseObj);
          if (
            responseObj &&
            responseObj.leadList &&
            responseObj.leadList.length > 0
          ) {
            console.log("lead logger 8");
            GlobalStore.user.UCC_Code = responseObj.leadList[0].UCCCode;
            if (responseObj.leadList[0].IsMobileVerified) {
              setIsMobileVerified(true);
              GlobalStore.user.mobile = values.mobile;
            } else {
              setIsMobileVerified(false);
            }

            if (responseObj.leadList[0].IsEmailVerified) {
              setIsEmailVerified(true);
              GlobalStore.user.email = values.email;
            } else {
              setIsEmailVerified(false);
            }
            if (
              responseObj.leadList[0].ApplicationId.slice(0, 2) != "80" &&
              responseObj.leadList[0].ApplicationId !== null
            ) {
              console.log("lead logger 3");
              GlobalStore.leadId = responseObj.leadList[0].LeadId;
              GlobalStore.LeadIdMsg = "duplicateLeadId";

              // dudpe implemetation from here //

              const objUtm = JSON.parse(
                window.sessionStorage.getItem("objUTM_Info") || "{}"
              );
              const objAccType =
                window.sessionStorage.getItem("objAccType") || productCode;
              GlobalStore.createLeadDudupeParam = null;

              const createLeadObj = {
                assignedTo: responseObj.leadList[0].AssignTeam,
                byPassDedupe: false,
                city: values.city,
                email: values.email,
                firstName: values.firstname,
                lastName: values.lastName,
                phone: values.mobile,
                productCode: objAccType === "DMT" ? "D" : "TD",
                rmCode: rmCode,
                // sourceId: sourceId,
                // subSourceId: subSourceId,
                utm_adgroup: objUtm.utm_adgroup || "",
                utm_campaign: objUtm.utm_campaign || "",
                sourceName: objUtm.utm_source || "",
                utm_content: objUtm.utm_content || "",
                utm_creative: objUtm.utm_creative || "",
                utm_device: objUtm.utm_device || "",
                utm_medium: objUtm.utm_medium || "",
                utm_placement: objUtm.utm_placement || "",
                utm_source: objUtm.utm_source || "",
                subSourceName: objUtm.utm_campaign || "",
                utm_term: objUtm.utm_term || "",
              };
              GlobalStore.createLeadDudupeParam = createLeadObj;

              // New lead is created. We need to register with clevertap event
              const data = {
                EventName:
                  cleverTapEvents.registration.personalDetailsPage
                    .CLICK_ON_PROCEED,
                UTM_SOURCE,
                UTM_MEDIUM,
                UTM_CAMPAIGN,
              };
              ClevertapService.pushEvent(data);
              // CleverTap event end

              // Apps flyer event for click on proceed on personal details page
              AppsFlyerService.pushEvent(
                appsFlyerEvents.registration.personalDetailsPage
                  .CLICK_ON_PROCEED
              );

              // ends//
              window.localStorage.removeItem("clientPartialDetails_ao");
              gtmService({ action: "LEAD_PAGE", label: "Step_Complete" });
              props.history.push(containerUrl.pan);
            }
          }
          if (responseObj.leadList.length == 0) {
            setIsMobileVerified(false);
            setIsEmailVerified(false);
          }
        }
        if (email_mobile_verificationCheckFlag) {
          setEmail_mobile_verificationCheckFlag(false);
        }
      }
    }
  }
  const checkRAAccountResp = (resp: any) => {
    if (resp == null || resp == undefined) {
      setHideAreBeingSection(false);
    } else {
      if (!resp || resp.error) {
        setHideAreBeingSection(false);
        setErrCode(resp.error);
      } else {
        if (resp && resp?.data) {
          if (
            resp?.data &&
            resp?.data?.IsRAExists !== null &&
            resp?.data?.IsRAExists !== undefined &&
            resp?.data?.IsRAExists
          ) {
            setHideAreBeingSection(true);
          } else {
            setHideAreBeingSection(false);
          }
        } else {
          setHideAreBeingSection(false);
        }
      }
    }
  };

  React.useEffect(() => {
    if (isMobileVerified && isEmailVerified && values.email && values.mobile) {
      AGetRelationshipData(
        getRelationshipData,
        "N/A",
        values.email,
        values.mobile.toString()
      );
      ACheckRAAccount(checkRAAccountResp, {
        EmailId: values.email,
        MobileNo: values.mobile,
        PanNo: "",
      });
    }
  }, [isMobileVerified, isEmailVerified]);
  const handleCreateUcc = () => {
    let reqObj = {
      mobile: values.mobile,

      email: values.email,

      oldMobile: "",

      oldEmail: "",

      leadId: GlobalStore.leadId,

      isMobileVerify: mobileVerifiedFE,

      isEmailVerify: mailVerifiedFE,

      firstName: values.firstname,

      lastName: values.lastName,
    };

    if (!GlobalStore.user.UCC_Code) {
      CreateUCCRegCode((req: any) => {
        props.history.push(containerUrl.pan);
      }, reqObj);
    }
  };
  function searchedLead(res: any) {
    setSkipCreateLead(false);
    if (!res || res.error) {
      //setErrCode('102 - Failed to fetch existing Leads');
      // setErrCode(errorMessage.searchLead);
      setAllreadyExistMobOrEmail({
        ...res.existedFieldDetails,
      });

      setErrCode(res.error);

      setIsLoading(false);
    } else {
      setErrCode("");
      if (res.data) {
        let responseObj = JSON.parse(res.data);
        if (
          responseObj &&
          responseObj.leadList &&
          responseObj.leadList.length > 0
        ) {
          console.log("lead logger 8");
          GlobalStore.leadId = responseObj.leadList[0].LeadId;
          if (responseObj.leadList[0].UCCCode) {
            GlobalStore.user.UCC_Code = responseObj.leadList[0].UCCCode;
          } else {
            handleCreateUcc();
          }
          if (responseObj.leadList[0].ApplicationId.slice(0, 2) != "80") {
            console.log("lead logger 3");
            GlobalStore.leadId = responseObj.leadList[0].LeadId;
            GlobalStore.LeadIdMsg = "duplicateLeadId";

            if (responseObj.leadList[0].IsMobileVerified) {
              setIsMobileVerified(true);
            }

            if (responseObj.leadList[0].IsEmailVerified) {
              setIsEmailVerified(true);
            }

            // dudpe implemetation from here //

            const objUtm = JSON.parse(
              window.sessionStorage.getItem("objUTM_Info") || "{}"
            );
            const objAccType =
              window.sessionStorage.getItem("objAccType") || productCode;
            GlobalStore.createLeadDudupeParam = null;

            // let sourceId = 0;
            // let subSourceId = 1;
            // if (objUtm.utm_source) {
            //     const res = getLeadId(objUtm.utm_campaign);
            //     sourceId = res ? res.sourceId : 0;
            //     subSourceId = res ? res.subSourceId : 1;
            // }
            // console.log('WWWWWWWWWWWWWDDDDDLLL', sourceId, subSourceId, objUtm.utm_source);

            const createLeadObj = {
              assignedTo: responseObj.leadList[0].AssignTeam,
              byPassDedupe: false,
              city: values.city,
              email: values.email,
              firstName: values.firstname,
              lastName: values.lastName,
              phone: values.mobile,
              productCode: objAccType === "DMT" ? "D" : "TD",
              rmCode: rmCode,
              // sourceId: sourceId,
              // subSourceId: subSourceId,
              utm_adgroup: objUtm.utm_adgroup || "",
              utm_campaign: objUtm.utm_campaign || "",
              sourceName: objUtm.utm_source || "",
              utm_content: objUtm.utm_content || "",
              utm_creative: objUtm.utm_creative || "",
              utm_device: objUtm.utm_device || "",
              utm_medium: objUtm.utm_medium || "",
              utm_placement: objUtm.utm_placement || "",
              utm_source: objUtm.utm_source || "",
              subSourceName: objUtm.utm_campaign || "",
              utm_term: objUtm.utm_term || "",
            };
            GlobalStore.createLeadDudupeParam = createLeadObj;

            // New lead is created. We need to register with clevertap event
            const data = {
              EventName:
                cleverTapEvents.registration.personalDetailsPage
                  .CLICK_ON_PROCEED,
              UTM_SOURCE,
              UTM_MEDIUM,
              UTM_CAMPAIGN,
            };
            ClevertapService.pushEvent(data);
            // CleverTap event end

            // Apps flyer event for click on proceed on personal details page
            AppsFlyerService.pushEvent(
              appsFlyerEvents.registration.personalDetailsPage.CLICK_ON_PROCEED
            );

            // ends//
            window.localStorage.removeItem("clientPartialDetails_ao");
            gtmService({ action: "LEAD_PAGE", label: "Step_Complete" });
            props.history.push(containerUrl.pan);
          } else if (!email_mobile_verificationCheckFlag) {
            createLead();
            // else {
            //   console.log("load logger 10");
            //   // let foundLeadObject = {
            //   //   Response: "Success",
            //   //   ResponseDescription: "Lead Created Successfully",
            //   //   LeadId: responseObj.leadList[0].LeadId,
            //   //   TaskId: "6421813457de712a0835455b",
            //   //   Exist: "Y",
            //   // };
            //   setSkipCreateLead(true);
            // }
          }
        } else {
          console.log("lead logger-- 2", email_mobile_verificationCheckFlag);
          if (!email_mobile_verificationCheckFlag) createLead();
          else if (
            !res?.existedFieldDetails?.mob &&
            !res?.existedFieldDetails?.email
          ) {
            console.log("lead logger 11");
            setSkipCreateLead(true);
          }
        }
      }
    }
    console.log("lead logger 12", isMobileVerified, "||", isEmailVerified);
    if (!isMobileVerified && !isEmailVerified) createLead();
    else {
      console.log("lead logger 13");
      setSkipCreateLead(true);
    }

    //CleverTap log
    // var data = {
    //     CurrentPage: '/Registration',
    //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
    //     EventName: 'email_stage_success',
    //     CTA: 'Confirm',
    //     FirstName: values.firstname,
    //     LastName: values.lastName
    // };
    // clevertap.event.push(data.EventName, data);
  }

  React.useEffect(() => {
    if (
      skipCreateLead &&
      values.mobile &&
      values.city &&
      values.email &&
      values.firstname &&
      values.lastName &&
      !errCode
    ) {
      console.log("lead logger 14");
      createLead();
    }
  }, [skipCreateLead]);

  function createLead() {
    const objUtm = JSON.parse(
      window.sessionStorage.getItem("objUTM_Info") || "{}"
    );
    const objAccType =
      window.sessionStorage.getItem("objAccType") || productCode;
    GlobalStore.createLeadDudupeParam = null;

    let utm_source = objUtm.utm_source || "";
    let utm_content = objUtm.utm_content || "";
    let utm_campaign = objUtm.utm_campaign || "";
    let isReferral = false;
    let sourceName = "";
    let subSourceName = "";

    // Check if we user entered RM code
    if (beingAssistedCheckbox && rmCodeAssisted) {
      utm_source = sourceNames;
      utm_content = planAsPerSource;
      utm_campaign = utmCampaignCode ? utmCampaignCode.toString() : "";
      isReferral = true;
      sourceName = sourceNames;
      subSourceName = utmCampaignCode ? utmCampaignCode.toString() : "";
      window.sessionStorage.setItem(
        "objUTM_Info",
        JSON.stringify({
          ...objUtm,
          utm_source,
          utm_content,
          utm_campaign,
        })
      );
    }

    // let sourceId = 0;
    // let subSourceId = 1;

    // if (objUtm.utm_campaign) {
    //     const res = getLeadSourceId(objUtm.utm_campaign);
    //     sourceId = res ? res.sourceId : 0;
    //     subSourceId = res ? res.subSourceId : 1;
    // }

    let createLead = {
      assignedTo: isReferral ? "0" : assignedTo,
      byPassDedupe: false,
      city: values.city,
      email: values.email,
      firstName: values.firstname,
      lastName: values.lastName,
      phone: values.mobile,
      productCode: objAccType === "DMT" ? "D" : "TD",
      rmCode: rmCode,
      // sourceId: sourceId,
      // subSourceId: subSourceId,
      sourceName: sourceName || objUtm.utm_source,
      utm_adgroup: objUtm.utm_adgroup || "",
      utm_campaign,
      utm_content,
      utm_creative: objUtm.utm_creative || "",
      utm_device: objUtm.utm_device || "",
      utm_medium: objUtm.utm_medium || "",
      utm_placement: objUtm.utm_placement || "",
      utm_source,
      subSourceName: subSourceName || objUtm.utm_campaign,
      utm_term: objUtm.utm_term || "",
    };

    // ACreateLead((res: any) => leadCreated(res, createLead), createLead);
    if (!skipCreateLead) {
      ACreateLeadSource((res: any) => leadCreated(res, createLead), createLead);
      // CreateNewUCC=false;
    } else {
      //CreateNewUCC=true;
      console.log("CreateNewUCC 1", CreateNewUCC);
      leadCreated({ LeadId: GlobalStore.leadId }, createLead);
    }
  }

  function leadCreated(res: any, leadObj: any) {
    let respData;
    // console.log(res, "leadCreated res");
    if (!res?.status) {
      // console.log("RES::", res, "||", JSON.parse(res?.data));
      respData = res.error;
    }

    setIsLoading(false);
    if (!res) {
      //setErrCode('103 - Error in generating new Lead.');
      // setErrCode(errorMessage.leadCreated);
      setErrCode(res.error);
    } else if (respData) {
      setErrCode(respData);
    } else {
      if ((res && res.data) || res.LeadId) {
        console.log("here 1", skipCreateLead);
        // New lead is created. We need to register with clevertap event
        const data = {
          EventName:
            cleverTapEvents.registration.personalDetailsPage.CLICK_ON_PROCEED,
          UTM_SOURCE,
          UTM_MEDIUM,
          UTM_CAMPAIGN,
        };
        ClevertapService.pushEvent(data);
        // CleverTap event end
        let responseObj;
        if (!skipCreateLead) {
          console.log("here 2");
          responseObj = JSON.parse(res.data);
        }
        if ((responseObj && responseObj.LeadId) || res.LeadId) {
          console.log("here 3");
          if (!skipCreateLead) {
            console.log("here 4");
            GlobalStore.leadId = responseObj.LeadId;
            GlobalStore.createLeadDudupeParam = leadObj;
          }
          setErrCode("");
          window.localStorage.removeItem("clientPartialDetails_ao");
          gtmService({ action: "LEAD_PAGE", label: "Step_Complete" });
          //   let reqObj = {
          //     mobile: values.mobile,
          //     email: values.email,
          //     oldMobile: "",
          //     oldEmail: "",
          //     leadId: GlobalStore.leadId,
          //     isMobileVerify: mobileVerifiedFE,
          //     isEmailVerify: mailVerifiedFE,
          //     firstName: values.firstname,
          //     lastName: values.lastName,
          //   };
          //   if(CreateNewUCC && (GlobalStore.user.UCC_Code=="" || GlobalStore.user.UCC_Code==null  || GlobalStore.user.UCC_Code==undefined))
          //   {
          //   CreateUCCRegCode((req: any) => {
          //     props.history.push(containerUrl.pan);
          //   }, reqObj);
          // }
          props.history.push(containerUrl.pan);
        } else {
          // setErrCode('Technical Issue - Error in generating new Lead.');
          setErrCode(res.error);
        }
      } else {
        // setErrCode('104 - Error in generating new Lead.');
        setErrCode(res.error);
      }
    }
  }
  window.onload = () => {
    localStorage.setItem("currentURL", window.btoa(window.location.href));
  };
  function userLogin() {
    gtmService({ action: "LEAD_PAGE", label: "LOGIN" });
    window.location.href = loginUrl;
  }

  const checkStatus = () => {
    props.history.push(statusUrl.statusHome);
  };

  function handleRmCodeCheckBox() {
    const currentRmCheckBoxStatus = !beingAssistedCheckbox;
    setRmCodeAssisted("");
    setRmCodeAssistedName("");
    setErrors({ ...errors, rmCodeAssisted: undefined });
    setUtmCampaignCode(null);
    setBeingAssistedCheckbox(currentRmCheckBoxStatus);
    setIsAssistedDataValid(currentRmCheckBoxStatus);
    setSourceNames("");
  }
  function sendEmailOtp() {
    setIsLoading(true);
    let emlObj = {
      mailBody: emailVerificationBody.mailBody,
      mailFrom: emailVerificationBody.mailFrom,
      mailSource: emailVerificationBody.mailSource,
      mailSubject: emailVerificationBody.mailSubject,
      mailTo: values.email,
    };
    //   AGetEmailOtp((res: any) => emailOtpRes(res), emlObj);
    AGetOtpNew(
      (res: any) => {
        emailOtpResNew(res);
      },
      values.email,
      "EMAIL"
    );
    // AGetMobileOtp(emailOtpSent, '9770308828');
  }
  function emailOtpResNew(res: any) {
    if (res.data && res.status) {
      if (
        res?.data?.data !== null &&
        res?.data?.data !== undefined &&
        !!res?.data?.data?.OTPId &&
        res?.data?.data?.OTPId !== ""
      ) {
        setOtpIds((otpIds) => {
          otpIds.emailOtpId = res?.data?.data?.OTPId;
          return otpIds;
        });
        setShowEmailOtp(true);
        setShowMobileMailBtn(false);
        setErrCode("");
      } else {
        setErrCode("Failed to send OTP, please retry.");
      }
    } else {
      // setErrCode('114 - Failed to send OTP, please retry.');
      setErrCode(res.error);
    }
    setIsLoading(false);
  }
  function emailOtpRes(res: any) {
    if (res.data && res.status) {
      setShowEmailOtp(true);
      setShowMobileMailBtn(false);
      setErrCode("");
    } else {
      // setErrCode('114 - Failed to send OTP, please retry.');
      setErrCode(res.error);
    }
    setIsLoading(false);
  }

  // console.log(
  //   "lead logger - disable",
  //   Object.values(errors).some((error) => error),
  //   "||",
  //   Object.values(values).some((value) => !value),
  //   "||",
  //   GlobalStore.user.mobile !== values.mobile,
  //   "||",
  //   GlobalStore.user.mobile === "",
  //   "||",
  //   GlobalStore.user.email !== values.email,
  //   "||",
  //   GlobalStore.user.email === "",
  //   "||",
  //   !isMobileVerified,
  //   "||",
  //   !isEmailVerified,
  //   "||",
  //   isAssistedDataValid
  // );

  // console.log(
  //   "lead logger - '",
  //   Object.values(errors).some((error) => error) ||
  //     Object.values(values).some((value) => !value) ||
  //     GlobalStore.user.mobile !== values.mobile ||
  //     GlobalStore.user.mobile === "" ||
  //     GlobalStore.user.email !== values.email ||
  //     GlobalStore.user.email === "" ||
  //     isAssistedDataValid ||
  //     isVerified
  // );

  console.log("skipLead", isMobileVerified, "||", isEmailVerified);

  return (
    <div className={"registrationForm" + (isLoading ? " op05" : "")}>
      <form>
        <fieldset className="regForm" disabled={isLoading}>
          <div className="checkStatus">
            <label className="statusLink" onClick={checkStatus}>
              Click here to know your Status
            </label>
          </div>
          {/* Name Start*/}
          <div className="row">
            {/*First Name*/}
            <div className="inlineDiv name">
              <label className="inptLbl">First Name</label>
              <InputField
                required
                // pattern={betweenNChars(3)}
                onChange={(value) => onChange("firstname", value)}
                onBlur={(error) =>
                  setErrors({
                    ...errors,
                    // firstname: error.required ? 'This field is required' : '',
                    firstname: error.required ? errorMsg(errorCode.FE01) : "",
                  })
                }
                placeholder="Eg. Prateek"
                errorText={errors.firstname}
                type="text"
              />
            </div>
            {/*Last Name*/}
            <div className="inlineDiv name lastName">
              <label className="inptLbl">Last Name</label>
              <InputField
                required
                // pattern={betweenNChars(2)}
                onChange={(value) => onChange("lastName", value)}
                onBlur={(error) =>
                  setErrors({
                    ...errors,
                    // lastName: error.required ? 'This field is required ' : '',
                    lastName: error.required ? errorMsg(errorCode.FE01) : "",
                  })
                }
                placeholder="Eg. Jain"
                errorText={errors.lastName}
                type="text"
              />
            </div>
          </div>
          {/* Name End*/}
          {/* City Start*/}
          <div className="row dd_div">
            <label>City</label>
            <DropdownSearch
              name="city"
              onOptionSelect={(value) =>
                onChange("city", value["CityCode"] as string)
              }
              // onChange={value => onChange("city", value)}
              searchLength={3}
              options={cities}
              onBlur={handleCityBlur}
              searchKey="CityName"
              placeholder="Eg. Mumbai"
              noDataMsg="City with similar name not found"
              errorText={errors.city}
              isDownArrow={false}
              autoSelectOff={true}
              autoComplete={"off"}
            />
          </div>
          {/* City End*/}
          {/* Email Start*/}
          <div className="email">
            <div className="row emlInput">
              <label className="inptLbl">Email ID</label>
              <InputField
                pattern={emailRegex}
                onChange={(value) => onChange("email", value)}
                onBlur={(error) =>
                  setErrors({
                    ...errors,
                    // email: error.pattern ? '' : 'Please enter valid email',
                    email: error.pattern ? "" : errorMsg(errorCode.FE03),
                  })
                }
                placeholder="Eg. example@email.com"
                errorText={errors.email}
                type="email"
                suffix={
                  GlobalStore.user.email === values.email &&
                  values.email !== "" &&
                  isEmailVerified ? (
                    <i className="edel-icon-tick" />
                  ) : (
                    <></>
                  )
                }
                hiddenText="I am aware that my contact details will be used for all future communications by Nuvama, including communications through WhatsApp."
              />
            </div>
            {showMobileMailBtn && !isEmailVerified && (
              <div className="emlVrfy">
                <Verify
                  btnName="Verify"
                  disabled={
                    Object.values([
                      errors.firstname,
                      errors.lastName,
                      errors.city,
                      errors.email,
                      // errors.mobile,
                    ]).some((error) => error) ||
                    Object.values([
                      values.firstname,
                      values.lastName,
                      values.city,
                      values.email,
                      // values.mobile,
                    ]).some((value) => !value)
                  }
                  onClick={() => {
                    gtmService({
                      action: "LEAD_PAGE",
                      label: "MOB_VERIFY",
                    });

                    sendEmailOtp();
                  }}
                />
              </div>
            )}
          </div>
          {showEmailOtp && !showMobileMailBtn ? (
            <div className="otpSection">
              <RegOtp
                label="Enter Email OTP"
                otpTimer={15}
                eleId="eml_"
                onChange={(val) => validateOtp("email", val)}
                resendClicked={sendEmailOtp}
                disableOtp={disableEmailOtp}
                invalidOtp={invalidEmailOtp}
              />
            </div>
          ) : (
            ""
          )}
          {/* <label className='emailInfoMsg'>
                        By entering the email address you confirm to receive all contract notes and other regulatory communication through digital / ECN mode
                    </label> */}
          {/* Email End*/}
          {/* Mobile Phone Start*/}
          <>
            <div className="email">
              <div className="row emlInput">
                <label className="inptLbl">Mobile No.</label>
                <InputField
                  pattern={phoneNo}
                  onChange={(value) => onChange("mobile", value)}
                  onBlur={(error) =>
                    setErrors({
                      ...errors,
                      // mobile: error.pattern ? '' : 'Please enter valid phone number',
                      mobile: error.pattern ? "" : errorMsg(errorCode.FE04),
                    })
                  }
                  placeholder="Eg. 9876543210"
                  errorText={errors.mobile}
                  type="tel"
                  suffix={
                    GlobalStore.user.mobile === values.mobile &&
                    values.mobile !== "" &&
                    isMobileVerified ? (
                      <i className="edel-icon-tick" />
                    ) : (
                      <></>
                    )
                  }
                  maxLength={10}
                />
              </div>
              {showMobileBtn && !isMobileVerified && (
                <div className="emlVrfy">
                  <Verify
                    btnName="Verify"
                    disabled={
                      Object.values([
                        errors.firstname,
                        errors.lastName,
                        errors.city,
                        // errors.email,
                        errors.mobile,
                      ]).some((error) => error) ||
                      Object.values([
                        values.firstname,
                        values.lastName,
                        values.city,
                        // values.email,
                        values.mobile,
                      ]).some((value) => !value)
                    }
                    onClick={() => {
                      gtmService({ action: "LEAD_PAGE", label: "MOB_VERIFY" });

                      sendOtp();
                    }}
                  />
                </div>
              )}
            </div>
            {showMobileOtp && !showMobileBtn && (
              <div className="otpSection">
                <RegOtp
                  label="Enter Mobile OTP"
                  otpTimer={15}
                  eleId="mob_"
                  onChange={(val) => validateOtp("mobile", val)}
                  resendClicked={() => {
                    gtmService({ action: "LEAD_PAGE", label: "OTP_RESEND" });
                    sendOtp();
                  }}
                  disableOtp={disableOtp}
                  invalidOtp={invalidOtp}
                />
              </div>
            )}
          </>
          {/* Mobile Phone End*/}
          {isMobileVerified && showRmCode && (
            <>
              <div className="row">
                <label className="rmCode">Have RM/Partner code?</label>
              </div>

              <div className="row les_margin">
                <label className="rmCodeLabel"></label>
                <div className="row les_margin">
                  <InputWithLabel
                    onChange={(value) => onChange("rmCode", value)}
                    label="Enter Code (Optional)"
                    placeholder="Eg. ABC1234"
                    type="text"
                    onBlur={() => {}}
                  />
                </div>
              </div>
            </>
          )}
          {relationShipTagError && (
            <div className="errMsgBlck">
              <ErrMsg>
                <label>{relationShipTagError}</label>
                {/* <label>Please try after sometime (error code:{errCode})</label> */}
              </ErrMsg>
            </div>
          )}
          {/* Error Msg Start*/}
          {errCode && (
            <div className="errMsgBlck">
              <ErrMsg>
                <label>{errCode}</label>
                {/* <label>We are facing some technical issue.</label> */}
                {/* <label>Please try after sometime (error code:{errCode})</label> */}
              </ErrMsg>
            </div>
          )}
          {/* Error Msg End*/}
          {/* Error Msg End*/}
          {isMobileVerified && (
            <>
              {!partner && (
                <>
                  {isMobileVerified &&
                    isEmailVerified &&
                    !hideAreBeingSection && (
                      <div className="row">
                        <Checkbox
                          name="Being"
                          checked={beingAssistedCheckbox}
                          disabled={partner}
                          onChange={(e) => handleRmCodeCheckBox()}
                        >
                          <span className="loginLink">
                            If you are being assisted by nuvama representative
                            or referred by existing account holder
                          </span>
                        </Checkbox>
                      </div>
                    )}
                </>
              )}
              {beingAssistedCheckbox && (
                <>
                  <div className="row">
                    <label className="inptLbl">Enter Code (Optional)</label>
                    <InputField
                      required
                      // pattern={betweenNChars(2)}
                      alphanum
                      onChange={(value) => {
                        setRmCodeAssisted(value);
                        debounce(value);
                      }}
                      onBlur={() => {}}
                      placeholder="Eg. ABC123"
                      errorText={errors.rmCodeAssisted}
                      type="text"
                    />
                  </div>
                  <div className="row">
                    <label className="inptLbl">{rmCodeAssistedName}</label>
                  </div>
                  <div className="row">
                    <p className="inptLbl">
                      Enter the employee / partner / EP / client code to
                      identify the code <br />
                      {/* manager */}
                    </p>
                  </div>
                </>
              )}
              <div className="row">
                <p className="whatsappInfo">
                  By signing up you agree to receive communication on WhatsApp
                </p>
              </div>
              {!(
                Object.values(errors).some((error) => error) ||
                Object.values(values).some((value) => !value) ||
                GlobalStore.user.mobile !== values.mobile ||
                GlobalStore.user.mobile === "" ||
                GlobalStore.user.email !== values.email ||
                GlobalStore.user.email === "" ||
                isAssistedDataValid
              ) && isVerified ? (
                <div className="row les_margin">
                  <Button
                    onClick={handleSubmit}
                    btnName="Proceed"
                    disabled={
                      Object.values(errors).some((error) => error) ||
                      Object.values(values).some((value) => !value) ||
                      GlobalStore.user.mobile !== values.mobile ||
                      GlobalStore.user.mobile === "" ||
                      GlobalStore.user.email !== values.email ||
                      GlobalStore.user.email === "" ||
                      !isMobileVerified ||
                      !isEmailVerified ||
                      isAssistedDataValid ||
                      isButtonDisabled
                    }
                    isLoading={isLoading}
                    type="submit"
                  />
                </div>
              ) : (
                ""
              )}
            </>
          )}
          {/* to stop auto check email and mobile verification
           */}

          {/* {Object.values(errors).some((error) => error) ||
          Object.values(values).some((value) => !value) ||
          GlobalStore.user.mobile !== values.mobile ||
          GlobalStore.user.mobile === "" ||
          GlobalStore.user.email !== values.email ||
          GlobalStore.user.email === "" ||
          isAssistedDataValid ? (
            <div className="row les_margin">
              <Button
                onClick={searchLeadByName_Check}
                btnName="Proceed with verification"
                disabled={
                  Object.values(errors).some((error) => error) ||
                  Object.values(values).some((value) => !value) ||
                  isAssistedDataValid ||
                  !values.firstname ||
                  !values.lastName ||
                  !values.city ||
                  !values.email ||
                  !values.mobile
                }
                isLoading={isLoading}
              />
            </div>
          ) : (
            ""
          )} */}
          <div className="row hvAcc">
            <label className="alreadyAccountLabel">
              Already have an account?{" "}
            </label>
            <label className="loginLink" onClick={userLogin}>
              Login
            </label>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default RegistrationForm;
