import React, { useState } from "react";
import ChatText from "../UI/ChatText/ChatText";

const InstanHelp = (props: any) => {
  // Fetch query params for clervertap

  return (
    <div>
      {props.children}
      <ChatText panPageFlag={props.panPageFlag} />
    </div>
  );
};

export default InstanHelp;
