export function compareValues(key: string, order: string = "asc") {
	return function innerSort(a: any, b: any) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			// property doesn't exist on either object
			return 0;
		}

		const varA = isNaN(parseFloat(a[key])) ? a[key].toUpperCase() : parseFloat(a[key]);
		const varB = isNaN(parseFloat(a[key])) ? b[key].toUpperCase() : parseFloat(b[key]);

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === "desc" ? comparison * -1 : comparison;
	};
}
