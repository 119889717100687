import * as React from 'react';
import './NriDetailForm.scss'
import NriDetail from './NriDetail/NriDetail';
import { nriIdType } from '../../utils/constants';
import { IListObj } from '../../types/genericTypes';

export interface NriDetailFormProps {
    countryList: Array<IListObj>
    nriDetailsObj: INriDetailField;
    onChangeNriDetails: (obj: INriDetailField) => void;
    errors: { [key: string]: string | undefined };
    setErrors: (obj: { [key: string]: string | undefined }) => void
}

export interface INriDetailField {
    country: string | undefined;
    idType?: string | undefined;
    idTypeOther?: string | undefined;
    idTypeValue: string | undefined;
}

export const initialNriDetail = {
    country: '',
    idTypeOther: '',
    idType: nriIdType[0].value as string,
    idTypeValue: ''
}

const NriDetailForm: React.FC<NriDetailFormProps> = (props) => (
    <div className='nriDetailForm'>
        <label className='nriInfo'>Please indicate ALL countries in which you are a resident for tax purposes and the associated Tax ID Number</label>
        <NriDetail
            countryList={props.countryList}
            nriDetailsObj={props.nriDetailsObj}
            onChangeNriDetails={props.onChangeNriDetails}
            errors={props.errors}
            setErrors={props.setErrors}
        />
    </div>
);

export default NriDetailForm;