import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";
import { xhrApiService } from "../xhrApiServices";

export const AUploadDocument = (
  callback: CallableFunction,
  uploadObj: any,
  onProgress?: any
) => {
  const docReqPayLoad: any = {
    ApplicationId: uploadObj.applicationid,
    FileName: uploadObj.filename,
    Base64Document: uploadObj.base64document,
  };
  if (uploadObj?.DocType !== null && uploadObj?.DocType !== undefined) {
    docReqPayLoad.DocType = uploadObj.DocType;
  }
  if (
    uploadObj?.QltyByPassFlag !== null &&
    uploadObj?.QltyByPassFlag !== undefined
  ) {
    docReqPayLoad.QltyByPassFlag = uploadObj.QltyByPassFlag;
  }
  if (
    uploadObj.filename.includes("_Photo") ||
    uploadObj.filename === "personalPhoto.jpeg"
    // ||uploadObj.filename.includes("Signature")
  ) {
    docReqPayLoad.QltyByPassFlag = 1;
  }

  xhrApiService(
    {
      url: "DIYAPI/UploadDocumentToS3",
      body: docReqPayLoad,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      isNewUrl: true,
      tempToken: config.tempToken,
      serviceName: servicesList.uploadDocumentToS3,
    },
    callback,
    onProgress
  );

  // xhrApiService(
  //     {
  //         url: 'eco/upload-document',
  //         body: uploadObj,
  //     },
  //     callback,
  //     onProgress
  // );
};

export const AGetBrokerage = (callback: CallableFunction, appID: string) => {
  apiService(
    {
      url: "DIYAPI/GetBrokerageDetails",
      body: {
        ApplicationId: appID,
        Category: "3",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBrokerage,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-brokerage",
  //     body: {
  //       applicationid: appID,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetBrokerageDetails = (
  callback: CallableFunction,
  appID: string
) => {
  apiService(
    {
      url: "DIYAPI/GetBrokerageDetails",
      body: {
        ApplicationId: appID,
        Category: "1",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBrokerageDetails,
    },
    callback
  );
  //   apiService(
  //     {
  //       url: "eco/get-brokerage-details",
  //       body: {
  //         applicationid: appID,
  //       },
  //       method: "POST",
  //     },
  //     callback
  //   );
};

export const ASaveBrokerageDetails = (
  callback: CallableFunction,
  saveObj: object
) => {
  apiService(
    {
      url: "DIYAPI/SaveBrokerageDetails",
      body: saveObj,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveBrokerageDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-brokerage-details",
  //     body: saveObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ASaveBrokerageSegmentDetiails = (
  callback: CallableFunction,
  saveSegObj: object
) => {
  apiService(
    {
      url: "DIYAPI/SaveBrokerageSegmentDetails",
      body: saveSegObj,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveBrokerageSegmentDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-brokeragesegment-details",
  //     body: saveSegObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetBrokerageSegmentDetails = (
  callback: CallableFunction,
  appID: string
) => {
  apiService(
    {
      url: "DIYAPI/GetBrokerageDetails",
      body: {
        ApplicationId: appID,
        Category: "2",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBrokerageSegmentDetails,
    },
    callback
  );
  //   apiService(
  //     {
  //       url: "eco/get-brokeragesegment-details",
  //       body: {
  //         applicationid: appID,
  //       },
  //       method: "POST",
  //     },
  //     callback
  //   );
};
