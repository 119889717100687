import * as React from "react";
import "./ExitScreen.scss";
import ProfileImage from "../ProfileImage/ProfileImage";
import { IListObj } from "../../types/genericTypes";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import DeviceContext from "../../Services/identifyDevice";
import { isSafariBrowser } from "../../Services/globalService";
import { downloadForm } from "../../POA/Poa";
import GlobalStore from "../../store/globalStore";
import gtmService from "../../Services/gtmService";
import { loginUrl } from "../../../components/utils/constants";

export interface ExitScreenProps {
  heading: string;
  info?: string;
  time?: string;
  displayInfoList: Array<IListObj>;
  logo: string;
}

const ExitScreen: React.FC<ExitScreenProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  function getStarted() {
    gtmService({ action: "FINAL_STATUS", label: "Get_Started" });
    if (DeviceContext().isDesktop) {
      window.location.href = loginUrl + "user-guide/web";
    } else {
      if (isSafariBrowser()) {
        window.location.href =
          "https://app.appsflyer.com/id1031657545?pid=newEW";
      } else {
        window.location.href = "https://edel.onelink.me/3126852515/a4df843a";
      }
    }
  }

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={isLoading ? " op05" : ""}>
      <ProfileImage className="prflImageBox">
        <img src={props.logo} alt="" className="pfImg" />
      </ProfileImage>
      <div className="finalForm">
        <label className="heading">{props.heading}</label>
        <label className="info">{props.info}</label>
        {props.time && (
          <label className="info">
            You took {props.time.toString()} to submit the application
          </label>
        )}
        <div className="card">
          {props.displayInfoList.length > 0 ? (
            props.displayInfoList.map((itemObj, index) => (
              <div className="infoRow" key={index}>
                <label className="infoHeading">{itemObj.display}</label>
                <label className="infoValue">{itemObj.value}</label>
              </div>
            ))
          ) : (
            <Loader size={100} />
          )}
        </div>
        {props.children}
        <div className="startBtn">
          <Button
            btnName="Get Started"
            isLoading={props.displayInfoList.length <= 0}
            onClick={getStarted}
          />
        </div>
        <div className="downloadLink">
          <label className="downloadLbl">You can download your POA form</label>
          <label
            className="hereLbl"
            onClick={() => {
              gtmService({ action: "FINAL_STATUS", label: "Download_form" });
              downloadForm(
                `EsignPoa_${GlobalStore.applicationID}`,
                setIsLoading,
                () => {}
              );
            }}
          >
            here
          </label>
        </div>
      </div>
    </div>
  );
};

export default ExitScreen;
