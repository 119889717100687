const cleverTapEvents = {
    registration: {
        personalDetailsPage: {
            PAGE_LOADED: 'New_Account_Opening | Lead_Page | Step_Start',
            CLICK_ON_PROCEED: 'New_Account_Opening | Lead_Page | Step_Complete'
        },
        panPage: {
            PAGE_LOAD: 'New_Account_Opening |  PAN_DOB | Step_Start',
            CLICK_ON_PROCEED: 'New_Account_Opening |  PAN_DOB | Step_Complete'
        },
        digiLockerPage: {
            PAGE_LOAD: 'New_Account_Opening |  DIGI_LOCKER | Step_Start',
            CLICK_ON_CONNECT_TO_DIGILOCKER: 'New_Account_Opening |  DIGI_LOCKER | Step_Complete'
        },
        profilePage: {
            PAGE_LOAD: 'New_Account_Opening |  Profile_Details | Step_Start',
            CLICK_ON_SUBMIT_PROFILE: 'New_Account_Opening |  Profile_Details | Step_Complete'
        },
        bankPage: {
            PAGE_LOAD: 'New_Account_Opening |  Bank_Details | Step_Start',
            CLICK_ON_SUBMIT_BANK_DETAILS: 'New_Account_Opening |  Bank_Details | Step_Complete'
        },
        planPage: {
            PAGE_LOAD: 'New_Account_Opening |  Plan_Segment | Step_Start',
            CLICK_ON_SUBMIT_SELECTED_PLAN: 'New_Account_Opening |  Plan_Segment | Step_Complete'
        },
        investmentPage: {
            PAGE_LOAD: 'New_Account_Opening |  Investment_Setup | Step_Start',
            CLICK_ON_SUBMIT: 'New_Account_Opening |  Investment_Setup | Step_Complete'
        },
        documentVerificationPage: {
            PAGE_LOAD: 'New_Account_Opening |  Document_Verification | Step_Start',
            CLICK_ON_SUBMIT: 'New_Account_Opening |  Document_Verification | Step_Complete'
        },
        ipvPage: {
            PAGE_LOAD: 'New_Account_Opening |  Video_IPV | Step_Start',
            CLICK_ON_SUBMIT: 'New_Account_Opening |  Video_IPV | Step_Complete'
        },
        eSignPage: {
            PAGE_LOAD: 'New_Account_Opening |  E-Sign | Step_Start',
            CLICK_ON_ESIGN: 'New_Account_Opening |  E-Sign | Step_Complete'
        },
        agentVisit: {
            PAGE_LOAD: 'New_Account_Opening |  Agent_Visit | Step_Start',
            CLICK_ON_SUBMIT: 'New_Account_Opening |  Agent_Visit | Step_Complete'
        },
        finalPage: {
            PAGE_LOAD: 'New_Account_Opening |  Final_Status | Step_Start'
        }
    }
}
export default cleverTapEvents;