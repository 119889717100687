/**
 * This function adds a \ character before all the characters that need to be escaped
 * @param str string which needs to be changed
 */
function escapeRegexStr(str: string) {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$1");
}

/**
 * This regex checks for integers.
 *
 * - Tests true for literals like - `"", "2", "32434325435"`
 * - Tests false for literals like - `"a", "12as", "as23", "123.13"`
 */
export const integerExp = /^\d*$/;

/**
 * This regex checks for alphabets.
 *
 * - Tests true for literals like - `"", "a", "vfsgreftgr"`
 * - Tests false for literals like - `"1", "12as", "as23", "s$ajkd"`
 */
export const alphabetExp = /^[a-zA-Z ]*$/;

/**
 * This regex checks for floating point numbers.
 *
 * If decimalPlaces is 2, then
 * - Tests true for literals like: `"", "1", "45243464", "3131.", "12312.2", "123432.34"`
 * - Tests false for literals like: `"a", "12ad", "as21"`
 *
 * @param decimalPlaces (optional) number of decimal places
 * @returns RegExp for checking floating point numbers
 */
export function floatNumExp(decimalPlaces: number = 2) {
  // if decimalPlaces === 2,
  // exp = ^\d*(?:\.\d{0,2})?$
  let exp = `^\\d*(?:\\.\\d{0,${decimalPlaces}})?$`;
  return new RegExp(exp);
}

/**
 * This regex checks for alphanumeric characters.
 *
 * - Tests true for literals like - `"", "asd", "ASD", "123"`
 * - Tests false for literals like - `"_", "-", "$", ".", " ", [any other non-alphanumeric character]`
 */
export const alphaNumExp = /^[a-zA-Z0-9]*$/;

export const addressAlphaNum = /^[a-zA-Z0-9 ]*$/;

/**
 * This regex checks for emails.
 *
 * - Tests true for literals like - `"", "asd", "ASD", "123"`
 * - Tests false for literals like - `"_", "-", "$", ".", " ", [any other non-alphanumeric character]`
 */
export const emailRegex =
  /^[-_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;

/**
 * This regex checks if given string's length is between min and max characters.
 *
 * @param min min number of characters
 * @param max (optional) max number of characters. If not present then considered as infinity
 * @returns RegExp for checking if given string's length is between min and max characters
 */
export function betweenNChars(min: number, max?: number) {
  return new RegExp("^.{" + min + "," + (max || "") + "}");
}

/**
 * This regex checks if given string's length is between min and max characters.
 *
 * @param min min number of characters
 * @param max (optional) max number of characters. If not present then considered as infinity
 * @returns RegExp for checking if given string's length is between min and max characters
 */
export function blockSpecChar(min: number, max?: number) {
  return new RegExp(
    `(^[a-zA-Z0-9]*[^~_@!\`#$%^&*?"',(){}<>\\[\\]:;|=+\\\\/]{${min},${
      max || ""
    }})+$`
  );
}

/**
 * This regex checks if string has atleast 1 character.
 *
 * - Tests true for literals like - `"A", "ASD", "1A23"`
 * - Tests false for literals like - `"abc", " ", "$", "123"`
 */
export const atleast1UpperChar = /[A-Z]/;

/**
 * This regex checks if string has atleast 1 digit.
 *
 * - Tests true for literals like - `"1", "123", "1A23"`
 * - Tests false for literals like - `"abc", " ", "$dfs", "ABC"`
 */
export const atleast1Digit = /\d/;

/**
 * This regex tests true if string is made from any character other than the mentioned characters.
 * If any mentioned character appears even once, then regex tests false
 *
 * - Tests true for literals like - `"", "$", "34", "saf&dfds", " "`
 * - Tests false for literals like - `"₹", "as₹fa", "DFS", "123"`
 */
export const atleast1SplChar = /[^0-9a-zA-Z]/;

/**
 * This regex tests true if string is made from any character other than the mentioned characters.
 * If any mentioned character appears even once, then regex tests false
 *
 * - Tests true for literals like - `"", "$", "34", "saf&dfds", " "`
 * - Tests false for literals like - `"₹", "as₹fa", "DFS", "123"`
 */
export const exceptSplChar = /^[^()<>?%+:;{}[\]\\|~`^\-‘",./₹]*$/;

/**
 * This regex checks for atleast 1 alphabet and 1 digit characters.
 *
 * - Tests true for literals like - `"a1", "1a", "sdfh23ejsd", "sdfh2-3ej$sd"`
 * - Tests false for literals like - `"", "-", "a", "1", "dfsdsd", "213213"`
 */
export const atleast1Alpha1Num = /(?=.*?[0-9])(?=.*?[A-Za-z]).+/;

/**
 * This regex checks if the string to be checked is not equal to str
 *
 * @param str the string that will be compared
 * @param flag regex flag
 * @returns RegExp for checking if the string to be checked is not equal to str
 */
export function notEqualTo(str: string, flag?: string) {
  return new RegExp("^(?!" + str + "$).*$", flag);
}

/**
 * This regex checks if the string to be checked is equal to str
 *
 * @param str the string that will be compared
 * @param flag regex flag
 * @returns RegExp for checking if the string to be checked is equal to str
 */
export function equalTo(str: string, flag?: string) {
  return new RegExp("^(?=" + escapeRegexStr(str) + "$).*$", flag);
}

/**
 * This regex checks if the string is a valid pan
 */
// export const pan = /^[a-zA-Z]{5}[\d]{4}[a-zA-Z]{1}$/;
export const pan = /^[a-zA-Z]{3}[pP]{1}[a-zA-Z]{1}[\d]{4}[a-zA-Z]{1}$/; //only for person
// export const pan = /^[a-zA-Z]{3}[pPcChHfFaAtTbBlLjJgG]{1}[a-zA-Z]{1}[\d]{4}[a-zA-Z]{1}$/;  //for all

/**
 * This regex checks if the string is a valid phone/mobile number
 */
export const phoneNo = /^[6789]\d{9}$/;

/**
 * This regex checks if the string is a valid upi
 */
export const upiExp = /^[-.a-zA-Z0-9_]+@{1}[A-Za-z0-9]+$/;

/**
 * This regex checks if the string to be contains only digits or not
 *
 * @returns RegExp for checking if the string contains only digits or not
 *
 * - Tests true for literals like - `"231256", "34 151 2112 11548"`
 * - Tests false for literals like - `"₹", "as1541fa"`
 */
export const detectIntWithSpace = /^(?=.*\d)[\d ]+$/;
