import React from "react";
import "./Checkbox.scss";

interface ICheckboxProps {
  checked: boolean; // true if checkbox is checked, else false
  name: string; // name for input checkbox
  disabled?: boolean; // if disabled then pass true
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // function to be called on change
}

interface IMultiSelectCheckBoxProps {
  list: Array<{
    OptionDescription: string;
    QuestionID: number;
    OptionId: number;
    checked: boolean;
    disabled?: boolean;
  }>;
  disabled?: boolean; // if disabled then pass true
  onChange: (
    list: Array<{
      OptionDescription: string;
      QuestionID: number;
      OptionId: number;
      checked: boolean;
      disabled?: boolean;
    }>
  ) => void; // function to be called on change
}

const Checkbox: React.FC<ICheckboxProps> = (props) => {
  return (
    <label className={"checkbox" + (props.disabled ? " disabled" : "")}>
      <input
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked || false}
        name={props.name}
      />
      <span className="checked edel-icon-tick"></span>
      {props.children && (
        <span className="checkbox_label">{props.children}</span>
      )}
    </label>
  );
};

export const MultiSelectCheckBox: React.FC<IMultiSelectCheckBoxProps> = (
  props
) => {
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let updatedList: typeof props.list = props.list.map((checkbox) => {
      if (checkbox.OptionDescription === event.target.name) {
        return {
          OptionDescription: checkbox.OptionDescription,
          QuestionID: checkbox.QuestionID,
          OptionId: checkbox.OptionId,
          checked: event.target.checked,
        };
      }
      return checkbox;
    });
    props.onChange(updatedList);
  }

  return (
    <>
      {props.list.map((checkbox) => (
        <label
          key={checkbox.OptionDescription}
          className={
            "checkbox multiSelect" +
            (props.disabled || checkbox.disabled ? " disabled" : "")
          }
        >
          <input
            type="checkbox"
            onChange={onChange}
            checked={checkbox.checked || false}
            name={checkbox.OptionDescription}
          />
          <span className="checked edel-icon-tick"></span>
          {checkbox.OptionDescription && (
            <span className="checkbox_label">{checkbox.OptionDescription}</span>
          )}
        </label>
      ))}
    </>
  );
};

export default Checkbox;
