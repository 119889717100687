interface IConfig {
  root: string; //root url of application
  diyNewApiUrl: string; //root url of DIY REVAMP
  piChainNewApiUrl: string; //root url of PiChain REVAMP
  tempToken: string; //DIY REVAMP API TOKEN (This will be corrected by backend TEAM)
  diyApiUrl: string; //api bas url for diy apis
  apiUrl: string; //api base url of application
  javaComUrl: string; //java api url
  whatsappBearerToken: string; //Whatsapp Bearer Token for consent and communication
  clientId: string; //clientId for DigiLocker config
  redirectUri: string; //redirection url from DigiLocker to application
  cleverTapId: string; //CleverTap config id
  clientQuesttionSourceId: string; //
  ClientProfilingDataSourseId: string;
  apiKey: string;
  digiLockerSource: number;
}
const dev: IConfig = {
  root: "http://localhost:3001/",
  diyNewApiUrl: "https://nwuat.nuvamawealth.com/DIYAPI/api/",
  piChainNewApiUrl: "https://nwuat.nuvamawealth.com/DIYAPI/",
  tempToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJhcGlrZXkiOiJjNDExMjFlZC1iNmZiLWM5YTYtYmM5Yi01NzRjODI5MjllN2UiLCJuYmYiOjE2NTg0NzQ2MTgsImV4cCI6MTY1ODU2MTAxOCwiaWF0IjoxNjU4NDc0NjE4fQ.fJdG6pwqwuY196E2Y9xxm7N7ncyl23RIY0-3lAanvwI",
  diyApiUrl: "",
  apiUrl: "https://nwuat.nuvamawealth.com/DIYAPI/api/",
  javaComUrl: "",
  whatsappBearerToken:
    "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NzEyMjQ4ODEsImV4cCI6MTU4MDMyMjYwMH0.Q-IE3XqL5s8DS7QuN0TWscyzrThMi29Tbfb65wDvamg",
  clientQuesttionSourceId: "42",
  ClientProfilingDataSourseId: "42",
  //DigiLocker config
  clientId: "E517209A",
  redirectUri: "https://onboardinguat.nuvamawealth.com/showdocuments",

  // CleverTap config
  cleverTapId: "TEST-6Z6-556-W55Z",
  apiKey: "c41121ed-b6fb-c9a6-bc9b-574c82929e7e",
  digiLockerSource: 2,
};

const uat: IConfig = {
  root: "https://onboardinguat.nuvamawealth.com/",
  diyNewApiUrl: "https://nwuat.nuvamawealth.com/DIYAPI/api/",
  piChainNewApiUrl: "https://nwuat.nuvamawealth.com/DIYAPI/",
  tempToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJhcGlrZXkiOiJjNDExMjFlZC1iNmZiLWM5YTYtYmM5Yi01NzRjODI5MjllN2UiLCJuYmYiOjE2NTg0NzQ2MTgsImV4cCI6MTY1ODU2MTAxOCwiaWF0IjoxNjU4NDc0NjE4fQ.fJdG6pwqwuY196E2Y9xxm7N7ncyl23RIY0-3lAanvwI",
  diyApiUrl: "",
  apiUrl: "https://nwuat.nuvamawealth.com/DIYAPI/api/",
  javaComUrl: "",
  whatsappBearerToken:
    "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NzEyMjQ4ODEsImV4cCI6MTU4MDMyMjYwMH0.Q-IE3XqL5s8DS7QuN0TWscyzrThMi29Tbfb65wDvamg",
  clientQuesttionSourceId: "42",
  ClientProfilingDataSourseId: "42",
  //DigiLocker config
  clientId: "E517209A",
  redirectUri: "https://onboardinguat.nuvamawealth.com/showdocuments",

  // CleverTap config
  cleverTapId: "TEST-6Z6-556-W55Z",
  apiKey: "c41121ed-b6fb-c9a6-bc9b-574c82929e7e",
  digiLockerSource: 2,
};

const preProd: IConfig = {
  root: "https://onboardingcug.nuvamawealth.com/",
  diyNewApiUrl: "https://nwaop.nuvamawealth.com/mwapi/api/",
  piChainNewApiUrl: "https://capsapi.nuvamawealth.com/DIYAPI/",
  tempToken: "",
  diyApiUrl: "",
  apiUrl: "https://capsapi.nuvamawealth.com/DIYAPIPre/api/",
  javaComUrl: "",
  whatsappBearerToken:
    "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NzEyMjQ4ODEsImV4cCI6MTU4MDMyMjYwMH0.Q-IE3XqL5s8DS7QuN0TWscyzrThMi29Tbfb65wDvamg",
  clientQuesttionSourceId: "15",
  ClientProfilingDataSourseId: "15",
  //DigiLocker config
  clientId: "FD8066A1",
  redirectUri: "https://onboardingcug.nuvamawealth.com/showdocuments",

  // CleverTap config
  cleverTapId: "TEST-6Z6-556-W55Z",
  apiKey: "c41121ed-b6fb-c9a6-bc9b-574c82929e7e",
  digiLockerSource: 2,
};

const prod: IConfig = {
  root: "https://onboarding.nuvamawealth.com/",
  diyNewApiUrl: "https://nwaop.nuvamawealth.com/mwapi/api/",
  piChainNewApiUrl: "https://capsapi.nuvamawealth.com/DIYAPI/",
  tempToken: "",
  diyApiUrl: "",
  apiUrl: "https://capsapi.nuvamawealth.com/DIYAPI/api/",
  javaComUrl: "",
  whatsappBearerToken:
    "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NzEyMjQ4ODEsImV4cCI6MTU4MDMyMjYwMH0.Q-IE3XqL5s8DS7QuN0TWscyzrThMi29Tbfb65wDvamg",
  clientQuesttionSourceId: "15",
  ClientProfilingDataSourseId: "15",

  //DigiLocker config
  clientId: "FD8066A1",
  redirectUri: "https://onboarding.nuvamawealth.com/showdocuments",

  // CleverTap config
  cleverTapId: "6K9-KW7-745Z",
  apiKey: "c41121ed-b6fb-c9a6-bc9b-574c82929e7e",
  digiLockerSource: 2,
};

const config =
  process.env.REACT_APP_ENV === "production"
    ? prod
    : process.env.REACT_APP_ENV === "preprod"
    ? preProd
    : process.env.REACT_APP_ENV === "uat"
    ? uat
    : dev;

export default config;
