import React, { useEffect, useState } from "react";
import Loader from "../UI/Loader/Loader";
import "./Selfie.scss";

interface INewSelfie {
  callback: Function;
  onFailedToOpenSdk: Function;
}

const NewSelfie = (props: INewSelfie) => {
  const [token, setToken] = useState("");
  const [sdkData, setSdkData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleSDKData = (data?: any) => {
    props?.callback(data);
    setSdkData(data);
  };

  const handleGetTokenAndSDKInit = () => {
    setLoading(true);

    const apiUrl =
      "https://1d037ckgu4.execute-api.ap-south-1.amazonaws.com/default/nuvama-uat-token?RefId=124";

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ RefId: "1234" }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setToken(data.Token);
        let token = data.Token;
        if ((window as any).AinxtSDK && !!token) {
          (window as any).AinxtSDK.Initialize(token, handleSDKData);
        } else {
          props?.onFailedToOpenSdk(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching token:", error);
        props?.onFailedToOpenSdk(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://d1qpv0waqdbwtx.cloudfront.net/FaceNuvSpec.js";
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      setSdkData(null);
    };
  }, []);

  return (
    <div className="text-center">
      {!loading ? (
        <>
          {sdkData !== null && sdkData?.is_valid == "1" ? (
            <div className="cameraBtn">
              <span
                className="edel-icon-retake"
                onClick={() => {
                  setSdkData(null);
                  handleGetTokenAndSDKInit();
                }}
              />
            </div>
          ) : (
            <div className="cameraBtn">
              <span
                className="edel-icon-camera"
                onClick={handleGetTokenAndSDKInit}
              />
            </div>
          )}
        </>
      ) : (
        <div className="cameraBtn">
          <Loader size={20} />
        </div>
      )}

      {/* <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        // onClick={handleGetTokenAndSDKInit}
        onClick={handleSDKData}
        disabled={loading}
        style={{
          background: "linear-gradient(90deg, #5e24a0, #9e6dd5)",
        }}
      >
        {loading ? "Loading..." : "Get Token & Initialize AinxtSDK"}
      </button> */}

      {/* {sdkData && (
        <div className="mt-4">
          <h2 style={{ color: "white" }} className="text-xl font-bold mb-2">
            Data from SDK:
          </h2>
          <pre style={{ color: "white" }} className="whitespace-pre-wrap">
            {JSON.stringify(sdkData, null, 2)}
          </pre>
        </div>
      )} */}
    </div>
  );
};

export default NewSelfie;
