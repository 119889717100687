import React from "react";
import Button from "../Button/Button";
import "./ChatText.scss";
import DeviceContext from "../../Services/identifyDevice";
import callSupport from "../../../assets/icons/call.png";
import InfoModal from "../InfoModal/InfoModal";
import PanSample from "../../../assets/pan_sample.jpg";
import { pan } from "../../utils/regex";

export interface ChatTextProps {
  className?: string;
  panPageFlag?: boolean;
}

const ChatText: React.FC<ChatTextProps> = (props) => {
  const [instantHelpReadMoreFlag, setInstantHelpReadMoreFlag] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    console.log(DeviceContext().isMobile);
  }, [DeviceContext().isMobile]);

  let readMoreFlagToggle = () => {
    console.log("click");
    if (!instantHelpReadMoreFlag) setInstantHelpReadMoreFlag(true);
    else setInstantHelpReadMoreFlag(false);
  };

  console.log(DeviceContext().isMobile, "HELLO");

  return (
    <div>
      {props.panPageFlag ? (
        <div className="">
          {DeviceContext().isMobile ? (
            <div>
              {instantHelpReadMoreFlag ? (
                <div className="instant-help-modal-mobile">
                  <InfoModal
                    width={DeviceContext().isMobile ? "400px" : "354px"}
                  >
                    <div className="" style={{ padding: "8px" }}>
                      <div style={{ marginBottom: "28px" }}>
                        <div
                          className=""
                          style={{ float: "left", width: "95%" }}
                        >
                          <p
                            className="blue text-center"
                            style={{ color: "#434343" }}
                          >
                            Instant Help!!!
                          </p>
                        </div>
                        <div
                          style={{
                            float: "right",
                            width: "3%",
                            color: "gray",
                            cursor: "pointer",
                          }}
                          onClick={readMoreFlagToggle}
                        >
                          x
                        </div>
                      </div>
                      <p>
                        Need help here? No problem! Just follow these easy steps
                        to get started.
                        <ul className="instant-help-ul">
                          <li>Click on selfie console</li>
                          <li>
                            You will be prompted to give access to your camera
                            and location-allow when asked.
                          </li>
                          <li>
                            If your laptop or desktop doesn't have a camera
                            don't worry! Enable the toggle button below the
                            selfie console and you'll get a link to your selfie
                            via SMS on your registered mobile device.
                          </li>
                          <li>
                            Enter your PAN and Aadhaar details to validate your
                            PAN-Aadhaar seeding check.
                          </li>
                          <li>
                            Remember to keep your PAN card handy. You'll need to
                            upload it in horizontal format so that it's clear
                            and can be shared with KRA.
                          </li>
                        </ul>
                        <div className="text-center">
                          <img
                            className="pan_image_sample"
                            src={PanSample}
                            alt={"pan-sample"}
                          />
                        </div>
                        <ul>
                          <li>
                            Make sure the following details are clear on your
                            pan card
                          </li>
                        </ul>
                        <div className="nested-list instant-help-ul">
                          <ul>
                            <li>
                              Always upload Original or Colored Scanned Pan Card
                              Image
                            </li>
                            <li>PAN card number</li>
                            <li>Date of birth</li>
                            <li>Photo</li>
                          </ul>
                        </div>
                      </p>
                    </div>
                  </InfoModal>
                </div>
              ) : (
                ""
              )}
              <div className="fixed-card">
                <div style={{ float: "left", width: "70%" }}>
                  <h4> Can't see your face?</h4>
                </div>
                <div style={{ float: "left", width: "30%" }}>
                  <button
                    className={`instant-help-button ${
                      instantHelpReadMoreFlag ? "color-red" : "color-blue"
                    }`}
                    onClick={readMoreFlagToggle}
                  >
                    ? instant help!!
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className={"chatText card " + props.className}>
              {instantHelpReadMoreFlag ? (
                <div className="f-modal">
                  <div className="f-modal-content">
                    <div className="" style={{ padding: "8px" }}>
                      <div style={{ marginBottom: "28px" }}>
                        <div
                          className=""
                          style={{ float: "left", width: "95%" }}
                        >
                          <p
                            className="blue text-center"
                            style={{ color: "#434343" }}
                          >
                            Instant Help!!!
                          </p>
                        </div>
                        <div
                          style={{
                            float: "right",
                            width: "3%",
                            color: "gray",
                            cursor: "pointer",
                          }}
                          onClick={readMoreFlagToggle}
                        >
                          x
                        </div>
                      </div>
                      <p>
                        Need help here? No problem! Just follow these easy steps
                        to get started.
                        <ul className="instant-help-ul">
                          <li>Click on selfie console</li>
                          <li>
                            You will be prompted to give access to your camera
                            and location-allow when asked.
                          </li>
                          <li>
                            If your laptop or desktop doesn't have a camera
                            don't worry! Enable the toggle button below the
                            selfie console and you'll get a link to your selfie
                            via SMS on your registered mobile device.
                          </li>
                          <li>
                            Enter your PAN and Aadhaar details to validate your
                            PAN-Aadhaar seeding check.
                          </li>
                          <li>
                            Remember to keep your PAN card handy. You'll need to
                            upload it in horizontal format so that it's clear
                            and can be shared with KRA.
                          </li>
                        </ul>
                        <div className="text-center">
                          <img
                            className="pan_image_sample"
                            src={PanSample}
                            alt={"pan-sample"}
                          />
                        </div>
                        <ul>
                          <li>
                            Make sure the following details are clear on your
                            pan card
                          </li>
                        </ul>
                        <div className="nested-list instant-help-ul">
                          <ul>
                            <li>
                              Always upload Original or Colored Scanned Pan Card
                              Image
                            </li>
                            <li>PAN card number</li>
                            <li>Date of birth</li>
                            <li>Photo</li>
                          </ul>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <p className="blue">Instant Help!!!</p>
              <p></p>

              <p>
                You are supposed to take a selfie. Enter PAN Card and Aadhar
                Card details to validate your Pan Aadhar seeding check. Upload
                original or scanned PAN card at this stage.
              </p>

              <p className="blue cursor-pointer" onClick={readMoreFlagToggle}>
                Read More
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          {DeviceContext().isMobile ? (
            <div className="tooltip">
              <div className="callIcon">
                <span>
                  <img src={callSupport} alt="Call Support" />
                </span>
              </div>
              <div className="callText">Call</div>
              <div className="tooltiptext">
                <p>
                  <a href="tel:18001023335" className="blue">
                    1800 102 3335
                  </a>
                </p>
                <p>Monday to Saturday : 10:00 AM - 6:00 PM</p>
                {/* <p>Saturday 10 AM - 4 PM</p> */}
              </div>
            </div>
          ) : (
            <div className={"chatText " + props.className}>
              <p>For any queries contact us:</p>
              <p>
                <a href="tel:18001023335" className="blue">
                  1800 102 3335
                </a>
              </p>
              <p>*Weekdays 10:00 AM - 6:00 PM</p>
              <p>*Saturday 10:00 AM - 5:00 PM</p>
              <p>(*Except on 1st Saturday and Trading Holidays.)</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ChatText;
