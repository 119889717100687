import React from "react";
import "./Loader.scss";

export interface ILoaderProps {
	color?: 'grey' | 'blue';
	size: 18 | 20 | 100;
}

const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {
	const color = props.color || "grey";
	return (
		<div className={`lds_spinner_${props.size}px ${color}`}>
			{Array.from(Array(12).keys(), (_, i) => (
				<div key={i}></div>
			))}
		</div>
	);
};

export default Loader;
