import React from "react";
import "./DocVerificationHome.scss";
import DocVerifcationForm from "../DocVerificationForm/DocVerificationForm";
import GlobalStore from "../../store/globalStore";
import cleverTapEvents from "../../../config/cleverTapEvents";
import { ClevertapService } from "../../Services/clevertapService";
import useQuery from "../../../customHooks/useQuery";

export interface DocVerificationHomeProps {}

const DocVerificationHome: React.FC<DocVerificationHomeProps> = (props) => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  React.useEffect(() => {
    window.sessionStorage.setItem("stageInfoId", "6");
  }, []);

  // Clervertap event: Profiler/Investment screen is loaded
  React.useEffect(() => {
    const data = {
      EventName:
        cleverTapEvents.registration.documentVerificationPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);
  }, []);

  return (
    <div className="docHome">
      <div className="docHeading">
        <label>Verify Personal Documents</label>
      </div>
      <div className="docSubHeading">
        <label>
          Share PDF/PNG/JPG files of your documents for account opening and
          verification
        </label>
      </div>
      <DocVerifcationForm
        UTM_SOURCE={`${UTM_SOURCE}`}
        UTM_MEDIUM={`${UTM_MEDIUM}`}
        UTM_CAMPAIGN={`${UTM_CAMPAIGN}`}
      />
    </div>
    // <div className='docHome'>
    //     <div className='docHeading'>
    //         <label>Document Verification</label>
    //     </div>
    //     <div className="docSubHeading">
    //         <label>Share PDF/PNG/JPG files of your documents for account opening and verification</label>
    //     </div>
    //     <DocVerifcationForm />
    // </div>
  );
};

export default DocVerificationHome;
