import { apiService } from "../apiServices";
import config from "../../../config/domain";
import servicesList from "../serviceList";
import GlobalStore from "../../store/globalStore";
import { AGetStageCompletionStatus } from "./continueApiService";

export const ACheckPanIbeats = (callback: CallableFunction, panNo: string) => {
  apiService(
    {
      url: "DIYAPI/CheckPanInSystem",
      method: "POST",
      body: {
        PanNo: panNo,
        RequestFrom: 1,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.checkPanInSystem,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/check-pan-in-ibeats",
  //     body: {
  //       panno: panNo,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const APanLinkedWithAadhar = (
  callback: CallableFunction,
  panNo: string,
  aadharNo: string
) => {
  apiService(
    {
      url: "DIYAPI/GetLinkStatus",
      method: "POST",
      body: {
        PanNo: panNo,
        AadharNo: aadharNo,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.panLinkedWithAadhar,
    },
    callback
  );
};

export const AGetEmployeeByPan = (
  callback: CallableFunction,
  panNo: string
) => {
  apiService(
    {
      url: "MastersData/GetEmployeeDetailsByPAN",
      method: "POST",
      body: {
        PanNo: panNo,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.getEmployeeDetailsByPan,
    },
    callback
  );
  // apiService(
  //     {
  //         url: 'eco/get-employee-by-pan',
  //         body: {
  //             "panno": panNo
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};

export const AIsPANExists = (callback: CallableFunction, panNo: string) => {
  apiService(
    {
      url: "DIYAPI/CheckPanInSystem",
      method: "POST",
      body: {
        PanNo: panNo,
        RequestFrom: 2,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.checkPanInSystem,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/check-pan-in-caps",
  //     body: {
  //       panno: panNo,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetPanUtiData = (callback: CallableFunction, panNo: string) => {
  let panDetails = GlobalStore.personaldetails.panDetails;
  console.log("panDetails fron Globalstrore", panDetails);

  const getData = (res: any) => {
    if (res && res.data) {
      let data = JSON.parse(res.data);
      panDetails = {
        PanNo: GlobalStore.personaldetails.pan,
        Firstname: data?.panfirstname,
        Middlename: data?.panmiddletname,
        Lastname: data?.panlastname,
        PANIssueDate: "",
        AadharSeedingStatus: "",
        VendorName: "",
        IsValid: true,
        IsAadharSeeded: "",
      };

      let result = {
        status: false,
        msgID: "",
        data: panDetails,
        // error: errorMessage.newPanUtiError,
        error: "",
      };

      callback(result);
    }
  };
  console.log("panDetails", panDetails, typeof panDetails);

  const panFromLocalStorage = window.localStorage.getItem(
    "clientPartialDetails_ao"
  );

  // if (panDetails === null || panDetails === undefined) {
  //   if (!!panFromLocalStorage) {
  //     console.log("panFromLocalStorage", panFromLocalStorage);
  //     let panDetailsLocal = JSON.parse(panFromLocalStorage);
  //     if (!!panDetailsLocal && panDetailsLocal.panDetails) {
  //       panDetails = panDetailsLocal.panDetails;

  //       let result = {
  //         status: false,
  //         msgID: "",
  //         data: panDetails,
  //         // error: errorMessage.newPanUtiError,
  //         error: "",
  //       };
  //       callback(result);
  //     } else {
  AGetStageCompletionStatus(getData, GlobalStore.applicationID);
  //     }
  //   } else {
  //     AGetStageCompletionStatus(getData, GlobalStore.applicationID);
  //   }
  // }

  // let result = {
  //   status: false,
  //   msgID: "",
  //   data: panDetails,
  //   // error: errorMessage.newPanUtiError,
  //   error: "",
  // };
  // // if(panDetails==null &&  panDetails==undefined){

  // // }
  // callback(result);
  // apiService(
  //   {
  //     url: "DIYAPI/GetPanDetails",
  //     method: "POST",
  //     body: {
  //       PanNo: panNo,
  //     },
  //     apiBaseUrl: config.diyNewApiUrl,
  //     tempToken: false,
  //     serviceName: servicesList.getPanUtiDetails,
  //   },
  //   callback
  // );
  // apiService(
  //   {
  //     url: "eco/get-panuti-data",
  //     body: {
  //       panno: panNo,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const APanVerification = (
  callback: CallableFunction,
  panObject: {
    panNo: string;
    name: string;
    dob: string;
    source: string;
  }
) => {
  apiService(
    {
      url: "DIYAPI/PanVerification",
      method: "POST",
      body: panObject,
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.APanVerification,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-client-details-by-leadid",
  //     body: {
  //       leadid: leadId,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};
export const AGetClientDetailsByPan = (
  callback: CallableFunction,
  panNo: string
) => {
  apiService(
    {
      url: "DIYAPI/GetClientDataByPanOrLead",
      method: "POST",
      body: {
        PanNumber: panNo,
        LeadId: "",
        RequestFrom: 1,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.getClientDetailsByPan,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-client-details-by-pan",
  //     body: {
  //       panno: panNo,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetClientDetailsByLeadId = (
  callback: CallableFunction,
  leadId: string
) => {
  apiService(
    {
      url: "DIYAPI/GetClientDataByPanOrLead",
      method: "POST",
      body: {
        PanNumber: "",
        LeadId: leadId,
        RequestFrom: 2,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: false,
      serviceName: servicesList.getClientDataByPanOrLead,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-client-details-by-leadid",
  //     body: {
  //       leadid: leadId,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AUpdateUtmDetails = (callback: CallableFunction, utmObj: any) => {
  apiService(
    {
      url: "DIYAPI/UpdateUTMParamters",
      body: {
        ApplicationId: utmObj.applicationid,
        UtmContent: utmObj.utmcontent || "",
        UtmCreative: utmObj.utmcreative || "",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.updateUtmInfo,
    },
    callback
  );
  //   apiService(
  //     {
  //       url: "eco/update-utm-details",
  //       body: utmObj,
  //       method: "POST",
  //     },
  //     callback
  //   );
};

export const AWhatsappCom = (callback: CallableFunction, req: object) => {
  apiService(
    {
      url: "WhatsUpAppService/WhatsUpAppInitiateCommunication",
      body: req,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.whatsUpAppInitiateCommunication,
    },
    callback
  );
  // apiService(
  //   {
  //     apiBaseUrl: config.javaComUrl,
  //     url: "initiate/communication",
  //     body: req,
  //     method: "POST",
  //     headers: {
  //       mwAuth: config.whatsappBearerToken,
  //     },
  //   },
  //   callback
  // );
};

export const ASavePanDobDetails = (
  callback: CallableFunction,
  panDobObj: any
) => {
  apiService(
    {
      url: "DIYAPI/SavePanDobDetails",
      method: "POST",
      body: {
        ...panDobObj,
        IsEmployeePan: panDobObj.isemployeepan,
        // TransactionId: panDobObj.transactionid,
        KycVerified: panDobObj.kycverified,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.savePanDobDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-pan-dob-details",
  //     body: panDobObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetCkycNumber = (callback: CallableFunction, panNo: string) => {
  apiService(
    {
      url: "DIYAPI/GetCkycNumber",
      method: "POST",
      body: {
        PanNo: panNo,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getClientDataByPanOrLead,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-ckyc-number",
  //     body: {
  //       panno: panNo,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetCkycDetails = (callback: CallableFunction, reqObj: any) => {
  apiService(
    {
      url: "DIYAPI/GetCKYCDetails",
      method: "POST",
      body: {
        PanNumber: reqObj.panno,
        Dob: reqObj.dob,
        ApplicationId: reqObj.applicationid,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getCKYCDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-ckyc-details",
  //     body: reqObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetKraDetails = (callback: CallableFunction, reqObj: any) => {
  apiService(
    {
      url: "DIYAPI/GetKraDetails",
      method: "POST",
      body: {
        PanNo: reqObj.panno,
        Dob: reqObj.dbo,
        ApplicationId: reqObj.applicationId,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getKraDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-kra-details",
  //     body: reqObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const ACheckUCCExists = (
  callback: CallableFunction,
  panNo: string,
  mobile: string,
  email: string,
  leadId?: string
) => {
  apiService(
    {
      url: "DIYAPI/v1/CheckUCCExists",
      method: "POST",
      body: {
        PanNo: panNo,
        mobileNo: mobile,
        emailId: email,
        leadId: leadId,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.checkPanInSystem,
    },
    callback
  );
};
