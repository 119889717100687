import React from "react";
import "./Button.scss";
import Loader from "../Loader/Loader";

interface ButtonFields {
  btnName: string;
  disabled?: boolean;
  isLoading?: boolean | undefined;
  isSecondary?: boolean;
  isLightBlue?: boolean;
  type?: string;
  isTick?: boolean;
  isWhatsapp?: boolean;
  isUpload?: boolean;
  isAdd?: boolean;
  prefix?: any;
  clicked?: boolean;
  onClick?: (value: any) => void;
}

const Button: React.FC<ButtonFields> = (props: any) => (
  <button
    className={
      "btn " +
      (props.isSecondary ? "isSecondary " : "") +
      (props.isLightBlue ? "isLightBlue " : "") +
      (props.disabled || props.isLoading ? "disable" : "active")
    }
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type || "button"}
  >
    {props.prefix && props.prefix}
    {props.isLoading ? (
      <Loader size={20} />
    ) : props.isTick ? (
      <>
        {" "}
        <span className="edel-icon-tick" /> {props.btnName}
      </>
    ) : (
      `${props.btnName}`
    )}
  </button>
);

export const DashedButton: React.FC<ButtonFields> = (props: any) => (
  <button
    className={"addBtn " + (props.disabled ? "dasDisable" : "dasActive")}
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type || "button"}
  >
    {/* {props.btnName}
        {props.isLoading && <Loader size={20} />} */}
    {props.isLoading ? (
      <Loader size={20} />
    ) : props.isAdd ? (
      <>
        {" "}
        <span className="edel-icon-plus" /> {props.btnName}
      </>
    ) : (
      `${props.btnName}`
    )}
  </button>
);

export const TransparentButton: React.FC<ButtonFields> = (props: any) => (
  <button
    className={"trnsBtn " + props.disabled}
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type || "button"}
  >
    {props.prefix && props.prefix}
    {props.isWhatsapp ? (
      <>
        {" "}
        <span className="edel-icon-whatsapp" />{" "}
      </>
    ) : null}
    {props.isUpload ? (
      <>
        {" "}
        <span className="edel-icon-upload" />{" "}
      </>
    ) : null}
    {props.isLoading ? (
      <Loader size={20} />
    ) : props.isTick ? (
      <>
        {" "}
        <span className="edel-icon-tick" /> {props.btnName}
      </>
    ) : (
      `${props.btnName}`
    )}
  </button>
);

export const SubmitButton: React.FC<ButtonFields> = (props: any) => (
  <button
    className={"submitBtn " + (props.disabled ? "disable" : "active")}
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type || "submit"}
  >
    {props.isLoading ? (
      <Loader size={20} />
    ) : props.isTick ? (
      <>
        {" "}
        <span className="edel-icon-tick" /> {props.btnName}
      </>
    ) : (
      `${props.btnName}`
    )}
  </button>
);

export const Verify: React.FC<ButtonFields> = (props: any) => (
  <button
    className={
      "btn vrfyBtn " +
      (props.disabled || props.isLoading ? "disable" : "active")
    }
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type}
  >
    {props.isLoading ? (
      <Loader size={20} />
    ) : props.isTick ? (
      <>
        {" "}
        <span className="edel-icon-tick" />
        {props.btnName}
      </>
    ) : (
      `${props.btnName}`
    )}
  </button>
);

export const LightBlueButton: React.FC<ButtonFields> = (props: any) => (
  <button
    className={
      "LightBluebtn info alignBtn " + (props.clicked ? "selected" : "")
    }
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type || "button"}
  >
    {props.prefix && props.prefix}
    {props.isWhatsapp ? (
      <>
        {" "}
        <span className="edel-icon-whatsapp" />{" "}
      </>
    ) : null}
    {props.isUpload ? (
      <>
        {" "}
        <span className="edel-icon-upload" />{" "}
      </>
    ) : null}
    {props.isLoading ? (
      <Loader size={20} />
    ) : props.isTick ? (
      <>
        {" "}
        <span className="edel-icon-tick" /> {props.btnName}
      </>
    ) : (
      `${props.btnName}`
    )}
  </button>
);

export default Button;
