import GlobalStore from "../components/store/globalStore";
export const errorCode = {
  FE01: "C11FE01",
  // Name cannot have special characters or cannot be null
  FE02: "C11FE02",
  // Select Valid City
  FE03: "C11FE03",
  // Enter Valid Email Id
  FE04: "C11FE04",
  // Enter 10 Digit Mobile Number
  FE05: "C11FE05",
  //   Enter a Valid OTP
  FE06: "C11FE06",
  // Please Enter correct RM code
  FE07: "C11FE07",
  //   Please enter valid pan number
  FE08: "C11FE08",
  //   Please enter valid phone number
  FE09: "C11FE09",
  // Enter Pan Number as per Pan Card
  FE10: "C11FE10",
  //DOB as per PAN Card
  FE11: "C11FE11",
  // Wrong DoB"
  FE12: "C11FE12",
  //  "Document size exceeds maximum limit of 5MB. Please upload it again",
  FE13: "C11FE13",
  //  "Please upload the files in jpeg, jpg formats.",
  FE14: "C11FE14",
  //  "Please upload the files in jpeg, jpg and pdf formats.",
  FE15: "C11FE15",
  //  "File with multiple extensions are not allowed",
  FE16: "C11FE16",
  //  "Special Characters are not allowed in Address",
  FE17: "C11FE17",
  //  "Please enter valid address. Address should contains minimum 5 characters and Address line should not contain only numbers",
  FE18: "C11FE18",
  //  "Enter 6 Digit Pin Code",
  FE19: "C11FE19",
  //  "Type first 3 characters of city",
  FE20: "C11FE20",
  //  "Please select from dropdown",
  FE21: "C11FE21",
  //  "Number required",
  FE22: "C11FE22",
  //  "Enter Networth",
  FE23: "C11FE23",
  //  "Please Enter Valid Type",
  FE24: "C11FE24",
  //  "Relationship Details are required",
  FE25: "C11FE25",
  //  "Enter Valid Identification Number as per document",
  FE26: "C11FE26",
  //  "Enter number between 1 to 100",
  FE27: "C11FE27",
  //  "Please select a valid date",
  FE28: "C11FE28",
  //  "Please Enter Amount More Than Rs 1000",
  FE29: "C11FE29",
  //  "Please Enter Place of Birth",
  FE30: "C11FE30",
  //  "Account number should have minimum 6 digits",
  FE31: "C11FE31",
  //  "Bank inputs do not match",
  FE32: "C11FE32",
  //  "SBI account number has more than 10 digits",
  FE33: "C11FE33",
  //  "Father Name cannot have special characters or cannot be null",
  FE34: "C11FE34",
  //  "Mother Name cannot have special characters or cannot be null",
  FE35: "C11FE35",
  //  "Nominee Name cannot have special characters or cannot be null",
  FE36: "C11FE36",
  //  "Gaurdian Name cannot have special characters or cannot be null",
  FE37: "C11FE37",
  //  "Please Enter Other Relation",
  FE38: "C11FE38",
  //  "Please enter valid pincode",
  FE39: "C11FE39",
  //  "Please select a city from dropdown",
  FE40: "C11FE40",
  // "Please enter minimum 8 or maximum 18",
  FE41: "C11FE41",
  //  "Please Enter last four digit of Adhar number",
  FE42: "C11FE42",
  //  "Please Enter Relation",
  FE43: "C11FE43",
  //  "Special Characters are not allowed and Address should contains minimum 5 characters",
  FE44: "C11FE44",
  //Type first 3 characters of Area
  FE46: "C11FE46",
  //Enter Aadhaar Number as per Aadhaar Card
  BE384: "C11BE384",
  //Limit of family tagging has been exceed
  FE47:  "C11FE50" 
  // Signature File Size Error Message
};
export const errorMsg = (code: string) => {
  let errorText = "Something went wrong";
  let errorResponse = GlobalStore.fieldErrorBank.filter(
    (data) => data.ErrorCode === code
  );
  if (errorResponse && errorResponse.length > 0) {
    errorText = errorResponse[0]?.ErrorMessage;
  }
  return errorText;
};
