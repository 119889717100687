import React from 'react'
import './ErrMsg.scss'

const ErrMsg = (props: any) => (
    <div className='errMsg'>
        <div className='errDiv'>
            <label className='msg'>
                {props.children}
            </label>
        </div>
    </div>
)

export default ErrMsg