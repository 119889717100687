import React from "react";
import DeviceContext from "../../Services/identifyDevice";
import "./ProfileHeader.scss";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../UI/ProgressBar/ProgressBar";
import GlobalStore from "../../store/globalStore";
import { calculateCompletePercentage } from "../../Services/globalService";
import logo from "../../../assets/icons/edel_icons/Nuvama_Identity_RGB_Positive.jpg";

export interface ProfileHeaderProps {}

const ProfileHeader: React.FC<ProfileHeaderProps> = () => {
  const isDesktop = DeviceContext().isDesktop;
  const history = useHistory();

  function openMenu() {
    GlobalStore.dashboardActivetUrl = history.location.pathname;
    history.push("/menu");
  }

  const percentageComplete: string = calculateCompletePercentage();

  return (
    <div className="prfHdr">
      {isDesktop ? (
        <div className="desktop">
          {/* <span className="edel-icon-logo-label-left-menu" /> */}
          <img className="Logo-main" src={logo} alt="logo" />
          <div className="progress">
            <span className="progressLbl">{percentageComplete}% complete</span>
            <ProgressBar
              percent={percentageComplete}
              width="120px"
              height="4px"
            />
          </div>
        </div>
      ) : (
        <div className="notDesktop" onClick={openMenu}>
          <span className="edel-icon-previous" />
          <label className="backLbl">Back</label>
        </div>
      )}
    </div>
  );
};

export default ProfileHeader;
