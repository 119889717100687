import React from "react";
import ProfileImage from "../../UI/ProfileImage/ProfileImage";
import Button from "../../UI/Button/Button";
import "./WelcomeBack.scss";
import GlobalStore from "../../store/globalStore";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import { useHistory } from "react-router-dom";
import { dashboardUrl, containerUrl, statusUrl } from "../../utils/constants";
import {
  clientPartialDetailsToStore,
  completeModuleStatus,
  updateUtmInfo,
  setStageData,
} from "../../Services/globalService";
import { APendingUploadList } from "../../Services/dashboard/eSignApiServices";
import welcomeBack from "../../../assets/icons/welcomeBack.jpg";
import { ACheckClientProfilingData } from "../../Services/dashboard/profilerApiServices";
import { checkModuleCompleteness } from "../../Profile/ProfileNavBar/ProfileNavBar";
import { AGetClientDetailsByLeadId } from "../../Services/registration/panDobApiService";
import config from "../../../config/domain";

export interface WelcomeBackProps {}

const WelcomeBack: React.FC<WelcomeBackProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [clientName, setClientName] = React.useState<string>("");
  const [errCode, setErrCode] = React.useState<number | string>();
  const [isUploadDocumentsCompleted, setIsUploadDocumentsCompleted] =
    React.useState<boolean>(false);
  const history = useHistory();

  React.useEffect(() => {
    if (Object.keys(clientPartialDetailsToStore()).length === 0) {
      history.push(containerUrl.registration);
    } else if (GlobalStore.isNormalClient && !GlobalStore.isPANVerified) {
      history.push(containerUrl.registration);
    } else {
      setIsLoading(true);
      if (
        GlobalStore.clientPrimaryDetailId === "" ||
        GlobalStore.clientPrimaryDetailId === "0" ||
        GlobalStore.clientPrimaryDetailId === null
      ) {
        console.log("Wrong clientPrimaryDetailId");
      }
      AGetClientDetailsByLeadId(searchByLeadId, GlobalStore.leadId);
      window.sessionStorage.setItem("isSeniorCitizen", "false");
      const clientFullName =
        GlobalStore.personaldetails.PANName.toLowerCase() ||
        window.localStorage.getItem("clientName");
      setClientName(
        clientFullName.replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase()) ||
          ""
      );
      APendingUploadList(checkPendingUpload, GlobalStore.applicationID);
    }
  }, []);

  function searchByLeadId(res: any) {
    if (!res || res.error) {
      // setErrCode("119 - Error in fetching Client details from our system.");
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data && res.data != null) {
        if (
          GlobalStore.personaldetails.pan.toUpperCase().trim() ===
          res.data.PAN.toUpperCase().trim()
        ) {
          GlobalStore.token = res.data.Token;
          GlobalStore.applicationID = res.data.ApplicatoinId;
          GlobalStore.personaldetails.pan = res.data.PAN;
          GlobalStore.leadId = res.data.LeadId;
          GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;
          const clientPartialDetails_ao = JSON.parse(
            window.localStorage.getItem("clientPartialDetails_ao") || "{}"
          );
          clientPartialDetails_ao.clientPrimaryDetailId =
            GlobalStore.clientPrimaryDetailId;
          window.localStorage.setItem(
            "clientPartialDetails_ao",
            JSON.stringify(clientPartialDetails_ao)
          );
        }
      } else {
        setErrCode(res.error);
        setIsLoading(false);
      }
    }
  }

  function populateSavedData() {
    const panDetails = GlobalStore.personaldetails.panDetails;
    console.log("panDetails", GlobalStore.personaldetails);
    if (typeof panDetails !== "undefined" && panDetails && panDetails !== "") {
      console.log("Line No 97 If call ");
      setIsLoading(true);
      updateUtmInfo(updateUtmDetails); // here updating utmcreative and content.

      ACheckClientProfilingData(
        getProfilerStatus,
        GlobalStore.applicationID,
        config.ClientProfilingDataSourseId
      );
    }
  }

  function getProfilerStatus(res: any) {
    if (!res || res.error) {
      // setErrCode("1231- Failed to fetch client profiling data.");
      setErrCode(res.error);
    } else {
      if (res.hasOwnProperty("data")) {
        if (res.data === true) completeModuleStatus(dashboardUrl.profiler);
        AGetStageCompletionStatus(
          getClientStageDetails,
          GlobalStore.applicationID
        );
      } else {
        setErrCode(res.error);
      }
    }
  }

  function updateUtmDetails(res: any) {
    if (!res || res.error) {
      // setErrCode(123);
    }
  }

  function checkPendingUpload(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res && res.data) {
        let resultObj = JSON.parse(res.data);
        let list: Array<any> = resultObj.filter(
          (item: any) =>
            // prev api types
            // item.documentcategoryid !== 36 &&
            // item.documentcategoryid !== 30 &&
            // item.documentcategoryid !== 62
            // item.DocumentCategoryId !== 36 &&
            // item.DocumentCategoryId !== 30 &&
            // item.DocumentCategoryId !== 62
            item.Mandatory == true
        );
        if (list.length === 0 || list[0].count === 0)
          setIsUploadDocumentsCompleted(true);
      }
    }
    setIsLoading(false);
  }

  function getClientStageDetails(res: any) {
    // console.log("AGetStageCompletionStatus", res);
    setIsLoading(true);
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        //set stage api data to GlobalStore
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );

        console.log(clientPartialDetails_ao, "clientPartialDetails_ao");

        if (!clientPartialDetails_ao.mobile) {
          clientPartialDetails_ao.mobile = responseObj.mobileno;
        }

        if (!clientPartialDetails_ao.emailID) {
          clientPartialDetails_ao.emailID = responseObj.emailid;
        }

        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        setStageData(responseObj);

        //check completeness of stages
        checkModuleCompleteness(isUploadDocumentsCompleted);

        //route according to stage api response
        if (
          !responseObj.isdigilockercompleted &&
          GlobalStore.aadharLinkedChoice &&
          !GlobalStore.isDigiLocker &&
          !GlobalStore.isKRA
        ) {
          history.push(containerUrl.dgLocker);
          return;
        }
        if (
          !(
            responseObj.isprofiledetailscompleted &&
            responseObj.ispersonaldetailscompleted
          )
        ) {
          history.push(dashboardUrl.profile);
          return;
        }
        if (!responseObj.isbankdetailscompleted) {
          history.push(dashboardUrl.bank);
          return;
        }
        if (
          !responseObj.isbrokeragesegmentcompleted ||
          !responseObj.isbrokerageschemecompleted
        ) {
          history.push(dashboardUrl.plan);
          return;
        }
        if (!isUploadDocumentsCompleted) {
          history.push(dashboardUrl.doc);
          return;
        }
        if (
          !GlobalStore.isDigiLocker &&
          !GlobalStore.isKRA &&
          !GlobalStore.isKYC &&
          !responseObj.isipvarificationcompleted
        ) {
          history.push(dashboardUrl.ipv);
          return;
        }
        if (responseObj.isagentvisitcompleted) {
          history.push(containerUrl.ocv);
          return;
        }
        if (!responseObj.isesigncompleted) {
          history.push(dashboardUrl.eSign + "?sc=e");
          return;
        }
        if (responseObj.isesigncompleted && !responseObj.iskraesigncompleted) {
          history.push(dashboardUrl.eSign + "?sc=k");
          return;
        }
        if (responseObj.bpm_pd_status === 0) {
          history.push(statusUrl.docVerificationInProgress);
          return;
        }
        if (responseObj.bpm_pd_status === 1) {
          history.push(containerUrl.poa);
          return;
        }
      }
    }
    setIsLoading(false);
  }

  function newAccountRedirect() {
    GlobalStore.isPartialFilledClient = true;
    clientPartialDetailsToStore({ applicationID: "" });
    history.replace(containerUrl.registration);
  }

  return (
    <div className="wb">
      <div className="wbLogo">
        <ProfileImage>
          <img src={welcomeBack} alt="Welcome" className="pfImg" />
        </ProfileImage>
      </div>
      <div className="wbheading">
        <label>Welcome Back {clientName}</label>
      </div>
      <div className="wbInfo">
        <label className="infoLine">
          We’ve detected a previous account opening in process. Would you like
          to continue or start afresh?
        </label>
      </div>
      {/* Error Msg Start*/}
      {errCode && (
        <div className="errMsgBlck">
          <ErrMsg>
            {typeof errCode === "number" ? (
              <>
                <label>We are facing some technical issue.</label>
                <label>Please try after sometime (error code:{errCode})</label>
              </>
            ) : (
              <label>{errCode}</label>
            )}
          </ErrMsg>
        </div>
      )}
      {/* Error Msg End*/}
      <div className="wbBtns">
        <div className="wbBtn">
          <Button
            btnName="New Account"
            disabled={isLoading}
            isSecondary={true}
            isLoading={isLoading}
            onClick={newAccountRedirect}
          />
        </div>
        <div className="wbBtn wbcntn">
          <Button
            btnName="Continue"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={populateSavedData}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack;
