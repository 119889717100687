import React from "react";
import "./DgLocker.scss";
import ProfileImage from "../../UI/ProfileImage/ProfileImage";
import dgLocker from "../../../assets/icons/panDob.svg";
import Button, { LightBlueButton } from "../../UI/Button/Button";
import Checkbox from "../../UI/Checkbox/Checkbox";
import domain from "../../../config/domain";
import { digiLockerUrl, dashboardUrl } from "../../utils/constants";
import arrowPoint from "../../../assets/icons/arrow_point.png";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import { useHistory } from "react-router-dom";
import gtmService from "../../Services/gtmService";
import cleverTapEvents from "../../../config/cleverTapEvents";
import useQuery from "../../../customHooks/useQuery";
import { ClevertapService } from "../../Services/clevertapService";
import GlobalStore from "../../store/globalStore";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import InfoModal from "../../UI/InfoModal/InfoModal";
import DeviceContext from "../../Services/identifyDevice";
import OptionDocumentList from "./OptionDocumentList";
import { AGetDigiLink } from "../../Services/registration/digiLockerApiService";
import { errorMessage } from "../../utils/errorMessage";

export interface DgLockerProps {}

const DgLocker: React.FC<DgLockerProps> = () => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const history = useHistory();
  //   const [consentChecked, setConsentChecked] = React.useState<boolean>(false);
  const [showDigiExplain, setShowDigiExplain] = React.useState<boolean>(false);
  const [DGLToggle, setDGLToggle] = React.useState<boolean>(false);
  const [uploadDocToggle, setUploadDocToggle] = React.useState<boolean>(false);
  const documentArray = ["Adhaar", "Driving License", "Voter Id", "Passport"];
  const [dgLockerLinkUrl, setdgLockerLinkUrl] = React.useState<string>("");
  const [errCode, setErrCode] = React.useState<number | string>("");

  // const dgLockerLinkUrl =
  //   digiLockerUrl +
  //   "?client_id=" +
  //   domain.clientId +
  //   "&response_type=code&redirect_uri=" +
  //   domain.redirectUri +
  //   "?&state=" +
  //   JSON.stringify({});

  const handleAppendedStage = async () => {
    const clientPartialDetails_ao = await JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );

    localStorage.setItem(
      "appendedStage",
      JSON.stringify({
        applicationId: clientPartialDetails_ao.applicationId,
        showDigiLockerPage: true,
        showPersonalDocPage: false,
      })
    );
  };

  React.useEffect(() => {
    window.sessionStorage.setItem("stageInfoId", "9");
    window.scroll(0, 0);

    handleAppendedStage();
  }, []);

  // Get Digilocker link
  React.useEffect(() => {
    if (domain.digiLockerSource == 1) {
      const dgLockerLinkUrlLocal =
        digiLockerUrl +
        "?client_id=" +
        domain.clientId +
        "&response_type=code&redirect_uri=" +
        domain.redirectUri +
        "?&state=" +
        JSON.stringify({});
      setdgLockerLinkUrl(dgLockerLinkUrlLocal);
    }
    if (domain.digiLockerSource == 2) {
      const getDigiLinkObj: any = {};
      getDigiLinkObj.ApplicationId = "";
      AGetDigiLink(
        (res: any) => getDigilockerLink(res, getDigiLinkObj),
        getDigiLinkObj
      );
    }
  }, []);

  // Clervertap event: DigiLocker screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.digiLockerPage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: "NO",
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      DIGILOCKER_PROCESS: "YES",
    };

    ClevertapService.pushEvent(data);
  }, []);

  const cleverTapConnectDLEvent = () => {
    const data = {
      EventName:
        cleverTapEvents.registration.digiLockerPage
          .CLICK_ON_CONNECT_TO_DIGILOCKER,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: "NO",
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      DIGILOCKER_PROCESS: "YES",
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);

    // Apps flyer event for click on proceed on pan details page
    AppsFlyerService.pushEvent(
      appsFlyerEvents.registration.digiLockerPage.CLICK_ON_CONNECT_TO_DIGILOCKER
    );
  };

  function handleContinue(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    event.preventDefault();
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    clientPartialDetails_ao.aadharLinkedChoice = false;
    window.localStorage.setItem(
      "clientPartialDetails_ao",
      JSON.stringify(clientPartialDetails_ao)
    );
    history.push(dashboardUrl.profile);
  }

  //new code
  let DgLockerToggle = () => {
    console.log("DGL");
    setDGLToggle(true);
    setUploadDocToggle(false);
  };

  let uploadDocumentsToggle = () => {
    console.log("UD");
    setUploadDocToggle(true);
    setDGLToggle(false);
  };

  function getDigilockerLink(res: any, getDigiLinkObj: any) {
    if (domain.digiLockerSource == 2) {
      if (!res || res.error) {
        setErrCode(res.error);
      } else {
        if (res.data) {
          if (res.data.access_token) {
            setdgLockerLinkUrl(res.data.url);
            const clientPartialDetails_ao = JSON.parse(
              window.localStorage.getItem("clientPartialDetails_ao") || "{}"
            );
            clientPartialDetails_ao.signzydt = res.data.access_token;
            window.localStorage.setItem(
              "clientPartialDetails_ao",
              JSON.stringify(clientPartialDetails_ao)
            );
          } else {
            setErrCode(errorMessage.newDigilockerError);
          }
        } else {
          // setIsLoading(false);
          setErrCode(errorMessage.newDigilockerError);
        }
      }
    }
  }

  return (
    <div className="dgLk">
      <div className="prfl">
        <ProfileImage color="rgba(112, 45, 255, 0.1)">
          <img src={dgLocker} className="dg_logo" alt="dgLocker" />
        </ProfileImage>
      </div>
      <div className="dg_hdng">
        <label className="heading">Personal Information</label>
      </div>
      <div className="dg_info" style={{ width: "56%" }}>
        <label>
          To complete your account opening process, you can choose any of the
          following options:
        </label>
      </div>
      <div className="row ButtonRow">
        <div className="inlineDiv">
          <LightBlueButton
            isLightBlue={true}
            btnName={"DigiLocker"}
            onClick={DgLockerToggle}
            clicked={DGLToggle}
          />
        </div>
        <div className="inlineDiv">
          <LightBlueButton
            btnName={"Without DigiLocker"}
            onClick={uploadDocumentsToggle}
            clicked={uploadDocToggle}
          />
        </div>
      </div>
      {DGLToggle ? (
        <div>
          <div className="dg_info">
            <label className="info" style={{ color: "#173f80  " }}>
              DigiLocker is a Govt. of India initiative, allowing us to access
              authorized digital copy of your documents. This will help us open
              your account digitally.
            </label>
            <div className="dg_points">
              <div className="dg_points_heading">Benefits</div>
              <div className="pts">
                <img src={arrowPoint} alt="=>" className="pointer_img" />
                <label className="pts_lb">Its simple and convinient</label>
              </div>
              <div className="pts mgt20">
                <img src={arrowPoint} alt="=>" className="pointer_img" />
                <label className="pts_lb">You can share documents online</label>
              </div>
              <div className="pts mgt20">
                <img src={arrowPoint} alt="=>" className="pointer_img" />
                <label className="pts_lb">
                  Built-in cloud storage space links to your Adhaar (UIDAI)
                  number.
                </label>
              </div>
              <div className="pts mgt20">
                <img src={arrowPoint} alt="=>" className="pointer_img" />
                <label className="pts_lb">
                  Documments are issued by registered issuers such as registrar
                  office, icome tax department, CBSE, etc.
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {uploadDocToggle ? (
        <div className="sampleDocDiv">
          <div className="dg_info">
            <label className="info" style={{ color: "#173f80  " }}>
              Upload any of the documents mentioned below.
            </label>
          </div>
          <div className="docListApproval">
            {documentArray.map((item, index) => (
              <OptionDocumentList key={index} docName={item} docInfo={item} />
            ))}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="dg_points">
        <div className="dg_btn">
          <Button
            btnName="Continue"
            disabled={!uploadDocToggle && !DGLToggle}
            isLoading={false}
            onClick={() => {
              gtmService({ action: "DIGI_LOCKER", label: "Step_Complete" });
              cleverTapConnectDLEvent();
              if (DGLToggle) {
                localStorage.removeItem("appendedStage");
                window.location.href = dgLockerLinkUrl;
              }
              if (uploadDocToggle) {
                // localStorage.setItem(
                //   "appendedStage",
                //   JSON.stringify({
                //     applicationId: GlobalStore.applicationID,
                //     showDigiLockerPage: false,
                //     showPersonalDocPage: true,
                //   })
                // );
                history.push(dashboardUrl.personalIfo);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DgLocker;
