import React from 'react';
import ProfileImage from '../../UI/ProfileImage/ProfileImage'
import './NRI.scss'

const NRI = () => (
    <div className='nri'>
        <div className='nriLogo'>
            <ProfileImage color='rgba(6, 99, 226, 0.1)' />
        </div>
        <p className='nriHeading'>Non Resident of India?</p>
        <p className='nriInfo'>Seems like you are a resident of United Kingdom. Someone from our team would be in touch with you shortly for opening account</p>
        <p className='nriLink'>Back to Home</p>
    </div>
)

export default NRI;