import React from "react";
import EdelLogoCenter from "../../UI/EdelLogoCenter/EdelLogoCenter";
import "./Layout.scss";
import { useHistory, useLocation } from "react-router-dom";
import {
  parseQuery,
  redirectToEntryPoint,
  clientPartialDetailsToStore,
  setStageData,
} from "../../Services/globalService";
import { containerUrl, dashboardUrl,statusUrl } from "../../utils/constants";
import GlobalStore from "../../store/globalStore";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import { checkModuleCompleteness } from "../../Profile/ProfileNavBar/ProfileNavBar";

export interface RegistrationLayoutProps {
  isSubmissionRoute?: boolean;
  supportNeeded?: boolean;
}

const RegistrationLayout: React.FC<RegistrationLayoutProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  props.isSubmissionRoute && clientPartialDetailsToStore();

  React.useEffect(() => {
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    console.log(GlobalStore.applicationID, "glo works");
    console.log(clientPartialDetails_ao.applicationId, "local works");
    if (
      GlobalStore.applicationID !== "" ||
      clientPartialDetails_ao.applicationId
    ) {
      AGetStageCompletionStatus(
        (res: any) => getClientStageDetails(res),
        GlobalStore.applicationID || clientPartialDetails_ao.applicationId
      );
    }

    // if (props.isSubmissionRoute && history.action === 'POP')
    //     history.push(redirectToEntryPoint());
    // else {
    //     const queryObject = parseQuery(location.search);
    //     if (queryObject && queryObject.applicationid && !queryObject.type) {
    //         setIsSubmit(true);
    //         if (queryObject.isPOA === 'true')
    //             history.replace(containerUrl.poa);
    //          else if(GlobalStore.stageStatus.isesigncompleted && !GlobalStore.stageStatus.iskraesigncompleted){
    //             history.replace(dashboardUrl.eSign);
    //          }
    //         else
    //             history.replace(containerUrl.submit);
    //     }
    // }
  }, []);
  function getClientStageDetails(res: any) {
    if (!res || res.error) {
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        //set stage api data to GlobalStore

        setStageData(responseObj);

        //check completeness of stages
        if (props.isSubmissionRoute && history.action === "POP")
          history.push(redirectToEntryPoint());
        else {
          const queryObject = parseQuery(location.search);
          if (queryObject && queryObject.applicationid && !queryObject.type) {
            setIsSubmit(true);
            if (queryObject.isPOA === "true") history.replace(containerUrl.poa);
            else if (queryObject.isEsign === "K") {
              history.replace(containerUrl.submit);
            } else if (queryObject.isEsign === "A" && !responseObj.iskraesigncompleted) {
              history.replace(dashboardUrl.eSign + "?sc=k");
              //history.replace(statusUrl.docVerificationInProgress);
            }else if (queryObject.isEsign === "A" && responseObj.iskraesigncompleted) {
              history.replace(containerUrl.submit);
            } else if (!queryObject.isEsign) {
              history.replace(dashboardUrl.eSign + "?sc=k");
            }
          }
        }
      }
    }
  }

  return (
    <>
      {!isSubmit && (
        <div className="regLayout">
          <EdelLogoCenter />
          {props.children}
        </div>
      )}
    </>
  );
};

export default RegistrationLayout;
