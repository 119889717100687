const servicesList = {
  getCities: "getCities",
  getEnumList: "GetEnumList",
  getEmployeeDetailsByPan: "GetEmployeeDetailsByPan",
  getPanUtiDetails: "getPanUtiDetails",
  getDocumentDetails: "getDocumentDetails",
  getArea: "getArea",
  getFullAddress: "getFullAddress",
  getRelationshipData: "getRelationshipData",
  getKYCDetails: "getKYCDetails",
  saveKYCDetails: "saveKYCDetails",
  getStageCompletionStatus: "getStageCOmpletionStatus",
  getCacheData: "getCacheData",
  checkClientProfilingData: "checkClientProfilingData",
  getClientProfilingQuestion: "getClientProfilingQuestion",
  saveClientProfilingQuestion: "saveClientProfilingQuestion",
  getClientProfilingDetails: "getClientProfilingDetails",
  getOCRDocumentResponseData: "getOCRDocumentResponseData",
  getDistinctBankList: "getDistinctBankList",
  getBankCities: "getBankCities",
  getBankBranch: "getBankBranch",
  getBankDetailsFromIfsc: "getBankDetailsFromIfsc",
  getBankDetails: "getBankDetails",
  saveBankDetails: "saveBankDetails",
  getExtractDocumentData: "getExtractDocumentData",
  uploadDocumentToS3: "uploadDocumentToS3",
  saveDocumentDetails: "saveDocumentDetails",
  saveRelationshipDetails: "saveRelationshipDetails",
  getOtp: "getOtp",
  getOtpNew: "getOtpNew",
  validateOtp: "validateOtp",
  validateOtpNew:"validateOtpNew",
  getBrokerage: "getBrokerage",
  getBrokerageDetails: "getBrokerageDetails",
  saveBrokerageDetails: "saveBrokerageDetails",
  getBrokerageSegmentDetails: "getBrokerageSegmentDetails",
  saveBrokerageSegmentDetails: "saveBrokerageSegmentDetails",
  getBeneficiaryName: "getBeneficiaryName",
  downloadDocumentFromS3: "downloadDocumentFromS3",
  getClientDetailsByPan: "getClientDetailsByPan",
  getClientDataByPanOrLead: "getClientDataByPanOrLead",
  checkPanInSystem: "checkPanInSystem",
  panLinkedWithAadhar: "panLinkedWithAadhar",
  saveIpverificationDetails: "saveIpverificationDetails",
  saveUploadedDocumentDetails: "saveUploadedDocumentDetails",
  margeMultipleImagestoPDF: "margeMultipleImagestoPDF",
  getPichainBeneficiaryName: "getPichainBeneficiaryName",
  getCKYCDetails: "getCKYCDetails",
  savePanDobDetails: "savePanDobDetails",
  getKraDetails: "getKraDetails",
  redisDigilockerDoc: "redisDigilockerDoc",
  getEmployeeDetails: "getEmployeeDetails",
  searchLeadByName: "searchLeadByName",
  createLead: "createLead",
  uploadIPVMerge: "uploadIPVMerge",
  imagetoVideoIpv: "imagetoVideoIpv",
  generateAccessTokenForDigilockerJavaMW:
    "generateAccessTokenForDigilockerJavaMW",
  getDocListFromDigilocker: "getDocListFromDigilocker",
  getSelectedDocument: "getSelectedDocument",
  selfieLink: "selfieLink",
  getEsignLink: "getEsignLink",
  updateUtmInfo: "updateUtmInfo",
  getAccountcodeData: "getAccountcodeData",
  createApplicationTicket: "createApplicationTicket",
  whatsUpAppInitiateCommunication: "whatsUpAppInitiateCommunication",
  whatsUpAppConsent: "whatsUpAppConsent",
  generateKYCPdf: "generateKYCPdf",
  getIPVOTP: "getIPVOTP",
  ErrorBank: "ErrorBank",
  getDocumentAutoQcDetails: "getDocumentAutoQcDetails",
  saveAddressDetails: "saveAddressDetails",
  getCityByPincode: "getCityByPincode",
  verifyDocumentData: "verifyDocumentData",
  getAddressDetails: "getAddressDetails",
  saveArea: "saveArea",
  checkPhotoLiveness: "checkPhotoLiveness",
  checkLocation: "checkLocation",
  updatePanAddharLinkStatus: "updatePanAddharLinkStatus",
  createUCCRegCode: "createUCCRegCode",
  CheckRAAccount: "CheckRAAccount",
  APanVerification: "APanVerification",
};

export default servicesList;
