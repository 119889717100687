import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Selfie from '../components/Selfie/Selfie';
import { containerUrl, dashboardUrl } from '../components/utils/constants';
import RegLayout from '../components/Registration/Layout/Layout';
import VideoVerification from '../components/VideoVerification/VideoVerification';
import SmsLinksLayout from '../components/SmsLinksLayout/SmsLinksLayout';
import InstanHelp from '../components/InstantHelp/InstantHelp';

const SmsRoutes = () => (
    <Switch>
        <Route
            path={containerUrl.selfie}
            exact
            render={(props) => (
                <InstanHelp panPageFlag={false}>
                <RegLayout>
                    <SmsLinksLayout {...props}>
                        <Selfie />
                    </SmsLinksLayout>
                </RegLayout>
                 </InstanHelp>
            )}
        />
        <Route
            path={'/ipvLink'}
            exact
            render={(props) => (
                <InstanHelp panPageFlag={false}>
                <RegLayout>
                    <SmsLinksLayout {...props}>
                        <VideoVerification />
                    </SmsLinksLayout>
                </RegLayout>
                 </InstanHelp>
            )}
        />
    </Switch>
);

export default SmsRoutes;
