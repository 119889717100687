import React from 'react';
import './BankHome.scss';
import BankForm from "../BankForm/BankForm";
import cleverTapEvents from '../../../config/cleverTapEvents';
import useQuery from '../../../customHooks/useQuery';
import { ClevertapService } from '../../Services/clevertapService';
import GlobalStore from '../../store/globalStore';

export interface BankHomeProps {

}

const BankHome: React.FC<BankHomeProps> = (props) => {
    // Fetch query params for clervertap
    const quryParams = useQuery();
    const UTM_SOURCE = quryParams.get('utm_source') ? quryParams.get('utm_source') : '';
    const UTM_MEDIUM = quryParams.get('utm_medium') ? quryParams.get('utm_medium') : '';
    const UTM_CAMPAIGN = quryParams.get('utm_campaign') ? quryParams.get('utm_campaign') : '';

    React.useEffect(() => {
        window.sessionStorage.setItem('stageInfoId', '2');
    }, []);

    // Clervertap event: Bank screen is loaded
    React.useEffect(() => {
        const data = {
            EventName: cleverTapEvents.registration.bankPage.PAGE_LOAD,
            InitiationPlatform: '',
            UTM_SOURCE,
            UTM_MEDIUM,
            UTM_CAMPAIGN,
            KRA: GlobalStore.isKRA,
            ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
            DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
            LEAD_ID: GlobalStore.leadId,
            REF_ID: GlobalStore.applicationID,
        };

        ClevertapService.pushEvent(data);
    }, []);

    return (
        <div className='bnklHome'>
            <div className='bnklHeading'>
                Bank Details
            </div>
            <div className="bnkSubHeading">
                Provide your bank details through which you would like to conduct transactions.
            </div>
            <BankForm UTM_SOURCE={UTM_SOURCE} UTM_MEDIUM={UTM_MEDIUM} UTM_CAMPAIGN={UTM_CAMPAIGN} />
        </div>
    );
}

export default BankHome;