import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";

export const AGetStageCompletionStatus = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "DIYAPI/GetStageCompletionStatus",
      body: {
        ApplicationId: applicationid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getStageCompletionStatus,
    },
    callback
  );

  // apiService(
  //     {
  //         url: 'eco/get-stage-completion-status',
  //         body: {
  //             "applicationid": applicationid
  //         },
  //         method: "POST",
  //     },
  //     callback
  // );
};
