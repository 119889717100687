import React, { useState } from "react";
import "./ProfileForm.scss";
import InputField from "../../UI/InputField/InputField";
import DropdownSearch from "../../UI/DropdownSearch/DropdownSearch";
import FamilyMember, { IFamilyMember } from "../FamilyMember/FamilyMember";
import Tag from "../../UI/Tag/Tag";
import Radio from "../../UI/Radio/Radio";
import {
  fatherSpouseRelationList,
  dateOptionsList,
  monthOptionsList,
  yearOptionsList,
  addressType,
  clientGroup4,
  retailAccCategory,
  employeeAccCategory,
  emailVerificationBody,
  dashboardUrl,
  poaTrueMsg,
  poaFalseMsg,
  PCGAccCategory,
} from "../../utils/constants";
import Toggle from "../../UI/Toggle/Toggle";
import Button from "../../UI/Button/Button";
import InfoModal from "../../UI/InfoModal/InfoModal";
import DeviceContext from "../../Services/identifyDevice";
import {
  AGetEnumList,
  AGetRelationshipData,
  ASaveRelationshipData,
  ASaveKycDetails,
  AGetKycDetails,
  ARedisDigilockerDoc,
  AGetOcrResponseDetails,
  AGetDocumentidByApplicationid,
  AGetArea,
  AGetFullAddress,
  ASavePinCity,
  AGetCityByPincode,
} from "../../Services/dashboard/profileApiService";
import { IListObj } from "../../types/genericTypes";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import GlobalStore from "../../store/globalStore";
import {
  convertEnumIntoIObj,
  completeModuleStatus,
  utmDurationInfoObj,
  incompleteStageNavigation,
  splitFullName,
  capitalizeWords,
  ageCalculator,
} from "../../Services/globalService";
import RegOtp from "../../Registration/RegOtp/RegOtp";
import { phoneNo, emailRegex } from "../../utils/regex";
import {
  AGetCities,
  AGetEmailOtp,
  AGetMobileOtp,
  AValidateEmailOtp,
  AValidateMobileOtp,
} from "../../Services/registration/registrationApiService";
import AddressField, {
  IAddressFormat,
  addressErrorObj,
  CkycAddressFormat,
} from "../AddressField/AddressField";
import { useHistory } from "react-router-dom";
import NriDetailForm, {
  INriDetailField,
  initialNriDetail,
} from "../NriDetailForm/NriDetailForm";
import { getProfileDetailsFromAadhar } from "../../Registration/ShareAdd/ShareAdd";
import { AGetEmployeeByPan } from "../../Services/registration/panDobApiService";
import moment from "moment";
import Calender from "../../UI/Calender/Calender";
import gtmService from "../../Services/gtmService";
import useQuery from "../../../customHooks/useQuery";
import cleverTapEvents from "../../../config/cleverTapEvents";
import { ClevertapService } from "../../Services/clevertapService";
import { repairAddressWithConditions } from "../../../components/utils/validation";
import { AppsFlyerService } from "../../Services/appsFlyerService";
import appsFlyerEvents from "../../../config/appsFlyerEvents";
import config from "../../../config/domain";
import nomineeDocsIds from "../../../config/nomineeDocsId";
import DocumentBrowse, {
  DocDetailFormat,
} from "../../UI/DocumentBrowse/DocumentBrowse";
import { AUploadDocument } from "../../Services/dashboard/brokerageServices";
import { pan } from "../../utils/regex";
import { errorMessage } from "../../utils/errorMessage";
import { AGetDocStatus } from "../../Services/dashboard/docVerificationAPIServices";
import Checkbox from "../../UI/Checkbox/Checkbox";
import { nomineeMatch } from "../ClientNomineeGuardianMatch/ClientNomineeGuardianMatch";
import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";
import { checkESignSubmitted } from "../../utils/eSignSubmitHandler";
// import useReactPath from "../../../pathhook";
export interface ProfileFormProps {}

export const initialAddressObj: IAddressFormat = {
  addressLine1: "",
  City: "",
  Country: "",
  District: "",
  PinCode: "",
  State: "",
  Area: "",
  CountryCode: 0,
  StateCode: 0,
};

export const ckycAddressObj: CkycAddressFormat = {
  addressLine1: "",
  corrAddress: "",
  sameAsPermanent: true,
  city: "",
  country: "",
  district: "",
  pincode: "",
  corrPincode: "",
  state: "",
  area: "",
  countrycode: 0,
  statecode: 0,
};

const nomineeIdentificationList = [
  // TODO: This is not done from frontend and backend. Here we need to give user to upload both photo and singnature. This is not done from backend so as per nitin we are not allow user for this option now.

  {
    display: "PAN Card no",
    value: 5,
  },
  {
    display: "Aadhar Card no",
    value: 2,
  },
  {
    display: "Saving Bank account no.",
    value: 3,
  },
  {
    display: "Demat Account ID",
    value: 4,
  },
  {
    display: "Photo and Signature of the Nominee/guardian",
    value: 1,
  },
];
const bankAccountType = [
  {
    display: "Ordinary Resident Demat and Trading Account",
    value: 1,
  },
  {
    display: "BSDA Resident Demat and Trading Account",
    value: 2,
  },
];

export const initialDocObj: DocDetailFormat = {
  id: "",
  disable: false,
  uploadPer: 0,
  hideProgress: false,
  fileName: "",
  showFile: true,
  imgUrl: "",
  documentcategory: "",
  documentCategoryId: "",
  documentId: 0,
  mimeType: "",
  base64: "",
  isWatsappFlag: false,
};

export interface RelationshipData {
  [key: string]: Array<IFamilyMember>;
}

const ProfileForm: React.FC<ProfileFormProps> = () => {
  // Fetch query params for clervertap
  const quryParams = useQuery();
  const UTM_SOURCE = quryParams.get("utm_source")
    ? quryParams.get("utm_source")
    : "";
  const UTM_MEDIUM = quryParams.get("utm_medium")
    ? quryParams.get("utm_medium")
    : "";
  const UTM_CAMPAIGN = quryParams.get("utm_campaign")
    ? quryParams.get("utm_campaign")
    : "";

  const history = useHistory();

  const [errors, setErrors] = React.useState<{
    [key: string]: string | undefined;
  }>({});
  const [errCode, setErrCode] = React.useState<number | string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<Date>(new Date());
  const [ocrDetails, setOcrDetails] = React.useState({ address: "" });

  const [isSeniorCitizen, setIsSeniorCitizen] = React.useState<boolean>(false);
  const [occupationList, setOccupationList] = React.useState<Array<IListObj>>(
    []
  );
  const [incomeList, setIncomeList] = React.useState<Array<IListObj>>([]);
  const [educationList, setEducationList] = React.useState<Array<IListObj>>([]);
  const [maritalStatusList, setMaritalStatusList] = React.useState<
    Array<IListObj>
  >([]);
  const [genderList, setGenderList] = React.useState<Array<IListObj>>([]);
  const [relationshipList, setRelationshipList] = React.useState<
    Array<IListObj>
  >([]);
  const [familyRelationList, setFamilyRelationList] = React.useState<
    Array<IListObj>
  >([]);
  const [familyRelationListMail, setFamilyRelationListMail] = React.useState<
    Array<IListObj>
  >([]);
  const [originalRelationList, setOriginalRelationList] = React.useState<
    Array<IListObj>
  >([]);
  const [titlePrefixList, setTitlePrefixList] = React.useState<Array<IListObj>>(
    []
  );
  const [countryList, setCountryList] = React.useState<Array<IListObj>>([]);

  const [relationshipData, setRelationshipData] =
    React.useState<RelationshipData>({});
  const [relationshipDataCount, setRelationshipDataCount] = React.useState<any>(
    {}
  );

  const [showExplain, setShowExplain] = React.useState<boolean>(false);
  const [showNominee, setShowNominee] = React.useState<boolean>(false);
  const [numOfNominee, setNumOfNominee] = React.useState<number>(0);

  const [email, setEmail] = React.useState<string>(GlobalStore.user.email);
  const [phone, setPhone] = React.useState<string>(GlobalStore.user.mobile);
  const [showEmailBtn, setShowEmailBtn] = React.useState<boolean>(true);
  const [showMobileBtn, setShowMobileBtn] = React.useState<boolean>(false);
  const [showEmailOtp, setShowEmailOtp] = React.useState<boolean>(false);
  const [showMobileOtp, setShowMobileOtp] = React.useState<boolean>(false);
  const [disableOtp, setDisableOtp] = React.useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(true);
  const [isMobileVerified, setIsMobileVerified] = React.useState<boolean>(true);
  const [invalidOtp, setInvalidOtp] = React.useState<boolean>(false);

  const [permanentAddress, setPermanentAddress] =
    React.useState<IAddressFormat>({ ...initialAddressObj });
  const [kycAddress, setKycAddress] = React.useState<IAddressFormat>({
    ...initialAddressObj,
  });
  const [correspondanceAddress, setCorrespondanceAddress] =
    React.useState<IAddressFormat>({ ...initialAddressObj });
  const [nomineeAddress, setNomineeAddress] = React.useState<IAddressFormat>({
    ...initialAddressObj,
  });
  const [guardianAddress, setGuardianAddress] = React.useState<IAddressFormat>({
    ...initialAddressObj,
  });
  const [nomineeDocuments, setNomineeDocuments] = React.useState<any>({
    nomineeOne: {},
    nomineeTwo: {},
    nomineeThree: {},
  });
  const [nomineeDocumentsArray, setNomineeDocumentsArray] = React.useState<any>(
    []
  );
  // check if new docs flag
  let [newUploads, setNewUploads] = React.useState<any>([
    {
      isPhoto: false,
      isSiganture: false,
      guardian: { isPhoto: false, isSignature: false },
    },
    {
      isPhoto: false,
      isSiganture: false,
      guardian: { isPhoto: false, isSignature: false },
    },
    {
      isPhoto: false,
      isSiganture: false,
      guardian: { isPhoto: false, isSignature: false },
    },
  ]);

  const [permErrorObj, setPermErrorObj] = React.useState<{
    [key in keyof IAddressFormat]?: string;
  }>({
    ...addressErrorObj,
  });
  const [corrErrorObj, setCorrErrorObj] = React.useState<{
    [key in keyof IAddressFormat]?: string;
  }>({
    ...addressErrorObj,
  });
  const [nomErrorObj, setNomErrorObj] = React.useState<{
    [key in keyof IAddressFormat]?: string;
  }>({
    ...addressErrorObj,
  });
  const [guardErrorObj, setGuardErrorObj] = React.useState<{
    [key in keyof IAddressFormat]?: string;
  }>({
    ...addressErrorObj,
  });

  const [sameAsPermanent, setSameAsPermanent] = React.useState<boolean>(true);
  const [sameAsNominee, setSameAsNominee] = React.useState<boolean>(false);

  const [isNomineeMinor, setIsNomineeMinor] = React.useState<boolean>(false);

  const [gender, setGender] = React.useState<string>(
    GlobalStore.personaldetails.gender || ""
  );
  const [maritalStatus, setMaritalStatus] = React.useState<string>("S");
  const [fatherOrSpouse, setFatherOrSpouse] = React.useState<string>("01");
  const [fatherOrSpouseName, setFatherOrSpouseName] = React.useState<string>(
    GlobalStore.personaldetails.fsfullName || ""
  );
  const [kycFatherName, setKycFatherName] = React.useState<string>("");
  const [motherName, setMotherName] = React.useState<string>(
    GlobalStore.personaldetails.motherfullName || ""
  );

  const [eduQualification, setEduQulification] = React.useState<string>("");
  const [occupation, setOccupation] = React.useState<string>("");
  const [politicalVal, setPoliticalVal] = React.useState<"0" | "1" | "2">("0");
  const [indianResident, setIndianResident] = React.useState<boolean>(true);
  const [annualIncome, setAnnualIncome] = React.useState<string>("");
  const [netWorth, setNetWorth] = React.useState<string>("");
  const [placeOfBirth, setPlaceOfBirth] = React.useState<string>("");
  const [periodicSettlement, setPeriodicSettlement] = React.useState<"1" | "2">(
    "2"
  );

  const [nomDob, setNomDob] = React.useState<Date>();

  const [nomTitle, setNomTitle] = React.useState<string>("");
  const [nomFullName, setNomFullName] = React.useState<string>("");
  const [nomRelation, setNomRelation] = React.useState<string>("");
  const [nomRelationOther, setNomRelationOther] = React.useState<string>("");
  const [validPan, setValidPan] = React.useState<any>([]);

  // New nominee state for multiple nominee functionality
  const [nomiDetails, setNomiDetails] = React.useState<any>([]);

  const [guardTitle, setGuardTitle] = React.useState<string>("");
  const [guardFullName, setGuardFullName] = React.useState<string>("");
  const [guardRelation, setGuardRelation] = React.useState<string>("");
  const [guardRelationOther, setGuardRelationOther] =
    React.useState<string>("");

  const [guardEduQualification, setGuardEduQulification] =
    React.useState<string>("");
  const [guardOccupation, setGuardOccupation] = React.useState<string>("");
  const [guardPoliticalVal, setGuardPoliticalVal] = React.useState<
    "0" | "1" | "2"
  >("0");
  const [guardAnnualIncome, setGuardAnnualIncome] = React.useState<string>("");
  const [guardNetWorth, setGuardNetWorth] = React.useState<string>("");
  const [guardPlaceOfBirth, setGuardPlaceOfBirth] = React.useState<string>("");

  const [nriDetailsObj, setNriDetailsObj] = React.useState<INriDetailField>({
    ...initialNriDetail,
  });
  // const [hideAddressKra, setHideAddressKra] = React.useState<boolean>(false);
  // const [hideAddressKyc, setHideAddressKyc] = React.useState<boolean>(false);
  const [hideAddress, setHideAddress] = React.useState<boolean>(false);

  const [staffCode, setStaffCode] = React.useState<string>("");
  const [aadharName, setAadharName] = React.useState<string>("");
  const [isAadharMasked, setIsAadharMasked] = React.useState<boolean>(true);
  const [nomIdentification, setNomIdentification] = React.useState<number>(0);
  const [nomPhotoId, setNomPhotoId] = React.useState<DocDetailFormat>({
    ...initialDocObj,
  });
  const [photoBase64, setPhotoBase64] = React.useState<string>("");
  const [nomAdharNum, setNomAdharNum] = React.useState<string>("");
  const [nomBankAccountNum, setNomBankAccountNum] = React.useState<string>("");
  const [nomDematAccountNum, setNomDematAccountNum] =
    React.useState<string>("");
  const [globalError, setGlobalError] = React.useState<string>("");
  const [addlIdentDetailsValue, setAddlIdentDetailsValue] = useState(true);
  const [regectedAddressDoc, setRegectedAddressDoc] = React.useState(false);

  const [POAType, setPOAType] = React.useState(true);
  const [POATypeMsg, setPOATypeMsg] = React.useState(poaTrueMsg);
  const [defaultAccountType, setDefaultAccountType] = React.useState<string>(
    "Ordinary Resident Demat and Trading Account"
  );
  const [defaultSetting, setDefaultSetting] = React.useState<boolean>(false);

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [errorMatchDetails, setErrorMatchDetails] = React.useState<any>("");
  const [accountTypeToggle, setAccountTypeToggle] =
    React.useState<boolean>(false);

  let addressEdited = false;

  let [imgOfAddlIdentDetails, setImgOfAddlIdentDetails] = React.useState<any>([
    {
      nomineeAddDtls: { isPhoto: true, isSignature: true },
      guardianAddDtls: { isPhoto: true, isSignature: true },
    },
    {
      nomineeAddDtls: { isPhoto: true, isSignature: true },
      guardianAddDtls: { isPhoto: true, isSignature: true },
    },
    {
      nomineeAddDtls: { isPhoto: true, isSignature: true },
      guardianAddDtls: { isPhoto: true, isSignature: true },
    },
  ]);

  const nomineeList = [
    {
      display: "One",
      value: 1,
    },
    {
      display: "Two",
      value: 2,
    },
    {
      display: "Three",
      value: 3,
    },
  ];

  const [cities, setCities] = React.useState([]);
  const [genderError, setGenderError] = React.useState<string>("");
  const [SettlementError, setSettlementError] = React.useState<string>("");
  const [togSettlement, settogSettlement] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (gender) {
      setGenderError("");
    } else {
      setGenderError("please Select Gender");
    }
  }, [gender]);

  const [familyRelationshipCount, setFamilyRelationshipCount] =
    React.useState<any>({
      mobile: {
        self: 0,
        spouse: 0,
        dependentChild: 0,
        dependentParents: 0,
      },
      email: {
        self: 0,
        spouse: 0,
        dependentChild: 0,
        dependentParents: 0,
      },
    });

  React.useEffect(() => {
    if (showNominee && nomiDetails && nomiDetails.length > 0) {
      const errorMsg = nomineeMatch(showNominee, nomiDetails);
      setErrorMatchDetails(errorMsg);
    }
  }, [nomiDetails]);
  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
      setIsLoading(false);
    }, 3000);
  }, [nomineeDocumentsArray]);

  // let path = useReactPath();
  React.useEffect(() => {
    // GlobalStore.pathname = path;
    AGetKycDetails(getKycDetails, GlobalStore.applicationID);
    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    if (clientPartialDetails_ao.isSeniorCitizen) {
      setIsSeniorCitizen(true);
      setShowNominee(true);
    }
    console.log(
      clientPartialDetails_ao,
      " = clientPartialDetails_ao || phone = ",
      phone
    );
    if (!phone) {
      setPhone(clientPartialDetails_ao.mobile);
    }
  }, []);

  const togSettlementclick = () => {
    if (togSettlement === true) {
      setSettlementError(
        "Connect our nearest Branch Office, this account cannot be processed online."
      );
      settogSettlement(false);
    } else {
      setSettlementError("");
      settogSettlement(true);
    }
  };

  React.useEffect(() => {
    window.scroll(0, 0);
    setIsLoading(true);
    AGetEnumList(getEnumList, "");

    const clientPartialDetails_ao = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );

    GlobalStore.isKYC = clientPartialDetails_ao.isKYC;
    GlobalStore.isKRA = clientPartialDetails_ao.isKRA;
    GlobalStore.isDigiLocker = clientPartialDetails_ao.isDigiLocker;
    !clientPartialDetails_ao.sameAsPermanent &&
      clientPartialDetails_ao.addressLine1 !==
        clientPartialDetails_ao.corrAddress &&
      setSameAsPermanent(false);
    if (
      clientPartialDetails_ao.addressLine1 &&
      clientPartialDetails_ao.PinCode
    ) {
      let permAddLocal = { ...permanentAddress };
      permAddLocal.addressLine1 = clientPartialDetails_ao.addressLine1;
      permAddLocal.PinCode = clientPartialDetails_ao.PinCode;
      setPermanentAddress(permAddLocal);
    }
    if (!clientPartialDetails_ao.sameAsPermanent) {
      let corrsAddLocal = { ...correspondanceAddress };
      corrsAddLocal.addressLine1 = clientPartialDetails_ao.corrAddress;
      corrsAddLocal.PinCode = clientPartialDetails_ao.corrPincode;
      setCorrespondanceAddress(corrsAddLocal);
    }
    const panFromLocalStorage = JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );
    let panNum = "";
    if (
      GlobalStore.personaldetails.pan != undefined ||
      GlobalStore.personaldetails.pan != ""
    ) {
      panNum = GlobalStore.personaldetails.pan;
    } else if (panFromLocalStorage.pan != "") {
      panNum = panFromLocalStorage.pan != "" ? panFromLocalStorage.pan : "";
    }
    let isValidPan = pan.test(panNum);
    if (isValidPan) {
      AGetEmployeeByPan(checkIsPanEmployee, panNum);
    }
  }, [sameAsPermanent]);

  React.useEffect(() => {
    if (nomDob !== undefined) {
      nomDob && ageCalculator(nomDob) >= 18
        ? setIsNomineeMinor(false)
        : setIsNomineeMinor(true);
    }
  }, [nomDob]);

  // Clervertap event: Profile screen is loaded
  React.useEffect(() => {
    const data = {
      EventName: cleverTapEvents.registration.profilePage.PAGE_LOAD,
      InitiationPlatform: "",
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN,
      KRA: GlobalStore.isKRA,
      ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
      DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
      LEAD_ID: GlobalStore.leadId,
      REF_ID: GlobalStore.applicationID,
    };

    ClevertapService.pushEvent(data);
    AGetCities(getCities);
  }, []);

  // Checking if the address proof is rejected inorder to enable editing of address field for KYC/KRA clients
  const getDocumentStatus = (res: any) => {
    if (!res || res.error) {
      // setErrCode("164- Failed to get the document status for the application.");
      setErrCode(res.error);
    } else {
      if (res.data) {
        const regectedDoc = JSON.parse(res.data).filter(
          (item: any) => item.Status === "Rejected"
        );
        regectedDoc.map((doc: { ContentType: any }) => {
          if (doc.ContentType === "PROOF OF ADDRESS PERMANENT") {
            setRegectedAddressDoc(true);
            setHideAddress(false);
          }
        });
      }
    }
  };

  function getCities(res: any) {
    if (!res || res.error)
      // setErrCode('113 - Failed to fetch the list of cities, please reload.');
      setErrCode(res.error);
    else {
      if (res.data && res.data.city) {
        setCities(res.data.city);
        setErrCode(errCode);
      }
    }
  }
  function onPanDocUploadComplete(
    val: any,
    fileName: string,
    id: string,
    type: string,
    index: number,
    isGuardian: boolean
  ) {
    console.log("call from fun321");
    setIsLoading(true);

    nomPhotoId.imgUrl = "";
    setNomPhotoId(nomPhotoId);
    setPhotoBase64(val!.split(",")[1]);
    let uploadObj = {
      applicationid: GlobalStore.applicationID,
      base64document: val!.split(",")[1],
      filename: fileName,
    };
    AUploadDocument(
      (res: any) =>
        getUploadRes(res, id, val, fileName, index, isGuardian, type),
      uploadObj,
      (event: ProgressEvent) => onUploadProgress(event, id, fileName, type)
    );
  }

  // React.useEffect(() => {
  //   console.log("imgOfAddlIdentDetails value ", imgOfAddlIdentDetails);
  // },[imgOfAddlIdentDetails]);

  function getUploadRes(
    res: any,
    id: string,
    val: any,
    fileName: string,
    index: number,
    isGuardian: boolean,
    type: string
  ) {
    if (!res || res.error) {
      setIsLoading(false);
      // setErrCode(137);
      setErrCode(res.error);
    } else {
      if (res.data && res.data.Result) {
        var nomineeDocObj = nomineeDocuments;
        let imgOfAddlIdentDtls = JSON.parse(
          JSON.stringify(imgOfAddlIdentDetails)
        );
        let newUpload = newUploads;
        if (id === "nomPhotoId" || id === "guardianPhotoId") {
          if (id === "nomPhotoId") {
            newUpload[index].isPhoto = true;
          }

          if (id === "guardianPhotoId") {
            newUpload[index].guardian.isPhoto = true;
          }

          setIsLoading(false);
          const array = JSON.parse(JSON.stringify(nomiDetails));
          if (isGuardian) {
            imgOfAddlIdentDtls[index]["guardianAddDtls"]["isPhoto"] = true;
            setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
            if (index == 0) {
              nomineeDocObj.nomineeOne.guardianDetails.photo.fileName =
                res.data?.Guid;
              nomineeDocObj.nomineeOne.guardianDetails.photo.mimeType = type
                ? type
                : ".";
            }

            if (index == 1) {
              nomineeDocObj.nomineeTwo.guardianDetails.photo.fileName =
                res.data?.Guid;
              nomineeDocObj.nomineeTwo.guardianDetails.photo.mimeType = type
                ? type
                : ".";
            }

            if (index == 2) {
              nomineeDocObj.nomineeThree.guardianDetails.photo.fileName =
                res.data?.Guid;
              nomineeDocObj.nomineeThree.guardianDetails.photo.mimeType = type
                ? type
                : ".";
            }

            array[index]["guardianDetails"]["identificationDetail"]["value"] =
              res.data?.Guid;
          } else {
            imgOfAddlIdentDtls[index]["nomineeAddDtls"]["isPhoto"] = true;
            setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
            if (index == 0) {
              nomineeDocObj.nomineeOne.photo.fileName = res.data?.Guid;
              nomineeDocObj.nomineeOne.photo.mimeType = type ? type : ".";
            }

            if (index == 1) {
              nomineeDocObj.nomineeTwo.photo.fileName = res.data?.Guid;
              nomineeDocObj.nomineeTwo.photo.mimeType = type ? type : ".";
            }

            if (index == 2) {
              nomineeDocObj.nomineeThree.photo.fileName = res.data?.Guid;
              nomineeDocObj.nomineeThree.photo.mimeType = type ? type : ".";
            }
            array[index]["identificationDetail"]["value"] = res.data?.Guid;
            array[index]["error"]["identificationDetailValue"] = undefined;
            setAddlIdentDetailsValue(true);
          }
          setNomiDetails(array);
          // AextractPan((res: any) => storeOcrData(res, panOCR), panOCR);
          // if (photoBase64 !== undefined) {
          //     setErrCode('');
          // }
        }

        if (id === "nomSignature" || id === "guardianSignature") {
          setIsLoading(false);
          if (id === "nomSignature") {
            newUpload[index].isSignature = true;
          }

          if (id === "guardianSignature") {
            newUpload[index].guardian.isSignature = true;
          }
          // var nomineeDocObj = nomineeDocuments;

          const array = JSON.parse(JSON.stringify(nomiDetails));
          if (isGuardian) {
            imgOfAddlIdentDtls[index]["guardianAddDtls"]["isSignature"] = true;
            setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
            if (index == 0) {
              nomineeDocObj.nomineeOne.guardianDetails.signature.fileName =
                res.data?.Guid;
              nomineeDocObj.nomineeOne.guardianDetails.signature.mimeType = type
                ? type
                : ".";
            }

            if (index == 1) {
              nomineeDocObj.nomineeTwo.guardianDetails.signature.fileName =
                res.data?.Guid;
              nomineeDocObj.nomineeTwo.guardianDetails.signature.mimeType = type
                ? type
                : ".";
            }

            if (index == 2) {
              nomineeDocObj.nomineeThree.guardianDetails.signature.fileName =
                res.data?.Guid;
              nomineeDocObj.nomineeThree.guardianDetails.signature.mimeType =
                type ? type : ".";
            }

            array[index]["guardianDetails"]["identificationDetail"]["value"] =
              res.data?.Guid;
          } else {
            imgOfAddlIdentDtls[index]["nomineeAddDtls"]["isSignature"] = true;
            setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
            if (index == 0) {
              nomineeDocObj.nomineeOne.signature.fileName = res.data?.Guid;
              nomineeDocObj.nomineeOne.signature.mimeType = type ? type : ".";
            }

            if (index == 1) {
              nomineeDocObj.nomineeTwo.signature.fileName = res.data?.Guid;
              nomineeDocObj.nomineeTwo.signature.mimeType = type ? type : ".";
            }

            if (index == 2) {
              nomineeDocObj.nomineeThree.signature.fileName = res.data?.Guid;
              nomineeDocObj.nomineeThree.signature.mimeType = type ? type : ".";
            }
            array[index]["identificationDetail"]["value"] = res.data?.Guid;
            array[index]["error"]["identificationDetailValue"] = undefined;
            setAddlIdentDetailsValue(true);
          }
          setNomiDetails(array);

          console.log("Nomineedetails", array);
          console.log("setNomPhotoId", nomPhotoId);

          // AextractPan((res: any) => storeOcrData(res, panOCR), panOCR);
          // if (photoBase64 !== undefined) {
          //     setErrCode('');
          // }
        }
        setNomineeDocuments(nomineeDocObj);
        setNewUploads(newUpload);
        console.log("NomineeDocs", nomineeDocuments);
      }
    }
  }

  const onUploadProgress = (
    event: ProgressEvent,
    id: string,
    fileName: string,
    type: string
  ) => {
    const { loaded, total } = event;
    let percentage = Math.floor((loaded * 100) / total);
    let panCopy = { ...nomPhotoId };
    if (percentage < 100) {
      if (id === "panCard") {
        panCopy.id = id;
        panCopy.disable = false;
        panCopy.uploadPer = percentage;
        panCopy.hideProgress = true;
        panCopy.showFile = false;
        setNomPhotoId(panCopy);
      }
    }
  };

  function getDocumentidPOA(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      let responseObj = JSON.parse(res.data);
      if (
        responseObj &&
        responseObj.DocumentCategory &&
        responseObj.DocumentId
      ) {
        const reqObj = {
          applicationid: GlobalStore.applicationID,
          documentcategory: responseObj.DocumentCategory,
          documentid: responseObj.DocumentId,
        };
        AGetOcrResponseDetails(getOcrDetails, reqObj);
      } else {
        setIsLoading(false);
      }
    }
  }

  function getOcrDetails(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res.data) {
        // const responseObj = JSON.parse(JSON.parse(res.data));
        const responseObj = JSON.parse(res.data);
        if (
          responseObj &&
          responseObj.ocrdata &&
          responseObj.ocrdata.result &&
          responseObj.ocrdata.result.extraction_output &&
          responseObj.ocrdata.result.extraction_output.address
        ) {
          let obj = responseObj.ocrdata.result.extraction_output;
          const clientPartialDetails_ao = JSON.parse(
            window.localStorage.getItem("clientPartialDetails_ao") || "{}"
          );
          const permAdd = { ...permanentAddress };
          permAdd.addressLine1 = obj.address;
          const resPin = obj.pincode || getPincode(obj.address);
          if (resPin && resPin !== "" && resPin.length === 6)
            permAdd.PinCode = resPin.toString();
          setOcrDetails(obj);
          if (!clientPartialDetails_ao.isManual) {
            setPermanentAddress(permAdd);
            console.log(permanentAddress, "permanentAddress works 1");
          }
        }
      }
    }
    setIsLoading(false);
  }

  function getPincode(str: string) {
    const pincode = str.slice(-6);
    return isNaN(parseInt(pincode)) ? "" : pincode;
  }

  function getEnumList(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      // setErrCode('164 - Failed to fetch the category lists, please reload.');
      // setErrCode(errorMessage.getEnumList);
      setErrCode(res.error);
    } else {
      if (res.data && res.data.enumlist && res.data.enumlist.length > 0) {
        let [
          occupationList,
          incomeList,
          educationList,
          maritalStatusList,
          genderList,
          relationshipList,
          titlePrefixList,
          familyRelationList,
          countryList,
        ] = convertEnumIntoIObj(res.data.enumlist, [
          "OccupationCode",
          "Networth",
          "Education",
          "MaritalStatusCode",
          "GenderCode",
          "NomineeRelationship",
          "Prefix",
          "ContactRelationTypeCode",
          "Country",
        ]);
        incomeList = fixIncomeList(incomeList);
        setOccupationList(occupationList);
        setIncomeList(incomeList);
        setEducationList(educationList);
        setMaritalStatusList(maritalStatusList);
        setGenderList(genderList);
        setRelationshipList(relationshipList);
        setTitlePrefixList(titlePrefixList);
        setFamilyRelationList(familyRelationList);
        setFamilyRelationListMail(familyRelationList);
        setOriginalRelationList(familyRelationList);
        setCountryList(countryList);
      }
    }
    setIsLoading(false);
  }

  function fixIncomeList(list: IListObj[]) {
    return list.map((e) => ({ ...e, display: e.display.replace("Btw ", "") }));
  }

  function getRedisData(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      // setErrCode();
      setErrCode(res.error);
    } else {
      if (res.data && res.data.coresAddress) {
        let responseObj = res.data;
        GlobalStore.isDigiLocker = true;
        getProfileDetailsFromAadhar(responseObj);
        const permAdd = { ...permanentAddress };
        permAdd.addressLine1 = responseObj.coresAddress;
        setAadharName(responseObj.aadharName);
        responseObj.aadharNo !== "000000000000" && setIsAadharMasked(false);
        permAdd.PinCode = responseObj.coresPincode.toString();
        setGender(responseObj.gender === "M" ? "M" : "F");
        setGenderError("");
        setPermanentAddress(permAdd);
        console.log(permanentAddress, "permanentAddress works 2");
      } else {
        AGetDocumentidByApplicationid(
          getDocumentidPOA,
          GlobalStore.applicationID
        );
      }
    }
    setIsLoading(false);
  }

  function getRelationshipData(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      // setErrCode('132 - Failed to fetch relationship data');
      //   setErrCode(errorMessage.getRelationshipData);
      setErrCode(res.error);
    } else {
      if (res.data) {
        console.log("count log", res.data);
        let responseObj = res.data;
        setRelationshipDataCount({
          AvailableRelationshipTagEmail:
            responseObj.AvailableRelationshipTagEmail,
          AvailableRelationshipTagMobile:
            responseObj.AvailableRelationshipTagMobile,
        });
        setRelationshipData(responseObj);
      } else {
        // setErrCode('132 - Failed to fetch relationship data');
        setErrCode(res.error);
      }
    }
    setIsLoading(false);
  }

  function checkIsPanEmployee(res: any) {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res.data) {
        // const responseDataObj = JSON.parse(JSON.parse(res.data));
        const responseDataObj = res.data;
        if (responseDataObj.result.Flag === "Y")
          setStaffCode(responseDataObj.result.EmployeeCode);
      }
    }
  }

  function getbase64(dataString: string, obj: DocDetailFormat) {
    return (
      "data:" +
      (obj.mimeType === "application/pdf" ? "application/pdf" : "image/jpeg") +
      ";base64," +
      dataString
    );
  }

  async function getKycDetails(res: any) {
    try {
      setIsLoading(true);
      if (!res || res.error) {
        setIsLoading(false);
        setErrCode(res.error);
      } else {
        if (res.data) {
          let srNo = 0;
          let responseObj = JSON.parse(res.data);
          //   let responseObj = JSON.parse(JSON.stringify(res.data));
          if (responseObj.ObjEcoClientPersoanlDetails) {
            const clientPartialDetails_ao = JSON.parse(
              window.localStorage.getItem("clientPartialDetails_ao") || "{}"
            );

            clientPartialDetails_ao.addressLine1 =
              responseObj.ObjEcoClientPersoanlDetails.objPermanentAddressDetails.permanentaddressline1;
            clientPartialDetails_ao.sameAsPermanent =
              responseObj.ObjEcoClientPersoanlDetails.objCorrespondenceAddressDetails.corrsaddraspermanent;
            clientPartialDetails_ao.corrAddress =
              responseObj.ObjEcoClientPersoanlDetails.objCorrespondenceAddressDetails.correspondenceaddressline1;
            window.localStorage.setItem(
              "clientPartialDetails_ao",
              JSON.stringify(clientPartialDetails_ao)
            );

            GlobalStore.isKRA && setHideAddress(true);
            GlobalStore.isKYC && setHideAddress(true);
            let resultObj =
              responseObj.ObjEcoClientPersoanlDetails
                .objPermanentAddressDetails;
            if (resultObj) {
              const permAddObj = { ...permanentAddress };
              permAddObj.addressLine1 =
                resultObj.permanentaddressline1 +
                resultObj.permanentaddressline2 +
                (resultObj.permanentaddressline3 || "") +
                (resultObj.permanentaddressline4 || "");
              permAddObj.Area = resultObj.permanentarea;
              permAddObj.City = resultObj.permanentcity;
              permAddObj.PinCode = resultObj.permanentpincode;
              permAddObj.District = resultObj.permanentdistrict;
              permAddObj.State = resultObj.permanentstate;
              permAddObj.StateCode = resultObj.permanentstatecode;
              permAddObj.Country = resultObj.permanentcountry;
              permAddObj.CountryCode = resultObj.permanentcountrycode;
              setPermanentAddress(permAddObj);
              console.log(permanentAddress, "permanentAddress works 3");

              // for AO-1395 KYC/KRA address proof reject
            }
            resultObj =
              responseObj.ObjEcoClientPersoanlDetails
                .objCorrespondenceAddressDetails;
            if (resultObj) {
              const corrAddObj = { ...correspondanceAddress };
              corrAddObj.addressLine1 =
                resultObj.correspondenceaddressline1 +
                resultObj.correspondenceaddressline2 +
                (resultObj.correspondenceaddressline3 || "") +
                (resultObj.correspondenceaddressline4 || "");
              corrAddObj.Area = resultObj.correspondancearea;
              corrAddObj.City = resultObj.correspondancecity;
              corrAddObj.PinCode = resultObj.correspondancepincode;
              corrAddObj.District = resultObj.correspondancedistrict;
              corrAddObj.State = resultObj.correspondancestate;
              corrAddObj.StateCode = resultObj.correspondancestatecode;
              corrAddObj.Country = resultObj.correspondancecountry;
              corrAddObj.CountryCode = resultObj.correspondancecountrycode;
              setCorrespondanceAddress(corrAddObj);
              console.log(
                correspondanceAddress,
                "correspondanceAddress works 1"
              );
            }

            setSameAsPermanent(
              responseObj?.ObjEcoClientPersoanlDetails?.corrsaddraspermanent
            );
          }
          if (
            responseObj.ObjEcoProfileDetails &&
            responseObj.ObjEcoClientPersoanlDetails
          ) {
            if (
              responseObj.ListMultipleNomineeDetails &&
              responseObj.ListMultipleNomineeDetails.length > 0
            ) {
              setShowNominee(true);
              setIsEditMode(false);
              setNumOfNominee(responseObj.ListMultipleNomineeDetails.length);
              const array: any = [];
              for (
                let i = 0;
                i < responseObj.ListMultipleNomineeDetails.length;
                i++
              ) {
                const element = responseObj.ListMultipleNomineeDetails[i];
                if (
                  element?.additional_details ==
                  "Photo and Signature of the Nominee/guardian"
                ) {
                  setIsEditMode(true);
                  const photo: any = {
                    id: "nomPhotoId",
                    disable: false,
                    uploadPer: 100,
                    fileName: element?.additional_details_value,
                    imgUrl:
                      "data:image/jpg;base64," +
                      JSON.parse(
                        JSON.stringify(element?.additional_details_value)
                      ).base64document,
                    showFile: false,
                    hideProgress: false,
                  };
                  setNomPhotoId(photo);
                }

                let addressLine1 =
                  element.objpermanentaddressdetails.permanentaddressline1;
                let addressLine2 =
                  element.objpermanentaddressdetails.permanentaddressline2;
                let addressLine3 =
                  element?.objpermanentaddressdetails.permanentaddressline3;
                let addressLine4 =
                  element?.objpermanentaddressdetails.permanentaddressline4;
                addressLine1 =
                  addressLine1 != "null" || addressLine1 != null
                    ? addressLine1
                    : "";
                addressLine2 =
                  addressLine2 != "null" || addressLine2 != null
                    ? addressLine2
                    : "";
                addressLine3 =
                  addressLine3 != "null" || addressLine3 != null
                    ? addressLine3
                    : "";
                addressLine4 =
                  addressLine4 != "null" || addressLine4 != null
                    ? addressLine4
                    : "";

                let completeAddress = `${addressLine1}${addressLine2}${addressLine3}${addressLine4}`;
                let nFirstName =
                  element.firstname != null || element.firstname != "null"
                    ? element.firstname
                    : "";
                let nMiddleName =
                  element.middlename != null || element.middlename != "null"
                    ? element.middlename
                    : "";
                let nLastname =
                  element.lastname != null || element.lastname != "null"
                    ? element.lastname
                    : "";
                let nomFullName = `${nFirstName} ${nMiddleName} ${nLastname}`;

                const singleNominee: any = {
                  error: {},
                  nomTitle: element.title,
                  nomFullName: nomFullName,
                  nomRelation: element.relationship,
                  nomineeAddress: {
                    addressLine1: completeAddress,
                    City: element.objpermanentaddressdetails.permanentcity,
                    Country:
                      element.objpermanentaddressdetails.permanentcountry,
                    District:
                      element.objpermanentaddressdetails.permanentdistrict,
                    PinCode:
                      element.objpermanentaddressdetails.permanentpincode,
                    State: element.objpermanentaddressdetails.permanentstate,
                    Area: element.objpermanentaddressdetails.permanentarea,
                    CountryCode:
                      element.objpermanentaddressdetails.permanentcountrycode,
                    StateCode:
                      element.objpermanentaddressdetails.permanentstatecode,
                  },
                  nomPerSharing: element.percentage,
                  nomDob: element.dob ? new Date(element.dob) : null,
                  isNomineeMinor: element.isminor,
                  identificationDetail: {
                    name: element.additional_details,
                    value: element.additional_details_value,
                  },
                };

                // need to check if isMonir is there than need to check in guardian details from ListMultipleGuardianDetails and set into guardianDetails with singleNominee Obj
                if (element.isminor) {
                  const guardianDetails =
                    responseObj.ListMultipleGuardianDetails.find(
                      (singleGuardianDetail: any) =>
                        singleGuardianDetail.srno === srNo
                    );

                  if (guardianDetails && Object.keys(guardianDetails).length) {
                    console.log("guardianDetails", guardianDetails);
                    let addressLine1 =
                      guardianDetails?.objpermanentaddressdetails
                        .permanentaddressline1;
                    let addressLine2 =
                      guardianDetails?.objpermanentaddressdetails
                        .permanentaddressline2;
                    let addressLine3 =
                      guardianDetails?.objpermanentaddressdetails
                        .permanentaddressline3;
                    let addressLine4 =
                      guardianDetails?.objpermanentaddressdetails
                        .permanentaddressline4;
                    addressLine1 =
                      addressLine1 !== "NULL" ||
                      addressLine1 !== "null" ||
                      addressLine1 !== null
                        ? addressLine1
                        : "";
                    addressLine2 =
                      addressLine2 !== "NULL" ||
                      addressLine2 !== "null" ||
                      addressLine2 !== null
                        ? addressLine2
                        : "";
                    addressLine3 =
                      addressLine3 !== "NULL" ||
                      addressLine3 !== "null" ||
                      addressLine3 !== null
                        ? addressLine3
                        : "";
                    addressLine4 =
                      addressLine4 !== "NULL" ||
                      addressLine4 !== "null" ||
                      addressLine4 !== null
                        ? addressLine4
                        : "";
                    let completeAddress = `${addressLine1}${addressLine2}${addressLine3}${addressLine4}`;

                    let nFirstName =
                      guardianDetails?.firstname != null ||
                      guardianDetails?.firstname != "null"
                        ? guardianDetails?.firstname
                        : "";
                    let nMiddleName =
                      guardianDetails?.middlename != null ||
                      guardianDetails?.middlename != "null"
                        ? guardianDetails?.middlename
                        : "";
                    let nLastname =
                      guardianDetails?.lastname != null ||
                      guardianDetails?.lastname != "null"
                        ? guardianDetails?.lastname
                        : "";
                    let nomFullName = `${nFirstName} ${nMiddleName} ${nLastname}`;

                    singleNominee["guardianDetails"] = {
                      guardPoliticalVal: "0",
                      guardianAddress: {
                        addressLine1: completeAddress,
                        City: guardianDetails?.objpermanentaddressdetails
                          .permanentcity,
                        Country:
                          guardianDetails?.objpermanentaddressdetails
                            .permanentcountry,
                        District:
                          guardianDetails?.objpermanentaddressdetails
                            .permanentdistrict,
                        PinCode:
                          guardianDetails?.objpermanentaddressdetails
                            .permanentpincode,
                        State:
                          guardianDetails?.objpermanentaddressdetails
                            .permanentstate,
                        Area: guardianDetails?.objpermanentaddressdetails
                          .permanentarea,
                        CountryCode:
                          guardianDetails?.objpermanentaddressdetails
                            .permanentcountrycode,
                        StateCode:
                          guardianDetails?.objpermanentaddressdetails
                            .permanentstatecode,
                      },
                      sameAsNominee: false,
                      guardTitle: guardianDetails?.title,
                      guardFullName: nomFullName,
                      guardRelation: guardianDetails?.relationship,
                      identificationDetail: {
                        name: guardianDetails?.additional_details,
                        value: guardianDetails?.additional_details_value,
                      },
                      guardEduQualification: guardianDetails?.education,
                      guardOccupation: guardianDetails?.occupation,
                      guardAnnualIncome: guardianDetails?.annualincomerange,
                      guardNetWorth: guardianDetails?.networthamountac_open,
                      guardPlaceOfBirth: guardianDetails?.placeofbirth,
                    };
                  }
                }
                srNo += 1;
                array.push(singleNominee);
              }
              console.log("array ----->", array);
              setNomiDetails(array);
            }
            // console.log('responseObj.ListMultipleNomineeDetails', responseObj.ListMultipleNomineeDetails);

            // setNomiDetails(responseObj.ListMultipleNomineeDetails);
            // Changes made to populate local storage when client is coming from Know the status flow
            GlobalStore.leadId = responseObj.LeadId;
            GlobalStore.KRA_email = responseObj.ObjEcoClientPersoanlDetails
              .KraEmailId
              ? responseObj.ObjEcoClientPersoanlDetails.KraEmailId
              : "";
            GlobalStore.KRA_mobile = responseObj.ObjEcoClientPersoanlDetails
              .KraMobileNo
              ? responseObj.ObjEcoClientPersoanlDetails.KraMobileNo
              : "";
            GlobalStore.KRA_mode = responseObj.ObjEcoClientPersoanlDetails
              .KraMode
              ? responseObj.ObjEcoClientPersoanlDetails.KraMode
              : 0;

            const clientPartialDetails_ao = JSON.parse(
              window.localStorage.getItem("clientPartialDetails_ao") || "{}"
            );
            clientPartialDetails_ao.applicationId = GlobalStore.applicationID;
            clientPartialDetails_ao.leadID = GlobalStore.leadId;
            clientPartialDetails_ao.clientPrimaryDetailId =
              responseObj.ClientPrimaryDetailID;
            clientPartialDetails_ao.emailID = responseObj
              .ObjEcoClientPersoanlDetails.Email
              ? responseObj.ObjEcoClientPersoanlDetails.Email
              : clientPartialDetails_ao.emailID;
            clientPartialDetails_ao.birthDate =
              responseObj.ObjEcoClientPersoanlDetails.Birthdate;
            clientPartialDetails_ao.ckycNo =
              responseObj.ObjEcoClientPersoanlDetails.ckycnumber;
            clientPartialDetails_ao.fsfullName = `${
              responseObj.ObjEcoClientPersoanlDetails.FatherOrSpouseFirstname
                ? responseObj.ObjEcoClientPersoanlDetails
                    .FatherOrSpouseFirstname
                : ""
            } ${
              responseObj.ObjEcoClientPersoanlDetails.FatherOrSpouseMiddleName
                ? responseObj.ObjEcoClientPersoanlDetails
                    .FatherOrSpouseMiddleName
                : ""
            } ${
              responseObj.ObjEcoClientPersoanlDetails.FatherOrSpouseLastName
                ? responseObj.ObjEcoClientPersoanlDetails.FatherOrSpouseLastName
                : ""
            }`;
            clientPartialDetails_ao.motherfullName = `${
              responseObj.ObjEcoClientPersoanlDetails.MotherFirstname
                ? responseObj.ObjEcoClientPersoanlDetails.MotherFirstname
                : ""
            } ${
              responseObj.ObjEcoClientPersoanlDetails.MotherMiddleName
                ? responseObj.ObjEcoClientPersoanlDetails.MotherMiddleName
                : ""
            } ${
              responseObj.ObjEcoClientPersoanlDetails.MotherLastName
                ? responseObj.ObjEcoClientPersoanlDetails.MotherLastName
                : ""
            }`;
            clientPartialDetails_ao.addressLine1 =
              responseObj.ObjEcoClientPersoanlDetails.objPermanentAddressDetails.permanentaddressline1;
            clientPartialDetails_ao.sameAsPermanent =
              responseObj.ObjEcoClientPersoanlDetails.objCorrespondenceAddressDetails.corrsaddraspermanent;
            clientPartialDetails_ao.corrAddress =
              responseObj.ObjEcoClientPersoanlDetails.objCorrespondenceAddressDetails.correspondenceaddressline1;
            clientPartialDetails_ao.isKYC =
              responseObj.ObjEcoClientPersoanlDetails.iskyc;
            clientPartialDetails_ao.isKRA =
              responseObj.ObjEcoClientPersoanlDetails.isKRA;
            window.localStorage.setItem(
              "clientPartialDetails_ao",
              JSON.stringify(clientPartialDetails_ao)
            );

            GlobalStore.isKRA && setHideAddress(true);
            GlobalStore.isKYC && setHideAddress(true);
            let resultObj =
              responseObj.ObjEcoClientPersoanlDetails
                .objPermanentAddressDetails;
            if (resultObj) {
              const permAddObj = { ...permanentAddress };
              permAddObj.addressLine1 =
                resultObj.permanentaddressline1 +
                resultObj.permanentaddressline2 +
                (resultObj.permanentaddressline3 || "") +
                (resultObj.permanentaddressline4 || "");
              permAddObj.Area = resultObj.permanentarea;
              permAddObj.City = resultObj.permanentcity;
              permAddObj.PinCode = resultObj.permanentpincode;
              permAddObj.District = resultObj.permanentdistrict;
              permAddObj.State = resultObj.permanentstate;
              permAddObj.StateCode = resultObj.permanentstatecode;
              permAddObj.Country = resultObj.permanentcountry;
              permAddObj.CountryCode = resultObj.permanentcountrycode;
              setPermanentAddress(permAddObj);
              console.log(permanentAddress, "permanentAddress works 4");

              // for AO-1395 KYC/KRA address proof reject
              if (GlobalStore.isKYC || GlobalStore.isKRA) {
                AGetDocStatus(getDocumentStatus, GlobalStore.applicationID);
                setKycAddress(permAddObj);
                setKycFatherName(clientPartialDetails_ao.fsfullName);
              }
            }

            resultObj =
              responseObj.ObjEcoClientPersoanlDetails
                .objCorrespondenceAddressDetails;
            if (resultObj) {
              const corrAddObj = { ...correspondanceAddress };
              corrAddObj.addressLine1 =
                resultObj.correspondenceaddressline1 +
                resultObj.correspondenceaddressline2 +
                (resultObj.correspondenceaddressline3 || "") +
                (resultObj.correspondenceaddressline4 || "");
              corrAddObj.Area = resultObj.correspondancearea;
              corrAddObj.City = resultObj.correspondancecity;
              corrAddObj.PinCode = resultObj.correspondancepincode;
              corrAddObj.District = resultObj.correspondancedistrict;
              corrAddObj.State = resultObj.correspondancestate;
              corrAddObj.StateCode = resultObj.correspondancestatecode;
              corrAddObj.Country = resultObj.correspondancecountry;
              corrAddObj.CountryCode = resultObj.correspondancecountrycode;
              setCorrespondanceAddress(corrAddObj);
              console.log(
                correspondanceAddress,
                "correspondanceAddress works 2"
              );
            }
            resultObj = responseObj.ObjEcoClientPersoanlDetails;
            if (resultObj) {
              setFatherOrSpouse(resultObj.FlagFS || "01");
              setFatherOrSpouseName(
                (resultObj.FatherOrSpouseFirstname || "") +
                  " " +
                  (resultObj.FatherOrSpouseMiddleName || "") +
                  " " +
                  (resultObj.FatherOrSpouseLastName || "")
              );
              setMotherName(
                (resultObj.MotherFirstname || "") +
                  " " +
                  (resultObj.MotherMiddleName || "") +
                  " " +
                  (resultObj.MotherLastName || "")
              );
              console.log(
                "||clientPartialDetails_ao||=",
                clientPartialDetails_ao
              );
              setMaritalStatus(resultObj.MaritalStatus);
              setGender(resultObj.Gender);
              setGenderError("");
              setSameAsPermanent(resultObj.corrsaddraspermanent);
              setEmail(clientPartialDetails_ao.emailID);
              GlobalStore.user.email = clientPartialDetails_ao.emailID;
              setIsEmailVerified(true);
              setShowEmailBtn(false);
              setPhone(clientPartialDetails_ao.mobile);
              GlobalStore.user.mobile = clientPartialDetails_ao.mobile;
            }
            resultObj = responseObj.ObjEcoProfileDetails;
            if (resultObj) {
              setEduQulification(resultObj.education || "");
              setOccupation(resultObj.occupation || "");
              setPoliticalVal(resultObj.pep || "0");
              setAnnualIncome(resultObj.annualincomerange || "");
              setPlaceOfBirth(resultObj.placeofbirth || "");
              setNetWorth(resultObj.networthwhenacopen);
              setPeriodicSettlement(resultObj.periodicsettlement.toString());
              setIndianResident(!resultObj.taxresidentofothercountry);
              //setting PO flag after getting
              if (resultObj.ddpi_type == 1) setPOAType(true);
              else setPOAType(false);
              if (resultObj.applicationtype == 2)
                setDefaultAccountType(
                  "BSDA Resident Demat and Trading Account"
                );
              else
                setDefaultAccountType(
                  "Ordinary Resident Demat and Trading Account"
                );
              if (
                resultObj.taxresidentofothercountry &&
                resultObj.taxresidenceofindialist.length > 0
              ) {
                const nriObj: typeof nriDetailsObj = {
                  country: resultObj.taxresidenceofindialist[0].country,
                  idType:
                    resultObj.taxresidenceofindialist[0].identification_type,
                  idTypeValue:
                    resultObj.taxresidenceofindialist[0].identification_number,
                  idTypeOther:
                    resultObj.taxresidenceofindialist[0]
                      .identification_type_other,
                };
                setNriDetailsObj(nriObj);
              }
            }

            resultObj =
              responseObj.ObjEcoNomineeDetails?.objPermanentAddressDetails;
            if (resultObj) {
              const nomAddObj = { ...nomineeAddress };
              nomAddObj.addressLine1 =
                resultObj.permanentaddressline1 +
                resultObj.permanentaddressline2 +
                (resultObj.permanentaddressline3 || "") +
                (resultObj.permanentaddressline4 || "");
              nomAddObj.Area = resultObj.permanentarea;
              nomAddObj.City = resultObj.permanentcity;
              nomAddObj.PinCode = resultObj.permanentpincode;
              nomAddObj.District = resultObj.permanentdistrict;
              nomAddObj.StateCode = resultObj.permanentstatecode;
              nomAddObj.Country = resultObj.permanentcountry;
              nomAddObj.CountryCode = resultObj.permanentcountrycode;
              setNomineeAddress(nomAddObj);
              setShowNominee(true);
            }
            resultObj = responseObj.ObjEcoNomineeDetails;
            if (resultObj) {
              setNomTitle(resultObj.Title);
              setNomFullName(
                resultObj.FirstName +
                  " " +
                  (resultObj.MiddleName || "") +
                  " " +
                  (resultObj.LastName || "")
              );
              setNomRelation(resultObj.relationship || "");
              setIsNomineeMinor(resultObj.isminor);
              setNomDob(resultObj.DOB);
            }
            resultObj =
              responseObj.ObjEcoGuardianDetails?.objPermanentAddressDetails;
            if (resultObj) {
              const gaurdAddObj = { ...guardianAddress };
              gaurdAddObj.addressLine1 =
                resultObj.permanentaddressline1 +
                resultObj.permanentaddressline2 +
                (resultObj.permanentaddressline3 || "") +
                (resultObj.permanentaddressline4 || "");
              gaurdAddObj.Area = resultObj.permanentarea;
              gaurdAddObj.City = resultObj.permanentcity;
              gaurdAddObj.PinCode = resultObj.permanentpincode;
              gaurdAddObj.District = resultObj.permanentdistrict;
              gaurdAddObj.State = resultObj.permanentstate;
              gaurdAddObj.StateCode = resultObj.permanentstatecode;
              gaurdAddObj.Country = resultObj.permanentcountry;
              gaurdAddObj.CountryCode = resultObj.permanentcountrycode;
              setGuardianAddress(gaurdAddObj);
              setIsNomineeMinor(true);
            }
            resultObj = responseObj.ObjEcoGuardianDetails;
            if (resultObj) {
              setGuardTitle(resultObj.Title);
              setGuardFullName(
                resultObj.FirstName +
                  " " +
                  (resultObj.MiddleName || "") +
                  " " +
                  (resultObj.LastName || "")
              );
              setGuardRelation(resultObj.relationship || "");
              setGuardEduQulification(resultObj.education || "");
              setGuardOccupation(resultObj.occupation || "");
              setGuardPoliticalVal(resultObj.pep || "0");
              setGuardAnnualIncome(resultObj.annualincomerange || "");
              setGuardNetWorth(resultObj.networthamountac_open || "");
              setGuardPlaceOfBirth(resultObj.placeofbirth || "");
            }
            setIsLoading(false);
          } else {
            const clientPartialDetails_ao = JSON.parse(
              window.localStorage.getItem("clientPartialDetails_ao") || "{}"
            );
            if (clientPartialDetails_ao.pincode) {
              AGetCityByPincode(
                getFullAddress,
                clientPartialDetails_ao.pincode
              );
            } else if (
              GlobalStore.isKRA &&
              GlobalStore.kraInfoObject.addressLine1 &&
              GlobalStore.kraInfoObject.pincode
            )
              AGetCityByPincode(
                getFullAddress,
                GlobalStore.kraInfoObject.pincode
              );
            else if (
              GlobalStore.isKYC &&
              GlobalStore.ckycNo !== "" &&
              GlobalStore.ckycInfoObject.addressLine1 &&
              GlobalStore.ckycInfoObject.pincode
            )
              AGetCityByPincode(
                getFullAddress,
                GlobalStore.ckycInfoObject.pincode
              );
            else if (
              GlobalStore.isDigiLocker &&
              GlobalStore.personaldetails.addressLine1 &&
              GlobalStore.personaldetails.pincode
            )
              AGetCityByPincode(
                getFullAddress,
                GlobalStore.personaldetails.pincode
              );
            else {
              const redisReqObj = {
                applicationId: GlobalStore.applicationID,
                clientPrimaryDetailsId: GlobalStore.clientPrimaryDetailId,
              };
              ARedisDigilockerDoc(getRedisData, redisReqObj);
            }
          }

          let mobNumber = GlobalStore.user.mobile;
          let emailID = GlobalStore.user.email;
          const clientPartialDetails_ao = JSON.parse(
            window.localStorage.getItem("clientPartialDetails_ao") || "{}"
          );
          if (!mobNumber) {
            mobNumber = clientPartialDetails_ao.mobile;
          }
          if (!emailID) {
            emailID = clientPartialDetails_ao.emailID;
          }
          AGetRelationshipData(
            getRelationshipData,
            GlobalStore.applicationID,
            emailID,
            mobNumber.toString()
          );

          // check if nominee is present is yes get Uploaded documents and prefill

          // AGetUploadedDocument(getUploadedDoc, GlobalStore.applicationID);
        }
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  }

  // function getAreaList(res: any) {
  //   if (!res || res.error) {
  //     // setErrCode('130 - Failed to fetch the area list for the pincode');
  //     //   setErrCode(errorMessage.getAreaList);
  //     setErrCode(res.error);
  //   } else {
  //     if (res.data && res.data.areas) {
  //       let arr: Array<IListObj> = [];
  //       res.data.areas.forEach((item: string) => {
  //         let obj: IListObj = {
  //           value: item,
  //           display: item,
  //         };
  //         arr.push(obj);
  //       });
  //       const clientPartialDetails_ao = JSON.parse(
  //         window.localStorage.getItem("clientPartialDetails_ao") || "{}"
  //       );
  //       if (clientPartialDetails_ao.pincode) {
  //         AGetFullAddress(
  //           getFullAddress,
  //           res.data.areas[0],
  //           clientPartialDetails_ao.pincode
  //         );
  //       } else if (GlobalStore.ckycInfoObject.pincode) {
  //         AGetFullAddress(
  //           getFullAddress,
  //           res.data.areas[0],
  //           GlobalStore.ckycInfoObject.pincode
  //         );
  //       } else if (GlobalStore.kraInfoObject.pincode) {
  //         AGetFullAddress(
  //           getFullAddress,
  //           res.data.areas[0],
  //           GlobalStore.kraInfoObject.pincode
  //         );
  //       } else if (GlobalStore.personaldetails.pincode) {
  //         AGetFullAddress(
  //           getFullAddress,
  //           res.data.areas[0],
  //           GlobalStore.personaldetails.pincode
  //         );
  //       }
  //     } else {
  //       // setErrCode('130 - Failed to fetch the area list for the pincode');
  //       setErrCode(res.error);
  //     }
  //   }
  // }

  function getFullAddress(res: any) {
    if (!res || res.error) {
      // setErrCode('131 - Failed to fetch details corresponding to pincode and area');
      //   setErrCode(errorMessage.getFullAddress);
      setErrCode(res.error);
    } else {
      if (res.data && res.data.length > 0) {
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        let obj: any = {};
        obj.PinCode = res.data[0].PinCode;
        obj.District = res.data[0].District;
        obj.Country = res.data[0].Country;
        obj.City = res.data[0].City;
        obj.State = res.data[0].State;
        obj.StateCode = res.data[0].StateCode;
        obj.CountryCode = res.data[0].CountryCode;
        obj.addressLine1 = clientPartialDetails_ao.addressLine1
          ? clientPartialDetails_ao.addressLine1
          : GlobalStore.ckycInfoObject.addressLine1
          ? GlobalStore.ckycInfoObject.addressLine1
          : GlobalStore.kraInfoObject.addressLine1
          ? GlobalStore.kraInfoObject.addressLine1
          : GlobalStore.personaldetails.addressLine1;
        // setHideAddressKra(true);
        setPermanentAddress(obj);
        const areaObj = {
          pincode: res.data[0].PinCode,
          city: res.data[0].City,
          state: res.data[0].State,
        };
        ASavePinCity((res: any) => savePinCityRes(res), areaObj);
        if (sameAsPermanent) {
          setCorrespondanceAddress(obj);
          console.log(correspondanceAddress, "correspondanceAddress works 3");

          setIsLoading(false);
        } else {
          if (clientPartialDetails_ao.corrPincode) {
            AGetCityByPincode(
              (res: any) =>
                getCorrAddress(res, clientPartialDetails_ao.corrAddress),
              GlobalStore.ckycInfoObject.pincode
            );

            // AGetArea(
            //   (res: any) =>
            //     getCorrespondingAreaList(
            //       res,
            //       clientPartialDetails_ao.corrPincode,
            //       clientPartialDetails_ao.corrAddress
            //     ),
            //   clientPartialDetails_ao.corrPincode
            // );
          } else setIsLoading(false);
        }
      } else {
        // setErrCode('131 - Failed to fetch details corresponding to pincode and area');
        setErrCode(res.error);
      }
    }
  }
  function updateNomineeDetails() {
    // setNomineeDocuments([]);
    // setAddlIdentDetailsValue(true);
    const array = JSON.parse(JSON.stringify(nomiDetails));
    console.log("ARRAY", array);

    if (array.length > 0) {
      array.forEach((element: any, index: number) => {
        //  element.identificationDetail.name = '';
        // handleAddNomineeValue('identificationDetail', index, { display: '', value: 0});
      });
    }
  }

  function savePinCityRes(res: any) {
    console.log(res, "response works");
    if (!res || res.error) {
      // setErrCode('130 - Failed to fetch the area list for the pincode');
      //   setErrCode(errorMessage.getCorrespondingAreaList);
      setErrCode(res.error);
    } else {
      if (res.data && res.data.length > 0) {
      }
    }
  }
  // function getCorrespondingAreaList(
  //   res: any,
  //   pincode: string,
  //   address: string
  // ) {
  //   if (!res || res.error) {
  //     // setErrCode('130 - Failed to fetch the area list for the pincode');
  //     //   setErrCode(errorMessage.getCorrespondingAreaList);
  //     setErrCode(res.error);
  //   } else {
  //     if (res.data && res.data.areas) {
  //       let arr: Array<IListObj> = [];
  //       res.data.areas.forEach((item: string) => {
  //         let obj: IListObj = {
  //           value: item,
  //           display: item,
  //         };
  //         arr.push(obj);
  //       });
  //       AGetFullAddress(
  //         (res: any) => getCorrAddress(res, address),
  //         res.data.areas[0],
  //         pincode
  //       );
  //     }
  //   }
  // }

  function getCorrAddress(res: any, address: string) {
    if (!res || res.error) {
      // setErrCode('131 - Failed to fetch details corresponding to pincode and area');
      setErrCode(res.error);
      setErrCode(res.error);
    } else {
      if (res.data && res.data.length > 0) {
        let obj: any = {};
        obj.pincode = res.data[0].PinCode;
        obj.area = res.data[0].City;
        obj.district = res.data[0].District;
        obj.country = res.data[0].Country;
        obj.city = res.data[0].City;
        obj.state = res.data[0].State;
        obj.statecode = res.data[0].StateCode;
        obj.countrycode = res.data[0].CountryCode;
        obj.addressLine1 = address;
        setCorrespondanceAddress(obj);
        console.log(correspondanceAddress, "correspondanceAddress works 4");

        setIsLoading(false);
      }
    }
  }

  function sendOtp(otpType: "email" | "mobile") {
    setIsLoading(true);
    setShowEmailOtp(false);
    setInvalidOtp(false);
    if (otpType === "email") {
      let emlObj = {
        mailBody: emailVerificationBody.mailBody,
        mailFrom: emailVerificationBody.mailFrom,
        mailSource: emailVerificationBody.mailSource,
        mailSubject: emailVerificationBody.mailSubject,
        mailTo: email,
      };
      AGetEmailOtp((res: any) => otpSent(res, "email"), emlObj);
      // AGetMobileOtp(emailOtpSent, '9770308828');
    } else {
      AGetMobileOtp((res: any) => otpSent(res, "mobile"), phone);
    }
  }

  function otpSent(res: any, otpType: "email" | "mobile") {
    if (res.data && res.status) {
      if (res.data && otpType === "email") {
        setShowEmailBtn(false);
        setShowEmailOtp(true);
      }
      if (res.data.Success && otpType === "mobile") {
        setShowMobileBtn(false);
        setShowMobileOtp(true);
      }
      setErrCode(errCode);
    } else {
      // setErrCode('114 - Failed to send OTP, please retry.');
      setErrCode(res.error);
    }
    setIsLoading(false);
  }

  function validateOtp(otpType: "email" | "mobile", val: string) {
    if (invalidOtp) {
      setInvalidOtp(false);
    }
    if (val.length === 4) {
      setIsLoading(true);
      setDisableOtp(true);
      if (otpType === "email")
        AValidateEmailOtp(
          (res: any) => checkValidateOtp(res, "email"),
          email,
          val
        );
      else
        AValidateMobileOtp(
          (res: any) => checkValidateOtp(res, "mobile"),
          phone.toString(),
          val
        );
    }
  }

  function checkValidateOtp(res: any, otpType: "email" | "mobile") {
    setDisableOtp(false);
    if (!res || res.error) {
      // setErrCode('115 - Failed to validate OTP, please retry.');
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data == true || res.data == "true" || res.data == "Same OTP") {
        if (otpType === "email") {
          GlobalStore.user.email = email;
          setIsEmailVerified(true);
          setShowEmailOtp(false);
        }
        if (otpType === "mobile") {
          GlobalStore.user.mobile = phone;
          setIsMobileVerified(true);
          setShowMobileOtp(false);
        }

        let mobNumber = GlobalStore.user.mobile;
        let emailID = GlobalStore.user.email;
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        setFatherOrSpouseName(GlobalStore.personaldetails.fsfullName);
        if (!mobNumber) {
          mobNumber = clientPartialDetails_ao.mobile;
        }
        if (!emailID) {
          emailID = clientPartialDetails_ao.emailID;
        }
        AGetRelationshipData(
          getRelationshipData,
          GlobalStore.applicationID,
          emailID,
          mobNumber.toString()
        );
      } else {
        setInvalidOtp(true);
      }
      setErrCode(errCode);
    }
    setIsLoading(false);
  }

  function handleSameAsAbove(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === "sameAscorrespondance") {
      if (
        !GlobalStore.isDigiLocker &&
        !GlobalStore.isKRA &&
        !GlobalStore.isKYC
      ) {
        sameAsPermanent && setCorrespondanceAddress({ ...initialAddressObj });
        !sameAsPermanent && setCorrespondanceAddress({ ...permanentAddress });
        if (!sameAsPermanent) {
          setCorrErrorObj({});
        }
        setSameAsPermanent(!sameAsPermanent);
      }
    } else {
      !sameAsNominee && setGuardianAddress({ ...nomineeAddress });
      setSameAsNominee(!sameAsNominee);
    }
  }

  function handleSameAsAboveForGaurdian(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const nomineeAdd = JSON.parse(JSON.stringify(nomiDetails));
    nomineeAdd[index]["guardianDetails"]["sameAsNominee"] =
      !nomineeAdd[index]["guardianDetails"]["sameAsNominee"];
    setNomiDetails(nomineeAdd);
    if (event.target.name === "sameAsguardian") {
      if (
        nomineeAdd[index]["guardianDetails"]["sameAsNominee"] &&
        nomineeAdd[index]?.nomineeAddress
      ) {
        nomineeAdd[index]["guardianDetails"]["guardianAddress"] =
          nomineeAdd[index]?.nomineeAddress;
        setNomiDetails(nomineeAdd);
      } else {
        nomineeAdd[index]["guardianDetails"]["guardianAddress"] = {
          ...initialAddressObj,
        };
        setNomiDetails(nomineeAdd);
      }
    } else {
      nomineeAdd[index]["guardianDetails"]["guardianAddress"] = {
        ...initialAddressObj,
      };
      setNomiDetails(nomineeAdd);
    }
  }

  function onRelationChange(type: string, index: number, val: string) {
    let value: string = parseInt(val).toString();

    switch (type) {
      case "email":
        let emailArr = relationshipData.emailMatchedList;
        emailArr[index].relationshipName = value;
        setRelationshipData({
          ...relationshipData,
          emailMatchedList: emailArr,
        });
        break;
      case "mobile":
        let mobileArr = relationshipData.mobileMatchedList;
        mobileArr[index].relationshipName = value;
        setRelationshipData({
          ...relationshipData,
          mobileMatchedList: mobileArr,
        });
        break;
      case "both":
        let bothArr = relationshipData.bothMatchedList;
        bothArr[index].relationshipName = value;
        setRelationshipData({ ...relationshipData, bothMatchedList: bothArr });
        break;
    }
  }

  React.useEffect(() => {
    console.log("familyRelationshipCount RE log = ", familyRelationshipCount);
    console.log("familyRelationshipList RL log = ", familyRelationListMail);
    console.log("familyRelationshipData Data log = ", relationshipDataCount);

    // Rohit code changes starts

    let mobileDropDownList = familyRelationListMail;
    let emailDropDownList = familyRelationListMail;
    let removeFromMobileList: string[] = [];
    let removeFromEmailList: string[] = [];

    // check if data count is greater then 0 for each type and update the list -- mobile // familyRelationshipCount
    if (relationshipDataCount?.AvailableRelationshipTagMobile?.Self == 0) {
      removeFromMobileList.push("1");
    }

    if (
      relationshipDataCount?.AvailableRelationshipTagMobile?.DependentParent ==
      0
    ) {
      removeFromMobileList.push("4");
    }

    if (
      relationshipDataCount?.AvailableRelationshipTagMobile?.DependentChild == 0
    ) {
      removeFromMobileList.push("3");
    }

    if (relationshipDataCount?.AvailableRelationshipTagMobile?.Spouse == 0) {
      removeFromMobileList.push("2");
    }

    // check if data count is greater then 0 for each type and update the list -- email // familyRelationListMail

    if (relationshipDataCount?.AvailableRelationshipTagEmail?.Self == 0) {
      removeFromEmailList.push("1");
    }

    if (
      relationshipDataCount?.AvailableRelationshipTagEmail?.DependentParent == 0
    ) {
      removeFromEmailList.push("4");
    }

    if (
      relationshipDataCount?.AvailableRelationshipTagEmail?.DependentChild == 0
    ) {
      removeFromEmailList.push("3");
    }

    if (relationshipDataCount?.AvailableRelationshipTagEmail?.Spouse == 0) {
      removeFromEmailList.push("2");
    }

    // FIlter out values what all is not required
    const mobileList = mobileDropDownList.filter(
      (i: any) => !removeFromMobileList.includes(i.value)
    );

    const emailList = emailDropDownList.filter(
      (i: any) => !removeFromEmailList.includes(i.value)
    );

    console.log("EMAIL", removeFromEmailList, emailList);
    console.log("Mobile", removeFromMobileList, mobileList);

    // Rohit Code changes ends

    // let familyRelationListCopy = familyRelationList;
    // let familyRelationListMailCopy = familyRelationListMail;
    // if (
    //   familyRelationshipCount.mobile.self ==
    //   relationshipDataCount?.AvailableRelationshipTagMobile?.Self
    // ) {
    //   //something
    //   familyRelationListCopy = familyRelationList.filter(
    //     (item) => item.value != "1"
    //   );
    // }
    // if (
    //   familyRelationshipCount.mobile.spouse ==
    //   relationshipDataCount?.AvailableRelationshipTagMobile?.Spouse
    // ) {
    //   //something
    //   familyRelationListCopy = familyRelationList.filter(
    //     (item) => item.value != "2"
    //   );
    // }
    // if (
    //   familyRelationshipCount.mobile.dependentChild ==
    //   relationshipDataCount?.AvailableRelationshipTagMobile?.DependentChild
    // ) {
    //   //something
    //   familyRelationListCopy = familyRelationList.filter(
    //     (item) => item.value != "3"
    //   );
    // }
    // if (
    //   familyRelationshipCount.mobile.dependentParents ==
    //   relationshipDataCount?.AvailableRelationshipTagMobile?.DependentParent
    // ) {
    //   //something
    //   familyRelationListCopy = familyRelationList.filter(
    //     (item) => item.value != "4"
    //   );
    // }
    // if (
    //   familyRelationshipCount.email.self ==
    //   relationshipDataCount?.AvailableRelationshipTagEmail?.Self
    // ) {
    //   //something
    //   familyRelationListMailCopy = familyRelationListMail.filter(
    //     (item) => item.value != "1"
    //   );
    // }
    // if (
    //   familyRelationshipCount.email.spouse ==
    //   relationshipDataCount?.AvailableRelationshipTagEmail?.Spouse
    // ) {
    //   //something
    //   familyRelationListMailCopy = familyRelationListMail.filter(
    //     (item) => item.value != "2"
    //   );
    // }
    // if (
    //   familyRelationshipCount.email.dependentChild ==
    //   relationshipDataCount?.AvailableRelationshipTagEmail?.DependentChild
    // ) {
    //   //something
    //   familyRelationListMailCopy = familyRelationListMail.filter(
    //     (item) => item.value != "3"
    //   );
    // }
    // if (
    //   familyRelationshipCount.email.dependentParents ==
    //   relationshipDataCount?.AvailableRelationshipTagEmail?.DependentParent
    // ) {
    //   //something
    //   familyRelationListMailCopy = familyRelationListMail.filter(
    //     (item) => item.value != "4"
    //   );
    // }
    setFamilyRelationList(mobileList);
    setFamilyRelationListMail(emailList);
  }, [familyRelationshipCount, relationshipDataCount]);

  function relationshipListFilter(type: string, index: number, val: any) {
    let value = val?.value;
    // let familyRelationshipCountData = familyRelationshipCount;
    // use of accountcode to bind the relatioship data
    console.log("Relationship", val);
    if (type === "mobile") {
      if (value == "1") {
        console.log("limit 1");
        let updatedCount = {
          ...familyRelationshipCount.mobile,
          self: familyRelationshipCount.mobile.self + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          mobile: updatedCount,
        });
      } else if (value == "2") {
        console.log("limit 1");
        let updatedCount = {
          ...familyRelationshipCount.mobile,
          spouse: familyRelationshipCount.mobile.spouse + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          mobile: updatedCount,
        });
      } else if (value == "3") {
        console.log("limit 2");
        let updatedCount = {
          ...familyRelationshipCount.mobile,
          dependentChild: familyRelationshipCount.mobile.dependentChild + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          mobile: updatedCount,
        });
      } else if (value == "4") {
        console.log("limit 2");
        let updatedCount = {
          ...familyRelationshipCount.mobile,
          dependentParents: familyRelationshipCount.mobile.dependentParents + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          mobile: updatedCount,
        });
      }
    }
    if (type === "email") {
      if (value == "1") {
        console.log("limit 1");
        let updatedCount = {
          ...familyRelationshipCount.email,
          self: familyRelationshipCount.email.self + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          email: updatedCount,
        });
      } else if (value == "2") {
        console.log("limit 1");
        let updatedCount = {
          ...familyRelationshipCount.email,
          spouse: familyRelationshipCount.email.spouse + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          email: updatedCount,
        });
      } else if (value == "3") {
        console.log("limit 2");
        let updatedCount = {
          ...familyRelationshipCount.email,
          dependentChild: familyRelationshipCount.email.dependentChild + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          email: updatedCount,
        });
      } else if (value == "4") {
        console.log("limit 2");
        let updatedCount = {
          ...familyRelationshipCount.email,
          dependentParents: familyRelationshipCount.email.dependentParents + 1,
        };
        setFamilyRelationshipCount({
          ...familyRelationshipCount,
          email: updatedCount,
        });
      }
    }
  }

  function onRelationBlur(type: string, index: number, val: any) {
    console.log("----- log", val);
    switch (type) {
      case "email":
        let emailArr = relationshipData.emailMatchedList;
        emailArr[index].error = !val ? errorMsg(errorCode.FE20) : "";
        setRelationshipData({
          ...relationshipData,
          emailMatchedList: emailArr,
        });
        relationshipListFilter(type, index, val);
        break;
      case "mobile":
        let mobileArr = relationshipData.mobileMatchedList;
        mobileArr[index].error = !val ? errorMsg(errorCode.FE20) : "";
        setRelationshipData({
          ...relationshipData,
          mobileMatchedList: mobileArr,
        });
        relationshipListFilter(type, index, val);
        break;
      case "both":
        let bothArr = relationshipData.bothMatchedList;
        bothArr[index].relationshipName = !val ? errorMsg(errorCode.FE20) : "";
        setRelationshipData({ ...relationshipData, bothMatchedList: bothArr });
        break;
    }
  }
  function onEmailPhoneChange(key: "email" | "phone", value: string) {
    if (key === "email") {
      if (GlobalStore.user.email === value && isEmailVerified) {
        setShowEmailBtn(false);
        setIsEmailVerified(true);
      } else {
        setShowEmailBtn(true);
        setShowEmailOtp(false);
      }
      setEmail(value);
    }
    if (key === "phone") {
      if (GlobalStore.user.mobile === value) {
        setShowMobileBtn(false);
        setIsMobileVerified(true);
      } else {
        setShowMobileBtn(true);
        setShowMobileOtp(false);
      }
      setPhone(value);
    }
    setErrors({ ...errors, [key]: "" });
  }

  function dropDownBlur(key: string, value: any) {
    typeof value !== "object"
      ? setErrors({ ...errors, [key]: errorMsg(errorCode.FE20) })
      : setErrors({ ...errors, [key]: undefined });
  }

  function onBlurNomDob(event: React.FocusEvent<HTMLInputElement>) {
    !nomDob && setErrors({ ...errors, nomDob: errorMsg(errorCode.FE27) });
  }

  function saveRelationshipData() {
    if (
      relationshipData.emailMatchedList &&
      relationshipData.emailMatchedList.length > 0
    ) {
      relationshipData.emailMatchedList.map((member: object) => {
        if (!member.hasOwnProperty("relationshipName")) {
          // setErrCode('Please Select Relationship in Family Declaration');
          setErrCode(errorMessage.saveRelationshipData);
          return null;
        }
      });
    }
    if (
      relationshipData.mobileMatchedList &&
      relationshipData.mobileMatchedList.length > 0
    ) {
      relationshipData.mobileMatchedList.map((member: object) => {
        if (!member.hasOwnProperty("relationshipName")) {
          // setErrCode('Please Select Relationship in Family Declaration');
          setErrCode(errorMessage.saveRelationshipData);
          return null;
        }
      });
    }
    let saveRelationshipObj = {
      applicationid: GlobalStore.applicationID,
      clientprimarydetailid: parseInt(GlobalStore.clientPrimaryDetailId),
      emailMatchedList: relationshipData.emailMatchedList,
      empcode: 0,
      mobileMatchedList: relationshipData.mobileMatchedList,
    };
    setIsLoading(true);
    ASaveRelationshipData(saveFamilyRelation, saveRelationshipObj);
  }

  function saveFamilyRelation(res: any) {
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res.data) {
        console.log("in saveFamilyRelation", res);
      }
    }
  }

  function validateNominees(): boolean {
    try {
      let isValidate = false;
      if (showNominee && numOfNominee === 0) {
        isValidate = true;
      } else if (showNominee && nomiDetails && nomiDetails.length > 0) {
        for (let i = 0; i < nomiDetails?.length; i++) {
          const element = nomiDetails[i];
          // const imgAddDtls = imgOfAddlIdentDetails[i];
          if (
            element &&
            Object.keys(element) &&
            (!element?.nomTitle ||
              !element?.nomFullName ||
              element?.nomFullName === "" ||
              !element?.nomRelation ||
              element?.nomRelation === "" ||
              (element?.nomRelation === "13" &&
                element?.nomRelationOther === "") ||
              !element?.nomDob ||
              element.nomPerSharing == 0 ||
              Object.values(element?.error).some((error) => error) ||
              Object.values(element?.nomineeAddress).some((value) => !value))
            // !imgAddDtls.nomineeAddDtls.isPhoto ||
            // !imgAddDtls.nomineeAddDtls.isSignature
          ) {
            isValidate = true;
            break;
          }
          if (
            element.isNomineeMinor &&
            (!element["guardianDetails"]?.guardTitle ||
              element["guardianDetails"]?.guardTitle === "" ||
              !element["guardianDetails"]?.guardFullName ||
              element["guardianDetails"]?.guardFullName === "" ||
              !element["guardianDetails"]?.guardRelation ||
              element["guardianDetails"]?.guardRelation === "" ||
              (element["guardianDetails"]?.guardRelation === "13" &&
                element["guardianDetails"]?.guardRelationOther === "") ||
              !element["guardianDetails"]?.guardEduQualification ||
              element["guardianDetails"]?.guardEduQualification === "" ||
              !element["guardianDetails"]?.guardOccupation ||
              element["guardianDetails"]?.guardOccupation === "" ||
              !element["guardianDetails"]?.guardAnnualIncome ||
              element["guardianDetails"]?.guardAnnualIncome === "" ||
              !element["guardianDetails"]?.guardPlaceOfBirth ||
              element["guardianDetails"]?.guardPlaceOfBirth === "" ||
              !element["guardianDetails"]?.guardNetWorth ||
              element["guardianDetails"]?.guardNetWorth === "" ||
              parseInt(element["guardianDetails"]?.guardNetWorth) < 1000 ||
              Object.values(element.error).some((error) => error) ||
              Object.values(!element["guardianDetails"]?.guardianAddress).some(
                (value) => !value
              ))
            // ||
            // !imgAddDtls.guardianAddDtls.isPhoto ||
            // !imgAddDtls.guardianAddDtls.isSignature
          ) {
            isValidate = true;
            break;
          } else {
            isValidate = false;
          }
        }
      } else {
        // console.log('inside the last else');
        isValidate = false;
      }
      // console.log('isValidate inside the validateNominee', isValidate);
      return isValidate;
    } catch (e) {
      return false;
    }
  }

  function validateSubmit(): boolean {
    if (
      Object.values(permErrorObj).some((error) => error) ||
      Object.values(nomErrorObj).some((error) => error) ||
      Object.values(corrErrorObj).some((error) => error) ||
      Object.values(guardErrorObj).some((error) => error) ||
      Object.values(permanentAddress).some((value) => !value) ||
      Object.values(correspondanceAddress).some((value) => !value) ||
      (relationshipData.mobileMatchedList &&
        relationshipData.mobileMatchedList.some(
          (value) => value.error || !value?.relationshipName
        )) ||
      (relationshipData.emailMatchedList &&
        relationshipData.emailMatchedList.some(
          (value) => value.error || !value?.relationshipName
        )) ||
      (relationshipData?.bothMatchedList &&
        relationshipData?.bothMatchedList?.some(
          (value) => value.error || !value?.relationshipName
        )) ||
      validateNominees() ||
      !isEmailVerified ||
      !isMobileVerified ||
      email === "" ||
      phone === "" ||
      fatherOrSpouseName.trim() === "" ||
      motherName.trim() === "" ||
      eduQualification === "" ||
      occupation === "" ||
      (!indianResident &&
        (nriDetailsObj.country === "" ||
          (nriDetailsObj.idType === "Others" &&
            nriDetailsObj.idTypeOther === "") ||
          nriDetailsObj.idTypeValue === "")) ||
      netWorth === "" ||
      parseInt(netWorth) < 1000 ||
      annualIncome === "" ||
      placeOfBirth === "" ||
      Object.values(errors).some((error) => error)
      // !addlIdentDetailsValue
    ) {
      return true;
    }

    return false;
  }

  function onBlurPan(error: any, index: number, name: string) {
    const array = JSON.parse(JSON.stringify(validPan));
    handleAddNomineeError(
      index,
      name,
      error.required || !error.pattern ? errorMsg(errorCode.FE07) : ""
    );
    if (error.pattern) {
      array[index] = true;
      setValidPan(array);
    } else {
      array[index] = false;
      setValidPan(array);
    }
  }

  const additionalDetailsOfGuardian = async (
    data: any,
    index: number,
    key: string
  ) => {
    let result = data[index]?.guardianDetails?.identificationDetail[key];
    if (!result) {
      let ckycBackup = JSON.parse(
        window.localStorage.getItem("ckycBackup") || "{}"
      );
      result = ckycBackup[index]?.guardianDetails?.identificationDetail[key];
    }
    return result;
  };

  const additionalDetailsOfNominee = async (
    data: any,
    index: number,
    key: string
  ) => {
    let result = data?.identificationDetail[key];
    if (!result) {
      let ckycBackup = JSON.parse(
        window.localStorage.getItem("ckycBackup") || "{}"
      );
      result = ckycBackup[index]?.identificationDetail[key];
    }
    return result;
  };

  const removeSpclCracters = (value: string) => {
    if (value) {
      return value; //.replace(/[^a-zA-Z0-9 ]/g, " ");
    } else {
      return "";
    }
  };

  console.log(
    "holderFirstname",
    GlobalStore.personaldetails.panDetails,

    {
      holderFirstname: GlobalStore?.stageStatus?.panfirstname ?? "",
      holderMiddleName: GlobalStore?.stageStatus?.panmiddletname ?? "",
      holderLastName: GlobalStore?.stageStatus?.panlastname ?? "",
    }
  );

  async function handleSubmit() {
    setIsLoading(true);
    // for AO-1395 KYC/KRA address proof reject
    if (regectedAddressDoc) {
      if (
        JSON.stringify(permanentAddress) !== JSON.stringify(kycAddress) ||
        kycFatherName !== fatherOrSpouseName
      ) {
        GlobalStore.isKRA = false;
        GlobalStore.isKYC = false;
        addressEdited = true;
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        clientPartialDetails_ao.isKRA = GlobalStore.isKRA;
        clientPartialDetails_ao.isKYC = GlobalStore.isKYC;
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
      }
    }

    let sumOfPer = 0;
    for (let i = 0; i < nomiDetails.length; i++) {
      sumOfPer += Number(nomiDetails[i]?.nomPerSharing);
    }
    if (!showNominee || (showNominee && sumOfPer === 100)) {
      const clientPartialDetails_ao = JSON.parse(
        window.localStorage.getItem("clientPartialDetails_ao") || "{}"
      );
      clientPartialDetails_ao.newLeadStorage = GlobalStore.leadId;
      window.localStorage.setItem(
        "clientPartialDetails_ao",
        JSON.stringify(clientPartialDetails_ao)
      );

      setIsLoading(true);
      if (
        relationshipData?.emailMatchedList?.length > 0 ||
        relationshipData?.mobileMatchedList?.length > 0
      ) {
        saveRelationshipData();
      }
      const objUTM_Info = utmDurationInfoObj(startTime, "profiledur");
      const fatherOrSpouseNames = splitFullName(fatherOrSpouseName);
      const motherNames = splitFullName(motherName);
      const holderNameArr = splitFullName(getFullPANNAMe());

      const correspondanceAddressLine = repairAddressWithConditions(
        correspondanceAddress.addressLine1,
        correspondanceAddress.City
      );
      const permanentAddressLine = repairAddressWithConditions(
        permanentAddress.addressLine1,
        permanentAddress.City
      );
      const kycObj: any = {
        applicationID: GlobalStore.applicationID,
        clientPrimaryDetailID: GlobalStore.clientPrimaryDetailId,
        isGuardian: false,
        isNominee: showNominee,
        leadId: GlobalStore.leadId,
        // objEcoClientPersoanlDetails: {
        persoanlDetails: {
          KraEmail: GlobalStore.KRA_email ? GlobalStore.KRA_email : "",
          KraMobile: GlobalStore.KRA_mobile ? GlobalStore.KRA_mobile : "",
          Kra_mode: GlobalStore.KRA_mode ? Number(GlobalStore.KRA_mode) : 0,
          aadhaar: "",
          aadhaarName: "",
          additionalEmail: clientPartialDetails_ao.emailID
            ? clientPartialDetails_ao.emailID
            : email,
          additionalMobile: clientPartialDetails_ao.mobile
            ? clientPartialDetails_ao.mobile
            : phone,
          birthdate: GlobalStore.birthDate,
          // ckycnumber: GlobalStore.ckycNo,
          ckycNumber: GlobalStore.ckycNo,
          corrsaddraspermanent: sameAsPermanent,
          createdby: 0,
          fatherOrSpouseFirstname: fatherOrSpouseNames[0],
          fatherOrSpouseMiddleName: fatherOrSpouseNames[1],
          fatherOrSpouseLastName: fatherOrSpouseNames[2],
          fatherOrSpouseTitle: "Mr",
          flagFS: fatherOrSpouse,
          fsfullName: fatherOrSpouseName,
          gender: gender,
          holderFirstname: GlobalStore?.stageStatus?.panfirstname ?? "",
          holderMiddleName: GlobalStore?.stageStatus?.panmiddletname ?? "",
          holderLastName: GlobalStore?.stageStatus?.panlastname ?? "",
          holderTitle: gender === "F" ? "Ms" : "Mr",
          isKRA: GlobalStore.isKRA,
          isRelationship: false,
          // isdigilocker: GlobalStore.isDigiLocker,
          isDigilocker: GlobalStore.isDigiLocker,
          // iskyc: GlobalStore.isKYC,
          isKyc: GlobalStore.isKYC,
          maidenFirstname: "",
          maidenLastName: "",
          maidenMiddleName: "",
          maritalStatus: maritalStatus,
          maidenTitle: "",
          motherFirstname: motherNames[0],
          motherMiddleName: motherNames[1],
          motherLastName: motherNames[2],
          motherTitle: "Mrs",
          digiKraKycDetailsmodifieddate: addressEdited ? Date.now() : "",
          selfielink: GlobalStore.selfieUrl,
          // objCorrespondenceAddressDetails: {
          correspondenceAddressDetails: {
            // correspondanceAddresstype: addressType, //hardCode
            correspondenceAddressType: addressType, //hardCode
            correspondenceCity: correspondanceAddress.City,
            correspondenceCountry: correspondanceAddress.Country,
            correspondenceDistrict: correspondanceAddress.District,
            correspondencePincode: correspondanceAddress.PinCode,
            correspondenceState: correspondanceAddress.State,
            // correspondancearea: correspondanceAddress.Area,
            correspondenceArea: correspondanceAddress.Area,
            // correspondancecountrycode: correspondanceAddress.CountryCode,
            correspondenceCountryCode: Number(
              correspondanceAddress.CountryCode
            ),
            // correspondancestatecode: correspondanceAddress.StateCode,
            correspondenceStatecode: Number(correspondanceAddress.StateCode),
            correspondenceAddressLine1:
              removeSpclCracters(correspondanceAddressLine.addLine1) || "",
            correspondenceAddressLine2:
              removeSpclCracters(correspondanceAddressLine.addLine2) || "",
            correspondenceAddressLine3:
              removeSpclCracters(correspondanceAddressLine.addLine3) || "",
            correspondenceAddressLine4:
              removeSpclCracters(correspondanceAddressLine.addLine4) || "",
            autoQc: GlobalStore.isKYC
              ? true
              : GlobalStore.personaldetails.aadharName &&
                GlobalStore.personaldetails.aadharName
                  .toLowerCase()
                  .includes(GlobalStore.personaldetails.PANName.toLowerCase())
              ? true
              : false,
            // autoqc: sameAsPermanent &&
            //     (
            //         (
            //             GlobalStore.isDigiLocker && isAadharMasked &&
            //             (aadharName.toLowerCase() === GlobalStore.personaldetails.PANName.toLowerCase())
            //         ) ||
            //         (ocrDetails && (ocrDetails.address === permanentAddress.addressLine1))
            //     )
            //pin code condition need to add ? //hardcode
          },
          // objPermanentAddressDetails: {
          permanentAddressDetails: {
            permanentAddressLine1:
              removeSpclCracters(permanentAddressLine.addLine1) || "",
            permanentAddressLine2:
              removeSpclCracters(permanentAddressLine.addLine2) || "",
            permanentAddressLine3:
              removeSpclCracters(permanentAddressLine.addLine3) || "",
            permanentAddressLine4:
              removeSpclCracters(permanentAddressLine.addLine4) || "",
            permanentAddresstype: addressType, //hardCode
            permanentCity: permanentAddress.City,
            permanentCountry: permanentAddress.Country,
            permanentDistrict: permanentAddress.District,
            permanentPincode: permanentAddress.PinCode,
            permanentState: permanentAddress.State,
            permanentarea: permanentAddress.Area,
            permanentcountrycode: Number(permanentAddress.CountryCode),
            permanentstatecode: Number(permanentAddress.StateCode),
            autoQc: GlobalStore.isKYC
              ? true
              : GlobalStore.personaldetails.aadharName &&
                GlobalStore.personaldetails.aadharName
                  .toLowerCase()
                  .includes(GlobalStore.personaldetails.PANName.toLowerCase())
              ? true
              : false,
            // autoqc: (
            //     GlobalStore.isDigiLocker && isAadharMasked &&
            //     (aadharName.toLowerCase() === GlobalStore.personaldetails.PANName.toLowerCase())
            // ) || (ocrDetails && (ocrDetails.address === permanentAddress.addressLine1))
          },
          others: "",
          relationshipName: "",
          relationshipnamefordiy: "",
        },
        // listMultiplenomineeDetails: [],
        listNomineeDetails: [],
        // objEcoProfileDetails: {
        profileDetails: {
          accountCategory: staffCode
            ? employeeAccCategory
            : GlobalStore.stageStatus.isPCGSubLob
            ? PCGAccCategory
            : retailAccCategory,
          annualIncomeRange: annualIncome,
          clientGroup4: clientGroup4, //hardCode
          education: eduQualification,
          netWorthwhenAcOpen: parseInt(netWorth),
          occupation: occupation,
          ddpi_type: POAType ? 1 : 0,
          applicationType:
            defaultAccountType === "Ordinary Resident Demat and Trading Account"
              ? "1"
              : "2",
          pep: politicalVal,
          periodicSettlement: parseInt(periodicSettlement),
          placeofbirth: placeOfBirth,
          staffCode: staffCode,
          taxResidentOfOtherCountry: !indianResident,
          listTaxrRsidentofIndia: !indianResident
            ? [
                {
                  identificationNumber: nriDetailsObj.idTypeValue,
                  identificationType: nriDetailsObj.idType,
                  identificationType_Other: nriDetailsObj.idTypeOther || "TIN",
                  oCountry: nriDetailsObj.country,
                },
              ]
            : null,
        },
        utmInfoDetails: objUTM_Info,
      };

      const listMultipleGuardianDetails = [];
      let srNo = 0;
      for (let i = 0; i < nomiDetails.length; i++) {
        if (nomiDetails[i].isNomineeMinor && nomiDetails[i].guardianDetails) {
          const guardianNames = splitFullName(
            nomiDetails[i]?.guardianDetails?.guardFullName
          );
          const guardianAddressLine = repairAddressWithConditions(
            nomiDetails[i]?.guardianDetails?.guardianAddress.addressLine1,
            nomiDetails[i]?.guardianDetails?.guardianAddress.city
          );

          listMultipleGuardianDetails.push({
            annualIncomeRange:
              nomiDetails[i]?.guardianDetails?.guardAnnualIncome,
            dob: moment(nomiDetails[i]?.nomDob).format("YYYY-MM-DD"),
            education: nomiDetails[i]?.guardianDetails?.guardEduQualification,
            holderId: 5,
            isSamePermenantAddress: true,
            isMinor: false,
            firstName: guardianNames[0],
            middleName: guardianNames[1],
            lastName: guardianNames[2],
            netWorthwhenAcOpen: parseInt(
              nomiDetails[i]?.guardianDetails?.guardNetWorth
            ),
            correspondenceAddressDetails: {
              correspondenceAddressType: addressType,
              correspondenceCity:
                nomiDetails[i]?.guardianDetails?.guardianAddress.City,
              correspondenceCountry:
                nomiDetails[i]?.guardianDetails?.guardianAddress.Country,
              correspondenceDistrict:
                nomiDetails[i]?.guardianDetails?.guardianAddress.District,
              correspondencePincode:
                nomiDetails[i]?.guardianDetails?.guardianAddress.PinCode,
              correspondenceState:
                nomiDetails[i]?.guardianDetails?.guardianAddress.State,
              correspondenceArea:
                nomiDetails[i]?.guardianDetails?.guardianAddress.Area,
              correspondenceCountryCode:
                nomiDetails[i]?.guardianDetails?.guardianAddress.CountryCode,
              correspondenceStatecode:
                nomiDetails[i]?.guardianDetails?.guardianAddress.StateCode,
              correspondenceAddressLine1: removeSpclCracters(
                guardianAddressLine.addLine1
              ),
              correspondenceAddressLine2: removeSpclCracters(
                guardianAddressLine.addLine2
              ),
              correspondenceAddressLine3: removeSpclCracters(
                guardianAddressLine.addLine3
              ),
              correspondenceAddressLine4: removeSpclCracters(
                guardianAddressLine.addLine4
              ),
              autoQc: false,
            },
            permanentAddressDetails: {
              permanentAddressLine1: removeSpclCracters(
                guardianAddressLine.addLine1
              ),
              permanentAddressLine2: removeSpclCracters(
                guardianAddressLine.addLine2
              ),
              permanentAddressLine3: removeSpclCracters(
                guardianAddressLine.addLine3
              ),
              permanentAddressLine4: removeSpclCracters(
                guardianAddressLine.addLine4
              ),
              permanentAddresstype: addressType,
              permanentCity:
                nomiDetails[i]?.guardianDetails?.guardianAddress.City,
              permanentCountry:
                nomiDetails[i]?.guardianDetails?.guardianAddress.Country,
              permanentDistrict:
                nomiDetails[i]?.guardianDetails?.guardianAddress.District,
              permanentPincode:
                nomiDetails[i]?.guardianDetails?.guardianAddress.PinCode,
              permanentState:
                nomiDetails[i]?.guardianDetails?.guardianAddress.State,
              permanentarea:
                nomiDetails[i]?.guardianDetails?.guardianAddress.Area,
              permanentcountrycode:
                nomiDetails[i]?.guardianDetails?.guardianAddress.CountryCode,
              permanentstatecode:
                nomiDetails[i]?.guardianDetails?.guardianAddress.StateCode,
              autoQc: false,
            },
            occupation: nomiDetails[i]?.guardianDetails?.guardOccupation,
            pep: nomiDetails[i]?.guardianDetails?.guardPoliticalVal,
            placeofbirth: nomiDetails[i]?.guardianDetails?.guardPlaceOfBirth,
            relationship: nomiDetails[i]?.guardianDetails?.guardRelation,
            title: nomiDetails[i]?.guardianDetails?.guardTitle,
            additionalDetails: await additionalDetailsOfGuardian(
              nomiDetails,
              i,
              "name"
            ),
            additionalDetailsValue:
              // "guardian" + (i + 1),
              await additionalDetailsOfGuardian(nomiDetails, i, "value"),
            gurdianMap: `4_${i}`,
            // srNo: srNo,
            srNo: i,
          });
          srNo += 1;
        }
      }
      kycObj["listGuardianDetails"] = listMultipleGuardianDetails;

      if (listMultipleGuardianDetails && listMultipleGuardianDetails.length)
        kycObj["isGuardian"] = true;

      const listMultiplenomineeDetails = [];
      if (showNominee && numOfNominee > 0) {
        for (let j = 0; j < nomiDetails.length; j++) {
          const singleNomineeDetails = nomiDetails[j];
          const nomineeNames = splitFullName(singleNomineeDetails?.nomFullName);
          const nomineeAddressLine = repairAddressWithConditions(
            singleNomineeDetails.nomineeAddress.addressLine1,
            singleNomineeDetails.nomineeAddress.city
          );

          listMultiplenomineeDetails.push({
            dob: moment(singleNomineeDetails.nomDob).format("YYYY-MM-DD"),
            holderId: 4,
            isMinor: singleNomineeDetails.isNomineeMinor,
            firstName: nomineeNames[0],
            middleName: nomineeNames[1],
            lastName: nomineeNames[2],
            netWorthwhenAcOpen: 1000,
            correspondenceAddressDetails: {
              correspondenceAddressType: addressType,
              correspondenceCity: singleNomineeDetails.nomineeAddress.City,
              correspondenceCountry:
                singleNomineeDetails.nomineeAddress.Country,
              correspondenceDistrict:
                singleNomineeDetails.nomineeAddress.District,
              correspondencePincode:
                singleNomineeDetails.nomineeAddress.PinCode,
              correspondenceState: singleNomineeDetails.nomineeAddress.State,
              correspondenceArea: singleNomineeDetails.nomineeAddress.Area,
              correspondenceCountryCode:
                singleNomineeDetails.nomineeAddress.CountryCode,
              correspondenceStatecode:
                singleNomineeDetails.nomineeAddress.StateCode,
              correspondenceAddressLine1: removeSpclCracters(
                nomineeAddressLine.addLine1
              ),
              correspondenceAddressLine2: removeSpclCracters(
                nomineeAddressLine.addLine2
              ),
              correspondenceAddressLine3: removeSpclCracters(
                nomineeAddressLine.addLine3
              ),
              correspondenceAddressLine4: removeSpclCracters(
                nomineeAddressLine.addLine4
              ),
              autoQc: false,
            },
            permanentAddressDetails: {
              permanentAddressLine1: removeSpclCracters(
                nomineeAddressLine.addLine1
              ),
              permanentAddressLine2: removeSpclCracters(
                nomineeAddressLine.addLine2
              ),
              permanentAddressLine3: removeSpclCracters(
                nomineeAddressLine.addLine3
              ),
              permanentAddressLine4: removeSpclCracters(
                nomineeAddressLine.addLine4
              ),
              permanentAddresstype: addressType,
              permanentCity: singleNomineeDetails.nomineeAddress.City,
              permanentCountry: singleNomineeDetails.nomineeAddress.Country,
              permanentDistrict: singleNomineeDetails.nomineeAddress.District,
              permanentPincode: singleNomineeDetails.nomineeAddress.PinCode,
              permanentState: singleNomineeDetails.nomineeAddress.State,
              permanentarea: singleNomineeDetails.nomineeAddress.Area,
              permanentcountrycode:
                singleNomineeDetails.nomineeAddress.CountryCode,
              permanentstatecode: singleNomineeDetails.nomineeAddress.StateCode,
              autoQc: false,
            },
            relationship: singleNomineeDetails.nomRelation,
            title: singleNomineeDetails.nomTitle,
            additionalDetails: await additionalDetailsOfNominee(
              singleNomineeDetails,
              j,
              "name"
            ),
            // singleNomineeDetails?.identificationDetail?.name,
            additionalDetailsValue:
              // "Nominee" + (j + 1),
              await additionalDetailsOfNominee(
                singleNomineeDetails,
                j,
                "value"
              ),
            // singleNomineeDetails?.identificationDetail?.value,
            srNo: j,
            sharePercentage: Number(singleNomineeDetails?.nomPerSharing),
          });
        }
      }
      kycObj["listNomineeDetails"] = listMultiplenomineeDetails;

      console.log("nomiDetails ---------->", nomiDetails);

      //temp fix for address flag issue as not able to reproduce the issue
      var correspondenceAddressCheck =
        kycObj["persoanlDetails"]["correspondenceAddressDetails"];
      var permanentAddressCheck =
        kycObj["persoanlDetails"]["permanentAddressDetails"];

      if (
        (correspondenceAddressCheck.correspondenceAddressLine1 ==
          permanentAddressCheck.permanentAddressLine1 &&
          correspondenceAddressCheck.correspondenceAddressLine2 ==
            permanentAddressCheck.permanentAddressLine2 &&
          correspondenceAddressCheck.correspondenceAddressLine3 ==
            permanentAddressCheck.permanentAddressLine3 &&
          correspondenceAddressCheck.correspondenceAddressLine4 ==
            permanentAddressCheck.permanentAddressLine4 &&
          correspondenceAddressCheck.correspondenceCity ==
            permanentAddressCheck.permanentCity &&
          correspondenceAddressCheck.correspondenceCountry ==
            permanentAddressCheck.permanentCountry &&
          correspondenceAddressCheck.correspondenceDistrict ==
            permanentAddressCheck.permanentDistrict &&
          correspondenceAddressCheck.correspondencePincode ==
            permanentAddressCheck.permanentPincode &&
          correspondenceAddressCheck.correspondenceState ==
            permanentAddressCheck.permanentState &&
          correspondenceAddressCheck.correspondenceArea ==
            permanentAddressCheck.permanentarea) ||
        GlobalStore.isDigiLocker
      ) {
        kycObj["persoanlDetails"]["corrsaddraspermanent"] = true;
      } else {
        kycObj["persoanlDetails"]["corrsaddraspermanent"] = false;
      }
      ASaveKycDetails(saveKycDetails, kycObj);
    } else {
      setGlobalError("Nominee % sharing should be 100");
      return;
    }
  }

  function saveKycDetails(res: any) {
    setIsLoading(true);
    console.log("function called");
    if (!res || res.error) {
      // setErrCode('1120 - Failed to save the KYC details');
      // setErrCode(errorMessage.saveKycDetails);
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data) {
        // Check if any nominee and additional identification is Photo & Signature
        // if found hit save document details API // Added by rohit
        let toCheckString = ["Photo and Signature of the Nominee/guardian"];
        let toCheckPhoto = nomiDetails.filter((ele: any) =>
          toCheckString.includes(ele.identificationDetail.name)
        );

        // if (showNominee && numOfNominee > 0 && toCheckPhoto.length > 0) {
        //   saveNomineeDocuments();
        // } else {
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        clientPartialDetails_ao.emailID = email;
        clientPartialDetails_ao.mobile = phone;
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        completeModuleStatus(dashboardUrl.profile);
        completeModuleStatus(dashboardUrl.personalIfo);
        gtmService({ action: "PROFILE_DETAILS", label: "Step_Complete" });

        // Submit the clevertap event for profile submit
        const data = {
          EventName:
            cleverTapEvents.registration.profilePage.CLICK_ON_SUBMIT_PROFILE,
          InitiationPlatform: "",
          UTM_SOURCE,
          UTM_MEDIUM,
          UTM_CAMPAIGN,
          KRA: GlobalStore.isKRA,
          ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
        };

        ClevertapService.pushEvent(data);
        // Clevertap code end

        // Apps flyer event for click on proceed on profile details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.profilePage.CLICK_ON_SUBMIT_PROFILE
        );

        history.push(incompleteStageNavigation());
        // }
      } else {
        // setErrCode('1121 - Failed to save the KYC details');
        setErrCode(res.error);
        setIsLoading(false);
      }
    }
  }

  // async function saveNomineeDocuments() {
  //   var nomineeDocs = nomineeDocuments;
  //   const saveDetails = [];
  //   if (nomineeDocs.nomineeOne) {
  //     if (nomineeDocs.nomineeOne.photo && newUploads[0].isPhoto === true) {
  //       let nomineeOnePhoto = {
  //         documentCategoryId:
  //           nomineeDocsIds.nomineeOne.photo.documentCategoryId?.toString(),
  //         documentId: nomineeDocsIds.nomineeOne.photo.documentId?.toString(),
  //         documentexpirydate: "",
  //         fileName: nomineeDocs.nomineeOne.photo.fileName,
  //         isfilecropped: true,
  //         mimeType: nomineeDocs.nomineeOne.photo.mimeType,
  //         panno: GlobalStore.personaldetails.pan,
  //         personaldetailsid: "0",
  //         autoqc: false,
  //         // "autoqc": autoQc
  //       };
  //       saveDetails.push(nomineeOnePhoto);
  //     }
  //     if (
  //       nomineeDocs.nomineeOne.signature &&
  //       newUploads[0].isSignature === true
  //     ) {
  //       let nomineeOneSignature = {
  //         documentCategoryId:
  //           nomineeDocsIds.nomineeOne.signature.documentCategoryId?.toString(),
  //         documentId:
  //           nomineeDocsIds.nomineeOne.signature.documentId?.toString(),
  //         documentexpirydate: "",
  //         fileName: nomineeDocs.nomineeOne.signature.fileName,
  //         isfilecropped: true,
  //         mimeType: nomineeDocs.nomineeOne.signature.mimeType,
  //         panno: GlobalStore.personaldetails.pan,
  //         personaldetailsid: "0",
  //         autoqc: false,
  //         // "autoqc": autoQc
  //       };
  //       saveDetails.push(nomineeOneSignature);
  //     }
  //     if (nomineeDocs.nomineeOne.guardianDetails) {
  //       if (
  //         nomineeDocs.nomineeOne.guardianDetails.photo &&
  //         newUploads[0].guardian.isPhoto === true
  //       ) {
  //         let nomineeOneGuardianPhoto = {
  //           documentCategoryId:
  //             nomineeDocsIds.nomineeOne.guardianDetails.photo.documentCategoryId?.toString(),
  //           documentId:
  //             nomineeDocsIds.nomineeOne.guardianDetails.photo.documentId?.toString(),
  //           documentexpirydate: "",
  //           fileName: nomineeDocs.nomineeOne.guardianDetails.photo.fileName,
  //           isfilecropped: true,
  //           mimeType: nomineeDocs.nomineeOne.guardianDetails.photo.mimeType,
  //           panno: GlobalStore.personaldetails.pan,
  //           personaldetailsid: "0",
  //           autoqc: false,
  //           // "autoqc": autoQc
  //         };
  //         saveDetails.push(nomineeOneGuardianPhoto);
  //       }
  //       if (
  //         nomineeDocs.nomineeOne.guardianDetails.signature &&
  //         newUploads[0].guardian.isSignature === true
  //       ) {
  //         let nomineeOneGuardianSignature = {
  //           documentCategoryId:
  //             nomineeDocsIds.nomineeOne.guardianDetails.signature.documentCategoryId?.toString(),
  //           documentId:
  //             nomineeDocsIds.nomineeOne.guardianDetails.signature.documentId?.toString(),
  //           documentexpirydate: "",
  //           fileName: nomineeDocs.nomineeOne.guardianDetails.signature.fileName,
  //           isfilecropped: true,
  //           mimeType: nomineeDocs.nomineeOne.guardianDetails.signature.mimeType,
  //           panno: GlobalStore.personaldetails.pan,
  //           personaldetailsid: "0",
  //           autoqc: false,
  //           // "autoqc": autoQc
  //         };
  //         saveDetails.push(nomineeOneGuardianSignature);
  //       }
  //     }
  //   }

  //   if (nomineeDocs.nomineeTwo) {
  //     if (nomineeDocs.nomineeTwo.photo && newUploads[1].isPhoto === true) {
  //       let nomineeTwoPhoto = {
  //         documentCategoryId:
  //           nomineeDocsIds.nomineeTwo.photo.documentCategoryId?.toString(),
  //         documentId: nomineeDocsIds.nomineeTwo.photo.documentId?.toString(),
  //         documentexpirydate: "",
  //         fileName: nomineeDocs.nomineeTwo.photo.fileName,
  //         isfilecropped: true,
  //         mimeType: nomineeDocs.nomineeTwo.photo.mimeType,
  //         panno: GlobalStore.personaldetails.pan,
  //         personaldetailsid: "0",
  //         autoqc: false,
  //         // "autoqc": autoQc
  //       };
  //       saveDetails.push(nomineeTwoPhoto);
  //     }
  //     if (
  //       nomineeDocs.nomineeTwo.signature &&
  //       newUploads[1].isSignature === true
  //     ) {
  //       let nomineeTwoSignature = {
  //         documentCategoryId:
  //           nomineeDocsIds.nomineeTwo.signature.documentCategoryId?.toString(),
  //         documentId:
  //           nomineeDocsIds.nomineeTwo.signature.documentId?.toString(),
  //         documentexpirydate: "",
  //         fileName: nomineeDocs.nomineeTwo.signature.fileName,
  //         isfilecropped: true,
  //         mimeType: nomineeDocs.nomineeTwo.signature.mimeType,
  //         panno: GlobalStore.personaldetails.pan,
  //         personaldetailsid: "0",
  //         autoqc: false,
  //         // "autoqc": autoQc
  //       };
  //       saveDetails.push(nomineeTwoSignature);
  //     }
  //     if (nomineeDocs.nomineeTwo.guardianDetails) {
  //       if (
  //         nomineeDocs.nomineeTwo.guardianDetails &&
  //         newUploads[1].guardian.isPhoto === true
  //       ) {
  //         let nomineeTwoGuardianPhoto = {
  //           documentCategoryId:
  //             nomineeDocsIds.nomineeTwo.guardianDetails.photo.documentCategoryId?.toString(),
  //           documentId:
  //             nomineeDocsIds.nomineeTwo.guardianDetails.photo.documentId?.toString(),
  //           documentexpirydate: "",
  //           fileName: nomineeDocs.nomineeTwo.guardianDetails.photo.fileName,
  //           isfilecropped: true,
  //           mimeType: nomineeDocs.nomineeTwo.guardianDetails.photo.mimeType,
  //           panno: GlobalStore.personaldetails.pan,
  //           personaldetailsid: "0",
  //           autoqc: false,
  //           // "autoqc": autoQc
  //         };
  //         saveDetails.push(nomineeTwoGuardianPhoto);
  //       }
  //       if (
  //         nomineeDocs.nomineeTwo.guardianDetails.signature &&
  //         newUploads[1].guardian.isSignature === true
  //       ) {
  //         let nomineeTwoGuardianSignature = {
  //           documentCategoryId:
  //             nomineeDocsIds.nomineeTwo.guardianDetails.signature.documentCategoryId?.toString(),
  //           documentId:
  //             nomineeDocsIds.nomineeTwo.guardianDetails.signature.documentId?.toString(),
  //           documentexpirydate: "",
  //           fileName: nomineeDocs.nomineeTwo.guardianDetails.signature.fileName,
  //           isfilecropped: true,
  //           mimeType: nomineeDocs.nomineeTwo.guardianDetails.signature.mimeType,
  //           panno: GlobalStore.personaldetails.pan,
  //           personaldetailsid: "0",
  //           autoqc: false,
  //           // "autoqc": autoQc
  //         };
  //         saveDetails.push(nomineeTwoGuardianSignature);
  //       }
  //     }
  //   }

  //   if (nomineeDocs.nomineeThree) {
  //     if (nomineeDocs.nomineeThree.photo && newUploads[2].isPhoto === true) {
  //       let nomineeThreePhoto = {
  //         documentCategoryId:
  //           nomineeDocsIds.nomineeThree.photo.documentCategoryId?.toString(),
  //         documentId: nomineeDocsIds.nomineeThree.photo.documentId?.toString(),
  //         documentexpirydate: "",
  //         fileName: nomineeDocs.nomineeThree.photo.fileName,
  //         isfilecropped: true,
  //         mimeType: nomineeDocs.nomineeThree.photo.mimeType,
  //         panno: GlobalStore.personaldetails.pan,
  //         personaldetailsid: "0",
  //         autoqc: false,
  //         // "autoqc": autoQc
  //       };
  //       saveDetails.push(nomineeThreePhoto);
  //     }
  //     if (
  //       nomineeDocs.nomineeThree.signature &&
  //       newUploads[2].isSignature === true
  //     ) {
  //       let nomineeThreeSignature = {
  //         documentCategoryId:
  //           nomineeDocsIds.nomineeThree.signature.documentCategoryId?.toString(),
  //         documentId:
  //           nomineeDocsIds.nomineeThree.signature.documentId?.toString(),
  //         documentexpirydate: "",
  //         fileName: nomineeDocs.nomineeThree.signature.fileName,
  //         isfilecropped: true,
  //         mimeType: nomineeDocs.nomineeThree.signature.mimeType,
  //         panno: GlobalStore.personaldetails.pan,
  //         personaldetailsid: "0",
  //         autoqc: false,
  //         // "autoqc": autoQc
  //       };
  //       saveDetails.push(nomineeThreeSignature);
  //     }
  //     if (nomineeDocs.nomineeThree.guardianDetails) {
  //       if (
  //         nomineeDocs.nomineeThree.guardianDetails.photo &&
  //         newUploads[2].guardian.isPhoto === true
  //       ) {
  //         let nomineeThreeGuardianPhoto = {
  //           documentCategoryId:
  //             nomineeDocsIds.nomineeThree.guardianDetails.photo.documentCategoryId?.toString(),
  //           documentId:
  //             nomineeDocsIds.nomineeThree.guardianDetails.photo.documentId?.toString(),
  //           documentexpirydate: "",
  //           fileName: nomineeDocs.nomineeThree.guardianDetails.photo.fileName,
  //           isfilecropped: true,
  //           mimeType: nomineeDocs.nomineeThree.guardianDetails.photo.mimeType,
  //           panno: GlobalStore.personaldetails.pan,
  //           personaldetailsid: "0",
  //           autoqc: false,
  //           // "autoqc": autoQc
  //         };
  //         saveDetails.push(nomineeThreeGuardianPhoto);
  //       }
  //       if (
  //         nomineeDocs.nomineeThree.guardianDetails.signature &&
  //         newUploads[2].guardian.isSignature === true
  //       ) {
  //         let nomineeThreeGuardianSignature = {
  //           documentCategoryId:
  //             nomineeDocsIds.nomineeThree.guardianDetails.signature.documentCategoryId?.toString(),
  //           documentId:
  //             nomineeDocsIds.nomineeThree.guardianDetails.signature.documentId?.toString(),
  //           documentexpirydate: "",
  //           fileName:
  //             nomineeDocs.nomineeThree.guardianDetails.signature.fileName,
  //           isfilecropped: true,
  //           mimeType:
  //             nomineeDocs.nomineeThree.guardianDetails.signature.mimeType,
  //           panno: GlobalStore.personaldetails.pan,
  //           personaldetailsid: "0",
  //           autoqc: false,
  //           // "autoqc": autoQc
  //         };
  //         saveDetails.push(nomineeThreeGuardianSignature);
  //       }
  //     }
  //   }

  //   console.log("saveDetails", saveDetails);
  //   //setIsLoading(false);
  //   const objUTM_Info = utmDurationInfoObj(startTime, "documentsdur");
  //   const saveObj = {
  //     applicationId: GlobalStore.applicationID,
  //     clientPrimaryDetailId: GlobalStore.clientPrimaryDetailId || 0,
  //     documentInfo: saveDetails,
  //     leadId: GlobalStore.leadId,
  //     objUTM_Info: objUTM_Info,
  //   };

  //   ASaveDocumentDetails(getSaveStatus, saveObj);

  //   //return true;
  // }

  const getSaveStatus = (res: any) => {
    if (!res || res.error) {
      //setErrCode(1641)
      setIsLoading(false);
      return false;
    } else {
      if (res.data) {
        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        clientPartialDetails_ao.emailID = email;
        clientPartialDetails_ao.mobile = phone;
        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );
        completeModuleStatus(dashboardUrl.profile);
        completeModuleStatus(dashboardUrl.personalIfo);
        gtmService({ action: "PROFILE_DETAILS", label: "Step_Complete" });

        // Submit the clevertap event for profile submit
        const data = {
          EventName:
            cleverTapEvents.registration.profilePage.CLICK_ON_SUBMIT_PROFILE,
          InitiationPlatform: "",
          UTM_SOURCE,
          UTM_MEDIUM,
          UTM_CAMPAIGN,
          KRA: GlobalStore.isKRA,
          ADHAR_LINKED_WITH_MOBILE: GlobalStore.aadharLinkedChoice,
          DIGILOCKER_PROCESS: GlobalStore.isDigiLocker,
          LEAD_ID: GlobalStore.leadId,
          REF_ID: GlobalStore.applicationID,
        };

        ClevertapService.pushEvent(data);
        // Clevertap code end

        // Apps flyer event for click on proceed on profile details page
        AppsFlyerService.pushEvent(
          appsFlyerEvents.registration.profilePage.CLICK_ON_SUBMIT_PROFILE
        );

        history.push(incompleteStageNavigation());
      }
    }
  };

  function onChange(key: string, value: string) {
    setPlaceOfBirth(value);
  }

  function handleCityBlur(value: any) {
    if (typeof value === "object") {
      setErrors({ ...errors, placeOfBirth: "" });
    } else {
      setErrors({
        ...errors,
        placeOfBirth: errorMsg(errorCode.FE39),
      });
    }
  }

  // console.log('buttonDisabled?: ', validateSubmit());
  // console.log('value for nominee', nomiDetails);
  const handleRemoveDoc = (
    index: number,
    identityId: string,
    removeDocObj: any
  ) => {
    console.log("HandleRemoveDoc", index, identityId, removeDocObj);
  };

  const handleAddNomineeValue = (name: string, index: number, value: any) => {
    try {
      const array = JSON.parse(JSON.stringify(nomiDetails));
      if (name === "identificationDetail") {
        array[index][name] = { name: value?.display };

        if (value?.display === "Photo and Signature of the Nominee/guardian") {
          array[index][name] = {
            name: value?.display,
            value: "Nominee" + (index + 1),
          };
        }
        var objNomineeDocs = nomineeDocuments;

        if (value?.value == 1) {
          let imgOfAddlIdentDtls = JSON.parse(
            JSON.stringify(imgOfAddlIdentDetails)
          );
          imgOfAddlIdentDtls[index]["nomineeAddDtls"] = {
            isPhoto: false,
            isSignature: false,
          };
          setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
          if (index == 0) {
            objNomineeDocs.nomineeOne.photo = { ...initialDocObj };
            objNomineeDocs.nomineeOne.signature = { ...initialDocObj };
            setNomineeDocuments(objNomineeDocs);
          }

          if (index == 1) {
            objNomineeDocs.nomineeTwo.photo = { ...initialDocObj };
            objNomineeDocs.nomineeTwo.signature = { ...initialDocObj };
            setNomineeDocuments(objNomineeDocs);
          }

          if (index == 2) {
            objNomineeDocs.nomineeThree.photo = { ...initialDocObj };
            objNomineeDocs.nomineeThree.signature = { ...initialDocObj };
            setNomineeDocuments(objNomineeDocs);
          }
        } else {
          let imgOfAddlIdentDtls = JSON.parse(
            JSON.stringify(imgOfAddlIdentDetails)
          );
          imgOfAddlIdentDtls[index]["nomineeAddDtls"] = {
            isPhoto: true,
            isSignature: true,
          };
          setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
          if (index == 0) {
            delete objNomineeDocs.nomineeOne.photo;
            delete objNomineeDocs.nomineeOne.signature;
            setNomineeDocuments(objNomineeDocs);
          }
          if (index == 1) {
            delete objNomineeDocs.nomineeTwo.photo;
            delete objNomineeDocs.nomineeTwo.signature;
            setNomineeDocuments(objNomineeDocs);
          }
          if (index == 2) {
            delete objNomineeDocs.nomineeThree.photo;
            delete objNomineeDocs.nomineeThree.signature;
            setNomineeDocuments(objNomineeDocs);
          }
        }
        setAddlIdentDetailsValue(false);
      } else if (name === "identificationDetailValue") {
        array[index]["identificationDetail"]["value"] = value;
        setAddlIdentDetailsValue(true);
      } else {
        array[index][name] = value;
      }

      if (value) {
        if (array[index]["error"] && array[index]["error"][name]) {
          console.log("error from on change", array[index]["error"]);

          array[index]["error"][name] = undefined;
        }
      }

      if (name === "nomDob" && ageCalculator(value) < 18) {
        array[index]["isNomineeMinor"] = true;
        array[index]["guardianDetails"] = {};
        array[index]["guardianDetails"]["guardPoliticalVal"] = "0";
        array[index]["guardianDetails"]["guardianAddress"] = {
          ...initialAddressObj,
        };
        array[index]["guardianDetails"]["sameAsNominee"] = false;
      } else if (name === "nomDob" && ageCalculator(value) >= 18) {
        array[index]["isNomineeMinor"] = false;
      }

      if (name === "nomPerSharing") {
        if (numOfNominee !== 1) {
          for (let i = 0; i < numOfNominee; i++) {
            if (i !== index) {
              const remainigPer = (100 - Number(value)) / (numOfNominee - 1);
              array[i]["nomPerSharing"] = remainigPer;
            }
          }
        }
      }

      setNomiDetails(array);

      window.localStorage.removeItem("ckycBackup");
      localStorage.setItem("ckycBackup", JSON.stringify(array));
    } catch (error) {
      console.log("error from on chane", error);
    }
  };

  const handleAddNomineeError = (index: number, name: string, value: any) => {
    const array = JSON.parse(JSON.stringify(nomiDetails));
    if (name === "nomDob" && array[index][name]) {
      array[index]["error"][name] = null;
    } else {
      array[index]["error"][name] = value;
    }
    setNomiDetails(array);
  };

  const handleAddNomineeGuardianValue = (
    name: string,
    index: number,
    value: any
  ) => {
    const array = JSON.parse(JSON.stringify(nomiDetails));
    if (name === "identificationDetail") {
      array[index]["guardianDetails"][name] = { name: value?.display };
      if (value?.display === "Photo and Signature of the Nominee/guardian") {
        array[index]["guardianDetails"][name] = {
          name: value?.display,
          value: "Guardian" + (index + 1),
        };
      }
      setAddlIdentDetailsValue(false);
      if (value?.value == 1) {
        let imgOfAddlIdentDtls = JSON.parse(
          JSON.stringify(imgOfAddlIdentDetails)
        );
        imgOfAddlIdentDtls[index]["guardianAddDtls"] = {
          isPhoto: false,
          isSignature: false,
        };
        setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
        var objNomineeDocs = nomineeDocuments;
        if (index == 0) {
          objNomineeDocs.nomineeOne.guardianDetails = {};
          objNomineeDocs.nomineeOne.guardianDetails.photo = {
            ...initialDocObj,
          };
          objNomineeDocs.nomineeOne.guardianDetails.signature = {
            ...initialDocObj,
          };
          setNomineeDocuments(objNomineeDocs);
        }
        if (index == 1) {
          objNomineeDocs.nomineeTwo.guardianDetails = {};
          objNomineeDocs.nomineeTwo.guardianDetails.photo = {
            ...initialDocObj,
          };
          objNomineeDocs.nomineeTwo.guardianDetails.signature = {
            ...initialDocObj,
          };
          setNomineeDocuments(objNomineeDocs);
        }
        if (index == 2) {
          objNomineeDocs.nomineeThree.guardianDetails = {};
          objNomineeDocs.nomineeThree.guardianDetails.photo = {
            ...initialDocObj,
          };
          objNomineeDocs.nomineeThree.guardianDetails.signature = {
            ...initialDocObj,
          };
          setNomineeDocuments(objNomineeDocs);
        }
      } else {
        let imgOfAddlIdentDtls = JSON.parse(
          JSON.stringify(imgOfAddlIdentDetails)
        );
        imgOfAddlIdentDtls[index]["guardianAddDtls"] = {
          isPhoto: true,
          isSignature: true,
        };
        setImgOfAddlIdentDetails(imgOfAddlIdentDtls);
        var objNomineeDocs = nomineeDocuments;
        if (index == 0 && objNomineeDocs.nomineeOne.guardianDetails) {
          delete objNomineeDocs.nomineeOne.guardianDetails;
          setNomineeDocuments(objNomineeDocs);
        }
        if (index == 1 && objNomineeDocs.nomineeTwo.guardianDetails) {
          delete objNomineeDocs.nomineeTwo.guardianDetails;
          setNomineeDocuments(objNomineeDocs);
        }
        if (index == 2 && objNomineeDocs.nomineeThree.guardianDetails) {
          delete objNomineeDocs.nomineeThree.guardianDetails;
          setNomineeDocuments(objNomineeDocs);
        }
      }
    } else if (name === "guardIdentificationDetailValue") {
      array[index]["guardianDetails"]["identificationDetail"]["value"] = value;
      setAddlIdentDetailsValue(true);
    } else {
      array[index]["guardianDetails"][name] = value;
    }

    if (value) {
      if (array[index]["error"] && array[index]["error"]?.name)
        array[index]["error"][name] = undefined;
    }
    setNomiDetails(array);

    window.localStorage.removeItem("ckycBackup");
    localStorage.setItem("ckycBackup", JSON.stringify(array));
  };
  function handleAccountTypeBlur(key: string, value: any) {
    typeof value !== "object"
      ? setErrors({ ...errors, [key]: "Please select from dropdown" })
      : setErrors({ ...errors, [key]: undefined });
  }

  console.log("RelationshipData", relationshipData, "||", familyRelationList);

  function ChangePoliticalVal(Value: any) {
    setPoliticalVal(Value);
    setErrCode("");
    errorMsg("");
  }
  const getFullPANNAMe = () => {
    let panFullName = "";
    if (
      GlobalStore?.stageStatus?.panfirstname !== undefined &&
      GlobalStore?.stageStatus?.panfirstname?.length > 0
    ) {
      panFullName = GlobalStore?.stageStatus?.panfirstname + " ";
      if (
        GlobalStore?.stageStatus?.panmiddletname !== undefined &&
        GlobalStore?.stageStatus?.panmiddletname?.length > 0
      ) {
        panFullName += GlobalStore?.stageStatus?.panmiddletname
          .split(" ")
          .join(" ");
      }
      if (
        GlobalStore?.stageStatus?.panlastname !== undefined &&
        GlobalStore?.stageStatus?.panlastname?.length > 0
      ) {
        panFullName = GlobalStore?.stageStatus?.panlastname
          ? panFullName + " " + GlobalStore?.stageStatus?.panlastname
          : panFullName;
      }
    }
    if (panFullName == null || panFullName == undefined || panFullName == "") {
      panFullName = GlobalStore.personaldetails.PANName;
    }
    return panFullName;
  };
  return (
    <div className={"profileForm " + (isLoading ? " op05" : "")}>
      <label className="prflFormHeading">Primary Details</label>

      <div className="mgt40">
        <label className="fieldLbl">Name (as per PAN)</label>
        <InputField
          defaultValue={getFullPANNAMe()}
          disabled={true}
          type="text"
          helpText="This field cannot be edited"
        />
      </div>
      <form>
        <fieldset disabled={isLoading}>
          {!hideAddress && (
            <>
              {/* Permanent Address Start */}
              <AddressField
                addressObj={permanentAddress}
                isDisable={
                  (GlobalStore.isKYC ||
                    GlobalStore.isKRA ||
                    GlobalStore.isDigiLocker) &&
                  !regectedAddressDoc
                }
                sectionLabel="Permanent Addr."
                addressType="permanent"
                setErrCode={setErrCode}
                onAddressChange={(obj: IAddressFormat) => {
                  setPermanentAddress(obj);
                  sameAsPermanent && setCorrespondanceAddress(obj);
                  console.log(
                    correspondanceAddress,
                    "correspondanceAddress works 5"
                  );
                }}
                onAddressBlur={setPermErrorObj}
                handleSameAsAbove={() => {
                  console.log("great works");
                }}
              />
              {/* Permanent Address End */}

              {/* Correspondence Address Start */}
              {!GlobalStore.isDigiLocker && (
                <AddressField
                  addressObj={correspondanceAddress}
                  isDisable={
                    GlobalStore.isKYC ||
                    GlobalStore.isKRA ||
                    GlobalStore.isDigiLocker
                  }
                  sectionLabel="Correspondence Addr."
                  addressType="correspondance"
                  setErrCode={setErrCode}
                  onAddressChange={setCorrespondanceAddress}
                  onAddressBlur={setCorrErrorObj}
                  showSameAsAbove
                  sameAsAbove={sameAsPermanent}
                  handleSameAsAbove={handleSameAsAbove}
                />
              )}
              {/* Correspondence Address End */}
            </>
          )}

          {/* Mobile Phone Start*/}
          <div className="inputVerification mgt40">
            <div className="">
              <label className="fieldLbl">Mobile No.</label>
              <InputField
                defaultValue={phone}
                pattern={phoneNo}
                disabled={true}
                // onChange={(value) => onEmailPhoneChange('phone', value)}
                onBlur={(error) =>
                  setErrors({
                    ...errors,
                    phone: error.pattern ? "" : errorMsg(errorCode.FE08),
                  })
                }
                placeholder="Eg. 9876543210"
                errorText={errors.phone}
                integer
                type="tel"
                suffix={
                  GlobalStore.user.mobile === phone &&
                  phone !== "" &&
                  isMobileVerified &&
                  !showMobileOtp &&
                  !showMobileBtn ? (
                    <i className="edel-icon-tick" />
                  ) : (
                    <></>
                  )
                }
                maxLength={10}
              />
            </div>
            {showMobileBtn && (
              <div className="emlVrfy">
                <Button
                  type="submit"
                  btnName="Verify Mobile No."
                  isLoading={isLoading}
                  disabled={
                    Object.values([errors.phone]).some((error) => error) ||
                    phone === ""
                  }
                  onClick={() => sendOtp("mobile")}
                />
              </div>
            )}
          </div>
          {showMobileOtp && !showMobileBtn && (
            <div className="mgt40">
              <RegOtp
                label="Enter Mobile OTP"
                otpTimer={15}
                eleId="mob_"
                onChange={(val) => validateOtp("mobile", val)}
                resendClicked={() => sendOtp("mobile")}
                disableOtp={disableOtp}
                invalidOtp={invalidOtp}
              />
            </div>
          )}
          {/* Mobile Phone End*/}

          {/* Email Start*/}
          {/* <div className="inputVerification mgt40">
            <div className="">
              <label className="fieldLbl">Email ID *</label>
              <InputField
                defaultValue={email}
                pattern={emailRegex}
                disabled={true}
                // onChange={(value) => onEmailPhoneChange('email', value)}
                onBlur={(error) =>
                  setErrors({
                    ...errors,
                    email: error.pattern ? "" : errorMsg(errorCode.FE03),
                  })
                }
                placeholder="Eg. example@email.com"
                errorText={errors.email}
                type="email"
                suffix={
                  GlobalStore.user.email === email &&
                  email !== "" &&
                  isEmailVerified &&
                  !showEmailOtp &&
                  !showEmailBtn ? (
                    <i className="edel-icon-tick" />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            {showEmailBtn && (
              <div className="emlVrfy">
                <Button
                  type="submit"
                  btnName="Verify Email"
                  isLoading={isLoading}
                  disabled={
                    Object.values([errors.email]).some((error) => error) ||
                    email === ""
                  }
                  onClick={() => sendOtp("email")}
                />
              </div>
            )}
          </div>
          {showEmailOtp && !showEmailBtn && (
            <div className="mgt40">
              <RegOtp
                label="Enter Email OTP"
                otpTimer={15}
                eleId="eml_"
                onChange={(val) => validateOtp("email", val)}
                resendClicked={() => sendOtp("email")}
                disableOtp={disableOtp}
                invalidOtp={invalidOtp}
              />
            </div>
          )} */}
          {/* Email End*/}

          {isEmailVerified && (
            <>
              {/* Family Declaration start */}
              {relationshipData.emailMatchedList &&
                relationshipData.mobileMatchedList &&
                (relationshipData.emailMatchedList.length > 0 ||
                  relationshipData.mobileMatchedList.length > 0) && (
                  <>
                    <div className="mgt80">
                      <label className="prflFormHeading">
                        Family Declaration
                      </label>
                    </div>

                    <div className="mgt20">
                      <label className="fmlyDecInfo">
                        Your mobile number and/or email ID matches with already
                        existing account. Mention your Relation with each
                        account or change your Email ID or Mobile Number
                      </label>
                    </div>
                    {relationshipData.emailMatchedList &&
                      relationshipData.emailMatchedList.map(
                        (item: IFamilyMember, index: number) => (
                          <>
                            <label className="AAA"></label>
                            <FamilyMember
                              key={index}
                              index={index}
                              type="email"
                              member={item}
                              relationList={familyRelationListMail}
                              originalRelationList={originalRelationList}
                              onChange={(value) =>
                                onRelationChange("email", index, value)
                              }
                              onBlur={(value) =>
                                onRelationBlur("email", index, value)
                              }
                              error={item?.error}
                            />
                          </>
                        )
                      )}
                    {relationshipData.mobileMatchedList &&
                      relationshipData.mobileMatchedList.map(
                        (item: IFamilyMember, index: number) => (
                          <FamilyMember
                            key={index}
                            index={index}
                            type="mobile"
                            member={item}
                            relationList={familyRelationList}
                            originalRelationList={originalRelationList}
                            onChange={(value) =>
                              onRelationChange("mobile", index, value)
                            }
                            onBlur={(value) =>
                              onRelationBlur("mobile", index, value)
                            }
                            error={item?.error}
                          />
                        )
                      )}
                    {relationshipData.bothMatchedList &&
                      relationshipData.bothMatchedList.map(
                        (item: IFamilyMember, index: number) => (
                          <FamilyMember
                            key={index}
                            index={index}
                            type="both"
                            member={item}
                            relationList={familyRelationList}
                            originalRelationList={originalRelationList}
                            onChange={(value) =>
                              onRelationChange("both", index, value)
                            }
                            onBlur={(value) =>
                              onRelationBlur("both", index, value)
                            }
                            error={item?.error}
                          />
                        )
                      )}
                  </>
                )}
              {/* Family Declaration end */}
              {/* Secondary Details Start */}
              <div className="mgt80">
                <label className="prflFormHeading">Secondary Details</label>
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Gender</label>
                <Tag
                  defaultValue={gender}
                  tagList={genderList}
                  onSelect={(val) => {
                    setGender(val as string);
                    setGenderError("");
                  }}
                  className="liMgt12"
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Marital Status</label>
                <Tag
                  defaultValue={maritalStatus}
                  tagList={maritalStatusList}
                  onSelect={(val) => setMaritalStatus(val as string)}
                  className="liMgt12"
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">
                  Father’s/Spouse’s Name (Select as per PAN)
                </label>
                <Tag
                  defaultValue={fatherOrSpouse}
                  tagList={fatherSpouseRelationList}
                  onSelect={(val) => setFatherOrSpouse(val as string)}
                  className="liMgt12"
                  bool={{
                    lhs: maritalStatus === "S" || gender !== "F",
                    rhs: fatherSpouseRelationList[1].value,
                    className: " not-allowed",
                  }}
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">
                  {fatherOrSpouse === "01" ? "Father" : "Spouse"}’s Name
                </label>
                <InputField
                  required
                  alphabate
                  defaultValue={fatherOrSpouseName}
                  disabled={
                    !regectedAddressDoc &&
                    GlobalStore.personaldetails.fsfullName &&
                    GlobalStore.personaldetails.fsfullName.trim()
                      ? true
                      : false
                  }
                  onBlur={(error) =>
                    setErrors({
                      ...errors,
                      // fatherOrSpouse: error.required ? `Please Enter Valid Name` : '',
                      fatherOrSpouse: error.required
                        ? errorMsg(errorCode.FE33)
                        : "",
                    })
                  }
                  onChange={(val) =>
                    setFatherOrSpouseName(capitalizeWords(val))
                  }
                  errorText={errors.fatherOrSpouse}
                  placeholder="Eg. Ankit Agrawal"
                  type="text"
                  helpText="Enter name as per PAN"
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Mother’s Name</label>
                <InputField
                  required
                  alphabate
                  defaultValue={motherName}
                  disabled={
                    GlobalStore.isKYC &&
                    GlobalStore.personaldetails.motherfullName &&
                    GlobalStore.personaldetails.motherfullName.trim()
                      ? true
                      : false
                  }
                  onBlur={(error) =>
                    setErrors({
                      ...errors,
                      // motherName: error.required ? 'Please Enter Mother’s Name' : '',
                      motherName: error.required
                        ? errorMsg(errorCode.FE34)
                        : "",
                    })
                  }
                  onChange={(val) => setMotherName(capitalizeWords(val))}
                  errorText={errors.motherName}
                  placeholder="Eg. Ankita Agrawal"
                  type="text"
                />
              </div>
              {/* Secondary Details End */}
              {/* Professional Details Start */}
              <div className="mgt80">
                <label className="prflFormHeading">Professional Details</label>
              </div>
              <div className="mgt40">
                <label className="fieldLbl">
                  Your Educational Qualification
                </label>
                <DropdownSearch
                  name="eduQualification"
                  defaultValue={
                    educationList.find(
                      (item) => item.value === eduQualification
                    )?.display
                  }
                  onOptionSelect={(val) =>
                    setEduQulification(val["value"] as string)
                  }
                  searchLength={0}
                  options={educationList}
                  onBlur={(val) => dropDownBlur("eduQualification", val)}
                  searchKey="display"
                  placeholder="Select Your Education Qualification"
                  noDataMsg="Qualification with similar name not found"
                  errorText={errors.eduQualification}
                  isDownArrow={true}
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Your Occupation</label>
                <DropdownSearch
                  name="occupation"
                  defaultValue={
                    occupationList.find((item) => item.value === occupation)
                      ?.display
                  }
                  onOptionSelect={(val) =>
                    setOccupation(val["value"] as string)
                  }
                  searchLength={0}
                  options={occupationList}
                  onBlur={(val) => dropDownBlur("occupation", val)}
                  searchKey="display"
                  placeholder="Select Your Occupation"
                  noDataMsg="Occupation with similar name not found"
                  errorText={errors.occupation}
                  isDownArrow={true}
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Politically Exposed Person</label>
                <div className="fieldLblRight">
                  <div className="explain">
                    <label
                      className="explainLbl"
                      onMouseOver={() => setShowExplain(true)}
                      onMouseLeave={() => setShowExplain(false)}
                    >
                      Explain
                    </label>
                    {showExplain && (
                      <InfoModal
                        width={DeviceContext().isMobile ? "328px" : "354px"}
                      >
                        <div className="explainInfo">
                          <label>
                            A Politically Exposed Person (PEP) is a person who
                            can potentially be influenced by political parties
                            directly or indirectly.
                          </label>
                        </div>
                      </InfoModal>
                    )}
                  </div>
                </div>
              </div>
              <div className="mgt20">
                <Radio
                  checked={politicalVal === "1"}
                  onChange={() => ChangePoliticalVal("1")}
                  name="politicalParty"
                  label="I am a Politically Exposed Person"
                  //disabled={true}
                />
                <Radio
                  className="mgt14"
                  checked={politicalVal === "2"}
                  onChange={() => ChangePoliticalVal("2")}
                  name="politicalParty"
                  label="I am Related to Politically Exposed Person"
                  //disabled={true}
                />
                <Radio
                  className="mgt14"
                  checked={politicalVal === "0"}
                  onChange={() => ChangePoliticalVal("0")}
                  name="politicalParty"
                  label="None"
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">{`I am an Indian citizen & pay taxes in India, I do not pay taxes in USA or any other country.`}</label>
                <div className="mgt14">
                  <div className="inlineDiv">
                    <Radio
                      checked={indianResident}
                      onChange={() => {
                        const {
                          country,
                          idTypeOther,
                          idTypeValue,
                          ...newErrors
                        } = errors;
                        setErrors(newErrors);
                        setIndianResident(true);
                      }}
                      name="taxResident"
                      label="Yes"
                    />
                  </div>
                  <div className="inlineDiv noRadio">
                    <Radio
                      checked={!indianResident}
                      onChange={() => setIndianResident(false)}
                      name="taxResident"
                      label="No"
                    />
                  </div>
                </div>
              </div>
              {!indianResident && (
                <NriDetailForm
                  countryList={countryList}
                  nriDetailsObj={nriDetailsObj}
                  onChangeNriDetails={setNriDetailsObj}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              <div className="mgt40">
                <label className="fieldLbl">Annual Income</label>
                <DropdownSearch
                  name="annualIncome"
                  defaultValue={
                    incomeList.find((item) => item.value === annualIncome)
                      ?.display
                  }
                  onOptionSelect={(val) =>
                    setAnnualIncome(val["value"] as string)
                  }
                  searchLength={0}
                  options={incomeList}
                  searchKey="display"
                  placeholder="Select Your Annual Income Bracket"
                  noDataMsg="Annual Income with similar name not found"
                  errorText={errors.annualIncome}
                  onBlur={(val) => dropDownBlur("annualIncome", val)}
                  isDownArrow={true}
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Net Worth</label>
                <InputField
                  integer
                  required
                  maxLength={9}
                  min={1001}
                  defaultValue={netWorth}
                  onBlur={(error) =>
                    setErrors({
                      ...errors,
                      netWorth:
                        error.required || error.min
                          ? // ? 'Please Enter Amount More Than Rs 1000'
                            errorMsg(errorCode.FE22)
                          : "",
                    })
                  }
                  errorText={errors.netWorth}
                  onChange={(val) => setNetWorth(val)}
                  placeholder="Eg. 30,00,000"
                  // helpText='Maximum 100 Cr.'
                />
              </div>
              <div className="mgt40">
                <label className="fieldLbl">Place of birth</label>
                <DropdownSearch
                  name="city"
                  onOptionSelect={(value) =>
                    onChange("city", value["CityName"] as string)
                  }
                  searchLength={3}
                  options={cities}
                  onBlur={handleCityBlur}
                  searchKey="CityName"
                  placeholder="Eg. Mumbai"
                  noDataMsg="City with similar name not found"
                  errorText={errors.placeOfBirth}
                  isDownArrow={false}
                  autoSelectOff={true}
                  defaultValue={placeOfBirth}
                />
                {/* <InputField
                                    required
                                    defaultValue={placeOfBirth}
                                    onBlur={(error) =>
                                        setErrors({
                                            ...errors,
                                            placeOfBirth: error.required ? 'Please Enter Place of Birth' : '',
                                        })
                                    }
                                    errorText={errors.placeOfBirth}
                                    onChange={(val) => setPlaceOfBirth(val)}
                                    placeholder="Eg. Mumbai, Maharashtra"
                                    type="text"
                                /> */}
              </div>
              <div className="mgt40 flexDiv">
                <div className="nomLbl">
                  <label className="fieldLbl">
                    How frequently would you like us to settle unused funds back
                    to your bank account?
                  </label>
                </div>
                <div className="nomToggle">
                  <Toggle
                    isOn={togSettlement}
                    onChange={() => togSettlementclick()}
                  />
                </div>
              </div>
              {togSettlement && (
                <div className="mgt30">
                  <div className="inlineDiv">
                    <Radio
                      checked={periodicSettlement === "1"}
                      onChange={() => setPeriodicSettlement("1")}
                      name="periodicSettlement"
                      label="Monthly"
                    />
                  </div>
                  <div className="inlineDiv noRadio">
                    <Radio
                      checked={periodicSettlement === "2"}
                      onChange={() => setPeriodicSettlement("2")}
                      name="periodicSettlement"
                      label="Quarterly"
                    />
                  </div>
                </div>
              )}
              <div className="mgt40 flexDiv">
                <div className="nomLbl">
                  <label className="fieldLbl">
                    Allow DDPI as an mode of operating Pledge and Payin
                    transaction
                  </label>
                  {/* <br></br>
                  <label className="sectionLbl">({POATypeMsg})</label> */}
                </div>
                <div className="nomToggle">
                  <Toggle
                    name="POAType"
                    isOn={POAType}
                    disabled={GlobalStore.stageStatus.dpaccounttype === 2}
                    onChange={() => {
                      if (POAType == true) {
                        setPOAType(false);
                      } else {
                        setPOAType(true);
                      }
                    }}
                  />
                </div>
              </div>
              {defaultSetting && (
                <>
                  <div className="termList mrt41">
                    <Checkbox
                      checked={POAType}
                      name="defaultSetting"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      Transfer of security is held in beneficial owner accounts
                      of client towards stock exchange related is settlement
                      obligations arising out of trade executed by client on the
                      stock exchange through stay schemes stock broker.
                    </label>
                  </div>
                  <div className="termList mrt41">
                    <Checkbox
                      checked={POAType}
                      name="defaultSetting"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      Pledging / replacing of security in favour of trading
                      member in (TM)/ clearing members (CM ) for the purpose of
                      meeting margin requirements of the clients in connection
                      with trades executed by clients on the stock exchange.
                    </label>
                  </div>
                  <div className="termList mrt41">
                    <Checkbox
                      checked={POAType}
                      name="defaultSetting"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      Mutual Fund transaction being executed on stock exchange
                      order entry platform.
                    </label>
                  </div>
                  <div className="termList mrt41">
                    <Checkbox
                      checked={POAType}
                      name="defaultSetting"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      Tendering shares in open offers through stock exchange
                      platforms.
                    </label>
                  </div>
                </>
              )}
              <h4
                onClick={() => setDefaultSetting(!defaultSetting)}
                className="defaultHeading"
              >
                Read {defaultSetting ? "Less" : "More"}
              </h4>
              <div className="mgt12">
                <label className="sectionLbl greyish-two">{POATypeMsg}</label>
              </div>
              <div className="mgt40">
                {/* Permanent Address Proof */}
                <label className="bankDoc"> Type of Account</label>
                <DropdownSearch
                  name="defaultAccountType"
                  defaultValue={
                    bankAccountType.find(
                      (item) => item.display === defaultAccountType
                    )?.display
                  }
                  onOptionSelect={(val) => setDefaultAccountType(val.display)}
                  searchLength={0}
                  options={bankAccountType}
                  onBlur={(val) =>
                    handleAccountTypeBlur("defaultAccountType", val)
                  }
                  errorText={errors.defaultAccountType}
                  searchKey="display"
                  placeholder="Select Account Type"
                  isDownArrow={true}
                />
                <h4> Additional default settings for Online Account </h4>
                <h4
                  onClick={() => setAccountTypeToggle(!accountTypeToggle)}
                  className="defaultHeading"
                >
                  {accountTypeToggle ? "Read Less" : "Read More"}
                </h4>
              </div>
              {accountTypeToggle && (
                <>
                  <label className="linkLabel">
                    I know that these services are for opening Ordinary
                    Individual Resident Demat and Trading Account
                  </label>
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      Mode of receiving demat account statement and annual
                      report: electronic
                    </label>
                  </div>
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      I am aware that all transaction cum holding statement
                      would be received on registered email ID.
                    </label>
                  </div>{" "}
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      Share the email ID with RTA
                    </label>
                  </div>{" "}
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      I agree to receive all my credits/dividend into my linked
                      bank account.
                    </label>
                  </div>{" "}
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      I confirm that I do not require DIS booklet at the time of
                      account opening.
                    </label>
                  </div>{" "}
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      I wish to availed the trust (Transaction using squared
                      testing) facility using mobile number registered for SMS
                      alert facility.
                    </label>
                  </div>{" "}
                  <div className="termList mrt41">
                    <Checkbox
                      checked={true}
                      name="accountType"
                      onChange={() => {}}
                    />
                    <label className="mrl13 linkLabel">
                      I hereby confirm that no action is taken / proceedings
                      initiated / pending including but not limited to debarment
                      / blacklisting / de-registering / name strike off from ROC
                      records / declaring defaulter / monetary penalty / adverse
                      arbitration award by SEBI / Stock exchange / Government
                      Authority / Statutory Authority / MCA or any other
                      authority against me for dealing in securities. If an
                      identical name match appears, it is not me. I authorised
                      NWIL to carry additional checks and take necessary actions
                      if identified to be true.
                    </label>
                  </div>
                </>
              )}
              {/* Professional Details End */}
              {!isSeniorCitizen && (
                <div className="mgt40 flexDiv">
                  <div className="nomLbl">
                    <label className="fieldLbl">
                      Would you like to add nominee details to your account?
                    </label>
                    <br></br>
                    <label className="sectionLbl">
                      (For senior citizen, nominee details are mandatory)
                    </label>
                  </div>
                  <div className="nomToggle">
                    <Toggle
                      name="showNominee"
                      disabled={isSeniorCitizen}
                      isOn={showNominee}
                      onChange={() => {
                        const {
                          guardAnnualIncome,
                          guardEduQualification,
                          guardFullName,
                          guardOccupation,
                          guardPlaceOfBirth,
                          guardRelation,
                          guardTitle,
                          nomFullName,
                          nomRelation,
                          nomTitle,
                          numOfNominee,
                          ...newErrors
                        } = errors;
                        const existingNominee = showNominee;
                        if (existingNominee === true) {
                          setNomiDetails([]);
                          setNumOfNominee(0);
                        }
                        setErrors(newErrors);
                        setShowNominee(!existingNominee);
                        setGlobalError("");
                      }}
                    />
                  </div>
                </div>
              )}
              {/* Below div is for temp purpose. We need to remove it once multiple nominee we implement it. */}
              {/* <div className="mgt40 tempNomineeSection">
                                <label className="fieldLbl">
                                    Currently We do not cater Online Nomination Request. In case you intend to opt for
                                    nominee, please download and fill the attached form. You can courier the same along
                                    with physical form.
                                </label>
                                <p className="fieldLbl downloadNomineeForm">
                                    <a href={`${config.root}docs/EBL-Combine-Nomination-Form.pdf`} target="_blank">
                                        Click here
                                    </a>{' '}
                                    to Download Add Nomination form
                                </p>
                            </div> */}
              {/* Nominee Details Start */}
              {showNominee && (
                <>
                  <div className="mgt40">
                    <label className="fieldLbl">
                      How many Nominee do you want?
                    </label>
                    <DropdownSearch
                      name="numOfNominee"
                      // defaultValue={'1'}
                      onOptionSelect={(val) => {
                        const totalNomineeCount = Number(val["value"]);
                        const existingNomineeCount = Number(numOfNominee);
                        const countForNeedToAddBlankNomine =
                          totalNomineeCount - existingNomineeCount;

                        const existingNomineeDetails = JSON.parse(
                          JSON.stringify(nomiDetails)
                        );

                        const nomineeEquaPerSharing = (
                          100 / totalNomineeCount
                        ).toFixed(2);
                        console.log(
                          "nomineeEquaPerSharing",
                          nomineeEquaPerSharing
                        );

                        const nomineeSharePer: any = {
                          1:
                            totalNomineeCount === 3
                              ? 33
                              : Number(nomineeEquaPerSharing),
                          2:
                            totalNomineeCount === 3
                              ? 33
                              : Number(nomineeEquaPerSharing),
                          3:
                            totalNomineeCount === 3
                              ? 34
                              : Number(nomineeEquaPerSharing),
                        };

                        console.log("nomineeSharePer", nomineeSharePer);

                        for (let i = 0; i < countForNeedToAddBlankNomine; i++) {
                          existingNomineeDetails.push({
                            error: {},
                            nomineeAddress: initialAddressObj,
                          });
                        }

                        for (let i = 0; i < totalNomineeCount; i++) {
                          existingNomineeDetails[i]["nomPerSharing"] =
                            nomineeSharePer[i + 1];
                        }

                        // If there are 3 nominee and user is going to remove last one and new select for 2 nominee then we need to remove last nominee
                        let numOfNomineeRemove =
                          existingNomineeCount - totalNomineeCount;
                        console.log(
                          "numOfNomineeRemove ---->",
                          numOfNomineeRemove
                        );
                        if (numOfNomineeRemove > 0) {
                          for (let i = 0; i < numOfNomineeRemove; i++) {
                            console.log("Inside the while loop", i);
                            existingNomineeDetails.pop();
                          }
                        }

                        setNomiDetails(existingNomineeDetails);
                        setNumOfNominee(val["value"] as number);
                      }}
                      defaultValue={
                        nomineeList.find((d) => d.value === numOfNominee)
                          ?.display
                      }
                      options={nomineeList}
                      onBlur={(val) => dropDownBlur("numOfNominee", val)}
                      errorText={errors.numOfNominee}
                      searchKey="display"
                      searchLength={0}
                      isDownArrow
                      placeholder="One"
                      disableTick
                    />
                  </div>
                  {numOfNominee > 0 &&
                    nomineeList.slice(0, numOfNominee).map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="mgt80">
                            <label className="prflFormHeading">
                              Nominee {index + 1} Details
                            </label>
                          </div>
                          <div className="mgt40">
                            <label className="fieldLbl">Full Name</label>
                            <div className="flexDiv">
                              <div className="titleSelect">
                                <DropdownSearch
                                  name="nomTitle"
                                  defaultValue={
                                    titlePrefixList.find(
                                      (item) =>
                                        item.value ===
                                        nomiDetails[index]?.nomTitle
                                    )?.display
                                  }
                                  onOptionSelect={(val) => {
                                    handleAddNomineeValue(
                                      "nomTitle",
                                      index,
                                      val["value"] as string
                                    );
                                  }}
                                  options={titlePrefixList}
                                  onBlur={(val) =>
                                    handleAddNomineeError(
                                      index,
                                      "nomTitle",
                                      typeof val !== "object"
                                        ? errorMsg(errorCode.FE20)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.nomTitle
                                  }
                                  searchKey="display"
                                  searchLength={0}
                                  isDownArrow
                                  placeholder="Mr."
                                  disableTick
                                />
                              </div>
                              <div className="nomName">
                                <InputField
                                  required
                                  alphabate
                                  onBlur={(error) =>
                                    handleAddNomineeError(
                                      index,
                                      "nomFullName",
                                      error.required
                                        ? // ? 'Please Enter Nominee Name'
                                          errorMsg(errorCode.FE35)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.nomFullName
                                  }
                                  defaultValue={nomiDetails[index]?.nomFullName}
                                  onChange={(val) => {
                                    console.log("inputs for name@@@", val);

                                    handleAddNomineeValue(
                                      "nomFullName",
                                      index,
                                      capitalizeWords(val)
                                    );
                                  }}
                                  type="text"
                                  placeholder="Eg. Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mgt40">
                            <label className="fieldLbl">Relation</label>
                            <DropdownSearch
                              name="nomRelation"
                              defaultValue={
                                relationshipList.find(
                                  (item) =>
                                    item.value ===
                                    nomiDetails[index]?.nomRelation
                                )?.display
                              }
                              onOptionSelect={(val) => {
                                handleAddNomineeValue(
                                  "nomRelation",
                                  index,
                                  val["value"] as string
                                );
                              }}
                              searchLength={0}
                              options={relationshipList}
                              onBlur={(val) =>
                                handleAddNomineeError(
                                  index,
                                  "nomRelation",
                                  typeof val !== "object"
                                    ? // ? 'Please select from dropdown'
                                      errorMsg(errorCode.FE24)
                                    : undefined
                                )
                              }
                              errorText={nomiDetails[index]?.error?.nomRelation}
                              searchKey="display"
                              placeholder="Eg. Father"
                              noDataMsg="Relation with similar name not found"
                              isDownArrow={true}
                            />
                          </div>
                          {nomRelation === "13" && (
                            <div className="mgt40">
                              <label className="fieldLbl">Other</label>
                              <InputField
                                required
                                onBlur={(error) =>
                                  handleAddNomineeError(
                                    index,
                                    "nomOtherRelation",
                                    error.required
                                      ? errorMsg(errorCode.FE37)
                                      : ""
                                  )
                                }
                                errorText={
                                  nomiDetails[index]?.error?.nomOtherRelation
                                }
                                onChange={(value) =>
                                  handleAddNomineeValue(
                                    "nomOtherRelation",
                                    index,
                                    value
                                  )
                                }
                                type="text"
                                placeholder="Eg. Niece"
                              />
                            </div>
                          )}
                          {/* Nominee Address Start */}
                          <AddressField
                            addressObj={nomiDetails[index]?.nomineeAddress}
                            isDisable={false}
                            sectionLabel="Nominee Addr."
                            addressType="nominee"
                            setErrCode={setErrCode}
                            onAddressChange={(value) =>
                              handleAddNomineeValue(
                                "nomineeAddress",
                                index,
                                value
                              )
                            }
                            onAddressBlur={setNomErrorObj}
                            handleSameAsAbove={() => {}}
                          />
                          {/* Nominee Address End */}

                          {/* Nominee sharing per start */}
                          <div className="mgt40">
                            <label className="fieldLbl">
                              Nominee % sharing
                            </label>
                            <InputField
                              integer
                              maxLength={3}
                              min={0}
                              max={100}
                              required
                              onBlur={(error) =>
                                handleAddNomineeError(
                                  index,
                                  "nomPerSharing",
                                  error.required || error.min || error.max
                                    ? // ? 'Please Enter percentage from 0 to 100'
                                      errorMsg(errorCode.FE26)
                                    : ""
                                )
                              }
                              errorText={
                                nomiDetails[index]?.error?.nomPerSharing
                                  ? nomiDetails[index]?.error?.nomPerSharing
                                  : nomiDetails[index]?.nomPerSharing == 0
                                  ? errorMsg(errorCode.FE26)
                                  : ""
                              }
                              onChange={(value) =>
                                handleAddNomineeValue(
                                  "nomPerSharing",
                                  index,
                                  value
                                )
                              }
                              type="text"
                              placeholder="100"
                              defaultValue={nomiDetails[index]?.nomPerSharing}
                            />
                          </div>
                          {/* Nominee sharing per end */}

                          {/* start nominee upload photo */}
                          <div className="mgt40">
                            <label className="fieldLbl">
                              Additional identification Details
                            </label>
                            <DropdownSearch
                              name="identificationDetail"
                              defaultValue={
                                nomineeIdentificationList.find(
                                  (item) =>
                                    item.display ===
                                    nomiDetails[index]?.identificationDetail
                                      ?.name
                                )?.display
                              }
                              onOptionSelect={
                                (val) =>
                                  handleAddNomineeValue(
                                    "identificationDetail",
                                    index,
                                    val as number
                                  )

                                // setNomIdentification(val['value'] as number)
                              }
                              searchLength={0}
                              options={nomineeIdentificationList}
                              onBlur={(val) =>
                                handleAddNomineeError(
                                  index,
                                  "identificationDetail",
                                  typeof val !== "object"
                                    ? // ? 'Please select from dropdown'
                                      errorMsg(errorCode.FE25)
                                    : undefined
                                )
                              }
                              errorText={
                                nomiDetails[index]?.error?.identificationDetail
                              }
                              searchKey="display"
                              placeholder="Eg. Adhar"
                              isDownArrow={true}
                            />
                          </div>

                          {/* {nomiDetails[index]?.identificationDetail?.name ===
                            "Photo and Signature of the Nominee/guardian" && (
                            <div className="mgt40">
                              <p>Photo</p>
                            
                              {!isEditMode && (
                                <DocumentBrowse
                                  id="nomPhotoId"
                                  docObj={nomPhotoId}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onPanDocUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index,
                                      false
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomPhotoId",
                                      removeDocObj
                                    )
                                  }
                                />
                              )}
                              {(nomineeDocumentsArray[index]?.photo
                                ?.uploadPer == 100 ||
                                nomineeDocumentsArray[index]?.photo
                                  ?.uploadPer == 0) &&
                                isEditMode && (
                                  <DocumentBrowse
                                    id="nomPhotoId"
                                    docObj={nomineeDocumentsArray[index].photo}
                                    onDocUploadComplete={(
                                      val,
                                      fileName,
                                      id,
                                      type
                                    ) =>
                                      onPanDocUploadComplete(
                                        val,
                                        fileName,
                                        id,
                                        type,
                                        index,
                                        false
                                      )
                                    }
                                    isRemove={(removeDocObj) =>
                                      handleRemoveDoc(
                                        index,
                                        "nomPhotoId",
                                        removeDocObj
                                      )
                                    }
                                  />
                                )}
                              <p>Signature</p>
                              {!isEditMode && (
                                <DocumentBrowse
                                  id="nomSignature"
                                  docObj={nomPhotoId}
                                  onDocUploadComplete={(
                                    val,
                                    fileName,
                                    id,
                                    type
                                  ) =>
                                    onPanDocUploadComplete(
                                      val,
                                      fileName,
                                      id,
                                      type,
                                      index,
                                      false
                                    )
                                  }
                                  isRemove={(removeDocObj) =>
                                    handleRemoveDoc(
                                      index,
                                      "nomSignature",
                                      removeDocObj
                                    )
                                  }
                                />
                              )}
                              {(nomineeDocumentsArray[index]?.signature
                                ?.uploadPer == 100 ||
                                nomineeDocumentsArray[index]?.signature
                                  ?.uploadPer == 0) &&
                                isEditMode && (
                                  <DocumentBrowse
                                    id="nomSignature"
                                    docObj={
                                      nomineeDocumentsArray[index].signature
                                    }
                                    onDocUploadComplete={(
                                      val,
                                      fileName,
                                      id,
                                      type
                                    ) =>
                                      onPanDocUploadComplete(
                                        val,
                                        fileName,
                                        id,
                                        type,
                                        index,
                                        false
                                      )
                                    }
                                    isRemove={(removeDocObj) =>
                                      handleRemoveDoc(
                                        index,
                                        "nomSignature",
                                        removeDocObj
                                      )
                                    }
                                  />
                                )}
                            </div>
                          )} */}

                          {nomiDetails[index]?.identificationDetail?.name ===
                            "Aadhar Card no" && (
                            <div className="mgt40">
                              <label className="fieldLbl">
                                Enter the last four digit of Adhar Number
                              </label>
                              <InputField
                                integer
                                required
                                maxLength={4}
                                pattern={/^\d{4}$/}
                                type="text"
                                placeholder="Eg. 1234"
                                onBlur={(error) =>
                                  handleAddNomineeError(
                                    index,
                                    "identificationDetailValue",
                                    error.required ||
                                      nomiDetails[index]?.identificationDetail
                                        ?.value?.length < 4
                                      ? // ? 'Please Enter last four digit of Adhar number'
                                        errorMsg(errorCode.FE25)
                                      : undefined
                                  )
                                }
                                errorText={
                                  nomiDetails[index]?.error
                                    ?.identificationDetailValue
                                }
                                defaultValue={
                                  nomiDetails[index]?.identificationDetail
                                    ?.value
                                }
                                onChange={(val) =>
                                  handleAddNomineeValue(
                                    "identificationDetailValue",
                                    index,
                                    val
                                  )
                                }
                              />
                            </div>
                          )}
                          {nomiDetails[index]?.identificationDetail?.name ===
                            "Saving Bank account no." && (
                            <div className="mgt40">
                              <label className="fieldLbl">
                                Enter the Bank account Number
                              </label>
                              <InputField
                                integer
                                required
                                type="text"
                                maxLength={18}
                                onBlur={(error) =>
                                  handleAddNomineeError(
                                    index,
                                    "identificationDetailValue",
                                    error.required ||
                                      nomiDetails[index]?.identificationDetail
                                        ?.value?.length < 8
                                      ? // ? 'Please enter minimum 8 or maximum 18'
                                        errorMsg(errorCode.FE25)
                                      : ""
                                  )
                                }
                                errorText={
                                  nomiDetails[index]?.error
                                    ?.identificationDetailValue
                                }
                                defaultValue={
                                  nomiDetails[index]?.identificationDetail
                                    ?.value
                                }
                                onChange={(val) =>
                                  handleAddNomineeValue(
                                    "identificationDetailValue",
                                    index,
                                    val
                                  )
                                }
                                placeholder="Eg. 18273946573849"
                              />
                            </div>
                          )}
                          {nomiDetails[index]?.identificationDetail?.name ===
                            "Demat Account ID" && (
                            <div className="mgt40">
                              <label className="fieldLbl">
                                Enter the demat account Number
                              </label>
                              <InputField
                                integer
                                required
                                type="text"
                                maxLength={18}
                                onBlur={(error) =>
                                  handleAddNomineeError(
                                    index,
                                    "identificationDetailValue",
                                    error.required ||
                                      nomiDetails[index]?.identificationDetail
                                        ?.value?.length < 8
                                      ? // ? 'Please enter minimum 8 or maximum 18'
                                        errorMsg(errorCode.FE25)
                                      : ""
                                  )
                                }
                                errorText={
                                  nomiDetails[index]?.error
                                    ?.identificationDetailValue
                                }
                                defaultValue={
                                  nomiDetails[index]?.identificationDetail
                                    ?.value
                                }
                                onChange={(val) =>
                                  handleAddNomineeValue(
                                    "identificationDetailValue",
                                    index,
                                    val
                                  )
                                }
                                placeholder="Eg. 18273946573849"
                              />
                            </div>
                          )}
                          {nomiDetails[index]?.identificationDetail?.name ===
                            "PAN Card no" && (
                            <div className="mgt40">
                              <label className="fieldLbl">Enter PAN</label>
                              <InputField
                                className="nomPanNum"
                                required
                                pattern={pan}
                                maxLength={10}
                                suffix={
                                  validPan[index] ? (
                                    <i className="edel-icon-tick" />
                                  ) : (
                                    <></>
                                  )
                                }
                                defaultValue={
                                  nomiDetails[index]?.identificationDetail
                                    ?.value
                                }
                                onChange={(val) =>
                                  handleAddNomineeValue(
                                    "identificationDetailValue",
                                    index,
                                    val.toUpperCase()
                                  )
                                }
                                onBlur={(error) =>
                                  onBlurPan(
                                    error,
                                    index,
                                    "identificationDetailValue"
                                  )
                                }
                                type="text"
                                placeholder="Eg. ABCDE1234F"
                                errorText={
                                  nomiDetails[index]?.error
                                    ?.identificationDetailValue
                                }
                              />
                            </div>
                          )}
                          {/* nominee upload photo end */}

                          <div className="mgt40">
                            <label className="fieldLbl">
                              Nominee Date Of Birth
                            </label>
                            {/* DOB start */}

                            <Calender
                              onDateSelect={(date) => {
                                console.log("on select called", date);
                                handleAddNomineeValue("nomDob", index, date);
                              }}
                              defaultDate={
                                nomiDetails[index]?.nomDob
                                  ? new Date(nomiDetails[index]?.nomDob)
                                  : undefined
                              }
                              placeholderText={"Select Date of Birth"}
                              maxDate={new Date()}
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 100
                                  )
                                )
                              }
                              onBlur={(e) => {
                                console.log("on blur called", e);
                                handleAddNomineeError(
                                  index,
                                  "nomDob",
                                  errorMsg(errorCode.FE27)
                                );
                              }}
                              isError={
                                nomiDetails[index]?.error?.nomDob ? true : false
                              }
                            />
                            {nomiDetails[index]?.error?.nomDob && (
                              <label className="errorMsg">
                                {nomiDetails[index]?.error?.nomDob}
                              </label>
                            )}
                            {/* DOB end */}
                          </div>

                          {/* Guardian Details Start */}
                          {nomiDetails[index]?.isNomineeMinor && (
                            <>
                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian Full Name
                                </label>
                                <div className="flexDiv">
                                  <div className="titleSelect">
                                    <DropdownSearch
                                      name="guardTitle"
                                      defaultValue={
                                        titlePrefixList.find(
                                          (item) =>
                                            item.value ===
                                            nomiDetails[index][
                                              "guardianDetails"
                                            ]?.guardTitle
                                        )?.display
                                      }
                                      onOptionSelect={(val) =>
                                        handleAddNomineeGuardianValue(
                                          "guardTitle",
                                          index,
                                          val["value"] as string
                                        )
                                      }
                                      options={titlePrefixList}
                                      onBlur={(val) =>
                                        handleAddNomineeError(
                                          index,
                                          "guardTitle",
                                          typeof val !== "object"
                                            ? errorMsg(errorCode.FE20)
                                            : undefined
                                        )
                                      }
                                      errorText={
                                        nomiDetails[index]?.error?.guardTitle
                                      }
                                      searchKey="display"
                                      searchLength={0}
                                      isDownArrow
                                      placeholder="Mr."
                                      disableTick
                                    />
                                  </div>
                                  <div className="nomName">
                                    <InputField
                                      required
                                      alphabate
                                      onBlur={(error) =>
                                        handleAddNomineeError(
                                          index,
                                          "guardFullName",
                                          error.required
                                            ? // ? 'Please Enter Guardian Name'
                                              errorMsg(errorCode.FE36)
                                            : ""
                                        )
                                      }
                                      errorText={
                                        nomiDetails[index]?.error?.guardFullName
                                      }
                                      defaultValue={
                                        nomiDetails[index]?.guardianDetails
                                          ?.guardFullName
                                      }
                                      onChange={(val) =>
                                        handleAddNomineeGuardianValue(
                                          "guardFullName",
                                          index,
                                          capitalizeWords(val)
                                        )
                                      }
                                      type="text"
                                      placeholder="Eg. Rajesh Agarwal"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian’s Relation
                                </label>
                                <DropdownSearch
                                  name="guardRelation"
                                  defaultValue={
                                    relationshipList.find(
                                      (item) =>
                                        item.value ===
                                        nomiDetails[index]["guardianDetails"]
                                          ?.guardRelation
                                    )?.display
                                  }
                                  onOptionSelect={(val) =>
                                    // setGuardRelation(val['value'] as string)
                                    handleAddNomineeGuardianValue(
                                      "guardRelation",
                                      index,
                                      val["value"] as string
                                    )
                                  }
                                  searchLength={0}
                                  options={relationshipList}
                                  onBlur={(val) =>
                                    // dropDownBlur('guardRelation', val)
                                    handleAddNomineeError(
                                      index,
                                      "guardRelation",
                                      typeof val !== "object"
                                        ? // ? 'Please select from dropdown'
                                          errorMsg(errorCode.FE24)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.guardRelation
                                  }
                                  searchKey="display"
                                  placeholder="Eg. Father"
                                  noDataMsg="Relation with similar name not found"
                                  isDownArrow={true}
                                />
                              </div>
                              {guardRelation === "13" && (
                                <div className="mgt40">
                                  <label className="fieldLbl">Other</label>
                                  <InputField
                                    required
                                    onBlur={(error) =>
                                      // setErrors({
                                      //     ...errors,
                                      //     guardOtherRelation: error.required
                                      //         ? 'Please Enter Relation'
                                      //         : '',
                                      // })
                                      handleAddNomineeError(
                                        index,
                                        "guardOtherRelation",
                                        error.required
                                          ? errorMsg(errorCode.FE42)
                                          : ""
                                      )
                                    }
                                    errorText={
                                      nomiDetails[index]?.error
                                        ?.guardOtherRelation
                                    }
                                    onChange={(val) =>
                                      handleAddNomineeGuardianValue(
                                        "guardOtherRelation",
                                        index,
                                        val
                                      )
                                    }
                                    type="text"
                                    placeholder="Eg. Niece"
                                  />
                                </div>
                              )}
                              {/* Guardian’s Address Start */}
                              <AddressField
                                addressObj={
                                  nomiDetails[index]?.guardianDetails
                                    ?.guardianAddress
                                }
                                isDisable={false}
                                sectionLabel="Guardian Addr."
                                addressType="guardian"
                                setErrCode={setErrCode}
                                onAddressChange={(val) =>
                                  handleAddNomineeGuardianValue(
                                    "guardianAddress",
                                    index,
                                    val
                                  )
                                }
                                onAddressBlur={setGuardErrorObj}
                                showSameAsAbove
                                sameAsAbove={
                                  nomiDetails[index]?.guardianDetails
                                    ?.sameAsNominee
                                }
                                handleSameAsAbove={(event) =>
                                  handleSameAsAboveForGaurdian(event, index)
                                }
                              />
                              {/* Guardian’s Address End */}

                              {/* start nominee upload photo */}
                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Additional identification Details
                                </label>
                                <DropdownSearch
                                  name="guardidentificationDetail"
                                  defaultValue={
                                    nomineeIdentificationList.find(
                                      (item) =>
                                        item.display ===
                                        nomiDetails[index]["guardianDetails"]
                                          ?.identificationDetail?.name
                                    )?.display
                                  }
                                  onOptionSelect={(val) =>
                                    handleAddNomineeGuardianValue(
                                      "identificationDetail",
                                      index,
                                      val as number
                                    )
                                  }
                                  searchLength={0}
                                  options={nomineeIdentificationList}
                                  onBlur={(val) =>
                                    handleAddNomineeError(
                                      index,
                                      "guardidentificationDetail",
                                      typeof val !== "object"
                                        ? errorMsg(errorCode.FE20)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error
                                      ?.guardidentificationDetail
                                  }
                                  searchKey="display"
                                  placeholder="Eg. Adhar"
                                  isDownArrow={true}
                                />
                              </div>
                              {/* {nomiDetails[index]["guardianDetails"]
                                ?.identificationDetail?.name ===
                                "Photo and Signature of the Nominee/guardian" && (
                                <div className="mgt40">
                                  <p>Photo</p>
                                  {!isEditMode && (
                                    <DocumentBrowse
                                      id="guardianPhotoId"
                                      docObj={nomPhotoId}
                                      onDocUploadComplete={(
                                        val,
                                        fileName,
                                        id,
                                        type
                                      ) =>
                                        onPanDocUploadComplete(
                                          val,
                                          fileName,
                                          id,
                                          type,
                                          index,
                                          true
                                        )
                                      }
                                      isRemove={(removeDocObj) =>
                                        handleRemoveDoc(
                                          index,
                                          "guardianPhotoId",
                                          removeDocObj
                                        )
                                      }
                                    />
                                  )}{" "}
                                  {(nomineeDocumentsArray[index]
                                    ?.guardianDetails?.photo?.uploadPer ==
                                    100 ||
                                    nomineeDocumentsArray[index]
                                      ?.guardianDetails?.photo?.uploadPer ==
                                      0) &&
                                    isEditMode && (
                                      <DocumentBrowse
                                        id="guardianPhotoId"
                                        docObj={
                                          nomineeDocumentsArray[index]
                                            ?.guardianDetails?.photo
                                        }
                                        onDocUploadComplete={(
                                          val,
                                          fileName,
                                          id,
                                          type
                                        ) =>
                                          onPanDocUploadComplete(
                                            val,
                                            fileName,
                                            id,
                                            type,
                                            index,
                                            true
                                          )
                                        }
                                        isRemove={(removeDocObj) =>
                                          handleRemoveDoc(
                                            index,
                                            "guardianPhotoId",
                                            removeDocObj
                                          )
                                        }
                                      />
                                    )}
                                  <p>Signature</p>
                                  {!isEditMode && (
                                    <DocumentBrowse
                                      id="guardianSignature"
                                      docObj={nomPhotoId}
                                      onDocUploadComplete={(
                                        val,
                                        fileName,
                                        id,
                                        type
                                      ) =>
                                        onPanDocUploadComplete(
                                          val,
                                          fileName,
                                          id,
                                          type,
                                          index,
                                          true
                                        )
                                      }
                                      isRemove={(removeDocObj) =>
                                        handleRemoveDoc(
                                          index,
                                          "guardianSignature",
                                          removeDocObj
                                        )
                                      }
                                    />
                                  )}{" "}
                                  {(nomineeDocumentsArray[index]
                                    ?.guardianDetails?.signature?.uploadPer ==
                                    100 ||
                                    nomineeDocumentsArray[index]
                                      ?.guardianDetails?.signature?.uploadPer ==
                                      0) &&
                                    isEditMode && (
                                      <DocumentBrowse
                                        id="guardianSignature"
                                        docObj={
                                          nomineeDocumentsArray[index]
                                            ?.guardianDetails?.signature
                                        }
                                        onDocUploadComplete={(
                                          val,
                                          fileName,
                                          id,
                                          type
                                        ) =>
                                          onPanDocUploadComplete(
                                            val,
                                            fileName,
                                            id,
                                            type,
                                            index,
                                            true
                                          )
                                        }
                                        isRemove={(removeDocObj) =>
                                          handleRemoveDoc(
                                            index,
                                            "guardianSignature",
                                            removeDocObj
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              )} */}
                              {nomiDetails[index]["guardianDetails"]
                                ?.identificationDetail?.name ===
                                "Aadhar Card no" && (
                                <div className="mgt40">
                                  <label className="fieldLbl">
                                    Enter the last four digit of Adhar Number
                                  </label>
                                  <InputField
                                    integer
                                    maxLength={4}
                                    pattern={/^\d{4}$/}
                                    type="text"
                                    placeholder="Eg. 1234"
                                    onBlur={(val) =>
                                      handleAddNomineeError(
                                        index,
                                        "guardIdentificationDetailValue",
                                        !val.pattern
                                          ? errorMsg(errorCode.FE41)
                                          : undefined
                                      )
                                    }
                                    errorText={
                                      nomiDetails[index]?.error
                                        ?.guardIdentificationDetailValue
                                    }
                                    defaultValue={
                                      nomiDetails[index]["guardianDetails"]
                                        ?.identificationDetail?.value
                                    }
                                    onChange={(val) =>
                                      handleAddNomineeGuardianValue(
                                        "guardIdentificationDetailValue",
                                        index,
                                        val
                                      )
                                    }
                                  />
                                </div>
                              )}
                              {nomiDetails[index]["guardianDetails"]
                                ?.identificationDetail?.name ===
                                "Saving Bank account no." && (
                                <div className="mgt40">
                                  <label className="fieldLbl">
                                    Enter the Bank account Number
                                  </label>
                                  <InputField
                                    integer
                                    required
                                    type="text"
                                    maxLength={18}
                                    onBlur={(error) =>
                                      setErrors({
                                        ...errors,
                                        guardIdentificationDetailValue:
                                          error.required ||
                                          nomiDetails[index]["guardianDetails"]
                                            ?.identificationDetail?.value
                                            ?.length < 8
                                            ? errorMsg(errorCode.FE40)
                                            : "",
                                      })
                                    }
                                    errorText={
                                      errors.guardIdentificationDetailValue
                                    }
                                    defaultValue={
                                      nomiDetails[index]["guardianDetails"]
                                        ?.identificationDetail?.value
                                    }
                                    onChange={(val) =>
                                      handleAddNomineeGuardianValue(
                                        "guardIdentificationDetailValue",
                                        index,
                                        val
                                      )
                                    }
                                    placeholder="Eg. 18273946573849"
                                  />
                                </div>
                              )}
                              {nomiDetails[index]["guardianDetails"]
                                ?.identificationDetail?.name ===
                                "Demat Account ID" && (
                                <div className="mgt40">
                                  <label className="fieldLbl">
                                    Enter the demat account Number
                                  </label>
                                  <InputField
                                    integer
                                    required
                                    type="text"
                                    maxLength={18}
                                    onBlur={(error) =>
                                      setErrors({
                                        ...errors,
                                        guardIdentificationDetailValue:
                                          error.required ||
                                          nomiDetails[index]
                                            ?.identificationDetail?.value
                                            ?.length < 8
                                            ? errorMsg(errorCode.FE40)
                                            : "",
                                      })
                                    }
                                    errorText={
                                      errors.guardIdentificationDetailValue
                                    }
                                    defaultValue={
                                      nomiDetails[index]["guardianDetails"]
                                        ?.identificationDetail?.value
                                    }
                                    onChange={(val) =>
                                      handleAddNomineeGuardianValue(
                                        "guardIdentificationDetailValue",
                                        index,
                                        val
                                      )
                                    }
                                    placeholder="Eg. 18273946573849"
                                  />
                                </div>
                              )}
                              {nomiDetails[index]["guardianDetails"]
                                ?.identificationDetail?.name ===
                                "PAN Card no" && (
                                <div className="mgt40">
                                  <label className="fieldLbl">Enter PAN</label>
                                  <InputField
                                    className="nomPanNum"
                                    required
                                    pattern={pan}
                                    maxLength={10}
                                    suffix={
                                      validPan[index] ? (
                                        <i className="edel-icon-tick" />
                                      ) : (
                                        <></>
                                      )
                                    }
                                    defaultValue={
                                      nomiDetails[index]["guardianDetails"]
                                        ?.identificationDetail?.value
                                    }
                                    onChange={(val) =>
                                      handleAddNomineeGuardianValue(
                                        "guardIdentificationDetailValue",
                                        index,
                                        val.toUpperCase()
                                      )
                                    }
                                    onBlur={(error) =>
                                      onBlurPan(
                                        error,
                                        index,
                                        "guardIdentificationDetailValue"
                                      )
                                    }
                                    type="text"
                                    placeholder="Eg. ABCDE1234F"
                                    errorText={
                                      nomiDetails[index]?.error
                                        ?.guardIdentificationDetailValue
                                    }
                                  />
                                </div>
                              )}
                              {/* nominee upload photo end */}

                              {/* Guardian Professional Details Start */}
                              <div className="mgt80">
                                <label className="prflFormHeading">
                                  Guardian’s Professional Details
                                </label>
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian’s Educational Qualification
                                </label>
                                <DropdownSearch
                                  name="guardEduQualification"
                                  defaultValue={
                                    educationList.find(
                                      (item) =>
                                        item.value ===
                                        nomiDetails[index]["guardianDetails"]
                                          ?.guardEduQualification
                                    )?.display
                                  }
                                  onOptionSelect={(val) =>
                                    // setGuardEduQulification(val['value'] as string)
                                    handleAddNomineeGuardianValue(
                                      "guardEduQualification",
                                      index,
                                      val["value"] as string
                                    )
                                  }
                                  searchLength={0}
                                  options={educationList}
                                  onBlur={(val) =>
                                    // dropDownBlur('guardRelation', val)
                                    handleAddNomineeError(
                                      index,
                                      "guardEduQualification",
                                      typeof val !== "object"
                                        ? errorMsg(errorCode.FE20)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error
                                      ?.guardEduQualification
                                  }
                                  searchKey="display"
                                  placeholder="Select Guardian’s Education Qualification"
                                  noDataMsg="Qualification with similar name not found"
                                  isDownArrow={true}
                                />
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian’s Occupation
                                </label>
                                <DropdownSearch
                                  name="guardOccupation"
                                  defaultValue={
                                    occupationList.find(
                                      (item) =>
                                        item.value ===
                                        nomiDetails[index]["guardianDetails"]
                                          ?.guardOccupation
                                    )?.display
                                  }
                                  onOptionSelect={(val) =>
                                    // setGuardOccupation(val['value'] as string)
                                    handleAddNomineeGuardianValue(
                                      "guardOccupation",
                                      index,
                                      val["value"] as string
                                    )
                                  }
                                  searchLength={0}
                                  options={occupationList}
                                  onBlur={(val) =>
                                    // dropDownBlur('guardRelation', val)
                                    handleAddNomineeError(
                                      index,
                                      "guardOccupation",
                                      typeof val !== "object"
                                        ? errorMsg(errorCode.FE20)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.guardOccupation
                                  }
                                  searchKey="display"
                                  placeholder="Select Guardian’s Occupation"
                                  noDataMsg="Occupation with similar name not found"
                                  isDownArrow={true}
                                />
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Politically Exposed Person
                                </label>
                                <div className="fieldLblRight">
                                  <div className="explain">
                                    <label
                                      className="explainLbl"
                                      onMouseOver={() => setShowExplain(true)}
                                      onMouseLeave={() => setShowExplain(false)}
                                    >
                                      Explain
                                    </label>
                                    {showExplain && (
                                      <InfoModal
                                        width={
                                          DeviceContext().isMobile
                                            ? "328px"
                                            : "354px"
                                        }
                                      >
                                        <div className="explainInfo">
                                          <label>
                                            A Politically Exposed Person or PEP
                                            is someone who is either involved
                                            with or closely associated to any
                                            prominent public function or are
                                            involved with political parties or
                                            entities related to political
                                            parties.
                                          </label>
                                        </div>
                                      </InfoModal>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="mgt20">
                                <Radio
                                  checked={
                                    nomiDetails[index]["guardianDetails"]
                                      ?.guardPoliticalVal === "1"
                                  }
                                  onChange={() =>
                                    // setGuardPoliticalVal('1')
                                    handleAddNomineeGuardianValue(
                                      "guardPoliticalVal",
                                      index,
                                      "1"
                                    )
                                  }
                                  name={"guardPoliticalParty" + index}
                                  label="I am a Politically Exposed Person"
                                />
                                <Radio
                                  className="mgt14"
                                  checked={
                                    nomiDetails[index]["guardianDetails"]
                                      ?.guardPoliticalVal === "2"
                                  }
                                  onChange={() =>
                                    // setGuardPoliticalVal('2')
                                    handleAddNomineeGuardianValue(
                                      "guardPoliticalVal",
                                      index,
                                      "2"
                                    )
                                  }
                                  name={"guardPoliticalParty" + index}
                                  label="I am Related to Politically Exposed Person"
                                />
                                <Radio
                                  className="mgt14"
                                  checked={
                                    nomiDetails[index]["guardianDetails"]
                                      ?.guardPoliticalVal === "0"
                                  }
                                  onChange={() =>
                                    //  setGuardPoliticalVal('0')
                                    handleAddNomineeGuardianValue(
                                      "guardPoliticalVal",
                                      index,
                                      "0"
                                    )
                                  }
                                  name={"guardPoliticalParty" + index}
                                  label="None"
                                />
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian’s Annual Income
                                </label>
                                <DropdownSearch
                                  name="guardAnnualIncome"
                                  defaultValue={
                                    incomeList.find(
                                      (item) =>
                                        item.value ===
                                        nomiDetails[index]["guardianDetails"]
                                          ?.guardAnnualIncome
                                    )?.display
                                  }
                                  onOptionSelect={(val) =>
                                    handleAddNomineeGuardianValue(
                                      "guardAnnualIncome",
                                      index,
                                      val["value"] as string
                                    )
                                  }
                                  onBlur={(val) =>
                                    handleAddNomineeError(
                                      index,
                                      "guardAnnualIncome",
                                      typeof val !== "object"
                                        ? errorMsg(errorCode.FE20)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.guardAnnualIncome
                                  }
                                  searchLength={0}
                                  options={incomeList}
                                  searchKey="display"
                                  placeholder="Select Guardian’s Annual Income Bracket"
                                  noDataMsg="Annual Income with similar name not found"
                                  isDownArrow={true}
                                />
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian’s Net Worth
                                </label>
                                <InputField
                                  integer
                                  required
                                  maxLength={9}
                                  min={1001}
                                  defaultValue={
                                    nomiDetails[index]["guardianDetails"]
                                      ?.guardNetWorth
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.guardNetWorth
                                  }
                                  onChange={(val) =>
                                    handleAddNomineeGuardianValue(
                                      "guardNetWorth",
                                      index,
                                      val
                                    )
                                  }
                                  onBlur={(error) =>
                                    handleAddNomineeError(
                                      index,
                                      "guardNetWorth",
                                      error.required || error.min
                                        ? errorMsg(errorCode.FE28)
                                        : ""
                                    )
                                  }
                                  placeholder="Eg. 30,00,000"
                                />
                              </div>

                              <div className="mgt40">
                                <label className="fieldLbl">
                                  Guardian’s Place of birth
                                </label>
                                <DropdownSearch
                                  name="city"
                                  onOptionSelect={(val) =>
                                    handleAddNomineeGuardianValue(
                                      "guardPlaceOfBirth",
                                      index,
                                      val["CityName"] as string
                                    )
                                  }
                                  searchLength={3}
                                  options={cities}
                                  // onBlur={handleCityBlur}
                                  onBlur={(value) =>
                                    handleAddNomineeError(
                                      index,
                                      "guardPlaceOfBirth",
                                      typeof value !== "object"
                                        ? errorMsg(errorCode.FE29)
                                        : undefined
                                    )
                                  }
                                  errorText={
                                    nomiDetails[index]?.error?.guardPlaceOfBirth
                                  }
                                  searchKey="CityName"
                                  placeholder="Eg. Mumbai"
                                  noDataMsg="City with similar name not found"
                                  // errorText={errors.placeOfBirth}
                                  isDownArrow={false}
                                  autoSelectOff={true}
                                  defaultValue={
                                    nomiDetails[index]["guardianDetails"]
                                      ?.guardPlaceOfBirth
                                  }
                                />
                                {/* <InputField
                                                                    required
                                                                    defaultValue={guardPlaceOfBirth}
                                                                    onChange={(val) =>
                                                                        handleAddNomineeGuardianValue(
                                                                            'guardPlaceOfBirth',
                                                                            index,
                                                                            val
                                                                        )
                                                                    }
                                                                    onBlur={(error) =>
                                                                        handleAddNomineeError(
                                                                            index,
                                                                            'guardPlaceOfBirth',
                                                                            error.required
                                                                                ? 'Please Enter Place of Birth'
                                                                                : ''
                                                                        )
                                                                    }
                                                                    errorText={
                                                                        nomiDetails[index]?.error?.guardPlaceOfBirth
                                                                    }
                                                                    placeholder="Eg. Mumbai, Maharashtra"
                                                                    type="text"
                                                                /> */}
                              </div>
                              {/*Guardian Professional Details End */}
                            </>
                          )}
                          {/* Guardian Details End */}
                        </React.Fragment>
                      );
                    })}
                </>
              )}
              <br></br>
              {/* Nominee Details End */}
              {/* Error Msg Start*/}
              {errCode && politicalVal == "0" && (
                <div className="errMsgBlck">
                  <ErrMsg>
                    <label>We are facing some technical issue.</label>
                    <label>
                      Please try after sometime (error code:{errCode})
                    </label>
                  </ErrMsg>
                </div>
              )}
              {errCode && politicalVal != "0" && (
                <div className="errMsgBlck">
                  <ErrMsg>
                    <label>{errCode}</label>
                  </ErrMsg>
                </div>
              )}
              {globalError && (
                <div className="errMsgBlck">
                  <ErrMsg>{globalError}</ErrMsg>
                </div>
              )}
              {errorMatchDetails && (
                <div className="errMsgBlck">
                  <ErrMsg>{errorMatchDetails}</ErrMsg>
                </div>
              )}
              {genderError && (
                <div className="errMsgBlck">
                  <ErrMsg>{genderError}</ErrMsg>
                </div>
              )}
              {SettlementError && (
                <div className="errMsgBlck">
                  <ErrMsg>{SettlementError}</ErrMsg>
                </div>
              )}
              {/* Error Msg End*/}
              <div className="mgt40">
                <Button
                  type="submit"
                  btnName="Submit"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  disabled={
                    checkESignSubmitted(GlobalStore.applicationID) ||
                    !togSettlement ||
                    (errCode && politicalVal != "0") ||
                    validateSubmit() ||
                    SettlementError ||
                    errorMatchDetails ||
                    genderError !== ""
                  }
                />
              </div>
            </>
          )}
        </fieldset>
      </form>
    </div>
  );
};

export default ProfileForm;
