import React from 'react'
import ProfileImage from '../ProfileImage/ProfileImage'
import './ErrorScreen.scss'


const ErrorScreen = (props: any) => (
    <div className='err'>
        <div className='errLogo'>
            <ProfileImage color='rgba(255, 10, 10, 0.1)' />
        </div>
        <p className='errHeading'>An Error Occured</p>
        <p className='errInfo'>Sorry! We are facing technical issues.</p>
        <p className='errLink'>Try Again</p>
    </div>
)

export default ErrorScreen