import React from "react";
import "./Loader.scss";

export interface IPennyDropLoaderProps {
  color?: "grey" | "blue";
  size?: 18 | 20 | 100;
}

const PennyDropLoader: React.FC<IPennyDropLoaderProps> = (
  props: IPennyDropLoaderProps
) => {
  const color = props.color || "grey";
  return (
    <div className="Loading-animation">
      <div className="trail"></div>
      <div className="icon"></div>
    </div>
  );
};

export default PennyDropLoader;
