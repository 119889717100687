import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";

export const ACheckClientProfilingData = (
  callback: CallableFunction,
  appID: string,
  sourceId: string
) => {
  apiService(
    {
      url: "DIYAPI/GetClientProfilingQuestion",
      body: {
        ClientId: appID,
        SourceId: sourceId,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getClientProfilingDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/check-client-profiling-data",
  //     body: {
  //       clientId: appID,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetClientProfilingQuestion = (
  callback: CallableFunction,
  appId: string,
  sourceId: string
) => {
  // apiService(
  //   {
  //     url: "eco/get-client-profiling-question",
  //     method: "GET",
  //   },
  //   callback
  // );
  apiService(
    {
      url: "DIYAPI/GetClientProfilingQuestion",
      body: {
        ClientId: appId,
        SourceId: sourceId,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getClientProfilingQuestion,
    },
    callback
  );
};

export const ASaveClientProfilingQuestion = (
  callback: CallableFunction,
  appId: string,
  sourceId: string,
  saveQuestion: Array<object>
) => {
  apiService(
    {
      url: "DIYAPI/SaveClientProfilingQuestion",
      body: {
        ClientId: appId,
        SourceId: sourceId,
        ListQuestionDetails: saveQuestion,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveClientProfilingQuestion,
    },
    callback
  );

  // apiService(
  //   {
  //     url: "eco/save-client-profiling-question",
  //     body: saveQuestion,
  //     method: "POST",
  //   },
  //   callback
  // );
};
