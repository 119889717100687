import * as React from "react";
import "./DocStatusList.scss";

export interface DocStatusListProps {
  docName: string;
  docStatus: "Pending Upload" | "Approved" | "AUTO APPROVED" | "PENDING";
  docInfo: string;
}

const DocStatusList: React.FC<DocStatusListProps> = (props) => (
  <div className="docStatusList">
    <label className="docName">{props.docName}</label>
    <label
      className={"docStatus" + (props.docStatus === "Approved" ? " blue" : "")}
    >
      {props.docStatus}
    </label>
    <label className="docInfo">{props.docInfo}</label>
  </div>
);

export default DocStatusList;
