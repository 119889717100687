import * as React from "react";
import "../ESignForm/ESignForm.scss";
import DocStatus from "../DocStatus/DocStatus";
import Button, { TransparentButton } from "../../UI/Button/Button";
import esignBanner from "../../../assets/icons/esignBanner.jpeg";
import { AGetEsignUrlDetails } from "../../Services/dashboard/eSignApiServices";
import GlobalStore from "../../store/globalStore";
import gtmService from "../../Services/gtmService";
import { errorMessage } from "../../utils/errorMessage";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import { setStageData } from "../../Services/globalService";
var timeLeft = 4;
const clientPartialDetails_ao = JSON.parse(
  window.localStorage.getItem("clientPartialDetails_ao") || "{}"
);
const EsignKra: React.FC<any> = () => {
  // Fetch query params for clervertap
  const [errCode, setErrCode] = React.useState<number | string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEsign, setIsEsign] = React.useState<boolean>(
    GlobalStore.stageStatus.isesigncompleted
  );
  React.useEffect(() => {
    var timerId = setInterval(countdown, 5000);
    function countdown() {
      if (timeLeft == -1 || GlobalStore.stageStatus.isesigncompleted) {
        clearInterval(timerId);
      } else {
        timeLeft--;
        if (
          GlobalStore.applicationID !== "" ||
          clientPartialDetails_ao.applicationId
        ) {
          setIsLoading(true);
          AGetStageCompletionStatus(
            (res: any) => getClientStageDetails(res),
            GlobalStore.applicationID || clientPartialDetails_ao.applicationId
          );
        }
      }
    }
    return () => {
      clearInterval(timerId);
    };
  }, []);
  function esignKraSubmit() {
    //redirect to NSDL website for esign
    setIsLoading(true);
    window.alert("You will be redirected here after Completion of Esign");
    AGetEsignUrlDetails(getEsignKraUrl, GlobalStore.applicationID, 2);
  }
  function getEsignKraUrl(res: any) {
    if (!res || res.error) {
      console.log(res.errMsg);
      setErrCode(res.error);
      gtmService({ action: "GetEsignKraLink", label: "ERROR || ERR117" });
      setIsLoading(false);
    } else {
      if (
        (res.data.Code == 200 || res.data.Code == 201) &&
        res.data.Data.EsignLink !== null
      ) {
        const encryptedUrl = res.data.Data.EsignLink;
        // gtmService({ action: "E_SIGN_KRA | E_SIGN_KRA", label: "Step_Complete" });

        // Clevertap event while user choose for e-sign
        // esignCleverTapEvent(true);
        // Clevertap ends

        window.location.href = encryptedUrl;
      } else {
        var allErrorsString = "";
        if (res.data.Data.FormValidationSummary.length > 0) {
          res.data.Data.FormValidationSummary.forEach(
            (element: any, i: number) => {
              if (i === 0) allErrorsString = element.FormValidations;
              else allErrorsString += `, ${element.FormValidations}`;
            }
          );
        }
        setErrCode(errorMessage.newEsignError);
        setIsLoading(false);
      }
    }
  }
  function handleChangeStatus() {
    if (
      GlobalStore.applicationID !== "" ||
      clientPartialDetails_ao.applicationId
    ) {
      AGetStageCompletionStatus(
        (res: any) => getClientStageDetails(res),
        GlobalStore.applicationID || clientPartialDetails_ao.applicationId
      );
    }
  }

  function getClientStageDetails(res: any) {
    setIsLoading(false);
    if (!res || res.error) {
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        //set stage api data to GlobalStore

        setStageData(responseObj);
        setIsEsign(responseObj.isesigncompleted);
        //check completeness of stages
      }
    }
  }
  return (
    <div className="prflHome">
      <div className="prflHeading">
        <label>E-Sign</label>
      </div>
      <div className="banner">
        <img className="bannerImg" src={esignBanner} alt="ESign" />
      </div>
      <div>
        <div className="esignKra">
          <h3>KRA Registration Request</h3>
        </div>
        <div className="esignContent">
          <ul>
            <li>
              I hereby declare that the details fumished are true and correct to
              the best of my knowledge and belief and I undertake to inform you
              of any changes therein, immediately. In case any of the
              information is found to be false or untrue or misleading or
              misrepresenting, I am aware that I may be held liable for it.
            </li>
            <li>
              {" "}
              I hereby consent to receiving information from Central KYC
              Registry and / or KRA through SMS/Email on the above registered
              number / email address.{" "}
            </li>
            <li>
              I am also aware that for Aadhaar OVD based KYC, my KYC request
              shall be validated against Aadhaar details. I hereby consent to
              sharing my masked Aadhaar card with readable QR code or my Aadhaar
              XML/Digilocker XML file, along with passcode and as applicable,
              with KRA and other Intermediaries with whom I have a business
              relationship for KYC purposes only.
            </li>
          </ul>
        </div>
        <div className={"esignKraBtn"}>
          {isEsign ? (
            <div className="eSignBtn">
              <Button
                btnName="E-Sign"
                isLoading={isLoading}
                disabled={!isEsign}
                onClick={esignKraSubmit}
              />
            </div>
          ) : (
            <div>
              <div className={"esignKraBtn"}>
                <Button
                  btnName="Retry & Esign"
                  isLoading={isLoading}
                  disabled={timeLeft < 0}
                  onClick={handleChangeStatus}
                />
              </div>
              <br />
              <div className="esignContent">
                E-Sign Confirmation is pending for previous signature. Click on
                above button to Retry and E-Sign
              </div>
            </div>
          )}
        </div>
        {errCode && (
          <div className="errMsgBlck">
            <ErrMsg>
              {typeof errCode === "number" ? (
                <>
                  <label>We are facing some technical issue.</label>
                  <label>
                    Please try after sometime (error code:{errCode})
                  </label>
                </>
              ) : (
                <label>{errCode}</label>
              )}
            </ErrMsg>
          </div>
        )}
      </div>
    </div>
  );
};

export default EsignKra;
