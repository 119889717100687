import React from "react";
import "./Backdrop.scss";
// import 'cropperjs/dist/cropper.css';
export interface BackdropProps {
  children: any;
  isShow: boolean;
  isCloseIcon: boolean;
  onClose?: () => void;
  width?: string;
}

const Backdrop: React.FC<BackdropProps> = (props) => {
  return (
    <React.Fragment>
      {props.isShow && (
        <div className="backdrop">
          <div className={`bdContent ${props.width ? props.width : ""}`}>
            {props.isCloseIcon && (
              <span
                onClick={props.onClose}
                className="edel-icon-close closeIcon"
              />
            )}
            {props.children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export const FullBackdrop: React.FC<BackdropProps> = (props) => {
  return (
    <React.Fragment>
      {props.isShow && (
        <div className="fullBackdrop">
          <div className="bdFullContent">
            {props.isCloseIcon && (
              <span
                onClick={props.onClose}
                className="edel-icon-close closeIcon"
              />
            )}
            {props.children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Backdrop;
