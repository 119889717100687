import React, { useState } from "react";
import "./PersonalInfo.scss";
import InputField from "../../UI/InputField/InputField";
import moment from "moment";
import Calender from "../../UI/Calender/Calender";
// import { errorCode, errorMsg } from "../../../ErrorCode/ErrorCode";
import { DocDetailFormat } from "../../UI/DocumentBrowse/DocumentBrowse";
import { alphaNumExp } from "../../utils/regex";
import { format } from "date-fns";
export interface DocumentBrowseProps {
  defaultAddress: string;
  addressObj: IAddressInfoFormat;
  addressErrorObj: any;
  onAddressInfoChange: (addressObj: IAddressInfoFormat) => void;
  onAddressInfoBlur: (addressObj: {
    [key in keyof IAddressInfoFormat]?: string;
  }) => void;
  qrVerified?: boolean;
}
export interface IAddressInfoFormat {
  addressLine1: string;
  City: string;
  Country: string;
  District: string;
  PinCode: string;
  State: string;
  Area?: string;
  CountryCode?: number;
  StateCode?: number;
  clientName?: string;
  documentNumber?: string;
  dateOfIssue?: any;
  dateOfExpiry?: any;
}
export const addressInfoErrorObj = {
  addressLine1: undefined,
  pincode: undefined,
  area: undefined,
  clientName: undefined,
};
let time = "00:00:00";
const PermanentAddressField: React.FC<DocumentBrowseProps> = (props) => {
  const [defaultAddress, setDefaultAddress] = useState<string>(
    props.defaultAddress
  );
  const [addressObj, setAddressObj] = React.useState<IAddressInfoFormat>(
    props.addressObj
  );

  const [errors, setErrors] = React.useState<any>({
    ...props.addressErrorObj,
  });

  React.useEffect(() => {
    if (
      JSON.stringify(addressObj) !== JSON.stringify(props?.addressObj) ||
      JSON.stringify(errors) !== JSON.stringify(props?.addressErrorObj)
    ) {
      console.log("||=======", props?.addressObj);
      let addObj = props?.addressObj;

      setAddressObj(addObj);
      setErrors(props.addressErrorObj);
    }
    setDefaultAddress(props.defaultAddress);
  }, [props.defaultAddress, props.addressObj]);

  function handleChangeAddress(key: keyof IAddressInfoFormat, value: any) {
    // props.onAddressInfoBlur({ ...errors, [key]: undefined });
    switch (key) {
      case "clientName":
        props.onAddressInfoChange({
          ...addressObj,
          [`${key}`]: value.length > 0 ? value : "",
        });
        props.onAddressInfoBlur({
          ...errors,
          [key]: undefined,
        });
        break;
      case "documentNumber":
        props.onAddressInfoChange({
          ...addressObj,
          [`${key}`]: value.length > 0 ? value : "",
        });
        props.onAddressInfoBlur({
          ...errors,
          [key]: undefined,
        });
        break;
      case "dateOfIssue":
        props.onAddressInfoChange({
          ...addressObj,
          [`${key}`]: value ? moment(value).format("DD/MM/YYYY") : "",
        });
        props.onAddressInfoBlur({
          ...errors,
          [key]: undefined,
        });
        break;
      case "dateOfExpiry":
        props.onAddressInfoChange({
          ...addressObj,
          [`${key}`]: value ? moment(value).format("DD/MM/YYYY") : "",
        });
        props.onAddressInfoBlur({
          ...errors,
          [key]: undefined,
        });
        break;
    }
  }
  function handleBlurAddress(key: string, error: any) {
    switch (key) {
      case "clientName":
        setErrors({
          ...errors,
          clientName: error?.required
            ? "Client Name cannot have special characters or cannot be null"
            : undefined,
        });
        props.onAddressInfoBlur({
          ...errors,
          [key]: error?.required
            ? "Client Name cannot have special characters or cannot be null"
            : undefined,
        });
        break;
      case "documentNumber":
        setErrors({
          ...errors,
          [key]: error?.required
            ? "Document No cannot have special characters or cannot be null"
            : undefined,
        });
        props.onAddressInfoBlur({
          ...errors,
          [key]: error?.required
            ? "Client Name cannot have special characters or cannot be null"
            : undefined,
        });
        break;
    }
  }
  function onBlurDoi(event: React.FocusEvent<HTMLInputElement>) {
    if (!addressObj.dateOfIssue) {
      setErrors({ ...errors, dateOfIssue: "DOI as per Document" });
    }
  }
  function onBlurDoe(event: React.FocusEvent<HTMLInputElement>) {
    if (!addressObj.dateOfExpiry) {
      setErrors({ ...errors, dateOfExpiry: "DOE as per Document" });
    }
  }
  const getMonthName = (monthDate: any) => {
    switch (monthDate) {
      case "01":
        return "January";
      case "02":
        return "Febrauary";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
    }
  };

  // console.log(
  //   "Date===",
  //   new Date(`${
  //     addressObj?.dateOfIssue &&
  //     getMonthName(addressObj?.dateOfIssue?.split("/")[1])
  //   }
  //               ${
  //                 addressObj?.dateOfIssue &&
  //                 addressObj?.dateOfIssue?.split("/")[0]
  //               }
  //               ,${
  //                 addressObj?.dateOfIssue &&
  //                 addressObj?.dateOfIssue?.split("/")[2]
  //               } ${addressObj?.dateOfIssue && time}`)
  // );

  return (
    <form className="PersonalInfoForm">
      <>
        <div>
          {/* Aadhar start */}
          {defaultAddress.length > 0 && (
            <div className="row">
              <label className="inptLbl">Client Name</label>
              <InputField
                required
                defaultValue={addressObj?.clientName}
                placeholder="Eg. Prateek"
                name="aadhaarName"
                type="text"
                disabled={props.qrVerified ? props.qrVerified : false}
                onChange={(value) => handleChangeAddress("clientName", value)}
                onBlur={(error) => handleBlurAddress("clientName", error)}
                errorText={errors.clientName}
              />
            </div>
          )}
          {defaultAddress === "Aadhaar Card" && (
            <>
              <div className="row">
                <label className="inptLbl">Aadhaar Number</label>
                <InputField
                  required
                  defaultValue={addressObj?.documentNumber}
                  placeholder="Eg. 1234"
                  name="aadhaarNo"
                  maxLength={12}
                  type="text"
                  disabled={props.qrVerified ? props.qrVerified : false}
                  onChange={(value) =>
                    handleChangeAddress("documentNumber", value)
                  }
                  onBlur={(error) => handleBlurAddress("documentNumber", error)}
                  errorText={errors.documentNumber}
                />
              </div>
            </>
          )}
          {/* Aadhar End*/}

          {/* Driving License Start */}
          {defaultAddress === "Driving License" && (
            <>
              <div className="row">
                <label className="inptLbl">Driving License Number</label>
                <InputField
                  required
                  alphanum
                  defaultValue={addressObj?.documentNumber}
                  placeholder="Eg. 1234"
                  name="Driving License Number"
                  type="text"
                  onChange={(value) =>
                    handleChangeAddress("documentNumber", value)
                  }
                  onBlur={(error) => handleBlurAddress("documentNumber", error)}
                  errorText={errors.documentNumber}
                />
              </div>
              <div className="row">
                <label className="inptLbl">Date of Issue</label>
                <Calender
                  defaultDate={
                    addressObj?.dateOfIssue
                      ? new Date(addressObj?.dateOfIssue?.split("/")?.reverse())
                      : undefined
                  }
                  onDateSelect={(val) =>
                    handleChangeAddress("dateOfIssue", val)
                  }
                  placeholderText={"Select Date of Issue"}
                  maxDate={
                    new Date(new Date().setFullYear(new Date().getFullYear()))
                  }
                  onBlur={onBlurDoi}
                  isError={errors.dateOfIssue ? true : false}
                />
              </div>
              {errors.dateOfIssue && (
                <p className="help_text red">{errors.dateOfIssue}</p>
              )}
              <div className="row">
                <label className="inptLbl">Date of Expiry</label>
                <Calender
                  // defaultDate={new Date('December 24, 1996 00:00:00')} //To be removed
                  defaultDate={
                    addressObj?.dateOfExpiry
                      ? new Date(
                          addressObj?.dateOfExpiry?.split("/")?.reverse()
                        )
                      : undefined
                  }
                  onDateSelect={(val) =>
                    handleChangeAddress("dateOfExpiry", val)
                  }
                  placeholderText={"Select Date of Expiry"}
                  minDate={
                    new Date(new Date().setFullYear(new Date().getFullYear()))
                  }
                  onBlur={onBlurDoe}
                  isError={errors.dateOfExpiry ? true : false}
                />
              </div>
              {errors.dateOfExpiry && (
                <p className="help_text red">{errors.dateOfExpiry}</p>
              )}
            </>
          )}
          {/* Driving License End */}
          {/* Voter Id  start*/}
          {defaultAddress === "Voter ID" && (
            <>
              {" "}
              <div className="row">
                <label className="inptLbl">Voter Id</label>
                <InputField
                  required
                  alphanum
                  defaultValue={addressObj?.documentNumber}
                  placeholder="Eg. 1234"
                  name="Voter Id"
                  type="text"
                  onChange={(value) =>
                    handleChangeAddress("documentNumber", value)
                  }
                  onBlur={(error) => handleBlurAddress("documentNumber", error)}
                  errorText={errors.documentNumber}
                />
              </div>
              <div className="row">
                <label className="inptLbl">Date of Issue</label>
                <Calender
                  defaultDate={
                    addressObj?.dateOfIssue
                      ? new Date(addressObj?.dateOfIssue?.split("/")?.reverse())
                      : undefined
                  }
                  onDateSelect={(val) =>
                    handleChangeAddress("dateOfIssue", val)
                  }
                  placeholderText={"Select Date of Issue"}
                  maxDate={
                    new Date(new Date().setFullYear(new Date().getFullYear()))
                  }
                  onBlur={onBlurDoi}
                  isError={errors.dateOfIssue ? true : false}
                />
              </div>
              {errors.dateOfIssue && (
                <p className="help_text red">{errors.dateOfIssue}</p>
              )}
            </>
          )}
          {/* Voter Id  end*/}
          {/* PassPort start */}
          {defaultAddress === "Passport" && (
            <>
              <div className="row">
                <label className="inptLbl">Passport Number</label>
                <InputField
                  required
                  alphanum
                  defaultValue={addressObj?.documentNumber}
                  placeholder="Eg. 1234"
                  name="Passport Number"
                  type="text"
                  onChange={(value) =>
                    handleChangeAddress("documentNumber", value)
                  }
                  onBlur={(error) => handleBlurAddress("documentNumber", error)}
                  errorText={errors.documentNumber}
                />
              </div>
              <div className="row">
                <label className="inptLbl">Date of Issue</label>
                <Calender
                 defaultDate={
                  addressObj?.dateOfIssue
                    ? new Date(addressObj?.dateOfIssue?.split("/")?.reverse())
                    : undefined
                }
                  onDateSelect={(val) =>
                    handleChangeAddress("dateOfIssue", val)
                  }
                  placeholderText={"Select Date of Issue"}
                  maxDate={
                    new Date(new Date().setFullYear(new Date().getFullYear()))
                  }
                  onBlur={onBlurDoi}
                  isError={errors.dateOfIssue ? true : false}
                />
              </div>
              {errors.dateOfIssue && (
                <p className="help_text red">{errors.dateOfIssue}</p>
              )}
              <div className="row">
                <label className="inptLbl">Date of Expiry</label>
                <Calender
                   defaultDate={
                    addressObj?.dateOfExpiry
                      ? new Date(
                          addressObj?.dateOfExpiry?.split("/")?.reverse()
                        )
                      : undefined
                  }
                  onDateSelect={(val) =>
                    handleChangeAddress("dateOfExpiry", val)
                  }
                  placeholderText={"Select Date of Expiry"}
                  minDate={
                    new Date(new Date().setFullYear(new Date().getFullYear()))
                  }
                  onBlur={onBlurDoe}
                  isError={errors.dateOfExpiry ? true : false}
                />
              </div>
              {errors.dateOfExpiry && (
                <p className="help_text red">{errors.dateOfExpiry}</p>
              )}
            </>
          )}
          {/* PassPort end */}
        </div>
      </>
    </form>
  );
};

export default PermanentAddressField;
