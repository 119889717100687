import React, { Component } from "react";
import CheckStatusForm from "./CheckStatusForm/CheckStatusForm";
import regbanner from "../../../assets/icons/regBanner.jpeg"; //'../../../assets/icons/regBanner.jpeg'
import { parseQuery } from "../../Services/globalService";
import GlobalStore from "../../store/globalStore";
import "./CheckStatusHome.scss";
import { RouteChildrenProps } from "react-router-dom";

class CheckStatusHome extends Component<RouteChildrenProps> {
  // constructor(props: any) {
  //     super(props);
  //     this.init()
  // }

  // init = () => {
  //     let queryObject = parseQuery(this.props.location.search);
  //     if (queryObject && queryObject.utm_source && queryObject.utm_source.toUpperCase() === "LEADFORDSA")
  //         GlobalStore.leadForDsa = true;

  //     this.setUTMParams(queryObject);

  //     if (typeof (queryObject.refno) !== "undefined" && queryObject.refno && queryObject.refno !== '')
  //         // this.getClientDetails(queryObject.refno.replace(" ", '+'));

  //         if (typeof (queryObject.applicationid) !== "undefined" && queryObject.applicationid && queryObject.applicationid !== '') {
  //             // if (typeof (queryObject.type) !== "undefined" && queryObject.type && queryObject.type === "resume")
  //             // this.getRedisDocumentDetails(queryObject.applicationid.replace(/ /g, "+"));
  //             // else
  //             // this.getClientDetAfterEsign(queryObject.applicationid.replace(/ /g, "+"));
  //         }
  //     // this.checkforSiteMaintainence(queryObject);
  //     this.onlyDematParam(queryObject);
  //     // GlobalStore.sessionId = guid()
  // }

  // setUTMParams = (queryObject: any) => {
  //     if (Object.keys(queryObject).length > 0) {
  //         let objUTM_Info = {
  //             modeofapplication: "E",
  //             utm_source: queryObject.utm_source,
  //             utm_medium: queryObject.utm_medium,
  //             utm_term: queryObject.utm_term,
  //             utm_creative: queryObject.utm_creative,
  //             utm_campaign: queryObject.utm_campaign,
  //             utm_adgroup: queryObject.utm_adgroup,
  //             utm_placement: queryObject.utm_placement,
  //             utm_device: queryObject.utm_device,
  //             utm_content: queryObject.utm_content,
  //             referralcode: "",
  //             couponcode: "",
  //             stageinfo: "",
  //             signupdropout: "",
  //             registrationdur: "",
  //             devicetype: "",
  //             clientip: "",
  //             diysource: "W"
  //         }
  //         window.sessionStorage.setItem("objUTM_Info", JSON.stringify(objUTM_Info));
  //     } else {
  //         const utmObj = JSON.parse(window.sessionStorage.getItem("objUTM_Info") || '{}')
  //         if (!utmObj && Object.keys(utmObj).length === 0) {
  //             let objUTM_Info = {
  //                 modeofapplication: "E",
  //                 utm_source: queryObject.utm_source,
  //                 utm_medium: queryObject.utm_medium,
  //                 utm_term: queryObject.utm_term,
  //                 utm_creative: queryObject.utm_creative,
  //                 utm_campaign: queryObject.utm_campaign,
  //                 utm_adgroup: queryObject.utm_adgroup,
  //                 utm_placement: queryObject.utm_placement,
  //                 utm_device: queryObject.utm_device,
  //                 utm_content: queryObject.utm_content,
  //                 referralcode: "",
  //                 couponcode: "",
  //                 stageinfo: "",
  //                 signupdropout: "",
  //                 registrationdur: "",
  //                 devicetype: "",
  //                 clientip: "",
  //                 diysource: "W"
  //             }
  //             window.sessionStorage.setItem("objUTM_Info", JSON.stringify(objUTM_Info));
  //         }
  //     }
  // }

  // // getClientDetails = (refNo: string) => {
  // //     var clientObj = {
  // //         clientPrimaryDetailId: parseInt(GlobalStore.clientPrimaryDetailId),
  // //         applicationId: refNo
  // //     };
  // //     window.sessionStorage.setItem('clientDetails', JSON.stringify(clientObj));
  // //     $http({
  // //         method: "POST",
  // //         url: config.baseUrl + "/api/REST/GetClientDetails",
  // //         headers: { "Content-type": "application/json" },
  // //         data: clientObj,
  // //     }).then(function (res) {
  // //         $scope.ClientDetails = JSON.parse(res.data);
  // //         $scope.kraLoader = false;
  // //         $window.sessionStorage.setItem("isEnabled", true);
  // //         $('#panvarify').modal('hide');
  // //         if ($scope.ClientDetails.personal.length == 0) {
  // //             $state.go('personal-details');
  // //             $window.sessionStorage.setItem("isEnabled", false);
  // //         } else if ($scope.ClientDetails.bank.length == 0) {
  // //             $state.go('bank-details');
  // //         } else if ($scope.ClientDetails.profile.length == 0 && $scope.ClientDetails.nominee.length == 0) {
  // //             $rootScope.personaldetails = {};
  // //             $rootScope.profileDetails = {};
  // //             $state.go('profile-details');
  // //         } else if ($scope.ClientDetails.brokeragesegment.length == 0) {
  // //             $state.go('brokerage-segment');
  // //         }
  // //         //else if ($scope.ClientDetails.documents.length == 0) {
  // //         else if ($scope.ClientDetails.documents.length > 1) { // '1' is given here, because user will have already uploaded aadhar from digilocker.
  // //             if ($scope.ClientDetails.registration[0].bpm_pd_status != 2) {
  // //                 $rootScope.uploadedDocList = $scope.ClientDetails.documents;
  // //                 if ($scope.ClientDetails.personal[0].corrsaddraspermanent)
  // //                     $rootScope.isPermAddressProof = false;
  // //                 else
  // //                     $rootScope.isPermAddressProof = true;

  // //                 for (i = 0; i < $scope.ClientDetails.documents.length; i++) {
  // //                     if ($scope.ClientDetails.documents[i].documentid == 365 && $scope.ClientDetails.documents[i].documentcategoryid == 62) { // it means video ipv is done and uploaeded on ftp server
  // //                         $rootScope.videoIpvStatus = true;
  // //                         $state.go('documentVerification');
  // //                         break;
  // //                     }
  // //                     $rootScope.videoIpvStatus = false;
  // //                     $state.go('documents-upload');
  // //                 }
  // //             } else
  // //                 $state.go('documentVerification');
  // //         } else {
  // //             $state.go('documents-upload');
  // //         }
  // //     },
  // //         function (res) {
  // //             console.log(res);
  // //             errorMsg("errorcode: 106");
  // //         });
  // // }

  // // getRedisDocumentDetails = (appId: any) => {

  // //     // let digiobj = {
  // //     //     applicationId: appId
  // //     // };

  // //     // $http({
  // //     //     method: "POST",
  // //     //     url: config.baseUrl + "/api/REST/RedisDigilockerDoc",
  // //     //     headers: { "Content-type": "application/json" },
  // //     //     data: digiobj
  // //     // }).then(function (res) {
  // //     //     if (res.data != 'No Data') {
  // //     //         getProfileDetailsFromAadhar(res.data);
  // //     //         $scope.digiStepsCompleted = true;
  // //     //     } else {
  // //     //         $scope.digiStepsCompleted = false;
  // //     //     }
  // //     //     getclientprimarydetailsId(appId);
  // //     // }, function (res) {
  // //     //     errorMsg("errorcode: 108");
  // //     // });
  // // }

  // // getClientDetAfterEsign = (appId: any) => {
  // //     // $scope.loader = true;
  // //     // let clientObj = {
  // //     //     clientPrimaryDetailId: parseInt(GlobalStore.clientPrimaryDetailId),
  // //     //     applicationId: appId
  // //     // };
  // //     // $http({
  // //     //     method: "POST",
  // //     //     url: config.baseUrl + "/api/REST/GetClientDetails",
  // //     //     headers: { "Content-type": "application/json" },
  // //     //     data: clientObj,
  // //     // }).then(function (res) {
  // //     //     $scope.ClientDetails = JSON.parse(res.data);
  // //     //     $scope.kraLoader = false;
  // //     //     $('#panvarify').modal('hide');
  // //     //     $window.sessionStorage.setItem("isEnabled", true);
  // //     //     $rootScope.personaldetails = {};
  // //     //     $rootScope.personaldetails = angular.copy($scope.ClientDetails.personal[0]);
  // //     //     $rootScope.personaldetails.pan = $scope.ClientDetails.profile[0].pan;

  // //     //     $rootScope.personaldetails.firstname = $scope.ClientDetails.personal[0].holder_first_name;
  // //     //     $rootScope.personaldetails.lastname = $scope.ClientDetails.personal[0].holder_last_name;
  // //     //     $rootScope.personaldetails.middlename = $scope.ClientDetails.personal[0].holder_middle_name;

  // //     //     $rootScope.SaveKYCResponse = {};
  // //     //     $rootScope.SaveKYCResponse.ApplicationID = appId;

  // //     //     if ($scope.ClientDetails.registration.length > 0) { $rootScope.personaldetails.mobile = $scope.ClientDetails.registration[0].mobileno; } // fetching mobile number when user clicks on continue.

  // //     //     if ($scope.ClientDetails.personal.length > 0) {
  // //     //         $rootScope.personaldetails.adrLine1 = $scope.ClientDetails.personal[0].correspondence_address_1 + $scope.ClientDetails.personal[0].correspondence_address_2 + $scope.ClientDetails.personal[0].correspondence_address_3;
  // //     //         $rootScope.personaldetails.city = $scope.ClientDetails.personal[0].correspondance_city;
  // //     //         $rootScope.personaldetails.state = $scope.ClientDetails.personal[0].correspondance_state;
  // //     //         $rootScope.personaldetails.pincode = $scope.ClientDetails.personal[0].correspondance_pincode;
  // //     //     }

  // //     //     if ($scope.ClientDetails.esignurl.length > 0) {
  // //     //         for (i = 0; i < $scope.ClientDetails.documents.length; i++) {
  // //     //             if ($scope.ClientDetails.documents[i].documentid == 21 && $scope.ClientDetails.documents[i].documentcategoryid == 9) { // it means cheque leaf is submitted
  // //     //                 $rootScope.isChequeLeafSubmitted = true;
  // //     //             }
  // //     //         }
  // //     //         if ($scope.ClientDetails.esignurl[0].esginstatus == "PENDING") {
  // //     //             $state.go("documentVerification");
  // //     //         } else {
  // //     //             $state.go("poascheduler");
  // //     //         }
  // //     //     } else {
  // //     //         $state.go("documentVerification");
  // //     //     }
  // //     // }, function (res) {
  // //     //     console.log(res);
  // //     //     errorMsg("errorcode: 106");
  // //     // });
  // // }

  // // checkforSiteMaintainence = (queryObject: any) => {
  // //     $http({
  // //         method: "GET",
  // //         url: config.baseUrl + "/api/REST/Diymaintainance",
  // //         headers: { "Content-type": "application/json" }
  // //     }).then(function (res) {
  // //         var date = new Date();
  // //         if (typeof (res.data.data) != "undefined" && res.data.data.Status && res.data.data.DIY_Maintainance_From <= $filter('date')(new Date(), 'dd-MM-yyyy HH:mm:ss') &&
  // //             res.data.data.DIY_Maintainance_To >= $filter('date')(new Date(), 'dd-MM-yyyy HH:mm:ss')) {
  // //             $scope.maintainenceMsg = res.data.data.DIY_Maintainance_Msg;
  // //             $scope.isMaintainence = true;
  // //         } else {
  // //             $scope.isMaintainence = false;
  // //             if (typeof (queryObject.leadid) != "undefined" && typeof (queryObject.firstname) != "undefined" &&
  // //                 typeof (queryObject.lastname) != "undefined" && typeof (queryObject.city) != "undefined" && typeof (queryObject.mobile) != "undefined" &&
  // //                 typeof (queryObject.email) != "undefined") {

  // //                 $rootScope.LeadID = queryObject.leadid;
  // //                 $rootScope.details = {
  // //                     firstname: queryObject.firstname,
  // //                     lastname: queryObject.lastname,
  // //                     city: queryObject.city,
  // //                     mobile: queryObject.mobile,
  // //                     emailId: queryObject.email,
  // //                 };
  // //                 $state.go('aadhaar');
  // //                 return false;
  // //             }
  // //             if (!$rootScope.isPartialFilledClient && typeof ($location.search().refno) == "undefined" && typeof (queryObject.applicationid) == "undefined")
  // //                 resumeAccountCreation();
  // //         }
  // //     }, function (res) {
  // //         console.log(res);
  // //         errorMsg("errorcode: 105");
  // //     });
  // // }

  // onlyDematParam = (queryObject: any) => {
  //     let objAccType;
  //     if (Object.keys(queryObject).length !== 0)
  //         objAccType = queryObject.accType === 'DMT' ? 'DMT' : 'EQTY';
  //     else
  //         objAccType = 'EQTY';
  //     window.sessionStorage.setItem("objAccType", objAccType);
  // }

  render() {
    return (
      <div className="registration">
        <div className="banner">
          <img className="bannerImg" src={regbanner} alt="banner" />
        </div>
        <CheckStatusForm {...this.props} />
      </div>
    );
  }
}

export default CheckStatusHome;
