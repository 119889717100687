import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";

export const getIFSCDetails = (callback: CallableFunction, ifsc: string) => {
  apiService(
    {
      url: "DIYAPI/GetBankDetails",
      body: {
        Type: "IFSC",
        BankName: "",
        BankCity: "",
        Ifsc: ifsc,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBankDetailsFromIfsc,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-bank-list-for-ifsc",
  //     body: {
  //       ifsccode: ifsc,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const SaveBankDetails = (
  callback: CallableFunction,
  listsObj: object
) => {
  apiService(
    {
      url: "DIYAPI/SaveBankDetails",
      body: listsObj,
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveBankDetails,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/save-bank-details",
  //     body: listsObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const getbankList = (callback: CallableFunction) => {
  //   apiService(
  //     {
  //       url: "eco/get-distinct-bank-list",
  //       method: "POST",
  //     },
  //     callback
  //   );
  apiService(
    {
      url: "DIYAPI/GetBankDetails",
      body: {
        Type: "All",
        BankName: "",
        BankCity: "",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getDistinctBankList,
    },
    callback
  );
};

export const getbankCity = (callback: CallableFunction, bankName: string) => {
  //   apiService(
  //     {
  //       url: "eco/get-bank-cities",
  //       body: {
  //         bankname: bankName,
  //       },
  //       method: "POST",
  //     },
  //     callback
  //   );
  apiService(
    {
      url: "DIYAPI/GetBankDetails",
      body: {
        Type: "city",
        BankName: bankName,
        BankCity: "",
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBankCities,
    },
    callback
  );
};

export const getbankBranch = (
  callback: CallableFunction,
  bankCity: string,
  bankName: string
) => {
  //   apiService(
  //     {
  //       url: "eco/get-bank-branch",
  //       body: {
  //         bankcity: bankCity,
  //         bankname: bankName,
  //       },
  //       method: "POST",
  //     },
  //     callback
  //   );
  apiService(
    {
      url: "DIYAPI/GetBankDetails",
      body: {
        Type: "branch",
        BankName: bankName,
        BankCity: bankCity,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBankBranch,
    },
    callback
  );
};

export const AGetBankDetails = (
  callback: CallableFunction,
  applicationid: string
) => {
  // apiService(
  //   {
  //     url: "eco/get-bank-details",
  //     body: {
  //       applicationid: applicationid,
  //     },
  //     method: "POST",
  //   },
  //   callback
  // );
  apiService(
    {
      url: "DIYAPI/GetBankDetailsByAppId",
      body: {
        ApplicationId: applicationid,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getBankDetails,
    },
    callback
  );
};

export const AGetBankMandateDetails = (
  callback: CallableFunction,
  applicationid: string
) => {
  apiService(
    {
      url: "eco/get-Bank-Mandate-Details",
      body: {
        Applicationid: applicationid,
      },
      method: "POST",
    },
    callback
  );
};

export const ASaveBankMandateDetails = (
  callback: CallableFunction,
  mandateObj: object
) => {
  apiService(
    {
      url: "eco/save-MultiBank-Mandate-Details",
      body: mandateObj,
      method: "POST",
    },
    callback
  );
};

export const AgetBeneficiaryName = (
  callback: CallableFunction,
  beneficiaryObj: any
) => {
  apiService(
    {
      url: "Pichain/GetPichainBeneficiaryName",
      method: "POST",
      body: {
        ApplicationId: beneficiaryObj.applicationid,
        AccountNumber: beneficiaryObj.account_number,
        Ifsc: beneficiaryObj.ifsc,
        Name: beneficiaryObj.name,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getPichainBeneficiaryName,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-beneficiary-name-pichain",
  //     body: beneficiaryObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AgetOcrResponseDetails = (
  callback: CallableFunction,
  ocrResponseObj: any
) => {
  apiService(
    {
      url: "DIYAPI/GetOCRDocumentResponseData",
      method: "POST",
      body: {
        ApplicationId: ocrResponseObj.applicationId,
        DocumentCategory: ocrResponseObj.documentCategory,
        DocumentId: ocrResponseObj.documentId,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getOCRDocumentResponseData,
    },
    callback
  );
  // apiService(
  //   {
  //     url: "eco/get-ocr-response-details",
  //     body: ocrResponseObj,
  //     method: "POST",
  //   },
  //   callback
  // );
};

export const AGetBeneficiaryNamePichainV1 = (
  callback: CallableFunction,
  req: object,
  headers: object
) => {
  // apiService(
  //   {
  //     url: "DIYAPI/GetPichainBeneficiaryName",
  //     method: "POST",
  //     body: {
  //
  //     },
  //     apiBaseUrl: config.diyNewApiUrl,
  //     tempToken: config.tempToken,
  //     serviceName: servicesList.getBeneficiaryNamePichainV1,
  //   },
  //   callback
  // );
  apiService(
    {
      url: "eco/get-beneficiary-name-pichain/v1",
      body: req,
      method: "POST",
      headers: headers,
    },
    callback
  );
};
