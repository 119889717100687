import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import StatusLayout from "../components/Status/Layout/Layout";
import WelcomeBack from "../components/Registration/WelcomeBack/WelcomeBack";
import MailVerified from "../components/Registration/MailVerified/MailVerified";
import NRI from "../components/Registration/NRI/NRI";
import OTP from "../components/Registration/OTP/OTP";
import AccExists from "../components/Registration/AccExists/AccExists";
import PANInfo from "../components/Registration/PANInfo/PANInfo";
import Error from "../components/UI/ErrorScreen/ErrorScreen";
import ShareAdd from "../components/Registration/ShareAdd/ShareAdd";
import CheckStatusHome from "../components/Status/CheckStatus/CheckStatusHome";
import AccountNotFound from "../components/Status/AccountNotFound/AccountNotFound";
import StatusVerified from "../components/Status/StatusVerified/StatusVerified";
import DocsVerificationInProgress from "../components/Status/DocsVerificationInProgress/DocsVerificationInProgress";
import DocumentReject from "../components/Status/DocumentReject/DocumentReject";
import { statusUrl } from "../components/utils/constants";
import StatusContinue from "../components/Status/StatusContinue/StatusContinue";
import InstanHelp from "../components/InstantHelp/InstantHelp";

const StatusRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          path={statusUrl.statusHome}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <StatusLayout>
                <CheckStatusHome {...props} />
              </StatusLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={statusUrl.statusVerified}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <StatusLayout>
                <StatusVerified {...props} />
              </StatusLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={statusUrl.documentReject}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <StatusLayout>
                <DocumentReject {...props} />
              </StatusLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={statusUrl.docVerificationInProgress}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <StatusLayout>
                <DocsVerificationInProgress {...props} />
              </StatusLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={statusUrl.accountNotFound}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <StatusLayout>
                <AccountNotFound {...props} />
              </StatusLayout>
            </InstanHelp>
          )}
        />
        <Route
          path={statusUrl.statusContinue}
          exact
          render={(props) => (
            <InstanHelp panPageFlag={false}>
              <StatusLayout>
                <StatusContinue {...props} />
              </StatusLayout>
            </InstanHelp>
          )}
        />
      </Switch>
    </>
  );
};

export default StatusRoutes;
