import React from "react";
import ProfileImage from "../../UI/ProfileImage/ProfileImage";
import Button from "../../UI/Button/Button";
// import './StatusContinue.scss';
import GlobalStore from "../../store/globalStore";
import { AGetStageCompletionStatus } from "../../Services/registration/continueApiService";
import ErrMsg from "../../UI/ErrMsg/ErrMsg";
import { useHistory } from "react-router-dom";
import { dashboardUrl, containerUrl, statusUrl } from "../../utils/constants";
import { setStageData } from "../../Services/globalService";
import welcomeBack from "../../../assets/icons/welcomeBack.jpg";
import { checkModuleCompleteness } from "../../Profile/ProfileNavBar/ProfileNavBar";
import {
  AGetClientDetailsByPan,
  AGetPanUtiData,
} from "../../Services/registration/panDobApiService";
import { APendingUploadList } from "../../Services/dashboard/eSignApiServices";
import { errorMessage } from "../../utils/errorMessage";
import { pan } from "../../utils/regex";

export interface StatusContinueProps {}

const StatusContinue: React.FC<StatusContinueProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [clientName, setClientName] = React.useState<string>("");
  const [errCode, setErrCode] = React.useState<number | string>();
  // const [isUploadDocumentsCompleted, setIsUploadDocumentsCompleted] = React.useState<boolean>(false);
  let isUploadDocumentsCompleted = false;
  const history = useHistory();

  const handleContinue = async () => {
    // e.preventDefault();
    setIsLoading(true);
    const panFromLocalStorage = await JSON.parse(
      window.localStorage.getItem("clientPartialDetails_ao") || "{}"
    );

    let panNum = GlobalStore.personaldetails.pan;

    if (
      GlobalStore.personaldetails.pan != undefined ||
      GlobalStore.personaldetails.pan != ""
    ) {
      panNum = GlobalStore.personaldetails.pan;
    } else if (panFromLocalStorage.pan != "") {
      panNum = panFromLocalStorage.pan != "" ? panFromLocalStorage.pan : "";
    }
    let isValidPan = pan.test(panNum);

    console.log(
      "panNum",
      { panNum, isValidPan },
      panNum !== "" && panNum !== undefined && isValidPan
    );
    if (panNum !== "" && panNum !== undefined && isValidPan) {
      AGetPanUtiData(validatePAN, panNum);
    } else {
      alert(
        "Please restart or check your status to continue with your Account Opening process as current session is expired."
      );
      history.push(containerUrl.registration);
      return;
    }
  };

  // function validatePAN(res: any) {
  //   if (!res || res.error) {
  //     // setErrCode(107);
  //     // setErrCode(errorMessage.validatePAN);
  //     console.log(res.errMsg);
  //     setErrCode(res.error);

  //     setIsLoading(false);
  //   } else {
  //     if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails?.AadharSeedingStatus.indexOf(
  //         "Existing and Valid"
  //       ) !== -1
  //     ) {
  //       let PANFirstName = res.data.panDetails.Firstname
  //         ? res.data.panDetails.Firstname
  //         : "";
  //       let PANMiddleName = res.data.panDetails.Middlename
  //         ? res.data.panDetails.Middlename
  //         : "";
  //       let PANlastName = res.data.panDetails.Lastname
  //         ? res.data.panDetails.Lastname
  //         : "";

  //       if (PANFirstName === "null" && PANlastName !== "null") {
  //         PANFirstName = PANlastName;
  //         GlobalStore.personaldetails.PANName = PANFirstName;
  //         PANlastName = "";
  //         if (PANMiddleName !== "null") {
  //           GlobalStore.personaldetails.PANName =
  //             GlobalStore.personaldetails.PANName + " " + PANMiddleName;
  //         }
  //       } else if (
  //         PANFirstName === "null" &&
  //         PANMiddleName === "null" &&
  //         PANlastName === "null"
  //       ) {
  //         GlobalStore.personaldetails.PANName = "";
  //       } else if (PANMiddleName === "null") {
  //         GlobalStore.personaldetails.PANName =
  //           PANFirstName + " " + PANlastName;
  //         PANMiddleName = "";
  //       } else {
  //         GlobalStore.personaldetails.PANName =
  //           PANFirstName + " " + PANMiddleName + " " + PANlastName;
  //       }

  //       //Modify personal details based on this response.

  //       GlobalStore.personaldetails.firstname = PANFirstName;
  //       GlobalStore.personaldetails.lastname = PANlastName;
  //       GlobalStore.personaldetails.middlename = PANMiddleName;
  //       GlobalStore.personaldetails.panDetails = res.data.panDetails;
  //       AGetClientDetailsByPan(searchByPan, GlobalStore.personaldetails.pan);
  //     } else if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails?.AadharSeedingStatus.indexOf("Invalid PAN") === -1
  //     ) {
  //       setErrCode(errorMessage.newPanUtiError);
  //       setIsLoading(false);
  //     } else if (
  //       res &&
  //       res.data &&
  //       res.data.panDetails &&
  //       res.data.panDetails?.AadharSeedingStatus.indexOf(
  //         "Not in ITD Database"
  //       ) === -1
  //     ) {
  //       setErrCode(errorMessage.newPanUtiError);
  //       setIsLoading(false);
  //     } else {
  //       setErrCode(errorMessage.newPanUtiError);
  //       setIsLoading(false);
  //     }
  //   }
  // }

  function validatePAN(res: any) {
    if (!res || res.error) {
      // setErrCode('107  - Error in fetching Pan details from PAN UTI system.');
      setErrCode(res.error);
      console.log(res.errMsg);
      setIsLoading(false);
    } else {
      console.log("PANlastName", res.data);

      if (res && res.data && res.data.PanNo && res.data.IsValid == true) {
        //CleverTap log Start
        // var data = {
        //     CurrentPage: $location.path(),
        //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
        //     EventName: 'pan_dob_success',
        //     CTA: 'Continue'
        // };
        // clevertap.event.push(data.EventName, data);
        //CleverTap log End

        let PANFirstName = res.data.Firstname ? res.data.Firstname : "";
        let PANMiddleName = res.data.Middlename ? res.data.Middlename : "";
        let PANlastName = res.data.Lastname ? res.data.Lastname : "";

        if (PANFirstName === "" && PANlastName !== "") {
          PANFirstName = PANlastName;
          GlobalStore.personaldetails.PANName = PANFirstName;
          PANlastName = "";
          if (PANMiddleName !== "") {
            GlobalStore.personaldetails.PANName =
              GlobalStore.personaldetails.PANName + " " + PANMiddleName;
          }
        } else if (
          PANFirstName === "" &&
          PANMiddleName === "" &&
          PANlastName === ""
        ) {
          GlobalStore.personaldetails.PANName = "";
        } else if (PANMiddleName === "") {
          GlobalStore.personaldetails.PANName =
            PANFirstName + " " + PANlastName;
          PANMiddleName = "";
        } else {
          GlobalStore.personaldetails.PANName =
            PANFirstName + " " + PANMiddleName + " " + PANlastName;
        }

        //Modify personal details based on this response.

        GlobalStore.personaldetails.firstname = PANFirstName;
        GlobalStore.personaldetails.lastname = PANlastName;
        GlobalStore.personaldetails.middlename = PANMiddleName;
        GlobalStore.personaldetails.panDetails = res.data;

        let responseObj = res.data.AadharSeedingStatus
          ? res.data.AadharSeedingStatus
          : "";

        if (responseObj.includes("Aadhaar not Seeded")) {
          GlobalStore.isSeeding = false;
        } else if (responseObj.includes("Aadhaar Seeding is Successful")) {
          GlobalStore.isSeeding = true;
        }

        //-------- After Pan validation Navigate to DigiLocker Page -------

        //Dudupe Logic here//
        AGetClientDetailsByPan(searchByPan, GlobalStore.personaldetails.pan);
      } else if (
        res &&
        res.data &&
        res.data.PanNo &&
        res.data.IsValid == false
      ) {
        // setErrCode('Not in ITD Database. Please enter valid PAN.');
        setErrCode(res.error);
        setIsLoading(false);
        //CleverTap log start
        // var data = {
        //     CurrentPage: $location.path(),
        //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
        //     EventName: 'pan_dob_failure',
        //     Error: 'Not in ITD Database.Please enter valid PAN'
        // };
        // clevertap.event.push(data.EventName, data);
        //CleverTap log end
        // } else if (
        //     res &&
        //     res.data &&
        //     res.data.panDetails &&
        //     res.data.panDetails.indexOf('Not in ITD Database') === -1
        // ) {
        //     // setErrCode('Not in ITD Database.Please enter valid PAN.');
        //     setErrCode(errorMessage.clientDetailsByPan);
        //     setIsLoading(false);
        //CleverTap log start
        // var data = {
        //     CurrentPage: $location.path(),
        //     Platform: angular.element($window).width() > 767 ? 'Website_Desktop' : 'Website_Mobile',
        //     EventName: 'pan_dob_failure',
        //     Error: 'Not in ITD Database.Please enter valid PAN'
        // };
        // clevertap.event.push(data.EventName, data);
        //CleverTap log end
      } else {
        // setErrCode('PAN UTI server down');
        setErrCode(res.error);
        setIsLoading(false);
      }
    }
  }

  const searchByPan = (res: any) => {
    if (!res || res.error) {
      // setErrCode(118);
      setErrCode(res.error);
      setIsLoading(false);
    } else {
      if (res.data) {
        if (res.data != null) {
          GlobalStore.personaldetails.pan = res.data.PAN;
          GlobalStore.token = res.data.Token;
          GlobalStore.applicationID = res.data.ApplicatoinId;
          GlobalStore.leadId = res.data.LeadId;
          GlobalStore.clientPrimaryDetailId = res.data.ClientPrimaryDetailId;

          const clientPartialDetails_ao = JSON.parse(
            window.localStorage.getItem("clientPartialDetails_ao") || "{}"
          );
          clientPartialDetails_ao.clientPrimaryDetailId =
            GlobalStore.clientPrimaryDetailId;
          window.localStorage.setItem(
            "clientPartialDetails_ao",
            JSON.stringify(clientPartialDetails_ao)
          );

          // AGetStageCompletionStatus(getClientStageDetails, GlobalStore.applicationID);
          APendingUploadList(checkPendingUpload, GlobalStore.applicationID);
        } else if (res.data == null) {
          setErrCode("No records found");
        }
      } else {
        setErrCode(res.error);
        setIsLoading(false);
      }
    }
  };

  function checkPendingUpload(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      setErrCode(res.error);
    } else {
      if (res && res.data) {
        let list: Array<any> = JSON.parse(res.data).filter(
          (item: any) =>
            // Prev api response
            // item.documentcategoryid !== 36 &&
            // item.documentcategoryid !== 30 &&
            // item.documentcategoryid !== 62
            item.Mandatory == true
        );
        if (list.length === 0 || list[0].count === 0)
          isUploadDocumentsCompleted = true;
      }
    }
    setIsLoading(false);
    AGetStageCompletionStatus(getClientStageDetails, GlobalStore.applicationID);
  }

  function getClientStageDetails(res: any) {
    setIsLoading(true);
    if (!res || res.error) {
      // setErrCode(106);
      setErrCode(res.error);
    } else {
      if (res.data) {
        const responseObj = JSON.parse(res.data);
        //set stage api data to GlobalStore
        setStageData(responseObj);
        //check completeness of stages
        checkModuleCompleteness(isUploadDocumentsCompleted);

        if (responseObj.isdigilockercompleted) GlobalStore.isDigiLocker = true;
        if (responseObj.iskra) GlobalStore.isKRA = true;
        if (responseObj.iskyc) GlobalStore.isKYC = true;

        const clientPartialDetails_ao = JSON.parse(
          window.localStorage.getItem("clientPartialDetails_ao") || "{}"
        );
        clientPartialDetails_ao.applicationId = GlobalStore.applicationID;
        clientPartialDetails_ao.token = GlobalStore.token;
        clientPartialDetails_ao.leadID = GlobalStore.leadId;
        clientPartialDetails_ao.PANName = GlobalStore.personaldetails.PANName;
        clientPartialDetails_ao.panDetails =
          GlobalStore.personaldetails.panDetails;
        clientPartialDetails_ao.isDigiLocker = GlobalStore.isDigiLocker;
        clientPartialDetails_ao.isKRA = GlobalStore.stageStatus.iskra;
        clientPartialDetails_ao.isKYC = GlobalStore.stageStatus.iskyc;
        clientPartialDetails_ao.dob = GlobalStore.stageStatus.dob; //moment(values.dob).format("DD/MM/YYYY");

        window.localStorage.setItem(
          "clientPartialDetails_ao",
          JSON.stringify(clientPartialDetails_ao)
        );

        //route according to stage api response
        if (
          responseObj.applicationstatus === 1 &&
          responseObj.bpm_pd_status === 0
        ) {
          history.push(statusUrl.docVerificationInProgress);
          return;
        }
        if (!isUploadDocumentsCompleted && responseObj.bpm_pd_status === 2) {
          history.push(statusUrl.documentReject);
          return;
        }
        if (
          !responseObj.isdigilockercompleted &&
          GlobalStore.aadharLinkedChoice &&
          !GlobalStore.isDigiLocker &&
          !GlobalStore.isKRA
        ) {
          history.push(containerUrl.dgLocker);
          return;
        }
        if (
          !(
            responseObj.isprofiledetailscompleted &&
            responseObj.ispersonaldetailscompleted
          )
        ) {
          alert("Please restart/continue with your Account Opening process");
          history.push(containerUrl.registration);
          return;
        }
        if (!responseObj.isbankdetailscompleted) {
          history.push(dashboardUrl.bank);
          return;
        }
        if (
          !responseObj.isbrokeragesegmentcompleted ||
          !responseObj.isbrokerageschemecompleted
        ) {
          history.push(dashboardUrl.plan);
          return;
        }
        if (!isUploadDocumentsCompleted) {
          history.push(dashboardUrl.doc);
          return;
        }
        if (
          !GlobalStore.isDigiLocker &&
          !GlobalStore.isKRA &&
          !GlobalStore.isKYC &&
          !responseObj.isipvarificationcompleted
        ) {
          history.push(dashboardUrl.ipv);
          return;
        }
        if (responseObj.isagentvisitcompleted) {
          history.push(containerUrl.ocv);
          return;
        }
        if (!responseObj.isesigncompleted) {
          history.push(dashboardUrl.eSign + "?sc=e");
          return;
        }
        if (responseObj.isesigncompleted && !responseObj.iskraesigncompleted) {
          history.push(dashboardUrl.eSign + "?sc=k");
          return;
        }
        if (responseObj.bpm_pd_status === 1) {
          history.push(containerUrl.poa);
          return;
        }
      }
    }
    setIsLoading(false);
  }

  return (
    <div className="wb">
      <div className="wbLogo">
        <ProfileImage>
          <img src={welcomeBack} alt="Welcome" className="pfImg" />
        </ProfileImage>
      </div>
      <div className="wbheading">
        <label>Welcome Back {clientName}</label>
      </div>
      <div className="wbInfo">
        <label className="infoLine">
          Your form is incomplete. You are yet to provide some key information
          as well as some documents that may be pending for upload.
        </label>
      </div>
      {/* Error Msg Start*/}
      {errCode && (
        <div className="errMsgBlck">
          <ErrMsg>
            {typeof errCode === "number" ? (
              <>
                <label>We are facing some technical issue.</label>
                <label>Please try after sometime (error code:{errCode})</label>
              </>
            ) : (
              <label>{errCode}</label>
            )}
          </ErrMsg>
        </div>
      )}
      {/* Error Msg End*/}
      <div className="wbBtns">
        <div className="wbBtn wbcntn">
          <Button
            btnName="Continue"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
};

export default StatusContinue;
