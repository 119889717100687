import * as React from 'react';
import { Component } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import './PdfContainer.scss';

export interface PdfContainerProps {
    src: any;
    onClose?: () => void;
    showModal: boolean;
}

const PdfContainer: React.FC<PdfContainerProps> = (props) => {
    return (
        <Backdrop isShow={props.showModal} isCloseIcon={true} onClose={props.onClose}>
            <embed src={props.src} type='application/pdf' className="pdfModal" />
        </Backdrop>

    );
}

export default PdfContainer;