import * as React from "react";
import "./TextArea.scss";
import indexer from "../../types/genericTypes";
import {
  required,
  isValidRegex,
  grtrThanEqTo,
  lessThanEqTo,
} from "../../utils/validation";
import {
  integerExp,
  alphaNumExp,
  alphabetExp,
  addressAlphaNum,
} from "../../utils/regex";

export interface ITextAreaProps {
  disabled?: boolean; // To make input disabled
  defaultValue?: string; // The defaultValue in input field (pass only if required)
  placeholder?: string; // placeholder
  prefix?: React.ReactChild; // text/element to show before the input field
  suffix?: React.ReactChild; // text/element to show after the input field
  helpText?: string; // help text
  helpTextColor?: "grey" | "blue"; // the color of help text, takes gray as default
  errorText?: string | null; // error text
  required?: boolean; // true if is required
  integer?: boolean; // true if only integer can be entered
  alphanum?: boolean; // true if only alphanumeric can be entered
  addressAlphaNum?: boolean;
  alphabate?: boolean; // true if only alphabate can be entered
  float?: RegExp; // number of digits after decimal
  min?: number; // minimum number
  max?: number; // maximum number
  className?: string; // extra classname if needed for module specific style
  type?: "tel" | "text" | "email" | "password"; // type of input
  maxLength?: number; // if there is a max length for the input
  pattern?: RegExp; // validate any regExp
  id?: string;
  name?: string;
  autoComplete?: "on" | "off"; // used for switching on/off autocomplete
  onChange?: (value: string) => string | void; // function to be called on change. Return string if value needs to be changed from outside
  onBlur?: (error: indexer) => void; // function to be called on blur, all errors will be available in error object
  cancelSuffix?: boolean; //To display cross(x), to clear the input;
  isCaps?: boolean; //Input value should be all CAPS then true, else false
  hiddenText?: string; // text shows only on focus
}

const TextArea: React.FC<ITextAreaProps> = (props) => {
  // sets the default value of input if present
  // value: value of the input (type = string)
  // setValue: function to set value
  const [value, setValue] = React.useState(props.defaultValue || "");
  const [errText, setErrText] = React.useState(props.errorText);

  // if default value is updated from outside, change it inside also
  React.useEffect(() => {
    if (props.defaultValue !== undefined && value !== props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  React.useEffect(() => {
    setErrText(props.errorText);
  }, [props.errorText]);

  // when focussed, this helps to colour the border of input_field_cont
  const [focusClass, setFocusClass] = React.useState("");

  /**
   * @param value The string that needs to be checked for integer
   * @returns If integer need not be checked, then return true.
   * - If integer needs to be checked, then
   * - If value is valid integer, then return true; otherwise, return false
   */
  function isValidInteger(value: string): boolean {
    return (
      !props.integer || (props.integer && !isValidRegex(value, integerExp))
    );
  }

  /**
   * @param value The string that needs to be checked for float
   * @returns If float need not be checked, then return true.
   * - If float needs to be checked, then
   * - if value is valid float, then return true; otherwise, return false
   */
  function isValidFloat(value: string): boolean {
    return !props.float || (props.float && !isValidRegex(value, props.float));
  }

  /**
   * @param value The string that needs to be checked for alphanumeric characters
   * @returns If alphanum need not be checked, then return true.
   * - If alphanum needs to be checked, then
   * - if value is valid alphanum, then return true; otherwise, return false
   */
  function isValidAlphaNum(value: string): boolean {
    return (
      !props.alphanum || (props.alphanum && !isValidRegex(value, alphaNumExp))
    );
  }
  function isValidAddressAlphaNum(value: string): boolean {
    return (
      !props.addressAlphaNum ||
      (props.addressAlphaNum && !isValidRegex(value, addressAlphaNum))
    );
  }

  /**
   * @param value The string that needs to be checked for alphabatic characters
   * @returns If alphabate need not be checked, then return true.
   * - If alphabate needs to be checked, then
   * - if value is valid alphabate, then return true; otherwise, return false
   */
  function isValidAlphabate(value: string): boolean {
    return (
      !props.alphabate || (props.alphabate && !isValidRegex(value, alphabetExp))
    );
  }

  /**
   * @param value The string that needs to be checked for maxlength
   * @returns If maxlength need not be checked, then return true.
   * - If maxlength needs to be checked, then
   * - if value has less characters than maxlength, then return true; otherwise, return false
   */
  function isValidMaxLength(value: string): boolean {
    return props.maxLength === undefined || value.length <= props.maxLength;
  }

  /**
   * @param value The value that is required
   * @returns If required need not be checked, then return true.
   * - If required needs to be checked, return true if value is not present; false if value is present
   */
  function isRequired(value: any): boolean {
    return !props.required || (props.required && !!required(value));
  }

  /**
   * @param value The value that is to be validated
   * @returns If min need not be checked, then return true.
   * - If min needs to be checked,
   * - throw error if value is string
   * - return true if value < min; false if value >= min
   */
  function isLessThanMin(value: string): boolean {
    if (props.min === undefined) {
      return true;
    }
    if (isNaN(+value)) {
      throw Error("Can't validate non-numerical value for min");
    }
    return !!grtrThanEqTo(+value, props.min);
  }

  /**
   * @param value The value that is to be validated
   * @returns If max need not be checked, then return true.
   * - If max needs to be checked,
   * - throw error if value is string
   * - return true if value > max; false if value <= min
   */
  function isGrtrThanMax(value: string): boolean {
    if (props.max === undefined) {
      return true;
    }
    if (isNaN(+value)) {
      throw Error("Can't validate non-numerical value for max");
    }
    return !!lessThanEqTo(+value, props.max);
  }

  /**
   * @param value The string that needs to be checked for the pattern
   * @returns If pattern need not be checked, then return true.
   * - If pattern needs to be checked, then
   * - if value is valid pattern, then return true; otherwise, return false
   */
  function isValidPattern(value: string): boolean {
    return (
      !props.pattern || (props.pattern && !isValidRegex(value, props.pattern))
    );
  }

  /**
   * This function runs on blur.
   * This removes the focus class on input_field_cont and removes commas.
   * This function calls the onBlur callback function with all the errors.
   */
  function handleBlur() {
    setFocusClass("");

    let errorObj: indexer = {};

    if (props.required) {
      errorObj = { ...errorObj, required: isRequired(value) }; // set isRequired error
    }
    if (props.min) {
      errorObj = { ...errorObj, min: isLessThanMin(value) }; // set error for min
    }
    if (props.max) {
      errorObj = { ...errorObj, max: isGrtrThanMax(value) }; // set error for max
    }
    if (props.pattern) {
      errorObj = { ...errorObj, pattern: isValidPattern(value) }; // set error for pattern
    }
    props.onBlur && props.onBlur(errorObj);
  }

  /**
   * Checks for all the validations and then changes value accordingly
   * @param e The input event
   */
  function handleChange(e: React.FocusEvent<HTMLTextAreaElement>) {
    let resolvedValue: string;

    if (
      (e.target.value || e.target.value === "" || e.target.value === "0") &&
      isValidInteger(e.target.value) &&
      isValidFloat(e.target.value) &&
      isValidAlphaNum(e.target.value) &&
      isValidMaxLength(e.target.value) &&
      isValidAlphabate(e.target.value) &&
      isValidAddressAlphaNum(e.target.value)
    ) {
      // if value needs to be changed then process the new value
      if (props.isCaps) resolvedValue = e.target.value.toUpperCase();
      else resolvedValue = e.target.value;
      setErrText("");
    } else {
      // otherwise process the previous state
      resolvedValue = value;
    }

    if (props.onChange) {
      const newValue = props.onChange(resolvedValue || ""); // call onchange callback

      if (newValue !== undefined) {
        setValue(newValue); // set the new value
      } else {
        setValue(resolvedValue || ""); // set the new value
      }
    }
  }

  function handleCancel() {}

  return (
    <div className={"input_field_wrapper " + props.className}>
      <div
        className={
          "input_field_cont" + focusClass + (props.errorText ? " error" : "")
        }
      >
        {props.prefix && <div className="input_prefix">{props.prefix}</div>}
        <textarea
          value={value}
          onChange={handleChange}
          placeholder={props.placeholder}
          className="input_field"
          onFocus={() => setFocusClass(" focus")}
          onBlur={handleBlur}
          //type={props.type}
          id={props.id}
          autoComplete={props.autoComplete}
          disabled={props.disabled}
          name={props.name}
        />
        {props.cancelSuffix && focusClass === " focus" && (
          <div className="input_suffix">
            <i className="edel-icon-close" onClick={handleCancel} />
          </div>
        )}
        {props.suffix && <div className="input_suffix">{props.suffix}</div>}
      </div>
      {props.errorText && <p className="help_text red">{props.errorText}</p>}
      {props.hiddenText && (focusClass || value) && (
        <p className="help_text grey">{props.hiddenText}</p>
      )}
      {props.helpText && (
        <p className={`help_text ${props.helpTextColor || "blue"}`}>
          {props.helpText}
        </p>
      )}
    </div>
  );
};

export default TextArea;
