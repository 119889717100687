import config from "../../../config/domain";
import { apiService } from "../apiServices";
import servicesList from "../serviceList";



export const ASaveAddressDetails = (
  callback: CallableFunction,
  saveAddressPayload: any
) => {
  apiService(
    {
      url: "DIYAPI/SaveAddressDetails",
      method: "POST",
      body: saveAddressPayload,
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveAddressDetails,
    },
    callback
  );
}
export const AVerifyDocumentData = (
  callback: CallableFunction,
  verifyDocumentPayload: any
) => {
  apiService(
    {
      url: "Idfy/VerifyDocumentData",
      method: "POST",
      body: verifyDocumentPayload,
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveAddressDetails,
    },
    callback
  );
}
export const GetAddressDetails = (
  callback: CallableFunction,
  applicationId: string
) => {
  apiService(
    {
      url: "DIYAPI/GetAddressDetails",
      method: "POST",
      body:{
        applicationId: applicationId,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getAddressDetails,
    },
    callback
  );
}
export const ASaveDocumentDetails = (
  callback: CallableFunction,
  applicationId: string
) => {
  apiService(
    {
      url: "DIYAPI/GetAddressDetails",
      method: "POST",
      body:{
        applicationId: applicationId,
      },
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.getAddressDetails,
    },
    callback
  );
}
function sortDocumentInfo(documents: any) {
  let sortedDocuments: Array<Object> = [];
  for (let doc of documents) {
    let docObj: any = {};
    docObj.FileName = doc.fileName;
    docObj.DocumentId = doc.documentId;
    docObj.MimeType = doc.mimeType;
    docObj.PanNo = doc.panno;
    docObj.PersonalDetailsId = doc.personaldetailsid;
    docObj.DocumentCategoryId = doc.documentCategoryId;
    docObj.DocumentExpiryDate = doc.documentexpirydate;
    docObj.IsFileappFlag = doc.isWatsappFlag;
    docObj.DocumenCropped = doc.isfilecropped;
    docObj.AutoQc = doc.autoqc;
    docObj.IsQRVerified = doc.IsQRVerified;
    docObj.DocumentIssueDate = doc.DocumentIssueDate;
    docObj.DocumentName = doc.DocumentName;
    docObj.IsDocumentVerified = doc.IsDocumentVerified;
    docObj.documentNumber = doc.documentNumber;
    sortedDocuments.push(docObj);
  }
  return sortedDocuments;
}
export const ASavePersonalDocument = (
  callback: CallableFunction,
  saveObj: any
) => {
  apiService(
    {
      url: "DIYAPI/SaveUploadedDocumentDetails",
      body: {
        ClientPrimaryDetailId: saveObj.clientPrimaryDetailId,
        ApplicationId: saveObj.applicationId,
        LeadId: saveObj.leadId,
        DocumentInfo: saveObj.documentInfo
          ? sortDocumentInfo(saveObj.documentInfo)
          : [],
        UtmInfoDetails: saveObj.objUTM_Info,
      },
      method: "POST",
      apiBaseUrl: config.diyNewApiUrl,
      tempToken: config.tempToken,
      serviceName: servicesList.saveUploadedDocumentDetails,
    },
    callback
  );
 
};