import * as React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./Calender.scss";
import "./cssFile.css";
import { ageCalculator } from "../../Services/globalService";

export interface ICalenderProps {
  onDateSelect: (date: Date) => void;
  placeholderText: string;
  maxDate?: Date;
  minDate?: Date;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  isError?: boolean;
  defaultDate?: Date;
}

interface ICustomHeaderProps {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear: () => void;
  increaseYear: () => void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

interface ICalenderContainerProps {
  className: string;
  children: React.ReactNode[];
}

enum EPickerType {
  Date,
  Month,
  Year,
}

const Calender: React.FC<ICalenderProps> = (props) => {
  // sets the default value of date to null
  // date: value of the date (type = Date || null)
  // setDate: function to set date
  const [date, setDate] = React.useState<Date | null>(
    props.defaultDate || null
  );

  // pickerType is used to determine which state of calender is open
  // pickerType: date | month | year
  // setPickerType: function to set pickerType
  const [pickerType, setPickerType] = React.useState<EPickerType>(
    EPickerType.Date
  );

  /**
   * - Changes the picker type on date/month/year selection.
   * - Calls the onDateSelect callback function
   * @param date selected date
   */
  function onDateSelect(date: Date | null) {
    // if picker type is month then go to date
    // if picker type is year then go to month
    if (pickerType !== EPickerType.Date) {
      setPickerType(pickerType - 1);
    }
    if (!props.maxDate || (date && ageCalculator(date) >= 0)) {
      setDate(date);
      date && props.onDateSelect(date);
    }
  }

  /**
   * - Creates custom header for date/month state
   * - Acts as the complete calender in year state
   * @param headerProps props passed by the component
   */
  const customHeader = (headerProps: ICustomHeaderProps) => {
    // These variables are used only in year state
    const selectedYear = (headerProps.date || new Date()).getFullYear();
    const currentYear = new Date().getFullYear();
    const yearMapHelper = [
      [11, 10, 9],
      [8, 7, 6],
      [5, 4, 3],
      [2, 1, 0],
    ];

    // calculate last year to show in year state
    // if current year is 2020
    // then on selecting 2019, last year should still show 2019 in year state
    // if this calculation is not used, then last year would become 2019
    const endYear =
      currentYear - 12 * Math.floor((currentYear - selectedYear) / 12);

    /**
     * Changes the year state for datepicker component.
     * @param yr selected year
     */
    function onYearClick(yr: number) {
      console.log("onYearClick");
      onDateSelect(new Date(yr, 0, 0));
      headerProps.changeYear(yr);
    }

    return (
      <>
        <div className="op_calender_header">
          {headerProps.prevMonthButtonDisabled ||
          pickerType === EPickerType.Month ? (
            // Don't show left arrow
            <div />
          ) : (
            // Show customised left arrow
            <button
              type="button"
              className="arrow_button"
              onClick={() => {
                if (pickerType === EPickerType.Date) {
                  return headerProps.decreaseMonth();
                } else {
                  // decrease year by 12 because we are showing 12 years in year state
                  for (let i = 0; i < 12; i++) headerProps.decreaseYear();
                }
              }}
            >
              {`<`}
            </button>
          )}

          <div>
            {/* Show clickable month only in date state */}
            {pickerType === EPickerType.Date && (
              <button
                type="button"
                className="header_month header_year_month underline"
                onClick={() => setPickerType(EPickerType.Month)}
              >
                {moment(headerProps.date).format("MMM")}
              </button>
            )}
            {/* Show clickable year in both date and month state  */}
            {pickerType !== EPickerType.Year && (
              <button
                type="button"
                className="header_year header_year_month underline"
                onClick={() => setPickerType(EPickerType.Year)}
              >
                {pickerType === EPickerType.Date
                  ? moment(headerProps.date).format("YYYY")
                  : headerProps.date.getFullYear()}
              </button>
            )}
            {/* Show year range (not-clickable) in year state */}
            {pickerType === EPickerType.Year && (
              <div className="header_year header_year_month">
                {endYear - 11}-{endYear}
              </div>
            )}
          </div>

          {headerProps.nextMonthButtonDisabled ||
          pickerType === EPickerType.Month ? (
            // Don't show right arrow
            <div />
          ) : (
            // Show customised right arrow
            <button
              type="button"
              className="arrow_button"
              onClick={() => {
                if (pickerType === EPickerType.Date) {
                  return headerProps.increaseMonth();
                } else {
                  for (let i = 0; i < 12; i++) headerProps.increaseYear();
                }
              }}
            >
              {">"}
            </button>
          )}
        </div>

        {/* Make the year calender if pickerType is year */}
        {pickerType === EPickerType.Year && (
          <div className="react-datepicker__month">
            {yearMapHelper.map((yrMapArr) => (
              <div className="year_row" key={yrMapArr[0]}>
                {yrMapArr.map((yrMap) => (
                  <button
                    type="button"
                    key={yrMap}
                    className={
                      "year_btn" +
                      (endYear - yrMap === date?.getFullYear() ? " active" : "")
                    }
                    onClick={() => onYearClick(endYear - yrMap)}
                  >
                    {endYear - yrMap}
                  </button>
                ))}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  /**
   * Since everything is created in custom header in year state
   * this function extracts the header part of the date picker and keeps the rest empty in year state
   * @param containerProps props passed by the component
   */
  const calendarContainer = (containerProps: ICalenderContainerProps) => {
    return pickerType === EPickerType.Year ? (
      <div className="op_calender_custom_year react-datepicker">
        {/* Custom Header */}
        <div className="react-datepicker__header">
          {containerProps.children[2] &&
            (containerProps.children[2] as any)[0].props &&
            (containerProps.children[2] as any)[0].props.children[0] &&
            (containerProps.children[2] as any)[0].props.children[0].props
              .children[0]}
        </div>
        {/* Body is empty */}
      </div>
    ) : (
      <div className={containerProps.className}>{containerProps.children}</div>
    );
  };

  // This is the main calender component that is rendering calender
  return (
    <div className="op_calender">
      <DatePicker
        selected={props.defaultDate || date}
        maxDate={props.maxDate}
        minDate={props.minDate}
        showMonthDropdown
        onBlur={props.onBlur}
        onChange={(date: Date | null) => onDateSelect(date)}
        placeholderText={props.placeholderText}
        showPopperArrow={false}
        customInput={<CustomInput isError={props.isError} />}
        showMonthYearPicker={pickerType === EPickerType.Month} // show month picker only if picker type is month
        shouldCloseOnSelect={pickerType === EPickerType.Date} // should only close if date picker is open
        renderCustomHeader={(headerProps) => customHeader(headerProps)}
        calendarContainer={(containerProps: ICalenderContainerProps) =>
          calendarContainer(containerProps)
        }
        onCalendarClose={() => setPickerType(EPickerType.Date)} // if year/month state was open before closing the reset to date state
      />
    </div>
  );
};

export default Calender;

/**
 * This component is for showing custom input box for Calender
 */
export interface ICustomInputProps {
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  isError?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}

class CustomInput extends React.Component<ICustomInputProps, {}> {
  render() {
    return (
      <div className="input_field_wrapper">
        <button
          className={"input_field_cont" + (this.props.isError ? " error" : "")}
          type="button"
          onClick={this.props.onClick}
          onBlur={this.props.onBlur}
          onChange={this.props.onClick}
          onFocus={this.props.onFocus}
        >
          {/* If a date is selected, then show date otherwise show the placeholder text */}
          {this.props.value ? (
            <span className="input_date input_field">
              {moment(this.props.value).format("DD MMM YYYY")}
            </span>
          ) : (
            <span className="placeholder input_field">
              {this.props.placeholder}
            </span>
          )}

          {/* Calender icon */}
          <i className="input_suffix edel-icon-calendar"></i>
        </button>
      </div>
    );
  }
}
