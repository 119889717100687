import React from 'react';
import './PendingModulesList.scss';
import PendingModule from './PendingModule/PendingModule';

export interface PendingModulesListProps {
    pendingModulesList: Array<{ name: string, navUrl: string }>;
}

const PendingModulesList: React.FC<PendingModulesListProps> = (props) => {
    return (
        <div className='pendingModulesList'>
            <label className='pmHeading'>Pending Modules</label>
            <label className='pmInfo'>Complete all the pending modules to proceed with E-Sign</label>
            <div className='pmList'>
                {
                    props.pendingModulesList.map((module, index) => (
                        <PendingModule key={index} moduleName={module.name} moduleLink={module.navUrl} />
                    ))
                }
            </div>
        </div>
    );
}

export default PendingModulesList;