import React from 'react';
import './InfoModal.scss'

export interface InfoModalProps {
    children: any;
    width: string;
}

const InfoModal: React.FC<InfoModalProps> = (props) => {
    return (
        <div className='infoModal' style={{ width: props.width }}>
            {props.children}
        </div>
    );
}

export default InfoModal;