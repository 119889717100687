export default interface indexer {
	[indexer: string]: any; //indexer
}

export interface IUserObj {
	firstname: string,
	lastName: string,
	email: string,
	mobile: string,
	city: string,
	rmCode?: string,
	UCC_Code?:string
}

export interface jwtToken {
	nbf: number;
	iat: number;
	exp: number;
	user: {
		UserName: string;
		Ai: string;
		CAi: any;
		Name: string;
		empCategory: string;
		UserType: number;
		Roles: null;
	};
	auth_token: string;
	v_auth_token: string;
	username: string;
	isImpersonated: boolean;
	accType: string;
	dealerid: string;
	empcodetxt: string;
	lgnSrc?: string;
}

export interface appIdKey {
	app: number;
	appid: string;
	av: string;
	bd: string;
	exp: number;
	ff: string;
	iat: number;
	iss: string;
	nbf: number;
	src: string;
}

// can contain only two strings: "BUY" or "SELL"
export enum EBuyOrSell {
	BUY = "BUY",
	SELL = "SELL"
}

export interface IListObj {
	value: number | string;
	display: string;
}

export interface IListInfoObj extends IListObj {
	info?: string;
}

export const initialIListObj: IListObj = {
	value: '',
	display: ''
}

export interface IDomain {
	root: string;
	MW: string;
	streamUrl: string;
	jmw: string;
	jmw_eq: string;
	jmw_eq_eq: string;
	jmw_comm: string;
	jmw_mf: string;
	jmw_nse: string;
	jmw_content: string;
	jmw_login: string;
	tradeService: string;
	edelService: string;
	appId: string;
	appIdKey: string;
	rootUrl: string;
}

export type TAccDataRes = {
	accTyp: string;
	accs: IAccDataAccs;
	adhrEQ?: IAccDataAdhrEqCom;
	adhrCOM?: IAccDataAdhrEqCom;
	cnsntLst: Array<IAccDataCnsntLst>;
	optCnsts?: Array<IAccDataCnsntLst>;
	dfPrds: { [x: string]: TBySl };
	dfPrdsMTF: { [x: string]: TBySl };
	excs: Array<string>;
	gtdGtcValDays: string;
	mrgAccDts: { emIds?: Array<string>; phNos: Array<string>; uids: Array<string> };
	mrgSts: string;
	mtf: { popFl: string; sts: string };
	ordTypes: Array<{ key: string; value: string }>;
	othAccDts: { accTyp: string; isAct: boolean; isOnline: boolean; uid: string };
	prds: { exc: string; prd: Array<{ prdDpNm: string; prdVal: string; validity: Array<IAccDataValidity> }> };
	sts: string;
	type: string;
	val: Array<{ exc: string; validity: Array<IAccDataValidity> }>;
};

export type TAccData = ObjectConcat<
	TAccDataRes,
	{
		userID: IAccDataAccs["uid"];
		mtf: TAccDataRes["mtf"]["sts"];
		adhrEQ: { stat: TAccDataRes["adhrEQ"]; brkCode: IAccDataAccs["eqBrk"] };
		adhrCOM: { stat: TAccDataRes["adhrCOM"]; brkCode: IAccDataAccs["coBrk"] };
		coAccID: IAccDataAccs["coAccID"];
		eqAccID: IAccDataAccs["eqAccID"];
		eqRmRt: IAccDataAccs["eqRmRt"];
		eqAccName: IAccDataAccs["eqAccName"];
		coAccName: IAccDataAccs["coAccName"];
		prfId: IAccDataAccs["prfId"];
		wtspCnsnt: IAccDataAccs["wtspCnsnt"];
	}
>;

export interface IAccDataAccs {
	bseMfstwt: boolean;
	eqAccID?: string;
	coAccID?: string;
	eqAccName?: string;
	coAccName?: string;
	eqAlgoClnt: string;
	eqBrk?: string;
	eqBrkCode?: string;
	coBrk?: string;
	coBrkCode?: string;
	eqDob: string;
	eqEmpCat: string;
	eqRmRt: string;
	eqstwt: boolean;
	llt: string;
	prfId: string;
	rstpwd: boolean;
	rstusr: boolean;
	ucmCd: string;
	uid: string;
	wtspCnsnt: string;
}

export interface IAccDataAdhrEqCom {
	popFl: string;
	rlEDt: string;
	rlFl: string;
	sts: string;
}

export interface IAccDataCnsntLst {
	accType: string;
	mdtry: boolean;
	name: string;
}

export type TBySl = { by: string; sl: string };

export interface IAccDataValidity {
	dpNm: string;
	vaVal: string;
}

/**
 * RequireAtLeastOne<YourInterface, "rquirement1" | "requirement2" | ... >
 */
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
	{
		[K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
	}[Keys];

/**
 * RequireOnlyOne<YourInterface, "requirement1" | "requirement2" | ... >
 */
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
	{
		[K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
	}[Keys];

/**
 * This works if two objects are concatenated. Pass the type of first object in T and type of second object in S
 */
export type ObjectConcat<A, B> = B & Pick<A, Exclude<keyof A, keyof B>>;
