import React from 'react';
import ProfileNavBar from '../ProfileNavBar/ProfileNavBar';
import './ProfileMenu.scss';
import DeviceContext from '../../Services/identifyDevice';
import { useHistory } from 'react-router-dom';
import GlobalStore from '../../store/globalStore';
import ProgressBar from '../../UI/ProgressBar/ProgressBar';
import { dashboardUrl } from '../../utils/constants';
import { calculateCompletePercentage } from '../../Services/globalService';

export interface ProfileMenuProps {
}

const ProfileMenu: React.FC<ProfileMenuProps> = () => {

    const history = useHistory();

    React.useEffect(() => {
        if (DeviceContext().isDesktop) {
            if (GlobalStore.dashboardDefaultUrl)
                history.replace(GlobalStore.dashboardDefaultUrl);
            else
                history.replace(dashboardUrl.profile);
        }
    }, [history.location.pathname]);

    const percentageComplete: string = calculateCompletePercentage();

    return (
        <div className='prfMenu'>
            <div className='prfMenuLogo'>
                <span className='edel-icon-logo-label' />
                <div className='progBar'>
                    <ProgressBar percent={percentageComplete} width='120px' height='4px' />
                </div>
                <div className='progPercent'>
                    <label>{percentageComplete}% complete</label>
                </div>
            </div>
            <div className='menuList'>
                <ProfileNavBar />
            </div>
        </div>
    )
}

export default ProfileMenu;